export const Enfermedades = [
  {
     id:"A00",
     enfermedad:"Cólera"
  },
  {
     id:"A000",
     enfermedad:"Colera debido a vibrio cholerae o1, biotipo cholerae"
  },
  {
     id:"A001",
     enfermedad:"Colera debido a vibrio cholerae o1, biotipo el tor"
  },
  {
     id:"A009",
     enfermedad:"Colera no especificado"
  },
  {
     id:"A01",
     enfermedad:"Fiebres tifoidea y paratifoidea"
  },
  {
     id:"A010",
     enfermedad:"Fiebre tifoidea"
  },
  {
     id:"A011",
     enfermedad:"Fiebre paratifoidea a"
  },
  {
     id:"A012",
     enfermedad:"Fiebre paratifoidea b"
  },
  {
     id:"A013",
     enfermedad:"Fiebre paratifoidea c"
  },
  {
     id:"A014",
     enfermedad:"Fiebre paratifoidea, no especificada"
  },
  {
     id:"A02",
     enfermedad:"Otras infecciones debidas a Salmonella"
  },
  {
     id:"A020",
     enfermedad:"Enteritis debida a salmonella"
  },
  {
     id:"A021",
     enfermedad:"Septicemia debida a salmonella"
  },
  {
     id:"A022",
     enfermedad:"Infecciones localizadas debida a salmonella"
  },
  {
     id:"A028",
     enfermedad:"Otras infecciones especificadas como debidas a salmonella"
  },
  {
     id:"A029",
     enfermedad:"Infeccion debida a salmonella no especificada"
  },
  {
     id:"A03",
     enfermedad:"Shigelosis"
  },
  {
     id:"A030",
     enfermedad:"Shigelosis debida a shigella dysenteriae"
  },
  {
     id:"A031",
     enfermedad:"Shigelosis debida a shigella flexneri"
  },
  {
     id:"A032",
     enfermedad:"Shigelosis debida a shigella boydii"
  },
  {
     id:"A033",
     enfermedad:"Shigelosis debida a shigella sonnei"
  },
  {
     id:"A038",
     enfermedad:"Otras shigelosis"
  },
  {
     id:"A039",
     enfermedad:"Shigelosis de tipo no especificado"
  },
  {
     id:"A04",
     enfermedad:"Otras infecciones intestinales bacterianas"
  },
  {
     id:"A040",
     enfermedad:"Infeccion debida a escherichia coli enteropatogena"
  },
  {
     id:"A041",
     enfermedad:"Infeccion debida a escherichia coli enterotoxigena"
  },
  {
     id:"A042",
     enfermedad:"Infeccion debida a escherichia coli enteroinvasiva"
  },
  {
     id:"A043",
     enfermedad:"Infeccion debida a escherichia coli enterohemorragica"
  },
  {
     id:"A044",
     enfermedad:"Otras infecciones intestinales debidas a escherichia coli"
  },
  {
     id:"A045",
     enfermedad:"Enteritis debida a campylobacter"
  },
  {
     id:"A046",
     enfermedad:"Enteritis debida a yersinia enterocolitica"
  },
  {
     id:"A047",
     enfermedad:"Enterocolitis debida a clostridium difficile"
  },
  {
     id:"A048",
     enfermedad:"Otras infecciones intestinales bacterianas especificadas"
  },
  {
     id:"A049",
     enfermedad:"Infeccion intestinal bacteriana, no especificada"
  },
  {
     id:"A05",
     enfermedad:"Otras intoxicaciones alimentarias bacterias"
  },
  {
     id:"A050",
     enfermedad:"Intoxicacion alimentaria estafilococica"
  },
  {
     id:"A051",
     enfermedad:"Botulismo"
  },
  {
     id:"A052",
     enfermedad:"Intoxicacion alimentaria debida a clostridium perfringens [clostridium welchii]"
  },
  {
     id:"A053",
     enfermedad:"Intoxicacion alimentaria debida a vibrio parahaemolyticus"
  },
  {
     id:"A054",
     enfermedad:"Intoxicacion alimentaria debida a bacillus cereus"
  },
  {
     id:"A058",
     enfermedad:"Otras intoxicaciones alimentarias debidas a bacterias especificadas"
  },
  {
     id:"A059",
     enfermedad:"Intoxicacion alimentaria bacteriana, no especificada"
  },
  {
     id:"A06",
     enfermedad:"Amebiasis"
  },
  {
     id:"A060",
     enfermedad:"Disenteria amebiana aguda"
  },
  {
     id:"A061",
     enfermedad:"Amebiasis intestinal cronica"
  },
  {
     id:"A062",
     enfermedad:"Colitis amebiana no disenterica"
  },
  {
     id:"A063",
     enfermedad:"Ameboma intestinal"
  },
  {
     id:"A064",
     enfermedad:"Absceso amebiano del higado"
  },
  {
     id:"A065",
     enfermedad:"Absceso amebiano del pulmon (j99.8)"
  },
  {
     id:"A066",
     enfermedad:"Absceso amebiano del cerebro (g07)"
  },
  {
     id:"A067",
     enfermedad:"Amebiasis cutanea"
  },
  {
     id:"A068",
     enfermedad:"Infeccion amebiana de otras localizaciones"
  },
  {
     id:"A069",
     enfermedad:"Amebiasis, no especificada"
  },
  {
     id:"A07",
     enfermedad:"Otras enfermedades intestinales debidas a protozoarios"
  },
  {
     id:"A070",
     enfermedad:"Balantidiasis"
  },
  {
     id:"A071",
     enfermedad:"Giardiasis [lambliasis]"
  },
  {
     id:"A072",
     enfermedad:"Criptosporidiosis"
  },
  {
     id:"A073",
     enfermedad:"Isosporiasis"
  },
  {
     id:"A078",
     enfermedad:"Otras enfermedades intestinales especificadas debidas a protozoarios"
  },
  {
     id:"A079",
     enfermedad:"Enfermedad intestinal debida a protozoarios, no especificada"
  },
  {
     id:"A08",
     enfermedad:"Infecciones intestinales debidas a virus y otros organismos especificados"
  },
  {
     id:"A080",
     enfermedad:"Enteritis debida a rotavirus"
  },
  {
     id:"A081",
     enfermedad:"Gastroenteropatia aguda debida al agente de norwalk"
  },
  {
     id:"A082",
     enfermedad:"Enteritis debida a adenovirus"
  },
  {
     id:"A083",
     enfermedad:"Otras enteritis virales"
  },
  {
     id:"A084",
     enfermedad:"Infeccion intestinal viral, sin otra especificacion"
  },
  {
     id:"A085",
     enfermedad:"Otras infecciones intestinales especificadas"
  },
  {
     id:"A09",
     enfermedad:"Diarrea y gastroenteritis de presunto origen infeccioso"
  },
  {
     id:"A15",
     enfermedad:"Tuberculosis respiratoria, confirmada bacteriológica e histologicamente"
  },
  {
     id:"A150",
     enfermedad:"Tuberculosis del pulmon, confirmada por hallazgo microscopico del bacilo tuberculoso en esputo, con o sin cultivo"
  },
  {
     id:"A151",
     enfermedad:"Tuberculosis del pulmon, confirmada unicamente por cultivo"
  },
  {
     id:"A152",
     enfermedad:"Tuberculosis del pulmon, confirmada histologicamente"
  },
  {
     id:"A153",
     enfermedad:"Tuberculosis del pulmon , confirmada por medios no especificados"
  },
  {
     id:"A154",
     enfermedad:"Tuberculosis de ganglios linfaticos intratoracicos, confirmada bacteriologica e histologicamente"
  },
  {
     id:"A155",
     enfermedad:"Tuberculosis de laringe, traquea y bronquios, confirmada bacteriologica e histologicamente"
  },
  {
     id:"A156",
     enfermedad:"Pleuresia tuberculosa, confirmada bacteriologica e histologicamente"
  },
  {
     id:"A157",
     enfermedad:"Tuberculosis respiratoria primaria, confirmada bacteriologica e histologicamente"
  },
  {
     id:"A158",
     enfermedad:"Otras tuberculosis respiratorias, confirmadas bacteriologica e histologicamente"
  },
  {
     id:"A159",
     enfermedad:"Tuberculosis respiratoria no especificada, confirmada bacteriologicamente e histologicamente"
  },
  {
     id:"A16",
     enfermedad:"Tuberculosis respiratoria, no confirmada bacteriologica e histologicamente"
  },
  {
     id:"A160",
     enfermedad:"Tuberculosis del pulmon, con examen bacteriologico e histologico negativos"
  },
  {
     id:"A161",
     enfermedad:"Tuberculosis del pulmon, sin examen bacteriologico e histologico"
  },
  {
     id:"A162",
     enfermedad:"Tuberculosis de pulmon, sin mencion de confirmacion bacteriologica o histologica"
  },
  {
     id:"A163",
     enfermedad:"Tuberculosis de ganglios linfaticos intratoracicos, sin mencion de confirmacion bacteriologica o histologica"
  },
  {
     id:"A164",
     enfermedad:"Tuberculosis de laringe, traquea y bronquios, sin mencion de confirmacion bacteriologica o histologica"
  },
  {
     id:"A165",
     enfermedad:"Pleuresia tuberculosa, sin mencion de confirmacion bacteriologica e histologica"
  },
  {
     id:"A167",
     enfermedad:"Tuberculosis respiratoria primaria, sin mencion de confirmacion bacteriologica e histologica"
  },
  {
     id:"A168",
     enfermedad:"Otras tuberculosis respiratorias, sin mencion de confirmacion"
  },
  {
     id:"A169",
     enfermedad:"Tuberculosis respiratoria no especificada, sin mencion de confirmacion bacteriologica o histologica"
  },
  {
     id:"A17",
     enfermedad:"Tuberculosis del sistema nervioso"
  },
  {
     id:"A170",
     enfermedad:"Meningitis tuberculosa (g01)"
  },
  {
     id:"A171",
     enfermedad:"Tuberculoma meningeo (g07)"
  },
  {
     id:"A178",
     enfermedad:"Otras tuberculosis del sistema nervioso"
  },
  {
     id:"A179",
     enfermedad:"Tuberculosis del sistema nervioso, no especificada (g99.8)"
  },
  {
     id:"A18",
     enfermedad:"Tuberculosis de otros órganos"
  },
  {
     id:"A180",
     enfermedad:"Tuberculosis de huesos y articulaciones"
  },
  {
     id:"A181",
     enfermedad:"Tuberculosis del aparato genitourinario"
  },
  {
     id:"A182",
     enfermedad:"Linfadenopatia periferica tuberculosa"
  },
  {
     id:"A183",
     enfermedad:"Tuberculosis de los intestinos, el peritoneo y los ganglios mesentericos"
  },
  {
     id:"A184",
     enfermedad:"Tuberculosis de la piel y el tejido subcutaneo"
  },
  {
     id:"A185",
     enfermedad:"Tuberculosis del ojo"
  },
  {
     id:"A186",
     enfermedad:"Tuberculosis del oido"
  },
  {
     id:"A187",
     enfermedad:"Tuberculosis de glandulas suprarrenales (e35.1)"
  },
  {
     id:"A188",
     enfermedad:"Tuberculosis de otros organos especificados"
  },
  {
     id:"A19",
     enfermedad:"Tuberculosis miliar"
  },
  {
     id:"A190",
     enfermedad:"Tuberculosis miliar aguda de un solo sitio especificado"
  },
  {
     id:"A191",
     enfermedad:"Tuberculosis miliar aguda de sitios multiples"
  },
  {
     id:"A192",
     enfermedad:"Tuberculosis miliar aguda, no especificada"
  },
  {
     id:"A198",
     enfermedad:"Otras tuberculosis miliares"
  },
  {
     id:"A199",
     enfermedad:"Tuberculosis miliar, sin otra especificacion"
  },
  {
     id:"A20",
     enfermedad:"Peste"
  },
  {
     id:"A200",
     enfermedad:"Peste bubonica"
  },
  {
     id:"A201",
     enfermedad:"Peste celulocutanea"
  },
  {
     id:"A202",
     enfermedad:"Peste neumonica"
  },
  {
     id:"A203",
     enfermedad:"Meningitis por peste"
  },
  {
     id:"A207",
     enfermedad:"Peste septicemica"
  },
  {
     id:"A208",
     enfermedad:"Otras formas de peste"
  },
  {
     id:"A209",
     enfermedad:"Peste, no especificada"
  },
  {
     id:"A21",
     enfermedad:"Tularemia"
  },
  {
     id:"A210",
     enfermedad:"Tularemia ulceroglandular"
  },
  {
     id:"A211",
     enfermedad:"Tularemia oculoglandular"
  },
  {
     id:"A212",
     enfermedad:"Tularemia pulmonar"
  },
  {
     id:"A213",
     enfermedad:"Tularemia gastrointestinal"
  },
  {
     id:"A217",
     enfermedad:"Tularemia generalizada"
  },
  {
     id:"A218",
     enfermedad:"Otras formas de tularemia"
  },
  {
     id:"A219",
     enfermedad:"Tularemia , no especificada"
  },
  {
     id:"A22",
     enfermedad:"Carbunco (ántrax)"
  },
  {
     id:"A220",
     enfermedad:"Carbunco cutaneo"
  },
  {
     id:"A221",
     enfermedad:"Carbunco pulmonar"
  },
  {
     id:"A222",
     enfermedad:"Carbunco gastrointestinal"
  },
  {
     id:"A227",
     enfermedad:"Carbunco septicemico"
  },
  {
     id:"A228",
     enfermedad:"Otras formas de carbunco"
  },
  {
     id:"A229",
     enfermedad:"Carbunco, no especificado"
  },
  {
     id:"A23",
     enfermedad:"Brucelosis"
  },
  {
     id:"A230",
     enfermedad:"Brucelosis debida a brucella melitensis"
  },
  {
     id:"A231",
     enfermedad:"Brucelosis debida a brucella abortus"
  },
  {
     id:"A232",
     enfermedad:"Brucelosis debida a brucella suis"
  },
  {
     id:"A233",
     enfermedad:"Brucelosis debida a brucella canis"
  },
  {
     id:"A238",
     enfermedad:"Otras brucelosis"
  },
  {
     id:"A239",
     enfermedad:"Brucelosis, no especificada"
  },
  {
     id:"A24",
     enfermedad:"Muermo y melioidosis"
  },
  {
     id:"A240",
     enfermedad:"Muermo"
  },
  {
     id:"A241",
     enfermedad:"Melioidosis aguda y fulminante"
  },
  {
     id:"A242",
     enfermedad:"Melioidosis subaguda y cronica"
  },
  {
     id:"A243",
     enfermedad:"Otras melioidosis"
  },
  {
     id:"A244",
     enfermedad:"Melioidosis , no especificada"
  },
  {
     id:"A25",
     enfermedad:"Fiebre por mordedura de rata"
  },
  {
     id:"A250",
     enfermedad:"Espirilosis"
  },
  {
     id:"A251",
     enfermedad:"Estreptobacilosis"
  },
  {
     id:"A259",
     enfermedad:"Fiebre por mordedura de rata, no especificada"
  },
  {
     id:"A26",
     enfermedad:"Erisipeloide"
  },
  {
     id:"A260",
     enfermedad:"Erisipeloide cutaneo"
  },
  {
     id:"A267",
     enfermedad:"Septicemia por erysipelothrix"
  },
  {
     id:"A268",
     enfermedad:"Otras formas de erisipeloide"
  },
  {
     id:"A269",
     enfermedad:"Erisipeloide, no especificada"
  },
  {
     id:"A27",
     enfermedad:"Leptospirosis"
  },
  {
     id:"A270",
     enfermedad:"Leptospirosis icterohemorragica"
  },
  {
     id:"A278",
     enfermedad:"Otras formas de leptospirosis"
  },
  {
     id:"A279",
     enfermedad:"Leptospirosis, no especificada"
  },
  {
     id:"A28",
     enfermedad:"Otras enfermedades zoonóticas bacterianas, no clasificadas en otra parte"
  },
  {
     id:"A280",
     enfermedad:"Pasteurelosis"
  },
  {
     id:"A281",
     enfermedad:"Enfermedad por rasguño de gato"
  },
  {
     id:"A282",
     enfermedad:"Yersiniosis extraintestinal"
  },
  {
     id:"A288",
     enfermedad:"Otras enfermedades zoonoticas bacterianas especificadas, no clasificadas en otra parte"
  },
  {
     id:"A289",
     enfermedad:"Enfermedad zoonotica bacteriana, sin otra especificacion"
  },
  {
     id:"A30",
     enfermedad:"Lepra (enfermedad de Hansen)"
  },
  {
     id:"A300",
     enfermedad:"Lepra indeterminada"
  },
  {
     id:"A301",
     enfermedad:"Lepra tuberculoide"
  },
  {
     id:"A302",
     enfermedad:"Lepra tuberculoide limitrofe"
  },
  {
     id:"A303",
     enfermedad:"Lepra limitrofe"
  },
  {
     id:"A304",
     enfermedad:"Lepra lepromatosa limitrofe"
  },
  {
     id:"A305",
     enfermedad:"Lepra lepromatosa"
  },
  {
     id:"A308",
     enfermedad:"Otras formas de lepra"
  },
  {
     id:"A309",
     enfermedad:"Lepra, no especificada"
  },
  {
     id:"A31",
     enfermedad:"Infecciones debidas a otras micobacterias"
  },
  {
     id:"A310",
     enfermedad:"Infecciones por micobacterias pulmonares"
  },
  {
     id:"A311",
     enfermedad:"Infeccion cutanea por micobacterias"
  },
  {
     id:"A318",
     enfermedad:"Otras infecciones por micobacterias"
  },
  {
     id:"A319",
     enfermedad:"Infeccion por micobacteria, no especificada"
  },
  {
     id:"A32",
     enfermedad:"Listeriosis"
  },
  {
     id:"A320",
     enfermedad:"Listeriosis cutanea"
  },
  {
     id:"A321",
     enfermedad:"Meningitis y meningoencefalitis listeriana"
  },
  {
     id:"A327",
     enfermedad:"Septicemia listeriana"
  },
  {
     id:"A328",
     enfermedad:"Otras formas de listeriosis"
  },
  {
     id:"A329",
     enfermedad:"Listeriosis, no especificada"
  },
  {
     id:"A33",
     enfermedad:"Tetanos neonatal"
  },
  {
     id:"A34",
     enfermedad:"Tetanos obstetrico"
  },
  {
     id:"A35",
     enfermedad:"Otros tetanos"
  },
  {
     id:"A36",
     enfermedad:"Difteria"
  },
  {
     id:"A360",
     enfermedad:"Difteria faringea"
  },
  {
     id:"A361",
     enfermedad:"Difteria nasofaringea"
  },
  {
     id:"A362",
     enfermedad:"Difteria laringea"
  },
  {
     id:"A363",
     enfermedad:"Difteria cutanea"
  },
  {
     id:"A368",
     enfermedad:"Otras difterias"
  },
  {
     id:"A369",
     enfermedad:"Difteria, no especificada"
  },
  {
     id:"A37",
     enfermedad:"Tos ferina (tos convulsiva)"
  },
  {
     id:"A370",
     enfermedad:"Tos ferina debida a bordetella pertussis"
  },
  {
     id:"A371",
     enfermedad:"Tos ferina debida a bordetella parapertussis"
  },
  {
     id:"A378",
     enfermedad:"Tos ferina debida a otras especies de bordetella"
  },
  {
     id:"A379",
     enfermedad:"Tos ferina, no especificada"
  },
  {
     id:"A38",
     enfermedad:"Escarlatina"
  },
  {
     id:"A39",
     enfermedad:"Infección meningocócica"
  },
  {
     id:"A390",
     enfermedad:"Meningitis meningococica (g01)"
  },
  {
     id:"A391",
     enfermedad:"Sindrome de waterhouse"
  },
  {
     id:"A392",
     enfermedad:"Meningococemia aguda"
  },
  {
     id:"A393",
     enfermedad:"Meningococemia cronica"
  },
  {
     id:"A394",
     enfermedad:"Meningococemia, no especificada"
  },
  {
     id:"A395",
     enfermedad:"Enfermedad cardiaca debida a meningococo"
  },
  {
     id:"A398",
     enfermedad:"Otras infecciones meningococicas"
  },
  {
     id:"A399",
     enfermedad:"Infeccion meningococica, no especificada"
  },
  {
     id:"A40",
     enfermedad:"Septicemia estreptocócica"
  },
  {
     id:"A400",
     enfermedad:"Septicemia debida a estreptococo, grupo a"
  },
  {
     id:"A401",
     enfermedad:"Septicemia debida a estreptococo, grupo b"
  },
  {
     id:"A402",
     enfermedad:"Septicemia debida a estreptococo, grupo d"
  },
  {
     id:"A403",
     enfermedad:"Septicemia debida a streptococcus pneumoniae"
  },
  {
     id:"A408",
     enfermedad:"Otras septicemias estreptococicas"
  },
  {
     id:"A409",
     enfermedad:"Septicemia estreptococica, no especificada"
  },
  {
     id:"A41",
     enfermedad:"Otras septicemias"
  },
  {
     id:"A410",
     enfermedad:"Septicemia debida staphylococcus aureus"
  },
  {
     id:"A411",
     enfermedad:"Septicemia debida a otro estafilococo especificado"
  },
  {
     id:"A412",
     enfermedad:"Septicemia debida a estafilococo no especificado"
  },
  {
     id:"A413",
     enfermedad:"Septicemia debida a haemophilus influenzae"
  },
  {
     id:"A414",
     enfermedad:"Septicemia debida a anaerobios"
  },
  {
     id:"A415",
     enfermedad:"Septicemia debida a otros organismos gramnegativos"
  },
  {
     id:"A418",
     enfermedad:"Otras septicemias especificadas"
  },
  {
     id:"A419",
     enfermedad:"Septicemia, no especificada"
  },
  {
     id:"A42",
     enfermedad:"Actinomicosis"
  },
  {
     id:"A420",
     enfermedad:"Actinomicosis pulmonar"
  },
  {
     id:"A421",
     enfermedad:"Actinomicosis abdominal"
  },
  {
     id:"A422",
     enfermedad:"Actinomicosis cervicofacial"
  },
  {
     id:"A427",
     enfermedad:"Septicemia actinomicotica"
  },
  {
     id:"A428",
     enfermedad:"Otras formas de actinomicosis"
  },
  {
     id:"A429",
     enfermedad:"Actinomicosis, sin otra especificacion"
  },
  {
     id:"A43",
     enfermedad:"Nocardiosis"
  },
  {
     id:"A430",
     enfermedad:"Nocardiosis pulmonar"
  },
  {
     id:"A431",
     enfermedad:"Nocardiosis cutanea"
  },
  {
     id:"A438",
     enfermedad:"Otras formas de nocardiosis"
  },
  {
     id:"A439",
     enfermedad:"Norcardiosis , no especificada"
  },
  {
     id:"A44",
     enfermedad:"Bartonelosis"
  },
  {
     id:"A440",
     enfermedad:"Bartonelosis sistemica"
  },
  {
     id:"A441",
     enfermedad:"Bartonelosis cutanea y mucocutanea"
  },
  {
     id:"A448",
     enfermedad:"Otras formas de bartonelosis"
  },
  {
     id:"A449",
     enfermedad:"Bartonelosis, no especificada"
  },
  {
     id:"A46",
     enfermedad:"Erisipela"
  },
  {
     id:"A48",
     enfermedad:"Otras enfermedades bacterianas, no clasificadas en otra parte"
  },
  {
     id:"A480",
     enfermedad:"Gangrena gaseosa"
  },
  {
     id:"A481",
     enfermedad:"Enfermedad de los legionarios"
  },
  {
     id:"A482",
     enfermedad:"Enfermedad de los legionarios no neumonica [fiebre de pontiac]"
  },
  {
     id:"A483",
     enfermedad:"Sindrome de choque toxico"
  },
  {
     id:"A484",
     enfermedad:"Fiebre purpurica brasileña"
  },
  {
     id:"A488",
     enfermedad:"Otras enfermedades bacterianas especificadas"
  },
  {
     id:"A49",
     enfermedad:"Infección bacteriana de sitio no especificado"
  },
  {
     id:"A490",
     enfermedad:"Infeccion estafilococica, sin otra especificacion"
  },
  {
     id:"A491",
     enfermedad:"Infeccion estreptococica, sin otra especificacion"
  },
  {
     id:"A492",
     enfermedad:"Infeccion por haemophilus influenzae, sin otra especificacion"
  },
  {
     id:"A493",
     enfermedad:"Infeccion por micoplasma, sin otra especificacion"
  },
  {
     id:"A498",
     enfermedad:"Otras infecciones bacterianas de sitio no especificado"
  },
  {
     id:"A499",
     enfermedad:"Infeccion bacteriana, no especificada"
  },
  {
     id:"A500",
     enfermedad:"Sifilis congenita precoz, sintomatica"
  },
  {
     id:"A501",
     enfermedad:"Sifilis congenita precoz, latente"
  },
  {
     id:"A502",
     enfermedad:"Sifilis congenita precoz sin otra especificacion"
  },
  {
     id:"A503",
     enfermedad:"Oculopatia sifilitica congenita tardia"
  },
  {
     id:"A504",
     enfermedad:"Neurosifilis congenita tardia [neurosifilis juvenil]"
  },
  {
     id:"A505",
     enfermedad:"Otras formas de sifilis congenita tardia, sintomatica"
  },
  {
     id:"A506",
     enfermedad:"Sifilis congenita tardia, latente"
  },
  {
     id:"A507",
     enfermedad:"Sifilis congenita tardia, sin otra especificacion"
  },
  {
     id:"A509",
     enfermedad:"Sifilis congenita, sin otra especificacion"
  },
  {
     id:"A510",
     enfermedad:"Sifilis genital primaria"
  },
  {
     id:"A511",
     enfermedad:"Sifilis primaria anal"
  },
  {
     id:"A512",
     enfermedad:"Sifilis primaria en otros sitios"
  },
  {
     id:"A513",
     enfermedad:"Sifilis secundaria de piel y membranas mucosas"
  },
  {
     id:"A514",
     enfermedad:"Otras sifilis secundarias"
  },
  {
     id:"A515",
     enfermedad:"Sifilis precoz, latente"
  },
  {
     id:"A519",
     enfermedad:"Sifilis precoz, sin otra especificacion"
  },
  {
     id:"A52",
     enfermedad:"Sífilis tardía"
  },
  {
     id:"A520",
     enfermedad:"Sifilis cardiovascular"
  },
  {
     id:"A521",
     enfermedad:"Neurosifilis sintomatica"
  },
  {
     id:"A522",
     enfermedad:"Neurosifilis asintomatica"
  },
  {
     id:"A523",
     enfermedad:"Neurosifilis no especificada"
  },
  {
     id:"A527",
     enfermedad:"Otras sifilis tardias sintomaticas"
  },
  {
     id:"A528",
     enfermedad:"Sifilis tardia, latente"
  },
  {
     id:"A529",
     enfermedad:"Sifilis tardia, no especificada"
  },
  {
     id:"A53",
     enfermedad:"Otras sífilis y las no especificadas"
  },
  {
     id:"A530",
     enfermedad:"Sifilis latente, no especificada como precoz o tardia"
  },
  {
     id:"A539",
     enfermedad:"Sifilis, no especificada"
  },
  {
     id:"A54",
     enfermedad:"Infección gonocócica"
  },
  {
     id:"A540",
     enfermedad:"Infeccion gonococica del tracto genitourinario inferior sin absceso periuretral o de glandula accesoria"
  },
  {
     id:"A541",
     enfermedad:"Infeccion gonococica del tracto genitourinario inferior con absceso periuretral y de glandulas accesorias"
  },
  {
     id:"A542",
     enfermedad:"Pelviperitonitis gonococica y otras infecciones gonococicas genitourinarias"
  },
  {
     id:"A543",
     enfermedad:"Infeccion gonococica del ojo"
  },
  {
     id:"A544",
     enfermedad:"Infeccion gonococica del sistema osteomuscular"
  },
  {
     id:"A545",
     enfermedad:"Faringitis gonococica"
  },
  {
     id:"A546",
     enfermedad:"Infeccion gonococica del ano y del recto"
  },
  {
     id:"A548",
     enfermedad:"Otras infecciones gonococicas"
  },
  {
     id:"A549",
     enfermedad:"Infeccion, gonococica, no especificada"
  },
  {
     id:"A55",
     enfermedad:"Linfogranuloma (venereo) por clamidias"
  },
  {
     id:"A56",
     enfermedad:"Otras enfermedades de transmisión sexual debidas a clamidias"
  },
  {
     id:"A560",
     enfermedad:"Infeccion del tracto genitourinario inferior debida a clamidias"
  },
  {
     id:"A561",
     enfermedad:"Infeccion del pelviperitoneo y otros organos genitourinarios debida a clamidias"
  },
  {
     id:"A562",
     enfermedad:"Infecciones del tracto genitourinario debidas a clamidias, sin otra especificacion"
  },
  {
     id:"A563",
     enfermedad:"Infeccion del ano y del recto debida a clamidias"
  },
  {
     id:"A564",
     enfermedad:"Infeccion de faringe debida a clamidias"
  },
  {
     id:"A568",
     enfermedad:"Infeccion de transmision sexual de otros sitios debida a clamidias"
  },
  {
     id:"A57",
     enfermedad:"Chancro blando"
  },
  {
     id:"A58",
     enfermedad:"Granuloma inguinal"
  },
  {
     id:"A59",
     enfermedad:"Tricomoniasis"
  },
  {
     id:"A590",
     enfermedad:"Tricomoniasis urogenital"
  },
  {
     id:"A598",
     enfermedad:"Tricomoniasis de otros sitios"
  },
  {
     id:"A599",
     enfermedad:"Tricomoniasis, no especificada"
  },
  {
     id:"A60",
     enfermedad:"Infección anogenital debida a virus del herpes (herpes simple)"
  },
  {
     id:"A600",
     enfermedad:"Infeccion de genitales y trayecto urogenital y debida a virus del herpes [herpes simple]"
  },
  {
     id:"A601",
     enfermedad:"Infeccion de la piel perianal y recto por virus del herpes simple"
  },
  {
     id:"A609",
     enfermedad:"Infeccion anogenital por virus del herpes simple, sin otra especificacion"
  },
  {
     id:"A63",
     enfermedad:"Otras enfermedades de transmision predominantemente sexual, no clasificadas en otrasa parte"
  },
  {
     id:"A630",
     enfermedad:"Verrugas (venereas) anogenitales"
  },
  {
     id:"A638",
     enfermedad:"Otras enfermedades de transmision predominantemente sexual, especificadas"
  },
  {
     id:"A64",
     enfermedad:"Enfermedad de transmision sexual no especificada"
  },
  {
     id:"A65",
     enfermedad:"Sifilis no venerea"
  },
  {
     id:"A66",
     enfermedad:"Frambesia"
  },
  {
     id:"A660",
     enfermedad:"Lesiones iniciales de frambesia"
  },
  {
     id:"A661",
     enfermedad:"Lesiones papilomatosas multiples y frambesia con paso de cangrejo"
  },
  {
     id:"A662",
     enfermedad:"Otras lesiones precoces de la piel en la frambesia"
  },
  {
     id:"A663",
     enfermedad:"Hiperqueratosis de frambesia"
  },
  {
     id:"A664",
     enfermedad:"Goma y ulceras de frambesia"
  },
  {
     id:"A665",
     enfermedad:"Gangosa"
  },
  {
     id:"A666",
     enfermedad:"Lesiones frambesicas de los huesos y de las articulaciones"
  },
  {
     id:"A667",
     enfermedad:"Otras manifestaciones de frambesia"
  },
  {
     id:"A668",
     enfermedad:"Frambesia latente"
  },
  {
     id:"A669",
     enfermedad:"Frambesia , no especificada"
  },
  {
     id:"A67",
     enfermedad:"Pinta (carate)"
  },
  {
     id:"A670",
     enfermedad:"Lesiones primarias de la pinta"
  },
  {
     id:"A671",
     enfermedad:"Lesiones intermedias de la pinta"
  },
  {
     id:"A672",
     enfermedad:"Lesiones tardias de la pinta"
  },
  {
     id:"A673",
     enfermedad:"Lesiones mixtas de la pinta"
  },
  {
     id:"A679",
     enfermedad:"Pinta, no especificada"
  },
  {
     id:"A68",
     enfermedad:"Fiebres recurrentes"
  },
  {
     id:"A680",
     enfermedad:"Fiebre recurrente transmitida por piojos"
  },
  {
     id:"A681",
     enfermedad:"Fiebre recurrente transmitida por garrapatas"
  },
  {
     id:"A689",
     enfermedad:"Fiebre recurrente, no especificada"
  },
  {
     id:"A69",
     enfermedad:"Otras infecciones causadas por espiroquetas"
  },
  {
     id:"A690",
     enfermedad:"Estomatitis ulcerativa necrotizante"
  },
  {
     id:"A691",
     enfermedad:"Otras infecciones de vicent"
  },
  {
     id:"A692",
     enfermedad:"Enfermedad de lyme"
  },
  {
     id:"A698",
     enfermedad:"Otras infecciones especificadas por espiroquetas"
  },
  {
     id:"A699",
     enfermedad:"Infeccion por espiroqueta, no especificada"
  },
  {
     id:"A70",
     enfermedad:"Infeccion debida a chlamydia psittaci"
  },
  {
     id:"A71",
     enfermedad:"Tracoma"
  },
  {
     id:"A710",
     enfermedad:"Estado inicial de tracoma"
  },
  {
     id:"A711",
     enfermedad:"Estado activo de tracoma"
  },
  {
     id:"A719",
     enfermedad:"Tracoma, no especificado"
  },
  {
     id:"A74",
     enfermedad:"Otras enfermedades causadas por clamidias"
  },
  {
     id:"A740",
     enfermedad:"Conjuntivitis por clamidias (h13.1)"
  },
  {
     id:"A748",
     enfermedad:"Otras enfermedades por clamidias"
  },
  {
     id:"A749",
     enfermedad:"Infeccion por clamidias, no especificada"
  },
  {
     id:"A75",
     enfermedad:"Tifus"
  },
  {
     id:"A750",
     enfermedad:"Tifus epidemico debido a rickettsia prowazekii transmitido por piojos"
  },
  {
     id:"A751",
     enfermedad:"Tifus recrudescente [enfermedad de brill]"
  },
  {
     id:"A752",
     enfermedad:"Tifus debido a rickettsia typhi"
  },
  {
     id:"A753",
     enfermedad:"Tifus debido a rickettsia tsutsugamushi"
  },
  {
     id:"A759",
     enfermedad:"Tifus, no especificado"
  },
  {
     id:"A77",
     enfermedad:"Fiebre maculosa (rickettsiosis transmitida por garrapatas)"
  },
  {
     id:"A770",
     enfermedad:"Fiebre maculosa debido a rickettsia rickettsii"
  },
  {
     id:"A771",
     enfermedad:"Fiebre maculosa debido a rickettsia conorii"
  },
  {
     id:"A772",
     enfermedad:"Fiebre maculosa debido a rickettsia siberica"
  },
  {
     id:"A773",
     enfermedad:"Fiebre maculosa debido a rickettsia australis"
  },
  {
     id:"A778",
     enfermedad:"Otras fiebres maculosas"
  },
  {
     id:"A779",
     enfermedad:"Fiebre maculosa, no especificada"
  },
  {
     id:"A78",
     enfermedad:"Fiebre q"
  },
  {
     id:"A79",
     enfermedad:"Otras rickettsiosis"
  },
  {
     id:"A790",
     enfermedad:"Fiebre de las trincheras"
  },
  {
     id:"A791",
     enfermedad:"Rickettsiosis pustulosa debida a rickettsia akari"
  },
  {
     id:"A798",
     enfermedad:"Otras rickettsiosis especificadas"
  },
  {
     id:"A799",
     enfermedad:"Rickettsiosis, no especificada"
  },
  {
     id:"A80",
     enfermedad:"Poliomielitis aguda"
  },
  {
     id:"A800",
     enfermedad:"Poliomielitis aguda paralitica, asociada a vacuna"
  },
  {
     id:"A801",
     enfermedad:"Poliomielitis aguda paralitica debida a virus salvaje importado"
  },
  {
     id:"A802",
     enfermedad:"Poliomielitis aguda paralitica debida a virus salvaje autoctono"
  },
  {
     id:"A803",
     enfermedad:"Otras poliomielitis agudas paraliticas, y no las especificadas"
  },
  {
     id:"A804",
     enfermedad:"Poliomielitis aguda no paralitica"
  },
  {
     id:"A809",
     enfermedad:"Poliomielitis aguda, sin otra especificacion"
  },
  {
     id:"A81",
     enfermedad:"Infecciones del sistema nervioso central por virus atipico"
  },
  {
     id:"A810",
     enfermedad:"Enfermedad de creutzfeldt"
  },
  {
     id:"A811",
     enfermedad:"Panencefalitis esclerosante subaguda"
  },
  {
     id:"A812",
     enfermedad:"Leucoencefalopatia multifocal progresiva"
  },
  {
     id:"A818",
     enfermedad:"Otras infecciones del sistema nervioso por virus atipico"
  },
  {
     id:"A819",
     enfermedad:"Infecciones del sistema nervioso central por virus atipico, sin otra especificacion"
  },
  {
     id:"A82",
     enfermedad:"Rabia"
  },
  {
     id:"A820",
     enfermedad:"Rabia selvatica"
  },
  {
     id:"A821",
     enfermedad:"Rabia urbana"
  },
  {
     id:"A829",
     enfermedad:"Rabia, sin otra especificacion"
  },
  {
     id:"A83",
     enfermedad:"Encefalitis viral transmitida por mosquitos"
  },
  {
     id:"A830",
     enfermedad:"Encefalitis japonesa"
  },
  {
     id:"A831",
     enfermedad:"Encefalitis equina del oeste"
  },
  {
     id:"A832",
     enfermedad:"Encefalitis equina del este"
  },
  {
     id:"A833",
     enfermedad:"Encefalitis de san luis"
  },
  {
     id:"A834",
     enfermedad:"Encefalitis australiana"
  },
  {
     id:"A835",
     enfermedad:"Encefalitis de california"
  },
  {
     id:"A836",
     enfermedad:"Enfermedad por virus rocio"
  },
  {
     id:"A838",
     enfermedad:"Otras encefalitis virales transmitidas por mosquitos"
  },
  {
     id:"A839",
     enfermedad:"Encefalitis viral transmitida por mosquitos, sin otra especificacion"
  },
  {
     id:"A84",
     enfermedad:"Encefalitis viral transmitida por garrapatas"
  },
  {
     id:"A840",
     enfermedad:"Encefalitis del lejano oriente transmitida por garrapatas [encefalitis primaveroestival rusa]"
  },
  {
     id:"A841",
     enfermedad:"Encefalitis centroeuropea transmitida por garrapatas"
  },
  {
     id:"A848",
     enfermedad:"Otras encefalitis virales transmitidas por garrapatas"
  },
  {
     id:"A849",
     enfermedad:"Encefalitis viral transmitida por garrapatas, sin otra especificacion"
  },
  {
     id:"A85",
     enfermedad:"Otras encefalitis virales, no clasificadas en otra parte"
  },
  {
     id:"A850",
     enfermedad:"Encefalitis enteroviral (g05.1)"
  },
  {
     id:"A851",
     enfermedad:"Encefalitis por adenovirus (g05.1)"
  },
  {
     id:"A852",
     enfermedad:"Encefalitis viral transmitida por artropodos, sin otra especificacion"
  },
  {
     id:"A858",
     enfermedad:"Otras encefalitis virales especificadas"
  },
  {
     id:"A86",
     enfermedad:"Encefalitis viral, no especificada"
  },
  {
     id:"A87",
     enfermedad:"Meningitis viral"
  },
  {
     id:"A870",
     enfermedad:"Meningitis enteroviral (g02.0)"
  },
  {
     id:"A871",
     enfermedad:"Meningitis debida a adenovirus (g02.0)"
  },
  {
     id:"A872",
     enfermedad:"Coriomeningitis linfocitica"
  },
  {
     id:"A878",
     enfermedad:"Otras meningitis virales"
  },
  {
     id:"A879",
     enfermedad:"Meningitis viral, sin otra especificacion"
  },
  {
     id:"A88",
     enfermedad:"Otras infecciones virales del sistema nervioso central, no clasificadas en otra parte"
  },
  {
     id:"A880",
     enfermedad:"Fiebre exantematica enteroviral [exantema de boston]"
  },
  {
     id:"A881",
     enfermedad:"Vertigo epidemico"
  },
  {
     id:"A888",
     enfermedad:"Otras infecciones virales especificadas del sistema nervioso central"
  },
  {
     id:"A89",
     enfermedad:"Infeccion viral del sistema nervioso central, no especificada"
  },
  {
     id:"A90",
     enfermedad:"Fiebre del dengue [dengue clasico]"
  },
  {
     id:"A91",
     enfermedad:"Fiebre del dengue hemorragico"
  },
  {
     id:"A92",
     enfermedad:"Otras fiebres virales transmitidas por mosquitos"
  },
  {
     id:"A920",
     enfermedad:"Enfermedad por virus chikungunya"
  },
  {
     id:"A921",
     enfermedad:"Fiebre de oÂ´nyong"
  },
  {
     id:"A922",
     enfermedad:"Fiebre equina venezolana"
  },
  {
     id:"A923",
     enfermedad:"Fiebre del oeste del nilo"
  },
  {
     id:"A924",
     enfermedad:"Fiebre del valle del rift"
  },
  {
     id:"A928",
     enfermedad:"Otras fiebres virales especificadas transmitidas por mosquitos"
  },
  {
     id:"A929",
     enfermedad:"Fiebre viral transmitida por mosquito, sin otra especificacion"
  },
  {
     id:"A93",
     enfermedad:"Otras fiebres virales transmitidas por artrópodos, no clasificadas en otra parte"
  },
  {
     id:"A930",
     enfermedad:"Enfermedad por virus de oropouche"
  },
  {
     id:"A931",
     enfermedad:"Fiebre transmitida por flebotomos"
  },
  {
     id:"A932",
     enfermedad:"Fiebre de colorado transmitida por garrapatas"
  },
  {
     id:"A938",
     enfermedad:"Otras fiebres virales especificadas transmitidas por antropodos"
  },
  {
     id:"A94",
     enfermedad:"Fiebre viral transmitida por artropodos, no especificada"
  },
  {
     id:"A95",
     enfermedad:"Fiebre amarilla"
  },
  {
     id:"A950",
     enfermedad:"Fiebre amarilla selvatica"
  },
  {
     id:"A951",
     enfermedad:"Fiebre amarilla urbana"
  },
  {
     id:"A959",
     enfermedad:"Fiebre amarilla, no especificada"
  },
  {
     id:"A96",
     enfermedad:"Fiebre hemorrágica por arenavirus"
  },
  {
     id:"A960",
     enfermedad:"Fiebre hemorragica de junin"
  },
  {
     id:"A961",
     enfermedad:"Fiebre hemorragica de machupo"
  },
  {
     id:"A962",
     enfermedad:"Fiebre de lassa"
  },
  {
     id:"A968",
     enfermedad:"Otras fiebres hemorragicas por arenavirus"
  },
  {
     id:"A969",
     enfermedad:"Fiebre hemorragica por arenavirus, sin otra especificacion"
  },
  {
     id:"A98",
     enfermedad:"Otras fiebres virales hemorrágicas, no clasificadas en otra parte"
  },
  {
     id:"A980",
     enfermedad:"Fiebre hemorragica de crimea"
  },
  {
     id:"A981",
     enfermedad:"Fiebre hemorragica de omsk"
  },
  {
     id:"A982",
     enfermedad:"Enfermedad de la selva de kyasanur"
  },
  {
     id:"A983",
     enfermedad:"Enfermedad por el virus de marburg"
  },
  {
     id:"A984",
     enfermedad:"Enfermedad por el virus de ebola"
  },
  {
     id:"A985",
     enfermedad:"Fiebres hemorragicas con sindrome renal"
  },
  {
     id:"A988",
     enfermedad:"Otras fiebres hemorragicas virales especificadas"
  },
  {
     id:"A99",
     enfermedad:"Fiebre viral hemorragica, no especificada"
  },
  {
     id:"B00",
     enfermedad:"Infecciones herpética [herpes simple]"
  },
  {
     id:"B000",
     enfermedad:"Eczema herpetico"
  },
  {
     id:"B001",
     enfermedad:"Dermatitis vesicular herpetica"
  },
  {
     id:"B002",
     enfermedad:"Gingivoestomatitis y faringoamigdalitis herpetica"
  },
  {
     id:"B003",
     enfermedad:"Meningitis herpetica (g02.0)"
  },
  {
     id:"B004",
     enfermedad:"Encefalitis herpetica (g05.1)"
  },
  {
     id:"B005",
     enfermedad:"Oculopatia herpetica"
  },
  {
     id:"B007",
     enfermedad:"Enfermedad herpetica diseminada"
  },
  {
     id:"B008",
     enfermedad:"Otras formas de infecciones herpeticas"
  },
  {
     id:"B009",
     enfermedad:"Infeccion debida a el virus del herpes, no especificada"
  },
  {
     id:"B01",
     enfermedad:"Varicela"
  },
  {
     id:"B010",
     enfermedad:"Meningitis debida a la varicela (g02.0)"
  },
  {
     id:"B011",
     enfermedad:"Encefalitis debida a la varicela (g05.1)"
  },
  {
     id:"B012",
     enfermedad:"Neumonia debida a la varicela (j17.1)"
  },
  {
     id:"B018",
     enfermedad:"Varicela con otras complicaciones"
  },
  {
     id:"B019",
     enfermedad:"Varicela sin complicaciones"
  },
  {
     id:"B02",
     enfermedad:"Herpes zoster"
  },
  {
     id:"B020",
     enfermedad:"Encefalitis debida a herpes zoster (g05.1)"
  },
  {
     id:"B021",
     enfermedad:"Meningitis debida a herpes zoster (g02.0)"
  },
  {
     id:"B022",
     enfermedad:"Herpes zoster con otros compromisos del sistema nervioso"
  },
  {
     id:"B023",
     enfermedad:"Herpes zoster ocular"
  },
  {
     id:"B027",
     enfermedad:"Herpes zoster diseminado"
  },
  {
     id:"B028",
     enfermedad:"Herpes zoster con otras complicaciones"
  },
  {
     id:"B029",
     enfermedad:"Herpes zoster sin complicaciones"
  },
  {
     id:"B03",
     enfermedad:"Viruela"
  },
  {
     id:"B04",
     enfermedad:"Viruela de los monos"
  },
  {
     id:"B05",
     enfermedad:"Sarampión"
  },
  {
     id:"B050",
     enfermedad:"Sarampion complicado con encefalitis (g05.1)"
  },
  {
     id:"B051",
     enfermedad:"Sarampion complicado con meningitis (g02.0)"
  },
  {
     id:"B052",
     enfermedad:"Sarampion complicado con neumonia (j17.1)"
  },
  {
     id:"B053",
     enfermedad:"Sarampion complicado con otitis media (h67.1)"
  },
  {
     id:"B054",
     enfermedad:"Sarampion con complicaciones intestinales"
  },
  {
     id:"B058",
     enfermedad:"Sarampion con otras complicaciones"
  },
  {
     id:"B059",
     enfermedad:"Sarampion sin complicaciones"
  },
  {
     id:"B06",
     enfermedad:"Rubéola [sarampión alemán]"
  },
  {
     id:"B060",
     enfermedad:"Rubeola con complicaciones neurologicas"
  },
  {
     id:"B068",
     enfermedad:"Rubeola con otras complicaciones"
  },
  {
     id:"B069",
     enfermedad:"Rubeola sin complicaciones"
  },
  {
     id:"B07",
     enfermedad:"Verrugas viricas"
  },
  {
     id:"B08",
     enfermedad:"Otras infeciones víricas caracterizadas por lesiones de la piel y de l"
  },
  {
     id:"B080",
     enfermedad:"Otras infecciones debidas a ortopoxvirus"
  },
  {
     id:"B081",
     enfermedad:"Molusco contagioso"
  },
  {
     id:"B082",
     enfermedad:"Exantema subito [sexta enfermedad]"
  },
  {
     id:"B083",
     enfermedad:"Eritema infeccioso [quinta enfermedad]"
  },
  {
     id:"B084",
     enfermedad:"Estomatitis vesicular enteroviral con exantema"
  },
  {
     id:"B085",
     enfermedad:"Faringitis vesicular enterovirica"
  },
  {
     id:"B088",
     enfermedad:"Otras infecciones virales especificadas, caracterizadas por lesiones de la piel y de las membranas mucosas"
  },
  {
     id:"B09",
     enfermedad:"Infeccion viral no especificada, caracterizada por lesiones de la piel y de las membranas mucosas"
  },
  {
     id:"B15",
     enfermedad:"Hepatitis aguda tipo A"
  },
  {
     id:"B150",
     enfermedad:"Hepatitis aguda tipo a, sin coma hepatico"
  },
  {
     id:"B159",
     enfermedad:"Hepatitis aguda tipo a, con coma hepatico"
  },
  {
     id:"B16",
     enfermedad:"Hepatitis aguda tipo B"
  },
  {
     id:"B160",
     enfermedad:"Hepatitis aguda tipo b, con agente delta (coinfeccion). con coma hepatico"
  },
  {
     id:"B161",
     enfermedad:"Hepatitis aguda tipo b, con agente delta (coinfeccion), sin coma hepatico"
  },
  {
     id:"B162",
     enfermedad:"Hepatitis aguda tipo b, sin agente delta, con coma hepatico"
  },
  {
     id:"B169",
     enfermedad:"Hepatitis aguda tipo b, sin agente delta y sin coma hepatico"
  },
  {
     id:"B17",
     enfermedad:"Otras hepatitis virales agudas"
  },
  {
     id:"B170",
     enfermedad:"Infeccion (superinfeccion) aguda por agente delta en el portador de hepatitis b"
  },
  {
     id:"B171",
     enfermedad:"Hepatitis aguda tipo c"
  },
  {
     id:"B172",
     enfermedad:"Hepatitis aguda tipo e"
  },
  {
     id:"B178",
     enfermedad:"Otras hepatitis virales agudas especificadas"
  },
  {
     id:"B18",
     enfermedad:"Hepatitis viral crónica"
  },
  {
     id:"B180",
     enfermedad:"Hepatitis viral tipo b cronica, con agente delta"
  },
  {
     id:"B181",
     enfermedad:"Hepatitis viral tipo b cronica, sin agente delta"
  },
  {
     id:"B182",
     enfermedad:"Hepatitis viral tipo c cronica"
  },
  {
     id:"B188",
     enfermedad:"Otras hepatitis virales cronicas"
  },
  {
     id:"B189",
     enfermedad:"Hepatitis viral cronica, sin otra especificacion"
  },
  {
     id:"B19",
     enfermedad:"Hepatitis viral, sin otra especificación"
  },
  {
     id:"B190",
     enfermedad:"Hepatitis viral no especificada con coma"
  },
  {
     id:"B199",
     enfermedad:"Hepatitis viral no especificada sin coma"
  },
  {
     id:"B20",
     enfermedad:"Enfermedad por virus de la inmunodeficiencia humana [VIH], resultante en enfermedades infecciosas y parasitarias"
  },
  {
     id:"B200",
     enfermedad:"Enfermedad por el vih, resultante en infeccion por micobacterias"
  },
  {
     id:"B201",
     enfermedad:"Enfermedad por el vih, resultante en otras infecciones bacterianas"
  },
  {
     id:"B202",
     enfermedad:"Enfermedad por vih, resultante en enfermedad por citomegalovirus"
  },
  {
     id:"B203",
     enfermedad:"Enfermedad por vih, resultante en otras infecciones virales"
  },
  {
     id:"B204",
     enfermedad:"Enfermedad por vih, resultante en candidiasis"
  },
  {
     id:"B205",
     enfermedad:"Enfermedad por vih, resultante en otras micosis"
  },
  {
     id:"B206",
     enfermedad:"Enfermedad por vih, resultante en neumonia por pneumocystis carinii"
  },
  {
     id:"B207",
     enfermedad:"Enfermedad por vih, resultante en infecciones multiples"
  },
  {
     id:"B208",
     enfermedad:"Enfermedad por vih, resultante en otras enfermedades infecciosas o parasitarias"
  },
  {
     id:"B209",
     enfermedad:"Enfermedad por vih, resultante en enfermedad infecciosa o parasitaria no especificada"
  },
  {
     id:"B21",
     enfermedad:"Enfermedad por virus de la inmunodeficiencia humana [VIH], resultante en tumores malignos"
  },
  {
     id:"B210",
     enfermedad:"Enfermedad por vih, resultante en sarcoma de kaposi"
  },
  {
     id:"B211",
     enfermedad:"Enfermedad por vih, resultante en linfoma de burkitt"
  },
  {
     id:"B212",
     enfermedad:"Enfermedad por vih, resultante en otros tipos de linfoma no hodgkin"
  },
  {
     id:"B213",
     enfermedad:"Enfermedad por vih, resultante en otros tumores malignos del tejido linfoide, hematopoyetico y tejidos relacionados"
  },
  {
     id:"B217",
     enfermedad:"Enfermedad por vih, resultante en tumores malignos multiples"
  },
  {
     id:"B218",
     enfermedad:"Enfermedad por vih, resultante en otros tumores malignos"
  },
  {
     id:"B219",
     enfermedad:"Enfermedad por vih, resultante en tumores malignos no especificados"
  },
  {
     id:"B22",
     enfermedad:"Enfermedad por virus de la inmunodeficiencia humana [VIH], resultante en otras enfermedades especificadas"
  },
  {
     id:"B220",
     enfermedad:"Enfermedad por vih, resultante en encefalopatia"
  },
  {
     id:"B221",
     enfermedad:"Enfermedad por vih, resultante en neumonitis linfoide intersticial"
  },
  {
     id:"B222",
     enfermedad:"Enfermedad por vih, resultante en sindrome caquectico"
  },
  {
     id:"B227",
     enfermedad:"Enfermedad por vih, resultante en enfermedades multiples clasificadas en otra parte"
  },
  {
     id:"B23",
     enfermedad:"Enfermedad por virus de la inmunodeficiencia humana [VIH], resultante en otras afecciones"
  },
  {
     id:"B230",
     enfermedad:"Sindrome de infeccion aguda debida a vih"
  },
  {
     id:"B231",
     enfermedad:"Enfermedad por vih, resultante en linfadenopatia generalizada (persistente)"
  },
  {
     id:"B232",
     enfermedad:"Enfermedad por vih, resultante en anormalidades inmunologicas y hematologicas, no clasificadas en otra parte"
  },
  {
     id:"B238",
     enfermedad:"Enfermedad por vih, resultante en otras afecciones especificadas"
  },
  {
     id:"B24",
     enfermedad:"Enfermedad por virus de la inmunodeficiencia humana (vih), sin otra especificacion"
  },
  {
     id:"B25",
     enfermedad:"Enfermedad debida a virus citomegálico"
  },
  {
     id:"B250",
     enfermedad:"Neumonitis debida a virus citomegalico (j17.1)"
  },
  {
     id:"B251",
     enfermedad:"Hepatitis debida a virus citomegalico"
  },
  {
     id:"B252",
     enfermedad:"Pancreatitis debida a virus citomegalico"
  },
  {
     id:"B258",
     enfermedad:"Otras enfermedades debidas a virus citomegalico"
  },
  {
     id:"B259",
     enfermedad:"Enfermedad por virus citomegalico, no especificada"
  },
  {
     id:"B26",
     enfermedad:"Parotiditis infecciosa"
  },
  {
     id:"B260",
     enfermedad:"Orquitis por parotiditis (n51.1)"
  },
  {
     id:"B261",
     enfermedad:"Meningitis por parotiditis (g02.0)"
  },
  {
     id:"B262",
     enfermedad:"Encefalitis por parotiditis (g05.1)"
  },
  {
     id:"B263",
     enfermedad:"Pancreatitis por parotiditis (k87.1)"
  },
  {
     id:"B268",
     enfermedad:"Parotiditis infecciosa con otras complicaciones"
  },
  {
     id:"B269",
     enfermedad:"Parotiditis, sin complicaciones"
  },
  {
     id:"B27",
     enfermedad:"Mononucleosis infecciosa"
  },
  {
     id:"B270",
     enfermedad:"Mononucleosis debida a herpes virus gamma"
  },
  {
     id:"B271",
     enfermedad:"Mononucleosis por citomegalovirus"
  },
  {
     id:"B278",
     enfermedad:"Otras mononucleosis infecciosas"
  },
  {
     id:"B279",
     enfermedad:"Mononucleosis infecciosa, no especificada"
  },
  {
     id:"B30",
     enfermedad:"Conjuntivitis viral"
  },
  {
     id:"B300",
     enfermedad:"Queratoconjuntivitis debida a adenovirus (h19.2)"
  },
  {
     id:"B301",
     enfermedad:"Conjuntivitis debida a adenovirus (h13.1)"
  },
  {
     id:"B302",
     enfermedad:"Faringoconjuntivitis viral"
  },
  {
     id:"B303",
     enfermedad:"Conjuntivitis epidemica aguda hemorragica (enterovirica) (h13.1)"
  },
  {
     id:"B308",
     enfermedad:"Otras conjuntivitis virales (h13.1)"
  },
  {
     id:"B309",
     enfermedad:"Conjuntivitis viral, sin otra especificacion"
  },
  {
     id:"B33",
     enfermedad:"Otras enfermedades virales, no clasificadas en otra parte"
  },
  {
     id:"B330",
     enfermedad:"Mialgia epidemica"
  },
  {
     id:"B331",
     enfermedad:"Enfermedad del rio ross"
  },
  {
     id:"B332",
     enfermedad:"Carditis viral"
  },
  {
     id:"B333",
     enfermedad:"Infecciones debidas a retrovirus, no clasificadas en otra parte"
  },
  {
     id:"B338",
     enfermedad:"Otras enfermedades virales especificadas"
  },
  {
     id:"B34",
     enfermedad:"Infección viral de sitio no especificado"
  },
  {
     id:"B340",
     enfermedad:"Infeccion debida a adenovirus, sin otra especificacion"
  },
  {
     id:"B341",
     enfermedad:"Infeccion debida a enterovirus, sin otra especificacion"
  },
  {
     id:"B342",
     enfermedad:"Infeccion debida a coronavirus, sin otra especificacion"
  },
  {
     id:"B343",
     enfermedad:"Infeccion debida a parvovirus, sin otra especificacion"
  },
  {
     id:"B344",
     enfermedad:"Infeccion debida a papovavirus, sin otra especificacion"
  },
  {
     id:"B348",
     enfermedad:"Otras infecciones virales de sitio no especificado"
  },
  {
     id:"B349",
     enfermedad:"Infeccion viral, no especificada"
  },
  {
     id:"B35",
     enfermedad:"Dermatofitosis"
  },
  {
     id:"B350",
     enfermedad:"Tiña de la barba y del cuero cabelludo"
  },
  {
     id:"B351",
     enfermedad:"Tiña de las uñas"
  },
  {
     id:"B352",
     enfermedad:"Tiña de la mano"
  },
  {
     id:"B353",
     enfermedad:"Tiña del pie [tinea pedis]"
  },
  {
     id:"B354",
     enfermedad:"Tiña del cuerpo [tinea corporis]"
  },
  {
     id:"B355",
     enfermedad:"Tiña imbricada [tinea imbricata]"
  },
  {
     id:"B356",
     enfermedad:"Tiña inguinal [tinea cruris]"
  },
  {
     id:"B358",
     enfermedad:"Otras dermatofitosis"
  },
  {
     id:"B359",
     enfermedad:"Dermatofitosis, no especificada"
  },
  {
     id:"B36",
     enfermedad:"Otras micosis superficiales"
  },
  {
     id:"B360",
     enfermedad:"Pitiriasis versicolor"
  },
  {
     id:"B361",
     enfermedad:"Tiña negra"
  },
  {
     id:"B362",
     enfermedad:"Piedra blanca"
  },
  {
     id:"B363",
     enfermedad:"Piedra negra"
  },
  {
     id:"B368",
     enfermedad:"Otras micosis superficiales especificadas"
  },
  {
     id:"B369",
     enfermedad:"Micosis superficial, sin otra especificacion"
  },
  {
     id:"B37",
     enfermedad:"Candidiasis"
  },
  {
     id:"B370",
     enfermedad:"Estomatitis candidiasica"
  },
  {
     id:"B371",
     enfermedad:"Candidiasis pulmonar"
  },
  {
     id:"B372",
     enfermedad:"Candidiasis de la piel y de las uñas"
  },
  {
     id:"B373",
     enfermedad:"Candidiasis de la vulva y de la vagina (n77.1)"
  },
  {
     id:"B374",
     enfermedad:"Candidiasis de otras localizaciones urogenitales"
  },
  {
     id:"B375",
     enfermedad:"Meningitis debida a candida (g02.1)"
  },
  {
     id:"B376",
     enfermedad:"Endocarditis debida a candida (i39.8)"
  },
  {
     id:"B377",
     enfermedad:"Septicemia debida a candida"
  },
  {
     id:"B378",
     enfermedad:"Candidiasis de otros sitios"
  },
  {
     id:"B379",
     enfermedad:"Candidiasis, no especificada"
  },
  {
     id:"B38",
     enfermedad:"Coccidioidomicosis"
  },
  {
     id:"B380",
     enfermedad:"Coccidioidomicosis pulmonar aguda"
  },
  {
     id:"B381",
     enfermedad:"Coccidioidomicosis pulmonar cronica"
  },
  {
     id:"B382",
     enfermedad:"Coccidioidomicosis pulmonar, sin otra especificacion"
  },
  {
     id:"B383",
     enfermedad:"Coccidioidomicosis cutanea"
  },
  {
     id:"B384",
     enfermedad:"Meningitis debida a coccidioidomicosis (g02.1)"
  },
  {
     id:"B387",
     enfermedad:"Coccidioidomicosis diseminada"
  },
  {
     id:"B388",
     enfermedad:"Otras formas de coccidioidomicosis"
  },
  {
     id:"B389",
     enfermedad:"Coccidioidomicosis, no especificada"
  },
  {
     id:"B39",
     enfermedad:"Histoplasmosis"
  },
  {
     id:"B390",
     enfermedad:"Infeccion pulmonar aguda debida a histoplasma capsulatum"
  },
  {
     id:"B391",
     enfermedad:"Infeccion pulmonar cronica debida a histoplasma capsulatum"
  },
  {
     id:"B392",
     enfermedad:"Infeccion pulmonar debida a histoplasma capsulatum, sin otra especificacion"
  },
  {
     id:"B393",
     enfermedad:"Infeccion diseminada debida a histoplasma capsulatum"
  },
  {
     id:"B394",
     enfermedad:"Histoplasmosis debida a histoplasma capsulatum, sin otra especificacion"
  },
  {
     id:"B395",
     enfermedad:"Infeccion debida a histoplasma duboisii"
  },
  {
     id:"B399",
     enfermedad:"Histoplasmosis, no especificada"
  },
  {
     id:"B40",
     enfermedad:"Blastomicosis"
  },
  {
     id:"B400",
     enfermedad:"Blastomicosis pulmonar aguda"
  },
  {
     id:"B401",
     enfermedad:"Blastomicosis pulmonar cronica"
  },
  {
     id:"B402",
     enfermedad:"Blastomicosis pulmonar, sin otra especificacion"
  },
  {
     id:"B403",
     enfermedad:"Blastomicosis cutanea"
  },
  {
     id:"B407",
     enfermedad:"Blastomicosis diseminada"
  },
  {
     id:"B408",
     enfermedad:"Otras formas de blastomicosis"
  },
  {
     id:"B409",
     enfermedad:"Blastomicosis, no especificada"
  },
  {
     id:"B41",
     enfermedad:"Paracoccidioidomicosis"
  },
  {
     id:"B410",
     enfermedad:"Paracoccidioidomicosis pulmonar"
  },
  {
     id:"B417",
     enfermedad:"Paracoccidioidomicosis diseminada"
  },
  {
     id:"B418",
     enfermedad:"Otras formas paracoccidioidomicosis"
  },
  {
     id:"B419",
     enfermedad:"Paracoccidioidomicosis, no especificada"
  },
  {
     id:"B42",
     enfermedad:"Esporotricosis"
  },
  {
     id:"B420",
     enfermedad:"Esporotricosis pulmonar (j99.8)"
  },
  {
     id:"B421",
     enfermedad:"Esporotricosis linfocutanea"
  },
  {
     id:"B427",
     enfermedad:"Esporotricosis diseminada"
  },
  {
     id:"B428",
     enfermedad:"Otras formas de esporotricosis"
  },
  {
     id:"B429",
     enfermedad:"Esporotricosis, no especificada"
  },
  {
     id:"B43",
     enfermedad:"Cromomicosis y absceso feomicótico"
  },
  {
     id:"B430",
     enfermedad:"Cromomicosis cutanea"
  },
  {
     id:"B431",
     enfermedad:"Absceso cerebral feomicotico"
  },
  {
     id:"B432",
     enfermedad:"Absceso y quiste subcutaneo feomicotico"
  },
  {
     id:"B438",
     enfermedad:"Otras formas de cromomicosis"
  },
  {
     id:"B439",
     enfermedad:"Cromomicosis, no especificada"
  },
  {
     id:"B44",
     enfermedad:"Aspergilosis"
  },
  {
     id:"B440",
     enfermedad:"Aspergilosis pulmonar invasiva"
  },
  {
     id:"B441",
     enfermedad:"Otras aspergilosis pulmonares"
  },
  {
     id:"B442",
     enfermedad:"Aspergilosis amigdalina"
  },
  {
     id:"B447",
     enfermedad:"Aspergilosis diseminada"
  },
  {
     id:"B448",
     enfermedad:"Otras formas de aspergilosis"
  },
  {
     id:"B449",
     enfermedad:"Aspergilosis, no especificada"
  },
  {
     id:"B45",
     enfermedad:"Criptococosis"
  },
  {
     id:"B450",
     enfermedad:"Criptococosis pulmonar"
  },
  {
     id:"B451",
     enfermedad:"Criptococosis cerebral"
  },
  {
     id:"B452",
     enfermedad:"Criptococosis cutanea"
  },
  {
     id:"B453",
     enfermedad:"Criptococosis osea"
  },
  {
     id:"B457",
     enfermedad:"Criptococosis diseminada"
  },
  {
     id:"B458",
     enfermedad:"Otras formas de criptococosis"
  },
  {
     id:"B459",
     enfermedad:"Criptococosis, no especificada"
  },
  {
     id:"B46",
     enfermedad:"Cigomicosis"
  },
  {
     id:"B460",
     enfermedad:"Mucormicosis pulmonar"
  },
  {
     id:"B461",
     enfermedad:"Mucormicosis rinocerebral"
  },
  {
     id:"B462",
     enfermedad:"Mucormicosis gastrointestinal"
  },
  {
     id:"B463",
     enfermedad:"Mucormicosis cutanea"
  },
  {
     id:"B464",
     enfermedad:"Mucormicosis diseminada"
  },
  {
     id:"B465",
     enfermedad:"Mucormicosis, sin otra especificacion"
  },
  {
     id:"B468",
     enfermedad:"Otras cigomicosis"
  },
  {
     id:"B469",
     enfermedad:"Cigomicosis, no especificada"
  },
  {
     id:"B47",
     enfermedad:"Micetoma"
  },
  {
     id:"B470",
     enfermedad:"Eumicetoma"
  },
  {
     id:"B471",
     enfermedad:"Actinomicetoma"
  },
  {
     id:"B479",
     enfermedad:"Micetoma, no especificado"
  },
  {
     id:"B48",
     enfermedad:"Otras micosis, no clasificadas en otra parte"
  },
  {
     id:"B480",
     enfermedad:"Lobomicosis"
  },
  {
     id:"B481",
     enfermedad:"Rinosporidiosis"
  },
  {
     id:"B482",
     enfermedad:"Alesqueriasis"
  },
  {
     id:"B483",
     enfermedad:"Geotricosis"
  },
  {
     id:"B484",
     enfermedad:"Penicilosis"
  },
  {
     id:"B487",
     enfermedad:"Micosis oportunistas"
  },
  {
     id:"B488",
     enfermedad:"Otras micosis especificadas"
  },
  {
     id:"B49",
     enfermedad:"Micosis, no especificada"
  },
  {
     id:"B50",
     enfermedad:"Paludismo [malaria] debido a Plasmodium falciparum"
  },
  {
     id:"B500",
     enfermedad:"Paludismo debido a plasmodium falciparum con complicaciones cerebrales"
  },
  {
     id:"B508",
     enfermedad:"Otro paludismo grave y complicado debido a plasmodium falciparum"
  },
  {
     id:"B509",
     enfermedad:"Paludismo debido a plasmodium falciparum, sin otra especificacion"
  },
  {
     id:"B51",
     enfermedad:"Paludismo [malaria] debido a Plasmodium vivax"
  },
  {
     id:"B510",
     enfermedad:"Paludismo debido a plasmodium vivax con ruptura esplenica"
  },
  {
     id:"B518",
     enfermedad:"Paludismo debido a plasmodium vivax con otras complicaciones"
  },
  {
     id:"B519",
     enfermedad:"Paludismo debido a plasmodium vivax, sin complicaciones"
  },
  {
     id:"B52",
     enfermedad:"Paludismo (malaria) debido a Plasmodium malariae"
  },
  {
     id:"B520",
     enfermedad:"Paludismo debido a plasmodium malariae con nefropatia"
  },
  {
     id:"B528",
     enfermedad:"Paludismo debido a plasmodium malariae con otras complicaciones"
  },
  {
     id:"B529",
     enfermedad:"Paludismo debido a plasmodium malariae, sin complicaciones"
  },
  {
     id:"B53",
     enfermedad:"Otro paludismo [malaria] confirmado parasitológicamente"
  },
  {
     id:"B530",
     enfermedad:"Paludismo debido a plasmodium avale"
  },
  {
     id:"B531",
     enfermedad:"Paludismo debido a plasmodios de los simios"
  },
  {
     id:"B538",
     enfermedad:"Otro paludismo confirmado parasitologicamente, no clasificado en otra parte"
  },
  {
     id:"B54",
     enfermedad:"Paludismo [malaria] no especificado"
  },
  {
     id:"B55",
     enfermedad:"Leishmaniasis"
  },
  {
     id:"B550",
     enfermedad:"Leiishmaniasis visceral"
  },
  {
     id:"B551",
     enfermedad:"Leishmaniasis cutanea"
  },
  {
     id:"B552",
     enfermedad:"Leishmaniasis mucocutanea"
  },
  {
     id:"B559",
     enfermedad:"Leishmaniasis, no especificada"
  },
  {
     id:"B56",
     enfermedad:"Tripanosomiasis africana"
  },
  {
     id:"B560",
     enfermedad:"Tripanosomiasis gambiense"
  },
  {
     id:"B561",
     enfermedad:"Tripanosomiasis rhodesiense"
  },
  {
     id:"B569",
     enfermedad:"Tripanosomiasis africana, sin otra especificacion"
  },
  {
     id:"B57",
     enfermedad:"Enfermedad de Chagas"
  },
  {
     id:"B570",
     enfermedad:"Enfermedad de chagas aguda que afecta al corazon (141.2, 198.1)"
  },
  {
     id:"B571",
     enfermedad:"Enfermedad de chagas aguda que no afecta al corazon"
  },
  {
     id:"B572",
     enfermedad:"Enfermedad de chagas (cronica) que afecta al corazon"
  },
  {
     id:"B573",
     enfermedad:"Enfermedad de chagas (cronica) que afecta al sistema digestivo"
  },
  {
     id:"B574",
     enfermedad:"Enfermedad de chagas (cronica) que afecta al sistema nervioso"
  },
  {
     id:"B575",
     enfermedad:"Enfermedad de chagas (cronica) que afecta otros organos"
  },
  {
     id:"B58",
     enfermedad:"Toxoplasmosis"
  },
  {
     id:"B580",
     enfermedad:"Oculopatia debida a toxoplasma"
  },
  {
     id:"B581",
     enfermedad:"Hepatitis debida a toxoplasma (k77.0)"
  },
  {
     id:"B582",
     enfermedad:"Meningoencefalitis debida a toxoplasma (g05.2)"
  },
  {
     id:"B583",
     enfermedad:"Toxoplasmosis pulmonar (j17.3)"
  },
  {
     id:"B588",
     enfermedad:"Toxoplasmosis con otro organo afectado"
  },
  {
     id:"B589",
     enfermedad:"Toxoplasmosis, no especificada"
  },
  {
     id:"B59",
     enfermedad:"Neumocistosis (j17.3)"
  },
  {
     id:"B60",
     enfermedad:"Otras enfermedades debidas a protozoarios, no clasificadas en otra parte"
  },
  {
     id:"B600",
     enfermedad:"Babesiosis"
  },
  {
     id:"B601",
     enfermedad:"Acantamebiasis"
  },
  {
     id:"B602",
     enfermedad:"Naegleriasis"
  },
  {
     id:"B608",
     enfermedad:"Otras enfermedades especificadas debidas a protozarios"
  },
  {
     id:"B64",
     enfermedad:"Enfermedad debida a protozoarios, no especificada"
  },
  {
     id:"B65",
     enfermedad:"Esquistosomiasis [bilharziasis]"
  },
  {
     id:"B650",
     enfermedad:"Esquistosomiasis debida a schistosoma haematobium [esquistosomiasis urinaria]"
  },
  {
     id:"B651",
     enfermedad:"Esquistosomiasis debida a schistosoma mansoni [esquistosomiasis intestinal]"
  },
  {
     id:"B652",
     enfermedad:"Esquistosomiasis debida a schistosoma japonicum"
  },
  {
     id:"B653",
     enfermedad:"Dermatitis por cercarias"
  },
  {
     id:"B658",
     enfermedad:"Otras esquistosomiasis"
  },
  {
     id:"B659",
     enfermedad:"Esquistosomiasis, no especificada"
  },
  {
     id:"B66",
     enfermedad:"Otras infecciones debidas a trematodos"
  },
  {
     id:"B660",
     enfermedad:"Opistorquiasis"
  },
  {
     id:"B661",
     enfermedad:"Clonorquiasis"
  },
  {
     id:"B662",
     enfermedad:"Dicrocoeliasis"
  },
  {
     id:"B663",
     enfermedad:"Fascioliasis"
  },
  {
     id:"B664",
     enfermedad:"Paragonimiasis"
  },
  {
     id:"B665",
     enfermedad:"Fasciolopsiasis"
  },
  {
     id:"B668",
     enfermedad:"Otras infecciones especificadas debidas a trematodos"
  },
  {
     id:"B669",
     enfermedad:"Infeccion debida a trematodos, no especificada"
  },
  {
     id:"B67",
     enfermedad:"Esquinococosis"
  },
  {
     id:"B670",
     enfermedad:"Infeccion del higado debida a echinococcus granulosus"
  },
  {
     id:"B671",
     enfermedad:"Infeccion del pulmon debida a echinococcus granulosus"
  },
  {
     id:"B672",
     enfermedad:"Infeccion de hueso debida a echinococcus granulosus"
  },
  {
     id:"B673",
     enfermedad:"Infeccion de otro organo y de sitios multiples debida a echinococcus granulosus"
  },
  {
     id:"B674",
     enfermedad:"Infeccion debida a echinococcus granulosus, sin otra especificacion"
  },
  {
     id:"B675",
     enfermedad:"Infeccion del higado debida a echinococcus multilocularis"
  },
  {
     id:"B676",
     enfermedad:"Infeccion de otro organo y de sitios multiples debida a echinococcus multilocularis"
  },
  {
     id:"B677",
     enfermedad:"Infeccion debida a echinococcus multilocularis, sin otra especificacion"
  },
  {
     id:"B678",
     enfermedad:"Equinococosis del higado, no especificada"
  },
  {
     id:"B679",
     enfermedad:"Equinococosis, otra y la no especificada"
  },
  {
     id:"B68",
     enfermedad:"Teniasis"
  },
  {
     id:"B680",
     enfermedad:"Teniasis debida a taenia solium"
  },
  {
     id:"B681",
     enfermedad:"Infeccion debida a taenia saginata"
  },
  {
     id:"B689",
     enfermedad:"Teniasis, no especificada"
  },
  {
     id:"B69",
     enfermedad:"Cisticercosis"
  },
  {
     id:"B690",
     enfermedad:"Cisticercosis del sistema nervioso central"
  },
  {
     id:"B691",
     enfermedad:"Cisticercosis del ojo"
  },
  {
     id:"B698",
     enfermedad:"Cisticercosis de otros sitios"
  },
  {
     id:"B699",
     enfermedad:"Cisticercosis, no especificada"
  },
  {
     id:"B70",
     enfermedad:"Difilobotriasis y esparganosis"
  },
  {
     id:"B700",
     enfermedad:"Difilobotriasis intestinal"
  },
  {
     id:"B701",
     enfermedad:"Esparganosis"
  },
  {
     id:"B71",
     enfermedad:"Otras infecciones debidas a cestodos"
  },
  {
     id:"B710",
     enfermedad:"Himenolepiasis"
  },
  {
     id:"B711",
     enfermedad:"Dipilidiasis"
  },
  {
     id:"B718",
     enfermedad:"Otras infecciones debidas a cestodos especificadas"
  },
  {
     id:"B719",
     enfermedad:"Infeccion debida a cestodos, no especificada"
  },
  {
     id:"B72",
     enfermedad:"Dracontiasis"
  },
  {
     id:"B73",
     enfermedad:"Oncocercosis"
  },
  {
     id:"B74",
     enfermedad:"Filariasis"
  },
  {
     id:"B740",
     enfermedad:"Filariasis debida a wuchereria bancrofti"
  },
  {
     id:"B741",
     enfermedad:"Filariasis debida a brugia malayi"
  },
  {
     id:"B742",
     enfermedad:"Filariasis debida a brugia timori"
  },
  {
     id:"B743",
     enfermedad:"Loaiasis"
  },
  {
     id:"B744",
     enfermedad:"Mansoneliasis"
  },
  {
     id:"B748",
     enfermedad:"Otras filariasis"
  },
  {
     id:"B749",
     enfermedad:"Filariasis, no especificada"
  },
  {
     id:"B75",
     enfermedad:"Triquinosis"
  },
  {
     id:"B76",
     enfermedad:"Anquilostomiasis y necatoriasis"
  },
  {
     id:"B760",
     enfermedad:"Anquilostomiasis"
  },
  {
     id:"B761",
     enfermedad:"Necatoriasis"
  },
  {
     id:"B768",
     enfermedad:"Otras enfermedades debidas a anquilostomas"
  },
  {
     id:"B769",
     enfermedad:"Enfermedad debida a anquilostomas, no especificada"
  },
  {
     id:"B77",
     enfermedad:"Ascariasis"
  },
  {
     id:"B770",
     enfermedad:"Ascariasis con complicaciones intestinales"
  },
  {
     id:"B778",
     enfermedad:"Ascariasis con otras complicaciones"
  },
  {
     id:"B779",
     enfermedad:"Ascariasis, no especificada"
  },
  {
     id:"B78",
     enfermedad:"Estrongiloidiasis"
  },
  {
     id:"B780",
     enfermedad:"Estrongiloidiasis intestinal"
  },
  {
     id:"B781",
     enfermedad:"Estrongiloidiasis cutanea"
  },
  {
     id:"B787",
     enfermedad:"Estrongiloidiasis diseminada"
  },
  {
     id:"B789",
     enfermedad:"Estrongiloidiasis, no especificada"
  },
  {
     id:"B79",
     enfermedad:"Tricuriasis"
  },
  {
     id:"B80",
     enfermedad:"Enterobiasis"
  },
  {
     id:"B81",
     enfermedad:"Otras helmintiasis intestinales, no clasificadas en otra parte"
  },
  {
     id:"B810",
     enfermedad:"Anisaquiasis"
  },
  {
     id:"B811",
     enfermedad:"Capilariasis intestinal"
  },
  {
     id:"B812",
     enfermedad:"Tricoestrongiliasis"
  },
  {
     id:"B813",
     enfermedad:"Angioestrongiliasis intestinal"
  },
  {
     id:"B814",
     enfermedad:"Helmintiasis intestinal mixta"
  },
  {
     id:"B818",
     enfermedad:"Otras helmintiasis intestinales especificadas"
  },
  {
     id:"B82",
     enfermedad:"Parasitosis intestinal, sin otra especificación"
  },
  {
     id:"B820",
     enfermedad:"Helmintiasis intestinal, sin otra especificacion"
  },
  {
     id:"B829",
     enfermedad:"Parasitosis intestinal, sin otra especificacion"
  },
  {
     id:"B83",
     enfermedad:"Otros helmintiasis"
  },
  {
     id:"B830",
     enfermedad:"Larva migrans visceral"
  },
  {
     id:"B831",
     enfermedad:"Gnatostomiasis"
  },
  {
     id:"B832",
     enfermedad:"Angioestrongiliasis debida a parastrongylus cantonensis"
  },
  {
     id:"B833",
     enfermedad:"Singamiasis"
  },
  {
     id:"B834",
     enfermedad:"Hirudiniasis interna"
  },
  {
     id:"B838",
     enfermedad:"Otras helmintiasis especificadas"
  },
  {
     id:"B839",
     enfermedad:"Helmintiasis, no especificada"
  },
  {
     id:"B85",
     enfermedad:"Pediculosis y phthiriasis"
  },
  {
     id:"B850",
     enfermedad:"Pediculosis debida a pediculus humanus capitis"
  },
  {
     id:"B851",
     enfermedad:"Pediculosis debida a pediculus humanus corporis"
  },
  {
     id:"B852",
     enfermedad:"Pediculosis, sin otra especificacion"
  },
  {
     id:"B853",
     enfermedad:"Phthiriasis"
  },
  {
     id:"B854",
     enfermedad:"Pediculosis y phthiriasis mixtas"
  },
  {
     id:"B86",
     enfermedad:"Escabiosis"
  },
  {
     id:"B87",
     enfermedad:"Miasis"
  },
  {
     id:"B870",
     enfermedad:"Miasis cutanea"
  },
  {
     id:"B871",
     enfermedad:"Miasis en heridas"
  },
  {
     id:"B872",
     enfermedad:"Miasis ocular"
  },
  {
     id:"B873",
     enfermedad:"Miasis nasofaringea"
  },
  {
     id:"B874",
     enfermedad:"Miasis aural"
  },
  {
     id:"B878",
     enfermedad:"Miasis de otros sitios"
  },
  {
     id:"B879",
     enfermedad:"Miasis, no especificada"
  },
  {
     id:"B88",
     enfermedad:"Otras infestaciones"
  },
  {
     id:"B880",
     enfermedad:"Otras acariasis"
  },
  {
     id:"B881",
     enfermedad:"Tungiasis [infeccion debida a pulga de arena]"
  },
  {
     id:"B882",
     enfermedad:"Otras infestaciones debidas a artropodos"
  },
  {
     id:"B883",
     enfermedad:"Hirudiniasis externa"
  },
  {
     id:"B888",
     enfermedad:"Otras infestaciones especificas"
  },
  {
     id:"B889",
     enfermedad:"Infestacion, no especificada"
  },
  {
     id:"B89",
     enfermedad:"Enfermedad parasitaria, no especificada"
  },
  {
     id:"B90",
     enfermedad:"Secuelas de tuberculosis"
  },
  {
     id:"B900",
     enfermedad:"Secuelas de tuberculosis del sistema nervioso central"
  },
  {
     id:"B901",
     enfermedad:"Secuelas de tuberculosis genitourinaria"
  },
  {
     id:"B902",
     enfermedad:"Secuelas de tuberculosis de huesos y articulaciones"
  },
  {
     id:"B908",
     enfermedad:"Secuelas de tuberculosis de otros organos especificados"
  },
  {
     id:"B91",
     enfermedad:"Secuelas de poliomielitis"
  },
  {
     id:"B92",
     enfermedad:"Secuelas de lepra"
  },
  {
     id:"B94",
     enfermedad:"Secuelas de otras enfermedades infecciosas y parasitarias de los no especificadas"
  },
  {
     id:"B940",
     enfermedad:"Secuelas de tracoma"
  },
  {
     id:"B941",
     enfermedad:"Secuelas de encefalitis viral"
  },
  {
     id:"B942",
     enfermedad:"Secuelas de hepatitis viral"
  },
  {
     id:"B948",
     enfermedad:"Secuelas de otras enfermedades infecciosas y parasitarias especificadas"
  },
  {
     id:"B949",
     enfermedad:"Secuelas de enfermedades infecciosas y parasitarias no especificadas"
  },
  {
     id:"B95",
     enfermedad:"Estreptococos y estafilococos como causa de enfermedades clasificadas en otros capitulos"
  },
  {
     id:"B950",
     enfermedad:"Estreptococo, grupo a, como causa de enfermedades clasificadas en otros capitulos"
  },
  {
     id:"B951",
     enfermedad:"Estreptococo, grupo b, como causa de enfermedades clasificadas en otros capitulos"
  },
  {
     id:"B952",
     enfermedad:"Estreptococo, grupo d, como causa de enfermedades clasificadas en otros capitulos"
  },
  {
     id:"B953",
     enfermedad:"Streptococcus pneumoniae como causa de enfermedades clasificadas en otros capitulos"
  },
  {
     id:"B954",
     enfermedad:"Otros streptococos como causa de enfermedades clasificadas en otros capitulos"
  },
  {
     id:"B955",
     enfermedad:"Estreptococo no especificado como causa de enfermedades clasificadas en otros capitulos"
  },
  {
     id:"B956",
     enfermedad:"Staphylococcus aureus como causa de enfermedades clasificadas en otros capitulos"
  },
  {
     id:"B957",
     enfermedad:"Otros estafilococos como causa de enfermedades clasificadas en otros capitulos"
  },
  {
     id:"B958",
     enfermedad:"Estafilococo no especificado, como causa de enfermedades clasificadas en otros capitulos"
  },
  {
     id:"B96",
     enfermedad:"Otros agentes bacterianos como causa de enfermedades clasificadas en otros capitulos"
  },
  {
     id:"B960",
     enfermedad:"Mycoplasma pneumoniae [m. pneumoniae] como causa de enfermedades clasificadas en otros capitulos"
  },
  {
     id:"B961",
     enfermedad:"Klebsiella pneumoniae [k. pneumoniae] como causa de enfermedades clasificadas en otros capitulos"
  },
  {
     id:"B962",
     enfermedad:"Escherichia coli [e. coli] como causa de enfermedades clasificadas en otros capitulos"
  },
  {
     id:"B963",
     enfermedad:"Haemophilus influenzae [h. influenzae] como causa de enfermedades clasificadas en otros capitulos"
  },
  {
     id:"B964",
     enfermedad:"Proteus (mirabilis) (morganii) como causa de enfermedades clasificadas en otros capitulos"
  },
  {
     id:"B965",
     enfermedad:"Pseudomonas (aeruginosa) (mallei) (pseudomallei) como causa de enfermedades clasificadas en otros capitulos"
  },
  {
     id:"B966",
     enfermedad:"Bacillus fragilis [b. fragilis] como causa de enfermedades clasificadas en otros capitulos"
  },
  {
     id:"B967",
     enfermedad:"Clostridium perfringens [c. perfringens] como causa de enfermedades clasificadas en otros capitulos"
  },
  {
     id:"B968",
     enfermedad:"Otros agentes bacterianos especificados como causa de enfermedades clasificadas en otros capitulos"
  },
  {
     id:"B97",
     enfermedad:"Agentes virales como causa de enfermedades clasificadas en otros capitulos"
  },
  {
     id:"B970",
     enfermedad:"Adenovirus como causa de enfermedades clasificadas en otros capitulos"
  },
  {
     id:"B971",
     enfermedad:"Enterovirus como causa de enfermedades clasificadas en otros capitulos"
  },
  {
     id:"B972",
     enfermedad:"Coronavirus como causa de enfermedades clasificadas en otros capitulos"
  },
  {
     id:"B973",
     enfermedad:"Retrovirus como causa de enfermedades clasificadas en otros capitulos"
  },
  {
     id:"B974",
     enfermedad:"Virus sincicial respiratorio como causa de enfermedades clasificadas en otros capitulos"
  },
  {
     id:"B975",
     enfermedad:"Reovirus como causa de enfermedades clasificadas en otros capitulos"
  },
  {
     id:"B976",
     enfermedad:"Parvovirus como causa de enfermedades clasificadas en otros capitulos"
  },
  {
     id:"B977",
     enfermedad:"Papilomavirus como causa de enfermedades clasificadas en otros capitulos"
  },
  {
     id:"B978",
     enfermedad:"Otros agentes virales como causa de enfermedades clasificadas en otros capitulos"
  },
  {
     id:"B99",
     enfermedad:"Otras enfermedades infecciosas y las no especificadas"
  },
  {
     id:"C00",
     enfermedad:"Tumor maligno del labio"
  },
  {
     id:"C000",
     enfermedad:"Tumor maligno del labio superior, cara externa"
  },
  {
     id:"C001",
     enfermedad:"Tumor maligno del labio inferior, cara externa"
  },
  {
     id:"C002",
     enfermedad:"Tumor maligno del labio, cara externa, sin otra especificacion"
  },
  {
     id:"C003",
     enfermedad:"Tumor maligno del labio superior, cara interna"
  },
  {
     id:"C004",
     enfermedad:"Tumor maligno del labio inferior, cara interna"
  },
  {
     id:"C005",
     enfermedad:"Tumor maligno del labio, cara interna, sin otra especificacion"
  },
  {
     id:"C006",
     enfermedad:"Tumor maligno de la comisura labial"
  },
  {
     id:"C008",
     enfermedad:"Lesion de sitios contiguos del labio"
  },
  {
     id:"C009",
     enfermedad:"Tumor maligno del labio, parte no especificada"
  },
  {
     id:"C01",
     enfermedad:"Tumor maligno de la base de la lengua"
  },
  {
     id:"C02",
     enfermedad:"Tumor maligno de otras partes y de las no especificadas de la lengua"
  },
  {
     id:"C020",
     enfermedad:"Tumor maligno de la cara dorsal de la lengua"
  },
  {
     id:"C021",
     enfermedad:"Tumor maligno del borde de la lengua"
  },
  {
     id:"C022",
     enfermedad:"Tumor maligno de la cara ventral de la lengua"
  },
  {
     id:"C023",
     enfermedad:"Tumor maligno de los dos tercios anteriores de la lengua, parte no especificada"
  },
  {
     id:"C024",
     enfermedad:"Tumor maligno de la amigdala lingual"
  },
  {
     id:"C028",
     enfermedad:"Lesion de sitios contiguos de la lengua"
  },
  {
     id:"C029",
     enfermedad:"Tumor maligno de la lengua, parte no especificada"
  },
  {
     id:"C03",
     enfermedad:"Tumor maligno de la encia"
  },
  {
     id:"C030",
     enfermedad:"Tumor maligno de la encia superior"
  },
  {
     id:"C031",
     enfermedad:"Tumor maligno de la encia inferior"
  },
  {
     id:"C039",
     enfermedad:"Tumor maligno de la encia, parte no especificada"
  },
  {
     id:"C04",
     enfermedad:"Tumor maligno del piso de la boca"
  },
  {
     id:"C040",
     enfermedad:"Tumor maligno de la parte anterior del piso de la boca"
  },
  {
     id:"C041",
     enfermedad:"Tumor maligno de la parte lateral del piso de la boca"
  },
  {
     id:"C048",
     enfermedad:"Lesion de sitios contiguos del piso de la boca"
  },
  {
     id:"C049",
     enfermedad:"Tumor maligno del piso de la boca, parte no especificada"
  },
  {
     id:"C05",
     enfermedad:"Tumor maligno del paladar"
  },
  {
     id:"C050",
     enfermedad:"Tumor maligno del paladar duro"
  },
  {
     id:"C051",
     enfermedad:"Tumor maligno del paladar blando"
  },
  {
     id:"C052",
     enfermedad:"Tumor maligno de la uvula"
  },
  {
     id:"C058",
     enfermedad:"Lesion de sitios contiguos del paladar"
  },
  {
     id:"C059",
     enfermedad:"Tumor maligno del paladar, parte no especificada"
  },
  {
     id:"C06",
     enfermedad:"Tumor maligno de otras partes y de las no especificadas de la boca"
  },
  {
     id:"C060",
     enfermedad:"Tumor maligno de la mucosa de la mejilla"
  },
  {
     id:"C061",
     enfermedad:"Tumor maligno del vestibulo de la boca"
  },
  {
     id:"C062",
     enfermedad:"Tumor maligno del area retromolar"
  },
  {
     id:"C068",
     enfermedad:"Lesion de sitios contiguos de otras partes y de las no especificadas de la boca"
  },
  {
     id:"C069",
     enfermedad:"Tumor maligno de la boca, parte no especificada"
  },
  {
     id:"C07",
     enfermedad:"Tumor maligno de la glandula parotida"
  },
  {
     id:"C08",
     enfermedad:"Tumor maligno de otras glandulas salivales mayores y de las no especificadas"
  },
  {
     id:"C080",
     enfermedad:"Tumor maligno de la glandula submaxilar"
  },
  {
     id:"C081",
     enfermedad:"Tumor maligno de la glandula sublingual"
  },
  {
     id:"C088",
     enfermedad:"Lesion de sitios contiguos de las glandulas salivales mayores"
  },
  {
     id:"C089",
     enfermedad:"Tumor maligno de glandula salival mayor, no especificada"
  },
  {
     id:"C09",
     enfermedad:"Tumor maligno de la amigdala"
  },
  {
     id:"C090",
     enfermedad:"Tumor maligno de la fosa amigdalina"
  },
  {
     id:"C091",
     enfermedad:"Tumor maligno del pilar amigdalino (anterior) (posterior)"
  },
  {
     id:"C098",
     enfermedad:"Lesion de sitios contiguos de la amigdala"
  },
  {
     id:"C099",
     enfermedad:"Tumor maligno de la amigdala, parte no especificada"
  },
  {
     id:"C10",
     enfermedad:"Tumor maligno de la orofaringe"
  },
  {
     id:"C100",
     enfermedad:"Tumor maligno de la valecula"
  },
  {
     id:"C101",
     enfermedad:"Tumor maligno de la cara anterior de la epiglotis"
  },
  {
     id:"C102",
     enfermedad:"Tumor maligno de la pared lateral de la orofaringe"
  },
  {
     id:"C103",
     enfermedad:"Tumor maligno de la pared posterior de la orofaringe"
  },
  {
     id:"C104",
     enfermedad:"Tumor maligno de la hendidura branquial"
  },
  {
     id:"C108",
     enfermedad:"Lesion de sitios contiguos de la orofaringe"
  },
  {
     id:"C109",
     enfermedad:"Tumor maligno de la orofaringe, parte no especificada"
  },
  {
     id:"C11",
     enfermedad:"Tumor maligno de la nasofaringe"
  },
  {
     id:"C110",
     enfermedad:"Tumor maligno de la pared superior de la nasofaringe"
  },
  {
     id:"C111",
     enfermedad:"Tumor maligno de la pared posterior de la nasofaringe"
  },
  {
     id:"C112",
     enfermedad:"Tumor maligno de la pared lateral de la nasofaringe"
  },
  {
     id:"C113",
     enfermedad:"Tumor maligno de la pared anterior de la nasofaringe"
  },
  {
     id:"C118",
     enfermedad:"Lesion de sitios contiguos de la nasofaringe"
  },
  {
     id:"C119",
     enfermedad:"Tumor maligno de la nasofaringe, parte no especificada"
  },
  {
     id:"C12",
     enfermedad:"Tumor maligno del seno piriforme"
  },
  {
     id:"C13",
     enfermedad:"Tumor maligno de la hipofaringe"
  },
  {
     id:"C130",
     enfermedad:"Tumor maligno de la region postcricoidea"
  },
  {
     id:"C131",
     enfermedad:"Tumor maligno del pliegue aritenoepiglotico, cara hipofaringea"
  },
  {
     id:"C132",
     enfermedad:"Tumor maligno de la pared posterior de la hipofaringe"
  },
  {
     id:"C138",
     enfermedad:"Lesion de sitios contiguos de la hipofaringe"
  },
  {
     id:"C139",
     enfermedad:"Tumor maligno de la hipofaringe, parte no especificada"
  },
  {
     id:"C14",
     enfermedad:"Tumor maligno de otros sitios y de los mal definidos del labio, de la cavidad bucal y de la faringe"
  },
  {
     id:"C140",
     enfermedad:"Tumor maligno de la faringe, parte no especificada"
  },
  {
     id:"C142",
     enfermedad:"Tumor maligno del anillo de waldeyer"
  },
  {
     id:"C148",
     enfermedad:"Lesion de sitios contiguos del labio, de la cavidad bucal y de la laringe"
  },
  {
     id:"C15",
     enfermedad:"Tumor maligno del esofago"
  },
  {
     id:"C150",
     enfermedad:"Tumor maligno del esofago, porcion cervical"
  },
  {
     id:"C151",
     enfermedad:"Tumor maligno del esofago, porcion toracica"
  },
  {
     id:"C152",
     enfermedad:"Tumor maligno del esofago, porcion abdominal"
  },
  {
     id:"C153",
     enfermedad:"Tumor maligno del tercio superior del esofago"
  },
  {
     id:"C154",
     enfermedad:"Tumor maligno del tercio medio del esofago"
  },
  {
     id:"C155",
     enfermedad:"Tumor maligno del tercio inferior del esofago"
  },
  {
     id:"C158",
     enfermedad:"Lesion de sitios contiguos del esofago"
  },
  {
     id:"C159",
     enfermedad:"Tumor maligno del esofago, parte no especificada"
  },
  {
     id:"C16",
     enfermedad:"Tumor maligno del estomago"
  },
  {
     id:"C160",
     enfermedad:"Tumor maligno del cardias"
  },
  {
     id:"C161",
     enfermedad:"Tumor maligno del fundus gastrico"
  },
  {
     id:"C162",
     enfermedad:"Tumor maligno del cuerpo del estomago"
  },
  {
     id:"C163",
     enfermedad:"Tumor maligno del antro pilorico"
  },
  {
     id:"C164",
     enfermedad:"Tumor maligno del piloro"
  },
  {
     id:"C165",
     enfermedad:"Tumor maligno de la curvatura menor del estomago, sin otra especificacion"
  },
  {
     id:"C166",
     enfermedad:"Tumor maligno de la curvatura mayor del estomago, sin otra especificacion"
  },
  {
     id:"C168",
     enfermedad:"Lesion de sitios contiguos del estomago"
  },
  {
     id:"C169",
     enfermedad:"Tumor maligno del estomago, parte no especificada"
  },
  {
     id:"C17",
     enfermedad:"Tumor maligno del intestino delgado"
  },
  {
     id:"C170",
     enfermedad:"Tumor maligno del duodeno"
  },
  {
     id:"C171",
     enfermedad:"Tumor maligno del yeyuno"
  },
  {
     id:"C172",
     enfermedad:"Tumor maligno del ileon"
  },
  {
     id:"C173",
     enfermedad:"Tumor maligno del diverticulo de meckel"
  },
  {
     id:"C178",
     enfermedad:"Tumor maligno del lesion de sitios contiguos del intestino delgado"
  },
  {
     id:"C179",
     enfermedad:"Tumor maligno del intestino delgado, parte no especificada"
  },
  {
     id:"C18",
     enfermedad:"Tumor maligno del colon"
  },
  {
     id:"C180",
     enfermedad:"Tumor maligno del ciego"
  },
  {
     id:"C181",
     enfermedad:"Tumor maligno del apendice"
  },
  {
     id:"C182",
     enfermedad:"Tumor maligno del colon ascendente"
  },
  {
     id:"C183",
     enfermedad:"Tumor maligno del angulo hepatico"
  },
  {
     id:"C184",
     enfermedad:"Tumor maligno del colon transverso"
  },
  {
     id:"C185",
     enfermedad:"Tumor maligno del angulo esplenico"
  },
  {
     id:"C186",
     enfermedad:"Tumor maligno del colon descendente"
  },
  {
     id:"C187",
     enfermedad:"Tumor maligno del colon sigmoide"
  },
  {
     id:"C188",
     enfermedad:"Lesion de sitios contiguos del colon"
  },
  {
     id:"C189",
     enfermedad:"Tumor maligno del colon, parte no especificada"
  },
  {
     id:"C19",
     enfermedad:"Tumor maligno de la union rectosigmoidea"
  },
  {
     id:"C20",
     enfermedad:"Tumor maligno del recto"
  },
  {
     id:"C21",
     enfermedad:"Tumor maligno del ano y del conducto anal"
  },
  {
     id:"C210",
     enfermedad:"Tumor maligno del ano, parte no especificada"
  },
  {
     id:"C211",
     enfermedad:"Tumor maligno del conducto anal"
  },
  {
     id:"C212",
     enfermedad:"Tumor maligno de la zona cloacogenica"
  },
  {
     id:"C218",
     enfermedad:"Lesion de sitios contiguos del ano, del conducto anal y del recto"
  },
  {
     id:"C22",
     enfermedad:"Tumor maligno del higado y de las vias biliares intrahepaticas"
  },
  {
     id:"C220",
     enfermedad:"Carcinoma de celulas hepaticas"
  },
  {
     id:"C221",
     enfermedad:"Carcinoma de vias biliares intrahepaticas"
  },
  {
     id:"C222",
     enfermedad:"Hepatoblastoma"
  },
  {
     id:"C223",
     enfermedad:"Angiosarcoma del higado"
  },
  {
     id:"C224",
     enfermedad:"Otros sarcomas del higado"
  },
  {
     id:"C227",
     enfermedad:"Otros carcinomas especificados del higado"
  },
  {
     id:"C229",
     enfermedad:"Tumor maligno del higado, no especificado"
  },
  {
     id:"C23",
     enfermedad:"Tumor maligno de la vesicula biliar"
  },
  {
     id:"C24",
     enfermedad:"Tumor maligno de otras partes y de las no especificadas de las vias biliares"
  },
  {
     id:"C240",
     enfermedad:"Tumor maligno d e las vias biliares extrahepaticas"
  },
  {
     id:"C241",
     enfermedad:"Tumor maligno de la ampolla de vater"
  },
  {
     id:"C248",
     enfermedad:"Lesion de sitios contiguos de las vias biliares"
  },
  {
     id:"C249",
     enfermedad:"Tumor maligno de las vias biliares, parte no especificada"
  },
  {
     id:"C25",
     enfermedad:"Tumor maligno del pancreas"
  },
  {
     id:"C250",
     enfermedad:"Tumor maligno de la cabeza del pancreas"
  },
  {
     id:"C251",
     enfermedad:"Tumor maligno del cuerpo del pancreas"
  },
  {
     id:"C252",
     enfermedad:"Tumor maligno de la cola del pancreas"
  },
  {
     id:"C253",
     enfermedad:"Tumor maligno del conducto pancreatico"
  },
  {
     id:"C254",
     enfermedad:"Tumor maligno del pancreas endocrino"
  },
  {
     id:"C257",
     enfermedad:"Tumor maligno de otras partes especificadas del pancreas"
  },
  {
     id:"C258",
     enfermedad:"Lesion de sitios contiguos del pancreas"
  },
  {
     id:"C259",
     enfermedad:"Tumor maligno del pancreas, parte no especificada"
  },
  {
     id:"C26",
     enfermedad:"Tumor maligno de otros sitios y de los mal definidos de los organos digestivos"
  },
  {
     id:"C260",
     enfermedad:"Tumor maligno del intestino, parte no especificada"
  },
  {
     id:"C261",
     enfermedad:"Tumor maligno del bazo"
  },
  {
     id:"C268",
     enfermedad:"Lesion de sitios contiguos de los organos digestivos"
  },
  {
     id:"C269",
     enfermedad:"Tumor maligno de sitios mal definidos de los organos digestivos"
  },
  {
     id:"C30",
     enfermedad:"Tumor maligno de las fosas nasales y del oido medio"
  },
  {
     id:"C300",
     enfermedad:"Tumor maligno de la fosa nasal"
  },
  {
     id:"C301",
     enfermedad:"Tumor maligno del oido medio"
  },
  {
     id:"C31",
     enfermedad:"Tumor maligno de los senos paranasales"
  },
  {
     id:"C310",
     enfermedad:"Tumor maligno del seno maxilar"
  },
  {
     id:"C311",
     enfermedad:"Tumor maligno del seno etmoidal"
  },
  {
     id:"C312",
     enfermedad:"Tumor maligno del seno frontal"
  },
  {
     id:"C313",
     enfermedad:"Tumor maligno del seno esfenoidal"
  },
  {
     id:"C318",
     enfermedad:"Lesion de sitios contiguos de los senos paranasales"
  },
  {
     id:"C319",
     enfermedad:"Tumor maligno del seno paranasal no especificado"
  },
  {
     id:"C32",
     enfermedad:"Tumor maligno de la laringe"
  },
  {
     id:"C320",
     enfermedad:"Tumor maligno de la glotis"
  },
  {
     id:"C321",
     enfermedad:"Tumor maligno de la region supraglotica"
  },
  {
     id:"C322",
     enfermedad:"Tumor maligno de la region subglotica"
  },
  {
     id:"C323",
     enfermedad:"Tumor maligno del cartilago laringeo"
  },
  {
     id:"C328",
     enfermedad:"Lesion de sitios contiguos de la laringe"
  },
  {
     id:"C329",
     enfermedad:"Tumor maligno de la laringe, parte no especificada"
  },
  {
     id:"C33",
     enfermedad:"Tumor maligno de la traquea"
  },
  {
     id:"C34",
     enfermedad:"Tumor maligno de los bronquios y del pulmon"
  },
  {
     id:"C340",
     enfermedad:"Tumor maligno del bronquio principal"
  },
  {
     id:"C341",
     enfermedad:"Tumor maligno del lobulo superior, bronquio o pulmon"
  },
  {
     id:"C342",
     enfermedad:"Tumor maligno del lobulo medio, bronquio o pulmon"
  },
  {
     id:"C343",
     enfermedad:"Tumor maligno del lobulo inferior, bronquio o pulmon"
  },
  {
     id:"C348",
     enfermedad:"Lesion de sitios contiguos de los bronquios y del pulmon"
  },
  {
     id:"C349",
     enfermedad:"Tumor maligno de los bronquios o del pulmon, parte no especificada"
  },
  {
     id:"C37",
     enfermedad:"Tumor maligno del timo"
  },
  {
     id:"C38",
     enfermedad:"Tumor maligno del corazon, del mediastino y de la pleura"
  },
  {
     id:"C380",
     enfermedad:"Tumor maligno del corazon"
  },
  {
     id:"C381",
     enfermedad:"Tumor maligno del mediastino anterior"
  },
  {
     id:"C382",
     enfermedad:"Tumor maligno del mediastino posterior"
  },
  {
     id:"C383",
     enfermedad:"Tumor maligno del mediastino, parte no especificada"
  },
  {
     id:"C384",
     enfermedad:"Tumor maligno de la pleura"
  },
  {
     id:"C388",
     enfermedad:"Lesion de sitios contiguos del corazon, del mediastino y de la pleura"
  },
  {
     id:"C39",
     enfermedad:"Tumor maligno de otros sitios y de los mal definidos del sistema respiratorio y de los organos intratoracicos"
  },
  {
     id:"C390",
     enfermedad:"Tumor maligno de las vias respiratorias superiores, parte no especificada"
  },
  {
     id:"C398",
     enfermedad:"Lesion de sitios contiguos de los organos respiratorios e intratoracicos"
  },
  {
     id:"C399",
     enfermedad:"Tumor maligno de sitios mal definidos del sistema respiratorio"
  },
  {
     id:"C40",
     enfermedad:"Tumor maligno de los huesos y de los cartilagos articulares de los miembros"
  },
  {
     id:"C400",
     enfermedad:"Tumor maligno del omoplato y de los huesos largos del miembro superior"
  },
  {
     id:"C401",
     enfermedad:"Tumor maligno de los huesos cortos del miembro superior"
  },
  {
     id:"C402",
     enfermedad:"Tumor maligno de los huesos largos del miembro inferior"
  },
  {
     id:"C403",
     enfermedad:"Tumor maligno de los huesos cortos del miembro inferior"
  },
  {
     id:"C408",
     enfermedad:"Lesion de sitios contiguos de los huesos y de los cartilagos articulares de los miembros"
  },
  {
     id:"C409",
     enfermedad:"Tumor maligno de los huesos y de los cartilagos articulares de los miembros, sin otra especificacion"
  },
  {
     id:"C41",
     enfermedad:"Tumor maligno de los huesos y de los cartilagos articulares, de otros sitios y de sitios no especificados"
  },
  {
     id:"C410",
     enfermedad:"Tumor maligno de los huesos del craneo y de la cara"
  },
  {
     id:"C411",
     enfermedad:"Tumor maligno del hueso del maxilar inferior"
  },
  {
     id:"C412",
     enfermedad:"Tumor maligno de la columna vertebral"
  },
  {
     id:"C413",
     enfermedad:"Tumor maligno de la costilla, esternon y clavicula"
  },
  {
     id:"C414",
     enfermedad:"Tumor maligno de los huesos de la pelvis, sacro y coccix"
  },
  {
     id:"C418",
     enfermedad:"Lesion de sitios contiguos del hueso y del cartilago articular"
  },
  {
     id:"C419",
     enfermedad:"Tumor maligno de hueso y del cartilago articular, no especificado"
  },
  {
     id:"C43",
     enfermedad:"Melanoma maligno de la piel"
  },
  {
     id:"C430",
     enfermedad:"Melanoma maligno del labio"
  },
  {
     id:"C431",
     enfermedad:"Melanoma maligno del parpado, incluida la comisura palpebral"
  },
  {
     id:"C432",
     enfermedad:"Melanoma maligno de la oreja y del conducto auditivo externo"
  },
  {
     id:"C433",
     enfermedad:"Melanoma maligno de las otras partes y las no especificadas de la cara"
  },
  {
     id:"C434",
     enfermedad:"Melanoma maligno del cuero cabelludo y del cuello"
  },
  {
     id:"C435",
     enfermedad:"Melanoma maligno del tronco"
  },
  {
     id:"C436",
     enfermedad:"Melanoma maligno del miembro superior, incluido el hombro"
  },
  {
     id:"C437",
     enfermedad:"Melanoma maligno del miembro inferior, incluida la cadera"
  },
  {
     id:"C438",
     enfermedad:"Melanoma maligno de sitios contiguos de la piel"
  },
  {
     id:"C439",
     enfermedad:"Melanoma maligno de piel, sitio no especificado"
  },
  {
     id:"C44",
     enfermedad:"Otros tumores maligno de la piel"
  },
  {
     id:"C440",
     enfermedad:"Tumor maligno de la piel del labio"
  },
  {
     id:"C441",
     enfermedad:"Tumor maligno de la piel del parpado, incluida la comisura palpebral"
  },
  {
     id:"C442",
     enfermedad:"Tumor maligno de la piel de la oreja y del conducto auditivo externo"
  },
  {
     id:"C443",
     enfermedad:"Tumor maligno de la piel de otras partes y de las no especificadas de la cara"
  },
  {
     id:"C444",
     enfermedad:"Tumor maligno de la piel del cuero cabelludo y del cuello"
  },
  {
     id:"C445",
     enfermedad:"Tumor maligno de la piel del tronco"
  },
  {
     id:"C446",
     enfermedad:"Tumor maligno de la piel del miembro superior, incluido el hombro"
  },
  {
     id:"C447",
     enfermedad:"Tumor maligno de la piel del miembro inferior, incluida la cadera"
  },
  {
     id:"C448",
     enfermedad:"Lesion de sitios contiguos de la piel"
  },
  {
     id:"C449",
     enfermedad:"Tumor maligno de la piel, sitio no especificado"
  },
  {
     id:"C45",
     enfermedad:"Mesotelioma"
  },
  {
     id:"C450",
     enfermedad:"Mesotelioma de la pleura"
  },
  {
     id:"C451",
     enfermedad:"Mesotelioma del peritoneo"
  },
  {
     id:"C452",
     enfermedad:"Mesotelioma del pericardio"
  },
  {
     id:"C457",
     enfermedad:"Mesotelioma de otros sitios especificados"
  },
  {
     id:"C459",
     enfermedad:"Mesotelioma, de sitio no especificado"
  },
  {
     id:"C46",
     enfermedad:"Sarcoma de kaposi"
  },
  {
     id:"C460",
     enfermedad:"Sarcoma de kaposi de la piel"
  },
  {
     id:"C461",
     enfermedad:"Sarcoma de kaposi del tejido blando"
  },
  {
     id:"C462",
     enfermedad:"Sarcoma de kaposi del paladar"
  },
  {
     id:"C463",
     enfermedad:"Sarcoma de kaposi de los ganglios linfaticos"
  },
  {
     id:"C467",
     enfermedad:"Sarcoma de kaposi de otros sitios especificados"
  },
  {
     id:"C468",
     enfermedad:"Sarcoma de kaposi de multiples organos"
  },
  {
     id:"C469",
     enfermedad:"Sarcoma de kaposi, de sitio no especificado"
  },
  {
     id:"C47",
     enfermedad:"Tumor maligno de los nervios perifericos y del sistema nervioso autonomo"
  },
  {
     id:"C470",
     enfermedad:"Tumor maligno de los nervios perifericos de la cabeza, cara y cuello"
  },
  {
     id:"C471",
     enfermedad:"Tumor maligno de los nervios perifericos del miembro superior, incluido el hombro"
  },
  {
     id:"C472",
     enfermedad:"Tumor maligno de los nervios perifericos del miembro inferior, incluida la cadera"
  },
  {
     id:"C473",
     enfermedad:"Tumor maligno de los nervios perifericos del torax"
  },
  {
     id:"C474",
     enfermedad:"Tumor maligno de los nervios perifericos del abdomen"
  },
  {
     id:"C475",
     enfermedad:"Tumor maligno de los nervios perifericos de la pelvis"
  },
  {
     id:"C476",
     enfermedad:"Tumor maligno de los nervios perifericos del tronco, sin otra especificacion"
  },
  {
     id:"C478",
     enfermedad:"Lesion de sitios contiguos de los nervios perifericos y del sistema nervioso autonomo"
  },
  {
     id:"C479",
     enfermedad:"Tumor maligno de los nervios perifericos y del sistema nervioso autonomo, parte no especificada"
  },
  {
     id:"C48",
     enfermedad:"Tumor maligno del peritoneo y retroperitoneo"
  },
  {
     id:"C480",
     enfermedad:"Tumor maligno del retroperitoneo"
  },
  {
     id:"C481",
     enfermedad:"Tumor maligno de parte especificada del peritoneo"
  },
  {
     id:"C482",
     enfermedad:"Tumor maligno del peritoneo, sin otra especificacion"
  },
  {
     id:"C488",
     enfermedad:"Lesion de sitios contiguos del peritoneo y del retroperitoneo"
  },
  {
     id:"C49",
     enfermedad:"Tumor maligno otros tejidos conjuntivos y de tejidos blandos"
  },
  {
     id:"C490",
     enfermedad:"Tumor maligno del tejido conjuntivo y tejido blando de la cabeza, cara y cuello"
  },
  {
     id:"C491",
     enfermedad:"Tumor maligno del tejido conjuntivo y tejido blando del miembro superior, incluido el hombro"
  },
  {
     id:"C492",
     enfermedad:"Tumor maligno del tejido conjuntivo y tejido blando del miembro inferior, incluida la cadera"
  },
  {
     id:"C493",
     enfermedad:"Tumor maligno del tejido conjuntivo y tejido blando del torax"
  },
  {
     id:"C494",
     enfermedad:"Tumor maligno del tejido conjuntivo y tejido blando del abdomen"
  },
  {
     id:"C495",
     enfermedad:"Tumor maligno del tejido conjuntivo y tejido blando de la pelvis"
  },
  {
     id:"C496",
     enfermedad:"Tumor maligno del tejido conjuntivo y tejido blando del tronco, sin otra especificacion"
  },
  {
     id:"C498",
     enfermedad:"Lesion de sitios contiguos del tejido conjuntivo y del tejido del blando"
  },
  {
     id:"C499",
     enfermedad:"Tumor maligno del tejido conjuntivo y tejido blando, de sitio no especificado"
  },
  {
     id:"C50",
     enfermedad:"Tumor maligno de la mama"
  },
  {
     id:"C500",
     enfermedad:"Tumor maligno del pezon y areola mamaria"
  },
  {
     id:"C501",
     enfermedad:"Tumor maligno de la porcion central de la mama"
  },
  {
     id:"C502",
     enfermedad:"Tumor maligno del cuadrante superior interno de la mama"
  },
  {
     id:"C503",
     enfermedad:"Tumor maligno del cuadrante inferior interno de la mama"
  },
  {
     id:"C504",
     enfermedad:"Tumor maligno del cuadrante superior externo de la mama"
  },
  {
     id:"C505",
     enfermedad:"Tumor maligno del cuadrante inferior externo de la mama"
  },
  {
     id:"C506",
     enfermedad:"Tumor maligno de la prolongacion axilar de la mama"
  },
  {
     id:"C508",
     enfermedad:"Lesion de sitios contiguos de la mama"
  },
  {
     id:"C509",
     enfermedad:"Tumor maligno de la mama, parte no especificada"
  },
  {
     id:"C51",
     enfermedad:"Tumor maligno de la vulva"
  },
  {
     id:"C510",
     enfermedad:"Tumor maligno del labio mayor"
  },
  {
     id:"C511",
     enfermedad:"Tumor maligno del labio menor"
  },
  {
     id:"C512",
     enfermedad:"Tumor maligno del clitoris"
  },
  {
     id:"C518",
     enfermedad:"Lesion de sitios contiguos de la vulva"
  },
  {
     id:"C519",
     enfermedad:"Tumor maligno de la vulva, parte no especificada"
  },
  {
     id:"C52",
     enfermedad:"Tumor maligno de la vagina"
  },
  {
     id:"C53",
     enfermedad:"Tumor maligno del cuello del utero"
  },
  {
     id:"C530",
     enfermedad:"Tumor maligno del endocervix"
  },
  {
     id:"C531",
     enfermedad:"Tumor maligno de exocervix"
  },
  {
     id:"C538",
     enfermedad:"Lesion de sitios contiguos del cuello del utero"
  },
  {
     id:"C539",
     enfermedad:"Tumor maligno del cuello del utero, sin otra especificacion"
  },
  {
     id:"C54",
     enfermedad:"Tumor maligno del cuerpo del utero"
  },
  {
     id:"C540",
     enfermedad:"Tumor maligno del istmo uterino"
  },
  {
     id:"C541",
     enfermedad:"Tumor maligno del endometrio"
  },
  {
     id:"C542",
     enfermedad:"Tumor maligno del miometrio"
  },
  {
     id:"C543",
     enfermedad:"Tumor maligno del fondo del utero"
  },
  {
     id:"C548",
     enfermedad:"Lesion de sitios contiguos del cuerpo del utero"
  },
  {
     id:"C549",
     enfermedad:"Tumor maligno del cuerpo del utero, parte no especificada"
  },
  {
     id:"C55",
     enfermedad:"Tumor maligno del utero parte no especificada"
  },
  {
     id:"C56",
     enfermedad:"Tumor maligno del ovario"
  },
  {
     id:"C57",
     enfermedad:"Tumor maligno de otros organos genitales femeninos y los no especificados"
  },
  {
     id:"C570",
     enfermedad:"Tumor maligno de la trompa de falopio"
  },
  {
     id:"C571",
     enfermedad:"Tumor maligno del ligamento ancho"
  },
  {
     id:"C572",
     enfermedad:"Tumor maligno del ligamento redondo"
  },
  {
     id:"C573",
     enfermedad:"Tumor maligno del parametrio"
  },
  {
     id:"C574",
     enfermedad:"Tumor maligno de los anexos uterinos, sin otra especificacion"
  },
  {
     id:"C577",
     enfermedad:"Tumor maligno de otras partes especificadas de los organos genitales femeninos"
  },
  {
     id:"C578",
     enfermedad:"Lesion de sitios contiguos de los organos genitales femeninos"
  },
  {
     id:"C579",
     enfermedad:"Tumor maligno de organo genital femenino, parte no especificada"
  },
  {
     id:"C58",
     enfermedad:"Tumor maligno de la placenta"
  },
  {
     id:"C60",
     enfermedad:"Tumor maligno del pene"
  },
  {
     id:"C600",
     enfermedad:"Tumor maligno del prepucio"
  },
  {
     id:"C601",
     enfermedad:"Tumor maligno del glande"
  },
  {
     id:"C602",
     enfermedad:"Tumor maligno del cuerpo del pene"
  },
  {
     id:"C608",
     enfermedad:"Lesion de sitios contiguos del pene"
  },
  {
     id:"C609",
     enfermedad:"Tumor maligno del pene, parte no especificada"
  },
  {
     id:"C61",
     enfermedad:"Tumor maligno de la prostata"
  },
  {
     id:"C62",
     enfermedad:"Tumor maligno del testiculo"
  },
  {
     id:"C620",
     enfermedad:"Tumor maligno del testiculo no descendido"
  },
  {
     id:"C621",
     enfermedad:"Tumor maligno del testiculo descendido"
  },
  {
     id:"C629",
     enfermedad:"Tumor maligno del testiculo, no especificado"
  },
  {
     id:"C63",
     enfermedad:"Tumor maligno de otros organos genitales masculinos y de los no especificados"
  },
  {
     id:"C630",
     enfermedad:"Tumor maligno del epididimo"
  },
  {
     id:"C631",
     enfermedad:"Tumor maligno del cordon espermatico"
  },
  {
     id:"C632",
     enfermedad:"Tumor maligno del escroto"
  },
  {
     id:"C637",
     enfermedad:"Tumor maligno de otras partes especificadas de los organos genitales masculinos"
  },
  {
     id:"C638",
     enfermedad:"Lesion de sitios contiguos de los organos genitales masculinos"
  },
  {
     id:"C639",
     enfermedad:"Tumor maligno de organo genital masculino, parte no especificada"
  },
  {
     id:"C64",
     enfermedad:"Tumor maligno del rinon excepto de la pelvis renal"
  },
  {
     id:"C65",
     enfermedad:"Tumor maligno de la pelvis renal"
  },
  {
     id:"C66",
     enfermedad:"Tumor maligno del ureter"
  },
  {
     id:"C67",
     enfermedad:"Tumor maligno de la vejiga urinaria"
  },
  {
     id:"C670",
     enfermedad:"Tumor maligno del trigono vesical"
  },
  {
     id:"C671",
     enfermedad:"Tumor maligno de la cupula vesical"
  },
  {
     id:"C672",
     enfermedad:"Tumor maligno de la pared lateral de la vejiga"
  },
  {
     id:"C673",
     enfermedad:"Tumor maligno de la pared anterior de la vejiga"
  },
  {
     id:"C674",
     enfermedad:"Tumor maligno de la pared posterior de la vejiga"
  },
  {
     id:"C675",
     enfermedad:"Tumor maligno del cuello de la vejiga"
  },
  {
     id:"C676",
     enfermedad:"Tumor maligno del orificio ureteral"
  },
  {
     id:"C677",
     enfermedad:"Tumor maligno del uraco"
  },
  {
     id:"C678",
     enfermedad:"Lesion de sitios contiguos de la vejiga"
  },
  {
     id:"C679",
     enfermedad:"Tumor maligno de la vejiga urinaria, parte no especificada"
  },
  {
     id:"C68",
     enfermedad:"Tumor maligno otros organos urinarios y de los no especificados"
  },
  {
     id:"C680",
     enfermedad:"Tumor maligno de la uretra"
  },
  {
     id:"C681",
     enfermedad:"Tumor maligno de las glandulas parauretrales"
  },
  {
     id:"C688",
     enfermedad:"Lesion de sitios contiguos de los organos urinarios"
  },
  {
     id:"C689",
     enfermedad:"Tumor maligno de organo urinario no especificado"
  },
  {
     id:"C69",
     enfermedad:"Tumor maligno del ojo y sus anexos"
  },
  {
     id:"C690",
     enfermedad:"Tumor maligno de la conjuntiva"
  },
  {
     id:"C691",
     enfermedad:"Tumor maligno de la cornea"
  },
  {
     id:"C692",
     enfermedad:"Tumor maligno de la retina"
  },
  {
     id:"C693",
     enfermedad:"Tumor maligno de la coroides"
  },
  {
     id:"C694",
     enfermedad:"Tumor maligno del cuerpo ciliar"
  },
  {
     id:"C695",
     enfermedad:"Tumor maligno de la glandula y conducto lagrimales"
  },
  {
     id:"C696",
     enfermedad:"Tumor maligno de la orbita"
  },
  {
     id:"C698",
     enfermedad:"Lesion de sitios contiguos del ojo y sus anexos"
  },
  {
     id:"C699",
     enfermedad:"Tumor maligno del ojo, parte no especificada"
  },
  {
     id:"C70",
     enfermedad:"Tumor maligno de las meninges"
  },
  {
     id:"C700",
     enfermedad:"Tumor maligno de las meninges cerebrales"
  },
  {
     id:"C701",
     enfermedad:"Tumor maligno de las meninges raquideas"
  },
  {
     id:"C709",
     enfermedad:"Tumor maligno de las meninges, parte no especificada"
  },
  {
     id:"C71",
     enfermedad:"Tumor maligno del encefalo"
  },
  {
     id:"C710",
     enfermedad:"Tumor maligno del cerebro, excepto lobulos y ventriculos"
  },
  {
     id:"C711",
     enfermedad:"Tumor maligno del lobulo frontal"
  },
  {
     id:"C712",
     enfermedad:"Tumor maligno del lobulo temporal"
  },
  {
     id:"C713",
     enfermedad:"Tumor maligno del lobulo parietal"
  },
  {
     id:"C714",
     enfermedad:"Tumor maligno del lobulo occipital"
  },
  {
     id:"C715",
     enfermedad:"Tumor maligno del ventriculo cerebral"
  },
  {
     id:"C716",
     enfermedad:"Tumor maligno del cerebelo"
  },
  {
     id:"C717",
     enfermedad:"Tumor maligno del pedunculo cerebral"
  },
  {
     id:"C718",
     enfermedad:"Lesion de sitios contiguos del encefalo"
  },
  {
     id:"C719",
     enfermedad:"Tumor maligno del encefalo, parte no especificada"
  },
  {
     id:"C72",
     enfermedad:"Tumor maligno de la medula espinal, de los nervios craneales y de otras partes del sistema nervioso central"
  },
  {
     id:"C720",
     enfermedad:"Tumor maligno de la medula espinal"
  },
  {
     id:"C721",
     enfermedad:"Tumor maligno de la cola de caballo"
  },
  {
     id:"C722",
     enfermedad:"Tumor maligno del nervio olfatorio"
  },
  {
     id:"C723",
     enfermedad:"Tumor maligno del nervio optico"
  },
  {
     id:"C724",
     enfermedad:"Tumor maligno del nervio acustico"
  },
  {
     id:"C725",
     enfermedad:"Tumor maligno de otros nervios craneales y los no especificados"
  },
  {
     id:"C728",
     enfermedad:"Lesion de sitios contiguos del encefalo y otras partes del sistema nervioso central"
  },
  {
     id:"C729",
     enfermedad:"Tumor maligno del sistema nervioso central, sin otra especificacion"
  },
  {
     id:"C73",
     enfermedad:"Tumor maligno de la glandula tiroides"
  },
  {
     id:"C74",
     enfermedad:"Tumor maligno de la glandula suprarrenal"
  },
  {
     id:"C740",
     enfermedad:"Tumor maligno de la corteza de la glandula suprarrenal"
  },
  {
     id:"C741",
     enfermedad:"Tumor maligno de la medula de la glandula suprarrenal"
  },
  {
     id:"C749",
     enfermedad:"Tumor maligno de la glandula suprarrenal, parte no especificada"
  },
  {
     id:"C75",
     enfermedad:"Tumor maligno de otras glandulas endocrinas y de estructuras afines"
  },
  {
     id:"C750",
     enfermedad:"Tumor maligno de la glandula paratiroides"
  },
  {
     id:"C751",
     enfermedad:"Tumor maligno de la hipofisis"
  },
  {
     id:"C752",
     enfermedad:"Tumor maligno del conducto craneofaringeo"
  },
  {
     id:"C753",
     enfermedad:"Tumor maligno de la glandula pineal"
  },
  {
     id:"C754",
     enfermedad:"Tumor maligno del cuerpo carotideo"
  },
  {
     id:"C755",
     enfermedad:"Tumor maligno del cuerpo aortico y otros cuerpos cromafines"
  },
  {
     id:"C758",
     enfermedad:"Tumor maligno pluriglandular, no especificado"
  },
  {
     id:"C759",
     enfermedad:"Tumor maligno de glandula endocrina no especificada"
  },
  {
     id:"C76",
     enfermedad:"Tumor malignode otros sitios y de sitios mal definidos"
  },
  {
     id:"C760",
     enfermedad:"Tumor maligno de la cabeza, cara y cuello"
  },
  {
     id:"C761",
     enfermedad:"Tumor maligno del torax"
  },
  {
     id:"C762",
     enfermedad:"Tumor maligno del abdomen"
  },
  {
     id:"C763",
     enfermedad:"Tumor maligno de la pelvis"
  },
  {
     id:"C764",
     enfermedad:"Tumor maligno del miembro superior"
  },
  {
     id:"C765",
     enfermedad:"Tumor maligno del miembro inferior"
  },
  {
     id:"C767",
     enfermedad:"Tumor maligno de otros sitios mal definidos"
  },
  {
     id:"C768",
     enfermedad:"Lesion de sitios contiguos mal definidos"
  },
  {
     id:"C77",
     enfermedad:"Tumor maligno secundario y el no especificado de los ganglios linfaticos"
  },
  {
     id:"C770",
     enfermedad:"Tumor maligno de los ganglios linfaticos de la cabeza, cara y cuello"
  },
  {
     id:"C771",
     enfermedad:"Tumor maligno de los ganglios linfaticos intratoracicos"
  },
  {
     id:"C772",
     enfermedad:"Tumor maligno de los ganglios linfaticos intraabdominales"
  },
  {
     id:"C773",
     enfermedad:"Tumor maligno de los ganglios linfaticos de la axila y del miembro superior"
  },
  {
     id:"C774",
     enfermedad:"Tumor maligno de los ganglios linfaticos de la region inguinal y del miembro inferior"
  },
  {
     id:"C775",
     enfermedad:"Tumor maligno de los ganglios linfaticos de la pelvis"
  },
  {
     id:"C778",
     enfermedad:"Tumor maligno de los ganglios linfaticos de regiones multiples"
  },
  {
     id:"C779",
     enfermedad:"Tumor maligno del ganglio linfatico, sitio no especificado"
  },
  {
     id:"C78",
     enfermedad:"Tumor maligno secundario de los organos respiratorios y digestivos"
  },
  {
     id:"C780",
     enfermedad:"Tumor maligno secundario del pulmon"
  },
  {
     id:"C781",
     enfermedad:"Tumor maligno secundario del mediastino"
  },
  {
     id:"C782",
     enfermedad:"Tumor maligno secundario de la pleura"
  },
  {
     id:"C783",
     enfermedad:"Tumor maligno secundario de otros organos respiratorios y de los no especificados"
  },
  {
     id:"C784",
     enfermedad:"Tumor maligno secundario del intestino delgado"
  },
  {
     id:"C785",
     enfermedad:"Tumor maligno secundario del intestino grueso y del recto"
  },
  {
     id:"C786",
     enfermedad:"Tumor maligno secundario del peritoneo y del retroperitoneo"
  },
  {
     id:"C787",
     enfermedad:"Tumor maligno secundario del higado"
  },
  {
     id:"C788",
     enfermedad:"Tumor maligno secundario de otros organos digestivos y de los no especificados"
  },
  {
     id:"C79",
     enfermedad:"Tumor maligno secundario de otros sitios"
  },
  {
     id:"C790",
     enfermedad:"Tumor maligno secundario del riñon y de la pelvis renal"
  },
  {
     id:"C791",
     enfermedad:"Tumor maligno secundario de la vejiga, y de otros organos y los no especificados de las vias urinarias"
  },
  {
     id:"C792",
     enfermedad:"Tumor maligno secundario de la piel"
  },
  {
     id:"C793",
     enfermedad:"Tumor maligno secundario del encefalo y de las meninges cerebrales"
  },
  {
     id:"C794",
     enfermedad:"Tumor maligno secundario de otras partes del sistema nervioso y de las no especificadas"
  },
  {
     id:"C795",
     enfermedad:"Tumor maligno secundario de los huesos y de la medula osea"
  },
  {
     id:"C796",
     enfermedad:"Tumor maligno secundario del ovario"
  },
  {
     id:"C797",
     enfermedad:"Tumor maligno secundario de la glandula suprarrenal"
  },
  {
     id:"C798",
     enfermedad:"Tumor maligno secundario de otros sitios especificados"
  },
  {
     id:"C80",
     enfermedad:"Tumor maligno de sitios no especificados"
  },
  {
     id:"C81",
     enfermedad:"Enfermedad de hodgkin"
  },
  {
     id:"C810",
     enfermedad:"Enfermedad de hodgkin con predominio linfocitico"
  },
  {
     id:"C811",
     enfermedad:"Enfermedad de hodgkin con esclerosis nodular"
  },
  {
     id:"C812",
     enfermedad:"Enfermedad de hodgkin con celularidad mixta"
  },
  {
     id:"C813",
     enfermedad:"Enfermedad de hodgkin con deplecion linfocitica"
  },
  {
     id:"C817",
     enfermedad:"Otros tipos de enfermedad de hodgkin"
  },
  {
     id:"C819",
     enfermedad:"Enfermedad de hodgkin, no especificada"
  },
  {
     id:"C82",
     enfermedad:"Linfoma no hodgkin folicular [ nodular ]"
  },
  {
     id:"C820",
     enfermedad:"Linfoma no hodgkin de celulas pequeñas hendidas, folicular"
  },
  {
     id:"C821",
     enfermedad:"Linfoma no hodgkin mixto, de pequeñas celulas hendidas y de grandes celulas, folicular"
  },
  {
     id:"C822",
     enfermedad:"Linfoma no hodgkin de celulas grandes, folicular"
  },
  {
     id:"C827",
     enfermedad:"Otros tipos especificados de linfoma no hodgkin folicular"
  },
  {
     id:"C829",
     enfermedad:"Linfoma no hodgkin folicular, sin otra especificacion"
  },
  {
     id:"C83",
     enfermedad:"Linfoma no"
  },
  {
     id:"C830",
     enfermedad:"Linfoma no hodgkin de celulas pequeñas (difuso)"
  },
  {
     id:"C831",
     enfermedad:"Linfoma no hodgkin de celulas pequeñas hendidas (difuso)"
  },
  {
     id:"C832",
     enfermedad:"Linfoma no hodgkin mixto, de celulas pequeñas y grandes (difuso)"
  },
  {
     id:"C833",
     enfermedad:"Linfoma no hodgkin de celulas grandes (difuso)"
  },
  {
     id:"C834",
     enfermedad:"Linfoma no hodgkin inmunoblastico (difuso)"
  },
  {
     id:"C835",
     enfermedad:"Linfoma no hodgkin linfoblastico (difuso)"
  },
  {
     id:"C836",
     enfermedad:"Linfoma no hodgkin indiferenciado (difuso)"
  },
  {
     id:"C837",
     enfermedad:"Tumor de burkitt"
  },
  {
     id:"C838",
     enfermedad:"Otros tipos especificados de linfoma no hodgkin difuso"
  },
  {
     id:"C839",
     enfermedad:"Linfoma no hodgkin difuso, sin otra especificacion"
  },
  {
     id:"C84",
     enfermedad:"Linfoma celulas t, periferico y cutaneo"
  },
  {
     id:"C840",
     enfermedad:"Micosis fungoide"
  },
  {
     id:"C841",
     enfermedad:"Enfermedad de sezary"
  },
  {
     id:"C842",
     enfermedad:"Linfoma de zona t"
  },
  {
     id:"C843",
     enfermedad:"Linfoma linfoepitelioide"
  },
  {
     id:"C844",
     enfermedad:"Linfoma de celulas t periferico"
  },
  {
     id:"C845",
     enfermedad:"Otros linfomas de celulas y los no especificados"
  },
  {
     id:"C85",
     enfermedad:"Linfoma no hodgkin de otro tipo y el no especificado"
  },
  {
     id:"C850",
     enfermedad:"Linfosarcoma"
  },
  {
     id:"C851",
     enfermedad:"Linfoma de celulas b, sin otra especificacion"
  },
  {
     id:"C857",
     enfermedad:"Otros tipos especificados de linfoma no hodgkin"
  },
  {
     id:"C859",
     enfermedad:"Linfoma no hodgkin, no especificado"
  },
  {
     id:"C88",
     enfermedad:"Enfermedades inmunoproliferativas malignas"
  },
  {
     id:"C880",
     enfermedad:"Macroglobulinemia de waldenstrom"
  },
  {
     id:"C881",
     enfermedad:"Enfermedad de cadena pesada alfa"
  },
  {
     id:"C882",
     enfermedad:"Enfermedad de cadena pesada gamma"
  },
  {
     id:"C883",
     enfermedad:"Enfermedad inmunoproliferativa del intestino delgado"
  },
  {
     id:"C887",
     enfermedad:"Otras enfermedades inmunoproliferativas malignas"
  },
  {
     id:"C889",
     enfermedad:"Enfermedad inmunoproliferativa maligna, sin otra especificacion"
  },
  {
     id:"C90",
     enfermedad:"Mieloma multiples y tumores malignos de celulas plasmaticas"
  },
  {
     id:"C900",
     enfermedad:"Mieloma multiple"
  },
  {
     id:"C901",
     enfermedad:"Leucemia de celulas plasmaticas"
  },
  {
     id:"C902",
     enfermedad:"Plasmocitoma, extramedular"
  },
  {
     id:"C91",
     enfermedad:"Leucemia linfoide"
  },
  {
     id:"C910",
     enfermedad:"Leucemia linfoblastica aguda"
  },
  {
     id:"C911",
     enfermedad:"Leucemia linfocitica cronica"
  },
  {
     id:"C912",
     enfermedad:"Leucemia linfocitica subaguda"
  },
  {
     id:"C913",
     enfermedad:"Leucemia prolinfocitica"
  },
  {
     id:"C914",
     enfermedad:"Leucemia de celulas vellosas"
  },
  {
     id:"C915",
     enfermedad:"Leucemia de celulas t adultas"
  },
  {
     id:"C917",
     enfermedad:"Otras leucemias linfoides"
  },
  {
     id:"C919",
     enfermedad:"Leucemia linfoide, sin otra especificacion"
  },
  {
     id:"C92",
     enfermedad:"Leucemia mieloide"
  },
  {
     id:"C920",
     enfermedad:"Leucemia mieloide aguda"
  },
  {
     id:"C921",
     enfermedad:"Leucemia mieloide cronica"
  },
  {
     id:"C922",
     enfermedad:"Leucemia mieloide subaguda"
  },
  {
     id:"C923",
     enfermedad:"Sarcoma mieloide"
  },
  {
     id:"C924",
     enfermedad:"Leucemia promielocitica aguda"
  },
  {
     id:"C925",
     enfermedad:"Leucemia mielomonocitica aguda"
  },
  {
     id:"C927",
     enfermedad:"Otras leucemias mieloides"
  },
  {
     id:"C929",
     enfermedad:"Leucemia mieloide, sin otra especificacion"
  },
  {
     id:"C93",
     enfermedad:"Leucemia monocitica"
  },
  {
     id:"C930",
     enfermedad:"Leucemia monocitica aguda"
  },
  {
     id:"C931",
     enfermedad:"Leucemia monocitica cronica"
  },
  {
     id:"C932",
     enfermedad:"Leucemia monocitica subaguda"
  },
  {
     id:"C937",
     enfermedad:"Otras leucemias monociticas"
  },
  {
     id:"C939",
     enfermedad:"Leucemia monocitica, sin otra especificacion"
  },
  {
     id:"C94",
     enfermedad:"Otras leucemias de tipo celular especificado"
  },
  {
     id:"C940",
     enfermedad:"Eritremia aguda y eritroleucemia"
  },
  {
     id:"C941",
     enfermedad:"Eritremia cronica"
  },
  {
     id:"C942",
     enfermedad:"Leucemia megacarioblastica aguda"
  },
  {
     id:"C943",
     enfermedad:"Leucemia de mastocitos"
  },
  {
     id:"C944",
     enfermedad:"Panmielosis aguda"
  },
  {
     id:"C945",
     enfermedad:"Mielofibrosis aguda"
  },
  {
     id:"C947",
     enfermedad:"Otras leucemias especificadas"
  },
  {
     id:"C95",
     enfermedad:"Leucemia de celulas de tipo no especificado"
  },
  {
     id:"C950",
     enfermedad:"Leucemia aguda, celulas de tipo no especificado"
  },
  {
     id:"C951",
     enfermedad:"Leucemia cronica, celulas de tipo no especificado"
  },
  {
     id:"C952",
     enfermedad:"Leucemia subaguda, celulas de tipo no especificado"
  },
  {
     id:"C957",
     enfermedad:"Otras leucemias de celulas de tipo no especificado"
  },
  {
     id:"C959",
     enfermedad:"Leucemia, no especificada"
  },
  {
     id:"C96",
     enfermedad:"Otros tumores malignos y los no especificados del tejido linfatico, de los organos hematopoyeticos y de tejidos afines"
  },
  {
     id:"C960",
     enfermedad:"Enfermedad de letterer"
  },
  {
     id:"C961",
     enfermedad:"Histiocitosis maligna"
  },
  {
     id:"C962",
     enfermedad:"Tumor maligno de mastocitos"
  },
  {
     id:"C963",
     enfermedad:"Linfoma histiocitico verdadero"
  },
  {
     id:"C967",
     enfermedad:"Otros tumores malignos especificados del tejido linfatico, hematopoyetico y tejidos afines"
  },
  {
     id:"C969",
     enfermedad:"Tumor maligno del tejido linfatico, hematopoyetico y tejidos afines, sin otra especificacion"
  },
  {
     id:"C97",
     enfermedad:"Tumores malignos ( primarios ) de sitios multiples independientes"
  },
  {
     id:"D00",
     enfermedad:"Carcinoma in situ de la cavidad bucal, del esofago y del estomago"
  },
  {
     id:"D000",
     enfermedad:"Carcinoma in situ del labio, de la cavidad bucal y de la faringe"
  },
  {
     id:"D001",
     enfermedad:"Carcinoma in situ del esofago"
  },
  {
     id:"D002",
     enfermedad:"Carcinoma in situ del estomago"
  },
  {
     id:"D01",
     enfermedad:"Carcinoma in situ de otros organos digestivos y de los no especificados"
  },
  {
     id:"D010",
     enfermedad:"Carcinoma in situ del colon"
  },
  {
     id:"D011",
     enfermedad:"Carcinoma in situ de la union rectosigmoidea"
  },
  {
     id:"D012",
     enfermedad:"Carcinoma in situ del recto"
  },
  {
     id:"D013",
     enfermedad:"Carcinoma in situ del ano y del conducto anal"
  },
  {
     id:"D014",
     enfermedad:"Carcinoma in situ de otras partes y de las no especificadas del intestino"
  },
  {
     id:"D015",
     enfermedad:"Carcinoma in situ del higado, de la vesicula biliar y del conducto biliar"
  },
  {
     id:"D017",
     enfermedad:"Carcinoma in situ de otras partes especificadas de organos digestivos"
  },
  {
     id:"D019",
     enfermedad:"Carcinoma in situ de organos digestivos no especificados"
  },
  {
     id:"D02",
     enfermedad:"Carcinoma in situ del sistema respiratorio y del oido medio"
  },
  {
     id:"D020",
     enfermedad:"Carcinoma in situ de la laringe"
  },
  {
     id:"D021",
     enfermedad:"Carcinoma in situ de la traquea"
  },
  {
     id:"D022",
     enfermedad:"Carcinoma in situ del bronquio y del pulmon"
  },
  {
     id:"D023",
     enfermedad:"Carcinoma in situ de otras partes del sistema respiratorio"
  },
  {
     id:"D024",
     enfermedad:"Carcinoma in situ de organos respiratorios no especificados"
  },
  {
     id:"D03",
     enfermedad:"Melanoma in situ"
  },
  {
     id:"D030",
     enfermedad:"Melanoma in situ del labio"
  },
  {
     id:"D031",
     enfermedad:"Melanoma in situ del parpado y de la comisura palpebral"
  },
  {
     id:"D032",
     enfermedad:"Melanoma in situ de la oreja y del conducto auditivo externo"
  },
  {
     id:"D033",
     enfermedad:"Melanoma in situ de otras partes y de las no especificadas de la cara"
  },
  {
     id:"D034",
     enfermedad:"Melanoma in situ del cuero cabelludo y del cuello"
  },
  {
     id:"D035",
     enfermedad:"Melanoma in situ del tronco"
  },
  {
     id:"D036",
     enfermedad:"Melanoma in situ del miembro superior, incluido el hombro"
  },
  {
     id:"D037",
     enfermedad:"Melanoma in situ del miembro inferior, incluida la cadera"
  },
  {
     id:"D038",
     enfermedad:"Melanoma in situ de otros sitios"
  },
  {
     id:"D039",
     enfermedad:"Melanoma in situ, sitio no especificado"
  },
  {
     id:"D04",
     enfermedad:"Carcinoma in situ de la piel"
  },
  {
     id:"D040",
     enfermedad:"Carcinoma in situ de la piel del labio"
  },
  {
     id:"D041",
     enfermedad:"Carcinoma in situ de la piel del parpado y de la comisura palpebral"
  },
  {
     id:"D042",
     enfermedad:"Carcinoma in situ de la piel de la oreja y del conducto auditivo externo"
  },
  {
     id:"D043",
     enfermedad:"Carcinoma in situ de la piel de otras partes y de las no especificadas de la cara"
  },
  {
     id:"D044",
     enfermedad:"Carcinoma in situ de la piel del cuero cabelludo y cuello"
  },
  {
     id:"D045",
     enfermedad:"Carcinoma in situ de la piel del tronco"
  },
  {
     id:"D046",
     enfermedad:"Carcinoma in situ de la piel del miembro superior, incluido el hombro"
  },
  {
     id:"D047",
     enfermedad:"Carcinoma in situ de la piel del miembro inferior, incluida la cadera"
  },
  {
     id:"D048",
     enfermedad:"Carcinoma in situ de la piel de otros sitios especificados"
  },
  {
     id:"D049",
     enfermedad:"Carcinoma in situ de la piel, sitio no especificado"
  },
  {
     id:"D05",
     enfermedad:"Carcinoma in situ de la mama"
  },
  {
     id:"D050",
     enfermedad:"Carcinoma in situ lobular"
  },
  {
     id:"D051",
     enfermedad:"Carcinoma in situ intracanalicular"
  },
  {
     id:"D057",
     enfermedad:"Otros carcinomas in situ de la mama"
  },
  {
     id:"D059",
     enfermedad:"Carcinoma in situ de la mama, parte no especificada"
  },
  {
     id:"D06",
     enfermedad:"Carcinoma in situ del cuello del utero"
  },
  {
     id:"D060",
     enfermedad:"Carcinoma in situ del endocervix"
  },
  {
     id:"D061",
     enfermedad:"Carcinoma in situ del exocervix"
  },
  {
     id:"D067",
     enfermedad:"Carcinoma in situ de otras partes especificadas del cuello del utero"
  },
  {
     id:"D069",
     enfermedad:"Carcinoma in situ del cuello del utero, parte no especificada"
  },
  {
     id:"D07",
     enfermedad:"Carcinoma in situ de otros organos genitales y de los no especificados"
  },
  {
     id:"D070",
     enfermedad:"Carcinoma in situ del endometrio"
  },
  {
     id:"D071",
     enfermedad:"Carcinoma in situ de la vulva"
  },
  {
     id:"D072",
     enfermedad:"Carcinoma in situ de la vagina"
  },
  {
     id:"D073",
     enfermedad:"Carcinoma in situ de otros sitios de organos genitales femeninos y de los no especificados"
  },
  {
     id:"D074",
     enfermedad:"Carcinoma in situ del pene"
  },
  {
     id:"D075",
     enfermedad:"Carcinoma in situ de la prostata"
  },
  {
     id:"D076",
     enfermedad:"Carcinoma in situ de otros organos genitales masculinos y de los no especificados"
  },
  {
     id:"D09",
     enfermedad:"Carcinoma in situ de otros sitios y de los no especificados"
  },
  {
     id:"D090",
     enfermedad:"Carcinoma in situ de la vejiga"
  },
  {
     id:"D091",
     enfermedad:"Carcinoma in situ de otros organos urinarios y de los no especificados"
  },
  {
     id:"D092",
     enfermedad:"Carcinoma in situ del ojo"
  },
  {
     id:"D093",
     enfermedad:"Carcinoma in situ de la glandula tiroides y de otras glandulas endocrinas"
  },
  {
     id:"D097",
     enfermedad:"Carcinoma in situ de otros sitios especificados"
  },
  {
     id:"D099",
     enfermedad:"Carcinoma in situ, sitio no especificado"
  },
  {
     id:"D10",
     enfermedad:"Tumor benigno de la boca y de la faringe"
  },
  {
     id:"D100",
     enfermedad:"Tumor benigno del labio"
  },
  {
     id:"D101",
     enfermedad:"Tumor benigno de la lengua"
  },
  {
     id:"D102",
     enfermedad:"Tumor benigno del piso de la boca"
  },
  {
     id:"D103",
     enfermedad:"Tumor benigno de otras partes y de las no especificadas de la boca"
  },
  {
     id:"D104",
     enfermedad:"Tumor benigno de la amigdala"
  },
  {
     id:"D105",
     enfermedad:"Tumor benigno de otras partes de la orofaringe"
  },
  {
     id:"D106",
     enfermedad:"Tumor benigno de la nasofaringe"
  },
  {
     id:"D107",
     enfermedad:"Tumor benigno de la hipofaringe"
  },
  {
     id:"D109",
     enfermedad:"Tumor benigno de la faringe, parte no especificada"
  },
  {
     id:"D11",
     enfermedad:"Tumor benigno de las glandulas salivares mayores"
  },
  {
     id:"D110",
     enfermedad:"Tumor benigno de la glandula parotida"
  },
  {
     id:"D117",
     enfermedad:"Tumor benigno de otras glandulas salivales mayores especificadas"
  },
  {
     id:"D119",
     enfermedad:"Tumor benigno de la glandula salival mayor, sin otra especificacion"
  },
  {
     id:"D12",
     enfermedad:"Tumor benigno del colon, del recto, del conducto anal y del ano"
  },
  {
     id:"D120",
     enfermedad:"Tumor benigno del ciego"
  },
  {
     id:"D121",
     enfermedad:"Tumor benigno del apendice"
  },
  {
     id:"D122",
     enfermedad:"Tumor benigno del colon ascendente"
  },
  {
     id:"D123",
     enfermedad:"Tumor benigno del colon transverso"
  },
  {
     id:"D124",
     enfermedad:"Tumor benigno del colon descendente"
  },
  {
     id:"D125",
     enfermedad:"Tumor benigno del colon sigmoide"
  },
  {
     id:"D126",
     enfermedad:"Tumor benigno del colon, parte no especificada"
  },
  {
     id:"D127",
     enfermedad:"Tumor benigno de la union rectosigmoidea"
  },
  {
     id:"D128",
     enfermedad:"Tumor benigno del recto"
  },
  {
     id:"D129",
     enfermedad:"Tumor benigno del conducto anal y del ano"
  },
  {
     id:"D13",
     enfermedad:"Tumor benigno de otras partes y de las mal definidas del sistema digestivo"
  },
  {
     id:"D130",
     enfermedad:"Tumor benigno del esofago"
  },
  {
     id:"D131",
     enfermedad:"Tumor benigno del estomago"
  },
  {
     id:"D132",
     enfermedad:"Tumor benigno del duodeno"
  },
  {
     id:"D133",
     enfermedad:"Tumor benigno de otras partes y de las no especificadas del intestino delgado"
  },
  {
     id:"D134",
     enfermedad:"Tumor benigno del higado"
  },
  {
     id:"D135",
     enfermedad:"Tumor benigno de las vias biliares extrahepaticas"
  },
  {
     id:"D136",
     enfermedad:"Tumor benigno del pancreas"
  },
  {
     id:"D137",
     enfermedad:"Tumor benigno del pancreas endocrino"
  },
  {
     id:"D139",
     enfermedad:"Tumor benigno de sitios mal definidos del sistema digestivo"
  },
  {
     id:"D14",
     enfermedad:"Tumor benigno del oido medio y del sistema respiratorio"
  },
  {
     id:"D140",
     enfermedad:"Tumor benigno del oido medio, de la cavidad nasal y de los senos paranasales"
  },
  {
     id:"D141",
     enfermedad:"Tumor benigno de la laringe"
  },
  {
     id:"D142",
     enfermedad:"Tumor benigno de la traquea"
  },
  {
     id:"D143",
     enfermedad:"Tumor benigno de los bronquios y del pulmon"
  },
  {
     id:"D144",
     enfermedad:"Tumor benigno del sistema respiratorio, sitio no especificado"
  },
  {
     id:"D15",
     enfermedad:"Tumor benigno de otros organos intratoracicos y de los no especificados"
  },
  {
     id:"D150",
     enfermedad:"Tumor benigno del timo"
  },
  {
     id:"D151",
     enfermedad:"Tumor benigno del corazon"
  },
  {
     id:"D152",
     enfermedad:"Tumor benigno del mediastino"
  },
  {
     id:"D157",
     enfermedad:"Tumor benigno de otros organos intratoracicos especificados"
  },
  {
     id:"D159",
     enfermedad:"Tumor benigno de organo intratoracico no especificado"
  },
  {
     id:"D16",
     enfermedad:"Tumor benigno del hueso y del cartilago articular"
  },
  {
     id:"D160",
     enfermedad:"Tumor benigno del omoplato y huesos largos del miembro superior"
  },
  {
     id:"D161",
     enfermedad:"Tumor benigno de los huesos cortos del miembro superior"
  },
  {
     id:"D162",
     enfermedad:"Tumor benigno de los huesos largos del miembro inferior"
  },
  {
     id:"D163",
     enfermedad:"Tumor benigno de los huesos cortos del miembro inferior"
  },
  {
     id:"D164",
     enfermedad:"Tumor benigno de los huesos del craneo y de la cara"
  },
  {
     id:"D165",
     enfermedad:"Tumor benigno del maxilar inferior"
  },
  {
     id:"D166",
     enfermedad:"Tumor benigno de la columna vertebral"
  },
  {
     id:"D167",
     enfermedad:"Tumor benigno de las costillas, esternon y clavicula"
  },
  {
     id:"D168",
     enfermedad:"Tumor benigno de los huesos pelvicos, sacro y coccix"
  },
  {
     id:"D169",
     enfermedad:"Tumor benigno del huesos y del cartilago articular, sitio no especificado"
  },
  {
     id:"D17",
     enfermedad:"Tumores benignos lipomatosos"
  },
  {
     id:"D170",
     enfermedad:"Tumor benigno lipomatoso de piel y de tejido subcutaneo de cabeza, cara y cuello"
  },
  {
     id:"D171",
     enfermedad:"Tumor benigno lipomatoso de piel y de tejido subcutaneo del tronco"
  },
  {
     id:"D172",
     enfermedad:"Tumor benigno lipomatoso de piel y de tejido subcutaneo de miembros"
  },
  {
     id:"D173",
     enfermedad:"Tumor benigno lipomatoso de piel y de tejido subcutaneo de otros sitios y de los no especificados"
  },
  {
     id:"D174",
     enfermedad:"Tumor benigno lipomatoso de los organos intratoracicos"
  },
  {
     id:"D175",
     enfermedad:"Tumor benigno lipomatoso de los organos intraabdominales"
  },
  {
     id:"D176",
     enfermedad:"Tumor benigno lipomatoso del cordon espermatico"
  },
  {
     id:"D177",
     enfermedad:"Tumor benigno lipomatoso de otros sitios especificados"
  },
  {
     id:"D179",
     enfermedad:"Tumor benigno lipomatoso, de sitio no especificado"
  },
  {
     id:"D18",
     enfermedad:"Hemangioma y linfangioma de cualquier sitio"
  },
  {
     id:"D180",
     enfermedad:"Hemangioma, de cualquier sitio"
  },
  {
     id:"D181",
     enfermedad:"Linfangioma, de cualquier sitio"
  },
  {
     id:"D19",
     enfermedad:"Tumor benigno del tejido mesotelial"
  },
  {
     id:"D190",
     enfermedad:"Tumor benigno del tejido mesotelial de la pleura"
  },
  {
     id:"D191",
     enfermedad:"Tumor benigno del tejido mesotelial del peritoneo"
  },
  {
     id:"D197",
     enfermedad:"Tumor benigno del tejido mesotelial de otros sitios especificados"
  },
  {
     id:"D199",
     enfermedad:"Tumor benigno del tejido mesotelial, de sitio no especificado"
  },
  {
     id:"D20",
     enfermedad:"Tumor benigno del tejido blando del peritoneo y del retroperitoneo"
  },
  {
     id:"D200",
     enfermedad:"Tumor benigno del retroperitoneo"
  },
  {
     id:"D201",
     enfermedad:"Tumor benigno del peritoneo"
  },
  {
     id:"D21",
     enfermedad:"Otros tumores benignos del tejido conjuntivo y tejido blando"
  },
  {
     id:"D210",
     enfermedad:"Tumor benigno del tejido cunjuntivo y de otros tejidos blandos de cabeza, cara y cuello"
  },
  {
     id:"D211",
     enfermedad:"Tumor benigno del tejido cunjuntivo y de otros tejidos blandos del miembro superior, incluido el hombro"
  },
  {
     id:"D212",
     enfermedad:"Tumor benigno del tejido cunjuntivo y de otros tejidos blandos del miembro inferior, incluido la cadera"
  },
  {
     id:"D213",
     enfermedad:"Tumor benigno del tejido cunjuntivo y de otros tejidos blandos del torax"
  },
  {
     id:"D214",
     enfermedad:"Tumor benigno del tejido cunjuntivo y de otros tejidos blandos del abdomen"
  },
  {
     id:"D215",
     enfermedad:"Tumor benigno del tejido cunjuntivo y de otros tejidos blandos de la pelvis"
  },
  {
     id:"D216",
     enfermedad:"Tumor benigno del tejido cunjuntivo y de otros tejidos blandos del tronco, sin otra especificacion"
  },
  {
     id:"D219",
     enfermedad:"Tumor benigno del tejido cunjuntivo y de otros tejidos blandos, de sitio no especificado"
  },
  {
     id:"D22",
     enfermedad:"Nevo melanocitico"
  },
  {
     id:"D220",
     enfermedad:"Nevo melanocitico del labio"
  },
  {
     id:"D221",
     enfermedad:"Nevo melanocitico del parpado, incluida la comisura palpebral"
  },
  {
     id:"D222",
     enfermedad:"Nevo melanocitico de la oreja y del conducto auditivo externo"
  },
  {
     id:"D223",
     enfermedad:"Nevo melanocitico de otras partes de las no especificadas de la cara"
  },
  {
     id:"D224",
     enfermedad:"Nevo melanocitico del cuero cabelludo y del cuello"
  },
  {
     id:"D225",
     enfermedad:"Nevo melanocitico del tronco"
  },
  {
     id:"D226",
     enfermedad:"Nevo melanocitico del miembro superior, incluido el hombro"
  },
  {
     id:"D227",
     enfermedad:"Nevo melanocitico del miembro inferior, incluida la cadera"
  },
  {
     id:"D229",
     enfermedad:"Nevo melanocitico, sitio no especificado"
  },
  {
     id:"D23",
     enfermedad:"Otros tumores benignos de la piel"
  },
  {
     id:"D230",
     enfermedad:"Tumor benigno de la piel del labio"
  },
  {
     id:"D231",
     enfermedad:"Tumor benigno de la piel del parpado, incluida la comisura palpebral"
  },
  {
     id:"D232",
     enfermedad:"Tumor benigno de la piel de la oreja y del conducto auditivo externo"
  },
  {
     id:"D233",
     enfermedad:"Tumor benigno de la piel de otras partes y de las no especificadas de la cara"
  },
  {
     id:"D234",
     enfermedad:"Tumor benigno de la piel del cuero cabelludo y del cuello"
  },
  {
     id:"D235",
     enfermedad:"Tumor benigno de la piel del tronco"
  },
  {
     id:"D236",
     enfermedad:"Tumor benigno de la piel del miembro superior, incluido el hombro"
  },
  {
     id:"D237",
     enfermedad:"Tumor benigno de la piel del miembro inferior, incluida la cadera"
  },
  {
     id:"D239",
     enfermedad:"Tumor benigno de la piel, sitio no especificado"
  },
  {
     id:"D24",
     enfermedad:"Tumor benigno de la mama"
  },
  {
     id:"D25",
     enfermedad:"Leiomioma del utero"
  },
  {
     id:"D250",
     enfermedad:"Leiomioma submucoso del utero"
  },
  {
     id:"D251",
     enfermedad:"Leiomioma intramural del utero"
  },
  {
     id:"D252",
     enfermedad:"Leiomioma subseroso del utero"
  },
  {
     id:"D259",
     enfermedad:"Leiomioma del utero, sin otra especificacion"
  },
  {
     id:"D26",
     enfermedad:"Otros tumores benignos del utero"
  },
  {
     id:"D260",
     enfermedad:"Tumor benigno del cuello del utero"
  },
  {
     id:"D261",
     enfermedad:"Tumor benigno del cuerpo del utero"
  },
  {
     id:"D267",
     enfermedad:"Tumor benigno de otras partes especificadas del utero"
  },
  {
     id:"D269",
     enfermedad:"Tumor benigno del utero, parte no especificada"
  },
  {
     id:"D27",
     enfermedad:"Tumor benigno del ovario"
  },
  {
     id:"D28",
     enfermedad:"Tumor benigno de otros organos genitales femeninos y de los no especificados"
  },
  {
     id:"D280",
     enfermedad:"Tumor benigno de la vulva"
  },
  {
     id:"D281",
     enfermedad:"Tumor benigno de la vagina"
  },
  {
     id:"D282",
     enfermedad:"Tumor benigno de la trompa de falopio y de los ligamentos uterinos"
  },
  {
     id:"D287",
     enfermedad:"Tumor benigno de otros sitios especificados de los organos genitales femeninos"
  },
  {
     id:"D289",
     enfermedad:"Tumor benigno de organo genital femenino, sitio no especificado"
  },
  {
     id:"D29",
     enfermedad:"Tumor benigno de los organos genitales masculinos"
  },
  {
     id:"D290",
     enfermedad:"Tumor benigno del pene"
  },
  {
     id:"D291",
     enfermedad:"Tumor benigno de la prostata"
  },
  {
     id:"D292",
     enfermedad:"Tumor benigno de los testiculos"
  },
  {
     id:"D293",
     enfermedad:"Tumor benigno del epididimo"
  },
  {
     id:"D294",
     enfermedad:"Tumor benigno del escroto"
  },
  {
     id:"D297",
     enfermedad:"Tumor benigno de otros organos genitales masculinos"
  },
  {
     id:"D299",
     enfermedad:"Tumor benigno de organo genital masculino, sitio no especificado"
  },
  {
     id:"D30",
     enfermedad:"Tumor benigno de los organos urinarios"
  },
  {
     id:"D300",
     enfermedad:"Tumor benigno del riñon"
  },
  {
     id:"D301",
     enfermedad:"Tumor benigno de la pelvis renal"
  },
  {
     id:"D302",
     enfermedad:"Tumor benigno del ureter"
  },
  {
     id:"D303",
     enfermedad:"Tumor benigno de la vejiga"
  },
  {
     id:"D304",
     enfermedad:"Tumor benigno de la uretra"
  },
  {
     id:"D307",
     enfermedad:"Tumor benigno de otros organos urinarios"
  },
  {
     id:"D309",
     enfermedad:"Tumor benigno de organo urinario no especificado"
  },
  {
     id:"D31",
     enfermedad:"Tumor benigno del ojo y sus anexos"
  },
  {
     id:"D310",
     enfermedad:"Tumor benigno de la conjuntiva"
  },
  {
     id:"D311",
     enfermedad:"Tumor benigno de la cornea"
  },
  {
     id:"D312",
     enfermedad:"Tumor benigno de la retina"
  },
  {
     id:"D313",
     enfermedad:"Tumor benigno de la coroides"
  },
  {
     id:"D314",
     enfermedad:"Tumor benigno del cuerpo ciliar"
  },
  {
     id:"D315",
     enfermedad:"Tumor benigno de las glandulas y de los conductos lagrimales"
  },
  {
     id:"D316",
     enfermedad:"Tumor benigno de la orbita, parte no especificada"
  },
  {
     id:"D319",
     enfermedad:"Tumor benigno del ojo, parte no especificada"
  },
  {
     id:"D32",
     enfermedad:"Tumor benigno de las meninges"
  },
  {
     id:"D320",
     enfermedad:"Tumor benigno de las meninges cerebrales"
  },
  {
     id:"D321",
     enfermedad:"Tumor benigno de las meninges raquideas"
  },
  {
     id:"D329",
     enfermedad:"Tumor benigno de las meninges, parte no especificada"
  },
  {
     id:"D33",
     enfermedad:"Tumor benigno del encefalo y de otras partes del sistema nervioso central"
  },
  {
     id:"D330",
     enfermedad:"Tumor benigno del encefalo, supratentorial"
  },
  {
     id:"D331",
     enfermedad:"Tumor benigno del encefalo, infratentorial"
  },
  {
     id:"D332",
     enfermedad:"Tumor benigno de encefalo, parte no especificada"
  },
  {
     id:"D333",
     enfermedad:"Tumor benigno de los nervios craneales"
  },
  {
     id:"D334",
     enfermedad:"Tumor benigno de la medula espinal"
  },
  {
     id:"D337",
     enfermedad:"Tumor benigno de otras partes especificadas del sistema nervioso central"
  },
  {
     id:"D339",
     enfermedad:"Tumor benigno del sistema nervioso central, sitio no especificado"
  },
  {
     id:"D34",
     enfermedad:"Tumor benigno de la glandula tiroides"
  },
  {
     id:"D35",
     enfermedad:"Tumor benigno de otras glandulas endocrinas y de las no especificadas"
  },
  {
     id:"D350",
     enfermedad:"Tumor benigno de la glandula suprarrenal"
  },
  {
     id:"D351",
     enfermedad:"Tumor benigno de la glandula paratiroides"
  },
  {
     id:"D352",
     enfermedad:"Tumor benigno de la hipofisis"
  },
  {
     id:"D353",
     enfermedad:"Tumor benigno del conducto craneofaringeo"
  },
  {
     id:"D354",
     enfermedad:"Tumor benigno de la glandula pineal"
  },
  {
     id:"D355",
     enfermedad:"Tumor benigno del cuerpo carotideo"
  },
  {
     id:"D356",
     enfermedad:"Tumor benigno del cuerpo aortico y de otros cuerpos cromafines"
  },
  {
     id:"D357",
     enfermedad:"Tumor benigno de otras glandulas endocrinas especificadas"
  },
  {
     id:"D358",
     enfermedad:"Tumor benigno de pluriglandular"
  },
  {
     id:"D359",
     enfermedad:"Tumor benigno de glandula endocrina no especificada"
  },
  {
     id:"D36",
     enfermedad:"Tumor benigno de otros sitios y de los no especificados"
  },
  {
     id:"D360",
     enfermedad:"Tumor benigno de los ganglios linfaticos"
  },
  {
     id:"D361",
     enfermedad:"Tumor benigno de los nervios perifericos y del sistema nervioso autonomo"
  },
  {
     id:"D367",
     enfermedad:"Tumor benigno de otros sitios especificados"
  },
  {
     id:"D369",
     enfermedad:"Tumor benigno de sitio no especificado"
  },
  {
     id:"D37",
     enfermedad:"Tumor de comportamiento incierto de la cavidad bucal y de los organos digestivos"
  },
  {
     id:"D370",
     enfermedad:"Tumor de comportamiento incierto o desconocido del labio, de la cavidad bucal y de la faringe"
  },
  {
     id:"D371",
     enfermedad:"Tumor de comportamiento incierto o desconocido del estomago"
  },
  {
     id:"D372",
     enfermedad:"Tumor de comportamiento incierto o desconocido del intestino delgado"
  },
  {
     id:"D373",
     enfermedad:"Tumor de comportamiento incierto o desconocido del apendice"
  },
  {
     id:"D374",
     enfermedad:"Tumor de comportamiento incierto o desconocido del colon"
  },
  {
     id:"D375",
     enfermedad:"Tumor de comportamiento incierto o desconocido del recto"
  },
  {
     id:"D376",
     enfermedad:"Tumor de comportamiento incierto o desconocido del higado, de la vesicula biliar y del conducto biliar"
  },
  {
     id:"D377",
     enfermedad:"Tumor de comportamiento incierto o desconocido de otros organos digestivos especificados"
  },
  {
     id:"D379",
     enfermedad:"Tumor de comportamiento incierto o desconocido de organos digestivos, sitio no especificado"
  },
  {
     id:"D38",
     enfermedad:"Tumor de comportamiento incierto del oido medio y de los organos respiratorios e intratoracicos"
  },
  {
     id:"D380",
     enfermedad:"Tumor de comportamiento incierto o desconocido de laringe"
  },
  {
     id:"D381",
     enfermedad:"Tumor de comportamiento incierto o desconocido de la traquea, de los bronquios y del pulmon"
  },
  {
     id:"D382",
     enfermedad:"Tumor de comportamiento incierto o desconocido de la pleura"
  },
  {
     id:"D383",
     enfermedad:"Tumor de comportamiento incierto o desconocido del mediastino"
  },
  {
     id:"D384",
     enfermedad:"Tumor de comportamiento incierto o desconocido del timo"
  },
  {
     id:"D385",
     enfermedad:"Tumor de comportamiento incierto o desconocido de otros organos respiratorios y del oido medio"
  },
  {
     id:"D386",
     enfermedad:"Tumor de comportamiento incierto o desconocido de organos respiratorios, sitio no especificado"
  },
  {
     id:"D39",
     enfermedad:"Tumor de comportamiento incierto de los organos genitales femeninos"
  },
  {
     id:"D390",
     enfermedad:"Tumor de comportamiento incierto o desconocido del utero"
  },
  {
     id:"D391",
     enfermedad:"Tumor de comportamiento incierto o desconocido del ovario"
  },
  {
     id:"D392",
     enfermedad:"Tumor de comportamiento incierto o desconocido de la placenta"
  },
  {
     id:"D397",
     enfermedad:"Tumor de comportamiento incierto o desconocido de otros organos genitales femeninos"
  },
  {
     id:"D399",
     enfermedad:"Tumor de comportamiento incierto o desconocido de organo genital femenino no especificado"
  },
  {
     id:"D40",
     enfermedad:"Tumor de comportamiento incierto de los organos genitales masculinos"
  },
  {
     id:"D400",
     enfermedad:"Tumor de comportamiento incierto o desconocido de la prostata"
  },
  {
     id:"D401",
     enfermedad:"Tumor de comportamiento incierto o desconocido del testiculo"
  },
  {
     id:"D407",
     enfermedad:"Tumor de comportamiento incierto o desconocido de otros organos genitales masculinos"
  },
  {
     id:"D409",
     enfermedad:"Tumor de comportamiento incierto o desconocido de organo genital masculino no especificado"
  },
  {
     id:"D41",
     enfermedad:"Tumor de comportamiento incierto de los organos urinarios"
  },
  {
     id:"D410",
     enfermedad:"Tumor de comportamiento incierto o desconocido del riñon"
  },
  {
     id:"D411",
     enfermedad:"Tumor de comportamiento incierto o desconocido dec la pelvis renal"
  },
  {
     id:"D412",
     enfermedad:"Tumor de comportamiento incierto o desconocido del ureter"
  },
  {
     id:"D413",
     enfermedad:"Tumor de comportamiento incierto o desconocido de la uretra"
  },
  {
     id:"D414",
     enfermedad:"Tumor de comportamiento incierto o desconocido de la vejiga"
  },
  {
     id:"D417",
     enfermedad:"Tumor de comportamiento incierto o desconocido de otros organos urinarios"
  },
  {
     id:"D419",
     enfermedad:"Tumor de comportamiento incierto o desconocido de organo urinario no especificado"
  },
  {
     id:"D42",
     enfermedad:"Tumor de comportamiento incierto de las meninges"
  },
  {
     id:"D420",
     enfermedad:"Tumor de comportamiento incierto o desconocido de las meninges cerebrales"
  },
  {
     id:"D421",
     enfermedad:"Tumor de comportamiento incierto o desconocido de las meninges raquideas"
  },
  {
     id:"D429",
     enfermedad:"Tumor de comportamiento incierto o desconocido de las meninges, parte no especificada"
  },
  {
     id:"D43",
     enfermedad:"Tumor de comportamiento incierto del encefalo y del sistema nervioso central"
  },
  {
     id:"D430",
     enfermedad:"Tumor de comportamiento incierto o desconocido del encefalo, supratentorial"
  },
  {
     id:"D431",
     enfermedad:"Tumor de comportamiento incierto o desconocido del encefalo, infratentorial"
  },
  {
     id:"D432",
     enfermedad:"Tumor de comportamiento incierto o desconocido del encefalo, parte no especificada"
  },
  {
     id:"D433",
     enfermedad:"Tumor de comportamiento incierto o desconocido de los nervios craneales"
  },
  {
     id:"D434",
     enfermedad:"Tumor de comportamiento incierto o desconocido de la medula espinal"
  },
  {
     id:"D437",
     enfermedad:"Tumor de comportamiento incierto o desconocido de otras partes especificadas del sistema nervioso central"
  },
  {
     id:"D439",
     enfermedad:"Tumor de comportamiento incierto o desconocido del sistema nervioso central, sitio no especificado"
  },
  {
     id:"D44",
     enfermedad:"Tumor de comportamiento incierto de las glandulas endocrinas"
  },
  {
     id:"D440",
     enfermedad:"Tumor de comportamiento incierto o desconocido de la glandula tiroides"
  },
  {
     id:"D441",
     enfermedad:"Tumor de comportamiento incierto o desconocido de la glandula suprarrenal"
  },
  {
     id:"D442",
     enfermedad:"Tumor de comportamiento incierto o desconocido de la glandula paratiroides"
  },
  {
     id:"D443",
     enfermedad:"Tumor de comportamiento incierto o desconocido de la glandula hipofisis"
  },
  {
     id:"D444",
     enfermedad:"Tumor de comportamiento incierto o desconocido del conducto craneofaringeo"
  },
  {
     id:"D445",
     enfermedad:"Tumor de comportamiento incierto o desconocido de la glandula pineal"
  },
  {
     id:"D446",
     enfermedad:"Tumor de comportamiento incierto o desconocido del cuerpo carotideo"
  },
  {
     id:"D447",
     enfermedad:"Tumor de comportamiento incierto o desconocido del cuerpo aortico y otros cromafines"
  },
  {
     id:"D448",
     enfermedad:"Tumor de comportamiento incierto o desconocido con afectacion pluriglandular"
  },
  {
     id:"D449",
     enfermedad:"Tumor de comportamiento incierto o desconocido de glandula endocrina no especificada"
  },
  {
     id:"D45",
     enfermedad:"Policitemia vera"
  },
  {
     id:"D46",
     enfermedad:"Sindromes mielodisplasicos"
  },
  {
     id:"D460",
     enfermedad:"Anemia refractaria sin sideroblastos, asi descrita"
  },
  {
     id:"D461",
     enfermedad:"Anemia refractaria con sideroblastos"
  },
  {
     id:"D462",
     enfermedad:"Anemia refractaria con exceso de blastos"
  },
  {
     id:"D463",
     enfermedad:"Anemia refractaria con exceso de blastos con transformacion"
  },
  {
     id:"D464",
     enfermedad:"Anemia refractaria, sin otra especificacion"
  },
  {
     id:"D467",
     enfermedad:"Otros sindromes mielodisplasicos"
  },
  {
     id:"D469",
     enfermedad:"Sindrome mielodisplasico, sin otra especificacion"
  },
  {
     id:"D47",
     enfermedad:"Otros tumores de comportamiento incierto o desconocido del tejido linfatico, de los organos hematopoyeticos y de tejidos afines"
  },
  {
     id:"D470",
     enfermedad:"Tumor de comportamiento incierto o desconocido de los mastocitos e histiocitos"
  },
  {
     id:"D471",
     enfermedad:"Enfermedad mieloproliferativa cronica"
  },
  {
     id:"D472",
     enfermedad:"Gammopatia monoclonal"
  },
  {
     id:"D473",
     enfermedad:"Trombocitopenia (hemorragica) esencial"
  },
  {
     id:"D477",
     enfermedad:"Otros tumores especificados de comportamiento incierto o desconocido del tejido linfatico, de los organos hematopoyeticos y de tejidos afines"
  },
  {
     id:"D479",
     enfermedad:"Tumores de comportamiento incierto o desconocido del tejido linfatico, de los organos hematopoyeticos y de tejidos afines, no especificados"
  },
  {
     id:"D48",
     enfermedad:"Tumor de comportamiento incierto o desconocido de otros sitios y de los no especificados"
  },
  {
     id:"D480",
     enfermedad:"Tumor de comportamiento incierto o desconocido del hueso y cartilago articular"
  },
  {
     id:"D481",
     enfermedad:"Tumor de comportamiento incierto o desconocido del tejido conjuntivo y otro tejido blando"
  },
  {
     id:"D482",
     enfermedad:"Tumor de comportamiento incierto o desconocido de los nervios perifericos y del sistema nervioso autonomo"
  },
  {
     id:"D483",
     enfermedad:"Tumor de comportamiento incierto o desconocido del retroperitoneo"
  },
  {
     id:"D484",
     enfermedad:"Tumor de comportamiento incierto o desconocido del peritoneo"
  },
  {
     id:"D485",
     enfermedad:"Tumor de comportamiento incierto o desconocido de la piel"
  },
  {
     id:"D486",
     enfermedad:"Tumor de comportamiento incierto o desconocido de la mama"
  },
  {
     id:"D487",
     enfermedad:"Tumor de comportamiento incierto o desconocido de otros sitios especificados"
  },
  {
     id:"D489",
     enfermedad:"Tumor de comportamiento incierto o desconocido, de sitio no especificado"
  },
  {
     id:"D50",
     enfermedad:"Anemias por deficiencia de hierro"
  },
  {
     id:"D500",
     enfermedad:"Anemia por deficiencia de hierro secundaria a perdida de sangre (cronica)"
  },
  {
     id:"D501",
     enfermedad:"Disfagia sideropenica"
  },
  {
     id:"D508",
     enfermedad:"Otras anemias por deficiencia de hierro"
  },
  {
     id:"D509",
     enfermedad:"Anemia por deficiencia de hierro sin otra especificacion"
  },
  {
     id:"D51",
     enfermedad:"Anemia por deficiencia de vitamina b12"
  },
  {
     id:"D510",
     enfermedad:"Anemia por deficiencia de vitamina b12 debida a deficiencia del factor intrinseco"
  },
  {
     id:"D511",
     enfermedad:"Anemia por deficiencia de vitamina b12 debida a mala absorcion selectiva de vitamina b12 con proteinuria"
  },
  {
     id:"D512",
     enfermedad:"Deficiencia de trascobalamina ii"
  },
  {
     id:"D513",
     enfermedad:"Otras anemias por deficiencia dietetica de vitamina b12"
  },
  {
     id:"D518",
     enfermedad:"Otras anemias por deficiencia de vitamina b12"
  },
  {
     id:"D519",
     enfermedad:"Anemia por deficiencia de vitamina b12, sin otra especificacion"
  },
  {
     id:"D52",
     enfermedad:"Anemia por deficiencia de folatos"
  },
  {
     id:"D520",
     enfermedad:"Anemia por deficiencia dietetica de folatos"
  },
  {
     id:"D521",
     enfermedad:"Anemia por deficiencia de folatos inducida por drogas"
  },
  {
     id:"D528",
     enfermedad:"Otras anemias por deficiencia de folatos"
  },
  {
     id:"D529",
     enfermedad:"Anemia por deficiencia de folatos, sin otra especificacion"
  },
  {
     id:"D53",
     enfermedad:"Otras anemias nutricionales"
  },
  {
     id:"D530",
     enfermedad:"Anemia por deficiencia de proteinas"
  },
  {
     id:"D531",
     enfermedad:"Otras anemias megaloblasticas, no clasificadas en otra parte"
  },
  {
     id:"D532",
     enfermedad:"Anemia escorbutica"
  },
  {
     id:"D538",
     enfermedad:"Otras anemias nutricionales especificadas"
  },
  {
     id:"D539",
     enfermedad:"Anemia nutricional, no especificada"
  },
  {
     id:"D55",
     enfermedad:"Anemia debida a trastornos enzimaticos"
  },
  {
     id:"D550",
     enfermedad:"Anemia debida a deficiencia de glucosa"
  },
  {
     id:"D551",
     enfermedad:"Anemia debida a otros trastornos del metabolismo del glutation"
  },
  {
     id:"D552",
     enfermedad:"Anemia debida a trastornos de las enzimas glucoliticas"
  },
  {
     id:"D553",
     enfermedad:"Anemia debida a trastornos del metabolismo de los nucleotidos"
  },
  {
     id:"D558",
     enfermedad:"Otras anemias debidas a trastornos enzimaticos"
  },
  {
     id:"D559",
     enfermedad:"Anemia debida a trastornos enzimaticos, sin otra especificacion"
  },
  {
     id:"D56",
     enfermedad:"Talasemia"
  },
  {
     id:"D560",
     enfermedad:"Alfa talasemia"
  },
  {
     id:"D561",
     enfermedad:"Beta talasemia"
  },
  {
     id:"D562",
     enfermedad:"Delta"
  },
  {
     id:"D563",
     enfermedad:"Rasgo talasemico"
  },
  {
     id:"D564",
     enfermedad:"Persistencia hereditaria de la hemoglobina fetal (phhf)"
  },
  {
     id:"D568",
     enfermedad:"Otras talasemias"
  },
  {
     id:"D569",
     enfermedad:"Talasemia, no especificada"
  },
  {
     id:"D57",
     enfermedad:"Trastornos falciformes"
  },
  {
     id:"D570",
     enfermedad:"Anemia falciforme con crisis"
  },
  {
     id:"D571",
     enfermedad:"Anemia falciforme sin crisis"
  },
  {
     id:"D572",
     enfermedad:"Trastornos falciformes heterocigoticos dobles"
  },
  {
     id:"D573",
     enfermedad:"Rasgo drepanocitico"
  },
  {
     id:"D578",
     enfermedad:"Otros trastornos falciformes"
  },
  {
     id:"D58",
     enfermedad:"Otras anemias hemoliticas hereditarias"
  },
  {
     id:"D580",
     enfermedad:"Esferocitosis hereditaria"
  },
  {
     id:"D581",
     enfermedad:"Eliptocitosis hereditaria"
  },
  {
     id:"D582",
     enfermedad:"Otras hemoglobinopatias"
  },
  {
     id:"D588",
     enfermedad:"Otras anemias hemoliticas hereditarias especificadas"
  },
  {
     id:"D589",
     enfermedad:"Anemia hemolitica hereditaria, sin otra especificacion"
  },
  {
     id:"D59",
     enfermedad:"Anemia hemolitica adquirida"
  },
  {
     id:"D590",
     enfermedad:"Anemia hemolitica autoinmune inducida por drogas"
  },
  {
     id:"D591",
     enfermedad:"Otras anemias hemoliticas autoinmunes"
  },
  {
     id:"D592",
     enfermedad:"Anemia hemolitica no autoinmune inducida por drogas"
  },
  {
     id:"D593",
     enfermedad:"Sindrome hemolitico"
  },
  {
     id:"D594",
     enfermedad:"Otras anemias hemoliticas no autoinmunes"
  },
  {
     id:"D595",
     enfermedad:"Hemoglobinuria paroxistica nocturna [marchiafava"
  },
  {
     id:"D596",
     enfermedad:"Hemoglobinuria debida a hemolisis por otras causas externas"
  },
  {
     id:"D598",
     enfermedad:"Otras anemias hemoliticas adquiridas"
  },
  {
     id:"D599",
     enfermedad:"Anemias hemolitica adquirida, sin otra especificacion"
  },
  {
     id:"D60",
     enfermedad:"Aplasia adquirida, exclusiva de la serie roja [ eritoblastopenia ]"
  },
  {
     id:"D600",
     enfermedad:"Aplasia cronica adquirida, exclusiva de la serie roja"
  },
  {
     id:"D601",
     enfermedad:"Aplasia transitoria adquirida, exclusiva de la serie roja"
  },
  {
     id:"D608",
     enfermedad:"Otras aplasias adquiridas, exclusivas de la serie roja"
  },
  {
     id:"D609",
     enfermedad:"Aplasia adquirida, exclusiva de la serie roja, no especificada"
  },
  {
     id:"D61",
     enfermedad:"Otras anemias aplasticas"
  },
  {
     id:"D610",
     enfermedad:"Anemia aplastica constitucional"
  },
  {
     id:"D611",
     enfermedad:"Anemia aplastica inducida por drogas"
  },
  {
     id:"D612",
     enfermedad:"Anemia aplastica debida a otros agentes externos"
  },
  {
     id:"D613",
     enfermedad:"Anemia aplastica idiopatica"
  },
  {
     id:"D618",
     enfermedad:"Otras anemias aplasticas especificadas"
  },
  {
     id:"D619",
     enfermedad:"Anemia aplastica, sin otra especificacion"
  },
  {
     id:"D62",
     enfermedad:"Anemia posthemorragica aguda"
  },
  {
     id:"D63",
     enfermedad:"Anemia en efermedades cronicas clasificadas en otra parte"
  },
  {
     id:"D630",
     enfermedad:"Anemia en enfermedad neoplasica (c00"
  },
  {
     id:"D638",
     enfermedad:"Anemia en otras enfermedades cronicas clasificadas en otra parte"
  },
  {
     id:"D64",
     enfermedad:"Otras anemias"
  },
  {
     id:"D640",
     enfermedad:"Anemia sideroblastica hereditaria"
  },
  {
     id:"D641",
     enfermedad:"Anemia sideroblastica secundaria a otra enfermedad"
  },
  {
     id:"D642",
     enfermedad:"Anemia sideroblastica secundaria, debida a drogas y toxinas"
  },
  {
     id:"D643",
     enfermedad:"Otras anemias sideroblasticas"
  },
  {
     id:"D644",
     enfermedad:"Anemia diseritropoyetica congenita"
  },
  {
     id:"D648",
     enfermedad:"Otras anemias especificadas"
  },
  {
     id:"D649",
     enfermedad:"Anemia de tipo no especificado"
  },
  {
     id:"D65",
     enfermedad:"Coagulacion intravascular diseminada"
  },
  {
     id:"D66",
     enfermedad:"Deficiencia hereditaria del factor viii"
  },
  {
     id:"D67",
     enfermedad:"Deficiencia hereditaria del factor ix"
  },
  {
     id:"D68",
     enfermedad:"Otros defectos de la coagulacion"
  },
  {
     id:"D680",
     enfermedad:"Enfermedad de von willebrand"
  },
  {
     id:"D681",
     enfermedad:"Deficiencia hereditaria del factor xi"
  },
  {
     id:"D682",
     enfermedad:"Deficiencia hereditaria de otros factores de la coagulacion"
  },
  {
     id:"D683",
     enfermedad:"Trastorno hemorragico debido a anticoagulantes circulantes"
  },
  {
     id:"D684",
     enfermedad:"Deficiencia adquirida de factores de la coagulacion"
  },
  {
     id:"D688",
     enfermedad:"Otros defectos especificados de la coagulacion"
  },
  {
     id:"D689",
     enfermedad:"Defecto de la coagulacion, no especificado"
  },
  {
     id:"D69",
     enfermedad:"Purpura y otras afecciones hemorragicas"
  },
  {
     id:"D690",
     enfermedad:"Purpura alergica"
  },
  {
     id:"D691",
     enfermedad:"Defectos cualitativos de las plaquetas"
  },
  {
     id:"D692",
     enfermedad:"Otras purpuras no trombocitopenicas"
  },
  {
     id:"D693",
     enfermedad:"Purpura trombocitopenica idiopatica"
  },
  {
     id:"D694",
     enfermedad:"Otras trombocitopenias primarias"
  },
  {
     id:"D695",
     enfermedad:"Trombocitopenia secundaria"
  },
  {
     id:"D696",
     enfermedad:"Trombocitopenia no especificada"
  },
  {
     id:"D698",
     enfermedad:"Otras afecciones hemorragicas especificadas"
  },
  {
     id:"D699",
     enfermedad:"Afeccion hemorragica, no especificada"
  },
  {
     id:"D70",
     enfermedad:"Agranulocitosis"
  },
  {
     id:"D71",
     enfermedad:"Trastornos funcionales polimorfonucleares neutrofilos"
  },
  {
     id:"D72",
     enfermedad:"Otros trastornos de los leucocitos"
  },
  {
     id:"D720",
     enfermedad:"Anomalias geneticas de los leucocitos"
  },
  {
     id:"D721",
     enfermedad:"Eosinofilia"
  },
  {
     id:"D728",
     enfermedad:"Otros trastornos especificados de los leucocitos"
  },
  {
     id:"D729",
     enfermedad:"Trastornos de los leucocitos, no especificado"
  },
  {
     id:"D73",
     enfermedad:"Enfermedades del bazo"
  },
  {
     id:"D730",
     enfermedad:"Hipoesplenismo"
  },
  {
     id:"D731",
     enfermedad:"Hiperesplenismo"
  },
  {
     id:"D732",
     enfermedad:"Esplenomegalia congestiva cronica"
  },
  {
     id:"D733",
     enfermedad:"Absceso del bazo"
  },
  {
     id:"D734",
     enfermedad:"Quiste del bazo"
  },
  {
     id:"D735",
     enfermedad:"Infarto del bazo"
  },
  {
     id:"D738",
     enfermedad:"Otras enfermedades del bazo"
  },
  {
     id:"D739",
     enfermedad:"Enfermedad del bazo, no especificada"
  },
  {
     id:"D74",
     enfermedad:"Metahemoglobinemia"
  },
  {
     id:"D740",
     enfermedad:"Metahemoglobinemia congenita"
  },
  {
     id:"D748",
     enfermedad:"Otras metahemoglobinemias"
  },
  {
     id:"D749",
     enfermedad:"Metahemoglobinemia, no especificada"
  },
  {
     id:"D75",
     enfermedad:"Otras enfermedades de la sangre y de los organos hematopoyeticos"
  },
  {
     id:"D750",
     enfermedad:"Eritrocitosis familiar"
  },
  {
     id:"D751",
     enfermedad:"Policitemia secundaria"
  },
  {
     id:"D752",
     enfermedad:"Trombocitosis esencial"
  },
  {
     id:"D758",
     enfermedad:"Otras enfermedades especificadas de la sangre y de los organos hematopoyeticos"
  },
  {
     id:"D759",
     enfermedad:"Enfermedad de la sangre y de los organos hematopoyeticos, no especificada"
  },
  {
     id:"D76",
     enfermedad:"Ciert enfermedades que afectanan el tejido linforreticular y al sistema reticuloendotelial"
  },
  {
     id:"D760",
     enfermedad:"Histiocitosis de las celulas de langerhans, no clasificada en otra parte"
  },
  {
     id:"D761",
     enfermedad:"Linfohistiocitosis hemofagocitica"
  },
  {
     id:"D762",
     enfermedad:"Sindrome hemofagocitico asociado a infeccion"
  },
  {
     id:"D763",
     enfermedad:"Otros sindromes histiociticos"
  },
  {
     id:"D77",
     enfermedad:"Otros trastornos de la sangre y de los organos hematopoyeticos en enfermedades clasificadas en otra parte"
  },
  {
     id:"D80",
     enfermedad:"Inmunodeficiencia con predominio de defectos de los anticuerpos"
  },
  {
     id:"D800",
     enfermedad:"Hipogammaglobulinemia hereditaria"
  },
  {
     id:"D801",
     enfermedad:"Hipogammaglobulinemia no familiar"
  },
  {
     id:"D802",
     enfermedad:"Deficiencia selectiva de inmunoglobulina a [iga]"
  },
  {
     id:"D803",
     enfermedad:"Deficiencia selectiva de subclases de la inmunoglobulina g [igg]"
  },
  {
     id:"D804",
     enfermedad:"Deficiencia selectiva de inmunoglobulina m [igm]"
  },
  {
     id:"D805",
     enfermedad:"Inmunodeficiencia con incremento de inmunoglobulina m [igm]"
  },
  {
     id:"D806",
     enfermedad:"Deficiencia de anticuerpos con inmunoglobulinas casi normales o con hiperinmunoglobulinemia"
  },
  {
     id:"D807",
     enfermedad:"Hipogammaglobulinemia transitoria de la infancia"
  },
  {
     id:"D808",
     enfermedad:"Otras inmunodeficiencias con predominio de defectos de los anticuerpos"
  },
  {
     id:"D809",
     enfermedad:"Inmunodeficiencia con predominio de defectos de los anticuerpos, no especificada"
  },
  {
     id:"D81",
     enfermedad:"Inmunodeficiencias combinadas"
  },
  {
     id:"D810",
     enfermedad:"Inmunodeficiencia combinada severa [idcs] con disgenesia reticular"
  },
  {
     id:"D811",
     enfermedad:"Inmunodeficiencia combinada severa [idcs] con linfocitopenia t y b"
  },
  {
     id:"D812",
     enfermedad:"Inmunodeficiencia combinada severa [idcs] con cifra baja o normal de linfocitos b"
  },
  {
     id:"D813",
     enfermedad:"Deficiencia de la adenosina deaminasa [ada]"
  },
  {
     id:"D814",
     enfermedad:"Sindrome de nezelof"
  },
  {
     id:"D815",
     enfermedad:"Deficiencia de la fosforilasa purinonucleosida [fpn]"
  },
  {
     id:"D816",
     enfermedad:"Deficiencia de la clase i del complejo de histocompatibilidad mayor"
  },
  {
     id:"D817",
     enfermedad:"Deficiencia de la clase ii del complejo de histocompatibilidad mayor"
  },
  {
     id:"D818",
     enfermedad:"Otras inmunodeficiencias combinadas"
  },
  {
     id:"D819",
     enfermedad:"Inmunodeficiencia combinada, no especificada"
  },
  {
     id:"D82",
     enfermedad:"Inmunodeficiencias asociadas a otros defectos mayores"
  },
  {
     id:"D820",
     enfermedad:"Sindrome de wiskott"
  },
  {
     id:"D821",
     enfermedad:"Sindrome de di george"
  },
  {
     id:"D822",
     enfermedad:"Inmunodeficiencia con enanismo micromelico [miembros cortos]"
  },
  {
     id:"D823",
     enfermedad:"Inmunodeficiencia consecutiva a respuesta defectuosa hereditaria contra el virus de epstein"
  },
  {
     id:"D824",
     enfermedad:"Sindrome de hiperinmunoglobulina e [ige]"
  },
  {
     id:"D828",
     enfermedad:"Inmunodeficiencia asociada con otros defectos mayores especificados"
  },
  {
     id:"D829",
     enfermedad:"Inmunodeficiencia asociada con otros defectos mayores no especificados"
  },
  {
     id:"D83",
     enfermedad:"Inmunodeficiencia variable comun"
  },
  {
     id:"D830",
     enfermedad:"Inmunodeficiencia variable comun con predominio de anormalidades en el numero y la funcion de los linfocitos b"
  },
  {
     id:"D831",
     enfermedad:"Inmunodeficiencia variable comun con predominio de trastornos inmunorreguladores de los linfocitos t"
  },
  {
     id:"D832",
     enfermedad:"Inmunodeficiencia variable comun con autoanticuerpos anti"
  },
  {
     id:"D838",
     enfermedad:"Otras inmunodeficiencias variables comunes"
  },
  {
     id:"D839",
     enfermedad:"Inmunodeficiencia variable comun, no especificada"
  },
  {
     id:"D84",
     enfermedad:"Otras inmunodeficiencias"
  },
  {
     id:"D840",
     enfermedad:"Defecto de la funcion del antigeno"
  },
  {
     id:"D841",
     enfermedad:"Defecto del sistema del complemento"
  },
  {
     id:"D848",
     enfermedad:"Otras inmunodeficiencias especificadas"
  },
  {
     id:"D849",
     enfermedad:"Inmunodeficiencia, no especificada"
  },
  {
     id:"D86",
     enfermedad:"Sarcoidosis"
  },
  {
     id:"D860",
     enfermedad:"Sarcoidosis del pulmon"
  },
  {
     id:"D861",
     enfermedad:"Sarcoidosis de los ganglios linfaticos"
  },
  {
     id:"D862",
     enfermedad:"Sarcoidosis del pulmon y de los ganglios linfaticos"
  },
  {
     id:"D863",
     enfermedad:"Sarcoidosis de la piel"
  },
  {
     id:"D868",
     enfermedad:"Sarcoidosis de otros sitios especificados o de sitios combinados"
  },
  {
     id:"D869",
     enfermedad:"Sarcoidosis de sitio no especificado"
  },
  {
     id:"D89",
     enfermedad:"Otros trastornos que afectanan el mecanismo de la inmunidad no clasificadas en otra parte"
  },
  {
     id:"D890",
     enfermedad:"Hipergammaglobulinemia policlonal"
  },
  {
     id:"D891",
     enfermedad:"Crioglobulinemia"
  },
  {
     id:"D892",
     enfermedad:"Hipergammaglobulinemia, no especificada"
  },
  {
     id:"D898",
     enfermedad:"Otros trastornos especificados que afectan el mecanismo de la inmunidad, no clasificados en otra parte"
  },
  {
     id:"D899",
     enfermedad:"Trastorno que afecta al mecanismo de la inmunidad, no especificado"
  },
  {
     id:"E00",
     enfermedad:"Sindrome congenito de deficiencia de yodo"
  },
  {
     id:"E000",
     enfermedad:"Sindrome congenito de deficiencia de yodo, tipo neurologico"
  },
  {
     id:"E001",
     enfermedad:"Sindrome de deficiencia congenita de yodo, tipo mixedematoso"
  },
  {
     id:"E002",
     enfermedad:"Sindrome congenito de deficiencia de yodo, tipo mixto"
  },
  {
     id:"E009",
     enfermedad:"Sindrome congenito de deficiencia de yodo, no especificado"
  },
  {
     id:"E01",
     enfermedad:"Trastornos tiroideos vinculados a deficiencia de yodo y afecciones relacionadas relacionadas"
  },
  {
     id:"E010",
     enfermedad:"Bocio difuso (endemico) relacionado con deficiencia de yodo"
  },
  {
     id:"E011",
     enfermedad:"Bocio multinodular (endemico) relacionado con deficiencia de yodo"
  },
  {
     id:"E012",
     enfermedad:"Bocio (endemico) relacionado con deficiencia de yodo, no especificado"
  },
  {
     id:"E018",
     enfermedad:"Otros trastornos de la tiroides relacionados con deficiencia de yodo y afecciones similares"
  },
  {
     id:"E02",
     enfermedad:"Hipotiroidismo subclinico por deficiencia yodo"
  },
  {
     id:"E03",
     enfermedad:"Otros hipotiroidismos"
  },
  {
     id:"E030",
     enfermedad:"Hipotiroidismo congenito con bocio difuso"
  },
  {
     id:"E031",
     enfermedad:"Hipotiroidismo congenito sin bocio"
  },
  {
     id:"E032",
     enfermedad:"Hipotiroidismo debido a medicamentos y a otras sustancias exogenas"
  },
  {
     id:"E033",
     enfermedad:"Hipotiroidismo postinfeccioso"
  },
  {
     id:"E034",
     enfermedad:"Atrofia de tiroides (adquirida)"
  },
  {
     id:"E035",
     enfermedad:"Coma mixedematoso"
  },
  {
     id:"E038",
     enfermedad:"Otros hipotiroidismos especificados"
  },
  {
     id:"E039",
     enfermedad:"Hipotiroidismo, no especificado"
  },
  {
     id:"E04",
     enfermedad:"Otros bocios no toxicos"
  },
  {
     id:"E040",
     enfermedad:"Bocio difuso no toxico"
  },
  {
     id:"E041",
     enfermedad:"Nodulo tiroideo solitario no toxico"
  },
  {
     id:"E042",
     enfermedad:"Bocio multinodular no toxico"
  },
  {
     id:"E048",
     enfermedad:"Otros bocios no toxicos especificados"
  },
  {
     id:"E049",
     enfermedad:"Bocio no toxico, no especificado"
  },
  {
     id:"E05",
     enfermedad:"Tirotoxicosis [ hipertiroidismo ]"
  },
  {
     id:"E050",
     enfermedad:"Tirotoxicosis con bocio difuso"
  },
  {
     id:"E051",
     enfermedad:"Tirotoxicosis con nodulo solitario tiroideo toxico"
  },
  {
     id:"E052",
     enfermedad:"Tirotoxicosis con bocio multinodular toxico"
  },
  {
     id:"E053",
     enfermedad:"Tirotoxicosis por tejido tiroideo ectopico"
  },
  {
     id:"E054",
     enfermedad:"Tirotoxicosis facticia"
  },
  {
     id:"E055",
     enfermedad:"Crisis o tormenta tirotoxica"
  },
  {
     id:"E058",
     enfermedad:"Otras tirotoxicosis"
  },
  {
     id:"E059",
     enfermedad:"Tirotoxicosis, no especificada"
  },
  {
     id:"E06",
     enfermedad:"Tiroiditis"
  },
  {
     id:"E060",
     enfermedad:"Tiroiditis aguda"
  },
  {
     id:"E061",
     enfermedad:"Tiroiditis subaguda"
  },
  {
     id:"E062",
     enfermedad:"Tiroiditis cronica con tirotoxicosis transitoria"
  },
  {
     id:"E063",
     enfermedad:"Tiroiditis autoinmune"
  },
  {
     id:"E064",
     enfermedad:"Tiroiditis inducida por drogas"
  },
  {
     id:"E065",
     enfermedad:"Otras tiroiditis cronicas"
  },
  {
     id:"E069",
     enfermedad:"Tiroiditis, no especificada"
  },
  {
     id:"E07",
     enfermedad:"Otros trastornos tiroideos"
  },
  {
     id:"E070",
     enfermedad:"Hipersecrecion de calcitonina"
  },
  {
     id:"E071",
     enfermedad:"Bocio dishormogenetico"
  },
  {
     id:"E078",
     enfermedad:"Otros trastornos especificados de la glandula tiroides"
  },
  {
     id:"E079",
     enfermedad:"Trastorno de la glandula tiroides, no especificado"
  },
  {
     id:"E10",
     enfermedad:"Diabetes mellitus insulinodependiente"
  },
  {
     id:"E100",
     enfermedad:"Diabetes mellitus insulinodependiente con coma"
  },
  {
     id:"E101",
     enfermedad:"Diabetes mellitus insulinodependiente con cetoacidosis"
  },
  {
     id:"E102",
     enfermedad:"Diabetes mellitus insulinodependiente con complicaciones renales"
  },
  {
     id:"E103",
     enfermedad:"Diabetes mellitus insulinodependiente con complicaciones oftalmicas"
  },
  {
     id:"E104",
     enfermedad:"Diabetes mellitus insulinodependiente con complicaciones neurologicas"
  },
  {
     id:"E105",
     enfermedad:"Diabetes mellitus insulinodependiente con complicaciones circulatorias perifericas"
  },
  {
     id:"E106",
     enfermedad:"Diabetes mellitus insulinodependiente con otras complicaciones especificadas"
  },
  {
     id:"E107",
     enfermedad:"Diabetes mellitus insulinodependiente con complicaciones multiples"
  },
  {
     id:"E108",
     enfermedad:"Diabetes mellitus insulinodependiente con complicaciones no especificadas"
  },
  {
     id:"E109",
     enfermedad:"Diabetes mellitus insulinodependiente sin mencion de complicacion"
  },
  {
     id:"E11",
     enfermedad:"Diabetes mellitus no insulinodependiente"
  },
  {
     id:"E110",
     enfermedad:"Diabetes mellitus no insulinodependiente con coma"
  },
  {
     id:"E111",
     enfermedad:"Diabetes mellitus no insulinodependiente con cetoacidosis"
  },
  {
     id:"E112",
     enfermedad:"Diabetes mellitus no insulinodependiente con complicaciones renales"
  },
  {
     id:"E113",
     enfermedad:"Diabetes mellitus no insulinodependiente con complicaciones oftalmicas"
  },
  {
     id:"E114",
     enfermedad:"Diabetes mellitus no insulinodependiente con complicaciones neurologicas"
  },
  {
     id:"E115",
     enfermedad:"Diabetes mellitus no insulinodependiente con complicaciones circulatorias perifericas"
  },
  {
     id:"E116",
     enfermedad:"Diabetes mellitus no insulinodependiente con otras complicaciones especificadas"
  },
  {
     id:"E117",
     enfermedad:"Diabetes mellitus no insulinodependiente con complicaciones multiples"
  },
  {
     id:"E118",
     enfermedad:"Diabetes mellitus no insulinodependiente con complicaciones no especificadas"
  },
  {
     id:"E119",
     enfermedad:"Diabetes mellitus no insulinodependiente sin mencion de complicacion"
  },
  {
     id:"E12",
     enfermedad:"Diabetes mellitus asociados con desnutricion"
  },
  {
     id:"E120",
     enfermedad:"Diabetes mellitus asociada con desnutricion con coma"
  },
  {
     id:"E121",
     enfermedad:"Diabetes mellitus asociada con desnutricion con cetoacidosis"
  },
  {
     id:"E122",
     enfermedad:"Diabetes mellitus asociada con desnutricion con complicaciones renales"
  },
  {
     id:"E123",
     enfermedad:"Diabetes mellitus asociada con desnutricion con complicaciones oftalmicas"
  },
  {
     id:"E124",
     enfermedad:"Diabetes mellitus asociada con desnutricion con complicaciones neurologicas"
  },
  {
     id:"E125",
     enfermedad:"Diabetes mellitus asociada con desnutricion con complicaciones circulatorias perifericas"
  },
  {
     id:"E126",
     enfermedad:"Diabetes mellitus asociada con desnutricion con otras complicaciones especificadas"
  },
  {
     id:"E127",
     enfermedad:"Diabetes mellitus asociada con desnutricion con complicaciones multiples"
  },
  {
     id:"E128",
     enfermedad:"Diabetes mellitus asociada con desnutricion con complicaciones no especificadas"
  },
  {
     id:"E129",
     enfermedad:"Diabetes mellitus asociada con desnutricion sin mencion de complicacion"
  },
  {
     id:"E13",
     enfermedad:"Otras diabetes mellitus especificadas"
  },
  {
     id:"E130",
     enfermedad:"Otras diabetes mellitus especificadas con coma"
  },
  {
     id:"E131",
     enfermedad:"Otras diabetes mellitus especificadas con cetoacidosis"
  },
  {
     id:"E132",
     enfermedad:"Otras diabetes mellitus especificadas con complicaciones renales"
  },
  {
     id:"E133",
     enfermedad:"Otras diabetes mellitus especificadas con complicaciones oftalmicas"
  },
  {
     id:"E134",
     enfermedad:"Otras diabetes mellitus especificadas con complicaciones neurologicas"
  },
  {
     id:"E135",
     enfermedad:"Otras diabetes mellitus especificadas con complicaciones circulatorias perifericas"
  },
  {
     id:"E136",
     enfermedad:"Otras diabetes mellitus especificadas con otras complicaciones especificadas"
  },
  {
     id:"E137",
     enfermedad:"Otras diabetes mellitus especificadas con complicaciones multiples"
  },
  {
     id:"E138",
     enfermedad:"Otras diabetes mellitus especificadas con complicaciones no especificadas"
  },
  {
     id:"E139",
     enfermedad:"Otras diabetes mellitus especificadas sin mencion de complicacion"
  },
  {
     id:"E14",
     enfermedad:"Diabetes mellitus no especificada"
  },
  {
     id:"E140",
     enfermedad:"Diabetes mellitus, no especificada con coma"
  },
  {
     id:"E141",
     enfermedad:"Diabetes mellitus, no especificada con cetoacidosis"
  },
  {
     id:"E142",
     enfermedad:"Diabetes mellitus, no especificada con complicaciones renales"
  },
  {
     id:"E143",
     enfermedad:"Diabetes mellitus, no especificada con complicaciones oftalmicas"
  },
  {
     id:"E144",
     enfermedad:"Diabetes mellitus, no especificada con complicaciones neurologicas"
  },
  {
     id:"E145",
     enfermedad:"Diabetes mellitus, no especificada con complicaciones circulatorias perifericas"
  },
  {
     id:"E146",
     enfermedad:"Diabetes mellitus, no especificada con otras complicaciones especificadas"
  },
  {
     id:"E147",
     enfermedad:"Diabetes mellitus, no especificada con complicaciones multiples"
  },
  {
     id:"E148",
     enfermedad:"Diabetes mellitus, no especificada con complicaciones no especificadas"
  },
  {
     id:"E149",
     enfermedad:"Diabetes mellitus, no especificada sin mencion de complicacion"
  },
  {
     id:"E15",
     enfermedad:"Coma hipoglicemico no diabetico"
  },
  {
     id:"E16",
     enfermedad:"Otros trastornos de la secrecion interna del pancreas"
  },
  {
     id:"E160",
     enfermedad:"Hipoglicemia sin coma, inducida por drogas"
  },
  {
     id:"E161",
     enfermedad:"Otras hipoglicemias"
  },
  {
     id:"E162",
     enfermedad:"Hipoglicemia , no especificada"
  },
  {
     id:"E163",
     enfermedad:"Secrecion exagerada del glucagon"
  },
  {
     id:"E164",
     enfermedad:"Secrecion anormal de gastrina"
  },
  {
     id:"E168",
     enfermedad:"Otros trastornos especificados de la secrecion interna del pancreas"
  },
  {
     id:"E169",
     enfermedad:"Trastornos de la secrecion interna del pancreas, sin otra especificacion"
  },
  {
     id:"E20",
     enfermedad:"Hipoparatiroidismo"
  },
  {
     id:"E200",
     enfermedad:"Hipoparatiroidismo idiopatico"
  },
  {
     id:"E201",
     enfermedad:"Pseudohipoparatiroidismo"
  },
  {
     id:"E208",
     enfermedad:"Otros tipos de hipoparatiroidismo"
  },
  {
     id:"E209",
     enfermedad:"Hipoparatiroidismo, no especificado"
  },
  {
     id:"E21",
     enfermedad:"Hiperparatiroidismo y otros trastornos de la glandula paratiroides"
  },
  {
     id:"E210",
     enfermedad:"Hiperparatiroidismo primario"
  },
  {
     id:"E211",
     enfermedad:"Hiperparatiroidismo secundario no clasificado en otra parte"
  },
  {
     id:"E212",
     enfermedad:"Otros tipos de hiperparatiroidismo"
  },
  {
     id:"E213",
     enfermedad:"Hiperparatiroidismo, sin otra especificacion"
  },
  {
     id:"E214",
     enfermedad:"Otros trastornos especificados de la glandula paratiroides"
  },
  {
     id:"E215",
     enfermedad:"Trastorno de la glandula paratiroides, no especificado"
  },
  {
     id:"E22",
     enfermedad:"Hiperfuncion de la glandula hipofisis"
  },
  {
     id:"E220",
     enfermedad:"Acromegalia y gigantismo hipofisario"
  },
  {
     id:"E221",
     enfermedad:"Hiperprolactinemia"
  },
  {
     id:"E222",
     enfermedad:"Sindrome de secrecion inapropiada de hormona antidiuretica"
  },
  {
     id:"E228",
     enfermedad:"Otras hiperfunciones de la glandula hipofisis"
  },
  {
     id:"E229",
     enfermedad:"Hiperfuncion de la glandula hipofisis, no especificada"
  },
  {
     id:"E23",
     enfermedad:"Hipofuncion y otros trastornos de la glandula hipofisis"
  },
  {
     id:"E230",
     enfermedad:"Hipopituitarismo"
  },
  {
     id:"E231",
     enfermedad:"Hipopituitarismo inducido por drogas"
  },
  {
     id:"E232",
     enfermedad:"Diabetes insipida"
  },
  {
     id:"E233",
     enfermedad:"Disfuncion hipotalamica, no clasificada en otra parte"
  },
  {
     id:"E236",
     enfermedad:"Otros trastornos de la glandula hipofisis"
  },
  {
     id:"E237",
     enfermedad:"Trastorno de la glandula hipofisis, no especificado"
  },
  {
     id:"E24",
     enfermedad:"Sindrome de cushing"
  },
  {
     id:"E240",
     enfermedad:"Enfermedad de cushing dependiente de la hipofisis"
  },
  {
     id:"E241",
     enfermedad:"Sindrome de nelson"
  },
  {
     id:"E242",
     enfermedad:"Sindrome de cushing inducido por drogas"
  },
  {
     id:"E243",
     enfermedad:"Sindrome de acth ectopico"
  },
  {
     id:"E244",
     enfermedad:"Sindrome de seudo"
  },
  {
     id:"E248",
     enfermedad:"Otros tipos de sindrome de cushing"
  },
  {
     id:"E249",
     enfermedad:"Sindrome de cushing, no especificado"
  },
  {
     id:"E25",
     enfermedad:"Trastornos adrenogenitales"
  },
  {
     id:"E250",
     enfermedad:"Trastornos adrenogenitales congenitos con deficiencia enzimatica"
  },
  {
     id:"E258",
     enfermedad:"Otros trastornos adrenogenitales"
  },
  {
     id:"E259",
     enfermedad:"Trastorno adrenogenital, no especificado"
  },
  {
     id:"E26",
     enfermedad:"Hiperaldosteronismo"
  },
  {
     id:"E260",
     enfermedad:"Hiperaldosteronismo primario"
  },
  {
     id:"E261",
     enfermedad:"Hiperaldosteronismo secundario"
  },
  {
     id:"E268",
     enfermedad:"Otros tipos de hiperaldosteronismo"
  },
  {
     id:"E269",
     enfermedad:"Hiperaldosteronismo, no especificado"
  },
  {
     id:"E27",
     enfermedad:"Otros trastornos de la glandula suprarrenal"
  },
  {
     id:"E270",
     enfermedad:"Otra hiperactividad corticosuprarrenal"
  },
  {
     id:"E271",
     enfermedad:"Insuficiencia corticosuprarrenal primaria"
  },
  {
     id:"E272",
     enfermedad:"Crisis addisoniana"
  },
  {
     id:"E273",
     enfermedad:"Insuficiencia corticosuprarrenal inducida por drogas"
  },
  {
     id:"E274",
     enfermedad:"Otras insuficiencias corticosuprarrenales y las no especificadas"
  },
  {
     id:"E275",
     enfermedad:"Hiperfuncion de la medula suprarrenal"
  },
  {
     id:"E278",
     enfermedad:"Otros trastornos especificados de la glandula suprarrenal"
  },
  {
     id:"E279",
     enfermedad:"Trastorno de la glandula suprarrenal, no especificado"
  },
  {
     id:"E28",
     enfermedad:"Disfuncion ovarica"
  },
  {
     id:"E280",
     enfermedad:"Exceso de estrogenos"
  },
  {
     id:"E281",
     enfermedad:"Exceso de androgenos"
  },
  {
     id:"E282",
     enfermedad:"Sindrome de ovario poliquistico"
  },
  {
     id:"E283",
     enfermedad:"Insuficiencia ovarica primaria"
  },
  {
     id:"E288",
     enfermedad:"Otras disfunciones ovaricas"
  },
  {
     id:"E289",
     enfermedad:"Disfuncion ovarica, no especificada"
  },
  {
     id:"E29",
     enfermedad:"Disfuncion testicular"
  },
  {
     id:"E290",
     enfermedad:"Hiperfuncion testicular"
  },
  {
     id:"E291",
     enfermedad:"Hipofuncion testicular"
  },
  {
     id:"E298",
     enfermedad:"Otras disfunciones testiculares"
  },
  {
     id:"E299",
     enfermedad:"Disfuncion testicular, no especificada"
  },
  {
     id:"E30",
     enfermedad:"Trastornos de la pubertad no clasificadas en otra parte"
  },
  {
     id:"E300",
     enfermedad:"Pubertad retardada"
  },
  {
     id:"E301",
     enfermedad:"Pubertad precoz"
  },
  {
     id:"E308",
     enfermedad:"Otros trastornos de la pubertad"
  },
  {
     id:"E309",
     enfermedad:"Trastorno de la pubertad, no especificado"
  },
  {
     id:"E31",
     enfermedad:"Disfuncion poliglandular"
  },
  {
     id:"E310",
     enfermedad:"Insuficiencia piloglandular autoinmune"
  },
  {
     id:"E311",
     enfermedad:"Hiperfuncion poliglandular"
  },
  {
     id:"E318",
     enfermedad:"Otras disfunciones poliglandulares"
  },
  {
     id:"E319",
     enfermedad:"Disfuncion poliglandular, no especificada"
  },
  {
     id:"E32",
     enfermedad:"Enfermedades del timo"
  },
  {
     id:"E320",
     enfermedad:"Hiperplasia persistente del timo"
  },
  {
     id:"E321",
     enfermedad:"Absceso del timo"
  },
  {
     id:"E328",
     enfermedad:"Otras enfermedades del timo"
  },
  {
     id:"E329",
     enfermedad:"Enfermedad del timo, no especificada"
  },
  {
     id:"E34",
     enfermedad:"Otros trastornos endocrinos"
  },
  {
     id:"E340",
     enfermedad:"Sindrome carcinoide"
  },
  {
     id:"E341",
     enfermedad:"Otras hipersecreciones de hormonas intestinales"
  },
  {
     id:"E342",
     enfermedad:"Secrecion hormonal ectopica, no clasificada en otra parte"
  },
  {
     id:"E343",
     enfermedad:"Enanismo, no clasificado en otra parte"
  },
  {
     id:"E344",
     enfermedad:"Estatura alta constitucional"
  },
  {
     id:"E345",
     enfermedad:"Sindrome de resistencia androgenica"
  },
  {
     id:"E348",
     enfermedad:"Otros trastornos endocrinos especificados"
  },
  {
     id:"E349",
     enfermedad:"Trastorno endocrino, no especificado"
  },
  {
     id:"E35",
     enfermedad:"Trastornos endocrinos en enfermedades clasificados en otra parte"
  },
  {
     id:"E350",
     enfermedad:"Trastornos de la glandula tiroides en enfermedades clasificadas en otra parte"
  },
  {
     id:"E351",
     enfermedad:"Trastornos de la glandulas suprarrenales en enfermedades clasificadas en otra parte"
  },
  {
     id:"E358",
     enfermedad:"Trastornos de otras glandulas endocrinas en enfermedades clasificadas en otra parte"
  },
  {
     id:"E40",
     enfermedad:"Kwashiorkor"
  },
  {
     id:"E41",
     enfermedad:"Marasmo nutricional"
  },
  {
     id:"E42",
     enfermedad:"Kwashiorkor marasmatico"
  },
  {
     id:"E43",
     enfermedad:"Desnutricion protcalorica severa no especificada"
  },
  {
     id:"E44",
     enfermedad:"Desnutricion protcalorica de grado moderado y leve"
  },
  {
     id:"E440",
     enfermedad:"Desnutricion proteicocalorica moderada"
  },
  {
     id:"E441",
     enfermedad:"Desnutricion proteicocalorica leve"
  },
  {
     id:"E45",
     enfermedad:"Retardo del desarrollo debidas a desnutricion protcalorico"
  },
  {
     id:"E46",
     enfermedad:"Desnutricion protcalorica no especificada"
  },
  {
     id:"E50",
     enfermedad:"Deficiencia de vitamina a"
  },
  {
     id:"E500",
     enfermedad:"Deficiencia de vitamina a con xerosis conjuntival"
  },
  {
     id:"E501",
     enfermedad:"Deficiencia de vitamina a con mancha de bitot y xerosis conjuntival"
  },
  {
     id:"E502",
     enfermedad:"Deficiencia de vitamina a con xerosis corneal"
  },
  {
     id:"E503",
     enfermedad:"Deficiencia de vitamina a con ulceracion corneal y xerosis"
  },
  {
     id:"E504",
     enfermedad:"Deficiencia de vitamina a con queratomalacia"
  },
  {
     id:"E505",
     enfermedad:"Deficiencia de vitamina a con ceguera nocturna"
  },
  {
     id:"E506",
     enfermedad:"Deficiencia de vitamina a con cicatrices xeroftalmicas de la cornea"
  },
  {
     id:"E507",
     enfermedad:"Otras manifestaciones oculares de deficiencia de vitamina a"
  },
  {
     id:"E508",
     enfermedad:"Otras manifestaciones de deficiencia de vitamina a"
  },
  {
     id:"E509",
     enfermedad:"Deficiencia de vitamina a, no especificada"
  },
  {
     id:"E51",
     enfermedad:"Deficiencia de tiamina"
  },
  {
     id:"E511",
     enfermedad:"Beriberi"
  },
  {
     id:"E512",
     enfermedad:"Encefalopatia de wernicke"
  },
  {
     id:"E518",
     enfermedad:"Otras manifestaciones de la deficiencia de tiamina"
  },
  {
     id:"E519",
     enfermedad:"Deficiencia de tiamina, no especificada"
  },
  {
     id:"E52",
     enfermedad:"Deficiencia de niacina"
  },
  {
     id:"E53",
     enfermedad:"Deficiencia de otras vitaminas del grupo b"
  },
  {
     id:"E530",
     enfermedad:"Deficiencia de riboflavina"
  },
  {
     id:"E531",
     enfermedad:"Deficiencia de piridoxina"
  },
  {
     id:"E538",
     enfermedad:"Deficiencia de otras vitaminas del grupo b"
  },
  {
     id:"E539",
     enfermedad:"Deficiencia de vitamina b, no especificada"
  },
  {
     id:"E54",
     enfermedad:"Deficiencia de acido ascorbico"
  },
  {
     id:"E55",
     enfermedad:"Deficiencia de vitamina d"
  },
  {
     id:"E550",
     enfermedad:"Raquitismo activo"
  },
  {
     id:"E559",
     enfermedad:"Deficiencia de vitamina d, no especificada"
  },
  {
     id:"E56",
     enfermedad:"Otras deficiencias de vitaminas"
  },
  {
     id:"E560",
     enfermedad:"Deficiencia de vitamina e"
  },
  {
     id:"E561",
     enfermedad:"Deficiencia de vitamina k"
  },
  {
     id:"E568",
     enfermedad:"Deficiencia de otras vitaminas"
  },
  {
     id:"E569",
     enfermedad:"Deficiencia de vitamina, no especificada"
  },
  {
     id:"E58",
     enfermedad:"Deficiencia dietetica de calcio"
  },
  {
     id:"E59",
     enfermedad:"Deficiencia dietetica de selenio"
  },
  {
     id:"E60",
     enfermedad:"Deficiencia dietetica de zinc"
  },
  {
     id:"E61",
     enfermedad:"Deficiencia de otros elementos nutricionales"
  },
  {
     id:"E610",
     enfermedad:"Deficiencia de cobre"
  },
  {
     id:"E611",
     enfermedad:"Deficiencia de hierro"
  },
  {
     id:"E612",
     enfermedad:"Deficiencia de magnesio"
  },
  {
     id:"E613",
     enfermedad:"Deficiencia de manganeso"
  },
  {
     id:"E614",
     enfermedad:"Deficiencia de cromo"
  },
  {
     id:"E615",
     enfermedad:"Deficiencia de molibdeno"
  },
  {
     id:"E616",
     enfermedad:"Deficiencia de vanadio"
  },
  {
     id:"E617",
     enfermedad:"Deficiencia de multiples elementos nutricionales"
  },
  {
     id:"E618",
     enfermedad:"Deficiencia de otros elementos nutricionales especificados"
  },
  {
     id:"E619",
     enfermedad:"Deficiencia de otro elemento nutricional, no especificado"
  },
  {
     id:"E63",
     enfermedad:"Otras deficiencias nutricionales"
  },
  {
     id:"E630",
     enfermedad:"Deficiencia de acidos grasos esenciales [age]"
  },
  {
     id:"E631",
     enfermedad:"Desequilibrio de los constituyentes en la dieta"
  },
  {
     id:"E638",
     enfermedad:"Otras deficiencias nutricionales especificadas"
  },
  {
     id:"E639",
     enfermedad:"Deficiencia nutricional, no especificada"
  },
  {
     id:"E64",
     enfermedad:"Secuelas de la desnutricion y otras deficiencias nutricionales"
  },
  {
     id:"E640",
     enfermedad:"Secuelas de la desnutricion proteicocalorica"
  },
  {
     id:"E641",
     enfermedad:"Secuelas de la deficiencia de vitamina a"
  },
  {
     id:"E642",
     enfermedad:"Secuelas de la deficiencia de vitamina c"
  },
  {
     id:"E643",
     enfermedad:"Secuelas del raquitismo"
  },
  {
     id:"E648",
     enfermedad:"Secuelas de otras deficiencias nutricionales"
  },
  {
     id:"E649",
     enfermedad:"Secuelas de la deficiencia nutricional no especificada"
  },
  {
     id:"E65",
     enfermedad:"Adiposidad localizada"
  },
  {
     id:"E66",
     enfermedad:"Obesidad"
  },
  {
     id:"E660",
     enfermedad:"Obesidad debida a exceso de calorias"
  },
  {
     id:"E661",
     enfermedad:"Obesidad inducida por drogas"
  },
  {
     id:"E662",
     enfermedad:"Obesidad extrema con hipoventilacion alveolar"
  },
  {
     id:"E668",
     enfermedad:"Otros tipos de obesidad"
  },
  {
     id:"E669",
     enfermedad:"Obesidad, no especificada"
  },
  {
     id:"E67",
     enfermedad:"Otros tipos de hiperalimentacion"
  },
  {
     id:"E670",
     enfermedad:"Hipervitaminosis a"
  },
  {
     id:"E671",
     enfermedad:"Hipercarotinemia"
  },
  {
     id:"E672",
     enfermedad:"Sindrome de megavitamina b6"
  },
  {
     id:"E673",
     enfermedad:"Hipervitaminosis d"
  },
  {
     id:"E678",
     enfermedad:"Otros tipos de hiperalimentacion especificados"
  },
  {
     id:"E68",
     enfermedad:"Secuelas de hiperalimentacion"
  },
  {
     id:"E70",
     enfermedad:"Trastornos del metabolismo de los aminoacidos aromaticos"
  },
  {
     id:"E700",
     enfermedad:"Fenilcetonuria clasica"
  },
  {
     id:"E701",
     enfermedad:"Otras hiperfenilalaninemias"
  },
  {
     id:"E702",
     enfermedad:"Trastornos del metabolismo de la tirosina"
  },
  {
     id:"E703",
     enfermedad:"Albinismo"
  },
  {
     id:"E708",
     enfermedad:"Otros trastornos del metabolismo de los aminoacidos aromaticos"
  },
  {
     id:"E709",
     enfermedad:"Trastorno del metabolismo de los aminoacidos aromaticos, no especificado"
  },
  {
     id:"E71",
     enfermedad:"Trastornos del metabolismo de los aminoacidos de cadena ramificada y de los acidos grasos"
  },
  {
     id:"E710",
     enfermedad:"Enfermedad de la orina en jarabe de arce"
  },
  {
     id:"E711",
     enfermedad:"Otros trastornos del metabolismo de los aminoacidos de cadena ramificada"
  },
  {
     id:"E712",
     enfermedad:"Otros trastornos del metabolismo de los aminoacidos de cadena ramificada, no especificados"
  },
  {
     id:"E713",
     enfermedad:"Trastornos del metabolismo de los acidos grasos"
  },
  {
     id:"E72",
     enfermedad:"Otros trastornos del metabolismo de los aminoacidos"
  },
  {
     id:"E720",
     enfermedad:"Trastornos del transporte de los aminoacidos"
  },
  {
     id:"E721",
     enfermedad:"Trastorno del metabolismo de los aminoacidos azufrados"
  },
  {
     id:"E722",
     enfermedad:"Trastornos del metabolismo del ciclo de la urea"
  },
  {
     id:"E723",
     enfermedad:"Trastornos del metabolismo de la lisina y la hidroxilisina"
  },
  {
     id:"E724",
     enfermedad:"Trastorno del metabolismo de la ornitina"
  },
  {
     id:"E725",
     enfermedad:"Trastorno del metabolismo de la glicina"
  },
  {
     id:"E728",
     enfermedad:"Otros trastornos especificados del metabolismo de los aminoacidos"
  },
  {
     id:"E729",
     enfermedad:"Trastorno del metabolismo de los aminoacidos, no especificado"
  },
  {
     id:"E73",
     enfermedad:"Intolerancia a la lactosa"
  },
  {
     id:"E730",
     enfermedad:"Deficiencia congenita de lactasa"
  },
  {
     id:"E731",
     enfermedad:"Deficiencia secundaria de lactasa"
  },
  {
     id:"E738",
     enfermedad:"Otros tipos de intolerancia a la lactosa"
  },
  {
     id:"E739",
     enfermedad:"Intolerancia a la lactosa, no especificada"
  },
  {
     id:"E74",
     enfermedad:"Otros trastornos del metabolismo de los carbohidratos"
  },
  {
     id:"E740",
     enfermedad:"Enfermedad del almacenamiento de glucogeno"
  },
  {
     id:"E741",
     enfermedad:"Trastornos del metabolismo de la fructosa"
  },
  {
     id:"E742",
     enfermedad:"Trastorno del metabolismo de la galactosa"
  },
  {
     id:"E743",
     enfermedad:"Otros trastornos de la absorcion intestinal de carbohidratos"
  },
  {
     id:"E744",
     enfermedad:"Trastornos del metabolismo del piruvato y de las gluconeogenesis"
  },
  {
     id:"E748",
     enfermedad:"Otros trastornos especificados del metabolismo de los carbohidratos"
  },
  {
     id:"E749",
     enfermedad:"Trastorno del metabolismo de los carbohidratos, no especificado"
  },
  {
     id:"E75",
     enfermedad:"Trastornos del metabolismo de los esfingolipidos y otros trastornos por almacenamiento de lipidos"
  },
  {
     id:"E750",
     enfermedad:"Gangliosidosis gm2"
  },
  {
     id:"E751",
     enfermedad:"Otras gangliosidosis"
  },
  {
     id:"E752",
     enfermedad:"Otras esfingolipidosis"
  },
  {
     id:"E753",
     enfermedad:"Esfingolipidosis, no especificada"
  },
  {
     id:"E754",
     enfermedad:"Lipofuscinosis ceroide neuronal"
  },
  {
     id:"E755",
     enfermedad:"Otros trastornos del almacenamiento de lipidos"
  },
  {
     id:"E756",
     enfermedad:"Trastornos de almacenamiento de lipidos, no especificado"
  },
  {
     id:"E76",
     enfermedad:"Trastornos del metabolismo de los glucosaminoglicanos"
  },
  {
     id:"E760",
     enfermedad:"Mucopolisacaridosis tipo i"
  },
  {
     id:"E761",
     enfermedad:"Mucopolisacaridosis tipo ii"
  },
  {
     id:"E762",
     enfermedad:"Otras mucopolisacaridosis"
  },
  {
     id:"E763",
     enfermedad:"Mucopolisacaridosis no especificada"
  },
  {
     id:"E768",
     enfermedad:"Otro trastornos del metabolismo de los glucosaminoglicanos"
  },
  {
     id:"E769",
     enfermedad:"Trastorno del metabolismo de los glucosaminoglicanos, no especificado"
  },
  {
     id:"E77",
     enfermedad:"Trastornos del metabolismo de las glucoproteinas"
  },
  {
     id:"E770",
     enfermedad:"Defectos en la modificacion postraslacional de enzimas lisosomales"
  },
  {
     id:"E771",
     enfermedad:"Defectos de la degradacion de glucoproteinas"
  },
  {
     id:"E778",
     enfermedad:"Otros trastornos del metabolismo de las glucoproteinas"
  },
  {
     id:"E779",
     enfermedad:"Trastorno del metabolismo de las glucoproteinas, no especificado"
  },
  {
     id:"E78",
     enfermedad:"Trastornosdel metabolismo de las lipoproteinas y otras lipidemias"
  },
  {
     id:"E780",
     enfermedad:"Hipercolesterolemia pura"
  },
  {
     id:"E781",
     enfermedad:"Hipergliceridemia pura"
  },
  {
     id:"E782",
     enfermedad:"Hiperlipidemia mixta"
  },
  {
     id:"E783",
     enfermedad:"Hiperquilomicronemia"
  },
  {
     id:"E784",
     enfermedad:"Otra hiperlipidemia"
  },
  {
     id:"E785",
     enfermedad:"Hiperlipidemia no especificada"
  },
  {
     id:"E786",
     enfermedad:"Deficiencia de lipoproteinas"
  },
  {
     id:"E788",
     enfermedad:"Otros trastornos del metabolismo de las lipoproteinas"
  },
  {
     id:"E789",
     enfermedad:"Trastorno del metabolismo de las lipoproteinas, no especificado"
  },
  {
     id:"E79",
     enfermedad:"Trastornos del metabolismo de las purinas y de las pirimidinas"
  },
  {
     id:"E790",
     enfermedad:"Hiperuricemia sin signos de artritis inflamatoria y enfermedad tofacea"
  },
  {
     id:"E791",
     enfermedad:"Sindrome de lesch"
  },
  {
     id:"E798",
     enfermedad:"Otros trastornos del metabolismo de las purinas y de las pirimidinas"
  },
  {
     id:"E799",
     enfermedad:"Trastornos del metabolismo de las purinas y de las pirimidinas, no especificado"
  },
  {
     id:"E80",
     enfermedad:"Trastornos del metabolismo de las porfirinas y de la bilirrubina"
  },
  {
     id:"E800",
     enfermedad:"Porfiria eritropoyetica hereditaria"
  },
  {
     id:"E801",
     enfermedad:"Porfiria cutanea tardia"
  },
  {
     id:"E802",
     enfermedad:"Otras porfirias"
  },
  {
     id:"E803",
     enfermedad:"Defectos de catalasa y peroxidasa"
  },
  {
     id:"E804",
     enfermedad:"Sindrome de gilbert"
  },
  {
     id:"E805",
     enfermedad:"Sindrome de crigler"
  },
  {
     id:"E806",
     enfermedad:"Otros trastornos del metabolismo de la bilirrubina"
  },
  {
     id:"E807",
     enfermedad:"Trastornos del metabolismo de la bilirrubina, no especificado"
  },
  {
     id:"E83",
     enfermedad:"Trastornos del metabolismo de los minerales"
  },
  {
     id:"E830",
     enfermedad:"Trastornos del metabolismo del cobre"
  },
  {
     id:"E831",
     enfermedad:"Trastornos del metabolismo del hierro"
  },
  {
     id:"E832",
     enfermedad:"Trastornos del metabolismo del zinc"
  },
  {
     id:"E833",
     enfermedad:"Trastornos del metabolismo del fosforo"
  },
  {
     id:"E834",
     enfermedad:"Trastornos del metabolismo del magnesio"
  },
  {
     id:"E835",
     enfermedad:"Trastornos del metabolismo del calcio"
  },
  {
     id:"E838",
     enfermedad:"Otros trastornos del metabolismo de los minerales"
  },
  {
     id:"E839",
     enfermedad:"Trastorno del metabolismo de los minerales, no especificado"
  },
  {
     id:"E84",
     enfermedad:"Fibrosis quistica"
  },
  {
     id:"E840",
     enfermedad:"Fibrosis quistica con manifestaciones pulmonares"
  },
  {
     id:"E841",
     enfermedad:"Fibrosis quistica con manifestaciones intestinales"
  },
  {
     id:"E848",
     enfermedad:"Fibrosis quistica con otras manifestaciones"
  },
  {
     id:"E849",
     enfermedad:"Fibrosis quistica, sin otra especificacion"
  },
  {
     id:"E85",
     enfermedad:"Amiloidosis"
  },
  {
     id:"E850",
     enfermedad:"Amiloidosis heredofamiliar no neuropatica"
  },
  {
     id:"E851",
     enfermedad:"Amiloidosis heredofamiliar neuropatica"
  },
  {
     id:"E852",
     enfermedad:"Amiloidosis heredofamiliar, no especificada"
  },
  {
     id:"E853",
     enfermedad:"Amiloidosis sistemica secundaria"
  },
  {
     id:"E854",
     enfermedad:"Amiloidosis limitada a un organo"
  },
  {
     id:"E858",
     enfermedad:"Otras amiloidosis"
  },
  {
     id:"E859",
     enfermedad:"Amiloidosis, no especificada"
  },
  {
     id:"E86",
     enfermedad:"Deplecion del volumen"
  },
  {
     id:"E87",
     enfermedad:"Otros trastornos de los liquidos, de los electrolitos y del equilibrio acido"
  },
  {
     id:"E870",
     enfermedad:"Hiperosmolaridad e hipernatremia"
  },
  {
     id:"E871",
     enfermedad:"Hiposmolaridad e hipernatremia"
  },
  {
     id:"E872",
     enfermedad:"Acidosis"
  },
  {
     id:"E873",
     enfermedad:"Alcalosis"
  },
  {
     id:"E874",
     enfermedad:"Trastornos mixtos del balance acido"
  },
  {
     id:"E875",
     enfermedad:"Hiperpotasemia"
  },
  {
     id:"E876",
     enfermedad:"Hipopotasmia"
  },
  {
     id:"E877",
     enfermedad:"Sobrecarga de liquidos"
  },
  {
     id:"E878",
     enfermedad:"Otros trastornos del equilibrio de los electrolitos y de los liquidos, no clasificados en otra parte"
  },
  {
     id:"E88",
     enfermedad:"Otros trastornos metabolicos"
  },
  {
     id:"E880",
     enfermedad:"Trastornos del metabolismo de las proteinas plasmaticas, no clasificados en otra parte"
  },
  {
     id:"E881",
     enfermedad:"Lipodistrofia, no clasificada en otra parte"
  },
  {
     id:"E882",
     enfermedad:"Lipomatosis, no clasificada en otra parte"
  },
  {
     id:"E888",
     enfermedad:"Otros trastornos especificados del metabolismo"
  },
  {
     id:"E889",
     enfermedad:"Trastorno metabolico, no especificado"
  },
  {
     id:"E89",
     enfermedad:"Trastornos endocrinos metabolicos consecutivos a procedimientos no clasificados en otra parte"
  },
  {
     id:"E890",
     enfermedad:"Hipotiroidismo consecutivo a procedimientos"
  },
  {
     id:"E891",
     enfermedad:"Hipoinsulinemia consecutiva a procedimientos"
  },
  {
     id:"E892",
     enfermedad:"Hipoparatiroidismo consecutivo a procedimientos"
  },
  {
     id:"E893",
     enfermedad:"Hipopituitarismo consecutivo a procedimientos"
  },
  {
     id:"E894",
     enfermedad:"Insuficiencia ovarica consecutiva a procedimientos"
  },
  {
     id:"E895",
     enfermedad:"Hipofuncion testicular consecutiva a procedimientos"
  },
  {
     id:"E896",
     enfermedad:"Hipofuncion adrenocortical [medula suprarrenal] consecutiva a procedimientos"
  },
  {
     id:"E898",
     enfermedad:"Otros trastornos metabolicos y endocrinos consecutivo a procedimientos"
  },
  {
     id:"E899",
     enfermedad:"Trastorno endocrino y metabolico consecutivo a procedimientos, no especificado"
  },
  {
     id:"E90",
     enfermedad:"Trastornos nutricionales metabolicos en enfermedades clasificadas en otra parte"
  },
  {
     id:"F00",
     enfermedad:"Demencia en la enfermedad de alzheimer"
  },
  {
     id:"F000",
     enfermedad:"Demencia en la enfermedad de alzheimer, de comienzo temprano (g30.0)"
  },
  {
     id:"F001",
     enfermedad:"Demencia en la enfermedad de alzheimer, de comienzo tardio (g30.1)"
  },
  {
     id:"F002",
     enfermedad:"Demencia en la enfermedad de alzheimer, atipica o de tipo mixto (g30.8)"
  },
  {
     id:"F009",
     enfermedad:"Demencia en la enfermedad de alzheimer, no especificada (g30.9)"
  },
  {
     id:"F01",
     enfermedad:"Demencia vascular"
  },
  {
     id:"F010",
     enfermedad:"Demencia vascular de comienzo agudo"
  },
  {
     id:"F011",
     enfermedad:"Demencia vascular por infartos multiples"
  },
  {
     id:"F012",
     enfermedad:"Demencia vascular subcortical"
  },
  {
     id:"F013",
     enfermedad:"Demencia vascular mixta, cortical y subcortical"
  },
  {
     id:"F018",
     enfermedad:"Otras demencias vasculares"
  },
  {
     id:"F019",
     enfermedad:"Demencia vascular, no especificada"
  },
  {
     id:"F02",
     enfermedad:"Demencia en otras enfermedades clasificadas otra parte"
  },
  {
     id:"F020",
     enfermedad:"Demencia en la enfermedad de pick (g31.0)"
  },
  {
     id:"F021",
     enfermedad:"Demencia en la enfermedad de creutzfeldt"
  },
  {
     id:"F022",
     enfermedad:"Demencia en la enfermedad de huntington (g10)"
  },
  {
     id:"F023",
     enfermedad:"Demencia en la enfermedad de parkinson (g20)"
  },
  {
     id:"F024",
     enfermedad:"Demencia en la enfermedad por virus de la inmunodeficiencia humana [vih] (b22.0)"
  },
  {
     id:"F028",
     enfermedad:"Demencia en otras enfermedades especificadas clasificadas en otra parte"
  },
  {
     id:"F03",
     enfermedad:"Demencia no especificada"
  },
  {
     id:"F04",
     enfermedad:"Sindrome amnesico organico, no inducido por el alcohol o por otras sustancias psicoactivas"
  },
  {
     id:"F05",
     enfermedad:"Delirio, no inducido por el alcohol o por otras sustancias psicoactivas"
  },
  {
     id:"F050",
     enfermedad:"Delirio no superpuesto a un cuadro de demencia, asi descrito"
  },
  {
     id:"F051",
     enfermedad:"Delirio superpuesto a un cuadro de demencia"
  },
  {
     id:"F058",
     enfermedad:"Otros delirios"
  },
  {
     id:"F059",
     enfermedad:"Delirio, no especificado"
  },
  {
     id:"F06",
     enfermedad:"Otros trastornos mentales debidos a lesion y disfuncion cerebral, y a enfermedad fisica"
  },
  {
     id:"F060",
     enfermedad:"Alucinosis organica"
  },
  {
     id:"F061",
     enfermedad:"Trastorno catatonico, organico"
  },
  {
     id:"F062",
     enfermedad:"Trastorno delirante [esquizofreniforme], organico"
  },
  {
     id:"F063",
     enfermedad:"Trastornos del humor [afectivos], organicos"
  },
  {
     id:"F064",
     enfermedad:"Trastorno de ansiedad, organico"
  },
  {
     id:"F065",
     enfermedad:"Trastorno disociativo, organico"
  },
  {
     id:"F066",
     enfermedad:"Trastorno de labilidad emocional [astenico], organico"
  },
  {
     id:"F067",
     enfermedad:"Trastorno cognoscitivo leve"
  },
  {
     id:"F068",
     enfermedad:"Otros trastornos mentales especificados debidos a lesion y disfuncion cerebral y a enfermedad fisica"
  },
  {
     id:"F069",
     enfermedad:"Trastorno mental no especificado debido a lesion y disfuncion cerebral y a enfermedad fisica"
  },
  {
     id:"F07",
     enfermedad:"Trastornos de la personalidad y del comportamiento debidos a enfermedad, lesion o disfuncion cerebral"
  },
  {
     id:"F070",
     enfermedad:"Trastorno de la personalidad, organico"
  },
  {
     id:"F071",
     enfermedad:"Sindrome postencefalitico"
  },
  {
     id:"F072",
     enfermedad:"Sindrome postconcusional"
  },
  {
     id:"F078",
     enfermedad:"Otros trastornos organicos de la personalidad y del comportamiento debidos a enfermedad, lesion y disfuncion cerebrales"
  },
  {
     id:"F079",
     enfermedad:"Trastorno organico de la personalidad y del comportamiento, no especificado, debido a enfermedad, lesion y disfuncion cerebral"
  },
  {
     id:"F09",
     enfermedad:"Trastorno mental organico o sintomatico, no especificado"
  },
  {
     id:"F10",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso del alcohol"
  },
  {
     id:"F100",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso del alcohol: intoxicacion aguda"
  },
  {
     id:"F101",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso del alcohol: uso nocivo"
  },
  {
     id:"F102",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso del alcohol: sindrome de dependencia"
  },
  {
     id:"F103",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso del alcohol: estado de abstinencia"
  },
  {
     id:"F104",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso del alcohol: estado de abstinencia con delirio"
  },
  {
     id:"F105",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso del alcohol: trastorno psicotico"
  },
  {
     id:"F106",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso del alcohol: sindrome amnesico"
  },
  {
     id:"F107",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso del alcohol: trastorno psicotico residual y de comienzo tardio"
  },
  {
     id:"F108",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso del alcohol: otros trastornos mentales y del comportamiento"
  },
  {
     id:"F109",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso del alcohol: trastorno mental y del comportamiento, no especificado"
  },
  {
     id:"F11",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de opiaceos"
  },
  {
     id:"F110",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de opiaceos: intoxicacion aguda"
  },
  {
     id:"F111",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de opiaceos: uso nocivo"
  },
  {
     id:"F112",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de opiaceos: sindrome de dependencia"
  },
  {
     id:"F113",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de opiaceos: estado de abstinencia"
  },
  {
     id:"F114",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de opiaceos: estado de abstinencia con delirio"
  },
  {
     id:"F115",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de opiaceos: trastorno psicotico"
  },
  {
     id:"F116",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de opiaceos: sindrome amnesico"
  },
  {
     id:"F117",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de opiaceos: trastorno psicotico residual y de comienzo tardio"
  },
  {
     id:"F118",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de opiaceos: otros trastornos mentales y del comportamiento"
  },
  {
     id:"F119",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de opiaceos: trastorno mental y del comportamiento, no especificado"
  },
  {
     id:"F12",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de cannabinoides"
  },
  {
     id:"F120",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de cannabinoides: intoxicacion aguda"
  },
  {
     id:"F121",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de cannabinoides: uso nocivo"
  },
  {
     id:"F122",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de cannabinoides: sindrome de dependencia"
  },
  {
     id:"F123",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de cannabinoides: estado de abstinencia"
  },
  {
     id:"F124",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de cannabinoides: estado de abstinencia con delirio"
  },
  {
     id:"F125",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de cannabinoides: trastorno psicotico"
  },
  {
     id:"F126",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de cannabinoides: sindrome amnesico"
  },
  {
     id:"F127",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de cannabinoides: trastorno psicotico residual y de comienzo tardio"
  },
  {
     id:"F128",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de cannabinoides: otros trastornos mentales y del comportamiento"
  },
  {
     id:"F129",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de cannabinoides: trastorno mental y del comportamiento, no especificado"
  },
  {
     id:"F13",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de sedantes o hipnoticos"
  },
  {
     id:"F130",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de sedantes o hipnoticos: intoxicacion aguda"
  },
  {
     id:"F131",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de sedantes o hipnoticos: uso nocivo"
  },
  {
     id:"F132",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de sedantes o hipnoticos: sindrome de dependencia"
  },
  {
     id:"F133",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de sedantes o hipnoticos: estado de abstinencia"
  },
  {
     id:"F134",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de sedantes o hipnoticos: estado de abstinencia con delirio"
  },
  {
     id:"F135",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de sedantes o hipnoticos: trastorno psicotico"
  },
  {
     id:"F136",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de sedantes o hipnoticos: sindrome amnesico"
  },
  {
     id:"F137",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de sedantes o hipnoticos: trastorno psicotico residual y de comienzo tardio"
  },
  {
     id:"F138",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de sedantes o hipnoticos: otros trastornos mentales y del comportamiento"
  },
  {
     id:"F139",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de sedantes o hipnoticos: trastorno mental y del comportamiento, no especificado"
  },
  {
     id:"F14",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de cocaina"
  },
  {
     id:"F140",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de cocaina: intoxicacion aguda"
  },
  {
     id:"F141",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de cocaina: uso nocivo"
  },
  {
     id:"F142",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de cocaina: sindrome de dependencia"
  },
  {
     id:"F143",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de cocaina: estado de abstinencia"
  },
  {
     id:"F144",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de cocaina: estado de abstinencia con delirio"
  },
  {
     id:"F145",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de cocaina: trastorno psicotico"
  },
  {
     id:"F146",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de cocaina: sindrome amnesico"
  },
  {
     id:"F147",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de cocaina: trastorno psicotico residual y de comienzo tardio"
  },
  {
     id:"F148",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de cocaina: otros trastornos mentales y del comportamiento"
  },
  {
     id:"F149",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de cocaina: trastorno mental y del comportamiento, no especificado"
  },
  {
     id:"F15",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de otros estimulantes"
  },
  {
     id:"F150",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de otros estimulantes, incluida la cafeina: intoxicacion aguda"
  },
  {
     id:"F151",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de otros estimulantes, incluida la cafeina: uso nocivo"
  },
  {
     id:"F152",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de otros estimulantes, incluida la cafeina: sindrome de dependencia"
  },
  {
     id:"F153",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de otros estimulantes, incluida la cafeina: estado de abstinencia"
  },
  {
     id:"F154",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de otros estimulantes, incluida la cafeina:. estado de abstinencia con delirio"
  },
  {
     id:"F155",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de otros estimulantes, incluida la cafeina: trastorno psicotico"
  },
  {
     id:"F156",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de otros estimulantes, incluida la cafeina: sindrome amnesico"
  },
  {
     id:"F157",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de otros estimulantes, incluida la cafeina: trastorno psicotico residual y de comienzo tardio"
  },
  {
     id:"F158",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de otros estimulantes, incluida la cafeina: otros trastornos mentales y del comportamiento"
  },
  {
     id:"F159",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de otros estimulantes, incluida la cafeina: trastorno mental y del comportamiento, no especificado"
  },
  {
     id:"F16",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de alucinogenos"
  },
  {
     id:"F160",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de alucinogenos: intoxicacion aguda"
  },
  {
     id:"F161",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de alucinogenos: uso nocivo"
  },
  {
     id:"F162",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de alucinogenos: sindrome de dependencia"
  },
  {
     id:"F163",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de alucinogenos: estado de abstinencia"
  },
  {
     id:"F164",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de alucinogenos: estado de abstinencia con delirio"
  },
  {
     id:"F165",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de alucinogenos: trastorno psicotico"
  },
  {
     id:"F166",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de alucinogenos: sindrome amnesico"
  },
  {
     id:"F167",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de alucinogenos: trastorno psicotico residual y de comienzo tardio"
  },
  {
     id:"F168",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de alucinogenos: otros trastornos mentales y del comportamiento"
  },
  {
     id:"F169",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de alucinogenos: trastorno mental y del comportamiento, no especificado"
  },
  {
     id:"F17",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso del tabaco"
  },
  {
     id:"F170",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de tabaco: intoxicacion aguda"
  },
  {
     id:"F171",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de tabaco: uso nocivo"
  },
  {
     id:"F172",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de tabaco: sindrome de dependencia"
  },
  {
     id:"F173",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de tabaco: estado de abstinencia"
  },
  {
     id:"F174",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de tabaco: estado de abstinencia con delirio"
  },
  {
     id:"F175",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de tabaco: trastorno psicotico"
  },
  {
     id:"F176",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de tabaco: sindrome amnesico"
  },
  {
     id:"F177",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de tabaco: trastorno psicotico residual y de comienzo tardio"
  },
  {
     id:"F178",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de tabaco: otros trastornos mentales y del comportamiento"
  },
  {
     id:"F179",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de tabaco: trastorno mental y del comportamiento, no especificado"
  },
  {
     id:"F18",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de disolventes volatiles"
  },
  {
     id:"F180",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de disolventes volatiles: intoxicacion aguda"
  },
  {
     id:"F181",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de disolventes volatiles: uso nocivo"
  },
  {
     id:"F182",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de disolventes volatiles: sindrome de dependencia"
  },
  {
     id:"F183",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de disolventes volatiles: estado de abstinencia"
  },
  {
     id:"F184",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de disolventes volatiles: estado de abstinencia con delirio"
  },
  {
     id:"F185",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de disolventes volatiles: trastorno psicotico"
  },
  {
     id:"F186",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de disolventes volatiles: sindrome amnesico"
  },
  {
     id:"F187",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de disolventes volatiles: trastorno psicotico residual y de comienzo tardio"
  },
  {
     id:"F188",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de disolventes volatiles: otros trastornos mentales y del comportamiento"
  },
  {
     id:"F189",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de disolventes volatiles: trastorno mental y del comportamiento, no especificado"
  },
  {
     id:"F19",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de multiples drogas y al uso de otras sustancias psicoactivas"
  },
  {
     id:"F190",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de multiples drogas y al uso de otras sustancias psicoactivas: intoxicacion aguda"
  },
  {
     id:"F191",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de multiples drogas y al uso de otras sustancias psicoactivas: uso nocivo"
  },
  {
     id:"F192",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de multiples drogas y al uso de otras sustancias psicoactivas: sindrome de dependencia"
  },
  {
     id:"F193",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de multiples drogas y al uso de otras sustancias psicoactivas: estado de abstinencia"
  },
  {
     id:"F194",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de multiples drogas y al uso de otras sustancias psicoactivas: estado de abstinencia con delirio"
  },
  {
     id:"F195",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de multiples drogas y al uso de otras sustancias psicoactivas: trastorno psicotico"
  },
  {
     id:"F196",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de multiples drogas y al uso de otras sustancias psicoactivas: sindrome amnesico"
  },
  {
     id:"F197",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de multiples drogas y al uso de otras sustancias psicoactivas: trastorno psicotico residual y de comienzo tardio"
  },
  {
     id:"F198",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de multiples drogas y al uso de otras sustancias psicoactivas: otros trastornos mentales y del comportamiento"
  },
  {
     id:"F199",
     enfermedad:"Trastornos mentales y del comportamiento debidos al uso de multiples drogas y al uso de otras sustancias psicoactivas: trastorno mental y del comportamiento, no especificado"
  },
  {
     id:"F20",
     enfermedad:"Esquizofrenia"
  },
  {
     id:"F200",
     enfermedad:"Esquizofrenia paranoide"
  },
  {
     id:"F201",
     enfermedad:"Esquizofrenia hebefrenica"
  },
  {
     id:"F202",
     enfermedad:"Esquizofrenia catatonica"
  },
  {
     id:"F203",
     enfermedad:"Esquizofrenia indiferenciada"
  },
  {
     id:"F204",
     enfermedad:"Depresion postesquizofrenica"
  },
  {
     id:"F205",
     enfermedad:"Esquizofrenia residual"
  },
  {
     id:"F206",
     enfermedad:"Esquizofrenia simple"
  },
  {
     id:"F208",
     enfermedad:"Otras esquizofrenias"
  },
  {
     id:"F209",
     enfermedad:"Esquizofrenia, no especificada"
  },
  {
     id:"F21",
     enfermedad:"Trastorno esquizotipico"
  },
  {
     id:"F22",
     enfermedad:"Trastornos delirantes persistentes"
  },
  {
     id:"F220",
     enfermedad:"Trastorno delirante"
  },
  {
     id:"F228",
     enfermedad:"Otros trastornos delirantes persistentes"
  },
  {
     id:"F229",
     enfermedad:"Trastorno delirante persistente, no especificado"
  },
  {
     id:"F23",
     enfermedad:"Trastornos psicoticos agudos y transitorios"
  },
  {
     id:"F230",
     enfermedad:"Trastorno psicotico agudo polimorfo, sin sintomas de esquizofrenia"
  },
  {
     id:"F231",
     enfermedad:"Trastorno psicotico agudo polimorfo, con sintomas de esquizofrenia"
  },
  {
     id:"F232",
     enfermedad:"Trastorno psicotico agudo de tipo esquizofrenico"
  },
  {
     id:"F233",
     enfermedad:"Otro trastorno psicotico agudo, con predominio de ideas delirantes"
  },
  {
     id:"F238",
     enfermedad:"Otros trastornos psicoticos agudos y transitorios"
  },
  {
     id:"F239",
     enfermedad:"Trastorno psicotico agudo y transitorio, no especificado de tipo esquizofrenico"
  },
  {
     id:"F24",
     enfermedad:"Trastorno delirante inducido"
  },
  {
     id:"F25",
     enfermedad:"Trastornos esquizoafectanivos"
  },
  {
     id:"F250",
     enfermedad:"Trastorno esquizoafectivo de tipo maniaco"
  },
  {
     id:"F251",
     enfermedad:"Trastorno esquizoafectivo de tipo depresivo"
  },
  {
     id:"F252",
     enfermedad:"Trastorno esquizoafectivo de tipo mixto"
  },
  {
     id:"F258",
     enfermedad:"Otros trastornos esquizoafectivos"
  },
  {
     id:"F259",
     enfermedad:"Trastorno esquizoafectivo, no especificado"
  },
  {
     id:"F28",
     enfermedad:"Otros trastornos psicoticos de origen no organico"
  },
  {
     id:"F29",
     enfermedad:"Psicosis de origen no organico, no especificada"
  },
  {
     id:"F30",
     enfermedad:"Episodio maniaco"
  },
  {
     id:"F300",
     enfermedad:"Hpomania"
  },
  {
     id:"F301",
     enfermedad:"Mania sin sintomas psicoticos"
  },
  {
     id:"F302",
     enfermedad:"Mania con sintomas psicoticos"
  },
  {
     id:"F308",
     enfermedad:"Otros episodios maniacos"
  },
  {
     id:"F309",
     enfermedad:"Episodio maniaco, no especificado"
  },
  {
     id:"F31",
     enfermedad:"Trastorno afectanivo bipolar"
  },
  {
     id:"F310",
     enfermedad:"Trastorno afectivo bipolar, episodio hipomaniaco presente"
  },
  {
     id:"F311",
     enfermedad:"Trastorno afectivo bipolar, episodio maniaco presente sin sintomas psicoticos"
  },
  {
     id:"F312",
     enfermedad:"Trastorno afectivo bipolar, episodio maniaco presente con sintomas psicoticos"
  },
  {
     id:"F313",
     enfermedad:"Trastorno afectivo bipolar, episodio depresivo presente leve o moderado"
  },
  {
     id:"F314",
     enfermedad:"Trastorno afectivo bipolar, episodio depresivo grave presente sin sintomas psicoticos"
  },
  {
     id:"F315",
     enfermedad:"Trastorno afectivo bipolar, episodio depresivo grave presente con sintomas psicoticos"
  },
  {
     id:"F316",
     enfermedad:"Trastorno afectivo bipolar, episodio mixto presente"
  },
  {
     id:"F317",
     enfermedad:"Trastorno afectivo bipolar, actualmente en remision"
  },
  {
     id:"F318",
     enfermedad:"Otros trastornos afectivos bipolares"
  },
  {
     id:"F319",
     enfermedad:"Trastorno afectivo bipolar, no especificado"
  },
  {
     id:"F32",
     enfermedad:"Episodio depresivo"
  },
  {
     id:"F320",
     enfermedad:"Episodio depresivo leve"
  },
  {
     id:"F321",
     enfermedad:"Episodio depresivo moderado"
  },
  {
     id:"F322",
     enfermedad:"Episodio depresivo grave sin sintomas psicoticos"
  },
  {
     id:"F323",
     enfermedad:"Episodio depresivo grave con sintomas psicoticos"
  },
  {
     id:"F328",
     enfermedad:"Otros episodios depresivos"
  },
  {
     id:"F329",
     enfermedad:"Episodio depresivo, no especificado"
  },
  {
     id:"F33",
     enfermedad:"Trastorno depresivo recurrente"
  },
  {
     id:"F330",
     enfermedad:"Trastorno depresivo recurrente, episodio leve presente"
  },
  {
     id:"F331",
     enfermedad:"Trastorno depresivo recurrente, episodio moderado presente"
  },
  {
     id:"F332",
     enfermedad:"Trastorno depresivo recurrente, episodio depresivo grave presente sin sintomas psicoticos"
  },
  {
     id:"F333",
     enfermedad:"Trastorno depresivo recurrente, episodio depresivo grave presente, con sintomas psicoticos"
  },
  {
     id:"F334",
     enfermedad:"Trastorno depresivo recurrente actualmente en remision"
  },
  {
     id:"F338",
     enfermedad:"Otros trastornos depresivos recurrentes"
  },
  {
     id:"F339",
     enfermedad:"Trastorno depresivo recurrente, no especificado"
  },
  {
     id:"F34",
     enfermedad:"Trastornos del humor [ afectivos ] persistentes"
  },
  {
     id:"F340",
     enfermedad:"Ciclotimia"
  },
  {
     id:"F341",
     enfermedad:"Distimia"
  },
  {
     id:"F348",
     enfermedad:"Otros trastornos del humor [afectivos] persistentes"
  },
  {
     id:"F349",
     enfermedad:"Trastorno persistente del humor [afectivo], no especificado"
  },
  {
     id:"F38",
     enfermedad:"Otros trastornos del humor [ afectivos ]"
  },
  {
     id:"F380",
     enfermedad:"Otros trastornos del humor [afectivos], aislados"
  },
  {
     id:"F381",
     enfermedad:"Otros trastornos del humor [afectivos], recurrentes"
  },
  {
     id:"F388",
     enfermedad:"Otros trastornos del humor [afectivos], especificados"
  },
  {
     id:"F39",
     enfermedad:"Trastorno del humor [ afectivos ], no especificado"
  },
  {
     id:"F40",
     enfermedad:"Trastornos fobicos de ansiedad"
  },
  {
     id:"F400",
     enfermedad:"Agorafobia"
  },
  {
     id:"F401",
     enfermedad:"Fobias sociales"
  },
  {
     id:"F402",
     enfermedad:"Fobias especificadas [aisladas]"
  },
  {
     id:"F408",
     enfermedad:"Otros trastornos fobicos de ansiedad"
  },
  {
     id:"F409",
     enfermedad:"Trastorno fobico de ansiedad, no especificado"
  },
  {
     id:"F41",
     enfermedad:"Otros trastornos de ansiedad"
  },
  {
     id:"F410",
     enfermedad:"Trastorno de panico [ansiedad paroxistica episodica]"
  },
  {
     id:"F411",
     enfermedad:"Trastorno de ansiedad generalizada"
  },
  {
     id:"F412",
     enfermedad:"Trastorno mixto de ansiedad y depresion"
  },
  {
     id:"F413",
     enfermedad:"Otros trastornos de ansiedad mixtos"
  },
  {
     id:"F418",
     enfermedad:"Otros trastornos de ansiedad especificados"
  },
  {
     id:"F419",
     enfermedad:"Trastorno de ansiedad , no especificado"
  },
  {
     id:"F42",
     enfermedad:"Trastorno obsesivo"
  },
  {
     id:"F420",
     enfermedad:"Predominio de pensamientos o rumiaciones obsesivas"
  },
  {
     id:"F421",
     enfermedad:"Predominio de actos compulsivos [rituales obsesivos]"
  },
  {
     id:"F422",
     enfermedad:"Actos e ideas obsesivas mixtos"
  },
  {
     id:"F428",
     enfermedad:"Otros trastornos obsesivo"
  },
  {
     id:"F429",
     enfermedad:"Trastorno obsesivo"
  },
  {
     id:"F43",
     enfermedad:"Reaccion al estres grave y trastornos de adaptacion"
  },
  {
     id:"F430",
     enfermedad:"Reaccion al estres agudo"
  },
  {
     id:"F431",
     enfermedad:"Trastorno de estres postraumatico"
  },
  {
     id:"F432",
     enfermedad:"Trastornos de adaptacion"
  },
  {
     id:"F438",
     enfermedad:"Otras reacciones al estres grave"
  },
  {
     id:"F439",
     enfermedad:"Reaccion al estres grave, no especificada"
  },
  {
     id:"F44",
     enfermedad:"Trastornos disociativos [ de conversion ]"
  },
  {
     id:"F440",
     enfermedad:"Amnesia disociativa"
  },
  {
     id:"F441",
     enfermedad:"Fuga disociativa"
  },
  {
     id:"F442",
     enfermedad:"Estupor disociativo"
  },
  {
     id:"F443",
     enfermedad:"Trastornos de trance y de posesion"
  },
  {
     id:"F444",
     enfermedad:"Trastornos disociativos del movimiento"
  },
  {
     id:"F445",
     enfermedad:"Convulsiones disociativas"
  },
  {
     id:"F446",
     enfermedad:"Anestesia disociativa y perdida sensorial"
  },
  {
     id:"F447",
     enfermedad:"Trastornos disociativos mixtos [y de conversion]"
  },
  {
     id:"F448",
     enfermedad:"Otros trastornos disociativos [de conversion]"
  },
  {
     id:"F449",
     enfermedad:"Trastorno disociativo [de conversion], no especificado"
  },
  {
     id:"F45",
     enfermedad:"Trastornos somatomorfos"
  },
  {
     id:"F450",
     enfermedad:"Trastorno de somatizacion"
  },
  {
     id:"F451",
     enfermedad:"Trastorno somatomorfo indiferenciado"
  },
  {
     id:"F452",
     enfermedad:"Trastorno hipocondriaco"
  },
  {
     id:"F453",
     enfermedad:"Disfuncion autonomica somatomorfa"
  },
  {
     id:"F454",
     enfermedad:"Trastorno de dolor persistente somatomorfo"
  },
  {
     id:"F458",
     enfermedad:"Otros trastornos somatomorfos"
  },
  {
     id:"F459",
     enfermedad:"Trastorno somatomorfo, no especificado"
  },
  {
     id:"F48",
     enfermedad:"Otros trastornos neuroticos"
  },
  {
     id:"F480",
     enfermedad:"Neurastenia"
  },
  {
     id:"F481",
     enfermedad:"Sindrome de despersonalizacion y desvinculacion de la realidad"
  },
  {
     id:"F488",
     enfermedad:"Otros trastornos neuroticos especificados"
  },
  {
     id:"F489",
     enfermedad:"Trastorno neurotico, no especificado"
  },
  {
     id:"F50",
     enfermedad:"Trastornos de la ingestion de alimentos"
  },
  {
     id:"F500",
     enfermedad:"Anorexia nerviosa"
  },
  {
     id:"F501",
     enfermedad:"Anorexia nerviosa atipica"
  },
  {
     id:"F502",
     enfermedad:"Bulimia nerviosa"
  },
  {
     id:"F503",
     enfermedad:"Bulimia nerviosa atipica"
  },
  {
     id:"F504",
     enfermedad:"Hiperfagia asociada con otras alteraciones psicologicas"
  },
  {
     id:"F505",
     enfermedad:"Vomitos asociados con otras alteraciones psicologicas"
  },
  {
     id:"F508",
     enfermedad:"Otros trastornos de la ingestion de alimentos"
  },
  {
     id:"F509",
     enfermedad:"Trastorno de la ingestion de alimentos, no especificado"
  },
  {
     id:"F51",
     enfermedad:"Trastornos no organicos del sueno"
  },
  {
     id:"F510",
     enfermedad:"Insomnio no organico"
  },
  {
     id:"F511",
     enfermedad:"Hipersomnio no organico"
  },
  {
     id:"F512",
     enfermedad:"Trastorno no organico del ciclo sueño"
  },
  {
     id:"F513",
     enfermedad:"Sonambulismo"
  },
  {
     id:"F514",
     enfermedad:"Terrores del sueño [terrores nocturnos]"
  },
  {
     id:"F515",
     enfermedad:"Pesadillas"
  },
  {
     id:"F518",
     enfermedad:"Otros trastornos no organicos del sueño"
  },
  {
     id:"F519",
     enfermedad:"Trastorno no organico del sueño, no especificado"
  },
  {
     id:"F52",
     enfermedad:"Disfuncion sexual no ocasionada por trastornos ni enfermedades organicos"
  },
  {
     id:"F520",
     enfermedad:"Falta o perdida del deseo sexual"
  },
  {
     id:"F521",
     enfermedad:"Aversion al sexo y falta de goce sexual"
  },
  {
     id:"F522",
     enfermedad:"Falla de la respuesta genital"
  },
  {
     id:"F523",
     enfermedad:"Disfuncion orgasmica"
  },
  {
     id:"F524",
     enfermedad:"Eyaculacion precoz"
  },
  {
     id:"F525",
     enfermedad:"Vaginismo no organico"
  },
  {
     id:"F526",
     enfermedad:"Dispareunia no organica"
  },
  {
     id:"F527",
     enfermedad:"Impulso sexual excesivo"
  },
  {
     id:"F528",
     enfermedad:"Otras disfunciones sexuales, no ocasionadas por trastorno ni por enfermedad organicos"
  },
  {
     id:"F529",
     enfermedad:"Disfuncion sexual, no ocasionada por trastorno ni por enfermedad organicos, no especificada"
  },
  {
     id:"F53",
     enfermedad:"Trastornos mentales y del comportamiento asociados alpuerperio no clasificadas en otra parte"
  },
  {
     id:"F530",
     enfermedad:"Trastornos mentales y del comportamiento leves, asociados con el puerperio, no clasificados en otra parte"
  },
  {
     id:"F531",
     enfermedad:"Trastornos mentales y del comportamiento graves, asociados con el puerperio, no clasificados en otra parte"
  },
  {
     id:"F538",
     enfermedad:"Trastornos mentales y del comportamiento asociados con el puerperio, no clasificados en otra parte"
  },
  {
     id:"F539",
     enfermedad:"Trastorno mental puerperal, no especificado"
  },
  {
     id:"F54",
     enfermedad:"Factores psicologicos y del comportamiento asociados a enfermedades clasificados en otra parte"
  },
  {
     id:"F55",
     enfermedad:"Abuso de sustancias que no producen dependencia"
  },
  {
     id:"F59",
     enfermedad:"Sindrome del comportamiento asociados con alteraciones fisiologicas y factores físicos no especificados"
  },
  {
     id:"F60",
     enfermedad:"Trastornos especificos de la personalidad"
  },
  {
     id:"F600",
     enfermedad:"Trastorno paranoide de la personalidad"
  },
  {
     id:"F601",
     enfermedad:"Trastorno esquizoide de la personalidad"
  },
  {
     id:"F602",
     enfermedad:"Trastorno asocial de la personalidad"
  },
  {
     id:"F603",
     enfermedad:"Trastorno de la personalidad emocionalmente inestable"
  },
  {
     id:"F604",
     enfermedad:"Trastorno histrionico de la personalidad"
  },
  {
     id:"F605",
     enfermedad:"Trastorno anancastico de la personalidad"
  },
  {
     id:"F606",
     enfermedad:"Trastorno de la personalidad ansiosa (evasiva, elusiva)"
  },
  {
     id:"F607",
     enfermedad:"Trastorno de la personalidad dependiente"
  },
  {
     id:"F608",
     enfermedad:"Otros trastornos especificos de la personalidad"
  },
  {
     id:"F609",
     enfermedad:"Trastorno de la personalidad, no especificado"
  },
  {
     id:"F61",
     enfermedad:"Trastornos mixtos y otros trastornos de la personalidad"
  },
  {
     id:"F62",
     enfermedad:"Cambios perdurables de la personalid no atribuibles a lesion o a enfermedad cerebral"
  },
  {
     id:"F620",
     enfermedad:"Cambio perdurable de la personalidad despues de una experiencia catastrofica"
  },
  {
     id:"F621",
     enfermedad:"Cambio perdurable de la personalidad consecutivo a una enfermedad psiquiatrica"
  },
  {
     id:"F628",
     enfermedad:"Otros cambios perdurables de la personalidad"
  },
  {
     id:"F629",
     enfermedad:"Cambio perdurable de la personalidad, no especificado"
  },
  {
     id:"F63",
     enfermedad:"Trastornos de los hábitos y de los impulsos"
  },
  {
     id:"F630",
     enfermedad:"Juego patológico"
  },
  {
     id:"F631",
     enfermedad:"Piromania"
  },
  {
     id:"F632",
     enfermedad:"Hurto patológico (cleptomania)"
  },
  {
     id:"F633",
     enfermedad:"Tricotilomania"
  },
  {
     id:"F638",
     enfermedad:"Otros trastornos de los hábitos y de los impulsos"
  },
  {
     id:"F639",
     enfermedad:"Trastorno de los hábitos y de los impulsos, no especificado"
  },
  {
     id:"F64",
     enfermedad:"Trastornos de la identidad de genero"
  },
  {
     id:"F640",
     enfermedad:"Transexualismo"
  },
  {
     id:"F641",
     enfermedad:"Transvestismo de rol dual"
  },
  {
     id:"F642",
     enfermedad:"Trastorno de la identidad de genero en la niñez"
  },
  {
     id:"F648",
     enfermedad:"Otros trastornos de la identidad de genero"
  },
  {
     id:"F649",
     enfermedad:"Trastorno de la identidad de genero, no especificado"
  },
  {
     id:"F65",
     enfermedad:"Trastornos de la preferencia sexual"
  },
  {
     id:"F650",
     enfermedad:"Fetichismo"
  },
  {
     id:"F651",
     enfermedad:"Transvestismo fetichista"
  },
  {
     id:"F652",
     enfermedad:"Exhibicionismo"
  },
  {
     id:"F653",
     enfermedad:"Voyeurismo"
  },
  {
     id:"F654",
     enfermedad:"Pedofilia"
  },
  {
     id:"F655",
     enfermedad:"Sadomasoquismo"
  },
  {
     id:"F656",
     enfermedad:"Trastornos multiples de la preferencia sexual"
  },
  {
     id:"F658",
     enfermedad:"Otros trastornos de la preferencia sexual"
  },
  {
     id:"F659",
     enfermedad:"Trastorno de la preferencia sexual, no especificado"
  },
  {
     id:"F66",
     enfermedad:"Trastornos psicologicos y del comportamiento asociados con el desarrollo y con la orientacion sexuales"
  },
  {
     id:"F660",
     enfermedad:"Trastorno de la maduracion sexual"
  },
  {
     id:"F661",
     enfermedad:"Orientacion sexual egodistonica"
  },
  {
     id:"F662",
     enfermedad:"Trastorno de la relacion sexual"
  },
  {
     id:"F668",
     enfermedad:"Otros trastornos del desarrollo psicosexual"
  },
  {
     id:"F669",
     enfermedad:"Trastorno del desarrollo psicosexual, no especificado"
  },
  {
     id:"F68",
     enfermedad:"Otros trastornos de la personalid y del comport en adultos"
  },
  {
     id:"F680",
     enfermedad:"Elaboracion de sintomas físicos por causas psicologicas"
  },
  {
     id:"F681",
     enfermedad:"Produccion intencional o simulacion de sintomas o de incapacidades, tanto fisicas como psicologicas [trastorno facticio]"
  },
  {
     id:"F688",
     enfermedad:"Otros trastornos especificados de la personalidad y del comportamiento en adultos"
  },
  {
     id:"F69",
     enfermedad:"Trastornos de la personalid y del comport en adultos, no especificado"
  },
  {
     id:"F70",
     enfermedad:"Retraso mental leve"
  },
  {
     id:"F700",
     enfermedad:"Retraso mental leve: deterioro del comportamiento nulo o minimo"
  },
  {
     id:"F701",
     enfermedad:"Retraso mental leve: deterioro del comportamiento significativo, que requiere atención o tratamiento"
  },
  {
     id:"F708",
     enfermedad:"Retraso mental leve: otros deterioros del comportamiento"
  },
  {
     id:"F709",
     enfermedad:"Retraso mental leve: deterioro del comportamiento de grado no especificado"
  },
  {
     id:"F71",
     enfermedad:"Retraso mental moderado"
  },
  {
     id:"F710",
     enfermedad:"Retraso mental moderado: deterioro del comportamiento nulo o minimo"
  },
  {
     id:"F711",
     enfermedad:"Retraso mental moderado: deterioro del comportamiento significativo, que requiere atención o tratamiento"
  },
  {
     id:"F718",
     enfermedad:"Retraso mental moderado: otros deterioros del comportamiento"
  },
  {
     id:"F719",
     enfermedad:"Retraso mental moderado: deterioro del comportamiento de grado no especificado"
  },
  {
     id:"F72",
     enfermedad:"Retraso mental grave"
  },
  {
     id:"F720",
     enfermedad:"Retraso mental grave: deterioro del comportamiento nulo o minimo"
  },
  {
     id:"F721",
     enfermedad:"Retraso mental grave: deterioro del comportamiento significativo, que requiere atención o tratamiento"
  },
  {
     id:"F728",
     enfermedad:"Retraso mental grave: otros deterioros del comportamiento"
  },
  {
     id:"F729",
     enfermedad:"Retraso mental grave: deterioro del comportamiento de grado no especificado"
  },
  {
     id:"F73",
     enfermedad:"Retraso mental profundo"
  },
  {
     id:"F730",
     enfermedad:"Retraso mental profundo: deterioro del comportamiento nulo o minimo"
  },
  {
     id:"F731",
     enfermedad:"Retraso mental profundo: deterioro del comportamiento significativo, que requiere atención o tratamiento"
  },
  {
     id:"F738",
     enfermedad:"Retraso mental profundo: otros deterioros del comportamiento"
  },
  {
     id:"F739",
     enfermedad:"Retraso mental profundo: deterioro del comportamiento de grado no especificado"
  },
  {
     id:"F78",
     enfermedad:"Otros tipos de retraso mental"
  },
  {
     id:"F780",
     enfermedad:"Otros tipos de retraso mental profundo: deterioro del comportamiento nulo o minimo"
  },
  {
     id:"F781",
     enfermedad:"Otros tipos de retraso mental profundo: deterioro del comportamiento significativo, que requiere atención o tratamiento"
  },
  {
     id:"F788",
     enfermedad:"Otros tipos de retraso mental profundo: otros deterioros del comportamiento"
  },
  {
     id:"F789",
     enfermedad:"Otros tipos de retraso mental profundo: deterioro del comportamiento de grado no especificado"
  },
  {
     id:"F79",
     enfermedad:"Retraso mental no especificado"
  },
  {
     id:"F790",
     enfermedad:"Retraso mental, no especificado: deterioro del comportamiento nulo o minimo"
  },
  {
     id:"F791",
     enfermedad:"Retraso mental, no especificado: deterioro del comportamiento significativo, que requiere atención o tratamiento"
  },
  {
     id:"F798",
     enfermedad:"Retraso mental, no especificado: otros deterioros del comportamiento"
  },
  {
     id:"F799",
     enfermedad:"Retraso mental, no especificado: deterioro del comportamiento de grado no especificado"
  },
  {
     id:"F80",
     enfermedad:"Trastornos especificos del desarrollo del habla y del lenguaje"
  },
  {
     id:"F800",
     enfermedad:"Trastorno especifico de la pronunciacion"
  },
  {
     id:"F801",
     enfermedad:"Trastorno del lenguaje expresivo"
  },
  {
     id:"F802",
     enfermedad:"Trastorno de la recepcion del lenguaje"
  },
  {
     id:"F803",
     enfermedad:"Afasia adquirida con epilepsia [landau"
  },
  {
     id:"F808",
     enfermedad:"Otros trastornos del desarrollo del habla y del lenguaje"
  },
  {
     id:"F809",
     enfermedad:"Trastorno del desarrollo del habla y del lenguaje no especificado"
  },
  {
     id:"F81",
     enfermedad:"Trastornos especificos del desarrollo de las habilidades escolares"
  },
  {
     id:"F810",
     enfermedad:"Trastorno especifico de la lectura"
  },
  {
     id:"F811",
     enfermedad:"Trastorno especifico del deletreo (ortografia)"
  },
  {
     id:"F812",
     enfermedad:"Trastorno especifico de las habilidades aritmeticas"
  },
  {
     id:"F813",
     enfermedad:"Trastorno mixto de las habilidades escolares"
  },
  {
     id:"F818",
     enfermedad:"Otros trastornos del desarrollo de las habilidades escolares"
  },
  {
     id:"F819",
     enfermedad:"Trastorno del desarrollo de las habilidades escolares, no especificado"
  },
  {
     id:"F82",
     enfermedad:"Trastornos especificos del desarrollo de la funcion motriz"
  },
  {
     id:"F83",
     enfermedad:"Trastornos especificos mixtos desarrollo"
  },
  {
     id:"F84",
     enfermedad:"Trastornos generalizados del desarrollo"
  },
  {
     id:"F840",
     enfermedad:"Autismo en la niñez"
  },
  {
     id:"F841",
     enfermedad:"Autismo atipico"
  },
  {
     id:"F842",
     enfermedad:"Sindrome de rett"
  },
  {
     id:"F843",
     enfermedad:"Otro trastorno desintegrativo de la niñez"
  },
  {
     id:"F844",
     enfermedad:"Trastorno hiperactivo asociado con retraso mental y movimientos estereotipados"
  },
  {
     id:"F845",
     enfermedad:"Sindrome de asperger"
  },
  {
     id:"F848",
     enfermedad:"Otros trastornos generalizados del desarrollo"
  },
  {
     id:"F849",
     enfermedad:"Trastorno generalizado del desarrollo no especificado"
  },
  {
     id:"F88",
     enfermedad:"Otros trastornos del desarrollo psicologico"
  },
  {
     id:"F89",
     enfermedad:"Trastornos del desarrollo psicologico, no especificados"
  },
  {
     id:"F90",
     enfermedad:"Trastornos hipercineticos"
  },
  {
     id:"F900",
     enfermedad:"Perturbacion de la actividad y de la atención "
  },
  {
     id:"F901",
     enfermedad:"Trastorno hipercinetico de la conducta"
  },
  {
     id:"F908",
     enfermedad:"Otros trastornos hipercineticos"
  },
  {
     id:"F909",
     enfermedad:"Trastorno hipercinetico, no especificado"
  },
  {
     id:"F91",
     enfermedad:"Trastornos de la conducta"
  },
  {
     id:"F910",
     enfermedad:"Trastorno de la conducta limitado al contexto familiar"
  },
  {
     id:"F911",
     enfermedad:"Trastorno de la conducta insociable"
  },
  {
     id:"F912",
     enfermedad:"Trastorno de la conducta sociable"
  },
  {
     id:"F913",
     enfermedad:"Trastorno opositor desafiante"
  },
  {
     id:"F918",
     enfermedad:"Otros trastornos de la conducta"
  },
  {
     id:"F919",
     enfermedad:"Trastorno de la conducta, no especificado"
  },
  {
     id:"F92",
     enfermedad:"Trastornos mixtos de la conducta y de las emociones"
  },
  {
     id:"F920",
     enfermedad:"Trastorno depresivo de la conducta"
  },
  {
     id:"F928",
     enfermedad:"Otros trastornos mixtos de la conducta y de las emociones"
  },
  {
     id:"F929",
     enfermedad:"Trastorno mixto de la conducta y de las emociones, no especificado"
  },
  {
     id:"F93",
     enfermedad:"Trastornos emocionales comienzo especifico en la ninez"
  },
  {
     id:"F930",
     enfermedad:"Trastorno de ansiedad de separacion en la niñez"
  },
  {
     id:"F931",
     enfermedad:"Trastorno de ansiedad fobica en la niñez"
  },
  {
     id:"F932",
     enfermedad:"Trastorno de ansiedad social en la niñez"
  },
  {
     id:"F933",
     enfermedad:"Trastorno de rivalidad entre hermanos"
  },
  {
     id:"F938",
     enfermedad:"Otros trastornos emocionales en la niñez"
  },
  {
     id:"F939",
     enfermedad:"Trastorno emocional en la niñez, no especificado"
  },
  {
     id:"F94",
     enfermedad:"Trastornos del comport social de comienzo especifico en la ninez y en la adolescencia"
  },
  {
     id:"F940",
     enfermedad:"Mutismo electivo"
  },
  {
     id:"F941",
     enfermedad:"Trastorno de vinculacion reactiva en la niñez"
  },
  {
     id:"F942",
     enfermedad:"Trastorno de vinculacion desinhibida en la niñez"
  },
  {
     id:"F948",
     enfermedad:"Otros trastornos del comportamiento social en la niñez"
  },
  {
     id:"F949",
     enfermedad:"Trastorno del comportamiento social en la niñez, no especificado"
  },
  {
     id:"F95",
     enfermedad:"Trastornos por tics"
  },
  {
     id:"F950",
     enfermedad:"Trastorno por tic transitorio"
  },
  {
     id:"F951",
     enfermedad:"Trastorno por tic motor o vocal cronico"
  },
  {
     id:"F952",
     enfermedad:"Trastornos por tics motores y vocales multiples combinados [de la tourette]"
  },
  {
     id:"F958",
     enfermedad:"Otros trastornos por tic"
  },
  {
     id:"F959",
     enfermedad:"Trastorno por tic, no especificado"
  },
  {
     id:"F98",
     enfermedad:"Otros trastornos emocionales y del comportamiento que aparecen habitualmente en la ninez y en la adolescencia"
  },
  {
     id:"F980",
     enfermedad:"Enuresis no organica"
  },
  {
     id:"F981",
     enfermedad:"Ecopresis no organica"
  },
  {
     id:"F982",
     enfermedad:"Trastorno de la ingestion alimentaria en la infancia y en la niñez"
  },
  {
     id:"F983",
     enfermedad:"Pica en la infancia y la niñez"
  },
  {
     id:"F984",
     enfermedad:"Trastornos de los movimientos estereotipados"
  },
  {
     id:"F985",
     enfermedad:"Tartamudez [espasmofemia]"
  },
  {
     id:"F986",
     enfermedad:"Farfulleo"
  },
  {
     id:"F988",
     enfermedad:"Otros trastornos emocionales y del comportamiento que aparecen habitualmente en la niñez y en la adolescencia"
  },
  {
     id:"F989",
     enfermedad:"Trastornos no especificados, emocionales y del comportamiento, que aparecen habitualmente en la niñez y en la adolescencia"
  },
  {
     id:"F99",
     enfermedad:"Trastorno mental no especificado"
  },
  {
     id:"G00",
     enfermedad:"Meningitis bacteriana no clasificada en otra parte"
  },
  {
     id:"G000",
     enfermedad:"Meningitis por hemofilos"
  },
  {
     id:"G001",
     enfermedad:"Meningitis neumococica"
  },
  {
     id:"G002",
     enfermedad:"Meningitis estreptococica"
  },
  {
     id:"G003",
     enfermedad:"Meningitis estafilococica"
  },
  {
     id:"G008",
     enfermedad:"Otras meningitis bacterianas"
  },
  {
     id:"G009",
     enfermedad:"Meningitis bacteriana, no especificada"
  },
  {
     id:"G01",
     enfermedad:"Meningitis en enfermedades bacterianas clasificadas en otra parte"
  },
  {
     id:"G02",
     enfermedad:"Meningitis en otras enfermedades infecciosas clasificadas otra parte"
  },
  {
     id:"G020",
     enfermedad:"Meningitis en enfermedades virales clasificadas en otra parte"
  },
  {
     id:"G021",
     enfermedad:"Meningitis en micosis"
  },
  {
     id:"G028",
     enfermedad:"Meningitis en otras enfermedades infecciosas y parasitarias especificadas clasificadas en otra parte"
  },
  {
     id:"G03",
     enfermedad:"Meningitis debida a otras causas y a las no especificadas"
  },
  {
     id:"G030",
     enfermedad:"Meningitis apiogena"
  },
  {
     id:"G031",
     enfermedad:"Meningitis cronica"
  },
  {
     id:"G032",
     enfermedad:"Meningitis recurrente benigna [mollaret]"
  },
  {
     id:"G038",
     enfermedad:"Meningitis debidas a otras causas especificadas"
  },
  {
     id:"G039",
     enfermedad:"Meningitis, no especificada"
  },
  {
     id:"G04",
     enfermedad:"Encefalitis mielitis y encefalomielitis"
  },
  {
     id:"G040",
     enfermedad:"Encefalitis aguda diseminada"
  },
  {
     id:"G041",
     enfermedad:"Paraplejia espastica tropical"
  },
  {
     id:"G042",
     enfermedad:"Meningoencefalitis y meningomielitis bacterianas, no clasificadas en otra parte"
  },
  {
     id:"G048",
     enfermedad:"Otras encefalitis, mielitis y encefalomielitis"
  },
  {
     id:"G049",
     enfermedad:"Encefalitis, mielitis y encefalomielitis, no especificadas"
  },
  {
     id:"G05",
     enfermedad:"Encefalitis mielitis y encefalomielitis en enfermedades clasificadas en otra parte"
  },
  {
     id:"G050",
     enfermedad:"Encefalitis, mielitis y encefalomielitis en enfermedades bacterianas clasificadas en otra parte"
  },
  {
     id:"G051",
     enfermedad:"Encefalitis, mielitis y encefalomielitis en enfermedades virales clasificadas en otra parte"
  },
  {
     id:"G052",
     enfermedad:"Encefalitis, mielitis y encefalomielitis en otras enfermedades infecciosas y parasitarias clasificadas en otra parte"
  },
  {
     id:"G058",
     enfermedad:"Encefalitis, mielitis y encefalomielitis en enfermedades clasificadas en otra parte"
  },
  {
     id:"G06",
     enfermedad:"Absceso y granuloma intracraneal e intrarraquideo"
  },
  {
     id:"G060",
     enfermedad:"Absceso y granuloma intracraneal"
  },
  {
     id:"G061",
     enfermedad:"Absceso y granuloma intrarraquideo"
  },
  {
     id:"G062",
     enfermedad:"Absceso extradural y subdural, no especificado"
  },
  {
     id:"G07",
     enfermedad:"Absceso y granuloma intracraneal e intrarraquideo en enfermedades clasificadas en otra parte"
  },
  {
     id:"G08",
     enfermedad:"Flebitis y tromboflebitis intracraneal e intrarraquidea"
  },
  {
     id:"G09",
     enfermedad:"Secuelas de enfermedades inflamatorias del sistema nervioso central"
  },
  {
     id:"G10",
     enfermedad:"Enfermedad de huntington"
  },
  {
     id:"G11",
     enfermedad:"Ataxia hereditaria"
  },
  {
     id:"G110",
     enfermedad:"Ataxia congenita no progresiva"
  },
  {
     id:"G111",
     enfermedad:"Ataxia cerebelosa de iniciacion temprana"
  },
  {
     id:"G112",
     enfermedad:"Ataxia cerebelosa de iniciacion tardia"
  },
  {
     id:"G113",
     enfermedad:"Ataxia cerebelosa con reparacion defectuosa del adn"
  },
  {
     id:"G114",
     enfermedad:"Paraplejia espastica hereditaria"
  },
  {
     id:"G118",
     enfermedad:"Otras ataxias hereditarias"
  },
  {
     id:"G119",
     enfermedad:"Ataxia hereditaria, no especificada"
  },
  {
     id:"G12",
     enfermedad:"Atrofia muscular espinal y sindromes afines"
  },
  {
     id:"G120",
     enfermedad:"Atrofia muscular espinal infantil, tipo i [werdnig"
  },
  {
     id:"G121",
     enfermedad:"Otras atrofias musculares espinales hereditarias"
  },
  {
     id:"G122",
     enfermedad:"Enfermedades de las neuronas motoras"
  },
  {
     id:"G128",
     enfermedad:"Otras atrofias musculares espinales y sindromes afines"
  },
  {
     id:"G129",
     enfermedad:"Atrofia muscular espinal, sin otra especificacion"
  },
  {
     id:"G13",
     enfermedad:"Atrofias sistemicas que afectan primariamente el sistema nervioso central en enfermedades clasificadas en otra parte"
  },
  {
     id:"G130",
     enfermedad:"Neuromiopatia y neuropatia paraneoplasica"
  },
  {
     id:"G131",
     enfermedad:"Otras atrofias sistemicas que afectan el sistema nervioso central en enfermedad neoplasica"
  },
  {
     id:"G132",
     enfermedad:"Atrofia sistemica que afecta primariamente el sistema nervioso central en el mixedema (e00.1, e03."
  },
  {
     id:"G138",
     enfermedad:"Atrofia sistemica que afecta primariamente el sistema nervioso central en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"G20",
     enfermedad:"Enfermedad de parkinson"
  },
  {
     id:"G21",
     enfermedad:"Parkinsonismo secundario"
  },
  {
     id:"G210",
     enfermedad:"Sindrome neuroleptico maligno"
  },
  {
     id:"G211",
     enfermedad:"Otro parkinsonismo secundario inducido por drogas"
  },
  {
     id:"G212",
     enfermedad:"Parkinsonismo secundario debido a otros agentes externos"
  },
  {
     id:"G213",
     enfermedad:"Parkinsonismo postencefalitico"
  },
  {
     id:"G218",
     enfermedad:"Otros tipos de parkinsonismo secundario"
  },
  {
     id:"G219",
     enfermedad:"Parkinsonismo secundario, no especificado"
  },
  {
     id:"G22",
     enfermedad:"Parkinsonismo en enfermedades clasificadas otra parte"
  },
  {
     id:"G23",
     enfermedad:"Otras enfermedades degenerativas de los nucleos de la base"
  },
  {
     id:"G230",
     enfermedad:"Enfermedad de hallervorden"
  },
  {
     id:"G231",
     enfermedad:"Oftalmoplejia supranuclear progresiva [steele"
  },
  {
     id:"G232",
     enfermedad:"Desgeneracion nigroestriada"
  },
  {
     id:"G238",
     enfermedad:"Otras enfermedades degenerativas especificadas de los nucleos de la base"
  },
  {
     id:"G239",
     enfermedad:"Enfermedad degenerativa de los nucleos de la base, no especificada"
  },
  {
     id:"G24",
     enfermedad:"Distonia"
  },
  {
     id:"G240",
     enfermedad:"Distonia inducida por drogas"
  },
  {
     id:"G241",
     enfermedad:"Distonia idiopatica familiar"
  },
  {
     id:"G242",
     enfermedad:"Distonia idiopatica no familiar"
  },
  {
     id:"G243",
     enfermedad:"Torticolis espasmodica"
  },
  {
     id:"G244",
     enfermedad:"Distonia bucofacial idiopatica"
  },
  {
     id:"G245",
     enfermedad:"Blefarospasmo"
  },
  {
     id:"G248",
     enfermedad:"Otras distonias"
  },
  {
     id:"G249",
     enfermedad:"Distonia, no especificada"
  },
  {
     id:"G25",
     enfermedad:"Otros trastornos extrapiramidales y del movimiento"
  },
  {
     id:"G250",
     enfermedad:"Temblor esencial"
  },
  {
     id:"G251",
     enfermedad:"Temblor inducido por drogas"
  },
  {
     id:"G252",
     enfermedad:"Otras formas especificadas de temblor"
  },
  {
     id:"G253",
     enfermedad:"Mioclonia"
  },
  {
     id:"G254",
     enfermedad:"Corea inducida por drogas"
  },
  {
     id:"G255",
     enfermedad:"Otras coreas"
  },
  {
     id:"G256",
     enfermedad:"Tic inducidos por drogas y otros tics de origen organico"
  },
  {
     id:"G258",
     enfermedad:"Otros trastornos extrapiramidales y del movimiento"
  },
  {
     id:"G259",
     enfermedad:"Trastorno extrapiramidal y del movimiento, no especificado"
  },
  {
     id:"G26",
     enfermedad:"Trastornos extrapiramidales y del movimiento en enfermedades clasificados en otra parte"
  },
  {
     id:"G30",
     enfermedad:"Enfermedad de alzheimer"
  },
  {
     id:"G300",
     enfermedad:"Enfermedad de alzheimer de comienzo temprano"
  },
  {
     id:"G301",
     enfermedad:"Enfermedad de alzheimer comienzo tardio"
  },
  {
     id:"G308",
     enfermedad:"Otros tipos de enfermedad de alzheimer"
  },
  {
     id:"G309",
     enfermedad:"Enfermedad de alzheimer, no especificada"
  },
  {
     id:"G31",
     enfermedad:"Otras enfermedades degenerativas del sistema nervioso no clasificadas en otra parte"
  },
  {
     id:"G310",
     enfermedad:"Atrofia cerebral circunscrita"
  },
  {
     id:"G311",
     enfermedad:"Degeneracion cerebral senil no clasificada en otra parte"
  },
  {
     id:"G312",
     enfermedad:"Degeneracion del sistema nervioso debida a el alcohol"
  },
  {
     id:"G318",
     enfermedad:"Otras enfermedades degenerativas especificadas del sistema nervioso"
  },
  {
     id:"G319",
     enfermedad:"Degeneracion del sistema nervioso, no especificada"
  },
  {
     id:"G32",
     enfermedad:"Otros trastornos degenerativos del sistema nervioso en enfermedades clasificados en otra parte"
  },
  {
     id:"G320",
     enfermedad:"Degeneracion combinada subaguda de la medula espinal en enfernedades clasificadas en otra parte"
  },
  {
     id:"G328",
     enfermedad:"Otros trastornos degenerativos especificados del sistema nervioso en enfermedades clasificadas en otra parte"
  },
  {
     id:"G35",
     enfermedad:"Esclerosis multiple"
  },
  {
     id:"G36",
     enfermedad:"Otras desmielinizaciones diseminadas agudas"
  },
  {
     id:"G360",
     enfermedad:"Neuromielitis optica [devic]"
  },
  {
     id:"G361",
     enfermedad:"Leucoencefalitis hemorragica aguda y subaguda [hurst]"
  },
  {
     id:"G368",
     enfermedad:"Otras desmielinizaciones agudas diseminadas especificadas"
  },
  {
     id:"G369",
     enfermedad:"Desmielinizacion diseminada aguda sin otra especificacion"
  },
  {
     id:"G37",
     enfermedad:"Otras enfermedades desmielinizantes del sistema nervioso central"
  },
  {
     id:"G370",
     enfermedad:"Esclerosis difusa"
  },
  {
     id:"G371",
     enfermedad:"Desmielinizacion central del cuerpo calloso"
  },
  {
     id:"G372",
     enfermedad:"Mielinolisis central pontina"
  },
  {
     id:"G373",
     enfermedad:"Mielitis transversa aguda en enfermedad desmielinizante del sistema nervioso central"
  },
  {
     id:"G374",
     enfermedad:"Mielitis necrotizante subaguda"
  },
  {
     id:"G375",
     enfermedad:"Esclerosis concentrica (balo)"
  },
  {
     id:"G378",
     enfermedad:"Otras enfermedades desmielinizantes del sistema nervioso central, especificadas"
  },
  {
     id:"G379",
     enfermedad:"Enfermedad desmielinizante del sistema nervioso central, no especificada"
  },
  {
     id:"G40",
     enfermedad:"Epilepsia"
  },
  {
     id:"G400",
     enfermedad:"Epilepsia y sindromes epilepticos idiopaticos relacionados con localizaciones (focales) (parciales) y con ataques de inicio localizado"
  },
  {
     id:"G401",
     enfermedad:"Epilepsia y sindromes epilepticos sintomaticos relacionados con localizaciones (focales) (parciales) y con ataques parciales simples"
  },
  {
     id:"G402",
     enfermedad:"Epilepsia y sindromes epilepticos sintomaticos relacionados con localizaciones (focales) (parciales) y con ataques parciales complejos"
  },
  {
     id:"G403",
     enfermedad:"Epilepsia y sindromes epilepticos idiopaticos generalizados"
  },
  {
     id:"G404",
     enfermedad:"Otras epilepsias y sindromes epilepticos generalizados"
  },
  {
     id:"G405",
     enfermedad:"Sindromes epilepticos especiales"
  },
  {
     id:"G406",
     enfermedad:"Ataques de gran mal, no especificados (con o sin pequeño mal)"
  },
  {
     id:"G407",
     enfermedad:"Pequeño mal, no especificado (sin ataque de gran mal)"
  },
  {
     id:"G408",
     enfermedad:"Otras epilepsias"
  },
  {
     id:"G409",
     enfermedad:"Epilepsia, tipo no especificado"
  },
  {
     id:"G41",
     enfermedad:"Estado de mal epileptico"
  },
  {
     id:"G410",
     enfermedad:"Estado de gran mal epileptico"
  },
  {
     id:"G411",
     enfermedad:"Estado de pequeño mal epileptico"
  },
  {
     id:"G412",
     enfermedad:"Estado de mal epileptico parcial complejo"
  },
  {
     id:"G418",
     enfermedad:"Otros estados epilepticos"
  },
  {
     id:"G419",
     enfermedad:"Estado de mal epileptico de tipo no especificado"
  },
  {
     id:"G43",
     enfermedad:"Migraña"
  },
  {
     id:"G430",
     enfermedad:"Migraña sin aura [migraña comun]"
  },
  {
     id:"G431",
     enfermedad:"Migraña con aura [migraña clasica]"
  },
  {
     id:"G432",
     enfermedad:"Estado migrañoso"
  },
  {
     id:"G433",
     enfermedad:"Migraña complicada"
  },
  {
     id:"G438",
     enfermedad:"Otras migrañas"
  },
  {
     id:"G439",
     enfermedad:"Migraña, no especificada"
  },
  {
     id:"G44",
     enfermedad:"Otros sindromes de cefalea"
  },
  {
     id:"G440",
     enfermedad:"Sindrome de cefalea en racimos"
  },
  {
     id:"G441",
     enfermedad:"Cefalea vascular, ncop"
  },
  {
     id:"G442",
     enfermedad:"Cefalea debida a tension"
  },
  {
     id:"G443",
     enfermedad:"Cefalea postraumatica cronica"
  },
  {
     id:"G444",
     enfermedad:"Cefalea inducida por drogas, no clasificada en otra parte"
  },
  {
     id:"G448",
     enfermedad:"Otros sindromes de cefalea especificados"
  },
  {
     id:"G45",
     enfermedad:"Ataques de isquemia cerebral transitoria y sindromes afines"
  },
  {
     id:"G450",
     enfermedad:"Sindrome arterial vertebro"
  },
  {
     id:"G451",
     enfermedad:"Sindrome de la arteria carotida (hemisferico)"
  },
  {
     id:"G452",
     enfermedad:"Sindromes arteriales precerebrales bilaterales y multiples"
  },
  {
     id:"G453",
     enfermedad:"Amaurosis fugaz"
  },
  {
     id:"G454",
     enfermedad:"Amnesia global transitoria"
  },
  {
     id:"G458",
     enfermedad:"Otras isquemias cerebrales transitorias y sindromes afines"
  },
  {
     id:"G459",
     enfermedad:"Isquemia cerebral transitoria, sin otra especificacion"
  },
  {
     id:"G46",
     enfermedad:"Sindromes vasculares encefalicos en enfermedades clasificadas en otra parte"
  },
  {
     id:"G460",
     enfermedad:"Cerebral media (i66.0)"
  },
  {
     id:"G461",
     enfermedad:"Sindrome de la arteria cerebral anterior (i66.1)"
  },
  {
     id:"G462",
     enfermedad:"Sindrome de la arteria cerebral posterior (i66.2)"
  },
  {
     id:"G463",
     enfermedad:"Sindromes apopleticos del tallo encefalico (i60"
  },
  {
     id:"G464",
     enfermedad:"Sindrome de infarto cerebeloso (i60"
  },
  {
     id:"G465",
     enfermedad:"Sindrome lacunar motor puro (i60"
  },
  {
     id:"G466",
     enfermedad:"Sindrome lacunar sensorial puro (i60"
  },
  {
     id:"G467",
     enfermedad:"Otros sindromes lacunares (i60"
  },
  {
     id:"G468",
     enfermedad:"Otros sindromes vasculares encefalicos en enfermedades cerebrovasculares (i60"
  },
  {
     id:"G47",
     enfermedad:"Trastornos del sueno"
  },
  {
     id:"G470",
     enfermedad:"Trastornos del inicio y del mantenimiento del sueño [insomnios]"
  },
  {
     id:"G471",
     enfermedad:"Trastornos de somnolencia excesiva [hipersomnios]"
  },
  {
     id:"G472",
     enfermedad:"Trastornos del ritmo nictameral"
  },
  {
     id:"G473",
     enfermedad:"Apnea del sueño"
  },
  {
     id:"G474",
     enfermedad:"Narcolepsia y cataplexia"
  },
  {
     id:"G478",
     enfermedad:"Otros trastornos del sueño"
  },
  {
     id:"G479",
     enfermedad:"Trastorno del sueño, no especificado"
  },
  {
     id:"G50",
     enfermedad:"Trastornos del nervio trigemino"
  },
  {
     id:"G500",
     enfermedad:"Neuralgia del trigemino"
  },
  {
     id:"G501",
     enfermedad:"Dolor facial atipico"
  },
  {
     id:"G508",
     enfermedad:"Otros trastornos del trigemino"
  },
  {
     id:"G509",
     enfermedad:"Trastornos del trigemino, no especificado"
  },
  {
     id:"G51",
     enfermedad:"Trastornos del nervio facial"
  },
  {
     id:"G510",
     enfermedad:"Paralisis de bell"
  },
  {
     id:"G511",
     enfermedad:"Ganglionitis geniculada"
  },
  {
     id:"G512",
     enfermedad:"Sindrome de melkersson"
  },
  {
     id:"G513",
     enfermedad:"Espasmo hemifacial clonico"
  },
  {
     id:"G514",
     enfermedad:"Mioquimia facial"
  },
  {
     id:"G518",
     enfermedad:"Otros trastornos del nervio facial"
  },
  {
     id:"G519",
     enfermedad:"Trastornos del nervio facial, no especificado"
  },
  {
     id:"G52",
     enfermedad:"Trastornos de otros nervios craneales"
  },
  {
     id:"G520",
     enfermedad:"Trastornos del nervio olfatorio"
  },
  {
     id:"G521",
     enfermedad:"Trastornos del nervio glosofaringeo"
  },
  {
     id:"G522",
     enfermedad:"Trastornos del nervio vago"
  },
  {
     id:"G523",
     enfermedad:"Trastornos del nervio hipogloso"
  },
  {
     id:"G527",
     enfermedad:"Trastornos de multiples nervios craneales"
  },
  {
     id:"G528",
     enfermedad:"Trastornos de otros nervios craneales especificados"
  },
  {
     id:"G529",
     enfermedad:"Trastorno de nervio craneal, no especificado"
  },
  {
     id:"G53",
     enfermedad:"Trastornos de los nervios craneales en enfermedades clasificadas en otra parte"
  },
  {
     id:"G530",
     enfermedad:"Neuralgia postherpes zoster (b02.2)"
  },
  {
     id:"G531",
     enfermedad:"Paralisis multiple de los nervios craneales en enfermedades infecciosas y parasitarias clasificadas en otra parte (a00"
  },
  {
     id:"G532",
     enfermedad:"Paralisis multiple de los nervios craneales, en la sarcoidosis (d86.8)"
  },
  {
     id:"G533",
     enfermedad:"Paralisis multiple de los nervios craneales, en enfermedades neoplasicas (c00"
  },
  {
     id:"G538",
     enfermedad:"Otros trastornos de los nervios craneales en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"G54",
     enfermedad:"Trastornos de las raices y de los plexos nerviosos"
  },
  {
     id:"G540",
     enfermedad:"Trastornos del plexo braquial"
  },
  {
     id:"G541",
     enfermedad:"Trastornos del plexo lumbosacro"
  },
  {
     id:"G542",
     enfermedad:"Trastornos de la raiz cervical, no clasificados en otra parte"
  },
  {
     id:"G543",
     enfermedad:"Trastornos de la raiz toracica, no clasificados en otra parte"
  },
  {
     id:"G544",
     enfermedad:"Trastornos de la raiz lumbosacra, no clasificados en otra parte"
  },
  {
     id:"G545",
     enfermedad:"Amiotrofia neuralgica"
  },
  {
     id:"G546",
     enfermedad:"Sindrome del miembro fantasma con dolor"
  },
  {
     id:"G547",
     enfermedad:"Sindrome del miembro fantasma sin dolor"
  },
  {
     id:"G548",
     enfermedad:"Otros trastornos de las raices y plexos nerviosos"
  },
  {
     id:"G549",
     enfermedad:"Trastorno de la raiz y plexos nerviosos, no especificado"
  },
  {
     id:"G55",
     enfermedad:"Compresiones de las raices y de los plexos nerviosos en enfermedades clasificadas en otra parte"
  },
  {
     id:"G550",
     enfermedad:"Compresiones de las raices y plexos nerviosos en enfermedades neoplasicas (c00"
  },
  {
     id:"G551",
     enfermedad:"Compresiones de las raices y plexos nerviosos en trastornos de los discos intervertebrales (m50"
  },
  {
     id:"G552",
     enfermedad:"Compresiones de las raices y plexos nerviosos en la espondilosis (m47."
  },
  {
     id:"G553",
     enfermedad:"Compresiones de las raices y plexos nerviosos en otras dorsopatias (m45"
  },
  {
     id:"G558",
     enfermedad:"Compresiones de las raices y plexos nerviosos en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"G56",
     enfermedad:"Mononeuropatias del miembro superior"
  },
  {
     id:"G560",
     enfermedad:"Sindrome del tunel carpiano"
  },
  {
     id:"G561",
     enfermedad:"Otras lesiones del nervio mediano"
  },
  {
     id:"G562",
     enfermedad:"Lesion del nervio cubital"
  },
  {
     id:"G563",
     enfermedad:"Lesion del nervio radial"
  },
  {
     id:"G564",
     enfermedad:"Causalgia"
  },
  {
     id:"G568",
     enfermedad:"Otras mononeuropatias del miembro superior"
  },
  {
     id:"G569",
     enfermedad:"Mononeuropatia del miembro superior sin otra especificacion"
  },
  {
     id:"G57",
     enfermedad:"Mononeuropatias del miembro inferior"
  },
  {
     id:"G570",
     enfermedad:"Lesion del nervio ciatico"
  },
  {
     id:"G571",
     enfermedad:"Meralgia parestesica"
  },
  {
     id:"G572",
     enfermedad:"Lesion del nervio crural"
  },
  {
     id:"G573",
     enfermedad:"Lesion del nervio ciatico popliteo externo"
  },
  {
     id:"G574",
     enfermedad:"Lesion del nervio ciatico popliteo interno"
  },
  {
     id:"G575",
     enfermedad:"Sindrome del tunel calcaneo"
  },
  {
     id:"G576",
     enfermedad:"Lesion del nervio plantar"
  },
  {
     id:"G578",
     enfermedad:"Otras mononeuropatias del miembro inferior"
  },
  {
     id:"G579",
     enfermedad:"Mononeuropatia del miembro inferior, sin otra especificacion"
  },
  {
     id:"G58",
     enfermedad:"Otras mononeuropatias"
  },
  {
     id:"G580",
     enfermedad:"Neuropatia intercostal"
  },
  {
     id:"G587",
     enfermedad:"Mononeuritis multiple"
  },
  {
     id:"G588",
     enfermedad:"Otras mononeuropatias especificadas"
  },
  {
     id:"G589",
     enfermedad:"Mononeuropatia, no especificada"
  },
  {
     id:"G59",
     enfermedad:"Mononeuropatia en enfermedades clasificadas otra parte"
  },
  {
     id:"G590",
     enfermedad:"Mononeuropatia diabetica (e10"
  },
  {
     id:"G598",
     enfermedad:"Otras mononeuropatias en enfermedades clasificadas en otra parte"
  },
  {
     id:"G60",
     enfermedad:"Neuropatia hereditaria e idiopatica"
  },
  {
     id:"G600",
     enfermedad:"Neuroptia hereditaria motora y sensorial"
  },
  {
     id:"G601",
     enfermedad:"Enfermedad de refsum"
  },
  {
     id:"G602",
     enfermedad:"Neuropatia asociada con ataxia hereditaria"
  },
  {
     id:"G603",
     enfermedad:"Neuropatia progresiva idiopatica"
  },
  {
     id:"G608",
     enfermedad:"Otras neuropatias hereditarias e idiopaticas"
  },
  {
     id:"G609",
     enfermedad:"Neuropatia hereditaria e idiopatica, sin otra especificacion"
  },
  {
     id:"G61",
     enfermedad:"Polineuropatia inflamatoria"
  },
  {
     id:"G610",
     enfermedad:"Sindrome de guillain"
  },
  {
     id:"G611",
     enfermedad:"Neuropatia al suero"
  },
  {
     id:"G618",
     enfermedad:"Otras polineuropatias inflamatorias"
  },
  {
     id:"G619",
     enfermedad:"Polineuropatia inflamatoria, no especificada"
  },
  {
     id:"G62",
     enfermedad:"Otras polineuropatias"
  },
  {
     id:"G620",
     enfermedad:"Polineuropatia inducida por drogas"
  },
  {
     id:"G621",
     enfermedad:"Polineuropatia alcoholica"
  },
  {
     id:"G622",
     enfermedad:"Polineuropatia debida a otro agente toxico"
  },
  {
     id:"G628",
     enfermedad:"Otras polineuropatias especificadas"
  },
  {
     id:"G629",
     enfermedad:"Polineuropatia, no especificada"
  },
  {
     id:"G63",
     enfermedad:"Polineuropatia en enfermedades clasificadas otra parte"
  },
  {
     id:"G630",
     enfermedad:"Polineuropatia en enfermedades infecciosas y parasitarias clasificadas en otra parte"
  },
  {
     id:"G631",
     enfermedad:"Polineuropatia en enfermedad neoplasica (c00"
  },
  {
     id:"G632",
     enfermedad:"Polineuropatia diabetica (e10"
  },
  {
     id:"G633",
     enfermedad:"Polineuropatia en otras enfermedades endocrinas y metabolicas (e00"
  },
  {
     id:"G634",
     enfermedad:"Polineuropatia en deficiencia nutricional (e40"
  },
  {
     id:"G635",
     enfermedad:"Polineuropatia en trastornos del tejido conectivo sistemico (m30"
  },
  {
     id:"G636",
     enfermedad:"Polineuropatia en otros trastornos osteomusculares (m00"
  },
  {
     id:"G638",
     enfermedad:"Polineuropatia en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"G64",
     enfermedad:"Otros trastornos del sistema nervioso periferico"
  },
  {
     id:"G70",
     enfermedad:"Miastenia gravis y otros trastornos neuromusculares"
  },
  {
     id:"G700",
     enfermedad:"Miastenia gravis"
  },
  {
     id:"G701",
     enfermedad:"Trastornos toxicos neuromusculares"
  },
  {
     id:"G702",
     enfermedad:"Miastenia congenita o del desarrollo"
  },
  {
     id:"G708",
     enfermedad:"Otros trastornos neuromusculares especificados"
  },
  {
     id:"G709",
     enfermedad:"Trastorno neuromuscular, no especificado"
  },
  {
     id:"G71",
     enfermedad:"Trastornos musculares primarios"
  },
  {
     id:"G710",
     enfermedad:"Distrofia muscular"
  },
  {
     id:"G711",
     enfermedad:"Trastornos miotonicos"
  },
  {
     id:"G712",
     enfermedad:"Miopatias congenitas"
  },
  {
     id:"G713",
     enfermedad:"Miopatia mitocondrica, no clasificada en otra parte"
  },
  {
     id:"G718",
     enfermedad:"Otros trastornos primarios de los musculos"
  },
  {
     id:"G719",
     enfermedad:"Trastorno primario del musculo, tipo no especificado"
  },
  {
     id:"G72",
     enfermedad:"Otras miopatias"
  },
  {
     id:"G720",
     enfermedad:"Miopatia inducida por drogas"
  },
  {
     id:"G721",
     enfermedad:"Miopatia alcoholica"
  },
  {
     id:"G722",
     enfermedad:"Miopatia debida a otros agentes toxicos"
  },
  {
     id:"G723",
     enfermedad:"Paralisis periodica"
  },
  {
     id:"G724",
     enfermedad:"Miopatia inflamatoria, no clasificada en otra parte"
  },
  {
     id:"G728",
     enfermedad:"Otras miopatias especificadas"
  },
  {
     id:"G729",
     enfermedad:"Miopatia, no especificada"
  },
  {
     id:"G73",
     enfermedad:"Trastornos del musculo y de la union neuromuscular en enfermedades clasificadas en otra parte"
  },
  {
     id:"G730",
     enfermedad:"Sindromes miastenicos en enfermedades endocrinas"
  },
  {
     id:"G731",
     enfermedad:"Sindrome de eaton"
  },
  {
     id:"G732",
     enfermedad:"Otros sindromes miastenicos en enfermedad neoplasica (c00"
  },
  {
     id:"G733",
     enfermedad:"Sindromes miastenicos en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"G734",
     enfermedad:"Miopatia en enfermedades infecciosas y parasitarias clasificadas en otra parte"
  },
  {
     id:"G735",
     enfermedad:"Miopatia en enfermedades endocrinas"
  },
  {
     id:"G736",
     enfermedad:"Miopatia en enfermedades metabolicas"
  },
  {
     id:"G737",
     enfermedad:"Miopatia en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"G80",
     enfermedad:"Paralisis cerebral infantil"
  },
  {
     id:"G800",
     enfermedad:"Paralisis cerebral espastica"
  },
  {
     id:"G801",
     enfermedad:"Diplejia espastica"
  },
  {
     id:"G802",
     enfermedad:"Hemiplejia infantil"
  },
  {
     id:"G803",
     enfermedad:"Paralisis cerebral discinetica"
  },
  {
     id:"G804",
     enfermedad:"Paralisis cerebral ataxica"
  },
  {
     id:"G808",
     enfermedad:"Otros tipos de paralisis cerebral infantil"
  },
  {
     id:"G809",
     enfermedad:"Paralisis cerebral infantil, sin otra especificacion"
  },
  {
     id:"G81",
     enfermedad:"Hemiplejia"
  },
  {
     id:"G810",
     enfermedad:"Hemiplejia flacida"
  },
  {
     id:"G811",
     enfermedad:"Hemiplejia espastica"
  },
  {
     id:"G819",
     enfermedad:"Hemiplejia, no especificada"
  },
  {
     id:"G82",
     enfermedad:"Paraplejia y cuadriplejia"
  },
  {
     id:"G820",
     enfermedad:"Paraplejia flacida"
  },
  {
     id:"G821",
     enfermedad:"Paraplejia espastica"
  },
  {
     id:"G822",
     enfermedad:"Paraplejia, no especificada"
  },
  {
     id:"G823",
     enfermedad:"Cuadriplejia flacida"
  },
  {
     id:"G824",
     enfermedad:"Cuadriplejia espastica"
  },
  {
     id:"G825",
     enfermedad:"Cuadriplejia, no especificada"
  },
  {
     id:"G83",
     enfermedad:"Otros sindromes paraliticos"
  },
  {
     id:"G830",
     enfermedad:"Diplejia de los miembros superiores"
  },
  {
     id:"G831",
     enfermedad:"Monoplejia del miembro inferior"
  },
  {
     id:"G832",
     enfermedad:"Monoplejia del miembro superior"
  },
  {
     id:"G833",
     enfermedad:"Monoplejia, no especificada"
  },
  {
     id:"G834",
     enfermedad:"Sindrome de la cola de caballo"
  },
  {
     id:"G838",
     enfermedad:"Otros sindromes paraliticos especificados"
  },
  {
     id:"G839",
     enfermedad:"Sindrome paralitico, no especificado"
  },
  {
     id:"G90",
     enfermedad:"Trastornos del sistema nervioso autonomo"
  },
  {
     id:"G900",
     enfermedad:"Neuropatia autonoma periferica idiopatica"
  },
  {
     id:"G901",
     enfermedad:"Disautonomia familiar [sindrome de riley"
  },
  {
     id:"G902",
     enfermedad:"Sindrome de horner"
  },
  {
     id:"G903",
     enfermedad:"Degeneracion de sistemas multiples"
  },
  {
     id:"G908",
     enfermedad:"Otros trastornos del sistema nervioso autonomo"
  },
  {
     id:"G909",
     enfermedad:"Trastorno del sistema nervioso autonomo, no especificado"
  },
  {
     id:"G91",
     enfermedad:"Hidrocefalo"
  },
  {
     id:"G910",
     enfermedad:"Hidrocefalo comunicante"
  },
  {
     id:"G911",
     enfermedad:"Hidrocefalo obstructivo"
  },
  {
     id:"G912",
     enfermedad:"Hidrocefalo de presion normal"
  },
  {
     id:"G913",
     enfermedad:"Hidrocefalo postraumatico, sin otra especificacion"
  },
  {
     id:"G918",
     enfermedad:"Otros tipos de hidrocefalo"
  },
  {
     id:"G919",
     enfermedad:"Hidrocefalo, no especificado"
  },
  {
     id:"G92",
     enfermedad:"Encefalopatia toxica"
  },
  {
     id:"G93",
     enfermedad:"Otros trastornos del encefalo"
  },
  {
     id:"G930",
     enfermedad:"Quiste cerebral"
  },
  {
     id:"G931",
     enfermedad:"Lesion cerebral anoxica, no clasificada en otra parte"
  },
  {
     id:"G932",
     enfermedad:"Hipertension intracraneal benigna"
  },
  {
     id:"G933",
     enfermedad:"Sindrome de fatiga postviral"
  },
  {
     id:"G934",
     enfermedad:"Encefalopatia no especificada"
  },
  {
     id:"G935",
     enfermedad:"Compresion del encefalo"
  },
  {
     id:"G936",
     enfermedad:"Edema cerebral"
  },
  {
     id:"G937",
     enfermedad:"Sindrome de reye"
  },
  {
     id:"G938",
     enfermedad:"Otros trastornos especificados del encefalo"
  },
  {
     id:"G939",
     enfermedad:"Trastorno del encefalo, no especificado"
  },
  {
     id:"G94",
     enfermedad:"Otros trastornos del encefalo en enfermedades clasificadas otra parte"
  },
  {
     id:"G940",
     enfermedad:"Hidrocefalo en enfermedades infecciosas y parasitarias clasificadas en otra parte (a00"
  },
  {
     id:"G941",
     enfermedad:"Hidrocefalo en enfermedad neoplasica (c00"
  },
  {
     id:"G942",
     enfermedad:"Hidrocefalo en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"G948",
     enfermedad:"Otros trastornos encefaliticos especificados en enfermedades clasificadas en otra parte"
  },
  {
     id:"G95",
     enfermedad:"Otras enfermedades de la medula espinal"
  },
  {
     id:"G950",
     enfermedad:"Siringomielia y siringobulbia"
  },
  {
     id:"G951",
     enfermedad:"Mielopatias vasculares"
  },
  {
     id:"G952",
     enfermedad:"Compresion medular, no especificada"
  },
  {
     id:"G958",
     enfermedad:"Otras enfermedades especificadas de la medula espinal"
  },
  {
     id:"G959",
     enfermedad:"Enfermedad de la medula espinal, no especificada"
  },
  {
     id:"G96",
     enfermedad:"Otros trastornos del sistema nervioso central"
  },
  {
     id:"G960",
     enfermedad:"Perdida de liquido cefalorraquideo"
  },
  {
     id:"G961",
     enfermedad:"Trastornos de la meninges, no clasificados en otra parte"
  },
  {
     id:"G968",
     enfermedad:"Otros trastornos especificados del sistema nervioso central"
  },
  {
     id:"G969",
     enfermedad:"Trastorno del sistema nervioso central, no especificado"
  },
  {
     id:"G97",
     enfermedad:"Trastornos del sistema nervioso consecutivos a procedimientos, no clasificados en otra parte"
  },
  {
     id:"G970",
     enfermedad:"Perdida de liquido cefalorraquideo por puncion espinal"
  },
  {
     id:"G971",
     enfermedad:"Otra reaccion a la puncion espinal y lumbar"
  },
  {
     id:"G972",
     enfermedad:"Hipotension intracraneal posterior a anastomosis ventricular"
  },
  {
     id:"G978",
     enfermedad:"Otros trastornos del sistema nervioso consecutivos a procedimientos"
  },
  {
     id:"G979",
     enfermedad:"Trastornos no especificados del sistema nervioso, consecutivos a procedimientos"
  },
  {
     id:"G98",
     enfermedad:"Otros trastornos sistema nervioso no clasificadas en otra parte"
  },
  {
     id:"G99",
     enfermedad:"Otros trastornos del sistema nervioso en enfermedades clasificadas otra parte"
  },
  {
     id:"G990",
     enfermedad:"Neuropatia autonomica en enfernedades metabolicas y endocrinas"
  },
  {
     id:"G991",
     enfermedad:"Otros trastornos del sistema nervioso autonomo en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"G992",
     enfermedad:"Mielopatia en enfermedades clasificadas en otra parte"
  },
  {
     id:"G998",
     enfermedad:"Otros trastornos especificados del sistema nervioso en enfermedades clasificadas en otra parte"
  },
  {
     id:"H00",
     enfermedad:"Orzuelo y calacio"
  },
  {
     id:"H000",
     enfermedad:"Orzuelo y otras inflamaciones profundas del parpado"
  },
  {
     id:"H001",
     enfermedad:"Calacio [chalazion]"
  },
  {
     id:"H01",
     enfermedad:"Otras inflamaciones del parpado"
  },
  {
     id:"H010",
     enfermedad:"Blefaritis"
  },
  {
     id:"H011",
     enfermedad:"Dermatosis no infecciosa del parpado"
  },
  {
     id:"H018",
     enfermedad:"Otras inflamaciones especificadas del parpado"
  },
  {
     id:"H019",
     enfermedad:"Inflamacion del parpado, no especificada"
  },
  {
     id:"H02",
     enfermedad:"Otros trastornos de los parpados"
  },
  {
     id:"H020",
     enfermedad:"Entropion y triquiasis palpebral"
  },
  {
     id:"H021",
     enfermedad:"Ectropion del parpado"
  },
  {
     id:"H022",
     enfermedad:"Lagoftalmos"
  },
  {
     id:"H023",
     enfermedad:"Blefarocalasia"
  },
  {
     id:"H024",
     enfermedad:"Blefaroptosis"
  },
  {
     id:"H025",
     enfermedad:"Otros trastornos funcionales del parpado"
  },
  {
     id:"H026",
     enfermedad:"Xantelasma del parpado"
  },
  {
     id:"H027",
     enfermedad:"Otros trastornos degenerativos del parpado y del area periocular"
  },
  {
     id:"H028",
     enfermedad:"Otros trastornos especificados del parpado"
  },
  {
     id:"H029",
     enfermedad:"Trastornos del parpado, no especificado"
  },
  {
     id:"H03",
     enfermedad:"Trastornos del parpado en enfermedades clasificadas otra parte"
  },
  {
     id:"H030",
     enfermedad:"Infeccion e infestacion parasitarias del parpado en enfermedades clasificadas en otra parte"
  },
  {
     id:"H031",
     enfermedad:"Compromiso del parpado en enfermedades infecciosas clasificadas en otra parte"
  },
  {
     id:"H038",
     enfermedad:"Compromiso del parpado en enfermedades clasificadas en otra parte"
  },
  {
     id:"H04",
     enfermedad:"Trastornos del aparato lagrimal"
  },
  {
     id:"H040",
     enfermedad:"Dacrioadenitis"
  },
  {
     id:"H041",
     enfermedad:"Otros trastornos de la glandula lagrimal"
  },
  {
     id:"H042",
     enfermedad:"Epifora"
  },
  {
     id:"H043",
     enfermedad:"Inflamacion aguda y la no especificada de las vias lagrimales"
  },
  {
     id:"H044",
     enfermedad:"Inflamacion cronica de las vias lagrimales"
  },
  {
     id:"H045",
     enfermedad:"Estenosis e insuficiencia de las vias lagrimales"
  },
  {
     id:"H046",
     enfermedad:"Otros cambios de las vias lagrimales"
  },
  {
     id:"H048",
     enfermedad:"Otros trastornos especificados del aparato lagrimal"
  },
  {
     id:"H049",
     enfermedad:"Trastorno del aparato lagrimal, no especificado"
  },
  {
     id:"H05",
     enfermedad:"Trastornos de la orbita"
  },
  {
     id:"H050",
     enfermedad:"Inflamacion aguda de la orbita"
  },
  {
     id:"H051",
     enfermedad:"Trastornos inflamatorios cronicos de la orbita"
  },
  {
     id:"H052",
     enfermedad:"Afecciones exoftalmicas"
  },
  {
     id:"H053",
     enfermedad:"Deformidad de la orbita"
  },
  {
     id:"H054",
     enfermedad:"Enoftalmia"
  },
  {
     id:"H055",
     enfermedad:"Retencion de cuerpo extraño (antiguo), consecutiva a herida penetrante de la orbita"
  },
  {
     id:"H058",
     enfermedad:"Otros trastornos de la orbita"
  },
  {
     id:"H059",
     enfermedad:"Trastorno de la orbita, no especificado"
  },
  {
     id:"H06",
     enfermedad:"Trastornos del aparato lagrimal y de la orbita en enfermedades clasificadas en otra parte"
  },
  {
     id:"H060",
     enfermedad:"Trastornos del aparato lagrimal en enfermedades clasificadas en otra parte"
  },
  {
     id:"H061",
     enfermedad:"Infeccion o infestacion parasitaria de la orbita en enfermedades clasificadas en otra parte"
  },
  {
     id:"H062",
     enfermedad:"Exoftalmia hipertiroidea (e05."
  },
  {
     id:"H063",
     enfermedad:"Otros trastornos de la orbita en enfermedades clasificadas en otra parte"
  },
  {
     id:"H10",
     enfermedad:"Conjuntivitis"
  },
  {
     id:"H100",
     enfermedad:"Conjuntivitis mucopurulenta"
  },
  {
     id:"H101",
     enfermedad:"Conjuntivitis atopica aguda"
  },
  {
     id:"H102",
     enfermedad:"Otras conjuntivitis agudas"
  },
  {
     id:"H103",
     enfermedad:"Conjuntivitis aguda, no especificada"
  },
  {
     id:"H104",
     enfermedad:"Conjuntivitis cronica"
  },
  {
     id:"H105",
     enfermedad:"Blefaroconjuntivitis"
  },
  {
     id:"H108",
     enfermedad:"Otras conjuntivitis"
  },
  {
     id:"H109",
     enfermedad:"Conjuntivitis, no especificada"
  },
  {
     id:"H11",
     enfermedad:"Otros trastornos de la conjuntiva"
  },
  {
     id:"H110",
     enfermedad:"Pterigion"
  },
  {
     id:"H111",
     enfermedad:"Degeneraciones y depositos conjuntivales"
  },
  {
     id:"H112",
     enfermedad:"Cicatrices conjuntivales"
  },
  {
     id:"H113",
     enfermedad:"Hemorragia conjuntival"
  },
  {
     id:"H114",
     enfermedad:"Otros trastornos vasculares y quistes conjuntivales"
  },
  {
     id:"H118",
     enfermedad:"Otros trastornos especificados de la conjuntiva"
  },
  {
     id:"H119",
     enfermedad:"Trastorno de la conjuntiva, no especificado"
  },
  {
     id:"H13",
     enfermedad:"Trastornos de la conjuntiva en enfermedades clasificadas otra parte"
  },
  {
     id:"H130",
     enfermedad:"Infeccion filarica de la conjuntiva (b74."
  },
  {
     id:"H131",
     enfermedad:"Conjuntivitis en enfermedades infecciosas y parasitarias clasificadas en otra parte"
  },
  {
     id:"H132",
     enfermedad:"Conjuntivitis en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"H133",
     enfermedad:"Penfigoide ocular (l12."
  },
  {
     id:"H138",
     enfermedad:"Otros trastornos de la conjuntiva en enfermedades clasificadas en otra parte"
  },
  {
     id:"H15",
     enfermedad:"Trastornos de la esclerotica"
  },
  {
     id:"H150",
     enfermedad:"Escleritis"
  },
  {
     id:"H151",
     enfermedad:"Episcleritis"
  },
  {
     id:"H158",
     enfermedad:"Otros trastornos de la esclerotica"
  },
  {
     id:"H159",
     enfermedad:"Trastornos de la esclerotica, no especificado"
  },
  {
     id:"H16",
     enfermedad:"Queratitis"
  },
  {
     id:"H160",
     enfermedad:"Ulcera de la cornea"
  },
  {
     id:"H161",
     enfermedad:"Otras queratitis superficiales sin cunjuntivitis"
  },
  {
     id:"H162",
     enfermedad:"Queratoconjuntivitis"
  },
  {
     id:"H163",
     enfermedad:"Queratitis intersticial y profunda"
  },
  {
     id:"H164",
     enfermedad:"Neovascularizacion de la cornea"
  },
  {
     id:"H168",
     enfermedad:"Otras queratitis"
  },
  {
     id:"H169",
     enfermedad:"Queratitis, no especificada"
  },
  {
     id:"H17",
     enfermedad:"Opacidades y cicatrices corneales"
  },
  {
     id:"H170",
     enfermedad:"Leucoma adherente"
  },
  {
     id:"H171",
     enfermedad:"Otras opacidades centrales de la cornea"
  },
  {
     id:"H178",
     enfermedad:"Otras opacidades o cicatrices de la cornea"
  },
  {
     id:"H179",
     enfermedad:"Cicatriz u opacidad de la cornea, no especificada"
  },
  {
     id:"H18",
     enfermedad:"Otros trastornos de la cornea"
  },
  {
     id:"H180",
     enfermedad:"Pigmentaciones y depositos en la cornea"
  },
  {
     id:"H181",
     enfermedad:"Queratopatia vesicular"
  },
  {
     id:"H182",
     enfermedad:"Otros edemas de la cornea"
  },
  {
     id:"H183",
     enfermedad:"Cambios en las membranas de la cornea"
  },
  {
     id:"H184",
     enfermedad:"Degeneracion de la cornea"
  },
  {
     id:"H185",
     enfermedad:"Distrofia hereditaria de la cornea"
  },
  {
     id:"H186",
     enfermedad:"Queratocono"
  },
  {
     id:"H187",
     enfermedad:"Otras deformidades de la cornea"
  },
  {
     id:"H188",
     enfermedad:"Otros trastornos especificados de la cornea"
  },
  {
     id:"H189",
     enfermedad:"Trastorno de la cornea, no especificado"
  },
  {
     id:"H19",
     enfermedad:"Trastornos de la esclerotica y de la cornea en enfermedades clasificadas en otra parte"
  },
  {
     id:"H190",
     enfermedad:"Escleritis y episcleritis en enfermedades clasificadas en otra parte"
  },
  {
     id:"H191",
     enfermedad:"Queratitis y queratoconjuntivitis por herpes simple (b00.5)"
  },
  {
     id:"H192",
     enfermedad:"Queratitis y queratoconjuntivitis en enfermedades infecciosas y parasitarias, clasificadas en otra parte"
  },
  {
     id:"H193",
     enfermedad:"Queratitis y queratoconjuntivitis en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"H198",
     enfermedad:"Otros trastornos de la esclerotica y de la cornea en enfermedades clasificadas en otra parte"
  },
  {
     id:"H20",
     enfermedad:"Iridociclitis"
  },
  {
     id:"H200",
     enfermedad:"Iridociclitis aguda y subaguda"
  },
  {
     id:"H201",
     enfermedad:"Iridociclitis cronica"
  },
  {
     id:"H202",
     enfermedad:"Iridociclitis inducida por trastorno del cristalino"
  },
  {
     id:"H208",
     enfermedad:"Otras iridociclitis especificadas"
  },
  {
     id:"H209",
     enfermedad:"Iridociclitis, no especificada"
  },
  {
     id:"H21",
     enfermedad:"Otros trastornos del iris y del cuerpo ciliar"
  },
  {
     id:"H210",
     enfermedad:"Hifema"
  },
  {
     id:"H211",
     enfermedad:"Otros trastornos vasculares del iris y del cuerpo ciliar"
  },
  {
     id:"H212",
     enfermedad:"Degeneracion del iris y del cuerpo ciliar"
  },
  {
     id:"H213",
     enfermedad:"Quiste del iris, del cuerpo ciliar y de la camara anterior"
  },
  {
     id:"H214",
     enfermedad:"Membranas pupilares"
  },
  {
     id:"H215",
     enfermedad:"Otras adherencias y desgarros del iris y del cuerpo ciliar"
  },
  {
     id:"H218",
     enfermedad:"Otros trastornos especificados del iris y del cuerpo ciliar"
  },
  {
     id:"H219",
     enfermedad:"Del iris y del cuerpo ciliar, no especificado"
  },
  {
     id:"H22",
     enfermedad:"Trastornos del iris y del cuerpo ciliar en enfermedades clasificadas en otra parte"
  },
  {
     id:"H220",
     enfermedad:"Iridociclitis en enfermedades infecciosas y parasitarias clasificadas en otra parte"
  },
  {
     id:"H221",
     enfermedad:"Iridociclitis en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"H228",
     enfermedad:"Otros trastornos del iris y del cuerpo ciliar en enfermedades clasificadas en otra parte"
  },
  {
     id:"H25",
     enfermedad:"Catarata senil"
  },
  {
     id:"H250",
     enfermedad:"Catarata senil incipiente"
  },
  {
     id:"H251",
     enfermedad:"Catarata senil nuclear"
  },
  {
     id:"H252",
     enfermedad:"Catarata senil, tipo morgagnian"
  },
  {
     id:"H258",
     enfermedad:"Otras cataratas seniles"
  },
  {
     id:"H259",
     enfermedad:"Catarata senil, no especificada"
  },
  {
     id:"H26",
     enfermedad:"Otras cataratas"
  },
  {
     id:"H260",
     enfermedad:"Catarata infantil, juvenil y presenil"
  },
  {
     id:"H261",
     enfermedad:"Catarata traumatica"
  },
  {
     id:"H262",
     enfermedad:"Catarata complicada"
  },
  {
     id:"H263",
     enfermedad:"Catarata inducida por drogas"
  },
  {
     id:"H264",
     enfermedad:"Catarata residual"
  },
  {
     id:"H268",
     enfermedad:"Otras formas especificadas de catarata"
  },
  {
     id:"H269",
     enfermedad:"Catarata, no especificada"
  },
  {
     id:"H27",
     enfermedad:"Otros trastornos del cristalino"
  },
  {
     id:"H270",
     enfermedad:"Afaquia"
  },
  {
     id:"H271",
     enfermedad:"Luxacion del cristalino"
  },
  {
     id:"H278",
     enfermedad:"Otros trastornos especificados del cristalino"
  },
  {
     id:"H279",
     enfermedad:"Trastorno del cristalino, no especificado"
  },
  {
     id:"H28",
     enfermedad:"Catarata y otros trastornos del cristalino en enfermedades clasificadas en otra parte"
  },
  {
     id:"H280",
     enfermedad:"Catarata diabetica (e10"
  },
  {
     id:"H281",
     enfermedad:"Catarata en otras enfermedades endocrinas, nutricionales y metabolicas clasificadas en otra parte"
  },
  {
     id:"H282",
     enfermedad:"Catarata en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"H288",
     enfermedad:"Otros trastornos del cristalino en enfermedades clasificadas en otra parte"
  },
  {
     id:"H30",
     enfermedad:"Inflamacion coriorretiniana"
  },
  {
     id:"H300",
     enfermedad:"Coriorretinitis focal"
  },
  {
     id:"H301",
     enfermedad:"Coriorretinitis diseminada"
  },
  {
     id:"H302",
     enfermedad:"Ciclitis posterior"
  },
  {
     id:"H308",
     enfermedad:"Otras coriorretinitis"
  },
  {
     id:"H309",
     enfermedad:"Coriorretinitis, no especificada"
  },
  {
     id:"H31",
     enfermedad:"Otros trastornos de la coroides"
  },
  {
     id:"H310",
     enfermedad:"Cicatrices coriorretinianas"
  },
  {
     id:"H311",
     enfermedad:"Desgeneracion coroidea"
  },
  {
     id:"H312",
     enfermedad:"Distrofia coroidea hereditaria"
  },
  {
     id:"H313",
     enfermedad:"Hemorragia y ruptura de la coroides"
  },
  {
     id:"H314",
     enfermedad:"Desprendimiento de la coroides"
  },
  {
     id:"H318",
     enfermedad:"Otros trastornos especificados de la coroides"
  },
  {
     id:"H319",
     enfermedad:"Trastorno de la coroides, no especificado"
  },
  {
     id:"H32",
     enfermedad:"Trastornos coriorretinianos en enfermedades clasificadas otra parte"
  },
  {
     id:"H320",
     enfermedad:"Inflamacion coriorretiniana en enfermedades infecciosas y parasitarias clasificadas en otra parte"
  },
  {
     id:"H328",
     enfermedad:"Otros trastornos coriorretinianos en enfermedades clasificadas en otra parte"
  },
  {
     id:"H33",
     enfermedad:"Desprendimiento y desgarro de la retina"
  },
  {
     id:"H330",
     enfermedad:"Desprendimiento de la retina con ruptura"
  },
  {
     id:"H331",
     enfermedad:"Retinosquisis y quistes de la retina"
  },
  {
     id:"H332",
     enfermedad:"Desprendimiento seroso de la retina"
  },
  {
     id:"H333",
     enfermedad:"Desgarro de la retina sin desprendimiento"
  },
  {
     id:"H334",
     enfermedad:"Desprendimiento de la retina por traccion"
  },
  {
     id:"H335",
     enfermedad:"Otros desprendimiento de la retina"
  },
  {
     id:"H34",
     enfermedad:"Oclusion vascular de la retina"
  },
  {
     id:"H340",
     enfermedad:"Oclusion arterial transitoria de la retina"
  },
  {
     id:"H341",
     enfermedad:"Oclusion de la arteria central de la retina"
  },
  {
     id:"H342",
     enfermedad:"Otras formas de oclusion de la arteria de la retina"
  },
  {
     id:"H348",
     enfermedad:"Otras oclusiones vasculares retinianas"
  },
  {
     id:"H349",
     enfermedad:"Oclusion vascular retiniana, sin otra especificacion"
  },
  {
     id:"H35",
     enfermedad:"Otros trastornos de la retina"
  },
  {
     id:"H350",
     enfermedad:"Retinopatias del fondo y cambios vasculares retinianos"
  },
  {
     id:"H351",
     enfermedad:"Retinopatia de la prematuridad"
  },
  {
     id:"H352",
     enfermedad:"Otras retinopatias proliferativas"
  },
  {
     id:"H353",
     enfermedad:"Degeneracion de la macula y del polo posterior del ojo"
  },
  {
     id:"H354",
     enfermedad:"Degeneracion periferica de la retina"
  },
  {
     id:"H355",
     enfermedad:"Distrofia hereditaria de la retina"
  },
  {
     id:"H356",
     enfermedad:"Hemorragia retiniana"
  },
  {
     id:"H357",
     enfermedad:"Separacion de las caspas de la retina"
  },
  {
     id:"H358",
     enfermedad:"Otros trastornos especificados de la retina"
  },
  {
     id:"H359",
     enfermedad:"Trastorno de la retina, no especificado"
  },
  {
     id:"H36",
     enfermedad:"Trastornos de la retina en enfermedades clasificadas en otra parte"
  },
  {
     id:"H360",
     enfermedad:"Retinopatia diabetica (e10"
  },
  {
     id:"H368",
     enfermedad:"Otros trastornos de la retina en enfermedades clasificadas en otra parte"
  },
  {
     id:"H40",
     enfermedad:"Glaucoma"
  },
  {
     id:"H400",
     enfermedad:"Sospecha de glaucoma"
  },
  {
     id:"H401",
     enfermedad:"Glaucoma primario de angulo abierto"
  },
  {
     id:"H402",
     enfermedad:"Glaucoma primario de angulo cerrado"
  },
  {
     id:"H403",
     enfermedad:"Glaucoma secundario a traumatismo ocular"
  },
  {
     id:"H404",
     enfermedad:"Glaucoma secundario a inflamacion ocular"
  },
  {
     id:"H405",
     enfermedad:"Glaucoma secundario a otros trastornos del ojo"
  },
  {
     id:"H406",
     enfermedad:"Glaucoma secundario a drogas"
  },
  {
     id:"H408",
     enfermedad:"Otros glaucomas"
  },
  {
     id:"H409",
     enfermedad:"Glaucoma, no especificado"
  },
  {
     id:"H42",
     enfermedad:"Glaucoma en enfermedades clasificadasif en otra parte"
  },
  {
     id:"H420",
     enfermedad:"Glaucoma en enfermedades endocrinas, nutricionales y metabolicas, clasificadas en otra parte"
  },
  {
     id:"H428",
     enfermedad:"Glaucoma en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"H43",
     enfermedad:"Trastornos del cuerpo vitreo"
  },
  {
     id:"H430",
     enfermedad:"Prolapso del vitreo"
  },
  {
     id:"H431",
     enfermedad:"Hemorragia del vitreo"
  },
  {
     id:"H432",
     enfermedad:"Depositos cristalinos en el cuerpo vitreo"
  },
  {
     id:"H433",
     enfermedad:"Otras opacidades vitreas"
  },
  {
     id:"H438",
     enfermedad:"Otros trastornos del cuerpo vitreo"
  },
  {
     id:"H439",
     enfermedad:"Trastornos del cuerpo vitreo, no especificado"
  },
  {
     id:"H44",
     enfermedad:"Trastornos del globo ocular"
  },
  {
     id:"H440",
     enfermedad:"Endoftalmitis purulenta"
  },
  {
     id:"H441",
     enfermedad:"Otras endoftalmitis"
  },
  {
     id:"H442",
     enfermedad:"Miopia degenerativa"
  },
  {
     id:"H443",
     enfermedad:"Otros trastornos degenerativos del globo ocular"
  },
  {
     id:"H444",
     enfermedad:"Hipotonia ocular"
  },
  {
     id:"H445",
     enfermedad:"Afecciones degenerativas del globo ocular"
  },
  {
     id:"H446",
     enfermedad:"Retencion intraocular de cuerpo extraño magnetico (antiguo)"
  },
  {
     id:"H447",
     enfermedad:"Retencion intraocular de cuerpo extraño no magnetico (antiguo)"
  },
  {
     id:"H448",
     enfermedad:"Otros trastornos del globo ocular"
  },
  {
     id:"H449",
     enfermedad:"Trastorno del globo ocular, no especificado"
  },
  {
     id:"H45",
     enfermedad:"Trastornos del cuerp vitreo y del globo ocular en enfermedades clasificadas en otra parte"
  },
  {
     id:"H450",
     enfermedad:"Hemorragia del vitreo en enfermedades clasificadas en otra parte"
  },
  {
     id:"H451",
     enfermedad:"Endoftalmitis en enfernedades clasificadas en otra parte"
  },
  {
     id:"H458",
     enfermedad:"Otros trastornos del cuerpo vitreo y del globo ocular en enfermedades clasificadas en otra parte"
  },
  {
     id:"H46",
     enfermedad:"Neuritis optica"
  },
  {
     id:"H47",
     enfermedad:"Otros trastornos del nervio optico [ ii par ] y de las vias opticas"
  },
  {
     id:"H470",
     enfermedad:"Trastornos del nervio optico, no clasificados en otra parte"
  },
  {
     id:"H471",
     enfermedad:"Papiledema, no especificado"
  },
  {
     id:"H472",
     enfermedad:"Atrofia optica"
  },
  {
     id:"H473",
     enfermedad:"Otros trastornos del disco optico"
  },
  {
     id:"H474",
     enfermedad:"Trastornos del quiasma optico"
  },
  {
     id:"H475",
     enfermedad:"Trastornos de otras vias opticas"
  },
  {
     id:"H476",
     enfermedad:"Trastornos de la corteza visual"
  },
  {
     id:"H477",
     enfermedad:"Trastornos de las vias opticas, no especificado"
  },
  {
     id:"H48",
     enfermedad:"Otros trastornos del nervio optico [ ii par ] y de las vias opticas en enfermedades clasificadas en otra parte"
  },
  {
     id:"H480",
     enfermedad:"Atrofia optica en enfermedades clasificadas en otra parte"
  },
  {
     id:"H481",
     enfermedad:"Neuritis retrobulbar en enfermedades clasificadas en otra parte"
  },
  {
     id:"H488",
     enfermedad:"Otros trastornos del nervio optico y de las vias opticas en enfermedades clasificadas en otra parte"
  },
  {
     id:"H49",
     enfermedad:"Estrabismo paralitico"
  },
  {
     id:"H490",
     enfermedad:"Paralisis del nervio motor ocular comun [iii par]"
  },
  {
     id:"H491",
     enfermedad:"Paralisis del nervio patetico [iv par]"
  },
  {
     id:"H492",
     enfermedad:"Paralisis del nervio motor ocular externo [vi par]"
  },
  {
     id:"H493",
     enfermedad:"Oftalmoplejia total (externa)"
  },
  {
     id:"H494",
     enfermedad:"Oftalmoplejia externa progresiva"
  },
  {
     id:"H498",
     enfermedad:"Otros estrabismos paraliticos"
  },
  {
     id:"H499",
     enfermedad:"Estrabismo paralitico, no especificado"
  },
  {
     id:"H50",
     enfermedad:"Otros estrabismos"
  },
  {
     id:"H500",
     enfermedad:"Estrabismo concomitante convergente"
  },
  {
     id:"H501",
     enfermedad:"Estrabismo concomitante divergente"
  },
  {
     id:"H502",
     enfermedad:"Estrabismo vertical"
  },
  {
     id:"H503",
     enfermedad:"Heterotropia intermitente"
  },
  {
     id:"H504",
     enfermedad:"Otras heterotropias o las no especificadas"
  },
  {
     id:"H505",
     enfermedad:"Heteroforia"
  },
  {
     id:"H506",
     enfermedad:"Estrabismo mecanico"
  },
  {
     id:"H508",
     enfermedad:"Otros estrabismos especificados"
  },
  {
     id:"H509",
     enfermedad:"Estrabismo, no especificado"
  },
  {
     id:"H51",
     enfermedad:"Otros trastornos de los movimientos binoculares"
  },
  {
     id:"H510",
     enfermedad:"Paralisis de la conjugacion de la mirada"
  },
  {
     id:"H511",
     enfermedad:"Exceso e insuficiencia de la convergencia ocular"
  },
  {
     id:"H512",
     enfermedad:"Oftalmoplejia internuclear"
  },
  {
     id:"H518",
     enfermedad:"Otros trastornos especificados de los movimientos binoculares"
  },
  {
     id:"H519",
     enfermedad:"Trastornos del movimiento binocular, no especificado"
  },
  {
     id:"H52",
     enfermedad:"Trastornos de la acomodacion y de la refraccion"
  },
  {
     id:"H520",
     enfermedad:"Hipermetropia"
  },
  {
     id:"H521",
     enfermedad:"Miopia"
  },
  {
     id:"H522",
     enfermedad:"Astigmatismo"
  },
  {
     id:"H523",
     enfermedad:"Anisometropia y aniseiconia"
  },
  {
     id:"H524",
     enfermedad:"Presbicia"
  },
  {
     id:"H525",
     enfermedad:"Trastornos de la acomodacion"
  },
  {
     id:"H526",
     enfermedad:"Otros trastornos de la refraccion"
  },
  {
     id:"H527",
     enfermedad:"Trastorno de la refraccion, no especificado"
  },
  {
     id:"H53",
     enfermedad:"Alteraciones de la vision"
  },
  {
     id:"H530",
     enfermedad:"Ambliopia ex anopsia"
  },
  {
     id:"H531",
     enfermedad:"Alteraciones visuales subjetivas"
  },
  {
     id:"H532",
     enfermedad:"Diplopia"
  },
  {
     id:"H533",
     enfermedad:"Otros trastornos de la vision binocular"
  },
  {
     id:"H534",
     enfermedad:"Defectos del campo visual"
  },
  {
     id:"H535",
     enfermedad:"Deficiencias de la vision cromatica"
  },
  {
     id:"H536",
     enfermedad:"Ceguera nocturna"
  },
  {
     id:"H538",
     enfermedad:"Otras alteraciones visuales"
  },
  {
     id:"H539",
     enfermedad:"Alteracion visual, no especificada"
  },
  {
     id:"H54",
     enfermedad:"Ceguera y disminucion de la agudeza visual"
  },
  {
     id:"H540",
     enfermedad:"Ceguera de ambos ojos"
  },
  {
     id:"H541",
     enfermedad:"Ceguera de un ojo, vision subnormal del otro"
  },
  {
     id:"H542",
     enfermedad:"Vision subnormal de ambos ojos"
  },
  {
     id:"H543",
     enfermedad:"Disminucion indeterminada de la agudeza visual en ambos ojos"
  },
  {
     id:"H544",
     enfermedad:"Ceguera de un ojo"
  },
  {
     id:"H545",
     enfermedad:"Vision subnormal de un ojo"
  },
  {
     id:"H546",
     enfermedad:"Disminucion indeterminada de la agudeza visual de un ojo"
  },
  {
     id:"H547",
     enfermedad:"Disminucion de la agudeza visual, sin especificacion"
  },
  {
     id:"H55",
     enfermedad:"Nistagmo y otros movimientos oculares irregulares"
  },
  {
     id:"H57",
     enfermedad:"Otros trastornos del ojo y sus anexos"
  },
  {
     id:"H570",
     enfermedad:"Anomalias de la funcion pupilar"
  },
  {
     id:"H571",
     enfermedad:"Dolor ocular"
  },
  {
     id:"H578",
     enfermedad:"Otros trastornos especificados del ojo y sus anexos"
  },
  {
     id:"H579",
     enfermedad:"Trastorno del ojo y sus anexos, no especificado"
  },
  {
     id:"H58",
     enfermedad:"Otros trastornos del ojo y sus anexos en enfermedades clasificadas en otra parte"
  },
  {
     id:"H580",
     enfermedad:"Anomalias de la funcion pupilar en enfermedades clasificadas en otra parte"
  },
  {
     id:"H581",
     enfermedad:"Alteraciones de la vision en enfermedades clasificadas en otra parte"
  },
  {
     id:"H588",
     enfermedad:"Otros trastornos especificados del ojo en enfermedades clasificadas en otra parte"
  },
  {
     id:"H59",
     enfermedad:"Trastornos del ojo y sus anexos consecutivos a procedimientos, no clasificados en otra parte"
  },
  {
     id:"H590",
     enfermedad:"Sindrome vitreo consecutivo a cirugia de catarata"
  },
  {
     id:"H598",
     enfermedad:"Otros trastornos del ojo y sus anexos, consecutivos a procedimientos"
  },
  {
     id:"H599",
     enfermedad:"Trastorno no especificado del ojo y sus anexos, consecutivo a procedimientos"
  },
  {
     id:"H60",
     enfermedad:"Otitis externa"
  },
  {
     id:"H600",
     enfermedad:"Absceso del oido externo"
  },
  {
     id:"H601",
     enfermedad:"Celulitis del oido externo"
  },
  {
     id:"H602",
     enfermedad:"Otitis externa maligna"
  },
  {
     id:"H603",
     enfermedad:"Otras otitis externas infecciosas"
  },
  {
     id:"H604",
     enfermedad:"Colesteatoma del oido externo"
  },
  {
     id:"H605",
     enfermedad:"Otitis externa aguda, no infecciosa"
  },
  {
     id:"H608",
     enfermedad:"Otras otitis externas"
  },
  {
     id:"H609",
     enfermedad:"Otitis externa, sin otra especificacion"
  },
  {
     id:"H61",
     enfermedad:"Otros trastornos del oido externo"
  },
  {
     id:"H610",
     enfermedad:"Pericondritis del oido externo"
  },
  {
     id:"H611",
     enfermedad:"Afecciones no infecciosas del pabellon auditivo"
  },
  {
     id:"H612",
     enfermedad:"Cerumen impactado"
  },
  {
     id:"H613",
     enfermedad:"Estenosis adquirida del conducto auditivo externo"
  },
  {
     id:"H618",
     enfermedad:"Otros trastornos especificados del oido externo"
  },
  {
     id:"H619",
     enfermedad:"Trastorno del oido externo, no especificado"
  },
  {
     id:"H62",
     enfermedad:"Trastornos del oido externo en enfermedades clasificadas en otra parte"
  },
  {
     id:"H620",
     enfermedad:"Otitis externa en enfermedades bacterianas clasificadas en otra parte"
  },
  {
     id:"H621",
     enfermedad:"Otitis externa en enfermedades virales clasificadas en otra parte"
  },
  {
     id:"H622",
     enfermedad:"Otitis externa en micosis"
  },
  {
     id:"H623",
     enfermedad:"Otitis externa en otras enfermedades infecciosas y parasitarias clasificadas en otra pate"
  },
  {
     id:"H624",
     enfermedad:"Otitis externa en otras enfermedades clasificadas en otra pate"
  },
  {
     id:"H628",
     enfermedad:"Otros trastornos del oido externo en enfermedades clasificadas en otra parte"
  },
  {
     id:"H65",
     enfermedad:"Otitis media no supurativa"
  },
  {
     id:"H650",
     enfermedad:"Otitis media aguda serosa"
  },
  {
     id:"H651",
     enfermedad:"Otra otitis media aguda, no supurativa"
  },
  {
     id:"H652",
     enfermedad:"Otitis media cronica serosa"
  },
  {
     id:"H653",
     enfermedad:"Otitis media cronica mucoide"
  },
  {
     id:"H654",
     enfermedad:"Otras otitis medias cronicas no supurativas"
  },
  {
     id:"H659",
     enfermedad:"Otitis media no supurativa, sin otra especificacion"
  },
  {
     id:"H66",
     enfermedad:"Otitis media supurativa y la no especificada"
  },
  {
     id:"H660",
     enfermedad:"Otitis media supurativa aguda"
  },
  {
     id:"H661",
     enfermedad:"Otitis media tubotimpanica supurativa cronica"
  },
  {
     id:"H662",
     enfermedad:"Otitis media supurativa cronica aticoantral"
  },
  {
     id:"H663",
     enfermedad:"Otras otitis medias cronicas supurativas"
  },
  {
     id:"H664",
     enfermedad:"Otitis media supurativa, sin otra especificacion"
  },
  {
     id:"H669",
     enfermedad:"Otitis media, no especificada"
  },
  {
     id:"H67",
     enfermedad:"Otitis media en enfermedades clasificadas en otra parte"
  },
  {
     id:"H670",
     enfermedad:"Otitis media en enfermedades bacterianas clasificadas en otra parte"
  },
  {
     id:"H671",
     enfermedad:"Otitis media en enfermedades virales clasificadas en otra parte"
  },
  {
     id:"H678",
     enfermedad:"Otitis media en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"H68",
     enfermedad:"Inflamacion y obstruccion de la trompa de eustaquio"
  },
  {
     id:"H680",
     enfermedad:"Salpingitis eustaquiana"
  },
  {
     id:"H681",
     enfermedad:"Obstruccion de la trompa de eustaquio"
  },
  {
     id:"H69",
     enfermedad:"Otros trastornos de la trompa de eustaquio"
  },
  {
     id:"H690",
     enfermedad:"Distension de la trompa de eustaquio"
  },
  {
     id:"H698",
     enfermedad:"Otros trastornos especificados de la trompa de eustaquio"
  },
  {
     id:"H699",
     enfermedad:"Trastorno de la trompa de eustaquio, no especificado"
  },
  {
     id:"H70",
     enfermedad:"Mastoiditis y afecciones relacionadas"
  },
  {
     id:"H700",
     enfermedad:"Mastoiditis aguda"
  },
  {
     id:"H701",
     enfermedad:"Mastoiditis cronica"
  },
  {
     id:"H702",
     enfermedad:"Petrositis"
  },
  {
     id:"H708",
     enfermedad:"Otras mastoiditis y afecciones relacionadas"
  },
  {
     id:"H709",
     enfermedad:"Mastoiditis, no especificada"
  },
  {
     id:"H71",
     enfermedad:"Colesteatoma del oido medio"
  },
  {
     id:"H72",
     enfermedad:"Perforacion de la membrana timpanica"
  },
  {
     id:"H720",
     enfermedad:"Perforacion central de la membrana timpanica"
  },
  {
     id:"H721",
     enfermedad:"Perforacion atica de la membrana timpanica"
  },
  {
     id:"H722",
     enfermedad:"Otras perforaciones marginales de la membrana timpanica"
  },
  {
     id:"H728",
     enfermedad:"Otras perforaciones de la membrana timpanica"
  },
  {
     id:"H729",
     enfermedad:"Perforacion de la membrana timpanica, sin otra especificacion"
  },
  {
     id:"H73",
     enfermedad:"Otros trastornos de la membrana timpanica"
  },
  {
     id:"H730",
     enfermedad:"Miringitis aguda"
  },
  {
     id:"H731",
     enfermedad:"Miringitis cronica"
  },
  {
     id:"H738",
     enfermedad:"Otros trastornos especificados de la membrana timpanica"
  },
  {
     id:"H739",
     enfermedad:"Trastorno de la membrana timpanica, no especificado"
  },
  {
     id:"H74",
     enfermedad:"Otros trastornos del oido medio y de la apofisis mastoides"
  },
  {
     id:"H740",
     enfermedad:"Timpanosclerosis"
  },
  {
     id:"H741",
     enfermedad:"Enfermedad adhesiva del oido medio"
  },
  {
     id:"H742",
     enfermedad:"Discontinuidad y dislocacion de los huesecillos del oido"
  },
  {
     id:"H743",
     enfermedad:"Otras anormalidades adquiridas de los huesecillos del oido"
  },
  {
     id:"H744",
     enfermedad:"Polipo del oido medio"
  },
  {
     id:"H748",
     enfermedad:"Otros trastornos especificados del oido medio y de la apofisis mastoides"
  },
  {
     id:"H749",
     enfermedad:"Trastorno del oido medio y de la apofisis mastoides, no especificado"
  },
  {
     id:"H75",
     enfermedad:"Otros trastornos del oido medio y de la apofisis mastoidesen enfermedades clasificadas en otra parte"
  },
  {
     id:"H750",
     enfermedad:"Mastoiditis en enfermedades infecciosas y parasitarias clasificadas en otra parte"
  },
  {
     id:"H758",
     enfermedad:"Otros trastornos especificados del oido medio y de la apofisis mastoides en enfermedades clasificadas en otra parte"
  },
  {
     id:"H80",
     enfermedad:"Otosclerosis"
  },
  {
     id:"H800",
     enfermedad:"Otosclerosis que afecta la ventana oval, no obliterante"
  },
  {
     id:"H801",
     enfermedad:"Otosclerosis que afecta la ventana oval, obliterante"
  },
  {
     id:"H802",
     enfermedad:"Ostosclerosis coclear"
  },
  {
     id:"H808",
     enfermedad:"Otras otosclerosis"
  },
  {
     id:"H809",
     enfermedad:"Otosclerosis, no especificada"
  },
  {
     id:"H81",
     enfermedad:"Trastornos de la funcion vestibular"
  },
  {
     id:"H810",
     enfermedad:"Enfermedad de meniere"
  },
  {
     id:"H811",
     enfermedad:"Vertigo paroxistico benigno"
  },
  {
     id:"H812",
     enfermedad:"Neuronitis vestibular"
  },
  {
     id:"H813",
     enfermedad:"Otros vertigos perifericos"
  },
  {
     id:"H814",
     enfermedad:"Vertigo de origen central"
  },
  {
     id:"H818",
     enfermedad:"Otros trastornos de la funcion vestibular"
  },
  {
     id:"H819",
     enfermedad:"Trastorno de la funcion vestibular, no especificado"
  },
  {
     id:"H82",
     enfermedad:"Sindromes vertiginosos en enfermedades clasificadas en otra parte"
  },
  {
     id:"H83",
     enfermedad:"Otros trastornos del oido interno"
  },
  {
     id:"H830",
     enfermedad:"Laberintitis"
  },
  {
     id:"H831",
     enfermedad:"Fistula del laberinto"
  },
  {
     id:"H832",
     enfermedad:"Disfuncion del laberinto"
  },
  {
     id:"H833",
     enfermedad:"Efectos del ruido sobre el oido interno"
  },
  {
     id:"H838",
     enfermedad:"Otros trastornos especificados del oido interno"
  },
  {
     id:"H839",
     enfermedad:"Trastorno del oido interno, no especificado"
  },
  {
     id:"H90",
     enfermedad:"Hipoacusia conductiva y neurosensorial"
  },
  {
     id:"H900",
     enfermedad:"Hipoacusia conductiva bilateral"
  },
  {
     id:"H901",
     enfermedad:"Hipoacusia conductiva, unilateral con audicion irrestricta contralateral"
  },
  {
     id:"H902",
     enfermedad:"Hipoacusia conductiva, sin otra especificacion"
  },
  {
     id:"H903",
     enfermedad:"Hipoacusia neurosensorial, bilateral"
  },
  {
     id:"H904",
     enfermedad:"Hipoacusia neurosensorial, unilateral con audicion irrestricta contralateral"
  },
  {
     id:"H905",
     enfermedad:"Hipoacusia neurosensorial, sin otra especificacion"
  },
  {
     id:"H906",
     enfermedad:"Hipoacusia mixta conductiva y neurosensorial, bilateral"
  },
  {
     id:"H907",
     enfermedad:"Hipoacusia mixta conductiva y neurosensorial, unilateral con audicion irrestricta contralateral"
  },
  {
     id:"H908",
     enfermedad:"Hipoacusia mixta conductiva y neurosensorial, no especificada"
  },
  {
     id:"H91",
     enfermedad:"Otras hipoacusias"
  },
  {
     id:"H910",
     enfermedad:"Hipoacusia ototoxica"
  },
  {
     id:"H911",
     enfermedad:"Presbiacusia"
  },
  {
     id:"H912",
     enfermedad:"Hipoacusia subida idiopatica"
  },
  {
     id:"H913",
     enfermedad:"Sordomudez, no clasificada en otra parte"
  },
  {
     id:"H918",
     enfermedad:"Otras hipoacusias especificadas"
  },
  {
     id:"H919",
     enfermedad:"Hipoacusia, no especificada"
  },
  {
     id:"H92",
     enfermedad:"Otalgia y secrecion del oido"
  },
  {
     id:"H920",
     enfermedad:"Otalgia"
  },
  {
     id:"H921",
     enfermedad:"Otorrea"
  },
  {
     id:"H922",
     enfermedad:"Otorragia"
  },
  {
     id:"H93",
     enfermedad:"Otros trastornos del oido no clasificados en otra parte"
  },
  {
     id:"H930",
     enfermedad:"Trastornos degenerativos y vasculares del oido"
  },
  {
     id:"H931",
     enfermedad:"Tinnitus"
  },
  {
     id:"H932",
     enfermedad:"Otras percepciones auditivas anormales"
  },
  {
     id:"H933",
     enfermedad:"Trastornos del nervio auditivo"
  },
  {
     id:"H938",
     enfermedad:"Otros trastornos especificados del oido"
  },
  {
     id:"H939",
     enfermedad:"Trastorno del oido, no especificado"
  },
  {
     id:"H94",
     enfermedad:"Otros trastornos del oido en enfermedades clasificadas en otra parte"
  },
  {
     id:"H940",
     enfermedad:"Neuritis del nervio auditivo en enfermedades infecciosas y parasitarias clasificadas en otra parte"
  },
  {
     id:"H948",
     enfermedad:"Otros trastornos del oido en enfermedades clasificadas en otra parte"
  },
  {
     id:"H95",
     enfermedad:"Trastornos del oido y de la apofisis mastoides consecutivos a procedimientos no clasificados en otra parte"
  },
  {
     id:"H950",
     enfermedad:"Colesteatoma recurrente de la cavidad resultante de la mastoidectomia"
  },
  {
     id:"H951",
     enfermedad:"Otros trastornos posteriores a la mastoidectomia"
  },
  {
     id:"H958",
     enfermedad:"Otros trastornos del oido y de la apofisis mastoides, consecutivos a procedimientos"
  },
  {
     id:"H959",
     enfermedad:"Trastornos no especificados del oido y de la apofisis mastoides, consecutivos a procedimientos"
  },
  {
     id:"I",
     enfermedad:"Ciertas enfermedades infecciosas y parasitarias"
  },
  {
     id:"I00",
     enfermedad:"Fiebre reumatica sin mencion de complicacion cardiaca"
  },
  {
     id:"I01",
     enfermedad:"Fiebre reumatica con complicacion cardiaca"
  },
  {
     id:"I010",
     enfermedad:"Pericarditis reumatica aguda"
  },
  {
     id:"I011",
     enfermedad:"Endocarditis reumatica aguda"
  },
  {
     id:"I012",
     enfermedad:"Miocarditis reumatica aguda"
  },
  {
     id:"I018",
     enfermedad:"Otras enfermedades reumaticas agudas del corazon"
  },
  {
     id:"I019",
     enfermedad:"Enfermedad reumatica aguda del corazon, no especificada"
  },
  {
     id:"I02",
     enfermedad:"Corea reumatica"
  },
  {
     id:"I020",
     enfermedad:"Corea reumatica con complicacion cardiaca"
  },
  {
     id:"I029",
     enfermedad:"Corea reumatica sin mencion de complicacion cardiaca"
  },
  {
     id:"I05",
     enfermedad:"Enfermedades reumaticas de la valvula mitral"
  },
  {
     id:"I050",
     enfermedad:"Estenosis mitral"
  },
  {
     id:"I051",
     enfermedad:"Insuficiencia mitral reumatica"
  },
  {
     id:"I052",
     enfermedad:"Estenosis mitral con insuficiencia"
  },
  {
     id:"I058",
     enfermedad:"Otras enfermedades de la valvula mitral"
  },
  {
     id:"I059",
     enfermedad:"Enfermedad valvular mitral, no especificada"
  },
  {
     id:"I06",
     enfermedad:"Enfermedades reumaticas de la valvula aortica"
  },
  {
     id:"I060",
     enfermedad:"Estenosis aortica reumatica"
  },
  {
     id:"I061",
     enfermedad:"Insuficiencia aortica reumatica"
  },
  {
     id:"I062",
     enfermedad:"Insuficiencia aortica reumatica con insuficiencia"
  },
  {
     id:"I068",
     enfermedad:"Otras enfermedades reumaticas de la valvula aortica"
  },
  {
     id:"I069",
     enfermedad:"Enfermedad valvular aortica reumatica, no especificada"
  },
  {
     id:"I07",
     enfermedad:"Enfermedades reumaticas de la valvula tricuspide"
  },
  {
     id:"I070",
     enfermedad:"Estenosis tricuspide"
  },
  {
     id:"I071",
     enfermedad:"Insuficiencia tricuspide"
  },
  {
     id:"I072",
     enfermedad:"Estenosis e insuficiencia tricuspide"
  },
  {
     id:"I078",
     enfermedad:"Otras enfermedades de la valvula tricuspide"
  },
  {
     id:"I079",
     enfermedad:"Enfermedad de la valvula tricuspide, no especificada"
  },
  {
     id:"I08",
     enfermedad:"Enfermedades valvulares multiples"
  },
  {
     id:"I080",
     enfermedad:"Trastornos de las valvulas mitral y aortica"
  },
  {
     id:"I081",
     enfermedad:"Trastornos de las valvulas mitral y tricuspide"
  },
  {
     id:"I082",
     enfermedad:"Trastornos de las valvulas aortica y tricuspide"
  },
  {
     id:"I083",
     enfermedad:"Trastornos combinados de las valvulas mitral, tricuspide y aortica"
  },
  {
     id:"I088",
     enfermedad:"Otras enfermedades de multiples valvulas"
  },
  {
     id:"I089",
     enfermedad:"Enfermedad de multiples valvulas, no especificada"
  },
  {
     id:"I09",
     enfermedad:"Otras enfermedades reumaticas del corazon"
  },
  {
     id:"I090",
     enfermedad:"Miocarditis reumatica"
  },
  {
     id:"I091",
     enfermedad:"Enfermedades reumaticas del endocardio, valvula no especificada"
  },
  {
     id:"I092",
     enfermedad:"Pericarditis reumatica cronica"
  },
  {
     id:"I098",
     enfermedad:"Otras enfermedades reumaticas especificadas del corazon"
  },
  {
     id:"I099",
     enfermedad:"Enfermedad reumatica del corazon, no especificada"
  },
  {
     id:"I10",
     enfermedad:"Hipertension esencial (primaria)"
  },
  {
     id:"I11",
     enfermedad:"Enfermedad cardiaca hipertensiva"
  },
  {
     id:"I110",
     enfermedad:"Enfermedad cardiaca hipertensiva con insuficiencia cardiaca (congestiva)"
  },
  {
     id:"I119",
     enfermedad:"Enfermedad cardiaca hipertensiva sin insuficiencia cardiaca (congestiva)"
  },
  {
     id:"I12",
     enfermedad:"Enfermedad renal hipertensiva"
  },
  {
     id:"I120",
     enfermedad:"Enfermedad renal hipertensiva con insuficiencia renal"
  },
  {
     id:"I129",
     enfermedad:"Enfermedad renal hipertensiva sin insuficiencia renal"
  },
  {
     id:"I13",
     enfermedad:"Enfermedad cardiorrenal hipertensiva"
  },
  {
     id:"I130",
     enfermedad:"Enfermedad cardiorrenal hipertensiva con insuficiencia cardiaca (congestiva)"
  },
  {
     id:"I131",
     enfermedad:"Enfermedad cardiorrenal hipertensiva con insuficiencia renal"
  },
  {
     id:"I132",
     enfermedad:"Enfermedad cardiorrenal hipertensiva con insuficiencia cardiaca (congestiva) e insuficiencia renal"
  },
  {
     id:"I139",
     enfermedad:"Enfermedad cardiorrenal hipertensiva, no especificada"
  },
  {
     id:"I15",
     enfermedad:"Hipertension secundaria"
  },
  {
     id:"I150",
     enfermedad:"Hipertension renovascular"
  },
  {
     id:"I151",
     enfermedad:"Hipertension secundaria a otros trastornos renales"
  },
  {
     id:"I152",
     enfermedad:"Hipertension secundaria a trastornos endocrinos"
  },
  {
     id:"I158",
     enfermedad:"Otros tipos de hipertension secundaria"
  },
  {
     id:"I159",
     enfermedad:"Hipertension secundaria, no especificada"
  },
  {
     id:"I20",
     enfermedad:"Angina de pecho"
  },
  {
     id:"I200",
     enfermedad:"Angina inestable"
  },
  {
     id:"I201",
     enfermedad:"Angina de pecho con espasmo documentado"
  },
  {
     id:"I208",
     enfermedad:"Otras formas especificadas de angina de pecho"
  },
  {
     id:"I209",
     enfermedad:"Angina de pecho, no especificada"
  },
  {
     id:"I21",
     enfermedad:"Infarto agudo del miocardio"
  },
  {
     id:"I210",
     enfermedad:"Infarto transmural agudo del miocardio de la pared anterior"
  },
  {
     id:"I211",
     enfermedad:"Infarto transmural agudo del miocardio de la pared inferior"
  },
  {
     id:"I212",
     enfermedad:"Infarto agudo transmural del miocardio de otros sitios"
  },
  {
     id:"I213",
     enfermedad:"Infarto transmural agudo del miocardio, de sitio no especificado"
  },
  {
     id:"I214",
     enfermedad:"Infarto subendocardico agudo del miocardio"
  },
  {
     id:"I219",
     enfermedad:"Infarto agudo del miocardio, sin otra especificacion"
  },
  {
     id:"I22",
     enfermedad:"Infarto subsecuente del miocardio"
  },
  {
     id:"I220",
     enfermedad:"Infarto subsecuente del miocardio de la pared anterior"
  },
  {
     id:"I221",
     enfermedad:"Infarto subsecuente del miocardio de la pared inferior"
  },
  {
     id:"I228",
     enfermedad:"Infarto subsecuente del miocardio de otros sitios"
  },
  {
     id:"I229",
     enfermedad:"Infarto subsecuente del miocardio de parte no especificada"
  },
  {
     id:"I23",
     enfermedad:"Ciertas complicaciones presentes post eriores al infarto agudo del miocardio"
  },
  {
     id:"I230",
     enfermedad:"Hemopericardio como complicacion presente posterior al infarto agudo del miocardio"
  },
  {
     id:"I231",
     enfermedad:"Defecto del tabique auricular como complicacion presente posterior al infarto del miocardio"
  },
  {
     id:"I232",
     enfermedad:"Defecto del tabique ventricular como complicacion presente posterior al infarto del miocardio"
  },
  {
     id:"I233",
     enfermedad:"Ruptura de la pared cardiaca sin hemopericardio como compilacion presente posterior al infarto agudo del miocardio"
  },
  {
     id:"I234",
     enfermedad:"Ruptura de las cuerdas tendinosas como complicacion presente posterior al infarto agudo del miocardio"
  },
  {
     id:"I235",
     enfermedad:"Ruptura de musculo papilar como complicacion presente posterior al infarto agudo del miocardio"
  },
  {
     id:"I236",
     enfermedad:"Trombosis de la auricula, apendice auricular y ventriculo como complicacion presente posterior al infarto agudo del miocardio"
  },
  {
     id:"I238",
     enfermedad:"Otras complicaciones presentes posteriores al infarto agudo del miocardio"
  },
  {
     id:"I24",
     enfermedad:"Otras enfermedades isquemicas agudas del corazon"
  },
  {
     id:"I240",
     enfermedad:"Trombosis coronaria que no resulta en infarto del miocardio"
  },
  {
     id:"I241",
     enfermedad:"Sindrome de dressler"
  },
  {
     id:"I248",
     enfermedad:"Otras formas de enfermedad isquemica aguda del corazon"
  },
  {
     id:"I249",
     enfermedad:"Enfermedad isquemica aguda del corazon, no especificada"
  },
  {
     id:"I25",
     enfermedad:"Enfermedad isquemica cronica del corazon"
  },
  {
     id:"I250",
     enfermedad:"Enfermedad cardiovascular aterosclerotica, asi descrita"
  },
  {
     id:"I251",
     enfermedad:"Enfermedad aterosclerotica del corazon"
  },
  {
     id:"I252",
     enfermedad:"Infarto antiguo del miocardio"
  },
  {
     id:"I253",
     enfermedad:"Aneurisma cardiaco"
  },
  {
     id:"I254",
     enfermedad:"Aneurisma de arteria coronaria"
  },
  {
     id:"I255",
     enfermedad:"Cardiomiopatia isquemica"
  },
  {
     id:"I256",
     enfermedad:"Isquemia silente del miocardio"
  },
  {
     id:"I258",
     enfermedad:"Otras formas de enfermedad isquemica cronica del corazon"
  },
  {
     id:"I259",
     enfermedad:"Enfermedad isquemia cronica del corazon, no especificada"
  },
  {
     id:"I26",
     enfermedad:"Embolia pulmonar"
  },
  {
     id:"I260",
     enfermedad:"Embolia pulmonar con mencion de corazon pulmonar agudo"
  },
  {
     id:"I269",
     enfermedad:"Embolia pulmonar sin mencion de corazon pulmonar agudo"
  },
  {
     id:"I27",
     enfermedad:"Otras enfermedades cardiopulmonares"
  },
  {
     id:"I270",
     enfermedad:"Hipertension pulmonar primaria"
  },
  {
     id:"I271",
     enfermedad:"Enfermedad cifoscoliotica del corazon"
  },
  {
     id:"I272",
     enfermedad:"Otras hipertensiones pulmonares secundarias"
  },
  {
     id:"I278",
     enfermedad:"Otras enfermedades cardiopulmonares especificadas"
  },
  {
     id:"I279",
     enfermedad:"Enfermedad pulmonar del corazon, no especificada"
  },
  {
     id:"I28",
     enfermedad:"Otras enfermedades vasos pulmonares"
  },
  {
     id:"I280",
     enfermedad:"Fistula arteriovenosa de los vasos pulmonares"
  },
  {
     id:"I281",
     enfermedad:"Aneurisma de la arteria pulmonar"
  },
  {
     id:"I288",
     enfermedad:"Otras enfermedades especificadas de los vasos pulmonares"
  },
  {
     id:"I289",
     enfermedad:"Enfermedad de los vasos pulmonares, no especificada"
  },
  {
     id:"I30",
     enfermedad:"Pericarditis aguda"
  },
  {
     id:"I300",
     enfermedad:"Pericarditis idiopatica aguda inespecifica"
  },
  {
     id:"I301",
     enfermedad:"Pericarditis infecciosa"
  },
  {
     id:"I308",
     enfermedad:"Otras formas de pericarditis aguda"
  },
  {
     id:"I309",
     enfermedad:"Pericarditis aguda, no especificada"
  },
  {
     id:"I31",
     enfermedad:"Otras enfermedades del pericardio"
  },
  {
     id:"I310",
     enfermedad:"Pericarditis cronica adhesiva"
  },
  {
     id:"I311",
     enfermedad:"Pericarditis constrictiva cronica"
  },
  {
     id:"I312",
     enfermedad:"Hemopericardio, no clasificado en otra parte"
  },
  {
     id:"I313",
     enfermedad:"Derrame pericardico (no inflamatorio)"
  },
  {
     id:"I318",
     enfermedad:"Otras enfermedades especificadas del pericardio"
  },
  {
     id:"I319",
     enfermedad:"Enfermedad del pericardio, no especificada"
  },
  {
     id:"I32",
     enfermedad:"Pericarditis en enfermedades clasificadas en otra parte"
  },
  {
     id:"I320",
     enfermedad:"Pericarditis en enfermedades bacterianas clasificadas en otra parte"
  },
  {
     id:"I321",
     enfermedad:"Pericarditis en otras enfermedades infecciosas y parasitarias clasificadas en otra parte"
  },
  {
     id:"I328",
     enfermedad:"Pericarditis en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"I33",
     enfermedad:"Endocarditis aguda y subaguda"
  },
  {
     id:"I330",
     enfermedad:"Endocarditis infecciosa aguda y subaguda"
  },
  {
     id:"I339",
     enfermedad:"Endocarditis aguda, no especificada"
  },
  {
     id:"I34",
     enfermedad:"Trastornos no reumaticos de la valvula mitral"
  },
  {
     id:"I340",
     enfermedad:"Insuficiencia (de la valvula) mitral"
  },
  {
     id:"I341",
     enfermedad:"Prolapso (de la valvula) mitral"
  },
  {
     id:"I342",
     enfermedad:"Estenosis (de la valvula) mitral, no reumatica"
  },
  {
     id:"I348",
     enfermedad:"Otros trastornos no reumaticos de la valvula mitral"
  },
  {
     id:"I349",
     enfermedad:"Trastorno mitral no reumatico, no especificado"
  },
  {
     id:"I35",
     enfermedad:"Trastornos no reumaticos de la valvula aortica"
  },
  {
     id:"I350",
     enfermedad:"Estenosis (de la valvula) aortica"
  },
  {
     id:"I351",
     enfermedad:"Insuficiencia (de la valvula) aortica"
  },
  {
     id:"I352",
     enfermedad:"Estenosis (de la valvula) aortica con insuficiencia"
  },
  {
     id:"I358",
     enfermedad:"Otros trastornos de la valvula aortica"
  },
  {
     id:"I359",
     enfermedad:"Trastorno de la valvula aortica, no especificado"
  },
  {
     id:"I36",
     enfermedad:"Trastornos no reumaticos de la valvula tricuspide"
  },
  {
     id:"I360",
     enfermedad:"Estenosis no reumatica (de la valvula) tricuspide)"
  },
  {
     id:"I361",
     enfermedad:"Insuficiencia no reumatica (de la valvula) tricuspide"
  },
  {
     id:"I362",
     enfermedad:"Estenosis con insuficiencia no reumatica (de la valvula) tricuspide"
  },
  {
     id:"I368",
     enfermedad:"Otros trastornos no reumaticos de la valvula tricuspide"
  },
  {
     id:"I369",
     enfermedad:"Trastorno no reumatico de la valvula tricuspide, no especificado"
  },
  {
     id:"I37",
     enfermedad:"Trastornos de la valvula pulmonar"
  },
  {
     id:"I370",
     enfermedad:"Estenosis de la valvula pulmonar"
  },
  {
     id:"I371",
     enfermedad:"Insuficiencia de la valvula pulmonar"
  },
  {
     id:"I372",
     enfermedad:"Estenosis de la valvula pulmonar con insuficiencia"
  },
  {
     id:"I378",
     enfermedad:"Otros trastornos de la valvula pulmonar"
  },
  {
     id:"I379",
     enfermedad:"Trastorno de la valvula pulmonar, no especificado"
  },
  {
     id:"I38",
     enfermedad:"Endocarditis, valvula no especificada"
  },
  {
     id:"I39",
     enfermedad:"Endocarditis y trastornos valvulares en enfermedades clasificadas en otra parte"
  },
  {
     id:"I390",
     enfermedad:"Trastornos de la valvula mitral en enfermedades clasificadas en otra parte"
  },
  {
     id:"I391",
     enfermedad:"Trastornos de la valvula aortica en enfermedades clasificadas en otra parte"
  },
  {
     id:"I392",
     enfermedad:"Trastornos de la valvula tricuspide en enfermedades clasificadas en otra parte"
  },
  {
     id:"I393",
     enfermedad:"Trastornos de la valvula pulmonar en enfermedades clasificadas en otra parte"
  },
  {
     id:"I394",
     enfermedad:"Trastornos de la valvulares multiples en enfermedades clasificadas en otra parte"
  },
  {
     id:"I398",
     enfermedad:"Endocarditis, valvula no especificada, en enfermedades clasificadas en otra parte"
  },
  {
     id:"I40",
     enfermedad:"Miocarditis aguda"
  },
  {
     id:"I400",
     enfermedad:"Miocarditis infecciosa"
  },
  {
     id:"I401",
     enfermedad:"Miocarditis aislada"
  },
  {
     id:"I408",
     enfermedad:"Otras miocarditis agudas"
  },
  {
     id:"I409",
     enfermedad:"Miocarditis aguda, no especificada"
  },
  {
     id:"I41",
     enfermedad:"Miocarditis en enfermedades clasificadas en otra parte"
  },
  {
     id:"I410",
     enfermedad:"Miocarditis en enfermedades bacterianas clasificadas en otra parte"
  },
  {
     id:"I411",
     enfermedad:"Miocarditis en enfermedades virales clasificadas en otra parte"
  },
  {
     id:"I412",
     enfermedad:"Miocarditis en otras enfermedades infecciosas y parasitarias clasificadas en otra parte"
  },
  {
     id:"I418",
     enfermedad:"Miocarditis en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"I42",
     enfermedad:"Cardiomiopatia"
  },
  {
     id:"I420",
     enfermedad:"Cardiomiopatia dilatada"
  },
  {
     id:"I421",
     enfermedad:"Cardiomiopatia hipertrofica obstructiva"
  },
  {
     id:"I422",
     enfermedad:"Otras cardiomiopatia hipertroficas"
  },
  {
     id:"I423",
     enfermedad:"Enfermedad endomiocardica (eosinofilica)"
  },
  {
     id:"I424",
     enfermedad:"Fibroelastosis endocardica"
  },
  {
     id:"I425",
     enfermedad:"Otras cardiomiopatias restrictivas"
  },
  {
     id:"I426",
     enfermedad:"Cardiomiopatia alcoholica"
  },
  {
     id:"I427",
     enfermedad:"Cardiomiopatia debida a drogas y a otros agentes externos"
  },
  {
     id:"I428",
     enfermedad:"Otras cardiomiopatia"
  },
  {
     id:"I429",
     enfermedad:"Cardiomiopatia, no especificada"
  },
  {
     id:"I43",
     enfermedad:"Cardiomiopatia en enfermedades clasificadas en otra parte"
  },
  {
     id:"I430",
     enfermedad:"Cardiomiopatia en enfermedades infecciosas y parasitarias clasificadas en otra parte"
  },
  {
     id:"I431",
     enfermedad:"Cardiomiopatia en enfermedades metabolicas"
  },
  {
     id:"I432",
     enfermedad:"Cardiomiopatia en enfermedades nutricionales"
  },
  {
     id:"I438",
     enfermedad:"Cardiomiopatia en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"I44",
     enfermedad:"Bloqueo auriculoventricular y de rama izquierda del haz"
  },
  {
     id:"I440",
     enfermedad:"Bloqueo auriculoventricular de primer grado"
  },
  {
     id:"I441",
     enfermedad:"Bloqueo auriculoventricular de segundo grado"
  },
  {
     id:"I442",
     enfermedad:"Bloqueo auriculoventricular completo"
  },
  {
     id:"I443",
     enfermedad:"Otros tipos de bloqueo auriculoventricular y los no especificados"
  },
  {
     id:"I444",
     enfermedad:"Bloqueo fascicular anterior izquierdo"
  },
  {
     id:"I445",
     enfermedad:"Bloqueo fascicular posterior izquierdo"
  },
  {
     id:"I446",
     enfermedad:"Otros tipos de bloqueo fascicular y los no especificados"
  },
  {
     id:"I447",
     enfermedad:"Bloqueo de rama izquierda del haz, sin otra especificacion"
  },
  {
     id:"I45",
     enfermedad:"Otros trastornos de la conduccion"
  },
  {
     id:"I450",
     enfermedad:"Bloqueo fascicular derecho"
  },
  {
     id:"I451",
     enfermedad:"Otros tipos de bloqueo de rama derecha del haz y los no especificados"
  },
  {
     id:"I452",
     enfermedad:"Bloqueo bifascicular"
  },
  {
     id:"I453",
     enfermedad:"Bloqueo trifascicular"
  },
  {
     id:"I454",
     enfermedad:"Bloqueo intraventricular no especificado"
  },
  {
     id:"I455",
     enfermedad:"Otros tipos especificados de bloqueo del corazon"
  },
  {
     id:"I456",
     enfermedad:"Sindrome de preexcitacion"
  },
  {
     id:"I458",
     enfermedad:"Otros trastornos especificados de la conduccion"
  },
  {
     id:"I459",
     enfermedad:"Trastorno de la conduccion, no especificado"
  },
  {
     id:"I46",
     enfermedad:"Paro cardiaco"
  },
  {
     id:"I460",
     enfermedad:"Paro cardiaco con resucitacion exitosa"
  },
  {
     id:"I461",
     enfermedad:"Muerte cardiaca subita, asi descrita"
  },
  {
     id:"I469",
     enfermedad:"Paro cardiaco, no especificado"
  },
  {
     id:"I47",
     enfermedad:"Taquicardia paroxistica"
  },
  {
     id:"I470",
     enfermedad:"Arritmia por reentrada ventricular"
  },
  {
     id:"I471",
     enfermedad:"Taquicardia supraventricular"
  },
  {
     id:"I472",
     enfermedad:"Taquicardia ventricular"
  },
  {
     id:"I479",
     enfermedad:"Taquicardia paroxistica, no especificada"
  },
  {
     id:"I48",
     enfermedad:"Fibrilacion y aleteo auricular"
  },
  {
     id:"I49",
     enfermedad:"Otras arritmias cardiacas"
  },
  {
     id:"I490",
     enfermedad:"Fibrilacion y aleteo ventricular"
  },
  {
     id:"I491",
     enfermedad:"Despolarizacion auricular prematura"
  },
  {
     id:"I492",
     enfermedad:"Despolarizacion prematura nodal"
  },
  {
     id:"I493",
     enfermedad:"Despolarizacion ventricular prematura"
  },
  {
     id:"I494",
     enfermedad:"Otros tipos de despolarizacion prematura y los no especificados"
  },
  {
     id:"I495",
     enfermedad:"Sindrome del seno enfermo"
  },
  {
     id:"I498",
     enfermedad:"Otras arritmias cardiacas especificadas"
  },
  {
     id:"I499",
     enfermedad:"Arritmia cardiaca, no especificada"
  },
  {
     id:"I50",
     enfermedad:"Insuficiencia cardiaca"
  },
  {
     id:"I500",
     enfermedad:"Insuficiencia cardiaca congestiva"
  },
  {
     id:"I501",
     enfermedad:"Insuficiencia ventricular izquierda"
  },
  {
     id:"I509",
     enfermedad:"Insuficiencia cardiaca, no especificada"
  },
  {
     id:"I51",
     enfermedad:"Complicaciones y descripciones mal definidas en enfermedades cardiacas"
  },
  {
     id:"I510",
     enfermedad:"Defecto del tabique cardiaco, adquirido"
  },
  {
     id:"I511",
     enfermedad:"Ruptura de cuerda tendinosa, no clasificada en otra parte"
  },
  {
     id:"I512",
     enfermedad:"Ruptura del musculo papilar, no clasificada en otra parte"
  },
  {
     id:"I513",
     enfermedad:"Trombosis intracardiaca, no clasificada en otra parte"
  },
  {
     id:"I514",
     enfermedad:"Miocarditis, no especificada"
  },
  {
     id:"I515",
     enfermedad:"Degeneracion miocardica"
  },
  {
     id:"I516",
     enfermedad:"Enfermedad cardiovascular, no especificada"
  },
  {
     id:"I517",
     enfermedad:"Cardiomegalia"
  },
  {
     id:"I518",
     enfermedad:"Otras enfermedades cardiacas mal definidas"
  },
  {
     id:"I519",
     enfermedad:"Enfermedad cardiaca, no especificada"
  },
  {
     id:"I52",
     enfermedad:"Otros trastornos cardiacos en enfermedades clasificadas en otra parte"
  },
  {
     id:"I520",
     enfermedad:"Otros trastornos cardiacos en enfermedades bacterianas clasificadas en otra parte"
  },
  {
     id:"I521",
     enfermedad:"Otros trastornos cardiacos en otras enfermedades infecciosas y parasitarias clasificadas en otra parte"
  },
  {
     id:"I528",
     enfermedad:"Otros trastornos cardiacos en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"I60",
     enfermedad:"Hemorragia subaracnoidea"
  },
  {
     id:"I600",
     enfermedad:"Hemorragia subaracnoidea de sifon y bifurcacion carotidea"
  },
  {
     id:"I601",
     enfermedad:"Hemorragia subaracnoidea de arteria cerebral media"
  },
  {
     id:"I602",
     enfermedad:"Hemorragia subaracnoidea de arteria comunicante anterior"
  },
  {
     id:"I603",
     enfermedad:"Hemorragia subaracnoidea de arteria comunicante posterior"
  },
  {
     id:"I604",
     enfermedad:"Hemorragia subaracnoidea de arteria basilar"
  },
  {
     id:"I605",
     enfermedad:"Hemorragia subaracnoidea de arteria vertebral"
  },
  {
     id:"I606",
     enfermedad:"Hemorragia subaracnoidea de otras arterias intracraneales"
  },
  {
     id:"I607",
     enfermedad:"Hemorragia subaracnoidea de arteria intracraneal no especificada"
  },
  {
     id:"I608",
     enfermedad:"Otras hemorragias subaracnoideas"
  },
  {
     id:"I609",
     enfermedad:"Hemorragia subaracnoidea, no especificada"
  },
  {
     id:"I61",
     enfermedad:"Hemorragia intraencefalica"
  },
  {
     id:"I610",
     enfermedad:"Hemorragia intracerebral en hemisferio, subcortical"
  },
  {
     id:"I611",
     enfermedad:"Hemorragia intracerebral en hemisferio, cortical"
  },
  {
     id:"I612",
     enfermedad:"Hemorragia intracerebral en hemisferio, no especificada"
  },
  {
     id:"I613",
     enfermedad:"Hemorragia intraencefalica en tallo cerebral"
  },
  {
     id:"I614",
     enfermedad:"Hemorragia intraencefalica en cerebelo"
  },
  {
     id:"I615",
     enfermedad:"Hemorragia intraencefalica, intraventricular"
  },
  {
     id:"I616",
     enfermedad:"Hemorragia intraencefalica de localizaciones multiples"
  },
  {
     id:"I618",
     enfermedad:"Otras hemorragias intraencefalicas"
  },
  {
     id:"I619",
     enfermedad:"Hemorragia intraencefalica, no especificada"
  },
  {
     id:"I62",
     enfermedad:"Otras hemorrag intracraneales no traumaticas"
  },
  {
     id:"I620",
     enfermedad:"Hemorragia subdural (aguda) (no traumatica)"
  },
  {
     id:"I621",
     enfermedad:"Hemorragia extradural no traumatica"
  },
  {
     id:"I629",
     enfermedad:"Hemorragia intracraneal (no traumatica), no especificada"
  },
  {
     id:"I63",
     enfermedad:"Infarto cerebral"
  },
  {
     id:"I630",
     enfermedad:"Infarto cerebral debido a trombosis de arterias precerebrales"
  },
  {
     id:"I631",
     enfermedad:"Infarto cerebral debido a embolia de arterias precerebrales"
  },
  {
     id:"I632",
     enfermedad:"Infarto cerebral debido a oclusion o estenosis no especificada de arterias precerebrales"
  },
  {
     id:"I633",
     enfermedad:"Infarto cerebral debido a trombosis de arterias cerebrales"
  },
  {
     id:"I634",
     enfermedad:"Infarto cerebral debido a embolia de arterias cerebrales"
  },
  {
     id:"I635",
     enfermedad:"Infarto cerebral debido a oclusion o estenosis no especificada de arterias cerebrales"
  },
  {
     id:"I636",
     enfermedad:"Infarto cerebral debido a trombosis de venas cerebrales, no piogeno"
  },
  {
     id:"I638",
     enfermedad:"Otros infartos cerebrales"
  },
  {
     id:"I639",
     enfermedad:"Infarto cerebral, no especificado"
  },
  {
     id:"I64",
     enfermedad:"Accidente vascular encefalico agudo no especificado como hemorragico o isquemico"
  },
  {
     id:"I65",
     enfermedad:"Oclusion y estenosis en arterias precerebrales sin ocasionar infarto cerebral"
  },
  {
     id:"I650",
     enfermedad:"Oclusion y estenosis de arteria vertebral"
  },
  {
     id:"I651",
     enfermedad:"Oclusion y estenosis de arteria basilar"
  },
  {
     id:"I652",
     enfermedad:"Oclusion y estenosis de arteria carotida"
  },
  {
     id:"I653",
     enfermedad:"Oclusion y estenosis multiple bilateral de arterias precerebrales"
  },
  {
     id:"I658",
     enfermedad:"Oclusion y estenosis de otras arterias precerebrales"
  },
  {
     id:"I659",
     enfermedad:"Oclusion y estenosis de arteria precerebral no especificada"
  },
  {
     id:"I66",
     enfermedad:"Oclusion y estenosis en arterias cerebrales sin ocasionar infarto cerebral"
  },
  {
     id:"I660",
     enfermedad:"Oclusion y estenosis de la arteria cerebral media"
  },
  {
     id:"I661",
     enfermedad:"Oclusion y estenosis de la arteria cerebral anterior"
  },
  {
     id:"I662",
     enfermedad:"Oclusion y estenosis de la arteria cerebral posterior"
  },
  {
     id:"I663",
     enfermedad:"Oclusion y estenosis de arterias cerebelosas"
  },
  {
     id:"I664",
     enfermedad:"Oclusion y estenosis multiple bilateral de arterias cerebrales"
  },
  {
     id:"I668",
     enfermedad:"Oclusion y estenosis de otras arterias cerebrales"
  },
  {
     id:"I669",
     enfermedad:"Oclusion y estenosis de arteria cerebral no especificada"
  },
  {
     id:"I67",
     enfermedad:"Otras enfermedades cerebrovasculares"
  },
  {
     id:"I670",
     enfermedad:"Diseccion de arterias cerebrales, sin ruptura"
  },
  {
     id:"I671",
     enfermedad:"Aneurisma cerebral, sin ruptura"
  },
  {
     id:"I672",
     enfermedad:"Aterosclerosis cerebral"
  },
  {
     id:"I673",
     enfermedad:"Leucoencefalopatia vascular progresiva"
  },
  {
     id:"I674",
     enfermedad:"Encefalopatia hipertensiva"
  },
  {
     id:"I675",
     enfermedad:"Enfermedad de moyamoya"
  },
  {
     id:"I676",
     enfermedad:"Trombosis apiogena del sistema venoso intracraneal"
  },
  {
     id:"I677",
     enfermedad:"Arteritis cerebral, no clasificada en otra parte"
  },
  {
     id:"I678",
     enfermedad:"Otras enfermedades cerebrovasculares especificadas"
  },
  {
     id:"I679",
     enfermedad:"Enfermedad cerebrovascular, no especificada"
  },
  {
     id:"I68",
     enfermedad:"Trastornos cerebrovasculares en enfermedades clasificadas en otra parte"
  },
  {
     id:"I680",
     enfermedad:"Angiopatia cerebral amiloide (e85."
  },
  {
     id:"I681",
     enfermedad:"Arteritis cerebral en enfermedades infecciosas y parasitarias clasificada en otra parte"
  },
  {
     id:"I682",
     enfermedad:"Arteritis cerebral en otras enfermedades clasificada en otra parte"
  },
  {
     id:"I688",
     enfermedad:"Otros trastornos cerebrovasculares en enfermedades clasificadas en otra parte"
  },
  {
     id:"I69",
     enfermedad:"Secuelas de enfermedades cerebrovascular"
  },
  {
     id:"I690",
     enfermedad:"Secuelas de hemorragia subaracnoidea"
  },
  {
     id:"I691",
     enfermedad:"Secuelas de hemorragia intraencefalica"
  },
  {
     id:"I692",
     enfermedad:"Secuelas de otras hemorragias intracraneales no traumaticas"
  },
  {
     id:"I693",
     enfermedad:"Secuelas de infarto cerebral"
  },
  {
     id:"I694",
     enfermedad:"Secuelas de accidente vascular encefalico, no especificado como hemorragico o isquemico"
  },
  {
     id:"I698",
     enfermedad:"Secuelas de otras enfermedades cerebrovasculares y de las no especificadas"
  },
  {
     id:"I70",
     enfermedad:"Aterosclerosis"
  },
  {
     id:"I700",
     enfermedad:"Aterosclerosis de la aorta"
  },
  {
     id:"I701",
     enfermedad:"Aterosclerosis de la arteria renal"
  },
  {
     id:"I702",
     enfermedad:"Aterosclerosis de las arterias de los miembros"
  },
  {
     id:"I708",
     enfermedad:"Aterosclerosis de otras arterias"
  },
  {
     id:"I709",
     enfermedad:"Aterosclerosis generalizada y la no especificada"
  },
  {
     id:"I71",
     enfermedad:"Aneurisma y diseccion aorticos"
  },
  {
     id:"I710",
     enfermedad:"Diseccion de aorta (cualquier parte)"
  },
  {
     id:"I711",
     enfermedad:"Ruptura de aneurisma de la aorta toracica"
  },
  {
     id:"I712",
     enfermedad:"Aneurisma de la aorta toracica, sin mencion de ruptura"
  },
  {
     id:"I713",
     enfermedad:"Ruptura de aneurisma de la aorta abdominal"
  },
  {
     id:"I714",
     enfermedad:"Aneurisma de la aorta abdominal, sin mencion de ruptura"
  },
  {
     id:"I715",
     enfermedad:"Ruptura de aneurisma de la aorta toracoabdominal"
  },
  {
     id:"I716",
     enfermedad:"Aneurisma de la aorta toracoabdominal, sin mencion de ruptura"
  },
  {
     id:"I718",
     enfermedad:"Ruptura de aneurisma aortico, sitio no especificado"
  },
  {
     id:"I719",
     enfermedad:"Aneurisma de la aorta, sitio no especificado, sin mencion de ruptura"
  },
  {
     id:"I72",
     enfermedad:"Otros aneurismas"
  },
  {
     id:"I720",
     enfermedad:"Aneurisma de la arteria carotida"
  },
  {
     id:"I721",
     enfermedad:"Aneurisma de la arteria del miembro superior"
  },
  {
     id:"I722",
     enfermedad:"Aneurisma de arteria renal"
  },
  {
     id:"I723",
     enfermedad:"Aneurisma de arteria iliaca"
  },
  {
     id:"I724",
     enfermedad:"Aneurisma de arteria del miembro inferior"
  },
  {
     id:"I728",
     enfermedad:"Aneurisma de otras arterias especificadas"
  },
  {
     id:"I729",
     enfermedad:"Aneurisma de sitio no especificado"
  },
  {
     id:"I73",
     enfermedad:"Otras enfermedades vasculares perifericas"
  },
  {
     id:"I730",
     enfermedad:"Sindrome de raynaud"
  },
  {
     id:"I731",
     enfermedad:"Tromboangeitis obliterante [buerger]"
  },
  {
     id:"I738",
     enfermedad:"Otras enfermedades vasculares perifericas especificadas"
  },
  {
     id:"I739",
     enfermedad:"Enfermedad vascular periferica, no especificada"
  },
  {
     id:"I74",
     enfermedad:"Embolia y trombosis arteriales"
  },
  {
     id:"I740",
     enfermedad:"Embolia y trombosis de la aorta abdominal"
  },
  {
     id:"I741",
     enfermedad:"Embolia y trombosis de otras porciones y las no especificadas de la aorta"
  },
  {
     id:"I742",
     enfermedad:"Embolia y trombosis de arterias de los miembros superiores"
  },
  {
     id:"I743",
     enfermedad:"Embolia y trombosis de arterias de los miembros inferiores"
  },
  {
     id:"I744",
     enfermedad:"Embolia y trombosis de arterias de los miembros, no especificadas"
  },
  {
     id:"I745",
     enfermedad:"Embolia y trombosis de arteria iliaca"
  },
  {
     id:"I748",
     enfermedad:"Embolia y trombosis de otras arterias"
  },
  {
     id:"I749",
     enfermedad:"Embolia y trombosis de arteria no especificada"
  },
  {
     id:"I77",
     enfermedad:"Otros trastornos arteriales o arteriolares"
  },
  {
     id:"I770",
     enfermedad:"Fistula arteriovenosa, adquirida"
  },
  {
     id:"I771",
     enfermedad:"Estrechez arterial"
  },
  {
     id:"I772",
     enfermedad:"Ruptura arterial"
  },
  {
     id:"I773",
     enfermedad:"Displasia fibromuscular arterial"
  },
  {
     id:"I774",
     enfermedad:"Sindrome de compresion del tronco celiaco"
  },
  {
     id:"I775",
     enfermedad:"Necrosis arterial"
  },
  {
     id:"I776",
     enfermedad:"Arteritis, no especificada"
  },
  {
     id:"I778",
     enfermedad:"Otros trastornos especificados de arterias y arteriolas"
  },
  {
     id:"I779",
     enfermedad:"Trastornos de arterias y arteriolas, no especificado"
  },
  {
     id:"I78",
     enfermedad:"Enfermedades de los vasos capilares"
  },
  {
     id:"I780",
     enfermedad:"Telangiectasia hemorragica hereditaria"
  },
  {
     id:"I781",
     enfermedad:"Nevo, no neoplasico"
  },
  {
     id:"I788",
     enfermedad:"Otras enfermedades de los capilares"
  },
  {
     id:"I789",
     enfermedad:"Enfermedad de los vasos capilares, no especificada"
  },
  {
     id:"I79",
     enfermedad:"Trastornos de las arterias, de las arteriolas y de los vasos capilares en enfermedades clasificadas en otra parte"
  },
  {
     id:"I790",
     enfermedad:"Aneurisma de la aorta en enfermedades clasificadas en otra parte"
  },
  {
     id:"I791",
     enfermedad:"Aortitis en enfermedades clasificadas en otra parte"
  },
  {
     id:"I792",
     enfermedad:"Angiopatia periferica en enfermedades clasificadas en otra parte"
  },
  {
     id:"I798",
     enfermedad:"Otros trastornos de arterias, arteriolas y vasos capilares en enfermedades clasificadas en otra parte"
  },
  {
     id:"I80",
     enfermedad:"Flebitis y tromboflebitis"
  },
  {
     id:"I800",
     enfermedad:"Flebitis y tromboflebitis de vasos superficiales de los miembros inferiores"
  },
  {
     id:"I801",
     enfermedad:"Flebitis y tromboflebitis de la vena femoral"
  },
  {
     id:"I802",
     enfermedad:"Flebitis y tromboflebitis de otros vasos profundos de los miembros inferiores"
  },
  {
     id:"I803",
     enfermedad:"Flebitis y tromboflebitis de los miembros inferiores, no especificada"
  },
  {
     id:"I808",
     enfermedad:"Flebitis y tromboflebitis de otros sitios"
  },
  {
     id:"I809",
     enfermedad:"Flebitis y tromboflebitis de sitio no especificado"
  },
  {
     id:"I81",
     enfermedad:"Trombosis de la vena porta"
  },
  {
     id:"I82",
     enfermedad:"Otras embolias y trombosis venosas"
  },
  {
     id:"I820",
     enfermedad:"Sindrome de budd"
  },
  {
     id:"I821",
     enfermedad:"Tromboflebitis migratoria"
  },
  {
     id:"I822",
     enfermedad:"Embolia y trombosis de vena cava"
  },
  {
     id:"I823",
     enfermedad:"Embolia y trombosis de vena renal"
  },
  {
     id:"I828",
     enfermedad:"Embolia y trombosis de otras venas especificadas"
  },
  {
     id:"I829",
     enfermedad:"Embolia y trombosis de vena no especificada"
  },
  {
     id:"I83",
     enfermedad:"Venas varicosas de los miembros inferiores"
  },
  {
     id:"I830",
     enfermedad:"Venas varicosas de los miembros inferiores con ulcera"
  },
  {
     id:"I831",
     enfermedad:"Venas varicosas de los miembros inferiores con inflamacion"
  },
  {
     id:"I832",
     enfermedad:"Venas varicosas de los miembros inferiores con ulcera e inflamacion"
  },
  {
     id:"I839",
     enfermedad:"Venas varicosas de los miembros inferiores sin ulcera ni inflamacion"
  },
  {
     id:"I84",
     enfermedad:"Hemorroides"
  },
  {
     id:"I840",
     enfermedad:"Hemorroides internas trombosadas"
  },
  {
     id:"I841",
     enfermedad:"Hemorroides internas con otras complicaciones"
  },
  {
     id:"I842",
     enfermedad:"Hemorroides internas sin complicacion"
  },
  {
     id:"I843",
     enfermedad:"Hemorroides externas trombosadas"
  },
  {
     id:"I844",
     enfermedad:"Hemorroides externas con otras complicaciones"
  },
  {
     id:"I845",
     enfermedad:"Hemorroides externas sin complicacion"
  },
  {
     id:"I846",
     enfermedad:"Prominencias cutaneas, residuo de hemorroides"
  },
  {
     id:"I847",
     enfermedad:"Hemorroides trombosadas no especificadas"
  },
  {
     id:"I848",
     enfermedad:"Hemorroides no especificadas, con otras complicaciones"
  },
  {
     id:"I849",
     enfermedad:"Hemorroides no especificadas, sin complicacion"
  },
  {
     id:"I85",
     enfermedad:"Varices esofagicas"
  },
  {
     id:"I850",
     enfermedad:"Varices esofagicas con hemorragia"
  },
  {
     id:"I859",
     enfermedad:"Varices esofagicas sin hemorragia"
  },
  {
     id:"I86",
     enfermedad:"Varices de otros sitios"
  },
  {
     id:"I860",
     enfermedad:"Varices sublinguales"
  },
  {
     id:"I861",
     enfermedad:"Varices escrotales"
  },
  {
     id:"I862",
     enfermedad:"Varices pelvicas"
  },
  {
     id:"I863",
     enfermedad:"Varices de la vulva"
  },
  {
     id:"I864",
     enfermedad:"Varices gastricas"
  },
  {
     id:"I868",
     enfermedad:"Varices en otros sitios especificados"
  },
  {
     id:"I87",
     enfermedad:"Otros trastornos de las venas"
  },
  {
     id:"I870",
     enfermedad:"Sindrome postflebitico"
  },
  {
     id:"I871",
     enfermedad:"Compresion de vena"
  },
  {
     id:"I872",
     enfermedad:"Insuficiencia venosa (cronica) (periferica)"
  },
  {
     id:"I878",
     enfermedad:"Otros trastornos venosos especificados"
  },
  {
     id:"I879",
     enfermedad:"Trastorno venoso, no especificado"
  },
  {
     id:"I88",
     enfermedad:"Linfadenitis inespecifica"
  },
  {
     id:"I880",
     enfermedad:"Linfadenitis mesenterica inespecifica"
  },
  {
     id:"I881",
     enfermedad:"Linfadenitis cronica, excepto la mesenterica"
  },
  {
     id:"I888",
     enfermedad:"Otras linfadenitis inespecificas"
  },
  {
     id:"I889",
     enfermedad:"Linfadenitis inespecifica no especificada"
  },
  {
     id:"I89",
     enfermedad:"Otros trastornos no infecciosos de los vasos y ganglios linfaticos"
  },
  {
     id:"I890",
     enfermedad:"Linfedema, no clasificado en otra parte"
  },
  {
     id:"I891",
     enfermedad:"Linfangitis"
  },
  {
     id:"I898",
     enfermedad:"Otros trastornos especificados no infecciosos de los vasos y ganglios linfaticos"
  },
  {
     id:"I899",
     enfermedad:"Trastorno no infeccioso de vasos y ganglios linfaticos, no especificado"
  },
  {
     id:"I95",
     enfermedad:"Hipotension"
  },
  {
     id:"I950",
     enfermedad:"Hipotension idiopatica"
  },
  {
     id:"I951",
     enfermedad:"Hipotension ortostatica"
  },
  {
     id:"I952",
     enfermedad:"Hipotension debida a drogas"
  },
  {
     id:"I958",
     enfermedad:"Otros tipos de hipotension"
  },
  {
     id:"I959",
     enfermedad:"Hipotension, no especificada"
  },
  {
     id:"I97",
     enfermedad:"Trastornos del sistema circulatorio consecutivos a procedimientos no clasificados en otra parte"
  },
  {
     id:"I970",
     enfermedad:"Sindrome de postcardiotomia"
  },
  {
     id:"I971",
     enfermedad:"Otras alteraciones funcionales consecutivas a cirugia cardiaca"
  },
  {
     id:"I972",
     enfermedad:"Sindrome de linfedema postmastectomia"
  },
  {
     id:"I978",
     enfermedad:"Otros trastornos del sistema circulatorio consecutivos a procedimientos, no clasificados en otra parte"
  },
  {
     id:"I979",
     enfermedad:"Trastorno no especificado del sistema circulatorio consecutivo a procedimientos"
  },
  {
     id:"I98",
     enfermedad:"Otros trastornos del sistema circulatorio en enfermedades clasificadas en otra parte"
  },
  {
     id:"I980",
     enfermedad:"Sifilis cardiovascular"
  },
  {
     id:"I981",
     enfermedad:"Trastornos cardiovasculares en otras enfermedades infecciosas y parasitarias clasificadas en otra parte"
  },
  {
     id:"I982",
     enfermedad:"Varices esofagicas en enfermedades clasificadas en otra parte"
  },
  {
     id:"I988",
     enfermedad:"Otros trastornos especificados del aparato circulatorio en enfermedades clasificadas en otra parte"
  },
  {
     id:"I99",
     enfermedad:"Otros trastornos y los no especificados del sistema circulatorio"
  },
  {
     id:"II",
     enfermedad:"Neoplasias"
  },
  {
     id:"III",
     enfermedad:"Enfermedades de la sangre y de los organos hematopoyeticos y otros trastornos que afectan el mecanismo de la inmunidad"
  },
  {
     id:"IV",
     enfermedad:"Enfermedades endocrinas, nutricionales y metabolicas"
  },
  {
     id:"IX",
     enfermedad:"Enfermedades del sistema circulatorio"
  },
  {
     id:"J00",
     enfermedad:"Rinofaringitis aguda"
  },
  {
     id:"J01",
     enfermedad:"Sinusitis aguda"
  },
  {
     id:"J010",
     enfermedad:"Sinusitis maxilar aguda"
  },
  {
     id:"J011",
     enfermedad:"Sinusitis frontal aguda"
  },
  {
     id:"J012",
     enfermedad:"Sinusitis etmoidal aguda"
  },
  {
     id:"J013",
     enfermedad:"Sinusitis esfenoidal aguda"
  },
  {
     id:"J014",
     enfermedad:"Pansinusitis aguda"
  },
  {
     id:"J018",
     enfermedad:"Otras sinusitis agudas"
  },
  {
     id:"J019",
     enfermedad:"Sinusitis aguda, no especificada"
  },
  {
     id:"J02",
     enfermedad:"Faringitis aguda"
  },
  {
     id:"J020",
     enfermedad:"Faringitis estreptococica"
  },
  {
     id:"J028",
     enfermedad:"Faringitis aguda debida a otros microorganismos especificados"
  },
  {
     id:"J029",
     enfermedad:"Faringitis aguda, no especificada"
  },
  {
     id:"J03",
     enfermedad:"Amigdalitis aguda"
  },
  {
     id:"J030",
     enfermedad:"Amigdalitis estreptococica"
  },
  {
     id:"J038",
     enfermedad:"Amigdalitis aguda debida a otros microorganismos especificados"
  },
  {
     id:"J039",
     enfermedad:"Amigdalitis aguda, no especificada"
  },
  {
     id:"J04",
     enfermedad:"Laringitis y traqueitis agudas"
  },
  {
     id:"J040",
     enfermedad:"Laringitis aguda"
  },
  {
     id:"J041",
     enfermedad:"Traqueitis aguda"
  },
  {
     id:"J042",
     enfermedad:"Laringotraqueitis aguda"
  },
  {
     id:"J05",
     enfermedad:"Laringitis obstructiva aguda [crup] y epiglotitis"
  },
  {
     id:"J050",
     enfermedad:"Laringitis obstructiva, aguda [crup]"
  },
  {
     id:"J051",
     enfermedad:"Epiglotitis aguda"
  },
  {
     id:"J06",
     enfermedad:"Infecciones agudas de las vias respiratorias superiores, de sitios multiples o no especificados"
  },
  {
     id:"J060",
     enfermedad:"Laringofaringitis aguda"
  },
  {
     id:"J068",
     enfermedad:"Otras infecciones agudas de sitios multiples de las vias respiratorias superiores"
  },
  {
     id:"J069",
     enfermedad:"Infeccion aguda de las vias respiratorias superiores, no especificada"
  },
  {
     id:"J10",
     enfermedad:"Influenza debida a virus de la influenza identificado"
  },
  {
     id:"J100",
     enfermedad:"Influenza con neumonia, debida a virus de la influenza identificado"
  },
  {
     id:"J101",
     enfermedad:"Influenza con otras manifestaciones respiratorias, debida a virus de la influenza identificado"
  },
  {
     id:"J108",
     enfermedad:"Influenza, con otras manifestaciones, debida a virus de la influenza identificado"
  },
  {
     id:"J11",
     enfermedad:"Influenza debida a virus no identificado"
  },
  {
     id:"J110",
     enfermedad:"Influenza con neumonia, virus no identificado"
  },
  {
     id:"J111",
     enfermedad:"Influenza con otras manifestaciones respiratorias, virus no identificado"
  },
  {
     id:"J118",
     enfermedad:"Influenza con otras manifestaciones, virus no identificado"
  },
  {
     id:"J12",
     enfermedad:"Neumonia viral no clasificada en otra parte"
  },
  {
     id:"J120",
     enfermedad:"Neumonia debida a adenovirus"
  },
  {
     id:"J121",
     enfermedad:"Neumonia debida a virus sincitial respiratorio"
  },
  {
     id:"J122",
     enfermedad:"Neumonia debida a virus parainfluenza"
  },
  {
     id:"J128",
     enfermedad:"Neumonia debida a otros virus"
  },
  {
     id:"J129",
     enfermedad:"Neumonia viral, no especificada"
  },
  {
     id:"J13",
     enfermedad:"Neumonia debida a streptococcus pneumoniae"
  },
  {
     id:"J14",
     enfermedad:"Neumonia debida a haemophilus influensae"
  },
  {
     id:"J15",
     enfermedad:"Neumonia bacteriana no clasificada en otra parte"
  },
  {
     id:"J150",
     enfermedad:"Neumonia debida a klebsiella pneumoniae"
  },
  {
     id:"J151",
     enfermedad:"Neumonia debida a pseudomonas"
  },
  {
     id:"J152",
     enfermedad:"Neumonia debida a estafilococos"
  },
  {
     id:"J153",
     enfermedad:"Neumonia debida a estreptococos del grupo b"
  },
  {
     id:"J154",
     enfermedad:"Neumonia debida a otros estreptococos"
  },
  {
     id:"J155",
     enfermedad:"Neumonia debida a escherichia coli"
  },
  {
     id:"J156",
     enfermedad:"Neumonia debida a otras bacterias aerobicas gramnegativas"
  },
  {
     id:"J157",
     enfermedad:"Neumonia debida a mycoplasma pneumoniae"
  },
  {
     id:"J158",
     enfermedad:"Otras neumonias bacterianas"
  },
  {
     id:"J159",
     enfermedad:"Neumonia bacteriana, no especificada"
  },
  {
     id:"J16",
     enfermedad:"Neumonia debida a otros microorganismos infecciosos, no clasificados en otra parte"
  },
  {
     id:"J160",
     enfermedad:"Neumonia debida a clamidias"
  },
  {
     id:"J168",
     enfermedad:"Neumonia debida a otros microorganismos infecciosos especificados"
  },
  {
     id:"J17",
     enfermedad:"Neumonia en enfermedades clasificadas en otra parte"
  },
  {
     id:"J170",
     enfermedad:"Neumonia en enfermedades bacterianas clasificadas en otra parte"
  },
  {
     id:"J171",
     enfermedad:"Neumonia en enfermedades virales clasificadas en otra parte"
  },
  {
     id:"J172",
     enfermedad:"Neumonia en micosis"
  },
  {
     id:"J173",
     enfermedad:"Neumonia en enfermedades parasitarias"
  },
  {
     id:"J178",
     enfermedad:"Neumonia en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"J18",
     enfermedad:"Neumonia organismo no especificado"
  },
  {
     id:"J180",
     enfermedad:"Bronconeumonia, no especificada"
  },
  {
     id:"J181",
     enfermedad:"Neumonia lobar, no especificada"
  },
  {
     id:"J182",
     enfermedad:"Neumonia hipostatica, no especificada"
  },
  {
     id:"J188",
     enfermedad:"Otras neumonias, de microorganismo no especificado"
  },
  {
     id:"J189",
     enfermedad:"Neumonia, no especificada"
  },
  {
     id:"J20",
     enfermedad:"Bronquitis aguda"
  },
  {
     id:"J200",
     enfermedad:"Bronquitis aguda debida a mycoplasma pneumoniae"
  },
  {
     id:"J201",
     enfermedad:"Bronquitis aguda debida a haemophilus influenzae"
  },
  {
     id:"J202",
     enfermedad:"Bronquitis aguda debida a estreptococos"
  },
  {
     id:"J203",
     enfermedad:"Bronquitis aguda debida a virus coxsackie"
  },
  {
     id:"J204",
     enfermedad:"Bronquitis aguda debida a virus parainfluenza"
  },
  {
     id:"J205",
     enfermedad:"Bronquitis aguda debida a virus sincitial respiratorio"
  },
  {
     id:"J206",
     enfermedad:"Bronquitis aguda debida a rinovirus"
  },
  {
     id:"J207",
     enfermedad:"Bronquitis aguda debida a virus echo"
  },
  {
     id:"J208",
     enfermedad:"Bronquitis aguda debida a otros microorganismos especificados"
  },
  {
     id:"J209",
     enfermedad:"Bronquitis aguda, no especificada"
  },
  {
     id:"J21",
     enfermedad:"Bronquiolitis aguda"
  },
  {
     id:"J210",
     enfermedad:"Bronquiolitis aguda debida a virus sincitial respiratorio"
  },
  {
     id:"J218",
     enfermedad:"Bronquiolitis aguda debida a otros microorganismos especificados"
  },
  {
     id:"J219",
     enfermedad:"Bronquiolitis aguda, no especificada"
  },
  {
     id:"J22",
     enfermedad:"Infec agud no especificada de las vias respiratorias inferiores"
  },
  {
     id:"J30",
     enfermedad:"Rinitis alergica y vasomotora"
  },
  {
     id:"J300",
     enfermedad:"Rinitis vasomotora"
  },
  {
     id:"J301",
     enfermedad:"Rinitis alergica debida al polen"
  },
  {
     id:"J302",
     enfermedad:"Otra rinitis alergica estacional"
  },
  {
     id:"J303",
     enfermedad:"Otras rinitis alergicas"
  },
  {
     id:"J304",
     enfermedad:"Rinitis alergica, no especificada"
  },
  {
     id:"J31",
     enfermedad:"Rinitis, rinofaringitis y faringitis cronica"
  },
  {
     id:"J310",
     enfermedad:"Rinitis cronica"
  },
  {
     id:"J311",
     enfermedad:"Rinofaringitis cronica"
  },
  {
     id:"J312",
     enfermedad:"Faringitis cronica"
  },
  {
     id:"J32",
     enfermedad:"Sinusitis cronica"
  },
  {
     id:"J320",
     enfermedad:"Sinusitis maxilar cronica"
  },
  {
     id:"J321",
     enfermedad:"Sinusitis frontal cronica"
  },
  {
     id:"J322",
     enfermedad:"Sinusitis etmoidal cronica"
  },
  {
     id:"J323",
     enfermedad:"Sinusitis esfenoidal cronica"
  },
  {
     id:"J324",
     enfermedad:"Pansinusitis cronica"
  },
  {
     id:"J328",
     enfermedad:"Otras sinusitis cronicas"
  },
  {
     id:"J329",
     enfermedad:"Sinusitis cronica, no especificada"
  },
  {
     id:"J33",
     enfermedad:"Polipo nasal"
  },
  {
     id:"J330",
     enfermedad:"Polipo de la cavidad nasal"
  },
  {
     id:"J331",
     enfermedad:"Degeneracion polipoide de seno paranasal"
  },
  {
     id:"J338",
     enfermedad:"Otros polipos de los senos paranasales"
  },
  {
     id:"J339",
     enfermedad:"Polipo nasal, no especificado"
  },
  {
     id:"J34",
     enfermedad:"Otros trastornos de la nariz y de los senos paranasales"
  },
  {
     id:"J340",
     enfermedad:"Absceso, furunculo y antrax de la nariz"
  },
  {
     id:"J341",
     enfermedad:"Quiste y mucocele de la nariz y del seno paranasal"
  },
  {
     id:"J342",
     enfermedad:"Desviacion del tabique nasal"
  },
  {
     id:"J343",
     enfermedad:"Hipertrofia de los cornetes nasales"
  },
  {
     id:"J348",
     enfermedad:"Otros trastornos especificados de la nariz y de los senos paranasales"
  },
  {
     id:"J35",
     enfermedad:"Enfermedades cronicas de las amigdalas y de las adenoides"
  },
  {
     id:"J350",
     enfermedad:"Amigdalitis cronica"
  },
  {
     id:"J351",
     enfermedad:"Hipertrofia de las amigdalas"
  },
  {
     id:"J352",
     enfermedad:"Hipertrofia de las adenoides"
  },
  {
     id:"J353",
     enfermedad:"Hipertrofia de las amigdalas con hipertrofia de las adenoides"
  },
  {
     id:"J358",
     enfermedad:"Otras enfermedades cronicas de las amigdalas y de las adenoides"
  },
  {
     id:"J359",
     enfermedad:"Enfermedad cronicas de las amigdalas y de las adenoides, no especificada"
  },
  {
     id:"J36",
     enfermedad:"Absceso periamigdalino"
  },
  {
     id:"J37",
     enfermedad:"Laringitis y laringotraqueitis cronicas"
  },
  {
     id:"J370",
     enfermedad:"Laringitis cronica"
  },
  {
     id:"J371",
     enfermedad:"Laringotraqueitis cronica"
  },
  {
     id:"J38",
     enfermedad:"Enfermedades de las cuerdas vocales y de la laringe no clasificadas en otra parte"
  },
  {
     id:"J380",
     enfermedad:"Paralisis de las cuerdas vocales y de la laringe"
  },
  {
     id:"J381",
     enfermedad:"Polipo de las cuerdas vocales y de la laringe"
  },
  {
     id:"J382",
     enfermedad:"Nodulos de las cuerdas vocales"
  },
  {
     id:"J383",
     enfermedad:"Otras enfermedades de las cuerdas vocales"
  },
  {
     id:"J384",
     enfermedad:"Edema de laringe"
  },
  {
     id:"J385",
     enfermedad:"Espasmo laringeo"
  },
  {
     id:"J386",
     enfermedad:"Estenosis laringea"
  },
  {
     id:"J387",
     enfermedad:"Otras enfermedades de la laringe"
  },
  {
     id:"J39",
     enfermedad:"Otras enfermedades de las vias respiratorias superiores"
  },
  {
     id:"J390",
     enfermedad:"Absceso retrofaringeo y parafaringeo"
  },
  {
     id:"J391",
     enfermedad:"Otros abscesos de la faringe"
  },
  {
     id:"J392",
     enfermedad:"Otras enfermedades de la faringe"
  },
  {
     id:"J393",
     enfermedad:"Reaccion de hipersensibilidad de las vias respiratorias superiores, sitio no especificado"
  },
  {
     id:"J398",
     enfermedad:"Otras enfermedades especificadas de las vias respiratorias superiores"
  },
  {
     id:"J399",
     enfermedad:"Enfermedad de las vias respiratorias superiores, no especificada"
  },
  {
     id:"J40",
     enfermedad:"Bronquitis no especificada como aguda o cronica"
  },
  {
     id:"J41",
     enfermedad:"Bronquitis cronica simple y mucopurulenta"
  },
  {
     id:"J410",
     enfermedad:"Bronquitis cronica simple"
  },
  {
     id:"J411",
     enfermedad:"Bronquitis cronica mucopurulenta"
  },
  {
     id:"J418",
     enfermedad:"Bronquitis cronica mixta simple y mucopurulenta"
  },
  {
     id:"J42",
     enfermedad:"Bronquitis cronica no especificada"
  },
  {
     id:"J43",
     enfermedad:"Enfisema"
  },
  {
     id:"J430",
     enfermedad:"Sindrome de macleod"
  },
  {
     id:"J431",
     enfermedad:"Enfisema panlobular"
  },
  {
     id:"J432",
     enfermedad:"Enfisema centrolobular"
  },
  {
     id:"J438",
     enfermedad:"Otros tipos de enfisema"
  },
  {
     id:"J439",
     enfermedad:"Enfisema, no especificado"
  },
  {
     id:"J44",
     enfermedad:"Otras enfermedades pulmonares obstructivas cronicas"
  },
  {
     id:"J440",
     enfermedad:"Enfermedad pulmonar obstructiva cronica con infeccion aguda de las vias respiratorias inferiores"
  },
  {
     id:"J441",
     enfermedad:"Enfermedad pulmonar obstructiva cronica con exacerbacion aguda, no especificada"
  },
  {
     id:"J448",
     enfermedad:"Otras enfermedades pulmonares obstructivas cronicas especificadas"
  },
  {
     id:"J449",
     enfermedad:"Enfermedad pulmonar obstructiva cronica, no especificada"
  },
  {
     id:"J45",
     enfermedad:"Asma"
  },
  {
     id:"J450",
     enfermedad:"Asma predominantemente alergica"
  },
  {
     id:"J451",
     enfermedad:"Asma no alergica"
  },
  {
     id:"J458",
     enfermedad:"Asma mixta"
  },
  {
     id:"J459",
     enfermedad:"Asma, no especificada"
  },
  {
     id:"J46",
     enfermedad:"Estado asmatico"
  },
  {
     id:"J47",
     enfermedad:"Bronquiectasia"
  },
  {
     id:"J60",
     enfermedad:"Neumoconiosis de los mineros de del carbon"
  },
  {
     id:"J61",
     enfermedad:"Neumoconiosis debida al asbesto y a otras fibras minerales"
  },
  {
     id:"J62",
     enfermedad:"Neumoconiosis debidas a polvo de silice"
  },
  {
     id:"J620",
     enfermedad:"Neumoconiosis debida a polvo de talco"
  },
  {
     id:"J628",
     enfermedad:"Neumoconiosis debida a otros polvos que contienen silice"
  },
  {
     id:"J63",
     enfermedad:"Neumoconiosis debida a otros polvos inorganicos"
  },
  {
     id:"J630",
     enfermedad:"Aluminosis (del pulmon)"
  },
  {
     id:"J631",
     enfermedad:"Fibrosis (del pulmon) debida a bauxita"
  },
  {
     id:"J632",
     enfermedad:"Beriliosis"
  },
  {
     id:"J633",
     enfermedad:"Fibrosis (del pulmon) debida a grafito"
  },
  {
     id:"J634",
     enfermedad:"Siderosis"
  },
  {
     id:"J635",
     enfermedad:"Estañosis"
  },
  {
     id:"J638",
     enfermedad:"Neumoconiosis debida a otros polvos inorganicos especificados"
  },
  {
     id:"J64",
     enfermedad:"Neumoconiosis no especificada"
  },
  {
     id:"J65",
     enfermedad:"Neumoconiosis asociada con tuberculosis"
  },
  {
     id:"J66",
     enfermedad:"Enfermedades de las vias aereas debidas a polvos organicos especificos"
  },
  {
     id:"J660",
     enfermedad:"Bisinosis"
  },
  {
     id:"J661",
     enfermedad:"Enfermedad de los trabajadores del lino"
  },
  {
     id:"J662",
     enfermedad:"Canabinosis"
  },
  {
     id:"J668",
     enfermedad:"Enfermedad de las vias aereas debidas a otros polvos organicos especificos"
  },
  {
     id:"J67",
     enfermedad:"Neumonitis debida a hipersensibilidad al polvo organico"
  },
  {
     id:"J670",
     enfermedad:"Pulmon del granjero"
  },
  {
     id:"J671",
     enfermedad:"Bagazosis"
  },
  {
     id:"J672",
     enfermedad:"Pulmon del ornitofilo"
  },
  {
     id:"J673",
     enfermedad:"Suberosis"
  },
  {
     id:"J674",
     enfermedad:"Pulmon del manipulador de malta"
  },
  {
     id:"J675",
     enfermedad:"Pulmon del manipulador de hongos"
  },
  {
     id:"J676",
     enfermedad:"Pulmon del descortezador del arce"
  },
  {
     id:"J677",
     enfermedad:"Neumonitis de la ventilacion debida al acondicionador y humidificador del aire"
  },
  {
     id:"J678",
     enfermedad:"Neumonitis debida a hipersensibilidad a otros polvos organicos"
  },
  {
     id:"J679",
     enfermedad:"Neumonitis debida a hipersensibilidad a polvo organico no especificado"
  },
  {
     id:"J68",
     enfermedad:"Afecciones respiratorias debidas a inhalacion de gases, humos, vapores y sustancias quimicas"
  },
  {
     id:"J680",
     enfermedad:"Bronquitis y neumonitis debidas a inhalacion de gases, humos, vapores y sustancias quimicas"
  },
  {
     id:"J681",
     enfermedad:"Edema pulmonar agudo debido a inhalacion de gases, humos, vapores y sustancias quimicas"
  },
  {
     id:"J682",
     enfermedad:"Inflamacion respiratoria superior debida a inhalacion de gases, humos, vapores y sustancias quimicas, no clasificadas en otra parte"
  },
  {
     id:"J683",
     enfermedad:"Otras afecciones respiratorias agudas y subagudas debidas a inhalacion de gases, humos, vapores y sustancias quimicas"
  },
  {
     id:"J684",
     enfermedad:"Afecciones respiratorias cronicas debidas a inhalacion de gases, humos, vapores y sustancias quimicas"
  },
  {
     id:"J688",
     enfermedad:"Otras afecciones respiratorias debidas a inhalacion de gases, humos, vapores y sustancias quimicas"
  },
  {
     id:"J689",
     enfermedad:"Afeccion respiratoria no especificada, debida a inhalacion de gases, humos, vapores y sustancias quimicas"
  },
  {
     id:"J69",
     enfermedad:"Neumonitis debida a solidos y liquidos"
  },
  {
     id:"J690",
     enfermedad:"Neumonitis debida a aspiracion de alimento o vomito"
  },
  {
     id:"J691",
     enfermedad:"Neumonitis debida a aspiracion de aceites y esencias"
  },
  {
     id:"J698",
     enfermedad:"Neumonitis debida a aspiracion de otros solidos y liquidos"
  },
  {
     id:"J70",
     enfermedad:"Afecciones respiratorias debidas a otros agentes externos"
  },
  {
     id:"J700",
     enfermedad:"Manifestaciones pulmonares agudas debidas a radiacion"
  },
  {
     id:"J701",
     enfermedad:"Manifestaciones pulmonares cronicas y otras manifestaciones debidas a radiacion"
  },
  {
     id:"J702",
     enfermedad:"Trastornos pulmonares intersticiales agudos inducidos por drogas"
  },
  {
     id:"J703",
     enfermedad:"Trastornos pulmonares intersticiales cronicos inducidos por drogas"
  },
  {
     id:"J704",
     enfermedad:"Trastornos pulmonares intersticiales no especificados inducidos por drogas"
  },
  {
     id:"J708",
     enfermedad:"Afecciones respiratorias debidas a otros agentes externos especificados"
  },
  {
     id:"J709",
     enfermedad:"Afecciones respiratorias debidas a agentes externos no especificados"
  },
  {
     id:"J80",
     enfermedad:"Sindrome de dificultad respiratoria del adulto"
  },
  {
     id:"J81",
     enfermedad:"Edema pulmonar"
  },
  {
     id:"J82",
     enfermedad:"Eosinofilia pulmonar no clasificada en otra parte"
  },
  {
     id:"J84",
     enfermedad:"Otras enfermedades pulmonares intersticiales"
  },
  {
     id:"J840",
     enfermedad:"Afecciones alveolares y alveoloparietales"
  },
  {
     id:"J841",
     enfermedad:"Otras enfermedades pulmonares intersticiales con fibrosis"
  },
  {
     id:"J848",
     enfermedad:"Otras enfermedades pulmonares intersticiales especificadas"
  },
  {
     id:"J849",
     enfermedad:"Enfermedad pulmonar intersticial, no especificada"
  },
  {
     id:"J85",
     enfermedad:"Absceso del pulmon y del mediastino"
  },
  {
     id:"J850",
     enfermedad:"Gangrena y necrosis del pulmon"
  },
  {
     id:"J851",
     enfermedad:"Absceso del pulmon con neumonia"
  },
  {
     id:"J852",
     enfermedad:"Absceso del pulmon sin neumonia"
  },
  {
     id:"J853",
     enfermedad:"Absceso del mediastino"
  },
  {
     id:"J86",
     enfermedad:"Piotorax"
  },
  {
     id:"J860",
     enfermedad:"Piotorax con fistula"
  },
  {
     id:"J869",
     enfermedad:"Piotorax sin fistula"
  },
  {
     id:"J90",
     enfermedad:"Derrame pleural no clasificado en otra parte"
  },
  {
     id:"J91",
     enfermedad:"Derrame pleural en afecciones clasificadas en otra parte"
  },
  {
     id:"J92",
     enfermedad:"Paquipleuritis"
  },
  {
     id:"J920",
     enfermedad:"Paquipleuritis con asbestosis"
  },
  {
     id:"J929",
     enfermedad:"Paquipleuritis sin asbestosis"
  },
  {
     id:"J93",
     enfermedad:"Neumotorax"
  },
  {
     id:"J930",
     enfermedad:"Neumotorax espontaneo a presion"
  },
  {
     id:"J931",
     enfermedad:"Otros tipos de neumotorax espontaneo"
  },
  {
     id:"J938",
     enfermedad:"Otros neumotorax"
  },
  {
     id:"J939",
     enfermedad:"Neumotorax, no especificado"
  },
  {
     id:"J94",
     enfermedad:"Otras afecciones de la pleura"
  },
  {
     id:"J940",
     enfermedad:"Quilotorax"
  },
  {
     id:"J941",
     enfermedad:"Fibrotorax"
  },
  {
     id:"J942",
     enfermedad:"Hemotorax"
  },
  {
     id:"J948",
     enfermedad:"Otras afecciones especificadas de la pleura"
  },
  {
     id:"J949",
     enfermedad:"Afeccion pleural, no especificada"
  },
  {
     id:"J95",
     enfermedad:"Trastornos del sistema respiratorio, consecutivos a procedimientos no clasificados en otra parte"
  },
  {
     id:"J950",
     enfermedad:"Funcionamiento defectuoso de la traqueostomia"
  },
  {
     id:"J951",
     enfermedad:"Insuficiencia pulmonar aguda consecutiva a cirugia toracica"
  },
  {
     id:"J952",
     enfermedad:"Insuficiencia pulmonar aguda consecutiva a cirugia extratoracica"
  },
  {
     id:"J953",
     enfermedad:"Insuficiencia pulmonar cronica consecutiva a cirugia"
  },
  {
     id:"J954",
     enfermedad:"Sindrome de mendelson"
  },
  {
     id:"J955",
     enfermedad:"Estenosis subglotica consecutiva a procedimientos"
  },
  {
     id:"J958",
     enfermedad:"Otros trastornos respiratorios consecutivos a procedimientos"
  },
  {
     id:"J959",
     enfermedad:"Trastorno no especificado del sistema respiratorio, consecutivos a procedimientos"
  },
  {
     id:"J96",
     enfermedad:"Insuficiencia respiratoria no clasificadas en otra parte"
  },
  {
     id:"J960",
     enfermedad:"Insuficiencia respiratoria aguda"
  },
  {
     id:"J961",
     enfermedad:"Insuficiencia respiratoria cronica"
  },
  {
     id:"J969",
     enfermedad:"Insuficiencia respiratoria, no especificada"
  },
  {
     id:"J98",
     enfermedad:"Otros trastornos respiratorios"
  },
  {
     id:"J980",
     enfermedad:"Enfermedades de la traquea y de los bronquios, no clasificadas en otra parte"
  },
  {
     id:"J981",
     enfermedad:"Colapso pulmonar"
  },
  {
     id:"J982",
     enfermedad:"Enfisema intersticial"
  },
  {
     id:"J983",
     enfermedad:"Enfisema compensatorio"
  },
  {
     id:"J984",
     enfermedad:"Otros trastornos del pulmon"
  },
  {
     id:"J985",
     enfermedad:"Enfermedades del mediastino, no clasificados en otra parte"
  },
  {
     id:"J986",
     enfermedad:"Trastornos del diafragma"
  },
  {
     id:"J988",
     enfermedad:"Otros trastornos respiratorios especificados"
  },
  {
     id:"J989",
     enfermedad:"Trastorno respiratorio, no especificado"
  },
  {
     id:"J99",
     enfermedad:"Trastornos respiratorios en enfermedades clasificadas en otra parte"
  },
  {
     id:"J990",
     enfermedad:"Enfermedad pulmonar reumatoide (m05.1)"
  },
  {
     id:"J991",
     enfermedad:"Trastornos respiratorios en otros trastornos difusos del tejido conjuntivo"
  },
  {
     id:"J998",
     enfermedad:"Trastornos respiratorios en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"K00",
     enfermedad:"Trastornos del desarrollo y de la erupcion de los dientes"
  },
  {
     id:"K000",
     enfermedad:"Anodoncia"
  },
  {
     id:"K001",
     enfermedad:"Dientes supernumerarios"
  },
  {
     id:"K002",
     enfermedad:"Anomalias del tamaño y de la forma del diente"
  },
  {
     id:"K003",
     enfermedad:"Dientes moteados"
  },
  {
     id:"K004",
     enfermedad:"Alteraciones en la formacion dentaria"
  },
  {
     id:"K005",
     enfermedad:"Alteraciones hereditarias de la estructura dentaria, no clasificadas en otra parte"
  },
  {
     id:"K006",
     enfermedad:"Alteraciones en la erupcion dentaria"
  },
  {
     id:"K007",
     enfermedad:"Sindrome de la erupcion dentaria"
  },
  {
     id:"K008",
     enfermedad:"Otros trastornos del desarrollo de los dientes"
  },
  {
     id:"K009",
     enfermedad:"Trastorno del desarrollo de los dientes, no especificado"
  },
  {
     id:"K01",
     enfermedad:"Dientes incluidos e impactados"
  },
  {
     id:"K010",
     enfermedad:"Dientes incluidos"
  },
  {
     id:"K011",
     enfermedad:"Dientes impactados"
  },
  {
     id:"K02",
     enfermedad:"Caries dental"
  },
  {
     id:"K020",
     enfermedad:"Caries limitada al esmalte"
  },
  {
     id:"K021",
     enfermedad:"Caries de la dentina"
  },
  {
     id:"K022",
     enfermedad:"Caries del cemento"
  },
  {
     id:"K023",
     enfermedad:"Caries dentaria detenida"
  },
  {
     id:"K024",
     enfermedad:"Odontoclasia"
  },
  {
     id:"K028",
     enfermedad:"Otras caries dentales"
  },
  {
     id:"K029",
     enfermedad:"Caries dental, no especificada"
  },
  {
     id:"K03",
     enfermedad:"Otras enfermedades de los tejidos duros de los dientes"
  },
  {
     id:"K030",
     enfermedad:"Atricion excesiva de los dientes"
  },
  {
     id:"K031",
     enfermedad:"Abrasion de los dientes"
  },
  {
     id:"K032",
     enfermedad:"Erosion de los dientes"
  },
  {
     id:"K033",
     enfermedad:"Reabsorcion patologica de los dientes"
  },
  {
     id:"K034",
     enfermedad:"Hipercementosis"
  },
  {
     id:"K035",
     enfermedad:"Anquilosis dental"
  },
  {
     id:"K036",
     enfermedad:"Depositos [acreciones] en los dientes"
  },
  {
     id:"K037",
     enfermedad:"Cambios posteruptivos del color de los tejidos dentales duros"
  },
  {
     id:"K038",
     enfermedad:"Otras enfermedades especificadas de los tejidos duros de los dientes"
  },
  {
     id:"K039",
     enfermedad:"Enfermedad no especificada de los tejidos dentales duros"
  },
  {
     id:"K04",
     enfermedad:"Enfermedades de la pulpa y de los tejidos periapicales"
  },
  {
     id:"K040",
     enfermedad:"Pulpitis"
  },
  {
     id:"K041",
     enfermedad:"Necrosis de la pulpa"
  },
  {
     id:"K042",
     enfermedad:"Degeneracion de la pulpa"
  },
  {
     id:"K043",
     enfermedad:"Formacion anormal de tejido duro en la pulpa"
  },
  {
     id:"K044",
     enfermedad:"Periodontitis apical aguda originada en la pulpa"
  },
  {
     id:"K045",
     enfermedad:"Periodontitis apical cronica"
  },
  {
     id:"K046",
     enfermedad:"Absceso periapical con fistula"
  },
  {
     id:"K047",
     enfermedad:"Absceso periapical sin fistula"
  },
  {
     id:"K048",
     enfermedad:"Quiste radicular"
  },
  {
     id:"K049",
     enfermedad:"Otras enfermedades y las no especificadas de la pulpa y del tejido periapical"
  },
  {
     id:"K05",
     enfermedad:"Gingivitis y enfermedades periodontales"
  },
  {
     id:"K050",
     enfermedad:"Gingivitis aguda"
  },
  {
     id:"K051",
     enfermedad:"Gingivitis cronica"
  },
  {
     id:"K052",
     enfermedad:"Periodontitis aguda"
  },
  {
     id:"K053",
     enfermedad:"Periodontitis cronica"
  },
  {
     id:"K054",
     enfermedad:"Periodontosis"
  },
  {
     id:"K055",
     enfermedad:"Otras enfermedades periodontales"
  },
  {
     id:"K056",
     enfermedad:"Enfermedad de periodonto, no especificada"
  },
  {
     id:"K06",
     enfermedad:"Otros trastornos de la encia y de la zona edentula"
  },
  {
     id:"K060",
     enfermedad:"Retraccion gingival"
  },
  {
     id:"K061",
     enfermedad:"Hiperplasia gingival"
  },
  {
     id:"K062",
     enfermedad:"Lesiones de la encia y de la zona edentula asociadas con traumatismo"
  },
  {
     id:"K068",
     enfermedad:"Otros trastornos especificados de la encia y de la zona edentula"
  },
  {
     id:"K069",
     enfermedad:"Trastorno no especificado de la encia y de la zona edentula"
  },
  {
     id:"K07",
     enfermedad:"Anomalias dentofaciales [inclusola maloclusion]"
  },
  {
     id:"K070",
     enfermedad:"Anomalias evidentes del tamaño de los maxilares"
  },
  {
     id:"K071",
     enfermedad:"Anomalias de la relacion maxilobasilar"
  },
  {
     id:"K072",
     enfermedad:"Anomalias de la relacion entre los arcos dentarios"
  },
  {
     id:"K073",
     enfermedad:"Anomalias de la posicion del diente"
  },
  {
     id:"K074",
     enfermedad:"Maloclusion de tipo no especificado"
  },
  {
     id:"K075",
     enfermedad:"Anomalias dentofaciales funcionales"
  },
  {
     id:"K076",
     enfermedad:"Trastornos de la articulacion temporomaxilar"
  },
  {
     id:"K078",
     enfermedad:"Otras anomalias dentofaciales"
  },
  {
     id:"K079",
     enfermedad:"Anomalia dentofacial, no especificada"
  },
  {
     id:"K08",
     enfermedad:"Otros trastornos de los dientes y de sus estructuras de sosten"
  },
  {
     id:"K080",
     enfermedad:"Exfoliacion de los dientes debida a causas sistemicas"
  },
  {
     id:"K081",
     enfermedad:"Perdida de dientes debida a accidente, extraccion o enfermedad periodontal local"
  },
  {
     id:"K082",
     enfermedad:"Atrofia de reborde alveolar desdentado"
  },
  {
     id:"K083",
     enfermedad:"Raiz dental retenida"
  },
  {
     id:"K088",
     enfermedad:"Otras afecciones especificadas de los dientes y de sus estructuras de sosten"
  },
  {
     id:"K089",
     enfermedad:"Trastorno de los dientes y de sus estructuras de sosten, no especificado"
  },
  {
     id:"K09",
     enfermedad:"Quistes de la region bucal no clasificadas en otra parte"
  },
  {
     id:"K090",
     enfermedad:"Quistes originados por el desarrollo de los dientes"
  },
  {
     id:"K091",
     enfermedad:"Quistes de las fisuras (no odontogenicos)"
  },
  {
     id:"K092",
     enfermedad:"Otros quistes de los maxilares"
  },
  {
     id:"K098",
     enfermedad:"Otros quistes de la region bucal, no clasificados en otra parte"
  },
  {
     id:"K099",
     enfermedad:"Quiste de la region bucal, sin otra especificacion"
  },
  {
     id:"K10",
     enfermedad:"Otras enfermedades de los maxilares"
  },
  {
     id:"K100",
     enfermedad:"Trastornos del desarrollo de los maxilares"
  },
  {
     id:"K101",
     enfermedad:"Granuloma central de celulas gigantes"
  },
  {
     id:"K102",
     enfermedad:"Afecciones inflamatorias de los maxilares"
  },
  {
     id:"K103",
     enfermedad:"Alveolitis del maxilar"
  },
  {
     id:"K108",
     enfermedad:"Otras enfermedades especificadas de los maxilares"
  },
  {
     id:"K109",
     enfermedad:"Enfermedad de los maxilares, no especificada"
  },
  {
     id:"K11",
     enfermedad:"Enfermedades de las glandulas salivares"
  },
  {
     id:"K110",
     enfermedad:"Atrofia de glandula salival"
  },
  {
     id:"K111",
     enfermedad:"Hipertrofia de glandula salival"
  },
  {
     id:"K112",
     enfermedad:"Sialadenitis"
  },
  {
     id:"K113",
     enfermedad:"Absceso de glandula salival"
  },
  {
     id:"K114",
     enfermedad:"Fistula de glandula salival"
  },
  {
     id:"K115",
     enfermedad:"Sialolitiasis"
  },
  {
     id:"K116",
     enfermedad:"Mucocele de glandula salival"
  },
  {
     id:"K117",
     enfermedad:"Alteraciones de la secrecion salival"
  },
  {
     id:"K118",
     enfermedad:"Otras enfermedades de las glandulas salivales"
  },
  {
     id:"K119",
     enfermedad:"Enfermedad de glandula salival. no especificada"
  },
  {
     id:"K12",
     enfermedad:"Estomatitis y lesiones afines"
  },
  {
     id:"K120",
     enfermedad:"Estomatitis aftosa recurrente"
  },
  {
     id:"K121",
     enfermedad:"Otras formas de estomatitis"
  },
  {
     id:"K122",
     enfermedad:"Celulitis y absceso de boca"
  },
  {
     id:"K13",
     enfermedad:"Otras enfermedades de los labios y de la mucosa bucal"
  },
  {
     id:"K130",
     enfermedad:"Enfermedades de los labios"
  },
  {
     id:"K131",
     enfermedad:"Mordedura del labio y de la mejilla"
  },
  {
     id:"K132",
     enfermedad:"Leucoplasia y otras alteraciones del epitelio bucal, incluyendo la lengua"
  },
  {
     id:"K133",
     enfermedad:"Leucoplasia pilosa"
  },
  {
     id:"K134",
     enfermedad:"Granuloma y lesiones semejantes de la mucosa bucal"
  },
  {
     id:"K135",
     enfermedad:"Fibrosis de la submucosa bucal"
  },
  {
     id:"K136",
     enfermedad:"Hiperplasia irritativa de la mucosa bucal"
  },
  {
     id:"K137",
     enfermedad:"Otras lesiones y las no especificadas de la mucosa bucal"
  },
  {
     id:"K14",
     enfermedad:"Enfermedades de la lengua"
  },
  {
     id:"K140",
     enfermedad:"Glositis"
  },
  {
     id:"K141",
     enfermedad:"Lengua geografica"
  },
  {
     id:"K142",
     enfermedad:"Glositis romboidea mediana"
  },
  {
     id:"K143",
     enfermedad:"Hipertrofia de las papilas linguales"
  },
  {
     id:"K144",
     enfermedad:"Atrofia de las papilas linguales"
  },
  {
     id:"K145",
     enfermedad:"Lengua plegada"
  },
  {
     id:"K146",
     enfermedad:"Glosodinia"
  },
  {
     id:"K148",
     enfermedad:"Otras enfermedades de la lengua"
  },
  {
     id:"K149",
     enfermedad:"Enfermedad de la lengua, no especificada"
  },
  {
     id:"K20",
     enfermedad:"Esofagitis"
  },
  {
     id:"K21",
     enfermedad:"Enfermedad del reflujo gastroesofagico"
  },
  {
     id:"K210",
     enfermedad:"Enfermedad del reflujo gastroesofagico con esofagitis"
  },
  {
     id:"K219",
     enfermedad:"Enfermedad del reflujo gastroesofagico sin esofagitis"
  },
  {
     id:"K22",
     enfermedad:"Otras enfermedades del esofago"
  },
  {
     id:"K220",
     enfermedad:"Acalasia del cardias"
  },
  {
     id:"K221",
     enfermedad:"Ulcera del esofago"
  },
  {
     id:"K222",
     enfermedad:"Obstruccion del esofago"
  },
  {
     id:"K223",
     enfermedad:"Perforacion del esofago"
  },
  {
     id:"K224",
     enfermedad:"Disquinesia del esofago"
  },
  {
     id:"K225",
     enfermedad:"Diverticulo del esofago, adquirido"
  },
  {
     id:"K226",
     enfermedad:"Sindrome de laceracion y hemorragia gastroesofagicas"
  },
  {
     id:"K228",
     enfermedad:"Otras enfermedades especificadas del esofago"
  },
  {
     id:"K229",
     enfermedad:"Enfermedad del esofago, no especificada"
  },
  {
     id:"K23",
     enfermedad:"Trastornos del esofago en enfermedades clasificadas en otra parte"
  },
  {
     id:"K230",
     enfermedad:"Esofagitis tuberculosa (a18.)"
  },
  {
     id:"K231",
     enfermedad:"Megaesofago en la enfermedad de chagas (b57.3)"
  },
  {
     id:"K238",
     enfermedad:"Trastornos del esofago en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"K25",
     enfermedad:"Ulcera gastrica"
  },
  {
     id:"K250",
     enfermedad:"Ulcera gastrica aguda con hemorragia"
  },
  {
     id:"K251",
     enfermedad:"Ulcera gastrica aguda con perforacion"
  },
  {
     id:"K252",
     enfermedad:"Ulcera gastrica aguda con hemorragia y perforacion"
  },
  {
     id:"K253",
     enfermedad:"Ulcera gastrica aguda sin hemorragia ni perforacion"
  },
  {
     id:"K254",
     enfermedad:"Ulcera gastrica cronica o no especificada, con hemorragia"
  },
  {
     id:"K255",
     enfermedad:"Ulcera gastrica cronica o no especificada, con perforacion"
  },
  {
     id:"K256",
     enfermedad:"Ulcera gastrica cronica o no especificada, con hemorragia y perforacion"
  },
  {
     id:"K257",
     enfermedad:"Ulcera gastrica cronica sin hemorragia ni perforacion"
  },
  {
     id:"K259",
     enfermedad:"Ulcera gastrica no especificada como aguda ni cronica, sin hemorragia ni perforacion"
  },
  {
     id:"K26",
     enfermedad:"Ulcera duodenal"
  },
  {
     id:"K260",
     enfermedad:"Ulcera duodenal aguda con hemorragia"
  },
  {
     id:"K261",
     enfermedad:"Ulcera duodenal aguda con perforacion"
  },
  {
     id:"K262",
     enfermedad:"Ulcera duodenal aguda con hemorragia y perforacion"
  },
  {
     id:"K263",
     enfermedad:"Ulcera duodenal aguda sin hemorragia ni perforacion"
  },
  {
     id:"K264",
     enfermedad:"Ulcera duodenal cronica o no especificada, con hemorragia"
  },
  {
     id:"K265",
     enfermedad:"Ulcera duodenal cronica o no especificada, con perforacion"
  },
  {
     id:"K266",
     enfermedad:"Ulcera duodenal cronica o no especificada, con hemorragia y perforacion"
  },
  {
     id:"K267",
     enfermedad:"Ulcera duodenal cronica sin hemorragia ni perforacion"
  },
  {
     id:"K269",
     enfermedad:"Ulcera duodenal no especificada como aguda ni cronica, sin hemorragia ni perforacion"
  },
  {
     id:"K27",
     enfermedad:"Ulcera peptica, sitio no especificado"
  },
  {
     id:"K270",
     enfermedad:"Ulcera peptica, de sitio no especificado aguda con hemorragia"
  },
  {
     id:"K271",
     enfermedad:"Ulcera peptica, de sitio no especificado aguda con perforacion"
  },
  {
     id:"K272",
     enfermedad:"Ulcera peptica, de sitio no especificado aguda con hemorragia y perforacion"
  },
  {
     id:"K273",
     enfermedad:"Ulcera peptica, de sitio no especificado aguda sin hemorragia ni perforacion"
  },
  {
     id:"K274",
     enfermedad:"Ulcera peptica, de sitio no especificado cronica o no especificada, con hemorragia"
  },
  {
     id:"K275",
     enfermedad:"Ulcera peptica, de sitio no especificado cronica o no especificada, con perforacion"
  },
  {
     id:"K276",
     enfermedad:"Ulcera peptica, de sitio no especificado cronica o no especificada, con hemorragia y perforacion"
  },
  {
     id:"K277",
     enfermedad:"Ulcera peptica, de sitio no especificado cronica sin hemorragia ni perforacion"
  },
  {
     id:"K279",
     enfermedad:"Ulcera peptica, de sitio no especificado no especificada como aguda ni cronica, sin hemorragia ni perforacion"
  },
  {
     id:"K28",
     enfermedad:"Ulcera gastroyeyunal"
  },
  {
     id:"K280",
     enfermedad:"Ulcera gastroyeyunal aguda con hemorragia"
  },
  {
     id:"K281",
     enfermedad:"Ulcera gastroyeyunal aguda con perforacion"
  },
  {
     id:"K282",
     enfermedad:"Ulcera gastroyeyunal aguda con hemorragia y perforacion"
  },
  {
     id:"K283",
     enfermedad:"Ulcera gastroyeyunal aguda sin hemorragia ni perforacion"
  },
  {
     id:"K284",
     enfermedad:"Ulcera gastroyeyunal cronica o no especificada, con hemorragia"
  },
  {
     id:"K285",
     enfermedad:"Ulcera gastroyeyunal cronica o no especificada, con perforacion"
  },
  {
     id:"K286",
     enfermedad:"Ulcera gastroyeyunal cronica o no especificada, con hemorragia y perforacion"
  },
  {
     id:"K287",
     enfermedad:"Ulcera gastroyeyunal cronica sin hemorragia ni perforacion"
  },
  {
     id:"K289",
     enfermedad:"Ulcera gastroyeyunal no especificada como aguda ni cronica, sin hemorragia ni perforacion"
  },
  {
     id:"K29",
     enfermedad:"Gastritis y duodenitis"
  },
  {
     id:"K290",
     enfermedad:"Gastritis aguda hemorragica"
  },
  {
     id:"K291",
     enfermedad:"Otras gastritis agudas"
  },
  {
     id:"K292",
     enfermedad:"Gastritis alcoholica"
  },
  {
     id:"K293",
     enfermedad:"Gastritis cronica superficial."
  },
  {
     id:"K294",
     enfermedad:"Gastritis cronica atrofica"
  },
  {
     id:"K295",
     enfermedad:"Gastritis cronica, no especificada"
  },
  {
     id:"K296",
     enfermedad:"Otras gastritis"
  },
  {
     id:"K297",
     enfermedad:"Gastritis, no especificada"
  },
  {
     id:"K298",
     enfermedad:"Duodenitis"
  },
  {
     id:"K299",
     enfermedad:"Gastroduodenitis, no especificada"
  },
  {
     id:"K30",
     enfermedad:"Dispepsia"
  },
  {
     id:"K31",
     enfermedad:"Otras enfermedades del estomago y del duodeno"
  },
  {
     id:"K310",
     enfermedad:"Dilatacion aguda del estomago"
  },
  {
     id:"K311",
     enfermedad:"Estenosis pilorica hipertrofica del adulto"
  },
  {
     id:"K312",
     enfermedad:"Estrechez o estenosis del estomago en reloj de arena"
  },
  {
     id:"K313",
     enfermedad:"Espasmo del piloro, no clasificado en otra parte"
  },
  {
     id:"K314",
     enfermedad:"Diverticulo gastrico"
  },
  {
     id:"K315",
     enfermedad:"Obstruccion del duodeno"
  },
  {
     id:"K316",
     enfermedad:"Fistula del estomago y del duodeno"
  },
  {
     id:"K317",
     enfermedad:"Polipo del estomago y del duodeno"
  },
  {
     id:"K318",
     enfermedad:"Otras enfermedades especificadas del estomago y del duodeno"
  },
  {
     id:"K319",
     enfermedad:"Enfermedad del estomago y del duodeno, no especificada"
  },
  {
     id:"K35",
     enfermedad:"Apendicitis aguda"
  },
  {
     id:"K350",
     enfermedad:"Apendicitis aguda con peritonitis generalizada"
  },
  {
     id:"K351",
     enfermedad:"Apendicitis aguda con absceso peritoneal"
  },
  {
     id:"K359",
     enfermedad:"Apendicitis aguda, no especificada"
  },
  {
     id:"K36",
     enfermedad:"Otros tipos de apendicitis"
  },
  {
     id:"K37",
     enfermedad:"Apendicitis no especificada"
  },
  {
     id:"K38",
     enfermedad:"Otras enfermedades del apendice"
  },
  {
     id:"K380",
     enfermedad:"Hiperplasia del apendice"
  },
  {
     id:"K381",
     enfermedad:"Concreciones apendiculares"
  },
  {
     id:"K382",
     enfermedad:"Diverticulo del apendice"
  },
  {
     id:"K383",
     enfermedad:"Fistula del apendice"
  },
  {
     id:"K388",
     enfermedad:"Otras enfermedades especificadas del apendice"
  },
  {
     id:"K389",
     enfermedad:"Enfermedad del apendice, no especificada"
  },
  {
     id:"K40",
     enfermedad:"Hernia inguinal"
  },
  {
     id:"K400",
     enfermedad:"Hernia inguinal bilateral con obstruccion, sin gangrena"
  },
  {
     id:"K401",
     enfermedad:"Hernia inguinal bilateral con gangrena"
  },
  {
     id:"K402",
     enfermedad:"Hernia inguinal bilateral, sin obstruccion ni gangrena"
  },
  {
     id:"K403",
     enfermedad:"Hernia inguinal unilateral o no especificada, con obstruccion, sin gangrena"
  },
  {
     id:"K404",
     enfermedad:"Hernia inguinal unilateral o no especificada, con gangrena"
  },
  {
     id:"K409",
     enfermedad:"Hernia inguinal unilateral o no especificada, sin obstrucion ni gangrena"
  },
  {
     id:"K41",
     enfermedad:"Hernia femoral"
  },
  {
     id:"K410",
     enfermedad:"Hernia femoral bilateral, con obstruccion, sin gangrena"
  },
  {
     id:"K411",
     enfermedad:"Hernia femoral bilateral, con gangrena"
  },
  {
     id:"K412",
     enfermedad:"Hernia femoral bilateral, sin obstruccion ni gangrena"
  },
  {
     id:"K413",
     enfermedad:"Hernia femoral unilateral o no especificada, con obstruccion, sin gangrena"
  },
  {
     id:"K414",
     enfermedad:"Hernia femoral unilateral o no especificada, con gangrena"
  },
  {
     id:"K419",
     enfermedad:"Hernia femoral unilateral o no especificada, sin obstruccion ni gangrena"
  },
  {
     id:"K42",
     enfermedad:"Hernia umbilical"
  },
  {
     id:"K420",
     enfermedad:"Hernia umbilical con obstruccion, sin gangrena"
  },
  {
     id:"K421",
     enfermedad:"Hernia umbilical con gangrena"
  },
  {
     id:"K429",
     enfermedad:"Hernia umbilical sin obstruccion ni gangrena"
  },
  {
     id:"K43",
     enfermedad:"Hernia ventral"
  },
  {
     id:"K430",
     enfermedad:"Hernia ventral con obstruccion, sin gangrena"
  },
  {
     id:"K431",
     enfermedad:"Hernia ventral con gangrena"
  },
  {
     id:"K439",
     enfermedad:"Hernia ventral sin obstruccion ni gangrena"
  },
  {
     id:"K44",
     enfermedad:"Hernia diafragmatica"
  },
  {
     id:"K440",
     enfermedad:"Hernia diafragmatica con obstruccion, sin gangrena"
  },
  {
     id:"K441",
     enfermedad:"Hernia diafragmatica con gangrena"
  },
  {
     id:"K449",
     enfermedad:"Hernia diafragmatica sin obstruccion ni gangrena"
  },
  {
     id:"K45",
     enfermedad:"Otras hernias de la cavidad abdominal"
  },
  {
     id:"K450",
     enfermedad:"Otras hernias de la cavidad abdominal especificadas, con obstruccion, sin gangrena"
  },
  {
     id:"K451",
     enfermedad:"Otras hernias de la cavidad abdominal especificadas, con gangrena"
  },
  {
     id:"K458",
     enfermedad:"Otras hernias de la cavidad abdominal especificadas, sin obstruccion ni gangrena"
  },
  {
     id:"K46",
     enfermedad:"Hernia no especificada de la cavidad abdominal"
  },
  {
     id:"K460",
     enfermedad:"Hernia abdominal no especificada, con obstruccion, sin gangrena"
  },
  {
     id:"K461",
     enfermedad:"Hernia abdominal no especificada, con gangrena"
  },
  {
     id:"K469",
     enfermedad:"Hernia abdominal no especificada, sin obstruccion ni gangrena"
  },
  {
     id:"K50",
     enfermedad:"Enfermedades de crohn [enteritis regional]"
  },
  {
     id:"K500",
     enfermedad:"Enfermedad de crohn del intestino delgado"
  },
  {
     id:"K501",
     enfermedad:"Enfermedad de crohn del intestino grueso"
  },
  {
     id:"K508",
     enfermedad:"Otros tipos de enfermedad de crohn"
  },
  {
     id:"K509",
     enfermedad:"Enfermedad de crohn, no especificada"
  },
  {
     id:"K51",
     enfermedad:"Colitis ulcerativa"
  },
  {
     id:"K510",
     enfermedad:"Enterocolitis (cronica) ulcerativa"
  },
  {
     id:"K511",
     enfermedad:"Ileocolitis (cronica) ulcerativa"
  },
  {
     id:"K512",
     enfermedad:"Proctitis (cronica) ulcerativa"
  },
  {
     id:"K513",
     enfermedad:"Rectosigmoiditis (cronica) ulcerativa"
  },
  {
     id:"K514",
     enfermedad:"Seudopoliposis del colon"
  },
  {
     id:"K515",
     enfermedad:"Proctocolitis mucosa"
  },
  {
     id:"K518",
     enfermedad:"Otras colitis ulcerativas"
  },
  {
     id:"K519",
     enfermedad:"Colitis ulcerativa, sin otra especificacion"
  },
  {
     id:"K52",
     enfermedad:"Otras colitis y gastroenterit no infecciosas"
  },
  {
     id:"K520",
     enfermedad:"Colitis y gastroenteritis debidas a radiacion"
  },
  {
     id:"K521",
     enfermedad:"Colitis y gastroenteritis toxicas"
  },
  {
     id:"K522",
     enfermedad:"Colitis y gastroenteritis alergicas y dieteticas"
  },
  {
     id:"K528",
     enfermedad:"Otras colitis y gastroenteritis no infecciosas especificadas"
  },
  {
     id:"K529",
     enfermedad:"Colitis y gastroenteritis no infecciosas, no especificadas"
  },
  {
     id:"K55",
     enfermedad:"Trastornos vasculares de los intestinos"
  },
  {
     id:"K550",
     enfermedad:"Trastorno vascular agudo de los intestinos"
  },
  {
     id:"K551",
     enfermedad:"Trastorno vascular cronico del intestino"
  },
  {
     id:"K552",
     enfermedad:"Angiodisplasia del colon"
  },
  {
     id:"K558",
     enfermedad:"Otros trastornos vasculares del intestino"
  },
  {
     id:"K559",
     enfermedad:"Trastorno vascular del intestino, no especificado"
  },
  {
     id:"K56",
     enfermedad:"Ileo paralitico y obstruccion intestinal sin hernia"
  },
  {
     id:"K560",
     enfermedad:"Ileo paralitico"
  },
  {
     id:"K561",
     enfermedad:"Invaginacion"
  },
  {
     id:"K562",
     enfermedad:"Volvulo"
  },
  {
     id:"K563",
     enfermedad:"Ileo por calculo biliar"
  },
  {
     id:"K564",
     enfermedad:"Otras obstrucciones del intestino"
  },
  {
     id:"K565",
     enfermedad:"Adherencias [bridas] intestinales con obstruccion"
  },
  {
     id:"K566",
     enfermedad:"Otras obstrucciones intestinales y las no especificadas"
  },
  {
     id:"K567",
     enfermedad:"Ileo, no especificado"
  },
  {
     id:"K57",
     enfermedad:"Enfermedad diverticular del intestino"
  },
  {
     id:"K570",
     enfermedad:"Enfermedad diverticular del intestino delgado con perforacion y absceso"
  },
  {
     id:"K571",
     enfermedad:"Enfermedad diverticular del intestino delgado sin perforacion ni absceso"
  },
  {
     id:"K572",
     enfermedad:"Enfermedad diverticular del intestino grueso con perforacion y absceso"
  },
  {
     id:"K573",
     enfermedad:"Enfermedad diverticular del intestino grueso sin perforacion ni absceso"
  },
  {
     id:"K574",
     enfermedad:"Enfermedad diverticular de ambos intestinos con perforacion y absceso"
  },
  {
     id:"K575",
     enfermedad:"Enfermedad diverticular de ambos intestinos, sin perforacion ni absceso"
  },
  {
     id:"K578",
     enfermedad:"Enfermedad diverticular del intestino, parte no especificada, con perforacion y absceso"
  },
  {
     id:"K579",
     enfermedad:"Enfermedad diverticular del intestino, parte no especificada, sin perforacion ni absceso"
  },
  {
     id:"K58",
     enfermedad:"Sindrome del colon irritable"
  },
  {
     id:"K580",
     enfermedad:"Sindrome del colon irritable con diarrea"
  },
  {
     id:"K589",
     enfermedad:"Sindrome del colon irritable sin diarrea"
  },
  {
     id:"K59",
     enfermedad:"Otros trastornos funcionales del intestino"
  },
  {
     id:"K590",
     enfermedad:"Constipacion"
  },
  {
     id:"K591",
     enfermedad:"Diarrea funcional"
  },
  {
     id:"K592",
     enfermedad:"Intestino neurogenico, no clasificado en otra parte"
  },
  {
     id:"K593",
     enfermedad:"Megacolon, no clasificado en otra parte"
  },
  {
     id:"K594",
     enfermedad:"Espasmo anal"
  },
  {
     id:"K598",
     enfermedad:"Otros trastornos funcionales especificados del intestino"
  },
  {
     id:"K599",
     enfermedad:"Trastorno funcional intestinal, no especificado"
  },
  {
     id:"K60",
     enfermedad:"Fisura y fistula de las regiones anal y rectal"
  },
  {
     id:"K600",
     enfermedad:"Fisura anal aguda"
  },
  {
     id:"K601",
     enfermedad:"Fisura anal cronica"
  },
  {
     id:"K602",
     enfermedad:"Fisura anal, no especificada"
  },
  {
     id:"K603",
     enfermedad:"Fisura anal"
  },
  {
     id:"K604",
     enfermedad:"Fistula rectal"
  },
  {
     id:"K605",
     enfermedad:"Fistula anorrectal"
  },
  {
     id:"K61",
     enfermedad:"Absceso de las regiones anal y rectal"
  },
  {
     id:"K610",
     enfermedad:"Absceso anal"
  },
  {
     id:"K611",
     enfermedad:"Absceso rectal"
  },
  {
     id:"K612",
     enfermedad:"Absceso anorrectal"
  },
  {
     id:"K613",
     enfermedad:"Absceso isquiorrectal"
  },
  {
     id:"K614",
     enfermedad:"Absceso intraesfinteriano"
  },
  {
     id:"K62",
     enfermedad:"Otras enfermedades del ano y del recto"
  },
  {
     id:"K620",
     enfermedad:"Polipo anal"
  },
  {
     id:"K621",
     enfermedad:"Polipo rectal"
  },
  {
     id:"K622",
     enfermedad:"Prolapso anal"
  },
  {
     id:"K623",
     enfermedad:"Prolapso rectal"
  },
  {
     id:"K624",
     enfermedad:"Estenosis del ano y del recto"
  },
  {
     id:"K625",
     enfermedad:"Hemorragia del ano y del recto"
  },
  {
     id:"K626",
     enfermedad:"Ulcera del ano y del recto"
  },
  {
     id:"K627",
     enfermedad:"Proctitis por radiacion"
  },
  {
     id:"K628",
     enfermedad:"Otras enfermedades especificadas del ano y del recto"
  },
  {
     id:"K629",
     enfermedad:"Enfermedad del ano y del recto, no especificada"
  },
  {
     id:"K63",
     enfermedad:"Otras enfermedades de los intestinos"
  },
  {
     id:"K630",
     enfermedad:"Absceso del intestino"
  },
  {
     id:"K631",
     enfermedad:"Perforacion del intestino (no traumatica)"
  },
  {
     id:"K632",
     enfermedad:"Fistula del intestino"
  },
  {
     id:"K633",
     enfermedad:"Ulcera del intestino"
  },
  {
     id:"K634",
     enfermedad:"Enteroptosis"
  },
  {
     id:"K635",
     enfermedad:"Polipo del colon"
  },
  {
     id:"K638",
     enfermedad:"Otras enfermedades especificadas del intestino"
  },
  {
     id:"K639",
     enfermedad:"Enfermedad del intestino, no especificada"
  },
  {
     id:"K65",
     enfermedad:"Peritonitis"
  },
  {
     id:"K650",
     enfermedad:"Peritonitis aguda"
  },
  {
     id:"K658",
     enfermedad:"Otras peritonitis"
  },
  {
     id:"K659",
     enfermedad:"Peritonitis, no especificada"
  },
  {
     id:"K66",
     enfermedad:"Otros trastornos del peritoneo"
  },
  {
     id:"K660",
     enfermedad:"Adherencias peritoneales"
  },
  {
     id:"K661",
     enfermedad:"Hemoperitoneo"
  },
  {
     id:"K668",
     enfermedad:"Otros trastornos especificados del peritoneo"
  },
  {
     id:"K669",
     enfermedad:"Trastorno del peritoneo, no especificado"
  },
  {
     id:"K67",
     enfermedad:"Trastornos del peritoneo en enfermedades clasificadas en otra parte"
  },
  {
     id:"K670",
     enfermedad:"Peritonitis por clamidias (a74.8)"
  },
  {
     id:"K671",
     enfermedad:"Peritonitis gonococica (a54.8)"
  },
  {
     id:"K672",
     enfermedad:"Peritonitis sifilitica (a52.7)"
  },
  {
     id:"K673",
     enfermedad:"Peritonitis tuberculosa (a18.3)"
  },
  {
     id:"K678",
     enfermedad:"Otros trastornos del peritoneo en enfermedades infecciosas clasificadas en otra parte"
  },
  {
     id:"K70",
     enfermedad:"Enfermedad alcoholica del higado"
  },
  {
     id:"K700",
     enfermedad:"Higado alcoholico adiposo"
  },
  {
     id:"K701",
     enfermedad:"Hepatitis alcoholica"
  },
  {
     id:"K702",
     enfermedad:"Fibrosis y esclerosis del higado, alcoholica"
  },
  {
     id:"K703",
     enfermedad:"Cirrosis hepatica alcoholica"
  },
  {
     id:"K704",
     enfermedad:"Insuficiencia hepatica alcoholica"
  },
  {
     id:"K709",
     enfermedad:"Enfermedad hepatica alcoholica, no especificada"
  },
  {
     id:"K71",
     enfermedad:"Enfermedad toxica del higado"
  },
  {
     id:"K710",
     enfermedad:"Enfermedad toxica del higado, con colestasis"
  },
  {
     id:"K711",
     enfermedad:"Enfermedad toxica del higado, con necrosis hepatica"
  },
  {
     id:"K712",
     enfermedad:"Enfermedad toxica del higado, con hepatitis aguda"
  },
  {
     id:"K713",
     enfermedad:"Enfermedad toxica del higado, con hepatitis cronica persistente"
  },
  {
     id:"K714",
     enfermedad:"Enfermedad toxica del higado, con hepatitis cronica lobular"
  },
  {
     id:"K715",
     enfermedad:"Enfermedad toxica del higado, con hepatitis cronica activa"
  },
  {
     id:"K716",
     enfermedad:"Enfermedad toxica del higado, con hepatitis no clasificada en otra parte"
  },
  {
     id:"K717",
     enfermedad:"Enfermedad toxica del higado, con cirrosis y fibrosis del higado"
  },
  {
     id:"K718",
     enfermedad:"Enfermedad toxica del higado, con otros trastornos hepaticos"
  },
  {
     id:"K719",
     enfermedad:"Enfermedad toxica del higado, no especificada"
  },
  {
     id:"K72",
     enfermedad:"Insuficiencia hepatica no clasificadas en otra parte"
  },
  {
     id:"K720",
     enfermedad:"Insuficiencia hepatica aguda o subaguda"
  },
  {
     id:"K721",
     enfermedad:"Insuficiencia hepatica cronica"
  },
  {
     id:"K729",
     enfermedad:"Insuficiencia hepatica, no especificada"
  },
  {
     id:"K73",
     enfermedad:"Hepatitis cronica no clasificadas en otra parte"
  },
  {
     id:"K730",
     enfermedad:"Hepatitis cronica persistente, no clasificada en otra parte"
  },
  {
     id:"K731",
     enfermedad:"Hepatitis cronica lobular, no clasificada en otra parte"
  },
  {
     id:"K732",
     enfermedad:"Hepatitis cronica activa, no clasificada en otra parte"
  },
  {
     id:"K738",
     enfermedad:"Otras hepatitis cronicas, no clasificadas en otra parte"
  },
  {
     id:"K739",
     enfermedad:"Hepatitis cronica, no especificada"
  },
  {
     id:"K74",
     enfermedad:"Fibrosis y cirrosis del higado"
  },
  {
     id:"K740",
     enfermedad:"Fibrosis hepatica"
  },
  {
     id:"K741",
     enfermedad:"Esclerosis hepatica"
  },
  {
     id:"K742",
     enfermedad:"Fibrosis hepatica con esclerosis hepatica"
  },
  {
     id:"K743",
     enfermedad:"Cirrosis biliar primaria"
  },
  {
     id:"K744",
     enfermedad:"Cirrosis biliar secundaria"
  },
  {
     id:"K745",
     enfermedad:"Cirrosis biliar, no especificada"
  },
  {
     id:"K746",
     enfermedad:"Otras cirrosis del higado y la no especificadas"
  },
  {
     id:"K75",
     enfermedad:"Otras enfermedades inflamatorias del higado"
  },
  {
     id:"K750",
     enfermedad:"Absceso del higado"
  },
  {
     id:"K751",
     enfermedad:"Flebitis de la vena porta"
  },
  {
     id:"K752",
     enfermedad:"Hepatitis reactiva no especifica"
  },
  {
     id:"K753",
     enfermedad:"Hepatitis granulomatosa, no clasificada en otra parte"
  },
  {
     id:"K754",
     enfermedad:"Hepatitis autoinmune"
  },
  {
     id:"K758",
     enfermedad:"Otras enfermedades inflamatorias del higado, especificadas"
  },
  {
     id:"K759",
     enfermedad:"Enfermedad inflamatoria del higado, no especificada"
  },
  {
     id:"K76",
     enfermedad:"Otras enfermedades del higado"
  },
  {
     id:"K760",
     enfermedad:"Degeneracion grasa del higado, no clasificada en otra parte"
  },
  {
     id:"K761",
     enfermedad:"Congestion pasiva cronica del higado"
  },
  {
     id:"K762",
     enfermedad:"Necrosis hemorragica central del higado"
  },
  {
     id:"K763",
     enfermedad:"Infarto del higado"
  },
  {
     id:"K764",
     enfermedad:"Peliosis hepatica"
  },
  {
     id:"K765",
     enfermedad:"Enfermedad veno"
  },
  {
     id:"K766",
     enfermedad:"Hipertension portal"
  },
  {
     id:"K767",
     enfermedad:"Sindrome hepatorrenal"
  },
  {
     id:"K768",
     enfermedad:"Otras enfermedades especificas del higado"
  },
  {
     id:"K769",
     enfermedad:"Enfermedad del higado, no especificada"
  },
  {
     id:"K77",
     enfermedad:"Trastornos del higado en enfermedades clasificadas en otra parte"
  },
  {
     id:"K770",
     enfermedad:"Trastornos del higado en enfermedades infecciosas y parasitarias clasificadas en otra parte"
  },
  {
     id:"K778",
     enfermedad:"Trastorno del higado en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"K80",
     enfermedad:"Colelitiasis"
  },
  {
     id:"K800",
     enfermedad:"Calculo de la vesicula biliar con colecistitis aguda"
  },
  {
     id:"K801",
     enfermedad:"Calculo de la vesicula biliar con otra colecistitis"
  },
  {
     id:"K802",
     enfermedad:"Calculo de la vesicula biliar sin colecistitis"
  },
  {
     id:"K803",
     enfermedad:"Calculo de conducto biliar con colangitis"
  },
  {
     id:"K804",
     enfermedad:"Calculo de conducto biliar con colecistitis"
  },
  {
     id:"K805",
     enfermedad:"Calculo de conducto biliar sin colangitis ni colecistitis"
  },
  {
     id:"K808",
     enfermedad:"Otras colelitiasis"
  },
  {
     id:"K81",
     enfermedad:"Colecistitis"
  },
  {
     id:"K810",
     enfermedad:"Colecistitis aguda"
  },
  {
     id:"K811",
     enfermedad:"Colecistitis cronica"
  },
  {
     id:"K818",
     enfermedad:"Otras colecistitis"
  },
  {
     id:"K819",
     enfermedad:"Colecistitis, no especificada"
  },
  {
     id:"K82",
     enfermedad:"Otras enfermedades de la vesicula biliar"
  },
  {
     id:"K820",
     enfermedad:"Obstruccion de la vesicula biliar"
  },
  {
     id:"K821",
     enfermedad:"Hidropesia de la vesicula biliar"
  },
  {
     id:"K822",
     enfermedad:"Perforacion de la vesicula biliar"
  },
  {
     id:"K823",
     enfermedad:"Fistula de la vesicula biliar"
  },
  {
     id:"K824",
     enfermedad:"Colesterolosis de la vesicula biliar"
  },
  {
     id:"K828",
     enfermedad:"Otras enfermedades especificadas de la vesicula biliar"
  },
  {
     id:"K829",
     enfermedad:"Enfermedad de la vesicula biliar, no especificada"
  },
  {
     id:"K83",
     enfermedad:"Otras enfermedades de las vias biliares"
  },
  {
     id:"K830",
     enfermedad:"Colangitis"
  },
  {
     id:"K831",
     enfermedad:"Obstruccion del conducto biliar"
  },
  {
     id:"K832",
     enfermedad:"Perforacion del conducto biliar"
  },
  {
     id:"K833",
     enfermedad:"Fistula del conducto biliar"
  },
  {
     id:"K834",
     enfermedad:"Espasmo del esfinter de oddi"
  },
  {
     id:"K835",
     enfermedad:"Quiste biliar"
  },
  {
     id:"K838",
     enfermedad:"Otras enfermedades especializadas de las vias biliares"
  },
  {
     id:"K839",
     enfermedad:"Enfermedad de las vias biliares, no especificada"
  },
  {
     id:"K85",
     enfermedad:"Pancreatitis aguda"
  },
  {
     id:"K86",
     enfermedad:"Otras enfermedades del pancreas"
  },
  {
     id:"K860",
     enfermedad:"Pancreatitis cronica inducida por el alcohol"
  },
  {
     id:"K861",
     enfermedad:"Otras pancreatitis cronicas"
  },
  {
     id:"K862",
     enfermedad:"Quiste del pancreas"
  },
  {
     id:"K863",
     enfermedad:"Seudoquiste del pancreas"
  },
  {
     id:"K868",
     enfermedad:"Otras enfermedades especificadas del pancreas"
  },
  {
     id:"K869",
     enfermedad:"Enfermedad del pancreas, no especificada"
  },
  {
     id:"K87",
     enfermedad:"Trastornos de la vesicula biliar, de las vias biliares y del pancreas en enfermedades clasificadas en otra parte"
  },
  {
     id:"K870",
     enfermedad:"Trastornos de la vesicula biliar y de las vias biliares en enfermedades clasificadas en otra parte"
  },
  {
     id:"K871",
     enfermedad:"Trastornos del pancreas en enfermedades clasificadas en otra parte"
  },
  {
     id:"K90",
     enfermedad:"Malabsorcion intestinal"
  },
  {
     id:"K900",
     enfermedad:"Enfermedad celiaca"
  },
  {
     id:"K901",
     enfermedad:"Esprue tropical"
  },
  {
     id:"K902",
     enfermedad:"Sindrome del asa ciega, no clasificado en otra parte"
  },
  {
     id:"K903",
     enfermedad:"Esteatorrea pancreatica"
  },
  {
     id:"K904",
     enfermedad:"Malabsorcion debida a intolerancia, no clasificada en otra parte"
  },
  {
     id:"K908",
     enfermedad:"Otros tipos de malabsorcion intestinal"
  },
  {
     id:"K909",
     enfermedad:"Malabsorcion intestinal, no especificada"
  },
  {
     id:"K91",
     enfermedad:"Trastornos del sistema digestivo, consecutivos a procedimientos, no clasificados en otra parte"
  },
  {
     id:"K910",
     enfermedad:"Vomito postcirugia gastrointestinal"
  },
  {
     id:"K911",
     enfermedad:"Sindromes consecutivos a la cirugia gastrica"
  },
  {
     id:"K912",
     enfermedad:"Malabsorcion postquirurgica, no clasificada en otra parte"
  },
  {
     id:"K913",
     enfermedad:"Obstrucion intestinal postoperatoria"
  },
  {
     id:"K914",
     enfermedad:"Disfuncion de colostomia o enterostomia"
  },
  {
     id:"K915",
     enfermedad:"Sindrome postcolecistectomia"
  },
  {
     id:"K918",
     enfermedad:"Otros trastornos del sistema digestivo consecutivos a procedimientos, no clasificados en otra parte"
  },
  {
     id:"K919",
     enfermedad:"Trastorno no especificado al sistema digestivo consecutivo a procedimientos"
  },
  {
     id:"K92",
     enfermedad:"Otras enfermedades del sistema digestivo"
  },
  {
     id:"K920",
     enfermedad:"Hematemesis"
  },
  {
     id:"K921",
     enfermedad:"Melena"
  },
  {
     id:"K922",
     enfermedad:"Hemorragia gastrointestinal, no especificada"
  },
  {
     id:"K928",
     enfermedad:"Otras enfermedades especificadas del sistema digestivo"
  },
  {
     id:"K929",
     enfermedad:"Enfermedad del sistema digestivo, no especificada"
  },
  {
     id:"K93",
     enfermedad:"Trastornos de otros organos digestivos en enfermedades clasificadas en otra parte"
  },
  {
     id:"K930",
     enfermedad:"Trastornos tuberculosos del intestino, peritoneo y ganglios mesentericos (a18.3)"
  },
  {
     id:"K931",
     enfermedad:"Megacolon en la enfermedad de chagas (b57.3)"
  },
  {
     id:"K938",
     enfermedad:"Trastornos de otros organos digestivos especificados en enfermedades clasificadas en otra parte"
  },
  {
     id:"L00",
     enfermedad:"Sindrome estafilococico de la piel escaldada"
  },
  {
     id:"L01",
     enfermedad:"Impetigo"
  },
  {
     id:"L010",
     enfermedad:"Impetigo [cualquier sitio anatomico] [cualquier organismo]"
  },
  {
     id:"L011",
     enfermedad:"Impetiginizacion de otras dermatosis"
  },
  {
     id:"L02",
     enfermedad:"Absceso cutaneo, furunculo y antrax"
  },
  {
     id:"L020",
     enfermedad:"Absceso cutaneo, furunculo y antrax de la cara"
  },
  {
     id:"L021",
     enfermedad:"Absceso cutaneo, furunculo y antrax de la cuello"
  },
  {
     id:"L022",
     enfermedad:"Absceso cutaneo, furunculo y antrax del tronco"
  },
  {
     id:"L023",
     enfermedad:"Absceso cutaneo, furunculo y antrax de gluteos"
  },
  {
     id:"L024",
     enfermedad:"Absceso cutaneo, furunculo y antrax de miembro"
  },
  {
     id:"L028",
     enfermedad:"Absceso cutaneo, furunculo y antrax de otros sitios"
  },
  {
     id:"L029",
     enfermedad:"Absceso cutaneo, furunculo y antrax de sitio no especificado"
  },
  {
     id:"L03",
     enfermedad:"Celulitis"
  },
  {
     id:"L030",
     enfermedad:"Celulitis de los dedos de la mano y del pie"
  },
  {
     id:"L031",
     enfermedad:"Celulitis de otras partes de los miembros"
  },
  {
     id:"L032",
     enfermedad:"Celulitis de la cara"
  },
  {
     id:"L033",
     enfermedad:"Celulitis del tronco"
  },
  {
     id:"L038",
     enfermedad:"Celulitis de otros sitios"
  },
  {
     id:"L039",
     enfermedad:"Celulitis de sitio no especificado"
  },
  {
     id:"L04",
     enfermedad:"Linfadenitis aguda"
  },
  {
     id:"L040",
     enfermedad:"Linfadenitis aguda de cara, cabeza y cuello"
  },
  {
     id:"L041",
     enfermedad:"Linfadenitis aguda del tronco"
  },
  {
     id:"L042",
     enfermedad:"Linfadenitis aguda del miembro superior"
  },
  {
     id:"L043",
     enfermedad:"Linfadenitis aguda del miembro inferior"
  },
  {
     id:"L048",
     enfermedad:"Linfadenitis aguda de otros sitios"
  },
  {
     id:"L049",
     enfermedad:"Linfadenitis aguda de sitio no especificado"
  },
  {
     id:"L05",
     enfermedad:"Quiste pilonidal"
  },
  {
     id:"L050",
     enfermedad:"Quiste pilonidal con absceso"
  },
  {
     id:"L059",
     enfermedad:"Quiste pilonidal sin absceso"
  },
  {
     id:"L08",
     enfermedad:"Otras infecciones locales piel y tejido subcutaneo"
  },
  {
     id:"L080",
     enfermedad:"Pioderma"
  },
  {
     id:"L081",
     enfermedad:"Eritrasma"
  },
  {
     id:"L088",
     enfermedad:"Otras infecciones locales especificadas de la piel y del tejido subcutaneo"
  },
  {
     id:"L089",
     enfermedad:"Infeccion local de la piel y del tejido subcutaneo, no especificada"
  },
  {
     id:"L10",
     enfermedad:"Penfigo"
  },
  {
     id:"L100",
     enfermedad:"Penfigo vulgar"
  },
  {
     id:"L101",
     enfermedad:"Penfigo vegetante"
  },
  {
     id:"L102",
     enfermedad:"Penfigo foliaceo"
  },
  {
     id:"L103",
     enfermedad:"Penfigo brasileño [fogo selvagem]"
  },
  {
     id:"L104",
     enfermedad:"Penfigo eritematoso"
  },
  {
     id:"L105",
     enfermedad:"Penfigo inducido por drogas"
  },
  {
     id:"L108",
     enfermedad:"Otros penfigos"
  },
  {
     id:"L109",
     enfermedad:"Penfigo, no especificado"
  },
  {
     id:"L11",
     enfermedad:"Otros trastornos acantoliticos"
  },
  {
     id:"L110",
     enfermedad:"Queratosis folicular adquirida"
  },
  {
     id:"L111",
     enfermedad:"Dermatosis acantolitica transitoria [grover]"
  },
  {
     id:"L118",
     enfermedad:"Otros trastornos acantoliticos especificados"
  },
  {
     id:"L119",
     enfermedad:"Trastorno acantolitico, no especificado"
  },
  {
     id:"L12",
     enfermedad:"Penfigoide"
  },
  {
     id:"L120",
     enfermedad:"Penfigoide flictenular"
  },
  {
     id:"L121",
     enfermedad:"Penfigoide cicatricial"
  },
  {
     id:"L122",
     enfermedad:"Enfermedad flictenular cronica de la infancia"
  },
  {
     id:"L123",
     enfermedad:"Epidermolisis bullosa adquirida"
  },
  {
     id:"L128",
     enfermedad:"Otros penfigoides"
  },
  {
     id:"L129",
     enfermedad:"Penfigoide, no especificado"
  },
  {
     id:"L13",
     enfermedad:"Otros trastornos flictenulares"
  },
  {
     id:"L130",
     enfermedad:"Dermatitis herpetiforme"
  },
  {
     id:"L131",
     enfermedad:"Dermatitis pustulosa subcorneal"
  },
  {
     id:"L138",
     enfermedad:"Otros trastornos flictenulares especificados"
  },
  {
     id:"L139",
     enfermedad:"Trastorno flictenular, no especificado"
  },
  {
     id:"L14",
     enfermedad:"Trastornos flictenulares en enfermedades clasificadas en otra parte"
  },
  {
     id:"L20",
     enfermedad:"Dermatitis atopica"
  },
  {
     id:"L200",
     enfermedad:"Prurigo de besnier"
  },
  {
     id:"L208",
     enfermedad:"Otras dermatitis atopicas"
  },
  {
     id:"L209",
     enfermedad:"Dermatitis atopica, no especificada"
  },
  {
     id:"L21",
     enfermedad:"Dermatitis seborreica"
  },
  {
     id:"L210",
     enfermedad:"Seborrea capitis"
  },
  {
     id:"L211",
     enfermedad:"Dermatitis seborreica infantil"
  },
  {
     id:"L218",
     enfermedad:"Otras dermatitis seborreicas"
  },
  {
     id:"L219",
     enfermedad:"Dermatitis seborreica, no especificada"
  },
  {
     id:"L22",
     enfermedad:"Dermatitis del panal"
  },
  {
     id:"L23",
     enfermedad:"Dermatitis alergica de contacto"
  },
  {
     id:"L230",
     enfermedad:"Dermatitis alergica de contacto debida a metales"
  },
  {
     id:"L231",
     enfermedad:"Dermatitis alergica de contacto debida a adhesivos"
  },
  {
     id:"L232",
     enfermedad:"Dermatitis alergica de contacto debida a cosmeticos"
  },
  {
     id:"L233",
     enfermedad:"Dermatitis alergica de contacto debida a drogas en contacto con la piel"
  },
  {
     id:"L234",
     enfermedad:"Dermatitis alergica de contacto debida a colorantes"
  },
  {
     id:"L235",
     enfermedad:"Dermatitis alergica de contacto debida a otros productos quimicos"
  },
  {
     id:"L236",
     enfermedad:"Dermatitis alergica de contacto debida a alimentos en contacto con la piel"
  },
  {
     id:"L237",
     enfermedad:"Dermatitis alergica de contacto debida a plantas, excepto las alimenticias"
  },
  {
     id:"L238",
     enfermedad:"Dermatitis alergica de contacto debida a otros agentes"
  },
  {
     id:"L239",
     enfermedad:"Dermatitis alergica de contacto, de causa no especificada"
  },
  {
     id:"L240",
     enfermedad:"Dermatitis de contacto por irritantes, debida a detergentes"
  },
  {
     id:"L241",
     enfermedad:"Dermatitis de contacto por irritantes, debida a aceites y grasas"
  },
  {
     id:"L242",
     enfermedad:"Dermatitis de contacto por irritantes, debida a disolventes"
  },
  {
     id:"L243",
     enfermedad:"Dermatitis de contacto por irritantes, debida a cosmeticos"
  },
  {
     id:"L244",
     enfermedad:"Dermatitis de contacto por irritantes, debida a drogas en contacto con la piel"
  },
  {
     id:"L245",
     enfermedad:"Dermatitis de contacto por irritantes, debida a otros productos quimicos"
  },
  {
     id:"L246",
     enfermedad:"Dermatitis de contacto por irritantes, debida a alimentos en contacto con la piel"
  },
  {
     id:"L247",
     enfermedad:"Dermatitis de contacto por irritantes, debida a plantas, excepto las alimenticias"
  },
  {
     id:"L248",
     enfermedad:"Dermatitis de contacto por irritantes, debida a otros agentes"
  },
  {
     id:"L249",
     enfermedad:"Dermatitis de contacto por irritantes, de causa no especificada"
  },
  {
     id:"L25",
     enfermedad:"Dermatitis de contacto forma no especificada"
  },
  {
     id:"L250",
     enfermedad:"Dermatitis de contacto, forma no especificada, debida a cosmeticos"
  },
  {
     id:"L251",
     enfermedad:"Dermatitis de contacto, forma no especificada, debida a drogas en contacto con la piel"
  },
  {
     id:"L252",
     enfermedad:"Dermatitis de contacto, forma no especificada, debida a colorantes"
  },
  {
     id:"L253",
     enfermedad:"Dermatitis de contacto, forma no especificada, debida a otros productos quimicos"
  },
  {
     id:"L254",
     enfermedad:"Dermatitis de contacto, forma no especificada, debida a alimentos en contacto con la piel"
  },
  {
     id:"L255",
     enfermedad:"Dermatitis de contacto, forma no especificada, , debida a plantas, excepto las alimenticias"
  },
  {
     id:"L258",
     enfermedad:"Dermatitis de contacto, forma no especificada, debida a otros agentes"
  },
  {
     id:"L259",
     enfermedad:"Dermatitis de contacto, forma y causa no especificadas"
  },
  {
     id:"L26",
     enfermedad:"Dermatitis exfoliativa"
  },
  {
     id:"L27",
     enfermedad:"Dermatitis debida a sustancias ingeridas"
  },
  {
     id:"L270",
     enfermedad:"Erupcion cutanea generalizada debida a drogas y medicamentos"
  },
  {
     id:"L271",
     enfermedad:"Erupcion cutanea localizada debida a drogas y medicamentos"
  },
  {
     id:"L272",
     enfermedad:"Dermatitis debida a ingestion de alimentos"
  },
  {
     id:"L278",
     enfermedad:"Dermatitis debida a otras sustancias ingeridas"
  },
  {
     id:"L279",
     enfermedad:"Dermatitis debida a sustancias ingeridas no especificadas"
  },
  {
     id:"L28",
     enfermedad:"Liquen simple cronico y prurigo"
  },
  {
     id:"L280",
     enfermedad:"Liquen simple cronico"
  },
  {
     id:"L281",
     enfermedad:"Prurigo nodular"
  },
  {
     id:"L282",
     enfermedad:"Otros prurigos"
  },
  {
     id:"L29",
     enfermedad:"Prurito"
  },
  {
     id:"L290",
     enfermedad:"Prurito anal"
  },
  {
     id:"L291",
     enfermedad:"Prurito escrotal"
  },
  {
     id:"L292",
     enfermedad:"Prurito vulvar"
  },
  {
     id:"L293",
     enfermedad:"Prurito anogenital, no especificado"
  },
  {
     id:"L298",
     enfermedad:"Otros pruritos"
  },
  {
     id:"L299",
     enfermedad:"Prurito, no especificado"
  },
  {
     id:"L30",
     enfermedad:"Otras dermatitis"
  },
  {
     id:"L300",
     enfermedad:"Dermatitis numular"
  },
  {
     id:"L301",
     enfermedad:"Dishidrosis [ponfolix]"
  },
  {
     id:"L302",
     enfermedad:"Autosensibilizacion cutanea"
  },
  {
     id:"L303",
     enfermedad:"Dermatitis infecciosa"
  },
  {
     id:"L304",
     enfermedad:"Eritema intertrigo"
  },
  {
     id:"L305",
     enfermedad:"Pitiriasis alba"
  },
  {
     id:"L308",
     enfermedad:"Otras dermatitis especificadas"
  },
  {
     id:"L309",
     enfermedad:"Dermatitis, no especificada"
  },
  {
     id:"L40",
     enfermedad:"Psoriasis"
  },
  {
     id:"L400",
     enfermedad:"Psoriasis vulgar"
  },
  {
     id:"L401",
     enfermedad:"Psoriasis pustulosa generalizada"
  },
  {
     id:"L402",
     enfermedad:"Acrodermatitis continua"
  },
  {
     id:"L403",
     enfermedad:"Pustulosis palmar y plantar"
  },
  {
     id:"L404",
     enfermedad:"Psoriasis guttata"
  },
  {
     id:"L405",
     enfermedad:"Artropatia psoriasica (m07.0"
  },
  {
     id:"L408",
     enfermedad:"Otras psoriasis"
  },
  {
     id:"L409",
     enfermedad:"Psoriasis, no especificada"
  },
  {
     id:"L41",
     enfermedad:"Parapsoriasis"
  },
  {
     id:"L410",
     enfermedad:"Pitiriasis linquenoide y varioliforme aguda"
  },
  {
     id:"L411",
     enfermedad:"Pitiriasis linquenoide cronica"
  },
  {
     id:"L412",
     enfermedad:"Papulosis linfomatoide"
  },
  {
     id:"L413",
     enfermedad:"Parapsoriasis en placas pequeñas"
  },
  {
     id:"L414",
     enfermedad:"Parapsoriasis en placas grandes"
  },
  {
     id:"L415",
     enfermedad:"Parapsoriasis retiforme"
  },
  {
     id:"L418",
     enfermedad:"Otras parapsoriasis"
  },
  {
     id:"L419",
     enfermedad:"Parapsoriasis, no especificada"
  },
  {
     id:"L42",
     enfermedad:"Pitiriasis rosada"
  },
  {
     id:"L43",
     enfermedad:"Liquen plano"
  },
  {
     id:"L430",
     enfermedad:"Liquen plano hipertrofico"
  },
  {
     id:"L431",
     enfermedad:"Liquen plano flictenular"
  },
  {
     id:"L432",
     enfermedad:"Reaccion linquenoide debida a drogas"
  },
  {
     id:"L433",
     enfermedad:"Liquen plano subagudo (activo)"
  },
  {
     id:"L438",
     enfermedad:"Otros liquenes planos"
  },
  {
     id:"L439",
     enfermedad:"Liquen plano, no especificado"
  },
  {
     id:"L44",
     enfermedad:"Otros trastornos papuloescamosos"
  },
  {
     id:"L440",
     enfermedad:"Pitiriasis rubra pilaris"
  },
  {
     id:"L441",
     enfermedad:"Liquen nitido"
  },
  {
     id:"L442",
     enfermedad:"Liquen estriado"
  },
  {
     id:"L443",
     enfermedad:"Liquen rojo moniliforme"
  },
  {
     id:"L444",
     enfermedad:"Acrodermatitis papular infantil [giannotti"
  },
  {
     id:"L448",
     enfermedad:"Otros trastornos palpuloescamosos especificados"
  },
  {
     id:"L449",
     enfermedad:"Trastorno papuloescamoso, no especificado"
  },
  {
     id:"L45",
     enfermedad:"Trastornos papuloescamosos en enfermedades clasificadas en otra parte"
  },
  {
     id:"L50",
     enfermedad:"Urticaria"
  },
  {
     id:"L500",
     enfermedad:"Urticaria alergica"
  },
  {
     id:"L501",
     enfermedad:"Urticaria idiopatica"
  },
  {
     id:"L502",
     enfermedad:"Urticaria debida al calor y al frio"
  },
  {
     id:"L503",
     enfermedad:"Urticaria dermatografica"
  },
  {
     id:"L504",
     enfermedad:"Urticaria vibratoria"
  },
  {
     id:"L505",
     enfermedad:"Urticaria colinergica"
  },
  {
     id:"L506",
     enfermedad:"Urticaria por contacto"
  },
  {
     id:"L508",
     enfermedad:"Otras urticarias"
  },
  {
     id:"L509",
     enfermedad:"Urticaria, no especificada"
  },
  {
     id:"L51",
     enfermedad:"Eritema multiforme"
  },
  {
     id:"L510",
     enfermedad:"Eritema multiforme no flictenular"
  },
  {
     id:"L511",
     enfermedad:"Eritema multiforme flictenular"
  },
  {
     id:"L512",
     enfermedad:"Necrolisis epidermica toxica [lyell]"
  },
  {
     id:"L518",
     enfermedad:"Otros eritemas multiformes"
  },
  {
     id:"L519",
     enfermedad:"Eritema multiforme, no especificado"
  },
  {
     id:"L52",
     enfermedad:"Eritema nudoso"
  },
  {
     id:"L53",
     enfermedad:"Otras afecciones eritematosas"
  },
  {
     id:"L530",
     enfermedad:"Eritema toxico"
  },
  {
     id:"L531",
     enfermedad:"Eritema anular centrifugo"
  },
  {
     id:"L532",
     enfermedad:"Eritema marginado"
  },
  {
     id:"L533",
     enfermedad:"Otros eritemas figurados cronicos"
  },
  {
     id:"L538",
     enfermedad:"Otras afecciones eritematosas especificadas"
  },
  {
     id:"L539",
     enfermedad:"Afeccion eritematosa, no especificada"
  },
  {
     id:"L54",
     enfermedad:"Eritema en enfermedades clasificadas en otra parte"
  },
  {
     id:"L540",
     enfermedad:"Eritema marginado en la fiebre reumatica aguda (i00)"
  },
  {
     id:"L548",
     enfermedad:"Eritema en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"L55",
     enfermedad:"Quemadura solar"
  },
  {
     id:"L550",
     enfermedad:"Quemadura solar primer grado"
  },
  {
     id:"L551",
     enfermedad:"Quemadura solar segundo grado"
  },
  {
     id:"L552",
     enfermedad:"Quemadura solar tercer grado"
  },
  {
     id:"L558",
     enfermedad:"Otras quemaduras solares"
  },
  {
     id:"L559",
     enfermedad:"Quemadura solar, no especificada"
  },
  {
     id:"L56",
     enfermedad:"Otros cambios agudos de la piel, debidos a radiacion ultravioleta"
  },
  {
     id:"L560",
     enfermedad:"Respuesta fototoxica a drogas"
  },
  {
     id:"L561",
     enfermedad:"Respuesta fotoalergica a drogas"
  },
  {
     id:"L562",
     enfermedad:"Dermatitis por fotocontacto [dermatitis de berloque]"
  },
  {
     id:"L563",
     enfermedad:"Urticaria solar"
  },
  {
     id:"L564",
     enfermedad:"Erupcion polimorfa a la luz"
  },
  {
     id:"L568",
     enfermedad:"Otros cambios agudos especificados de la piel debidos a radiacion ultravioleta"
  },
  {
     id:"L569",
     enfermedad:"Cambio agudo de la piel debido a radiacion ultravioleta, sin otra especificacion"
  },
  {
     id:"L57",
     enfermedad:"Cambios agudos de la piel, debidos a exposicion cronica a radiacion no ionizante"
  },
  {
     id:"L570",
     enfermedad:"Queratosis actinica"
  },
  {
     id:"L571",
     enfermedad:"Reticuloide actinico"
  },
  {
     id:"L572",
     enfermedad:"Piel romboidal de la nuca"
  },
  {
     id:"L573",
     enfermedad:"Poiquilodermia de civatte"
  },
  {
     id:"L574",
     enfermedad:"Piel laxa senil"
  },
  {
     id:"L575",
     enfermedad:"Granuloma actinico"
  },
  {
     id:"L578",
     enfermedad:"Otros cambios de la piel debidos a exposicion cronica a radiacion no ionizante"
  },
  {
     id:"L579",
     enfermedad:"Cambios de la piel debidos a exposicion cronica a radiacion no ionizante, sin otra especificacion"
  },
  {
     id:"L58",
     enfermedad:"Radiodermatitis"
  },
  {
     id:"L580",
     enfermedad:"Radiodermatitis aguda"
  },
  {
     id:"L581",
     enfermedad:"Radiodermatitis cronica"
  },
  {
     id:"L589",
     enfermedad:"Radiodermatitis, no especificada"
  },
  {
     id:"L59",
     enfermedad:"Otros trastornos de la piel y del tejido subcutaneo relacionados con radiacion"
  },
  {
     id:"L590",
     enfermedad:"Eritema ab igne [dermatitis ab igne]"
  },
  {
     id:"L598",
     enfermedad:"Otros trastornos especificados de la piel y del tejido subcutaneo relacionados con radiacion"
  },
  {
     id:"L599",
     enfermedad:"Trastornos no especificados de la piel y del tejido subcutaneo relacionados con radiacion"
  },
  {
     id:"L60",
     enfermedad:"Trastornos de las uñas"
  },
  {
     id:"L600",
     enfermedad:"Uña encarnada"
  },
  {
     id:"L601",
     enfermedad:"Onicolisis"
  },
  {
     id:"L602",
     enfermedad:"Onicogriposis"
  },
  {
     id:"L603",
     enfermedad:"Distrofia ungueal"
  },
  {
     id:"L604",
     enfermedad:"Lineas de beau"
  },
  {
     id:"L605",
     enfermedad:"Sindrome de la uña amarilla"
  },
  {
     id:"L608",
     enfermedad:"Otros trastornos de las uñas"
  },
  {
     id:"L609",
     enfermedad:"Trastorno de la uña, no especificado"
  },
  {
     id:"L62",
     enfermedad:"Trastornos uñas en enfermedades clasificadas en otra parte"
  },
  {
     id:"L620",
     enfermedad:"Uña deforme de la paquidermoperiostosis (m89.4)"
  },
  {
     id:"L628",
     enfermedad:"Trastornos de las uñas en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"L63",
     enfermedad:"Alopecia areata"
  },
  {
     id:"L630",
     enfermedad:"Alopecia (capitis) total"
  },
  {
     id:"L631",
     enfermedad:"Alopecia universal"
  },
  {
     id:"L632",
     enfermedad:"Ofiasis"
  },
  {
     id:"L638",
     enfermedad:"Otras alopecias areatas"
  },
  {
     id:"L639",
     enfermedad:"Alopecia areata, no especificada"
  },
  {
     id:"L64",
     enfermedad:"Alopecia androgena"
  },
  {
     id:"L640",
     enfermedad:"Alopecia androgena, inducida por drogas"
  },
  {
     id:"L648",
     enfermedad:"Otras alopecias androgenas"
  },
  {
     id:"L649",
     enfermedad:"Alopecia androgena, no especificada"
  },
  {
     id:"L65",
     enfermedad:"Otra perdida no cicatricial del pelo"
  },
  {
     id:"L650",
     enfermedad:"Perdida capilar telogena"
  },
  {
     id:"L651",
     enfermedad:"Perdida capilar anagena"
  },
  {
     id:"L652",
     enfermedad:"Alopecia mucinosa"
  },
  {
     id:"L658",
     enfermedad:"Otras perdidas especificadas no cicatriciales del pelo"
  },
  {
     id:"L659",
     enfermedad:"Perdida no cicatricial del pelo, sin otra especificacion"
  },
  {
     id:"L66",
     enfermedad:"Alopecia cicatricial [perdida cicatricial del pelo]"
  },
  {
     id:"L660",
     enfermedad:"Seudopelada"
  },
  {
     id:"L661",
     enfermedad:"Liquen plano pilaris"
  },
  {
     id:"L662",
     enfermedad:"Foliculitis decalvante"
  },
  {
     id:"L663",
     enfermedad:"Perifoliculitis capitis abscedens"
  },
  {
     id:"L664",
     enfermedad:"Foliculitis uleritematosa reticulada"
  },
  {
     id:"L668",
     enfermedad:"Otras alopecias cicatriciales"
  },
  {
     id:"L669",
     enfermedad:"Alopecia cicatricial, no especificada"
  },
  {
     id:"L67",
     enfermedad:"Anormalidades del tallo y del color del pelo"
  },
  {
     id:"L670",
     enfermedad:"Tricorrexis nudosa"
  },
  {
     id:"L671",
     enfermedad:"Variacion del color del pelo"
  },
  {
     id:"L678",
     enfermedad:"Otras anomalias del tallo y del color del pelo"
  },
  {
     id:"L679",
     enfermedad:"Anormalidad no especificada del tallo y del color del pelo"
  },
  {
     id:"L68",
     enfermedad:"Hipertricosis"
  },
  {
     id:"L680",
     enfermedad:"Hirsutismo"
  },
  {
     id:"L681",
     enfermedad:"Hipertricosis lanuginosa adquirida"
  },
  {
     id:"L682",
     enfermedad:"Hipertricosis localizada"
  },
  {
     id:"L683",
     enfermedad:"Politriquia"
  },
  {
     id:"L688",
     enfermedad:"Otras hipertricosis"
  },
  {
     id:"L689",
     enfermedad:"Hipertricosis , no especificada"
  },
  {
     id:"L70",
     enfermedad:"Acne"
  },
  {
     id:"L700",
     enfermedad:"Acne vulgar"
  },
  {
     id:"L701",
     enfermedad:"Acne conglobado"
  },
  {
     id:"L702",
     enfermedad:"Acne varioliforme"
  },
  {
     id:"L703",
     enfermedad:"Acne tropical"
  },
  {
     id:"L704",
     enfermedad:"Acne infantil"
  },
  {
     id:"L705",
     enfermedad:"Acne excoriado de la mujer joven"
  },
  {
     id:"L708",
     enfermedad:"Otros acnes"
  },
  {
     id:"L709",
     enfermedad:"Acne, no especificado"
  },
  {
     id:"L71",
     enfermedad:"Rosacea"
  },
  {
     id:"L710",
     enfermedad:"Dermatitis peribucal"
  },
  {
     id:"L711",
     enfermedad:"Rinofima"
  },
  {
     id:"L718",
     enfermedad:"Otras rosaceas"
  },
  {
     id:"L719",
     enfermedad:"Rosacea, no especificada"
  },
  {
     id:"L72",
     enfermedad:"Quiste folicular de la piel y del tejido subcutaneo"
  },
  {
     id:"L720",
     enfermedad:"Quiste epidermico"
  },
  {
     id:"L721",
     enfermedad:"Quiste tricodermico"
  },
  {
     id:"L722",
     enfermedad:"Esteatocistoma multiple"
  },
  {
     id:"L728",
     enfermedad:"Otros quistes foliculares de la piel y del tejido subcutaneo"
  },
  {
     id:"L729",
     enfermedad:"Quiste folicular de la piel y del tejido subcutaneo, sin otra especificacion"
  },
  {
     id:"L73",
     enfermedad:"Otros trastornos foliculares"
  },
  {
     id:"L730",
     enfermedad:"Acne queloide"
  },
  {
     id:"L731",
     enfermedad:"Seudofoliculitis de la barba"
  },
  {
     id:"L732",
     enfermedad:"Hidradenitis supurativa"
  },
  {
     id:"L738",
     enfermedad:"Otros trastornos foliculares especificados"
  },
  {
     id:"L739",
     enfermedad:"Trastorno folicular, no especificado"
  },
  {
     id:"L74",
     enfermedad:"Trastornos sudoriparos ecrinos"
  },
  {
     id:"L740",
     enfermedad:"Miliaria rubra"
  },
  {
     id:"L741",
     enfermedad:"Miliaria cristalina"
  },
  {
     id:"L742",
     enfermedad:"Miliaria profunda"
  },
  {
     id:"L743",
     enfermedad:"Miliaria , no especificada"
  },
  {
     id:"L744",
     enfermedad:"Anhidrosis"
  },
  {
     id:"L748",
     enfermedad:"Otros trastornos sudoripados ecrinos"
  },
  {
     id:"L749",
     enfermedad:"Trastorno sudoriparo ecrino, no especificado"
  },
  {
     id:"L75",
     enfermedad:"Trastornos sudoriparos apocrinos"
  },
  {
     id:"L750",
     enfermedad:"Bromhidrosis"
  },
  {
     id:"L751",
     enfermedad:"Cromhidrosis"
  },
  {
     id:"L752",
     enfermedad:"Miliaria apocrina"
  },
  {
     id:"L758",
     enfermedad:"Otros trastornos sudoriparo apocrino"
  },
  {
     id:"L759",
     enfermedad:"Trastorno sudoriparo apocrino, no especificado"
  },
  {
     id:"L80",
     enfermedad:"Vitiligo"
  },
  {
     id:"L81",
     enfermedad:"Otros trastornos de la pigmentacion"
  },
  {
     id:"L810",
     enfermedad:"Hiperpigmentacion postinflamatoria"
  },
  {
     id:"L811",
     enfermedad:"Cloasma"
  },
  {
     id:"L812",
     enfermedad:"Efelide"
  },
  {
     id:"L813",
     enfermedad:"Manchas cafe con leche"
  },
  {
     id:"L814",
     enfermedad:"Otros tipos de hiperpigmentacion melanodermica"
  },
  {
     id:"L815",
     enfermedad:"Leucodermia, no clasificada en otra parte"
  },
  {
     id:"L816",
     enfermedad:"Otros trastornos de disminucion de la formacion de la melanina"
  },
  {
     id:"L817",
     enfermedad:"Dermatosis purpurica pigmentada"
  },
  {
     id:"L818",
     enfermedad:"Otros trastornos especificados de la pigmentacion"
  },
  {
     id:"L819",
     enfermedad:"Trastorno de la pigmentacion, no especificado"
  },
  {
     id:"L82",
     enfermedad:"Queratosis seborreica"
  },
  {
     id:"L83",
     enfermedad:"Acantosis nigricans"
  },
  {
     id:"L84",
     enfermedad:"Callos y callosidades"
  },
  {
     id:"L85",
     enfermedad:"Otros tipos de engrosamiento epidermico"
  },
  {
     id:"L850",
     enfermedad:"Ictiosis adquirida"
  },
  {
     id:"L851",
     enfermedad:"Queratosis [queratodermia] palmar y plantar adquirida"
  },
  {
     id:"L852",
     enfermedad:"Queratosis punctata (palmar y plantar)"
  },
  {
     id:"L853",
     enfermedad:"Xerosis del cutis"
  },
  {
     id:"L858",
     enfermedad:"Otros engrosamientos epidermicos especificados"
  },
  {
     id:"L859",
     enfermedad:"Engrosamiento epidermico, no especificado"
  },
  {
     id:"L86",
     enfermedad:"Queratoderma en enfermedades clasificadas en otra parte"
  },
  {
     id:"L87",
     enfermedad:"Trastornos de la eliminacion transepidermica"
  },
  {
     id:"L870",
     enfermedad:"Queratosis folicular y parafolicular penetrante del cutis [kyrle]"
  },
  {
     id:"L871",
     enfermedad:"Colagenosis perforante reactiva"
  },
  {
     id:"L872",
     enfermedad:"Elastosis serpiginosa perforante"
  },
  {
     id:"L878",
     enfermedad:"Otros trastornos de la eliminacion transepidermica"
  },
  {
     id:"L879",
     enfermedad:"Trastorno de la eliminacion transepidermica. no especificado"
  },
  {
     id:"L88",
     enfermedad:"Pioderma gangrenoso"
  },
  {
     id:"L89",
     enfermedad:"Ulcera de decubito"
  },
  {
     id:"L90",
     enfermedad:"Trastornos atroficos de la piel"
  },
  {
     id:"L900",
     enfermedad:"Liquen escleroso y atrofico"
  },
  {
     id:"L901",
     enfermedad:"Anetodermia de schweninger"
  },
  {
     id:"L902",
     enfermedad:"Anetodermia de jadassohn"
  },
  {
     id:"L903",
     enfermedad:"Atrofoderma de pasini y pierini"
  },
  {
     id:"L904",
     enfermedad:"Acrodermatitis cronica atrofica"
  },
  {
     id:"L905",
     enfermedad:"Fibrosis y afecciones cicatriciales de la piel"
  },
  {
     id:"L906",
     enfermedad:"Estrias atroficas"
  },
  {
     id:"L908",
     enfermedad:"Otros trastornos atroficos de la piel"
  },
  {
     id:"L909",
     enfermedad:"Trastorno atrofico de la piel, no especificado"
  },
  {
     id:"L91",
     enfermedad:"Trastornos hipertroficos de la piel"
  },
  {
     id:"L910",
     enfermedad:"Cicatriz queloide"
  },
  {
     id:"L918",
     enfermedad:"Otros trastornos hipertroficos de la piel"
  },
  {
     id:"L919",
     enfermedad:"Trastorno hipertrofico de la piel, no especificado"
  },
  {
     id:"L92",
     enfermedad:"Trastornos granulomatosos de la piel y del tejido subcutaneo"
  },
  {
     id:"L920",
     enfermedad:"Granuloma anular"
  },
  {
     id:"L921",
     enfermedad:"Necrobiosis lipidica, no clasificada en otra parte"
  },
  {
     id:"L922",
     enfermedad:"Granuloma facial [granuloma eosinofilo de la piel]"
  },
  {
     id:"L923",
     enfermedad:"Granuloma por cuerpo extraño de la piel y en el tejido subcutaneo"
  },
  {
     id:"L928",
     enfermedad:"Otros trastornos granulomatosos de la piel y del tejido subcutaneo"
  },
  {
     id:"L929",
     enfermedad:"Trastorno granulomatoso de la piel y del tejido subcutaneo, no especificado"
  },
  {
     id:"L93",
     enfermedad:"Lupus eritematoso"
  },
  {
     id:"L930",
     enfermedad:"Lupus eritematoso discoide"
  },
  {
     id:"L931",
     enfermedad:"Lupus eritematoso cutaneo subagudo"
  },
  {
     id:"L932",
     enfermedad:"Otros lupus eritematosos localizados"
  },
  {
     id:"L94",
     enfermedad:"Otros trastornos localizados del tejido conjuntivo"
  },
  {
     id:"L940",
     enfermedad:"Escleroderma localizado [morfea]"
  },
  {
     id:"L941",
     enfermedad:"Escleroderma lineal"
  },
  {
     id:"L942",
     enfermedad:"Calcinosis de la piel"
  },
  {
     id:"L943",
     enfermedad:"Esclerodactilia"
  },
  {
     id:"L944",
     enfermedad:"Papulas de gottron"
  },
  {
     id:"L945",
     enfermedad:"Poiquilodermia vascular atrofica"
  },
  {
     id:"L946",
     enfermedad:"Ainhum"
  },
  {
     id:"L948",
     enfermedad:"Otros trastornos localizados especificados del tejido conjuntivo"
  },
  {
     id:"L949",
     enfermedad:"Trastorno localizado del tejido conjuntivo, no especificado"
  },
  {
     id:"L95",
     enfermedad:"Vasculitis limitada a la piel no clasificadas en otra parte"
  },
  {
     id:"L950",
     enfermedad:"Vasculitis livedoide"
  },
  {
     id:"L951",
     enfermedad:"Eritema elevatum diutinum"
  },
  {
     id:"L958",
     enfermedad:"Otras vasculitis limitadas de la piel"
  },
  {
     id:"L959",
     enfermedad:"Vasculitis limitada a la piel, sin otra especificacion"
  },
  {
     id:"L97",
     enfermedad:"Ulcera del miembro inferior no clasificada en otra parte"
  },
  {
     id:"L98",
     enfermedad:"Otros trastornos de la piel y del tejido subcutaneo no clasificados en otra parte"
  },
  {
     id:"L980",
     enfermedad:"Granuloma piogeno"
  },
  {
     id:"L981",
     enfermedad:"Dermatitis facticia"
  },
  {
     id:"L982",
     enfermedad:"Dermatosis neutrofila febril [sweet]"
  },
  {
     id:"L983",
     enfermedad:"Celulitis eosinofila [wells]"
  },
  {
     id:"L984",
     enfermedad:"Ulcera cronica de la piel, no clasificada en otra parte"
  },
  {
     id:"L985",
     enfermedad:"Mucinosis de la piel"
  },
  {
     id:"L986",
     enfermedad:"Otros trastornos infiltrativos de la piel y del tejido subcutaneo"
  },
  {
     id:"L988",
     enfermedad:"Otros trastornos especificados de la piel y del tejido subcutaneo"
  },
  {
     id:"L989",
     enfermedad:"Trastorno de la piel y del tejido subcutaneo, no especificado"
  },
  {
     id:"L99",
     enfermedad:"Otros trastornos de la piel y del tejido subcutaneo en enfermedades clasificadas en otra parte"
  },
  {
     id:"L990",
     enfermedad:"Amiloidosis de la piel (e85."
  },
  {
     id:"L998",
     enfermedad:"Otros trastornos de la piel y del tejido subcutaneo en enfermedades clasificadas en otra parte"
  },
  {
     id:"M00",
     enfermedad:"Artritis piogena"
  },
  {
     id:"M000",
     enfermedad:"Artritis y poliartritis estafilococica"
  },
  {
     id:"M001",
     enfermedad:"Artritis y poliartritis neumococica"
  },
  {
     id:"M002",
     enfermedad:"Artritis y poliartritis estreptococicas"
  },
  {
     id:"M008",
     enfermedad:"Artritis y poliartritis debidas a otros agentes bacterianos especificados"
  },
  {
     id:"M009",
     enfermedad:"Artritis piogena, no especificada"
  },
  {
     id:"M01",
     enfermedad:"Infecciones directas de la articulacion en enfermedades infecciosas y parasitarias clasificadas en otra parte"
  },
  {
     id:"M010",
     enfermedad:"Artritis meningococica (a39.8)"
  },
  {
     id:"M011",
     enfermedad:"Tuberculosa (a18.0)"
  },
  {
     id:"M012",
     enfermedad:"Artritis en la enfermedad de lyme (a69.2)"
  },
  {
     id:"M013",
     enfermedad:"Artritis en otras enfermedades bacterianas clasificadas en otra parte"
  },
  {
     id:"M014",
     enfermedad:"Artritis en rubeola (b06.8)"
  },
  {
     id:"M015",
     enfermedad:"Artritis en otras enfermedades virales clasificadas en otra parte"
  },
  {
     id:"M016",
     enfermedad:"Artritis en micosis (b35"
  },
  {
     id:"M018",
     enfermedad:"Artritis en otras enfermedades infecciosas y parasitarias clasificadas en otra parte"
  },
  {
     id:"M02",
     enfermedad:"Artropatias reactivas"
  },
  {
     id:"M020",
     enfermedad:"Artropatia consecutiva a derivacion intestinal"
  },
  {
     id:"M021",
     enfermedad:"Artropatia postdisenterica"
  },
  {
     id:"M022",
     enfermedad:"Artropatia postinmunizacion"
  },
  {
     id:"M023",
     enfermedad:"Enfermedad de reiter"
  },
  {
     id:"M028",
     enfermedad:"Otras artropatia reactivas"
  },
  {
     id:"M029",
     enfermedad:"Artropatia reactiva, no especificada"
  },
  {
     id:"M03",
     enfermedad:"Artropatias postinfecciosas y reactivas en enfermedades clasificadas en otra parte"
  },
  {
     id:"M030",
     enfermedad:"Artritis postmeningococica (a39.8)"
  },
  {
     id:"M031",
     enfermedad:"Artropatia postinfecciosa en sifilis"
  },
  {
     id:"M032",
     enfermedad:"Otras artropatias postinfecciosas en enfermedades clasificadas en otra parte"
  },
  {
     id:"M036",
     enfermedad:"Artropatia reactiva en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"M05",
     enfermedad:"Artritis reumatoide seropositiva"
  },
  {
     id:"M050",
     enfermedad:"Sindrome de felty"
  },
  {
     id:"M051",
     enfermedad:"Enfermedad reumatoide del pulmon (j99.0)"
  },
  {
     id:"M052",
     enfermedad:"Vasculitis reumatoide"
  },
  {
     id:"M053",
     enfermedad:"Artritis reumatoide con compromiso de otros organos o sistemas"
  },
  {
     id:"M058",
     enfermedad:"Otras artritis reumatoideas seropositivas"
  },
  {
     id:"M059",
     enfermedad:"Artritis reumatoide seropositiva, sin otra especificacion"
  },
  {
     id:"M06",
     enfermedad:"Otras artritis reumatoides"
  },
  {
     id:"M060",
     enfermedad:"Artritis reumatoide seronegativa"
  },
  {
     id:"M061",
     enfermedad:"Enfermedad de still de comienzo en el adulto"
  },
  {
     id:"M062",
     enfermedad:"Bursitis reumatoide"
  },
  {
     id:"M063",
     enfermedad:"Nodulo reumatoide"
  },
  {
     id:"M064",
     enfermedad:"Poliartropatia inflamatoria"
  },
  {
     id:"M068",
     enfermedad:"Otras artritis reumatoideas especificadas"
  },
  {
     id:"M069",
     enfermedad:"Artritis reumatoide, no especificada"
  },
  {
     id:"M07",
     enfermedad:"Artropatias psoriasicas y enteropaticas"
  },
  {
     id:"M070",
     enfermedad:"Artropatia psoriasica interfalangica distal (l40.5)"
  },
  {
     id:"M071",
     enfermedad:"Artritis mutilante (l40.5)"
  },
  {
     id:"M072",
     enfermedad:"Espondilitis psoriasica (l40.5)"
  },
  {
     id:"M073",
     enfermedad:"Otras artropatias psoriasicas (l40.5)"
  },
  {
     id:"M074",
     enfermedad:"Artropatia en la enfermedad de crohn (enteritis regional) (k50."
  },
  {
     id:"M075",
     enfermedad:"Artropatia en la colitis ulcerativa (k51."
  },
  {
     id:"M076",
     enfermedad:"Otras artropatias enteropaticas"
  },
  {
     id:"M08",
     enfermedad:"Artritis juvenil"
  },
  {
     id:"M080",
     enfermedad:"Artritis reumatoide juvenil"
  },
  {
     id:"M081",
     enfermedad:"Espondilitis anquilosante juvenil"
  },
  {
     id:"M082",
     enfermedad:"Artritis juvenil de comienzo generalizado"
  },
  {
     id:"M083",
     enfermedad:"Poliartritis juvenil (seronegativa)"
  },
  {
     id:"M084",
     enfermedad:"Artritis juvenil pauciarticular"
  },
  {
     id:"M088",
     enfermedad:"Otras artritis juveniles"
  },
  {
     id:"M089",
     enfermedad:"Artritis juvenil, no especificada"
  },
  {
     id:"M09",
     enfermedad:"Artritis juvenil en enfermedades clasificadas en otra parte"
  },
  {
     id:"M090",
     enfermedad:"Artritis juvenil en la psoriasis (l40.5)"
  },
  {
     id:"M091",
     enfermedad:"Artritis juvenil en la enfermedad de crohn [enteritis regional] (k50."
  },
  {
     id:"M092",
     enfermedad:"Artritis juvenil en la colitis ulcerativa (k51."
  },
  {
     id:"M098",
     enfermedad:"Artritis juvenil en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"M10",
     enfermedad:"Gota"
  },
  {
     id:"M100",
     enfermedad:"Gota idiopatica"
  },
  {
     id:"M101",
     enfermedad:"Gota saturnina"
  },
  {
     id:"M102",
     enfermedad:"Gota inducida por drogas"
  },
  {
     id:"M103",
     enfermedad:"Gota debida a alteracion renal"
  },
  {
     id:"M104",
     enfermedad:"Otras gotas secundarias"
  },
  {
     id:"M109",
     enfermedad:"Gota, no especificada"
  },
  {
     id:"M11",
     enfermedad:"Otras artropatias por cristales"
  },
  {
     id:"M110",
     enfermedad:"Enfermedad por deposito de hidroxiapatita"
  },
  {
     id:"M111",
     enfermedad:"Condrocalcinosis familiar"
  },
  {
     id:"M112",
     enfermedad:"Otras condrocalcinosis"
  },
  {
     id:"M118",
     enfermedad:"Otras artropatias por cristales, especificadas"
  },
  {
     id:"M119",
     enfermedad:"Artropatia por cristales, no especificada"
  },
  {
     id:"M12",
     enfermedad:"Otras artropatias especificas"
  },
  {
     id:"M120",
     enfermedad:"Artropatia postreumatica cronica [de jaccoud]"
  },
  {
     id:"M121",
     enfermedad:"Enfermedad de kaschin"
  },
  {
     id:"M122",
     enfermedad:"Sinovitis vellonodular (pigmentada)"
  },
  {
     id:"M123",
     enfermedad:"Reumatismo palindromico"
  },
  {
     id:"M124",
     enfermedad:"Hidrartrosis intermitente"
  },
  {
     id:"M125",
     enfermedad:"Artropatia traumatica"
  },
  {
     id:"M128",
     enfermedad:"Otras artropatias especificas, no clasificadas en otra parte"
  },
  {
     id:"M13",
     enfermedad:"Otras artritis"
  },
  {
     id:"M130",
     enfermedad:"Poliartritis, no especificada"
  },
  {
     id:"M131",
     enfermedad:"Monoartritis, no clasificada en otra parte"
  },
  {
     id:"M138",
     enfermedad:"Otras artritis especificadas"
  },
  {
     id:"M139",
     enfermedad:"Artritis, no especificada"
  },
  {
     id:"M14",
     enfermedad:"Artropatia en enfermedades clasificadas en en otra parte"
  },
  {
     id:"M140",
     enfermedad:"Artropatia gotosa debida a defectos enzimaticos y a otros trastornos hereditarios, clasificados en otra parte"
  },
  {
     id:"M141",
     enfermedad:"Artropatia por cristales en otros trastornos metabolicos"
  },
  {
     id:"M142",
     enfermedad:"Artropatia diabetica (e10"
  },
  {
     id:"M143",
     enfermedad:"Dermatoartritis lipoide (e78.8)"
  },
  {
     id:"M144",
     enfermedad:"Artropatia en la amiloidosis (e85."
  },
  {
     id:"M145",
     enfermedad:"Artropatia en otros trastornos endocrinos, metabolicos y nutricionales"
  },
  {
     id:"M146",
     enfermedad:"Artropatia neuropatica"
  },
  {
     id:"M148",
     enfermedad:"Artropatia en otras enfermedades especificadas, clasificadas en otra parte"
  },
  {
     id:"M15",
     enfermedad:"Poliartrosis"
  },
  {
     id:"M150",
     enfermedad:"(osteo)artrosis primaria generalizada"
  },
  {
     id:"M151",
     enfermedad:"Nodulos de heberden (con artropatia)"
  },
  {
     id:"M152",
     enfermedad:"Nodulos de bouchard (con artropatia)"
  },
  {
     id:"M153",
     enfermedad:"Artrosis secundaria multiple"
  },
  {
     id:"M154",
     enfermedad:"(osteo)artrosis erosiva"
  },
  {
     id:"M158",
     enfermedad:"Otras poliartrosis"
  },
  {
     id:"M159",
     enfermedad:"Poliartrosis, no especificada"
  },
  {
     id:"M16",
     enfermedad:"Coxartrosis"
  },
  {
     id:"M160",
     enfermedad:"Coxartrosis primaria, bilateral"
  },
  {
     id:"M161",
     enfermedad:"Otras coxartrosis primarias"
  },
  {
     id:"M162",
     enfermedad:"Coxartrosis a consecuencia de displasia, bilateral"
  },
  {
     id:"M163",
     enfermedad:"Otras coxartrosis displasicas"
  },
  {
     id:"M164",
     enfermedad:"Coxartrosis postraumatica, bilateral"
  },
  {
     id:"M165",
     enfermedad:"Otra coxartrosis postraumatica"
  },
  {
     id:"M166",
     enfermedad:"Otra coxartrosis secundaria, bilateral"
  },
  {
     id:"M167",
     enfermedad:"Otras coxartrosis secundarias"
  },
  {
     id:"M169",
     enfermedad:"Coxartrosis, no especificada"
  },
  {
     id:"M17",
     enfermedad:"Gonartrosis"
  },
  {
     id:"M170",
     enfermedad:"Gonartrosis primaria, bilateral"
  },
  {
     id:"M171",
     enfermedad:"Otras gonartrosis primarias"
  },
  {
     id:"M172",
     enfermedad:"Gonartrosis postraumatica, bilateral"
  },
  {
     id:"M173",
     enfermedad:"Otras gonartrosis postraumaticas"
  },
  {
     id:"M174",
     enfermedad:"Otras gonartrosis secundarias, bilaterales"
  },
  {
     id:"M175",
     enfermedad:"Otras gonartrosis secundarias"
  },
  {
     id:"M179",
     enfermedad:"Gonartrosis, no especificada"
  },
  {
     id:"M18",
     enfermedad:"Artros de la primera articulacion carpometacarpiana"
  },
  {
     id:"M180",
     enfermedad:"Artrosis primaria de la primera articulacion carpometacarpiana, bilateral"
  },
  {
     id:"M181",
     enfermedad:"Otras artrosis primarias de la primera articulacion carpometacarpiana"
  },
  {
     id:"M182",
     enfermedad:"Artrosis postraumatica de la primera articulacion carpometacarpiana, bilateral"
  },
  {
     id:"M183",
     enfermedad:"Otras artrosis postraumaticas de la primera articulacion carpometacarpiana"
  },
  {
     id:"M184",
     enfermedad:"Otras artrosis secundarias de la primera articulacion carpometacarpiana, bilaterales"
  },
  {
     id:"M185",
     enfermedad:"Otras artrosis secundarias de la primera articulacion carpometacarpiana"
  },
  {
     id:"M189",
     enfermedad:"Artrosis de la primera articulacion carpometacarpiana, sin otra especificacion"
  },
  {
     id:"M19",
     enfermedad:"Otras artrosis"
  },
  {
     id:"M190",
     enfermedad:"Artrosis primaria de otras articulaciones"
  },
  {
     id:"M191",
     enfermedad:"Artrosis postraumatica de otras articulaciones"
  },
  {
     id:"M192",
     enfermedad:"Artrosis secundaria de otras articulaciones"
  },
  {
     id:"M198",
     enfermedad:"Otras artrosis especificadas"
  },
  {
     id:"M199",
     enfermedad:"Artrosis, no especificada"
  },
  {
     id:"M20",
     enfermedad:"Deformidades adquiridas de los dedos de la mano y del pie"
  },
  {
     id:"M200",
     enfermedad:"Deformidad de dedo(s) de la mano"
  },
  {
     id:"M201",
     enfermedad:"Hallux valgus (adquirido)"
  },
  {
     id:"M202",
     enfermedad:"Hallux rigidus"
  },
  {
     id:"M203",
     enfermedad:"Otras deformidades del hallux (adquiridas)"
  },
  {
     id:"M204",
     enfermedad:"Otro(s) dedo(s) del pie en martillo (adquiridos)"
  },
  {
     id:"M205",
     enfermedad:"Otras deformidades (adquiridas) del (de los) dedo(s) del pie"
  },
  {
     id:"M206",
     enfermedad:"Deformidades adquiridas de los dedos del pie, no especificadas"
  },
  {
     id:"M21",
     enfermedad:"Otras deformidades adquiridas de los miembros"
  },
  {
     id:"M210",
     enfermedad:"Deformidad en valgo, no clasificada en otra parte"
  },
  {
     id:"M211",
     enfermedad:"Deformidad en varo, no clasificada en otra"
  },
  {
     id:"M212",
     enfermedad:"Deformidad en flexion"
  },
  {
     id:"M213",
     enfermedad:"Muñeca o pie en pendulo (adquirido)"
  },
  {
     id:"M214",
     enfermedad:"Pie plano [pes planus] (adquirido)"
  },
  {
     id:"M215",
     enfermedad:"Mano o pie en garra o en talipes, pie equinovaro o zambo adquiridos"
  },
  {
     id:"M216",
     enfermedad:"Otras deformidades adquiridas del tobillo y del pie"
  },
  {
     id:"M217",
     enfermedad:"Longitud desigual de los miembros (adquirida)"
  },
  {
     id:"M218",
     enfermedad:"Otras deformidades adquiridas de los miembros, especificadas"
  },
  {
     id:"M219",
     enfermedad:"Deformidad adquirida del miembro, no especificada"
  },
  {
     id:"M22",
     enfermedad:"Trastornos de la rotula"
  },
  {
     id:"M220",
     enfermedad:"Luxacion recidivante de la rotula"
  },
  {
     id:"M221",
     enfermedad:"Subluxacion recidivante de la rotula"
  },
  {
     id:"M222",
     enfermedad:"Trastornos rotulofemorales"
  },
  {
     id:"M223",
     enfermedad:"Otros desarreglos de la rotula"
  },
  {
     id:"M224",
     enfermedad:"Condromalacia de la rotula"
  },
  {
     id:"M228",
     enfermedad:"Otros trastornos de la rotula"
  },
  {
     id:"M229",
     enfermedad:"Trastorno de la rotula, no especificado"
  },
  {
     id:"M23",
     enfermedad:"Trastorno interno de la rodilla"
  },
  {
     id:"M230",
     enfermedad:"Menisco quistico"
  },
  {
     id:"M231",
     enfermedad:"Menisco discoide (congenito)"
  },
  {
     id:"M232",
     enfermedad:"Trastorno del menisco debido a desgarro o lesion antigua"
  },
  {
     id:"M233",
     enfermedad:"Otros trastornos de los meniscos"
  },
  {
     id:"M234",
     enfermedad:"Cuerpo flotante en la rodilla"
  },
  {
     id:"M235",
     enfermedad:"Inestabilidad cronica de la rodilla"
  },
  {
     id:"M236",
     enfermedad:"Otra ruptura espontanea del (de los) ligamento(s) de la rodilla"
  },
  {
     id:"M238",
     enfermedad:"Otros trastornos internos de la rodilla"
  },
  {
     id:"M239",
     enfermedad:"Trastornos interno de la rodilla, no especificado"
  },
  {
     id:"M24",
     enfermedad:"Otros trastornos articulares especificos"
  },
  {
     id:"M240",
     enfermedad:"Cuerpo flotante articular"
  },
  {
     id:"M241",
     enfermedad:"Otros trastornos del cartilago articular"
  },
  {
     id:"M242",
     enfermedad:"Trastorno del ligamento"
  },
  {
     id:"M243",
     enfermedad:"Luxacion y subluxacion patologica de la articulacion, no clasificada en otra parte"
  },
  {
     id:"M244",
     enfermedad:"Luxacion y subluxacion recidivante de la articulacion"
  },
  {
     id:"M245",
     enfermedad:"Contractura articular"
  },
  {
     id:"M246",
     enfermedad:"Anquilosis articular"
  },
  {
     id:"M247",
     enfermedad:"Protrusion de acetabulo"
  },
  {
     id:"M248",
     enfermedad:"Otras lesiones articuladas especificadas, no clasificadas en otra parte"
  },
  {
     id:"M249",
     enfermedad:"Desarreglo articular, no especificado"
  },
  {
     id:"M25",
     enfermedad:"Otros trastornos articulares no clasificadas en otra parte"
  },
  {
     id:"M250",
     enfermedad:"Hemartrosis"
  },
  {
     id:"M251",
     enfermedad:"Fistula articular"
  },
  {
     id:"M252",
     enfermedad:"Articulacion inestable"
  },
  {
     id:"M253",
     enfermedad:"Otras inestabilidades articulares"
  },
  {
     id:"M254",
     enfermedad:"Derrame articular"
  },
  {
     id:"M255",
     enfermedad:"Dolor en articulacion"
  },
  {
     id:"M256",
     enfermedad:"Rigidez articular, no clasificada en otra parte"
  },
  {
     id:"M257",
     enfermedad:"Osteofito"
  },
  {
     id:"M258",
     enfermedad:"Otros trastornos articulares especificados"
  },
  {
     id:"M259",
     enfermedad:"Trastorno articular, no especificado"
  },
  {
     id:"M30",
     enfermedad:"Poliartritis nudosa y afecciones relacionadas"
  },
  {
     id:"M300",
     enfermedad:"Poliarteritis nudosa"
  },
  {
     id:"M301",
     enfermedad:"Poliarteritis con compromiso pulmonar [churg"
  },
  {
     id:"M302",
     enfermedad:"Poliarteritis juvenil"
  },
  {
     id:"M303",
     enfermedad:"Sindrome mucocutaneo lifonodular [kawasaki]"
  },
  {
     id:"M308",
     enfermedad:"Otras afecciones relacionadas con la poliarteritis nudosa"
  },
  {
     id:"M31",
     enfermedad:"Otras vasculopatias necrotizantes"
  },
  {
     id:"M310",
     enfermedad:"Angiitis debida a hipersensibilidad"
  },
  {
     id:"M311",
     enfermedad:"Microangiopatia trombotica"
  },
  {
     id:"M312",
     enfermedad:"Granuloma letal de la linea media"
  },
  {
     id:"M313",
     enfermedad:"Granulomatosis de wegener"
  },
  {
     id:"M314",
     enfermedad:"Sindrome del cayado de la aorta [takayasu]"
  },
  {
     id:"M315",
     enfermedad:"Arteritis de celulas gigantes con polimialgia reumatica"
  },
  {
     id:"M316",
     enfermedad:"Otras arteritis de celulas gigantes"
  },
  {
     id:"M318",
     enfermedad:"Otras vasculopatias necrotizantes especificadas"
  },
  {
     id:"M319",
     enfermedad:"Vasculopatia necrotizante, no especificada"
  },
  {
     id:"M32",
     enfermedad:"Lupus eritematoso sistemico"
  },
  {
     id:"M320",
     enfermedad:"Lupus eritematoso sistemico, inducido por drogas"
  },
  {
     id:"M321",
     enfermedad:"Lupus eritematoso sistemico con compromiso de organos o sistemas"
  },
  {
     id:"M328",
     enfermedad:"Otras formas de lupus eritematoso sistemico"
  },
  {
     id:"M329",
     enfermedad:"Lupus eritematoso sistemico, sin otra especificacion"
  },
  {
     id:"M33",
     enfermedad:"Dermatopolimiositis"
  },
  {
     id:"M330",
     enfermedad:"Dermatomiositis juvenil"
  },
  {
     id:"M331",
     enfermedad:"Otras dermatomiositis"
  },
  {
     id:"M332",
     enfermedad:"Polimiositis"
  },
  {
     id:"M339",
     enfermedad:"Dermatopolimiositis, no especificada"
  },
  {
     id:"M34",
     enfermedad:"Esclerosis sistemica"
  },
  {
     id:"M340",
     enfermedad:"Esclerosis sistemica progresiva"
  },
  {
     id:"M341",
     enfermedad:"Sindrome cr(e)st"
  },
  {
     id:"M342",
     enfermedad:"Esclerosis sistemica inducida por drogas o productos quimicos"
  },
  {
     id:"M348",
     enfermedad:"Otras formas de esclerosis sistemica"
  },
  {
     id:"M349",
     enfermedad:"Esclerosis sistemica, no especificada"
  },
  {
     id:"M35",
     enfermedad:"Otro compromiso sistemico del tejido conjuntivo"
  },
  {
     id:"M350",
     enfermedad:"Sindrome seco [sjÃ–gren]"
  },
  {
     id:"M351",
     enfermedad:"Otros sindromes superpuestos"
  },
  {
     id:"M352",
     enfermedad:"Enfermedad de behcet"
  },
  {
     id:"M353",
     enfermedad:"Polimialgia reumatica"
  },
  {
     id:"M354",
     enfermedad:"Fascitis difusa (eosinofilica)"
  },
  {
     id:"M355",
     enfermedad:"Fibrosclerosis multifocal"
  },
  {
     id:"M356",
     enfermedad:"Paniculitis recidivante [weber"
  },
  {
     id:"M357",
     enfermedad:"Sindrome de hipermovilidad"
  },
  {
     id:"M358",
     enfermedad:"Otras enfermedades especificadas con compromiso sistemico del tejido conjuntivo"
  },
  {
     id:"M359",
     enfermedad:"Compromiso sistemico del tejido conjuntivo, no especificado"
  },
  {
     id:"M36",
     enfermedad:"Trastornos sistemicos del tejido conjuntivo en enfermedades clasificadas en otra parte"
  },
  {
     id:"M360",
     enfermedad:"Dermato(poli)miositis en enfermedad neoplasica (c00"
  },
  {
     id:"M361",
     enfermedad:"Artropatia en enfermedad neoplasica (c00"
  },
  {
     id:"M362",
     enfermedad:"Artropatia hemofilica (d66"
  },
  {
     id:"M363",
     enfermedad:"Artropatia en otros trastornos de la sangre (d50"
  },
  {
     id:"M364",
     enfermedad:"Artropatia en reacciones de hipersensibilidad clasificadas en otra parte"
  },
  {
     id:"M368",
     enfermedad:"Trastornos sistemicos del tejido conjuntivo en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"M40",
     enfermedad:"Cifosis y lordosis"
  },
  {
     id:"M400",
     enfermedad:"Cifosis postural"
  },
  {
     id:"M401",
     enfermedad:"Otras cifosis secundarias"
  },
  {
     id:"M402",
     enfermedad:"Otras cifosis y las no especificadas"
  },
  {
     id:"M403",
     enfermedad:"Sindrome de espalda plana"
  },
  {
     id:"M404",
     enfermedad:"Otras lordosis"
  },
  {
     id:"M405",
     enfermedad:"Lordosis, no especificada"
  },
  {
     id:"M41",
     enfermedad:"Escoliosis"
  },
  {
     id:"M410",
     enfermedad:"Escoliosis idiopatica infantil"
  },
  {
     id:"M411",
     enfermedad:"Escoliosis idiopatica juvenil"
  },
  {
     id:"M412",
     enfermedad:"Otras escoliosis idiopaticas"
  },
  {
     id:"M413",
     enfermedad:"Escoliosis toracogenica"
  },
  {
     id:"M414",
     enfermedad:"Escoliosis neuromuscular"
  },
  {
     id:"M415",
     enfermedad:"Otras escoliosis secundarias"
  },
  {
     id:"M418",
     enfermedad:"Otras formas de escoliosis"
  },
  {
     id:"M419",
     enfermedad:"Escoliosis, no especificada"
  },
  {
     id:"M42",
     enfermedad:"Osteocondrosis de la columna vertebral"
  },
  {
     id:"M420",
     enfermedad:"Osteocondrosis juvenil de la columna vertebral"
  },
  {
     id:"M421",
     enfermedad:"Osteocondrosis de la columna vertebral del adulto"
  },
  {
     id:"M429",
     enfermedad:"Osteocondrosis vertebral, no especificada"
  },
  {
     id:"M43",
     enfermedad:"Otras dorsopatias deformantes"
  },
  {
     id:"M430",
     enfermedad:"Espondilolisis"
  },
  {
     id:"M431",
     enfermedad:"Espondilolistesis"
  },
  {
     id:"M432",
     enfermedad:"Otras fusiones columna vertebral"
  },
  {
     id:"M433",
     enfermedad:"Subluxacion atlanto"
  },
  {
     id:"M434",
     enfermedad:"Otras subluxaciones atlanto"
  },
  {
     id:"M435",
     enfermedad:"Otras subluxaciones vertebrales recurrentes"
  },
  {
     id:"M436",
     enfermedad:"Torticolis"
  },
  {
     id:"M438",
     enfermedad:"Otras dorsopatias deformantes de la columna vertebral especificadas"
  },
  {
     id:"M439",
     enfermedad:"Dorsopatia deformante, no especificada"
  },
  {
     id:"M45",
     enfermedad:"Espondilitis anquilosante"
  },
  {
     id:"M46",
     enfermedad:"Otras espondilopatias inflamatorias"
  },
  {
     id:"M460",
     enfermedad:"Entesopatia vertebral"
  },
  {
     id:"M461",
     enfermedad:"Sacroiliitis, no clasificada en otra parte"
  },
  {
     id:"M462",
     enfermedad:"Osteomielitis de vertebra"
  },
  {
     id:"M463",
     enfermedad:"Infeccion de disco intervertebral (piogena)"
  },
  {
     id:"M464",
     enfermedad:"Discitis, no especificada"
  },
  {
     id:"M465",
     enfermedad:"Otras espondilopatias infecciosas"
  },
  {
     id:"M468",
     enfermedad:"Otras espondilopatias inflamatorias especificadas"
  },
  {
     id:"M469",
     enfermedad:"Espondilopatia inflamatoria, no especificada"
  },
  {
     id:"M47",
     enfermedad:"Espondilosis"
  },
  {
     id:"M470",
     enfermedad:"Sindromes de compresion de la arteria espinal o vertebral anterior (g99.2)"
  },
  {
     id:"M471",
     enfermedad:"Otras espondilosis con mielopatia"
  },
  {
     id:"M472",
     enfermedad:"Otras espondilosis con radiculopatia"
  },
  {
     id:"M478",
     enfermedad:"Otras espondilosis"
  },
  {
     id:"M479",
     enfermedad:"Espondilosis, no especificada"
  },
  {
     id:"M48",
     enfermedad:"Otras espondilopatias"
  },
  {
     id:"M480",
     enfermedad:"Estenosis espinal"
  },
  {
     id:"M481",
     enfermedad:"Hiperostosis anquilosante [forestier]"
  },
  {
     id:"M482",
     enfermedad:"Espondilopatia interespinosa (vertebras \"\"en beso\"\")"
  },
  {
     id:"M483",
     enfermedad:"Espondilopatia traumatica"
  },
  {
     id:"M484",
     enfermedad:"Fractura de vertebra por fatiga"
  },
  {
     id:"M485",
     enfermedad:"Vertebra colapsada, no clasificada en otra parte"
  },
  {
     id:"M488",
     enfermedad:"Otras espondilopatias especificadas"
  },
  {
     id:"M489",
     enfermedad:"Espondilopatia , no especificada"
  },
  {
     id:"M49",
     enfermedad:"Espondilopatias en enfermedades clasificadas en otra parte"
  },
  {
     id:"M490",
     enfermedad:"Tuberculosis de la columna vertebral (a180)"
  },
  {
     id:"M491",
     enfermedad:"Espondilitis por brucelosis (a23."
  },
  {
     id:"M492",
     enfermedad:"Espondilitis por enterobacterias (a01"
  },
  {
     id:"M493",
     enfermedad:"Espondilopatia en otras enfermedades infecciosas y parasitarias clasificadas en otra parte"
  },
  {
     id:"M494",
     enfermedad:"Espondilopatia neuropatica"
  },
  {
     id:"M495",
     enfermedad:"Vertebra colapsada en enfermedades clasificadas en otra parte"
  },
  {
     id:"M498",
     enfermedad:"Espondilopatia en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"M50",
     enfermedad:"Trastornos del disco cervical"
  },
  {
     id:"M500",
     enfermedad:"Trastorno de disco cervical con mielopatia (g99.2)"
  },
  {
     id:"M501",
     enfermedad:"Trastorno de disco cervical con radiculopatia"
  },
  {
     id:"M502",
     enfermedad:"Otros desplazamientos del disco cervical"
  },
  {
     id:"M503",
     enfermedad:"Otras degeneraciones del disco cervical"
  },
  {
     id:"M508",
     enfermedad:"Otros trastornos del disco cervical"
  },
  {
     id:"M509",
     enfermedad:"Trastorno de disco cervical, no especificado"
  },
  {
     id:"M51",
     enfermedad:"Otros trastornos de los discos intervertebrales"
  },
  {
     id:"M510",
     enfermedad:"Trastornos de discos intervertebrales lumbares y otros, con mielopatia (g99.2)"
  },
  {
     id:"M511",
     enfermedad:"Trastorno de disco lumbar y otros, con radiculopatia"
  },
  {
     id:"M512",
     enfermedad:"Otros desplazamientos especificados de disco intervertebral"
  },
  {
     id:"M513",
     enfermedad:"Otras degeneraciones especificadas de disco intervertebral"
  },
  {
     id:"M514",
     enfermedad:"Nodulos de schmorl"
  },
  {
     id:"M518",
     enfermedad:"Otros trastornos especificados de los discos intervertebrales"
  },
  {
     id:"M519",
     enfermedad:"Trastornos de los discos intervertebrales, no especificado"
  },
  {
     id:"M53",
     enfermedad:"Otras dorsopatias no clasificadas en otra parte"
  },
  {
     id:"M530",
     enfermedad:"Sindrome cervicocraneal"
  },
  {
     id:"M531",
     enfermedad:"Sindrome cervicobraquial"
  },
  {
     id:"M532",
     enfermedad:"Inestabilidad de la columna vertebral"
  },
  {
     id:"M533",
     enfermedad:"Trastornos sacrococcigeos, no clasificados en otra parte"
  },
  {
     id:"M538",
     enfermedad:"Otras dorsopatias especificadas"
  },
  {
     id:"M539",
     enfermedad:"Dorsopatia, no especificada"
  },
  {
     id:"M54",
     enfermedad:"Dorsalgia"
  },
  {
     id:"M540",
     enfermedad:"Paniculitis que afecta regiones del cuello y de la espalda"
  },
  {
     id:"M541",
     enfermedad:"Radiculopatia"
  },
  {
     id:"M542",
     enfermedad:"Cervicalgia"
  },
  {
     id:"M543",
     enfermedad:"Ciatica"
  },
  {
     id:"M544",
     enfermedad:"Lumbago con ciatica"
  },
  {
     id:"M545",
     enfermedad:"Lumbago no especificado"
  },
  {
     id:"M546",
     enfermedad:"Dolor en la columna dorsal"
  },
  {
     id:"M548",
     enfermedad:"Otras dorsalgias"
  },
  {
     id:"M549",
     enfermedad:"Dorsalgia, no especificada"
  },
  {
     id:"M60",
     enfermedad:"Miositis"
  },
  {
     id:"M600",
     enfermedad:"Miositis infecciosa"
  },
  {
     id:"M601",
     enfermedad:"Miositis intersticial"
  },
  {
     id:"M602",
     enfermedad:"Granuloma por cuerpo extraño en tejido blando, no clasificado en otra parte"
  },
  {
     id:"M608",
     enfermedad:"Otras miositis"
  },
  {
     id:"M609",
     enfermedad:"Miositis, no especificada"
  },
  {
     id:"M61",
     enfermedad:"Calcificacion y osificacion del musculo"
  },
  {
     id:"M610",
     enfermedad:"Miositis osificante traumatica"
  },
  {
     id:"M611",
     enfermedad:"Miositis osificante progresiva"
  },
  {
     id:"M612",
     enfermedad:"Calcificacion y osificacion paralitica del musculo"
  },
  {
     id:"M613",
     enfermedad:"Calcificacion y osificacion de los musculos asociadas con quemaduras"
  },
  {
     id:"M614",
     enfermedad:"Otras calcificaciones del musculo"
  },
  {
     id:"M615",
     enfermedad:"Otras osificaciones del musculo"
  },
  {
     id:"M619",
     enfermedad:"Calcificacion y osificacion del musculo, no especificada"
  },
  {
     id:"M62",
     enfermedad:"Otros trastornos de los musculos"
  },
  {
     id:"M620",
     enfermedad:"Diastasis del musculo"
  },
  {
     id:"M621",
     enfermedad:"Otros desgarros (no traumaticos) del musculo"
  },
  {
     id:"M622",
     enfermedad:"Infarto isquemico del musculo"
  },
  {
     id:"M623",
     enfermedad:"Sindrome de inmovilidad (paraplejico)"
  },
  {
     id:"M624",
     enfermedad:"Contractura muscular"
  },
  {
     id:"M625",
     enfermedad:"Atrofia y desgaste musculares, no clasificados en otra parte"
  },
  {
     id:"M626",
     enfermedad:"Distension muscular"
  },
  {
     id:"M628",
     enfermedad:"Otros trastornos especificados de los musculos"
  },
  {
     id:"M629",
     enfermedad:"Trastorno muscular, no especificado"
  },
  {
     id:"M63",
     enfermedad:"Trastornos de los musculos en enfermedades clasificadas en otra parte"
  },
  {
     id:"M630",
     enfermedad:"Miositis en enfermedades bacterianas clasificadas en otra parte"
  },
  {
     id:"M631",
     enfermedad:"Miositis en infecciones por protozoarios y parasitos clasificadas en otra parte"
  },
  {
     id:"M632",
     enfermedad:"Miositis en enfermedades infecciosas clasificadas en otra parte"
  },
  {
     id:"M633",
     enfermedad:"Miositis en sarcoidosis (d86.8)"
  },
  {
     id:"M638",
     enfermedad:"Otros trastornos de los musculos en enfermedades clasificadas en otra parte"
  },
  {
     id:"M65",
     enfermedad:"Sinovitis y tenosinovitis"
  },
  {
     id:"M650",
     enfermedad:"Absceso de vaina tendinosa"
  },
  {
     id:"M651",
     enfermedad:"Otras (teno) sinovitis infecciosas"
  },
  {
     id:"M652",
     enfermedad:"Tendinitis calcificada"
  },
  {
     id:"M653",
     enfermedad:"Dedo en gatillo"
  },
  {
     id:"M654",
     enfermedad:"Tenosinovitis de estiloides radial [de quervain]"
  },
  {
     id:"M658",
     enfermedad:"Otras sinovitis y tenosinovitis"
  },
  {
     id:"M659",
     enfermedad:"Sinovitis y tenosinovitis, no especificada"
  },
  {
     id:"M66",
     enfermedad:"Ruptura espontanea de la sinovia y del tendon"
  },
  {
     id:"M660",
     enfermedad:"Ruptura de quiste sinovial popliteo"
  },
  {
     id:"M661",
     enfermedad:"Ruptura de la sinovia"
  },
  {
     id:"M662",
     enfermedad:"Ruptura espontanea de tendones extensores"
  },
  {
     id:"M663",
     enfermedad:"Ruptura espontanea de tendones flexores"
  },
  {
     id:"M664",
     enfermedad:"Ruptura espontanea de otros tendones"
  },
  {
     id:"M665",
     enfermedad:"Ruptura espontanea de tendon no especificado"
  },
  {
     id:"M67",
     enfermedad:"Otros trastornos de la sinovia y tendon"
  },
  {
     id:"M670",
     enfermedad:"Acortamiento del tendon de aquiles (adquirido)"
  },
  {
     id:"M671",
     enfermedad:"Otras contracturas de tendon (vaina)"
  },
  {
     id:"M672",
     enfermedad:"Hipertrofia sinovial, no clasificada en otra parte"
  },
  {
     id:"M673",
     enfermedad:"Sinovitis transitoria"
  },
  {
     id:"M674",
     enfermedad:"Ganglion"
  },
  {
     id:"M678",
     enfermedad:"Otros trastornos especificados de la sinovia y del tendon"
  },
  {
     id:"M679",
     enfermedad:"Hipertrofia sinovial y tendinoso, no especificado"
  },
  {
     id:"M68",
     enfermedad:"Trastornos de la sinovia y del tendon del enfermedades clasificadas en otra parte"
  },
  {
     id:"M680",
     enfermedad:"Sinovitis y tenosinovitis en enfermedades bacterianas clasificadas en otra parte"
  },
  {
     id:"M688",
     enfermedad:"Otros trastornos sinoviales y tendinosos en enfermedades clasificadas en otra parte"
  },
  {
     id:"M70",
     enfermedad:"Trastornos del tejido blando relacionados con el uso, el uso excesivo y la presion"
  },
  {
     id:"M700",
     enfermedad:"Sinovitis crepitante cronica de la mano y de la muñeca"
  },
  {
     id:"M701",
     enfermedad:"Bursitis de la mano"
  },
  {
     id:"M702",
     enfermedad:"Bursitis del olecranon"
  },
  {
     id:"M703",
     enfermedad:"Otras bursitis del codo"
  },
  {
     id:"M704",
     enfermedad:"Otras bursitis prerrotulianas"
  },
  {
     id:"M705",
     enfermedad:"Otras bursitis de la rodilla"
  },
  {
     id:"M706",
     enfermedad:"Bursitis del trocanter"
  },
  {
     id:"M707",
     enfermedad:"Otras bursitis de la cadera"
  },
  {
     id:"M708",
     enfermedad:"Otros trastornos de los tejidos blandos relacionados con el uso, el uso excesivo y la presion"
  },
  {
     id:"M709",
     enfermedad:"Trastorno no especificado de los tejidos blandos relacionado con el uso excesivo y la presion"
  },
  {
     id:"M71",
     enfermedad:"Otras bursopatias"
  },
  {
     id:"M710",
     enfermedad:"Absceso de la bolsa sinovial"
  },
  {
     id:"M711",
     enfermedad:"Otras bursitis infecciosas"
  },
  {
     id:"M712",
     enfermedad:"Quiste sinovial del hueco popliteo [de baker]"
  },
  {
     id:"M713",
     enfermedad:"Otros quistes de la bolsa serosa"
  },
  {
     id:"M714",
     enfermedad:"Deposito de calcio en la bolsa serosa"
  },
  {
     id:"M715",
     enfermedad:"Otras bursitis, no clasificadas en otra parte"
  },
  {
     id:"M718",
     enfermedad:"Otros trastornos especificados de la bolsa serosa"
  },
  {
     id:"M719",
     enfermedad:"Bursopatia, no especificada"
  },
  {
     id:"M72",
     enfermedad:"Trastornos fibroblasticos"
  },
  {
     id:"M720",
     enfermedad:"Fibromatosis de la aponeurosis palmar [dupuytren]"
  },
  {
     id:"M721",
     enfermedad:"Nodulos interfalangicos"
  },
  {
     id:"M722",
     enfermedad:"Fibromatosis de la aponeurosis plantar"
  },
  {
     id:"M723",
     enfermedad:"Fascitis nodular"
  },
  {
     id:"M724",
     enfermedad:"Fibromatosis seudosarcomatosa"
  },
  {
     id:"M725",
     enfermedad:"Fascitis, no clasificada en otra parte"
  },
  {
     id:"M728",
     enfermedad:"Otros trastornos fibroblasticos"
  },
  {
     id:"M729",
     enfermedad:"Trastorno fibroblastico, no especificado"
  },
  {
     id:"M73",
     enfermedad:"Trastornos del tejido blando en enfermedades clasificadas en otra parte"
  },
  {
     id:"M730",
     enfermedad:"Bursitis gonococica (a54.4)"
  },
  {
     id:"M731",
     enfermedad:"Bursitis sifilitica (a52.7)"
  },
  {
     id:"M738",
     enfermedad:"Otros trastornos de los tejidos blandos en enfermedades clasificadas en otra parte"
  },
  {
     id:"M75",
     enfermedad:"Lesiones del hombro"
  },
  {
     id:"M750",
     enfermedad:"Capsulitis adhesiva del hombro"
  },
  {
     id:"M751",
     enfermedad:"Sindrome de manguito rotatorio"
  },
  {
     id:"M752",
     enfermedad:"Tendinitis de biceps"
  },
  {
     id:"M753",
     enfermedad:"Tendinitis calcificante del hombro"
  },
  {
     id:"M754",
     enfermedad:"Sindrome de abduccion dolorosa del hombro"
  },
  {
     id:"M755",
     enfermedad:"Bursitis del hombro"
  },
  {
     id:"M758",
     enfermedad:"Otras lesiones del hombro"
  },
  {
     id:"M759",
     enfermedad:"Lesiones del hombro, no especificada"
  },
  {
     id:"M76",
     enfermedad:"Entesopatias del miembro inferior excluido el pie"
  },
  {
     id:"M760",
     enfermedad:"Tendinitis del gluteo"
  },
  {
     id:"M761",
     enfermedad:"Tendinitis del psoas"
  },
  {
     id:"M762",
     enfermedad:"Espolon de la cresta iliaca"
  },
  {
     id:"M763",
     enfermedad:"Sindrome del tendon del tensor de la fascia lata"
  },
  {
     id:"M764",
     enfermedad:"Bursitis tibial colateral [pellegrini"
  },
  {
     id:"M765",
     enfermedad:"Tendinitis rotuliana"
  },
  {
     id:"M766",
     enfermedad:"Tendinitis aquiliana"
  },
  {
     id:"M767",
     enfermedad:"Tendinitis peroneal"
  },
  {
     id:"M768",
     enfermedad:"Otras entesopatias del miembro inferior, excluido el pie"
  },
  {
     id:"M769",
     enfermedad:"Entesopatia del miembro inferior, no especificada"
  },
  {
     id:"M77",
     enfermedad:"Otras entesopatias"
  },
  {
     id:"M770",
     enfermedad:"Epicondilitis media"
  },
  {
     id:"M771",
     enfermedad:"Epicondilitis lateral"
  },
  {
     id:"M772",
     enfermedad:"Periartritis de la muñeca"
  },
  {
     id:"M773",
     enfermedad:"Espolon calcaneo"
  },
  {
     id:"M774",
     enfermedad:"Metatarsalgia"
  },
  {
     id:"M775",
     enfermedad:"Otras entesopatias del pie"
  },
  {
     id:"M778",
     enfermedad:"Otras entesopatias, no clasificadas en otra parte"
  },
  {
     id:"M779",
     enfermedad:"Entesopatia, no especificada"
  },
  {
     id:"M79",
     enfermedad:"Otros trastornos de los tejidos blandos no clasificados en otra parte"
  },
  {
     id:"M790",
     enfermedad:"Reumatismo, no especificado"
  },
  {
     id:"M791",
     enfermedad:"Mialgia"
  },
  {
     id:"M792",
     enfermedad:"Neuralgia y neuritis, no especificadas"
  },
  {
     id:"M793",
     enfermedad:"Paniculitis, no especificada"
  },
  {
     id:"M794",
     enfermedad:"Hipertrofia de paquete adiposo (infrarrotuliano)"
  },
  {
     id:"M795",
     enfermedad:"Cuerpo extraño residual en tejido blando"
  },
  {
     id:"M796",
     enfermedad:"Dolor en miembro"
  },
  {
     id:"M798",
     enfermedad:"Otros trastornos especificados de los tejidos blandos"
  },
  {
     id:"M799",
     enfermedad:"Trastorno de los tejidos blandos, no especificado"
  },
  {
     id:"M80",
     enfermedad:"Osteoporosis con fractura patologica"
  },
  {
     id:"M800",
     enfermedad:"Osteoporosis postmenopausica, con fractura patologica"
  },
  {
     id:"M801",
     enfermedad:"Osteoporosis postooforectomia, con fractura patologica"
  },
  {
     id:"M802",
     enfermedad:"Osteoporosis por desuso, con fractura patologica"
  },
  {
     id:"M803",
     enfermedad:"Osteoporosis por malabsorcion postquirurgica, con fractura patologica"
  },
  {
     id:"M804",
     enfermedad:"Osteoporosis inducida por drogas, con fractura patologica"
  },
  {
     id:"M805",
     enfermedad:"Osteoporosis idiopatica, con fractura patologica"
  },
  {
     id:"M808",
     enfermedad:"Otras osteoporosis, con fractura patologica"
  },
  {
     id:"M809",
     enfermedad:"Osteoporosis no especificada, con fractura patologica"
  },
  {
     id:"M81",
     enfermedad:"Osteoporosis sin fractura patologica"
  },
  {
     id:"M810",
     enfermedad:"Osteoporosis postmenopausica, sin fractura patologica"
  },
  {
     id:"M811",
     enfermedad:"Osteoporosis postooforectomia, sin fractura patologica"
  },
  {
     id:"M812",
     enfermedad:"Osteoporosis por desuso, sin fractura patologica"
  },
  {
     id:"M813",
     enfermedad:"Osteoporosis por malabsorcion postquirurgica, sin fractura patologica"
  },
  {
     id:"M814",
     enfermedad:"Osteoporosis inducida por drogas, sin fractura patologica"
  },
  {
     id:"M815",
     enfermedad:"Osteoporosis idiopatica, sin fractura patologica"
  },
  {
     id:"M816",
     enfermedad:"Osteoporosis localizada [lequesne], sin fractura patologica"
  },
  {
     id:"M818",
     enfermedad:"Otras osteoporosis, sin fractura patologica"
  },
  {
     id:"M819",
     enfermedad:"Osteoporosis no especificada, sin fractura patologica"
  },
  {
     id:"M82",
     enfermedad:"Osteoporosis en enfermedades clasificadas en otra parte"
  },
  {
     id:"M820",
     enfermedad:"Osteoporosis en mielomatosis multiple (c90.0)"
  },
  {
     id:"M821",
     enfermedad:"Osteoporosis en trastornos endocrinos (e00"
  },
  {
     id:"M828",
     enfermedad:"Osteoporosis en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"M83",
     enfermedad:"Osteomalacia del adulto"
  },
  {
     id:"M830",
     enfermedad:"Osteomalacia puerperal"
  },
  {
     id:"M831",
     enfermedad:"Osteomalacia senil"
  },
  {
     id:"M832",
     enfermedad:"Osteomalacia del adulto debida a malabsorcion"
  },
  {
     id:"M833",
     enfermedad:"Osteomalacia del adulto debida a desnutricion"
  },
  {
     id:"M834",
     enfermedad:"Enfermedad de los huesos por aluminio"
  },
  {
     id:"M835",
     enfermedad:"Otras osteomalacias del adulto inducidas por drogas"
  },
  {
     id:"M838",
     enfermedad:"Otras osteomalacias del adulto"
  },
  {
     id:"M839",
     enfermedad:"Osteomalacia del adulto, no especificada"
  },
  {
     id:"M84",
     enfermedad:"Trastornos de la continuidad del hueso"
  },
  {
     id:"M840",
     enfermedad:"Consolidacion defectuosa de fractura"
  },
  {
     id:"M841",
     enfermedad:"Falta de consolidacion de fractura [seudoartrosis]"
  },
  {
     id:"M842",
     enfermedad:"Consolidacion retardada de fractura"
  },
  {
     id:"M843",
     enfermedad:"Fractura por tension, no clasificada en otra parte"
  },
  {
     id:"M844",
     enfermedad:"Fractura patologica, no clasificada en otra parte"
  },
  {
     id:"M848",
     enfermedad:"Otros trastornos de la continuidad del hueso"
  },
  {
     id:"M849",
     enfermedad:"Trastorno de la continuidad del hueso, no especificado"
  },
  {
     id:"M85",
     enfermedad:"Otros trastornos de la densidad y de la estructura oseas"
  },
  {
     id:"M850",
     enfermedad:"Displasia fibrosa (monostotica)"
  },
  {
     id:"M851",
     enfermedad:"Fluorosis del esqueleto"
  },
  {
     id:"M852",
     enfermedad:"Hiperostosis del craneo"
  },
  {
     id:"M853",
     enfermedad:"Osteitis condensante"
  },
  {
     id:"M854",
     enfermedad:"Quiste oseo solitario"
  },
  {
     id:"M855",
     enfermedad:"Quiste oseo aneurismatico"
  },
  {
     id:"M856",
     enfermedad:"Otros quistes oseos"
  },
  {
     id:"M858",
     enfermedad:"Otros trastornos especificados de la densidad y de la estructura oseas"
  },
  {
     id:"M859",
     enfermedad:"Trastorno de la densidad y de la estructura oseas, no especificado"
  },
  {
     id:"M86",
     enfermedad:"Osteomielitis"
  },
  {
     id:"M860",
     enfermedad:"Osteomielitis hematogena aguda"
  },
  {
     id:"M861",
     enfermedad:"Otras osteomielitis agudas"
  },
  {
     id:"M862",
     enfermedad:"Osteomielitis subaguda"
  },
  {
     id:"M863",
     enfermedad:"Osteomielitis multifocal cronica"
  },
  {
     id:"M864",
     enfermedad:"Osteomielitis cronica con drenaje del seno"
  },
  {
     id:"M865",
     enfermedad:"Otras osteomielitis hematogenas cronicas"
  },
  {
     id:"M866",
     enfermedad:"Otras osteomielitis cronicas"
  },
  {
     id:"M868",
     enfermedad:"Otras osteomielitis"
  },
  {
     id:"M869",
     enfermedad:"Osteomielitis, no especificada"
  },
  {
     id:"M87",
     enfermedad:"Osteonecrosis"
  },
  {
     id:"M870",
     enfermedad:"Necrosis aseptica idiopatica osea"
  },
  {
     id:"M871",
     enfermedad:"Osteonecrosis debida a drogas"
  },
  {
     id:"M872",
     enfermedad:"Osteonecrosis debida a traumatismo previo"
  },
  {
     id:"M873",
     enfermedad:"Otras osteonecrosis secundarias"
  },
  {
     id:"M878",
     enfermedad:"Otras osteonecrosis"
  },
  {
     id:"M879",
     enfermedad:"Osteonecrosis, no especificada"
  },
  {
     id:"M88",
     enfermedad:"Enfermedades de paget de los huesos [osteitis deformante]"
  },
  {
     id:"M880",
     enfermedad:"Enfermedad de paget del craneo"
  },
  {
     id:"M888",
     enfermedad:"Enfermedad de paget de otros huesos"
  },
  {
     id:"M889",
     enfermedad:"Enfermedad osea de paget, huesos no especificados"
  },
  {
     id:"M89",
     enfermedad:"Otros trastornos del hueso"
  },
  {
     id:"M890",
     enfermedad:"Algoneurodistrofia"
  },
  {
     id:"M891",
     enfermedad:"Detencion del crecimiento epifisario"
  },
  {
     id:"M892",
     enfermedad:"Otros trastornos del desarrollo y crecimiento oseo"
  },
  {
     id:"M893",
     enfermedad:"Hipertrofia del hueso"
  },
  {
     id:"M894",
     enfermedad:"Otras osteoartropatias hipertroficas"
  },
  {
     id:"M895",
     enfermedad:"Osteolisis"
  },
  {
     id:"M896",
     enfermedad:"Osteopatia a consecuencia de poliomielitis"
  },
  {
     id:"M898",
     enfermedad:"Otros trastornos especificados del hueso"
  },
  {
     id:"M899",
     enfermedad:"Trastorno del hueso, no especificado"
  },
  {
     id:"M90",
     enfermedad:"Osteopatias en enfermedades clasificadas en otra parte"
  },
  {
     id:"M900",
     enfermedad:"Tuberculosis oseas (a18.0)"
  },
  {
     id:"M901",
     enfermedad:"Periostitis en otras enfermedades infecciosas clasificadas en otra parte"
  },
  {
     id:"M902",
     enfermedad:"Osteopatia en otras enfermedades infecciosas clasificadas en otra parte"
  },
  {
     id:"M903",
     enfermedad:"Osteonecrosis en la enfermedad causada por descompresion (t70.3)"
  },
  {
     id:"M904",
     enfermedad:"Osteonecrosis debida a hemoglobinopatia (d50"
  },
  {
     id:"M905",
     enfermedad:"Osteonecrosis en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"M906",
     enfermedad:"Osteitis deformante en enfermedad neoplasica (c00"
  },
  {
     id:"M907",
     enfermedad:"Fractura osea en enfermedad neoplasica (c00"
  },
  {
     id:"M908",
     enfermedad:"Osteopatia en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"M91",
     enfermedad:"Osteocondrosis juvenil de la cadera y de la pelvis"
  },
  {
     id:"M910",
     enfermedad:"Osteocondrosis juvenil de la pelvis"
  },
  {
     id:"M911",
     enfermedad:"Osteocondrosis juvenil de la cabeza del femur [legg"
  },
  {
     id:"M912",
     enfermedad:"Coxa plana"
  },
  {
     id:"M913",
     enfermedad:"Pseudocoxalgia"
  },
  {
     id:"M918",
     enfermedad:"Otras osteocondrosis juveniles de la cadera y de la pelvis"
  },
  {
     id:"M919",
     enfermedad:"Osteocondrosis juvenil de la cadera y de la pelvis, sin otra especificacion"
  },
  {
     id:"M92",
     enfermedad:"Otras osteocondrosis juveniles"
  },
  {
     id:"M920",
     enfermedad:"Osteocondrosis juvenil del humero"
  },
  {
     id:"M921",
     enfermedad:"Osteocondrosis juvenil del cubito y del radio"
  },
  {
     id:"M922",
     enfermedad:"Osteocondrosis juvenil de la mano"
  },
  {
     id:"M923",
     enfermedad:"Otras osteocondrosis juveniles del miembro superior"
  },
  {
     id:"M924",
     enfermedad:"Osteocondrosis juvenil de la rotula"
  },
  {
     id:"M925",
     enfermedad:"Osteocondrosis juvenil de la tibia y del perone"
  },
  {
     id:"M926",
     enfermedad:"Osteocondrosis juvenil del tarso"
  },
  {
     id:"M927",
     enfermedad:"Osteocondrosis juvenil del metatarso"
  },
  {
     id:"M928",
     enfermedad:"Otras osteocondrosis juveniles especificadas"
  },
  {
     id:"M929",
     enfermedad:"Osteocondrosis juvenil, no especificada"
  },
  {
     id:"M93",
     enfermedad:"Otras osteocondropatias"
  },
  {
     id:"M930",
     enfermedad:"Deslizamiento de la epifisis femoral superior (no traumatico)"
  },
  {
     id:"M931",
     enfermedad:"Enfermedad de kienbÃ–ck del adulto"
  },
  {
     id:"M932",
     enfermedad:"Osteocondrosis disecante"
  },
  {
     id:"M938",
     enfermedad:"Otras osteocondropatias especificadas"
  },
  {
     id:"M939",
     enfermedad:"Osteocondropatia, no especificada"
  },
  {
     id:"M94",
     enfermedad:"Otros trastornos del cartilago"
  },
  {
     id:"M940",
     enfermedad:"Sindrome de la articulacion condrocostal [tietze]"
  },
  {
     id:"M941",
     enfermedad:"Policondritis recidivante"
  },
  {
     id:"M942",
     enfermedad:"Condromalacia"
  },
  {
     id:"M943",
     enfermedad:"Condrolisis"
  },
  {
     id:"M948",
     enfermedad:"Otros trastornos especificados del cartilago"
  },
  {
     id:"M949",
     enfermedad:"Trastorno del cartilago, no especificado"
  },
  {
     id:"M95",
     enfermedad:"Otras deformidades adquiridas del sistema osteomuscular y del tejido conjuntivo"
  },
  {
     id:"M950",
     enfermedad:"Deformidad adquirida de la nariz"
  },
  {
     id:"M951",
     enfermedad:"Oreja en coliflor"
  },
  {
     id:"M952",
     enfermedad:"Otras deformidades adquiridas de la cabeza"
  },
  {
     id:"M953",
     enfermedad:"Deformidad adquirida del cuello"
  },
  {
     id:"M954",
     enfermedad:"Deformidad adquirida de costillas y torax"
  },
  {
     id:"M955",
     enfermedad:"Deformidad adquirida de la pelvis"
  },
  {
     id:"M958",
     enfermedad:"Otras deformidades adquirida especificadas del sistema osteomuscular"
  },
  {
     id:"M959",
     enfermedad:"Deformidad adquirida del sistema osteomuscular, no especificada"
  },
  {
     id:"M96",
     enfermedad:"Trastornos osteomusculares consecutivos a procedimientos, no clasificados en otra parte"
  },
  {
     id:"M960",
     enfermedad:"Seudoartrosis consecutiva a fusion o artrodesis"
  },
  {
     id:"M961",
     enfermedad:"Sindrome postlaminectomia, no clasificado en otra parte"
  },
  {
     id:"M962",
     enfermedad:"Cifosis postradiacion"
  },
  {
     id:"M963",
     enfermedad:"Cifosis postlaminectomia"
  },
  {
     id:"M964",
     enfermedad:"Lordosis postquirurgica"
  },
  {
     id:"M965",
     enfermedad:"Escoliosis postrradiacion"
  },
  {
     id:"M966",
     enfermedad:"Fractura de hueso posterior a insercion o implante ortopedico, protesis articular o placa osea"
  },
  {
     id:"M968",
     enfermedad:"Otros trastornos osteomusculares consecutivos a procedimientos"
  },
  {
     id:"M969",
     enfermedad:"Trastornos osteomusculares no especificados consecutivos a procedimientos"
  },
  {
     id:"M99",
     enfermedad:"Lesiones biomecanicas no clasificadas en otra parte"
  },
  {
     id:"M990",
     enfermedad:"Disfuncion segmental o somatica"
  },
  {
     id:"M991",
     enfermedad:"Complejo de subluxacion (vertebral)"
  },
  {
     id:"M992",
     enfermedad:"Subluxacion con estenosis del canal neural"
  },
  {
     id:"M993",
     enfermedad:"Estenosis osea del canal neural"
  },
  {
     id:"M994",
     enfermedad:"Estenosis del canal neural por tejido conjuntivo"
  },
  {
     id:"M995",
     enfermedad:"Estenosis del canal neural por disco intervertebral"
  },
  {
     id:"M996",
     enfermedad:"Estenosis osea y subluxacion de los agujeros intervertebrales"
  },
  {
     id:"M997",
     enfermedad:"Estenosis de los agujeros intervertebrales por tejido conjuntivo o por disco intervertebral"
  },
  {
     id:"M998",
     enfermedad:"Otras lesiones biomecanicas"
  },
  {
     id:"M999",
     enfermedad:"Lesion biomecanica, no especificada"
  },
  {
     id:"N00",
     enfermedad:"Sindrome nefritico agudo"
  },
  {
     id:"N000",
     enfermedad:"Sindrome nefritico agudo: anomalia glomerular minima"
  },
  {
     id:"N001",
     enfermedad:"Sindrome nefritico agudo: lesiones glomerulares focales y segmentarias"
  },
  {
     id:"N002",
     enfermedad:"Sindrome nefritico agudo: glomerulonefritis membranosa difusa"
  },
  {
     id:"N003",
     enfermedad:"Sindrome nefritico agudo: glomerulonefritis proliferativa mesangial difusa"
  },
  {
     id:"N004",
     enfermedad:"Sindrome nefritico agudo: glomerulonefritis proliferativa endocapilar difusa"
  },
  {
     id:"N005",
     enfermedad:"Sindrome nefritico agudo: glomerulonefritis mesangiocapilar difusa"
  },
  {
     id:"N006",
     enfermedad:"Sindrome nefritico agudo: enfermedad por depositos densos"
  },
  {
     id:"N007",
     enfermedad:"Sindrome nefritico agudo: glomerulonefritis difusa en media luna"
  },
  {
     id:"N008",
     enfermedad:"Sindrome nefritico agudo: otras"
  },
  {
     id:"N009",
     enfermedad:"Sindrome nefritico agudo: no especificada"
  },
  {
     id:"N01",
     enfermedad:"Sindrome nefritico rapidamente progresivo"
  },
  {
     id:"N010",
     enfermedad:"Sindrome nefritico rapidamente progresivo: anomalia glomerular minima"
  },
  {
     id:"N011",
     enfermedad:"Sindrome nefritico rapidamente progresivo: lesiones glomerulares focales y segmentarias"
  },
  {
     id:"N012",
     enfermedad:"Sindrome nefritico rapidamente progresivo: glomerulonefritis membranosa difusa"
  },
  {
     id:"N013",
     enfermedad:"Sindrome nefritico rapidamente progresivo: glomerulonefritis proliferativa mesangial difusa"
  },
  {
     id:"N014",
     enfermedad:"Sindrome nefritico rapidamente progresivo: glomerulonefritis proliferativa endocapilar difusa"
  },
  {
     id:"N015",
     enfermedad:"Sindrome nefritico rapidamente progresivo: glomerulonefritis mesangiocapilar difusa"
  },
  {
     id:"N016",
     enfermedad:"Sindrome nefritico rapidamente progresivo: enfermedad por depositos densos"
  },
  {
     id:"N017",
     enfermedad:"Sindrome nefritico rapidamente progresivo: glomerulonefritis difusa en media luna"
  },
  {
     id:"N018",
     enfermedad:"Sindrome nefritico rapidamente progresivo: otras"
  },
  {
     id:"N019",
     enfermedad:"Sindrome nefritico rapidamente progresivo: no especificada"
  },
  {
     id:"N02",
     enfermedad:"Hematuria recurrente y persistente"
  },
  {
     id:"N020",
     enfermedad:"Hematuria recurrente y persistente: anomalia glomerular minima"
  },
  {
     id:"N021",
     enfermedad:"Hematuria recurrente y persistente: lesiones glomerulares focales y segmentarias"
  },
  {
     id:"N022",
     enfermedad:"Hematuria recurrente y persistente: glomerulonefritis membranosa difusa"
  },
  {
     id:"N023",
     enfermedad:"Hematuria recurrente y persistente: glomerulonefritis proliferativa mesangial difusa"
  },
  {
     id:"N024",
     enfermedad:"Hematuria recurrente y persistente: glomerulonefritis proliferativa endocapilar difusa"
  },
  {
     id:"N025",
     enfermedad:"Hematuria recurrente y persistente: glomerulonefritis mesangiocapilar difusa"
  },
  {
     id:"N026",
     enfermedad:"Hematuria recurrente y persistente: enfermedad por depositos densos"
  },
  {
     id:"N027",
     enfermedad:"Hematuria recurrente y persistente: glomerulonefritis difusa en media luna"
  },
  {
     id:"N028",
     enfermedad:"Hematuria recurrente y persistente: otras"
  },
  {
     id:"N029",
     enfermedad:"Hematuria recurrente y persistente: no especificada"
  },
  {
     id:"N03",
     enfermedad:"Sindrome nefritico cronico"
  },
  {
     id:"N030",
     enfermedad:"Sindrome nefritico cronico: anomalia glomerular minima"
  },
  {
     id:"N031",
     enfermedad:"Sindrome nefritico cronico: lesiones glomerulares focales y segmentarias"
  },
  {
     id:"N032",
     enfermedad:"Sindrome nefritico cronico: glomerulonefritis membranosa difusa"
  },
  {
     id:"N033",
     enfermedad:"Sindrome nefritico cronico: glomerulonefritis proliferativa mesangial difusa"
  },
  {
     id:"N034",
     enfermedad:"Sindrome nefritico cronico: glomerulonefritis proliferativa endocapilar difusa"
  },
  {
     id:"N035",
     enfermedad:"Sindrome nefritico cronico: glomerulonefritis mesangiocapilar difusa"
  },
  {
     id:"N036",
     enfermedad:"Sindrome nefritico cronico: enfermedad por depositos densos"
  },
  {
     id:"N037",
     enfermedad:"Sindrome nefritico cronico: glomerulonefritis difusa en media luna"
  },
  {
     id:"N038",
     enfermedad:"Sindrome nefritico cronico: otras"
  },
  {
     id:"N039",
     enfermedad:"Sindrome nefritico cronico: no especificada"
  },
  {
     id:"N04",
     enfermedad:"Sindrome nefrotico"
  },
  {
     id:"N040",
     enfermedad:"Sindrome nefrotico: anomalia glomerular minima"
  },
  {
     id:"N041",
     enfermedad:"Sindrome nefrotico: lesiones glomerulares focales y segmentarias"
  },
  {
     id:"N042",
     enfermedad:"Sindrome nefrotico: glomerulonefritis membranosa difusa"
  },
  {
     id:"N043",
     enfermedad:"Sindrome nefrotico: glomerulonefritis proliferativa mesangial difusa"
  },
  {
     id:"N044",
     enfermedad:"Sindrome nefrotico: glomerulonefritis proliferativa endocapilar difusa"
  },
  {
     id:"N045",
     enfermedad:"Sindrome nefrotico: glomerulonefritis mesangiocapilar difusa"
  },
  {
     id:"N046",
     enfermedad:"Sindrome nefrotico: enfermedad por depositos densos"
  },
  {
     id:"N047",
     enfermedad:"Sindrome nefrotico: glomerulonefritis difusa en media luna"
  },
  {
     id:"N048",
     enfermedad:"Sindrome nefrotico: otras"
  },
  {
     id:"N049",
     enfermedad:"Sindrome nefrotico: no especificada"
  },
  {
     id:"N05",
     enfermedad:"Sindrome nefritico no especificado"
  },
  {
     id:"N050",
     enfermedad:"Sindrome nefritico no especificado: anomalia glomerular minima"
  },
  {
     id:"N051",
     enfermedad:"Sindrome nefritico no especificado: lesiones glomerulares focales y segmentarias"
  },
  {
     id:"N052",
     enfermedad:"Sindrome nefritico no especificado: glomerulonefritis membranosa difusa"
  },
  {
     id:"N053",
     enfermedad:"Sindrome nefritico no especificado: glomerulonefritis proliferativa mesangial difusa"
  },
  {
     id:"N054",
     enfermedad:"Sindrome nefritico no especificado: glomerulonefritis proliferativa endocapilar difusa"
  },
  {
     id:"N055",
     enfermedad:"Sindrome nefritico no especificado: glomerulonefritis mesangiocapilar difusa"
  },
  {
     id:"N056",
     enfermedad:"Sindrome nefritico no especificado: enfermedad por depositos densos"
  },
  {
     id:"N057",
     enfermedad:"Sindrome nefritico no especificado: glomerulonefritis difusa en media luna"
  },
  {
     id:"N058",
     enfermedad:"Sindrome nefritico no especificado: otras"
  },
  {
     id:"N059",
     enfermedad:"Sindrome nefritico no especificado: no especificada"
  },
  {
     id:"N06",
     enfermedad:"Proteinuria aislada con lesion morfologica especada"
  },
  {
     id:"N060",
     enfermedad:"Proteinuria aislada con lesion morfologica especificada: anomalia glomerular minima"
  },
  {
     id:"N061",
     enfermedad:"Proteinuria aislada con lesion morfologica especificada lesiones glomerulares focales y segmentarias"
  },
  {
     id:"N062",
     enfermedad:"Proteinuria aislada con lesion morfologica especificada: glomerulonefritis membranosa difusa"
  },
  {
     id:"N063",
     enfermedad:"Proteinuria aislada con lesion morfologica especificada: glomerulonefritis proliferativa mesangial difusa"
  },
  {
     id:"N064",
     enfermedad:"Proteinuria aislada con lesion morfologica especificada: glomerulonefritis proliferativa endocapilar difusa"
  },
  {
     id:"N065",
     enfermedad:"Proteinuria aislada con lesion morfologica especificada: glomerulonefritis mesangiocapilar difusa"
  },
  {
     id:"N066",
     enfermedad:"Proteinuria aislada con lesion morfologica especificada: enfermedad por depositos densos"
  },
  {
     id:"N067",
     enfermedad:"Proteinuria aislada con lesion morfologica especificada: glomerulonefritis difusa en media luna"
  },
  {
     id:"N068",
     enfermedad:"Proteinuria aislada con lesion morfologica especificada: otras"
  },
  {
     id:"N069",
     enfermedad:"Proteinuria aislada con lesion morfologica especificada: no especificada"
  },
  {
     id:"N07",
     enfermedad:"Nefropatia hereditaria no clasificada en otra parte"
  },
  {
     id:"N070",
     enfermedad:"Nefropatia hereditaria, no clasificada en otra parte: anomalia glomerular minima"
  },
  {
     id:"N071",
     enfermedad:"Nefropatia hereditaria, no clasificada en otra parte: lesiones glomerulares focales y segmentarias"
  },
  {
     id:"N072",
     enfermedad:"Nefropatia hereditaria, no clasificada en otra parte: glomerulonefritis membranosa difusa"
  },
  {
     id:"N073",
     enfermedad:"Nefropatia hereditaria, no clasificada en otra parte: glomerulonefritis proliferativa mesangial difusa"
  },
  {
     id:"N074",
     enfermedad:"Nefropatia hereditaria, no clasificada en otra parte: glomerulonefritis proliferativa endocapilar difusa"
  },
  {
     id:"N075",
     enfermedad:"Nefropatia hereditaria, no clasificada en otra parte: glomerulonefritis mesangiocapilar difusa"
  },
  {
     id:"N076",
     enfermedad:"Nefropatia hereditaria, no clasificada en otra parte: enfermedad por depositos densos"
  },
  {
     id:"N077",
     enfermedad:"Nefropatia hereditaria, no clasificada en otra parte: glomerulonefritis difusa en media luna"
  },
  {
     id:"N078",
     enfermedad:"Nefropatia hereditaria, no clasificada en otra parte: otras"
  },
  {
     id:"N079",
     enfermedad:"Nefropatia hereditaria, no clasificada en otra parte: no especificada"
  },
  {
     id:"N08",
     enfermedad:"Trastornos glomerulares en enfermedades clasificadas otra parte"
  },
  {
     id:"N080",
     enfermedad:"Trastornos glomerulares en enfermedades infecciosas y parasitarias clasificadas en otra parte"
  },
  {
     id:"N081",
     enfermedad:"Trastornos glomerulares en enfermedades neoplasicas"
  },
  {
     id:"N082",
     enfermedad:"Trastornos glomerulares en enfermedades de la sangre y otros trastornos que afectan al mecanismo inmunitario"
  },
  {
     id:"N083",
     enfermedad:"Trastornos glomerulares en diabetes mellitus (e10"
  },
  {
     id:"N084",
     enfermedad:"Trastornos glomerulares en otras enfermedades endocrinas, nutricionales y metabolicas"
  },
  {
     id:"N085",
     enfermedad:"Trastornos glomerulares en trastornos sistemicos del tejido conjuntivo"
  },
  {
     id:"N088",
     enfermedad:"Trastornos glomerulares en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"N10",
     enfermedad:"Nefritis tubulointersticial aguda"
  },
  {
     id:"N11",
     enfermedad:"Nefritis tubulointersticial cronica"
  },
  {
     id:"N110",
     enfermedad:"Pielonefritis cronica no obstructiva asociada con reflujo"
  },
  {
     id:"N111",
     enfermedad:"Pielonefritis cronica obstructiva"
  },
  {
     id:"N118",
     enfermedad:"Otras nefritis tubulointersticiales cronicas"
  },
  {
     id:"N119",
     enfermedad:"Nefritis tubulointersticial cronica, sin otra especificacion"
  },
  {
     id:"N12",
     enfermedad:"Nefritis tubulointerst no especificada como aguda o cronica"
  },
  {
     id:"N13",
     enfermedad:"Uropatia obstructiva y por reflujo"
  },
  {
     id:"N130",
     enfermedad:"Hidronefrosis con obstruccion de la union uretero"
  },
  {
     id:"N131",
     enfermedad:"Hidronefrosis con estrechez ureteral, no clasificada en otra parte"
  },
  {
     id:"N132",
     enfermedad:"Hidronefrosis con obstruccion por calculos del riñon y del ureter"
  },
  {
     id:"N133",
     enfermedad:"Otras hidronefrosis y las no especificadas"
  },
  {
     id:"N134",
     enfermedad:"Hidroureter"
  },
  {
     id:"N135",
     enfermedad:"Torsion y estrechez del ureter sin hidronefrosis"
  },
  {
     id:"N136",
     enfermedad:"Pionefrosis"
  },
  {
     id:"N137",
     enfermedad:"Uropatia asociada con reflujo vesicoureteral"
  },
  {
     id:"N138",
     enfermedad:"Otras uropatias obstructivas por reflujo"
  },
  {
     id:"N139",
     enfermedad:"Uropatia obstructiva por reflujo, sin otra especificacion"
  },
  {
     id:"N14",
     enfermedad:"Afecciones tubulares y tubulointersticiales inducidas por drogas y por metales pesados"
  },
  {
     id:"N140",
     enfermedad:"Nefropatia inducida por analgesicos"
  },
  {
     id:"N141",
     enfermedad:"Nefropatia inducida por otras drogas, medicamentos y sustancias biologicas"
  },
  {
     id:"N142",
     enfermedad:"Nefropatia inducida por drogas, medicamentos y sustancias biologicas no especificadas"
  },
  {
     id:"N143",
     enfermedad:"Nefropatia inducida por metales pesados"
  },
  {
     id:"N144",
     enfermedad:"Nefropatia toxica, no especificada en otra parte"
  },
  {
     id:"N15",
     enfermedad:"Otras enfermedades renales tubulointersticiales"
  },
  {
     id:"N150",
     enfermedad:"Nefropatia de los balcanes"
  },
  {
     id:"N151",
     enfermedad:"Absceso renal y perirrenal"
  },
  {
     id:"N158",
     enfermedad:"Otras enfermedades renales tubulointersticiales especificadas"
  },
  {
     id:"N159",
     enfermedad:"Enfermedad renal tubulointersticial, no especificada"
  },
  {
     id:"N16",
     enfermedad:"Trastornos renales tubulointersticiales en enfermedades clasificadas en otra parte"
  },
  {
     id:"N160",
     enfermedad:"Trastornos renales tubulointersticiales en enfermedades infecciosas y parasitarias clasificadas en otra patrte"
  },
  {
     id:"N161",
     enfermedad:"Trastornos renales tubulointersticiales en enfermedades neoplasicas"
  },
  {
     id:"N162",
     enfermedad:"Trastornos renales tubulointersticiales en enfermedades de la sangre y en trastornos que afectan el mecanismo inmunitario"
  },
  {
     id:"N163",
     enfermedad:"Trastornos renales tubulointersticiales en enfermedades metabolicas"
  },
  {
     id:"N164",
     enfermedad:"Trastornos renales tubulointersticiales en enfermedades del tejido conjuntivo"
  },
  {
     id:"N165",
     enfermedad:"Trastornos renales tubulointersticiales en rechazo de trasplante (t86."
  },
  {
     id:"N168",
     enfermedad:"Trastornos renales tubulointersticiales en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"N17",
     enfermedad:"Insuficiencia renal aguda"
  },
  {
     id:"N170",
     enfermedad:"Insuficiencia renal aguda con necrosis tubular"
  },
  {
     id:"N171",
     enfermedad:"Insuficiencia renal aguda con necrosis cortical aguda"
  },
  {
     id:"N172",
     enfermedad:"Insuficiencia renal aguda con necrosis medular"
  },
  {
     id:"N178",
     enfermedad:"Otras insuficiencias renales agudas"
  },
  {
     id:"N179",
     enfermedad:"Insuficiencia renal aguda, no especificada"
  },
  {
     id:"N18",
     enfermedad:"Insuficiencia renal cronica"
  },
  {
     id:"N180",
     enfermedad:"Insuficiencia renal terminal"
  },
  {
     id:"N188",
     enfermedad:"Otras insuficiencias renales cronicas"
  },
  {
     id:"N189",
     enfermedad:"Insuficiencia renal cronica, no especificada"
  },
  {
     id:"N19",
     enfermedad:"Insuficiencia renal no especificada"
  },
  {
     id:"N20",
     enfermedad:"Calculo del rinon y del ureter"
  },
  {
     id:"N200",
     enfermedad:"Calculo del riñon"
  },
  {
     id:"N201",
     enfermedad:"Calculo del ureter"
  },
  {
     id:"N202",
     enfermedad:"Calculo del riñon con calculo del ureter"
  },
  {
     id:"N209",
     enfermedad:"Calculo urinario, no especificado"
  },
  {
     id:"N21",
     enfermedad:"Calculo de las vias urinarias inferiores"
  },
  {
     id:"N210",
     enfermedad:"Calculo en la vejiga"
  },
  {
     id:"N211",
     enfermedad:"Calculo en la uretra"
  },
  {
     id:"N218",
     enfermedad:"Otros calculos de las vias urinarias inferiores"
  },
  {
     id:"N219",
     enfermedad:"Calculo de las vias urinarias inferiores, no especificado"
  },
  {
     id:"N22",
     enfermedad:"Calculo de las vias urinarias en enfermedades clasificadas otra parte"
  },
  {
     id:"N220",
     enfermedad:"Litiasis urinaria en esquistosomiasis [bilharziasis] (b65."
  },
  {
     id:"N228",
     enfermedad:"Calculo de las vias urinarias en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"N23",
     enfermedad:"Colico renal no especificado"
  },
  {
     id:"N25",
     enfermedad:"Trastornos resultantes de la funcion tubular renal alterada"
  },
  {
     id:"N250",
     enfermedad:"Osteodistrofia renal"
  },
  {
     id:"N251",
     enfermedad:"Diabetes insipida nefrogena"
  },
  {
     id:"N258",
     enfermedad:"Otros trastornos resultantes de la funcion tubular renal alterada"
  },
  {
     id:"N259",
     enfermedad:"Trastorno no especificado, resultante de la funcion tubular renal alterada"
  },
  {
     id:"N26",
     enfermedad:"Rinon contraido no especificado"
  },
  {
     id:"N27",
     enfermedad:"Rinon pequeno de causa desconocida"
  },
  {
     id:"N270",
     enfermedad:"Riñon pequeño, unilateral"
  },
  {
     id:"N271",
     enfermedad:"Riñon pequeño, bilateral"
  },
  {
     id:"N279",
     enfermedad:"Riñon pequeño, no especificado"
  },
  {
     id:"N28",
     enfermedad:"Otros trastornos del rinon y del ureter no clasificadas en otra parte"
  },
  {
     id:"N280",
     enfermedad:"Isquemia e infarto del riñon"
  },
  {
     id:"N281",
     enfermedad:"Quiste de riñon, adquirido"
  },
  {
     id:"N288",
     enfermedad:"Otros trastornos especificados del riñon y del ureter"
  },
  {
     id:"N289",
     enfermedad:"Trastorno del riñon y del ureter, no especificado"
  },
  {
     id:"N29",
     enfermedad:"Otros trastornos del rinon y del ureter en enfermedades clasificadas en otra parte"
  },
  {
     id:"N290",
     enfermedad:"Sifilis renal tardia (a52.7)"
  },
  {
     id:"N291",
     enfermedad:"Otros trastornos del riñon y del ureter en enfermedades infecciosas y parasitarias clasificadas en otra parte"
  },
  {
     id:"N298",
     enfermedad:"Otros trastornos del riñon y del ureter en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"N30",
     enfermedad:"Cistitis"
  },
  {
     id:"N300",
     enfermedad:"Cistitis agudas"
  },
  {
     id:"N301",
     enfermedad:"Cistitis intersticial (cronica)"
  },
  {
     id:"N302",
     enfermedad:"Otras cistitis cronicas"
  },
  {
     id:"N303",
     enfermedad:"Trigonitis"
  },
  {
     id:"N304",
     enfermedad:"Cistitis por irradiacion"
  },
  {
     id:"N308",
     enfermedad:"Otras cistitis"
  },
  {
     id:"N309",
     enfermedad:"Cistitis, no especificada"
  },
  {
     id:"N31",
     enfermedad:"Disfuncion neuromuscular de vejiga no clasificada en otra parte"
  },
  {
     id:"N310",
     enfermedad:"Vejiga neuropatica no inhibida, no clasificada en otra parte"
  },
  {
     id:"N311",
     enfermedad:"Vejiga neuropatica refleja, no clasificada en otra parte"
  },
  {
     id:"N312",
     enfermedad:"Vejiga neuropatica flacida, no clasificada en otra parte"
  },
  {
     id:"N318",
     enfermedad:"Otras disfunciones neuromusculares de la vejiga"
  },
  {
     id:"N319",
     enfermedad:"Disfuncion neuromuscular de la vejiga, no especificada"
  },
  {
     id:"N32",
     enfermedad:"Otros trastornos de la vejiga"
  },
  {
     id:"N320",
     enfermedad:"Obstruccion de cuello de la vejiga"
  },
  {
     id:"N321",
     enfermedad:"Fistula vesicointestinal"
  },
  {
     id:"N322",
     enfermedad:"Fistula de la vejiga, no clasificada en otra parte"
  },
  {
     id:"N323",
     enfermedad:"Diverticulo de la vejiga"
  },
  {
     id:"N324",
     enfermedad:"Ruptura de la vejiga, no traumatica"
  },
  {
     id:"N328",
     enfermedad:"Otros trastornos especificados de la vejiga"
  },
  {
     id:"N329",
     enfermedad:"Trastorno de la vejiga, no especificado"
  },
  {
     id:"N33",
     enfermedad:"Trastornos vejiga en enfermedades clasificadas en otra parte"
  },
  {
     id:"N330",
     enfermedad:"Cistitis tuberculosa (a18.1)"
  },
  {
     id:"N338",
     enfermedad:"Trastornos de la vejiga en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"N34",
     enfermedad:"Uretritis y sindrome uretral"
  },
  {
     id:"N340",
     enfermedad:"Absceso uretral"
  },
  {
     id:"N341",
     enfermedad:"Uretritis no especificada"
  },
  {
     id:"N342",
     enfermedad:"Otras uretritis"
  },
  {
     id:"N343",
     enfermedad:"Sindrome uretral, no especificado"
  },
  {
     id:"N35",
     enfermedad:"Estrechez uretral"
  },
  {
     id:"N350",
     enfermedad:"Estrechez uretral postraumatica"
  },
  {
     id:"N351",
     enfermedad:"Estrechez uretral postinfeccion, no clasificada en otra parte"
  },
  {
     id:"N358",
     enfermedad:"Otras estrecheces uretrales"
  },
  {
     id:"N359",
     enfermedad:"Estrechez uretral, no especificada"
  },
  {
     id:"N36",
     enfermedad:"Otros trastornos de la uretra"
  },
  {
     id:"N360",
     enfermedad:"Fistula de la uretra"
  },
  {
     id:"N361",
     enfermedad:"Diverticulo de la uretra"
  },
  {
     id:"N362",
     enfermedad:"Caruncula uretral"
  },
  {
     id:"N363",
     enfermedad:"Prolapso de la mucosa uretral"
  },
  {
     id:"N368",
     enfermedad:"Otros trastornos especificados de la uretra"
  },
  {
     id:"N369",
     enfermedad:"Trastorno de la uretra, no especificado"
  },
  {
     id:"N37",
     enfermedad:"Trastornos uretra en enfermedades clasificadas en otra parte"
  },
  {
     id:"N370",
     enfermedad:"Uretritis en enfermedades clasificadas en otra parte"
  },
  {
     id:"N378",
     enfermedad:"Otros trastornos uretrales en enfermedades clasificadas en otra parte"
  },
  {
     id:"N39",
     enfermedad:"Otros trastornos del sistema urinario"
  },
  {
     id:"N390",
     enfermedad:"Infeccion de vias urinarias, sitio no especificado"
  },
  {
     id:"N391",
     enfermedad:"Proteinuria persistente, no especificada"
  },
  {
     id:"N392",
     enfermedad:"Proteinuria ortostatica, no especificada"
  },
  {
     id:"N393",
     enfermedad:"Incontinencia urinaria por tension"
  },
  {
     id:"N394",
     enfermedad:"Otras incontinencias urinarias especificadas"
  },
  {
     id:"N398",
     enfermedad:"Otros trastornos especificados del sistema urinario"
  },
  {
     id:"N399",
     enfermedad:"Trastorno del sistema urinario, no especificado"
  },
  {
     id:"N40",
     enfermedad:"Hiperplasia de la prostata"
  },
  {
     id:"N41",
     enfermedad:"Enfermedades inflamatorias de la prostata"
  },
  {
     id:"N410",
     enfermedad:"Prostatitis aguda"
  },
  {
     id:"N411",
     enfermedad:"Prostatitis cronica"
  },
  {
     id:"N412",
     enfermedad:"Absceso de la prostata"
  },
  {
     id:"N413",
     enfermedad:"Prostatocistitis"
  },
  {
     id:"N418",
     enfermedad:"Otras enfermedades inflamatorias de la prostata"
  },
  {
     id:"N419",
     enfermedad:"Enfermedad inflamatoria de la prostata, no especificada"
  },
  {
     id:"N42",
     enfermedad:"Otros trastornos de la prostata"
  },
  {
     id:"N420",
     enfermedad:"Calculo de la prostata"
  },
  {
     id:"N421",
     enfermedad:"Congestion y hemorragia de la prostata"
  },
  {
     id:"N422",
     enfermedad:"Atrofia de la prostata"
  },
  {
     id:"N428",
     enfermedad:"Otros trastornos especificados de la prostata"
  },
  {
     id:"N429",
     enfermedad:"Trastorno de la prostata, no especificado"
  },
  {
     id:"N43",
     enfermedad:"Hidrocele y espermatocele"
  },
  {
     id:"N430",
     enfermedad:"Hidrocele enquistado"
  },
  {
     id:"N431",
     enfermedad:"Hidrocele infectado"
  },
  {
     id:"N432",
     enfermedad:"Otras hidroceles"
  },
  {
     id:"N433",
     enfermedad:"Hidrocele, no especificado"
  },
  {
     id:"N434",
     enfermedad:"Espermatocele"
  },
  {
     id:"N44",
     enfermedad:"Torsion del testiculo"
  },
  {
     id:"N45",
     enfermedad:"Orquitis y epididimitis"
  },
  {
     id:"N450",
     enfermedad:"Orquitis, epididimitis y orquiepididimitis con absceso"
  },
  {
     id:"N459",
     enfermedad:"Orquitis, epididimitis y orquiepididimitis sin absceso"
  },
  {
     id:"N46",
     enfermedad:"Esterilidad en el varon"
  },
  {
     id:"N47",
     enfermedad:"Prepucio redundante, fimosis y parafimosis"
  },
  {
     id:"N48",
     enfermedad:"Otros trastornos del pene"
  },
  {
     id:"N480",
     enfermedad:"Leucoplasia del pene"
  },
  {
     id:"N481",
     enfermedad:"Balanopostitis"
  },
  {
     id:"N482",
     enfermedad:"Otros trastornos inflamatorios del pene"
  },
  {
     id:"N483",
     enfermedad:"Priapismo"
  },
  {
     id:"N484",
     enfermedad:"Impotencia de origen organico"
  },
  {
     id:"N485",
     enfermedad:"Ulcera del pene"
  },
  {
     id:"N486",
     enfermedad:"Induracion plastica del pene"
  },
  {
     id:"N488",
     enfermedad:"Otros trastornos especificados del pene"
  },
  {
     id:"N489",
     enfermedad:"Trastorno del pene, no especificado"
  },
  {
     id:"N49",
     enfermedad:"Trastornos inflamatorios de los organos genitales masculinos no clasificados en otra parte"
  },
  {
     id:"N490",
     enfermedad:"Trastornos inflamatorios de vesicula seminal"
  },
  {
     id:"N491",
     enfermedad:"Trastornos inflamatorios del cordon espermatico, tunica vaginal y conducto deferente"
  },
  {
     id:"N492",
     enfermedad:"Trastornos inflamatorios del escroto"
  },
  {
     id:"N498",
     enfermedad:"Otros trastornos inflamatorios de los organos genitales masculinos"
  },
  {
     id:"N499",
     enfermedad:"Trastorno inflamatorio de organo masculino, no especificado"
  },
  {
     id:"N50",
     enfermedad:"Otros trastornos de los organos genitales masculinos"
  },
  {
     id:"N500",
     enfermedad:"Atrofia del testiculo"
  },
  {
     id:"N501",
     enfermedad:"Trastornos vasculares de los organos genitales masculinos"
  },
  {
     id:"N508",
     enfermedad:"Otros trastornos especificados de los organos genitales masculinos"
  },
  {
     id:"N509",
     enfermedad:"Trastorno no especificado de los organos genitales masculinos"
  },
  {
     id:"N51",
     enfermedad:"Trastornos de los organos genitales masculinos en enfermedades clasificadas otra parte"
  },
  {
     id:"N510",
     enfermedad:"Trastornos de prostata en enfermedades clasificadas en otra parte"
  },
  {
     id:"N511",
     enfermedad:"Trastorno del testiculo y del epididimo en enfermedades clasificadas en otra parte"
  },
  {
     id:"N512",
     enfermedad:"Balanitis en enfermedades clasificadas en otra parte"
  },
  {
     id:"N518",
     enfermedad:"Otros trastornos de los organos genitales masculinos en enfermedades clasificadas en otra parte"
  },
  {
     id:"N60",
     enfermedad:"Displasia mamaria benigna"
  },
  {
     id:"N600",
     enfermedad:"Quiste solitario de la mama"
  },
  {
     id:"N601",
     enfermedad:"Mastopatia quistica difusa"
  },
  {
     id:"N602",
     enfermedad:"Fibroadenosis de la mama"
  },
  {
     id:"N603",
     enfermedad:"Fibroesclerosis de mama"
  },
  {
     id:"N604",
     enfermedad:"Ectasia de conducto mamario"
  },
  {
     id:"N608",
     enfermedad:"Otras displasias mamarias benignas"
  },
  {
     id:"N609",
     enfermedad:"Displasia mamaria benigna, sin otra especificacion"
  },
  {
     id:"N61",
     enfermedad:"Trastornos inflamatorios de la mama"
  },
  {
     id:"N62",
     enfermedad:"Hipertrofia de la mama"
  },
  {
     id:"N63",
     enfermedad:"Masa no especificada en la mama"
  },
  {
     id:"N64",
     enfermedad:"Otros trastornos de la mama"
  },
  {
     id:"N640",
     enfermedad:"Fisura y fistula del pezon"
  },
  {
     id:"N641",
     enfermedad:"Necrosis grasa de la mama"
  },
  {
     id:"N642",
     enfermedad:"Atrofia de la mama"
  },
  {
     id:"N643",
     enfermedad:"Galactorrea no asociada con el parto"
  },
  {
     id:"N644",
     enfermedad:"Mastodinia"
  },
  {
     id:"N645",
     enfermedad:"Otros signos y sintomas relativos a la mama"
  },
  {
     id:"N648",
     enfermedad:"Otros trastornos especificados de la mama"
  },
  {
     id:"N649",
     enfermedad:"Trastorno de la mama, no especificado"
  },
  {
     id:"N70",
     enfermedad:"Salpingitis y ooforitis"
  },
  {
     id:"N700",
     enfermedad:"Salpingitis y ooforitis aguda"
  },
  {
     id:"N701",
     enfermedad:"Salpingitis y ooforitis cronica"
  },
  {
     id:"N709",
     enfermedad:"Salpingitis y ooforitis, no especificadas"
  },
  {
     id:"N71",
     enfermedad:"Enfermedades inflamatorias del utero, excepto cuello"
  },
  {
     id:"N710",
     enfermedad:"Enfermedad inflamatoria aguda del utero"
  },
  {
     id:"N711",
     enfermedad:"Enfermedad inflamatoria cronica del utero"
  },
  {
     id:"N719",
     enfermedad:"Enfermedad inflamatoria del utero, no especificadas"
  },
  {
     id:"N72",
     enfermedad:"Enfermedades inflamatorias del cuello uterino"
  },
  {
     id:"N73",
     enfermedad:"Otras enfermedades pelvicas inflamatorias femeninas"
  },
  {
     id:"N730",
     enfermedad:"Parametritis y celulitis pelvica aguda"
  },
  {
     id:"N731",
     enfermedad:"Parametritis y celulitis pelvica cronica"
  },
  {
     id:"N732",
     enfermedad:"Parametritis y celulitis pelvica no especificada"
  },
  {
     id:"N733",
     enfermedad:"Peritonitis pelvica aguda, femenina"
  },
  {
     id:"N734",
     enfermedad:"Peritonitis pelvica cronica, femenina"
  },
  {
     id:"N735",
     enfermedad:"Peritonitis pelvica femenina, no especificada"
  },
  {
     id:"N736",
     enfermedad:"Adherencias peritoneales pelvicas femeninas"
  },
  {
     id:"N738",
     enfermedad:"Otras enfermedades inflamatorias pelvicas femeninas"
  },
  {
     id:"N739",
     enfermedad:"Enfermedad inflamatoria pelvica femenina, no especificada"
  },
  {
     id:"N74",
     enfermedad:"Trastornos inflamatorios de la pelvis femenina en enfermedades clasificadas otra parte"
  },
  {
     id:"N740",
     enfermedad:"Infeccion tuberculosa del cuello del utero (a18.1)"
  },
  {
     id:"N741",
     enfermedad:"Enfermedad inflamatoria pelvica femenina por tuberculosis (a18.1)"
  },
  {
     id:"N742",
     enfermedad:"Enfermedad inflamatoria pelvica femenina por sifilis (a51.4, a52.7)"
  },
  {
     id:"N743",
     enfermedad:"Enfermedad inflamatoria pelvica femenina por gonococos (a54.2)"
  },
  {
     id:"N744",
     enfermedad:"Enfermedad inflamatoria pelvica femenina por clamidias (a56.1)"
  },
  {
     id:"N748",
     enfermedad:"Trastornos inflamatorios pelvicos femeninos en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"N75",
     enfermedad:"Enfermedades de la glandula de bartholin"
  },
  {
     id:"N750",
     enfermedad:"Quiste de la glandula de bartholin"
  },
  {
     id:"N751",
     enfermedad:"Absceso de la glandula de bartholin"
  },
  {
     id:"N758",
     enfermedad:"Otras enfermedades de la glandula de bartholin"
  },
  {
     id:"N759",
     enfermedad:"Enfermedad de la glandula de bartholin, no especificada"
  },
  {
     id:"N76",
     enfermedad:"Otras enfermedades inflamatorias de la vagina y de la vulva"
  },
  {
     id:"N760",
     enfermedad:"Vaginitis aguda"
  },
  {
     id:"N761",
     enfermedad:"Vaginitis subaguda y cronica"
  },
  {
     id:"N762",
     enfermedad:"Vulvitis aguda"
  },
  {
     id:"N763",
     enfermedad:"Vulvitis subaguda y cronica"
  },
  {
     id:"N764",
     enfermedad:"Absceso vulvar"
  },
  {
     id:"N765",
     enfermedad:"Ulceracion de la vagina"
  },
  {
     id:"N766",
     enfermedad:"Ulceracion de la vulva"
  },
  {
     id:"N768",
     enfermedad:"Otras inflamaciones especificadas de la vagina y de la vulva"
  },
  {
     id:"N77",
     enfermedad:"Ulceracion e inflamacion vulvovaginal en enfermedades clasificadas en otra parte"
  },
  {
     id:"N770",
     enfermedad:"Ulceracion de la vulva en enfermedades infecciosas y parasitarias clasificadas en otra parte"
  },
  {
     id:"N771",
     enfermedad:"Vaginitis, vulvitis y vulvovaginitis en enfermedades infecciosas y parasitarias clasificadas en otra parte"
  },
  {
     id:"N778",
     enfermedad:"Ulceracion e inflamacion vulvovaginal en otras enfermedades clasificadas en otra parte"
  },
  {
     id:"N80",
     enfermedad:"Endometriosis"
  },
  {
     id:"N800",
     enfermedad:"Endometriosis del utero"
  },
  {
     id:"N801",
     enfermedad:"Endometriosis del ovario"
  },
  {
     id:"N802",
     enfermedad:"Endometriosis de la trompa de falopio"
  },
  {
     id:"N803",
     enfermedad:"Endometriosis del peritoneo pelvico"
  },
  {
     id:"N804",
     enfermedad:"Endometriosis del tabique rectovaginal y de la vagina"
  },
  {
     id:"N805",
     enfermedad:"Endometriosis del intestino"
  },
  {
     id:"N806",
     enfermedad:"Endometriosis en cicatriz cutanea"
  },
  {
     id:"N808",
     enfermedad:"Otras endometriosis"
  },
  {
     id:"N809",
     enfermedad:"Endometriosis, no especificada"
  },
  {
     id:"N81",
     enfermedad:"Prolapso genital femenino"
  },
  {
     id:"N810",
     enfermedad:"Uretrocele femenino"
  },
  {
     id:"N811",
     enfermedad:"Cistocele"
  },
  {
     id:"N812",
     enfermedad:"Prolapso uterovaginal incompleto"
  },
  {
     id:"N813",
     enfermedad:"Prolapso uterovaginal completo"
  },
  {
     id:"N814",
     enfermedad:"Prolapso uterovaginal, sin otra especificacion"
  },
  {
     id:"N815",
     enfermedad:"Enterocele vaginal"
  },
  {
     id:"N816",
     enfermedad:"Rectocele"
  },
  {
     id:"N818",
     enfermedad:"Otros prolapsos genitales femeninos"
  },
  {
     id:"N819",
     enfermedad:"Prolapso genital femenino, no especificado"
  },
  {
     id:"N82",
     enfermedad:"Fistulas que afectan el tracto genital femenino"
  },
  {
     id:"N820",
     enfermedad:"Fistula vesicovaginal"
  },
  {
     id:"N821",
     enfermedad:"Otras fistulas de las vias genitourinarias femeninas"
  },
  {
     id:"N822",
     enfermedad:"Fistula de la vagina al intestino delgado"
  },
  {
     id:"N823",
     enfermedad:"Fistula de la vagina al intestino grueso"
  },
  {
     id:"N824",
     enfermedad:"Otras fistulas del tracto genital femenino al tracto intestinal"
  },
  {
     id:"N825",
     enfermedad:"Fistula del tracto genital femenino a la piel"
  },
  {
     id:"N828",
     enfermedad:"Otras fistulas del tracto genital femenino"
  },
  {
     id:"N829",
     enfermedad:"Fistula del tracto genital femenino, sin otra especificacion"
  },
  {
     id:"N83",
     enfermedad:"Trastornos no inflamatorios del ovario, de la trompa de falopio y del ligamento ancho"
  },
  {
     id:"N830",
     enfermedad:"Quiste folicular del ovario"
  },
  {
     id:"N831",
     enfermedad:"Quiste del cuerpo amarillo"
  },
  {
     id:"N832",
     enfermedad:"Otros quistes ovaricos y los no especificados"
  },
  {
     id:"N833",
     enfermedad:"Atrofia adquirida del ovario y de la trompa falopio"
  },
  {
     id:"N834",
     enfermedad:"Prolapso y hernia del ovario y de la trompa de falopio"
  },
  {
     id:"N835",
     enfermedad:"Torsion de ovario, pediculo de ovario y trompa de falopio"
  },
  {
     id:"N836",
     enfermedad:"Hematosalpinx"
  },
  {
     id:"N837",
     enfermedad:"Hematoma del ligamento ancho"
  },
  {
     id:"N838",
     enfermedad:"Otros trastornos no inflamatorios del ovario, de la trompa de falopio y del ligamento ancho"
  },
  {
     id:"N839",
     enfermedad:"Enfermedad no inflamatoria del ovario, de la trompa de falopio y del ligamento ancho, no especificada"
  },
  {
     id:"N84",
     enfermedad:"Polipo del tracto genital femenino"
  },
  {
     id:"N840",
     enfermedad:"Polipo del cuerpo del utero"
  },
  {
     id:"N841",
     enfermedad:"Polipo del cuello del utero"
  },
  {
     id:"N842",
     enfermedad:"Polipo de la vagina"
  },
  {
     id:"N843",
     enfermedad:"Polipo de la vulva"
  },
  {
     id:"N848",
     enfermedad:"Polipo de otras partes del tracto genital femenino"
  },
  {
     id:"N849",
     enfermedad:"Polipo del tracto genital femenino, no especificado"
  },
  {
     id:"N85",
     enfermedad:"Otros trastornos no inflamatorios del utero, excepto del cuello"
  },
  {
     id:"N850",
     enfermedad:"Hiperplasia de glandula del endometrio"
  },
  {
     id:"N851",
     enfermedad:"Hiperplasia adenomatosa del endometrio"
  },
  {
     id:"N852",
     enfermedad:"Hipertrofia del utero"
  },
  {
     id:"N853",
     enfermedad:"Subinvolucion del utero"
  },
  {
     id:"N854",
     enfermedad:"Mala posicion del utero"
  },
  {
     id:"N855",
     enfermedad:"Inversion del utero"
  },
  {
     id:"N856",
     enfermedad:"Sinequias intrauterinas"
  },
  {
     id:"N857",
     enfermedad:"Hematometra"
  },
  {
     id:"N858",
     enfermedad:"Otros trastornos no inflamatorios especificados del utero"
  },
  {
     id:"N859",
     enfermedad:"Trastorno no inflamatorio del utero, no especificado"
  },
  {
     id:"N86",
     enfermedad:"Erosion y ectropion del cuello uterino"
  },
  {
     id:"N87",
     enfermedad:"Displasia del cuello uterino"
  },
  {
     id:"N870",
     enfermedad:"Displasia cervical leve"
  },
  {
     id:"N871",
     enfermedad:"Displasia cervical moderada"
  },
  {
     id:"N872",
     enfermedad:"Displasia cervical severa, no clasificada en otra parte"
  },
  {
     id:"N879",
     enfermedad:"Displasia del cuello del utero, no especificada"
  },
  {
     id:"N88",
     enfermedad:"Otros trastornos no inflamatoria del cuello uterino"
  },
  {
     id:"N880",
     enfermedad:"Leucoplasia del cuello del utero"
  },
  {
     id:"N881",
     enfermedad:"Laceracion antigua del cuello del utero"
  },
  {
     id:"N882",
     enfermedad:"Estrechez y estenosis del cuello del utero"
  },
  {
     id:"N883",
     enfermedad:"Incompetencia del cuello del utero"
  },
  {
     id:"N884",
     enfermedad:"Elongacion hipertrofica del cuello del utero"
  },
  {
     id:"N888",
     enfermedad:"Otros trastornos no inflamatorios especificados del cuello del utero"
  },
  {
     id:"N889",
     enfermedad:"Trastorno no inflamatorio del cuello del utero, no especificado"
  },
  {
     id:"N89",
     enfermedad:"Otros trastornos no inflamatorios de la vagina"
  },
  {
     id:"N890",
     enfermedad:"Displasia vaginal leve"
  },
  {
     id:"N891",
     enfermedad:"Displasia vaginal moderada"
  },
  {
     id:"N892",
     enfermedad:"Displasia vaginal severa, no clasificada en otra parte"
  },
  {
     id:"N893",
     enfermedad:"Displasia de la vagina, no especificada"
  },
  {
     id:"N894",
     enfermedad:"Leucoplasia de la vagina"
  },
  {
     id:"N895",
     enfermedad:"Estrechez y atresia de la vagina"
  },
  {
     id:"N896",
     enfermedad:"Anillo de himen estrecho"
  },
  {
     id:"N897",
     enfermedad:"Hematocolpos"
  },
  {
     id:"N898",
     enfermedad:"Otros trastornos especificados no inflamatorios de la vagina"
  },
  {
     id:"N899",
     enfermedad:"Trastorno no inflamatorio de la vagina, no especificado"
  },
  {
     id:"N90",
     enfermedad:"Otros trastornos no inflamatorios de la vulva y del perineo"
  },
  {
     id:"N900",
     enfermedad:"Displasia vulvar leve"
  },
  {
     id:"N901",
     enfermedad:"Displasia vulvar moderada"
  },
  {
     id:"N902",
     enfermedad:"Displasia vulvar severa, no clasificada en otra parte"
  },
  {
     id:"N903",
     enfermedad:"Displasia de la vulva, no especificada"
  },
  {
     id:"N904",
     enfermedad:"Leucoplasia de la vulva"
  },
  {
     id:"N905",
     enfermedad:"Atrofia de la vulva"
  },
  {
     id:"N906",
     enfermedad:"Hipertrofia de la vulva"
  },
  {
     id:"N907",
     enfermedad:"Quiste de la vulva"
  },
  {
     id:"N908",
     enfermedad:"Otros trastornos no inflamatorios especificados de la vulva y del perineo"
  },
  {
     id:"N909",
     enfermedad:"Trastorno no inflamatorio de la vulva y del perineo, no especificado"
  },
  {
     id:"N91",
     enfermedad:"Menstruacion ausente, escasa o rara"
  },
  {
     id:"N910",
     enfermedad:"Amenorrea primaria"
  },
  {
     id:"N911",
     enfermedad:"Amenorrea secundaria"
  },
  {
     id:"N912",
     enfermedad:"Amenorrea, sin otra especificacion"
  },
  {
     id:"N913",
     enfermedad:"Oligomenorrea primaria"
  },
  {
     id:"N914",
     enfermedad:"Oligomenorrea secundaria"
  },
  {
     id:"N915",
     enfermedad:"Oligomenorrea no especificada"
  },
  {
     id:"N92",
     enfermedad:"Menstruacion excesiva, frecuente e irregular"
  },
  {
     id:"N920",
     enfermedad:"Menstruacion excesiva y frecuente con ciclo regular"
  },
  {
     id:"N921",
     enfermedad:"Menstruacion excesiva y frecuente con ciclo irregular"
  },
  {
     id:"N922",
     enfermedad:"Menstruacion excesiva en la pubertad"
  },
  {
     id:"N923",
     enfermedad:"Hemorragia por ovulacion"
  },
  {
     id:"N924",
     enfermedad:"Hemorragia excesiva en periodo premenopausico"
  },
  {
     id:"N925",
     enfermedad:"Otras menstruaciones irregulares especificadas"
  },
  {
     id:"N926",
     enfermedad:"Menstruacion irregular, no especificada"
  },
  {
     id:"N93",
     enfermedad:"Otras hemorragias uterinas o vaginales anormales"
  },
  {
     id:"N930",
     enfermedad:"Hemorragia postcoito y postcontacto"
  },
  {
     id:"N938",
     enfermedad:"Otras hemorragias uterinas o vaginales anormales especificadas"
  },
  {
     id:"N939",
     enfermedad:"Hemorragia vaginal y uterina anormal, no especificada"
  },
  {
     id:"N94",
     enfermedad:"Dolor y otras afecciones relacionadas con los organos genitales femeninos y con el ciclo menstrual"
  },
  {
     id:"N940",
     enfermedad:"Dolor intermenstrual"
  },
  {
     id:"N941",
     enfermedad:"Dispareunia"
  },
  {
     id:"N942",
     enfermedad:"Vaginismo"
  },
  {
     id:"N943",
     enfermedad:"Sindrome de tension premenstrual"
  },
  {
     id:"N944",
     enfermedad:"Dismenorrea primaria"
  },
  {
     id:"N945",
     enfermedad:"Dismenorrea secundaria"
  },
  {
     id:"N946",
     enfermedad:"Dismenorrea, no especificada"
  },
  {
     id:"N948",
     enfermedad:"Otras afecciones especificadas asociadas con los organos genitales femeninos y el ciclo menstrual"
  },
  {
     id:"N949",
     enfermedad:"Afecciones no especificadas asociadas con los organos genitales femeninos y el ciclo menstrual"
  },
  {
     id:"N95",
     enfermedad:"Otros trastornos menopausicos y perimenopausicos"
  },
  {
     id:"N950",
     enfermedad:"Hemorragia postmenopausica"
  },
  {
     id:"N951",
     enfermedad:"Estados menopausicos y climatericos femeninos"
  },
  {
     id:"N952",
     enfermedad:"Vaginitis atrofica postmenopausica"
  },
  {
     id:"N953",
     enfermedad:"Estados asociados con menopausia artificial"
  },
  {
     id:"N958",
     enfermedad:"Otros trastornos menopausicos y perimenopausicos especificados"
  },
  {
     id:"N959",
     enfermedad:"Trastorno menopausico y perimenopausico, no especificado"
  },
  {
     id:"N96",
     enfermedad:"Abortadora habitual"
  },
  {
     id:"N97",
     enfermedad:"Infertilidad femenina"
  },
  {
     id:"N970",
     enfermedad:"Infertilidad femenina asociada con falta de ovulacion"
  },
  {
     id:"N971",
     enfermedad:"Infertilidad femenina de origen tubarico"
  },
  {
     id:"N972",
     enfermedad:"Infertilidad femenina de origen uterino"
  },
  {
     id:"N973",
     enfermedad:"Infertilidad femenina de origen cervical"
  },
  {
     id:"N974",
     enfermedad:"Infertilidad femenina asociada con factores masculinos"
  },
  {
     id:"N978",
     enfermedad:"Infertilidad femenina de otro origen"
  },
  {
     id:"N979",
     enfermedad:"Infertilidad femenina, no especificada"
  },
  {
     id:"N98",
     enfermedad:"Complicacion asociada con la fecundacion artificial"
  },
  {
     id:"N980",
     enfermedad:"Infeccion asociada con inseminacion artificial"
  },
  {
     id:"N981",
     enfermedad:"Hiperestimulacion de ovarios"
  },
  {
     id:"N982",
     enfermedad:"Complicaciones en el intento de introduccion del huevo fecundado en la fertilizacion en vitro"
  },
  {
     id:"N983",
     enfermedad:"Complicaciones en el intento introduccion del embrion en la transferencia de embriones"
  },
  {
     id:"N988",
     enfermedad:"Otras complicaciones asociadas con la fecundacion artificial"
  },
  {
     id:"N989",
     enfermedad:"Complicacion no especificada asociada con la fecundacion artificial"
  },
  {
     id:"N99",
     enfermedad:"Trastornos del sistema genitourinario consecutivos a procedimientos no clasificados en otra parte"
  },
  {
     id:"N990",
     enfermedad:"Insuficiencia renal consecutiva a procedimientos"
  },
  {
     id:"N991",
     enfermedad:"Estrechez uretral consecutiva a procedimientos"
  },
  {
     id:"N992",
     enfermedad:"Adherencias postoperatorias de la vagina"
  },
  {
     id:"N993",
     enfermedad:"Prolapso de la cupula vaginal despues de histerectomia"
  },
  {
     id:"N994",
     enfermedad:"Adherencias peritoneales pelvicas consecutivas a procedimientos"
  },
  {
     id:"N995",
     enfermedad:"Mal funcionamiento de estoma externo de vias urinarias"
  },
  {
     id:"N998",
     enfermedad:"Otros trastornos del sistema genitourinario consecutivos a procedimientos"
  },
  {
     id:"N999",
     enfermedad:"Trastorno no especificado del sistema genitourinario consecutivo a procedimientos"
  },
  {
     id:"O00",
     enfermedad:"Embarazo ectopico"
  },
  {
     id:"O000",
     enfermedad:"Embarazo abdominal"
  },
  {
     id:"O001",
     enfermedad:"Embarazo tubarico"
  },
  {
     id:"O002",
     enfermedad:"Embarazo ovarico"
  },
  {
     id:"O008",
     enfermedad:"Otros embarazos ectopicos"
  },
  {
     id:"O009",
     enfermedad:"Embarazo ectopico, no especificado"
  },
  {
     id:"O01",
     enfermedad:"Mola hidatiforme"
  },
  {
     id:"O010",
     enfermedad:"Mola hidatiforme clasica"
  },
  {
     id:"O011",
     enfermedad:"Mola hidatiforme, incompleta o parcial"
  },
  {
     id:"O019",
     enfermedad:"Mola hidatiforme, no especificada"
  },
  {
     id:"O02",
     enfermedad:"Otros productos anormales de la concepcion"
  },
  {
     id:"O020",
     enfermedad:"Detencion del desarrollo del huevo y mola no hidatiforme"
  },
  {
     id:"O021",
     enfermedad:"Aborto retenido"
  },
  {
     id:"O028",
     enfermedad:"Otros productos anormales especificados de la concepcion"
  },
  {
     id:"O029",
     enfermedad:"Producto anormal de la concepcion, no especificado"
  },
  {
     id:"O03",
     enfermedad:"Aborto espontaneo"
  },
  {
     id:"O030",
     enfermedad:"Aborto espontaneo: incompleto, complicado con infeccion genital y pelviana"
  },
  {
     id:"O031",
     enfermedad:"Aborto espontaneo: incompleto, complicado por hemorragia excesiva o tardia"
  },
  {
     id:"O032",
     enfermedad:"Aborto espontaneo: incompleto, complicado por embolia"
  },
  {
     id:"O033",
     enfermedad:"Aborto espontaneo: incompleto, con otras complicaciones especificadas y las no especificadas"
  },
  {
     id:"O034",
     enfermedad:"Aborto espontaneo: incompleto, sin complicacion"
  },
  {
     id:"O035",
     enfermedad:"Aborto espontaneo: completo o no especificado, complicado con infeccion genital y pelviana"
  },
  {
     id:"O036",
     enfermedad:"Aborto espontaneo: completo o no especificado, complicado por hemorragia excesiva o tardia"
  },
  {
     id:"O037",
     enfermedad:"Aborto espontaneo: completo o no especificado, complicado por embolia"
  },
  {
     id:"O038",
     enfermedad:"Aborto espontaneo: completo o no especificado, con otras complicaciones especificadas y las no especificadas"
  },
  {
     id:"O039",
     enfermedad:"Aborto espontaneo: completo o no especificado, sin complicacion"
  },
  {
     id:"O04",
     enfermedad:"Aborto medico"
  },
  {
     id:"O040",
     enfermedad:"Aborto medico: incompleto, complicado con infeccion genital y pelviana"
  },
  {
     id:"O041",
     enfermedad:"Aborto medico: incompleto, complicado por hemorragia excesiva o tardia"
  },
  {
     id:"O042",
     enfermedad:"Aborto medico: incompleto, complicado por embolia"
  },
  {
     id:"O043",
     enfermedad:"Aborto medico: incompleto, con otras complicaciones especificadas y las no especificadas"
  },
  {
     id:"O044",
     enfermedad:"Aborto medico: incompleto, sin complicacion"
  },
  {
     id:"O045",
     enfermedad:"Aborto medico: completo o no especificado, complicado con infeccion genital y pelviana"
  },
  {
     id:"O046",
     enfermedad:"Aborto medico: completo o no especificado, complicado por hemorragia excesiva o tardia"
  },
  {
     id:"O047",
     enfermedad:"Aborto medico: completo o no especificado, complicado por embolia"
  },
  {
     id:"O048",
     enfermedad:"Aborto medico: completo o no especificado, con otras complicaciones especificadas y las no especificadas"
  },
  {
     id:"O049",
     enfermedad:"Aborto medico: completo o no especificado, sin complicacion"
  },
  {
     id:"O05",
     enfermedad:"Otro aborto"
  },
  {
     id:"O050",
     enfermedad:"Otro aborto: incompleto, complicado con infeccion genital y pelviana"
  },
  {
     id:"O051",
     enfermedad:"Otro aborto: incompleto, complicado por hemorragia excesiva o tardia"
  },
  {
     id:"O052",
     enfermedad:"Otro aborto: incompleto, complicado por embolia"
  },
  {
     id:"O053",
     enfermedad:"Otro aborto: incompleto, con otras complicaciones especificadas y las no especificadas"
  },
  {
     id:"O054",
     enfermedad:"Otro aborto: incompleto, sin complicacion"
  },
  {
     id:"O055",
     enfermedad:"Otro aborto: completo o no especificado, complicado con infeccion genital y pelviana"
  },
  {
     id:"O056",
     enfermedad:"Otro aborto: completo o no especificado, complicado por hemorragia excesiva o tardia"
  },
  {
     id:"O057",
     enfermedad:"Otro aborto: completo o no especificado, complicado por embolia"
  },
  {
     id:"O058",
     enfermedad:"Otro aborto: completo o no especificado, con otras complicaciones especificadas y las no especificadas"
  },
  {
     id:"O059",
     enfermedad:"Otro aborto: completo o no especificado, sin complicacion"
  },
  {
     id:"O06",
     enfermedad:"Aborto no especificado"
  },
  {
     id:"O060",
     enfermedad:"Aborto no especificado: incompleto, complicado con infeccion genital y pelviana"
  },
  {
     id:"O061",
     enfermedad:"Aborto no especificado: incompleto, complicado por hemorragia excesiva o tardia"
  },
  {
     id:"O062",
     enfermedad:"Aborto no especificado: incompleto, complicado por embolia"
  },
  {
     id:"O063",
     enfermedad:"Aborto no especificado: incompleto, con otras complicaciones especificadas y las no especificadas"
  },
  {
     id:"O064",
     enfermedad:"Aborto no especificado: incompleto, sin complicacion"
  },
  {
     id:"O065",
     enfermedad:"Aborto no especificado: completo o no especificado, complicado con infeccion genital y pelviana"
  },
  {
     id:"O066",
     enfermedad:"Aborto no especificado: completo o no especificado, complicado por hemorragia excesiva o tardia"
  },
  {
     id:"O067",
     enfermedad:"Aborto no especificado: completo o no especificado, complicado por embolia"
  },
  {
     id:"O068",
     enfermedad:"Aborto no especificado: completo o no especificado, con otras complicaciones especificadas y las no especificadas"
  },
  {
     id:"O069",
     enfermedad:"Aborto no especificado: completo o no especificado, sin complicacion"
  },
  {
     id:"O07",
     enfermedad:"Intento fallido de aborto"
  },
  {
     id:"O070",
     enfermedad:"Falla de la induccion medica del aborto, complicado con infeccion genital y pelviana"
  },
  {
     id:"O071",
     enfermedad:"Falla de la induccion medica del aborto, complicado por hemorragia excesiva o tardia"
  },
  {
     id:"O072",
     enfermedad:"Falla de la induccion medica del aborto, complicado por embolia"
  },
  {
     id:"O073",
     enfermedad:"Falla de la induccion medica del aborto, con otras complicaciones especificadas y las no especificadas"
  },
  {
     id:"O074",
     enfermedad:"Falla de la induccion medica del aborto, sin complicacion"
  },
  {
     id:"O075",
     enfermedad:"Otros intentos fallidos de aborto y los no especificados, complicados por infeccion genital y pelviana"
  },
  {
     id:"O076",
     enfermedad:"Otros intentos fallidos de aborto y los no especificados, complicados por hemorragia excesiva o tardia"
  },
  {
     id:"O077",
     enfermedad:"Otros intentos fallidos de aborto y los no especificados, complicados por embolia"
  },
  {
     id:"O078",
     enfermedad:"Otros intentos fallidos de aborto y los no especificados, con otras complicaciones especificadas y las no especificadas"
  },
  {
     id:"O079",
     enfermedad:"Otros intentos fallidos de aborto y los no especificados, sin complicacion"
  },
  {
     id:"O08",
     enfermedad:"Complicaciones consecutivas al aborto, al embarazo ectopico y al embarazo molar"
  },
  {
     id:"O080",
     enfermedad:"Infeccion genital y pelviana consecutiva al aborto, al embarazo ectopico y al embarazo molar"
  },
  {
     id:"O081",
     enfermedad:"Hemorragia excesiva o tardia consecutiva al aborto, al embarazo ectopico y al embarazo molar"
  },
  {
     id:"O082",
     enfermedad:"Embolia consecutiva al aborto, al embarazo ectopico y al embarazo molar"
  },
  {
     id:"O083",
     enfermedad:"Choque consecutivo al aborto, al embarazo ectopico y al embarazo molar"
  },
  {
     id:"O084",
     enfermedad:"Insuficiencia renal consecutiva al aborto, al embarazo ectopico y al embarazo molar"
  },
  {
     id:"O085",
     enfermedad:"Trastorno metabolico consecutivo al aborto, al embarazo ectopico y al embarazo molar"
  },
  {
     id:"O086",
     enfermedad:"Lesion de organos o tejidos de la pelvis consecutivo al aborto, al embarazo ectopico y al embarazo molar"
  },
  {
     id:"O087",
     enfermedad:"Otras complicaciones venosas consecutiva al aborto, al embarazo ectopico y al embarazo molar"
  },
  {
     id:"O088",
     enfermedad:"Otras complicaciones consecutivas al aborto, al embarazo ectopico y al embarazo molar"
  },
  {
     id:"O089",
     enfermedad:"Complicacion no especificada consecutiva al aborto, al embarazo ectopico y al embarazo molar"
  },
  {
     id:"O10",
     enfermedad:"Hipertension preexistente que complica el embarazo, el parto y el puerperio"
  },
  {
     id:"O100",
     enfermedad:"Hipertension esencial preexistente que complica el embarazo, el parto y el puerperio"
  },
  {
     id:"O101",
     enfermedad:"Enfermedad cardiaca hipertensiva preexistente que complica el embarazo, el parto y el puerperio"
  },
  {
     id:"O102",
     enfermedad:"Enfermedad renal hipertensiva preexistente que complica el embarazo, el parto y el puerperio"
  },
  {
     id:"O103",
     enfermedad:"Enfermedad cardio"
  },
  {
     id:"O104",
     enfermedad:"Hipertension secundaria preexistente que complica el embarazo, el parto y el puerperio"
  },
  {
     id:"O109",
     enfermedad:"Hipertension preexistente no especificada, que complica el embarazo, el parto y el puerperio"
  },
  {
     id:"O11",
     enfermedad:"Trastornos hipertensivos preexistentes, con proteinuria agregada"
  },
  {
     id:"O12",
     enfermedad:"Edema y proteinuria gestacional [inducidos por elembarazo] sin hipertension"
  },
  {
     id:"O120",
     enfermedad:"Edema gestacional"
  },
  {
     id:"O121",
     enfermedad:"Proteinuria gestacional"
  },
  {
     id:"O122",
     enfermedad:"Edema gestacional con proteinuria"
  },
  {
     id:"O13",
     enfermedad:"Hipertension gestacional [inducida por elembarazo] sin proteinuria significativa"
  },
  {
     id:"O14",
     enfermedad:"Hipertens gestacional [inducida por elembarazo] con proteinuria significativa"
  },
  {
     id:"O140",
     enfermedad:"Preeclampsia moderada"
  },
  {
     id:"O141",
     enfermedad:"Preeclampsia severa"
  },
  {
     id:"O149",
     enfermedad:"Preeclampsia, no especificada"
  },
  {
     id:"O15",
     enfermedad:"Eclampsia"
  },
  {
     id:"O150",
     enfermedad:"Preeclampsia en el embarazo"
  },
  {
     id:"O151",
     enfermedad:"Preeclampsia durante el trabajo de parto"
  },
  {
     id:"O152",
     enfermedad:"Preeclampsia en el puerperio"
  },
  {
     id:"O159",
     enfermedad:"Preeclampsia, en periodo no especificado"
  },
  {
     id:"O16",
     enfermedad:"Hipertension materna no especificada"
  },
  {
     id:"O20",
     enfermedad:"Hemorragia precoz del embarazo"
  },
  {
     id:"O200",
     enfermedad:"Amenaza de aborto"
  },
  {
     id:"O208",
     enfermedad:"Otras hemorragias precoces del embarazo"
  },
  {
     id:"O209",
     enfermedad:"Hemorragia precoz del embarazo, sin otra especificacion"
  },
  {
     id:"O21",
     enfermedad:"Vomitos excesivos en el embarazo"
  },
  {
     id:"O210",
     enfermedad:"Hiperemesis gravidica leve"
  },
  {
     id:"O211",
     enfermedad:"Hiperemesis gravidica con trastornos metabolicos"
  },
  {
     id:"O212",
     enfermedad:"Hiperemesis gravidica tardia"
  },
  {
     id:"O218",
     enfermedad:"Otros vomitos que complican el embarazo"
  },
  {
     id:"O219",
     enfermedad:"Vomitos del embarazo, no especificado"
  },
  {
     id:"O22",
     enfermedad:"Complicaciones venosas en el embarazo"
  },
  {
     id:"O220",
     enfermedad:"Venas varicosas de los miembros inferiores en el embarazo"
  },
  {
     id:"O221",
     enfermedad:"Varices genitales en el embarazo"
  },
  {
     id:"O222",
     enfermedad:"Tromboflebitis en el embarazo"
  },
  {
     id:"O223",
     enfermedad:"Flebotrombosis profunda en el embarazo"
  },
  {
     id:"O224",
     enfermedad:"Hemorroides en el embarazo"
  },
  {
     id:"O225",
     enfermedad:"Trombosis venosa cerebral en el embarazo"
  },
  {
     id:"O228",
     enfermedad:"Otras complicaciones venosas en el embarazo"
  },
  {
     id:"O229",
     enfermedad:"Complicacion venosa no especificada en el embarazo"
  },
  {
     id:"O23",
     enfermedad:"Infeccionesion de las vias genitourinarias en el embarazo"
  },
  {
     id:"O230",
     enfermedad:"Infeccion del riñon en el embarazo"
  },
  {
     id:"O231",
     enfermedad:"Infeccion de la vejiga urinaria en el embarazo"
  },
  {
     id:"O232",
     enfermedad:"Infeccion de la uretra en el embarazo"
  },
  {
     id:"O233",
     enfermedad:"Infeccion de otras partes de las vias urinarias en el embarazo"
  },
  {
     id:"O234",
     enfermedad:"Infeccion no especificada de las vias urinarias en el embarazo"
  },
  {
     id:"O235",
     enfermedad:"Infeccion genital en el embarazo"
  },
  {
     id:"O239",
     enfermedad:"Otras infeccion y las no especificadas de las vias genitourinarias en el embarazo"
  },
  {
     id:"O24",
     enfermedad:"Diabetes mellitus en el embarazo"
  },
  {
     id:"O240",
     enfermedad:"Diabetes mellitus preexistente insulinodependiente, en el embarazo"
  },
  {
     id:"O241",
     enfermedad:"Diabetes mellitus preexistente no insulinodependiente, en el embarazo"
  },
  {
     id:"O242",
     enfermedad:"Diabetes mellitus preexistente relacionada con desnutricion, en el embarazo"
  },
  {
     id:"O243",
     enfermedad:"Diabetes mellitus preexistente, sin otra especificacion, en el embarazo"
  },
  {
     id:"O244",
     enfermedad:"Diabetes mellitus que se origina en el embarazo"
  },
  {
     id:"O249",
     enfermedad:"Diabetes mellitus no especificada, en el embarazo"
  },
  {
     id:"O25",
     enfermedad:"Desnutricion en el embarazo"
  },
  {
     id:"O26",
     enfermedad:"Atención materna por otras complicaciones relacionadas con el embarazo"
  },
  {
     id:"O260",
     enfermedad:"Aumento excesivo de peso en el embarazo"
  },
  {
     id:"O261",
     enfermedad:"Aumento pequeño de peso en el embarazo"
  },
  {
     id:"O262",
     enfermedad:"Atención del embarazo en una abortadora habitual"
  },
  {
     id:"O263",
     enfermedad:"Retencion de dispositivo anticonceptivo intrauterino en el embarazo"
  },
  {
     id:"O264",
     enfermedad:"Herpes gestacional"
  },
  {
     id:"O265",
     enfermedad:"Sindrome de hipotension materna"
  },
  {
     id:"O266",
     enfermedad:"Trastorno del higado en el embarazo, el parto y el puerperio"
  },
  {
     id:"O267",
     enfermedad:"Subluxacion de la sinfisis (del pubis) en el embarazo, el parto y el puerperio"
  },
  {
     id:"O268",
     enfermedad:"Otras complicaciones especificadas relacionadas con el embarazo"
  },
  {
     id:"O269",
     enfermedad:"Complicacion relacionada con el embarazo, no especificada"
  },
  {
     id:"O28",
     enfermedad:"Hallazgos anormales en el examen prenatal de la madre"
  },
  {
     id:"O280",
     enfermedad:"Hallazgo hematologico anormal en el examen prenatal de la madre"
  },
  {
     id:"O281",
     enfermedad:"Hallazgo bioquimico anormal en el examen prenatal de la madre"
  },
  {
     id:"O282",
     enfermedad:"Hallazgo citologico anormal en el examen prenatal de la madre"
  },
  {
     id:"O283",
     enfermedad:"Hallazgo ultrasonico anormal en el examen prenatal de la madre"
  },
  {
     id:"O284",
     enfermedad:"Hallazgo radiologico anormal en el examen prenatal de la madre"
  },
  {
     id:"O285",
     enfermedad:"Hallazgo cromosomico o genetico anormal en el examen prenatal de la madre"
  },
  {
     id:"O288",
     enfermedad:"Otros hallazgos anormales en el examen prenatal de la madre"
  },
  {
     id:"O289",
     enfermedad:"Hallazgo anormal no especificado en el examen prenatal de la madre"
  },
  {
     id:"O29",
     enfermedad:"Complicaciones de la anestesia administrada durante el embarazo"
  },
  {
     id:"O290",
     enfermedad:"Complicaciones pulmonares de la anestesia administrada durante el embarazo"
  },
  {
     id:"O291",
     enfermedad:"Complicaciones cardiacas de la anestesia administrada durante el embarazo"
  },
  {
     id:"O292",
     enfermedad:"Complicaciones del sistema nervioso central debidas a la anestesia administrada durante el embarazo"
  },
  {
     id:"O293",
     enfermedad:"Reaccion toxica a la anestesia local administrada durante el embarazo"
  },
  {
     id:"O294",
     enfermedad:"Cefalalgia inducida por la anestesia espinal o epidural administradas durante el embarazo"
  },
  {
     id:"O295",
     enfermedad:"Otras complicaciones de la anestesia espinal o epidural administradas durante el embarazo"
  },
  {
     id:"O296",
     enfermedad:"Falla o dificultad en la intubacion durante el embarazo"
  },
  {
     id:"O298",
     enfermedad:"Otras complicaciones de la anestesia administrada durante el embarazo"
  },
  {
     id:"O299",
     enfermedad:"Complicacion no especificada de la anestesia administrada durante el embarazo"
  },
  {
     id:"O30",
     enfermedad:"Embarazo multiple"
  },
  {
     id:"O300",
     enfermedad:"Embarazo doble"
  },
  {
     id:"O301",
     enfermedad:"Embarazo triple"
  },
  {
     id:"O302",
     enfermedad:"Embarazo cuadruple"
  },
  {
     id:"O308",
     enfermedad:"Otros embarazos multiples"
  },
  {
     id:"O309",
     enfermedad:"Embarazo multiple, no especificado"
  },
  {
     id:"O31",
     enfermedad:"Complicacion especificas del embarazo multiple"
  },
  {
     id:"O310",
     enfermedad:"Feto papiraceo"
  },
  {
     id:"O311",
     enfermedad:"Embarazo que continua despues del aborto de un feto o mas"
  },
  {
     id:"O312",
     enfermedad:"Embarazo que continua despues de la muerte intrauterina de un feto o mas"
  },
  {
     id:"O318",
     enfermedad:"Otras complicaciones especificadas del embarazo"
  },
  {
     id:"O32",
     enfermedad:"Atención materna por presentación anormal feto"
  },
  {
     id:"O320",
     enfermedad:"Atención materna por posicion fetal inestable"
  },
  {
     id:"O321",
     enfermedad:"Atención materna por presentación de nalgas"
  },
  {
     id:"O322",
     enfermedad:"Atención materna por posicion fetal oblicua o transversa"
  },
  {
     id:"O323",
     enfermedad:"Atención materna por presentación de cara, de frente o de menton"
  },
  {
     id:"O324",
     enfermedad:"Atención materna por cabeza alta en gestacion a termino"
  },
  {
     id:"O325",
     enfermedad:"Atención materna por embarazo multiple con presentación anormal de un feto o mas"
  },
  {
     id:"O326",
     enfermedad:"Atención materna por presentación compuesta"
  },
  {
     id:"O328",
     enfermedad:"Atención materna por otras presentaciones anormales del feto"
  },
  {
     id:"O329",
     enfermedad:"Atención materna por presentación anormal no especificada del feto"
  },
  {
     id:"O33",
     enfermedad:"Atención materna por desproporcion"
  },
  {
     id:"O330",
     enfermedad:"Atención materna por desproporcion debida a deformidad de la pelvis osea en la madre"
  },
  {
     id:"O331",
     enfermedad:"Atención materna por desproporcion debida a estrechez general de la pelvis"
  },
  {
     id:"O332",
     enfermedad:"Atención materna por desproporcion debida a disminucion del estrecho superior de la pelvis"
  },
  {
     id:"O333",
     enfermedad:"Atención materna por desproporcion debida a disminucion del estrecho inferior de la pelvis"
  },
  {
     id:"O334",
     enfermedad:"Atención materna por desproporcion fetopelviana de origen mixto, materno y fetal"
  },
  {
     id:"O335",
     enfermedad:"Atención materna por desproporcion debida a feto demasiado grande"
  },
  {
     id:"O336",
     enfermedad:"Atención materna por desproporcion debida a feto hidrocefalico"
  },
  {
     id:"O337",
     enfermedad:"Atención materna por desproporcion debida a otra deformidad fetal"
  },
  {
     id:"O338",
     enfermedad:"Atención materna por desproporcion de otro origen"
  },
  {
     id:"O339",
     enfermedad:"Atención materna por desproporcion de origen no especificado"
  },
  {
     id:"O34",
     enfermedad:"Atención materna por anormalidades conocidas o presuntas de los organos pelvianos de la madre"
  },
  {
     id:"O340",
     enfermedad:"Atención materna por anomalia congenita del utero"
  },
  {
     id:"O341",
     enfermedad:"Atención materna por tumor del cuerpo del utero"
  },
  {
     id:"O342",
     enfermedad:"Atención materna por cicatriz uterina debida a cirugia previa"
  },
  {
     id:"O343",
     enfermedad:"Atención materna por incompetencia del cuello uterino"
  },
  {
     id:"O344",
     enfermedad:"Atención materna por otra anormalidad del cuello uterino"
  },
  {
     id:"O345",
     enfermedad:"Atención materna por otras anormalidades del utero gravido"
  },
  {
     id:"O346",
     enfermedad:"Atención materna por anormalidad de la vagina"
  },
  {
     id:"O347",
     enfermedad:"Atención materna por anormalidad de la vulva y del perineo"
  },
  {
     id:"O348",
     enfermedad:"Atención materna por anormalidades de los organos pelvianos"
  },
  {
     id:"O349",
     enfermedad:"Atención materna por anormalidad no especificada de organo pelviano"
  },
  {
     id:"O35",
     enfermedad:"Atención materna por anormalidad o lesion fetal, conocida o presunta"
  },
  {
     id:"O350",
     enfermedad:"Atención materna por (presunta) malformacion del sistema nervioso central en el feto"
  },
  {
     id:"O351",
     enfermedad:"Atención materna por (presunta) anormalidad cromosomica en el feto"
  },
  {
     id:"O352",
     enfermedad:"Atención materna por (presunta) enfermedad hereditaria en el feto"
  },
  {
     id:"O353",
     enfermedad:"Atención materna por (presunta) lesion fetal debida a enfermedad virica en la madre"
  },
  {
     id:"O354",
     enfermedad:"Atención materna por (presunta) lesion al feto debida al alcohol"
  },
  {
     id:"O355",
     enfermedad:"Atención materna por (presunta) lesion fetal debida a drogas"
  },
  {
     id:"O356",
     enfermedad:"Atención materna por (presunta) lesion al feto debida a radiacion"
  },
  {
     id:"O357",
     enfermedad:"Atención materna por (presunta) lesion fetal debida a otros procedimientos medicos"
  },
  {
     id:"O358",
     enfermedad:"Atención materna por otras (presunta) anormalidades y lesiones fetales"
  },
  {
     id:"O359",
     enfermedad:"Atención materna por (presunta) anormalidad y lesion fetal no especificada"
  },
  {
     id:"O36",
     enfermedad:"Atención materna por otros problemas fetales, conocidos o presuntos"
  },
  {
     id:"O360",
     enfermedad:"Atención materna por isoinmunizacion rhesus"
  },
  {
     id:"O361",
     enfermedad:"Atención materna por otra isoinmunizacion"
  },
  {
     id:"O362",
     enfermedad:"Atención materna por hidropesia fetal"
  },
  {
     id:"O363",
     enfermedad:"Atención materna por signos de hipoxia fetal"
  },
  {
     id:"O364",
     enfermedad:"Atención materna por muerte intrauterina"
  },
  {
     id:"O365",
     enfermedad:"Atención materna por deficit del crecimiento fetal"
  },
  {
     id:"O366",
     enfermedad:"Atención materna por crecimiento fetal excesivo"
  },
  {
     id:"O367",
     enfermedad:"Atención materna por feto viable en embarazo abdominal"
  },
  {
     id:"O368",
     enfermedad:"Atención materna por otros problemas fetales especificados"
  },
  {
     id:"O369",
     enfermedad:"Atención materna por otros problemas fetales no especificados"
  },
  {
     id:"O40",
     enfermedad:"Polihidramnios"
  },
  {
     id:"O41",
     enfermedad:"Otros trastornos del liquido amniotico y de las membranas"
  },
  {
     id:"O410",
     enfermedad:"Oligohidramnios"
  },
  {
     id:"O411",
     enfermedad:"Infeccion de la bolsa amniotica o de las membranas"
  },
  {
     id:"O418",
     enfermedad:"Otros trastornos especificados del liquido amniotico y de las membranas"
  },
  {
     id:"O419",
     enfermedad:"Trastorno del liquido amniotico y de las membranas, no especificado"
  },
  {
     id:"O42",
     enfermedad:"Ruptura prematura de las membranas"
  },
  {
     id:"O420",
     enfermedad:"Ruptura prematura de las membranas, e inicio del trabajo de parto dentro de las 24 horas"
  },
  {
     id:"O421",
     enfermedad:"Ruptura prematura de las membranas, e inicio del trabajo de parto despues de las 24 horas"
  },
  {
     id:"O422",
     enfermedad:"Ruptura prematura de las membranas, trabajo de parto retrasado por la terapeutica"
  },
  {
     id:"O429",
     enfermedad:"Ruptura prematura de las membranas, sin otra especificacion"
  },
  {
     id:"O43",
     enfermedad:"Trastornos placentarios"
  },
  {
     id:"O430",
     enfermedad:"Sindrome de transfusion placentera"
  },
  {
     id:"O431",
     enfermedad:"Malformacion de la placenta"
  },
  {
     id:"O438",
     enfermedad:"Otros trastornos placentarios"
  },
  {
     id:"O439",
     enfermedad:"Trastorno de la placenta, no especificado"
  },
  {
     id:"O44",
     enfermedad:"Placenta previa"
  },
  {
     id:"O440",
     enfermedad:"Placenta previa con especificacion de que no hubo hemorragia"
  },
  {
     id:"O441",
     enfermedad:"Placenta previa con hemorragia"
  },
  {
     id:"O45",
     enfermedad:"Desprendimiento prematuro de la placenta"
  },
  {
     id:"O450",
     enfermedad:"Desprendimiento prematuro de la placenta con defecto de la coagulacion"
  },
  {
     id:"O458",
     enfermedad:"Otros desprendimientos prematuros de la placenta"
  },
  {
     id:"O459",
     enfermedad:"Desprendimiento prematuro de la placenta, sin otra especificacion"
  },
  {
     id:"O46",
     enfermedad:"Hemorragia anteparto no clasificada en otra parte"
  },
  {
     id:"O460",
     enfermedad:"Hemorragia anteparto con defecto de la coagulacion"
  },
  {
     id:"O468",
     enfermedad:"Otras hemorragias anteparto"
  },
  {
     id:"O469",
     enfermedad:"Hemorragia anteparto, no especificada"
  },
  {
     id:"O47",
     enfermedad:"Falso trabajo de parto"
  },
  {
     id:"O470",
     enfermedad:"Falso trabajo de parto antes de la 37 semanas completas de gestacion"
  },
  {
     id:"O471",
     enfermedad:"Falso trabajo de parto antes de la 37 y mas semanas completas de gestacion"
  },
  {
     id:"O479",
     enfermedad:"Falso trabajo de parto sin otra especificacion"
  },
  {
     id:"O48",
     enfermedad:"Embarazo prolongado"
  },
  {
     id:"O60",
     enfermedad:"Parto prematuro"
  },
  {
     id:"O61",
     enfermedad:"Fracaso de la induccion del trabajo de parto"
  },
  {
     id:"O610",
     enfermedad:"Fracaso de la induccion medica del trabajo de parto"
  },
  {
     id:"O611",
     enfermedad:"Fracaso de la induccion instrumental del trabajo de parto"
  },
  {
     id:"O618",
     enfermedad:"Otros fracasos de la induccion del trabajo de parto"
  },
  {
     id:"O619",
     enfermedad:"Fracaso no especificado de la induccion del trabajo de parto"
  },
  {
     id:"O62",
     enfermedad:"Anormalidades de la dinamica del trabajo de parto"
  },
  {
     id:"O620",
     enfermedad:"Contracciones primarias inadecuadas"
  },
  {
     id:"O621",
     enfermedad:"Inercia uterina secundaria"
  },
  {
     id:"O622",
     enfermedad:"Otras inercias uterinas"
  },
  {
     id:"O623",
     enfermedad:"Trabajo de parto precipitado"
  },
  {
     id:"O624",
     enfermedad:"Contracciones uterinas hipertonicas, incoordinadas y prolongadas"
  },
  {
     id:"O628",
     enfermedad:"Otras anomalias dinamicas del trabajo de parto"
  },
  {
     id:"O629",
     enfermedad:"Anomalia dinamica del trabajo de parto, no especificada"
  },
  {
     id:"O63",
     enfermedad:"Trabajo de parto prolongado"
  },
  {
     id:"O630",
     enfermedad:"Prolongacion del primer periodo (del trabajo de parto)"
  },
  {
     id:"O631",
     enfermedad:"Prolongacion del segundo periodo (del trabajo de parto)"
  },
  {
     id:"O632",
     enfermedad:"Retraso de la expulsion del segundo gemelo, del tercero, etc"
  },
  {
     id:"O639",
     enfermedad:"Trabajo de parto prolongado, no especificado"
  },
  {
     id:"O64",
     enfermedad:"Trabajo de parto obstruido debido mala posicion y presentación anormal del feto"
  },
  {
     id:"O640",
     enfermedad:"Trabajo de parto obstruido debido a rotacion incompleta de la cabeza fetal"
  },
  {
     id:"O641",
     enfermedad:"Trabajo de parto obstruido debido a presentación de nalgas"
  },
  {
     id:"O642",
     enfermedad:"Trabajo de parto obstruido debido a presentación de cara"
  },
  {
     id:"O643",
     enfermedad:"Trabajo de parto obstruido debido a presentación de frente"
  },
  {
     id:"O644",
     enfermedad:"Trabajo de parto obstruido debido a presentación de hombro"
  },
  {
     id:"O645",
     enfermedad:"Trabajo de parto obstruido debido a presentación compuesta"
  },
  {
     id:"O648",
     enfermedad:"Trabajo de parto obstruido debido a otras presentaciones anormales del feto"
  },
  {
     id:"O649",
     enfermedad:"Trabajo de parto obstruido debido a presentación anormal del feto no especificada"
  },
  {
     id:"O65",
     enfermedad:"Trabajo de parto obstruido debido a anormalidad de la pelvis materna"
  },
  {
     id:"O650",
     enfermedad:"Trabajo de parto obstruido debido a deformidad de la pelvis"
  },
  {
     id:"O651",
     enfermedad:"Trabajo de parto obstruido debido a estrechez general de la pelvis"
  },
  {
     id:"O652",
     enfermedad:"Trabajo de parto obstruido debido a disminucion del estrecho superior de la pelvis"
  },
  {
     id:"O653",
     enfermedad:"Trabajo de parto obstruido debido a disminucion del estrecho inferior de la pelvis"
  },
  {
     id:"O654",
     enfermedad:"Trabajo de parto obstruido debido a desproporcion fetopelviana, sin otra especificacion"
  },
  {
     id:"O655",
     enfermedad:"Trabajo de parto obstruido debido a anomalias de los organos pelvianos maternos"
  },
  {
     id:"O658",
     enfermedad:"Trabajo de parto obstruido debido a otras anomalias pelvianas maternas"
  },
  {
     id:"O659",
     enfermedad:"Trabajo de parto obstruido debido a anomalia pelviana no especificada"
  },
  {
     id:"O66",
     enfermedad:"Otras obstrucciones del trabajo de parto"
  },
  {
     id:"O660",
     enfermedad:"Trabajo de parto obstruido debido a distocia de hombros"
  },
  {
     id:"O661",
     enfermedad:"Trabajo de parto obstruido debido a distocia gemelar"
  },
  {
     id:"O662",
     enfermedad:"Trabajo de parto obstruido debido a distocia por feto inusualmente grande"
  },
  {
     id:"O663",
     enfermedad:"Trabajo de parto obstruido debido a otras anormalidades del feto"
  },
  {
     id:"O664",
     enfermedad:"Fracaso de la prueba del trabajo de parto, no especificada"
  },
  {
     id:"O665",
     enfermedad:"Fracaso no especificado de la aplicacion de forceps o de ventosa extractora"
  },
  {
     id:"O668",
     enfermedad:"Otras obstrucciones especificadas del trabajo de parto"
  },
  {
     id:"O669",
     enfermedad:"Trabajo de parto obstruido, sin otra especificacion"
  },
  {
     id:"O67",
     enfermedad:"Trabajo de parto y parto complicados por hemorragia intraparto no clasificados en otra parte"
  },
  {
     id:"O670",
     enfermedad:"Hemorragia intraparto con defectos de la coagulacion"
  },
  {
     id:"O678",
     enfermedad:"Otras hemorragias intraparto"
  },
  {
     id:"O679",
     enfermedad:"Hemorragia intraparto, no especificada"
  },
  {
     id:"O68",
     enfermedad:"Trabajo de parto y parto complicados por sufrimiento fetal"
  },
  {
     id:"O680",
     enfermedad:"Trabajo de parto y parto complicados por anomalias de la frecuencia cardiaca fetal"
  },
  {
     id:"O681",
     enfermedad:"Trabajo de parto y parto complicados por la presencia de meconio en el liquido amniotico"
  },
  {
     id:"O682",
     enfermedad:"Trabajo de parto y parto complicados por anomalia de la frecuencia cardiaca fetal asociada con presencia de meconio en liquido amniotico"
  },
  {
     id:"O683",
     enfermedad:"Trabajo de parto y parto complicados por evidencia bioquimica de sufrimiento fetal"
  },
  {
     id:"O688",
     enfermedad:"Trabajo de parto y parto complicados por otras evidencias de sufrimiento fetal"
  },
  {
     id:"O689",
     enfermedad:"Trabajo de parto y parto complicados por sufrimiento fetal, sin otra especificacion"
  },
  {
     id:"O69",
     enfermedad:"Trabajo de parto y parto complicados por problemas del cordon umbilical"
  },
  {
     id:"O690",
     enfermedad:"Trabajo de parto y parto complicados por prolapso del cordon umbilical"
  },
  {
     id:"O691",
     enfermedad:"Trabajo de parto y parto complicados por circular pericervical del cordon, con compresion"
  },
  {
     id:"O692",
     enfermedad:"Trabajo de parto y parto complicados por otros enredos del cordon"
  },
  {
     id:"O693",
     enfermedad:"Trabajo de parto y parto complicados por cordon umbilical corto"
  },
  {
     id:"O694",
     enfermedad:"Trabajo de parto y parto complicados por vasa previa"
  },
  {
     id:"O695",
     enfermedad:"Trabajo de parto y parto complicados por lesion vascular del cordon"
  },
  {
     id:"O698",
     enfermedad:"Trabajo de parto y parto complicados por otros problemas del cordon umbilical"
  },
  {
     id:"O699",
     enfermedad:"Trabajo de parto y parto complicados por problemas no especificados del cordon umbilical"
  },
  {
     id:"O70",
     enfermedad:"Desgarro perineal durante el parto"
  },
  {
     id:"O700",
     enfermedad:"Desgarro perineal de primer grado durante el parto"
  },
  {
     id:"O701",
     enfermedad:"Desgarro perineal de segundo grado durante el parto"
  },
  {
     id:"O702",
     enfermedad:"Desgarro perineal de tercer grado durante el parto"
  },
  {
     id:"O703",
     enfermedad:"Desgarro perineal de cuarto grado durante el parto"
  },
  {
     id:"O709",
     enfermedad:"Desgarro perineal durante el parto, de grado no especificado"
  },
  {
     id:"O71",
     enfermedad:"Otro trauma obstetrico"
  },
  {
     id:"O710",
     enfermedad:"Ruptura del utero antes del inicio del trabajo de parto"
  },
  {
     id:"O711",
     enfermedad:"Ruptura del utero durante el trabajo de parto"
  },
  {
     id:"O712",
     enfermedad:"Inversion del utero, postparto"
  },
  {
     id:"O713",
     enfermedad:"Desgarro obstetrico del cuello uterino"
  },
  {
     id:"O714",
     enfermedad:"Desgarro vaginal obstetrico alto, solo"
  },
  {
     id:"O715",
     enfermedad:"Otros traumatismos obstetricos de los organos pelvianos"
  },
  {
     id:"O716",
     enfermedad:"Traumatismo obstetrico de los ligamentos articulaciones de la pelvis"
  },
  {
     id:"O717",
     enfermedad:"Hematoma obstetrico de la pelvis"
  },
  {
     id:"O718",
     enfermedad:"Otros traumas obstetricos especificados"
  },
  {
     id:"O719",
     enfermedad:"Trauma obstetrico, no especificado"
  },
  {
     id:"O72",
     enfermedad:"Hemorragia postparto"
  },
  {
     id:"O720",
     enfermedad:"Hemorragia del tercer periodo del parto"
  },
  {
     id:"O721",
     enfermedad:"Otras hemorragias postparto inmediatas"
  },
  {
     id:"O722",
     enfermedad:"Hemorragia postparto secundaria o tardia"
  },
  {
     id:"O723",
     enfermedad:"Defecto de la coagulacion postparto"
  },
  {
     id:"O73",
     enfermedad:"Retencion de la placenta o de las membranas, sin hemorragia"
  },
  {
     id:"O730",
     enfermedad:"Retencion de la placenta sin hemorragia"
  },
  {
     id:"O731",
     enfermedad:"Retencion de fragmentos de la placenta o de las membranas, sin hemorragia"
  },
  {
     id:"O74",
     enfermedad:"Complicaciones de la anestesia administrada durante el trabajo de parto y el parto"
  },
  {
     id:"O740",
     enfermedad:"Neumonitis por aspiracion debida a la anestesia administrada durante el trabajo de parto y el parto"
  },
  {
     id:"O741",
     enfermedad:"Otras complicaciones pulmonares debidas a la anestesia administrada durante el trabajo de parto y el parto"
  },
  {
     id:"O742",
     enfermedad:"Complicaciones cardiacas de la anestesia administrada durante el trabajo de parto y el parto"
  },
  {
     id:"O743",
     enfermedad:"Complicaciones del sistema nervioso central por la anestesia administrada durante el trabajo de parto y el parto"
  },
  {
     id:"O744",
     enfermedad:"Reaccion toxica a la anestesia local administrada durante el trabajo de parto y el parto"
  },
  {
     id:"O745",
     enfermedad:"Cefalalgia inducida por la anestesia espinal o epidural administradas durante el trabajo de parto y el parto"
  },
  {
     id:"O746",
     enfermedad:"Otras complicaciones de la anestesia espinal o epidural administradas durante el trabajo de parto y el parto"
  },
  {
     id:"O747",
     enfermedad:"Falla o dificultad en la intubacion durante el trabajo de parto y el parto"
  },
  {
     id:"O748",
     enfermedad:"Otras complicaciones de la anestesia administrada durante el trabajo de parto y el parto"
  },
  {
     id:"O749",
     enfermedad:"Complicacion no especificada de la anestesia administrada durante el trabajo de parto y el parto"
  },
  {
     id:"O75",
     enfermedad:"Otras complicaciones del trabajo de parto y del parto no clasificadas en otra parte"
  },
  {
     id:"O750",
     enfermedad:"Sufrimiento materno durante el trabajo de parto y el parto"
  },
  {
     id:"O751",
     enfermedad:"Choque durante o despues del trabajo de parto y el parto"
  },
  {
     id:"O752",
     enfermedad:"Pirexa durante el trabajo de parto, no clasificada en otra parte"
  },
  {
     id:"O753",
     enfermedad:"Otras infecciones durante el trabajo de parto"
  },
  {
     id:"O754",
     enfermedad:"Otras complicaciones de procedimientos y de cirugia obstetrica"
  },
  {
     id:"O755",
     enfermedad:"Retraso del parto despues de la ruptura artificial de las membranas"
  },
  {
     id:"O756",
     enfermedad:"Retraso del parto despues de la ruptura espontanea o no especificada de las membranas"
  },
  {
     id:"O757",
     enfermedad:"Parto vaginal posterior a una cesarea previa"
  },
  {
     id:"O758",
     enfermedad:"Otras complicaciones especificadas del trabajo de parto y del parto"
  },
  {
     id:"O759",
     enfermedad:"Complicacion no especificada del trabajo de parto y del parto"
  },
  {
     id:"O80",
     enfermedad:"Parto unico espontaneo"
  },
  {
     id:"O800",
     enfermedad:"Parto unico espontaneo, presentación cefalica de vertice"
  },
  {
     id:"O801",
     enfermedad:"Parto unico espontaneo, presentación de nalgas o podalica"
  },
  {
     id:"O808",
     enfermedad:"Parto unico espontaneo, otras presentaciones"
  },
  {
     id:"O809",
     enfermedad:"Parto unico espontaneo, sin otra especificacion"
  },
  {
     id:"O81",
     enfermedad:"Parto unico con forceps y ventosa extractora"
  },
  {
     id:"O810",
     enfermedad:"Parto con forceps bajo"
  },
  {
     id:"O811",
     enfermedad:"Parto con forceps medio"
  },
  {
     id:"O812",
     enfermedad:"Parto con forceps medio con rotacion"
  },
  {
     id:"O813",
     enfermedad:"Parto con forceps de otros tipos y los no especificados"
  },
  {
     id:"O814",
     enfermedad:"Parto con ventosa extractora"
  },
  {
     id:"O815",
     enfermedad:"Parto con combinacion de forceps y ventosa extractora"
  },
  {
     id:"O82",
     enfermedad:"Parto unico por cesarea"
  },
  {
     id:"O820",
     enfermedad:"Parto por cesarea electiva"
  },
  {
     id:"O821",
     enfermedad:"Parto por cesarea de emergencia"
  },
  {
     id:"O822",
     enfermedad:"Parto por cesarea con histerectomia"
  },
  {
     id:"O828",
     enfermedad:"Otros partos unicos por cesarea"
  },
  {
     id:"O829",
     enfermedad:"Parto por cesarea, sin otra especificacion"
  },
  {
     id:"O83",
     enfermedad:"Otros partos unicos asistidos"
  },
  {
     id:"O830",
     enfermedad:"Extraccion de nalgas"
  },
  {
     id:"O831",
     enfermedad:"Otros partos unicos asistidos, de nalgas"
  },
  {
     id:"O832",
     enfermedad:"Otros partos unicos con ayuda de manipulacion obstetrica"
  },
  {
     id:"O833",
     enfermedad:"Parto de feto viable en embarazo abdominal"
  },
  {
     id:"O834",
     enfermedad:"Operación destructiva para facilitar el parto"
  },
  {
     id:"O838",
     enfermedad:"Otros partos unicos asistidos especificados"
  },
  {
     id:"O839",
     enfermedad:"Parto unico asistido, sin otra especificacion"
  },
  {
     id:"O84",
     enfermedad:"Parto multiple"
  },
  {
     id:"O840",
     enfermedad:"Parto multiple, todos espontaneos"
  },
  {
     id:"O841",
     enfermedad:"Parto multiple, todos forceps y ventosa extractora"
  },
  {
     id:"O842",
     enfermedad:"Parto multiple, todos por cesarea"
  },
  {
     id:"O848",
     enfermedad:"Otros partos multiples"
  },
  {
     id:"O849",
     enfermedad:"Parto multiple, no especificado"
  },
  {
     id:"O85",
     enfermedad:"Sepsis puerperal"
  },
  {
     id:"O86",
     enfermedad:"Otras infecciones puerperales"
  },
  {
     id:"O860",
     enfermedad:"Infeccion de herida quirurgica obstetrica"
  },
  {
     id:"O861",
     enfermedad:"Otras infecciones genitales consecutivas al parto"
  },
  {
     id:"O862",
     enfermedad:"Infeccion de las vias urinarias consecutiva al parto"
  },
  {
     id:"O863",
     enfermedad:"Otras infecciones de las vias genitourinarias consecutivas al parto"
  },
  {
     id:"O864",
     enfermedad:"Pirexa de origen desconocido consecutiva al parto"
  },
  {
     id:"O868",
     enfermedad:"Otras infecciones puerperales especificadas"
  },
  {
     id:"O87",
     enfermedad:"Complicaciones venosas en el puerperio"
  },
  {
     id:"O870",
     enfermedad:"Tromboflebitis superficial en el puerperio"
  },
  {
     id:"O871",
     enfermedad:"Flebotrombosis profunda en el puerperio"
  },
  {
     id:"O872",
     enfermedad:"Hemorroides en el puerperio"
  },
  {
     id:"O873",
     enfermedad:"Trobosis venosa cerebral en el puerperio"
  },
  {
     id:"O878",
     enfermedad:"Otras complicaciones venosas en el puerperio"
  },
  {
     id:"O879",
     enfermedad:"Complicacion venosa en el puerperio, no especificada"
  },
  {
     id:"O88",
     enfermedad:"Embolia obstetrica"
  },
  {
     id:"O880",
     enfermedad:"Embolia gaseosa, obstetrica"
  },
  {
     id:"O881",
     enfermedad:"Embolia de liquido amniotico"
  },
  {
     id:"O882",
     enfermedad:"Embolia de coagulo sanguíneo, obstetrica"
  },
  {
     id:"O883",
     enfermedad:"Embolia septica y piemica, obstetrica"
  },
  {
     id:"O888",
     enfermedad:"Otras embolias obstetricas"
  },
  {
     id:"O89",
     enfermedad:"Complicacion de la anestesia administrada durante el puerperio"
  },
  {
     id:"O890",
     enfermedad:"Complicaciones pulmonares de la anestesia administrada durante el puerperio"
  },
  {
     id:"O891",
     enfermedad:"Complicaciones cardiacas de la anestesia administrada durante el puerperio"
  },
  {
     id:"O892",
     enfermedad:"Complicaciones del sistema nervioso central debidas a la anestesia administrada durante el puerperio"
  },
  {
     id:"O893",
     enfermedad:"Reaccion toxica a la anestesia local administrada durante el puerperio"
  },
  {
     id:"O894",
     enfermedad:"Cefalalgia inducida por la anestesia espinal o epidural administradas durante el puerperio"
  },
  {
     id:"O895",
     enfermedad:"Otras complicaciones de la anestesia espinal o epidural administradas durante el puerperio"
  },
  {
     id:"O896",
     enfermedad:"Falla o dificultad en la intubacion durante el puerperio"
  },
  {
     id:"O898",
     enfermedad:"Otras complicaciones de la anestesia administrada durante el puerperio"
  },
  {
     id:"O899",
     enfermedad:"Complicacion no especificada de la anestesia administrada durante el trabajo puerperio"
  },
  {
     id:"O90",
     enfermedad:"Complicaciones del puerperio no clasificadas en otra parte"
  },
  {
     id:"O900",
     enfermedad:"Dehiscencia de sutura de cesarea"
  },
  {
     id:"O901",
     enfermedad:"Dehiscencia de sutura obstetrica perineal"
  },
  {
     id:"O902",
     enfermedad:"Hematoma de herida quirurgica obstetrica"
  },
  {
     id:"O903",
     enfermedad:"Cardiomiopatia en el puerperio"
  },
  {
     id:"O904",
     enfermedad:"Insuficiencia renal aguda postparto"
  },
  {
     id:"O905",
     enfermedad:"Tiroiditis postparto"
  },
  {
     id:"O908",
     enfermedad:"Otras complicaciones puerperales, no clasificadas en otra parte"
  },
  {
     id:"O909",
     enfermedad:"Complicacion puerperal , no especificada"
  },
  {
     id:"O91",
     enfermedad:"Infecciones de la mama asociadas con el parto"
  },
  {
     id:"O910",
     enfermedad:"Infecciones del pezon asociadas con el parto"
  },
  {
     id:"O911",
     enfermedad:"Absceso de la mama asociado con el parto"
  },
  {
     id:"O912",
     enfermedad:"Mastitis no purulenta asociada con el parto"
  },
  {
     id:"O92",
     enfermedad:"Otros trastornos de la mama y de la lactancia asociados con el parto"
  },
  {
     id:"O920",
     enfermedad:"Retraccion del pezon asociada con el parto"
  },
  {
     id:"O921",
     enfermedad:"Fisuras del pezon asociadas con el parto"
  },
  {
     id:"O922",
     enfermedad:"Otros trastornos de la mama y los no especificados asociados con el parto"
  },
  {
     id:"O923",
     enfermedad:"Agalactia"
  },
  {
     id:"O924",
     enfermedad:"Hipogalactia"
  },
  {
     id:"O925",
     enfermedad:"Supresion de la lactancia"
  },
  {
     id:"O926",
     enfermedad:"Galactorrea"
  },
  {
     id:"O927",
     enfermedad:"Otros trastornos y los no especificados de la lactancia"
  },
  {
     id:"O93",
     enfermedad:"Muerte materna de causa basica especificada en otro capitulo relacionada con el embarazo"
  },
  {
     id:"O95",
     enfermedad:"Muerte obstetrica de causa no especificada"
  },
  {
     id:"O96",
     enfermedad:"Muerte materna debida a cualquier causa obstetrica que ocurre despues de 42 días pero antes de un año del parto"
  },
  {
     id:"O97",
     enfermedad:"Muerte por secuelas de causas obstetricas directas"
  },
  {
     id:"O98",
     enfermedad:"Enfermedades maternas infecciosas y parasitarias clasificables en otra parte, pero que complican el embarazo, el parto y el puerperio"
  },
  {
     id:"O980",
     enfermedad:"Tuberculosis que complica el embarazo, el parto y el puerperio"
  },
  {
     id:"O981",
     enfermedad:"Sifilis que complica el embarazo, el parto y el puerperio"
  },
  {
     id:"O982",
     enfermedad:"Gonorrea a que complica el embarazo, el parto y el puerperio"
  },
  {
     id:"O983",
     enfermedad:"Otras infecciones con un modo de transmision predominante sexual que complican el embarazo, el parto y el puerperio"
  },
  {
     id:"O984",
     enfermedad:"Hepatitis viral que complica el embarazo, el parto y el puerperio"
  },
  {
     id:"O985",
     enfermedad:"Otras enfermedades virales que complican el embarazo, el parto y el puerperio"
  },
  {
     id:"O986",
     enfermedad:"Enfermedades causadas por protozoarios que complican el embarazo, el parto y el puerperio"
  },
  {
     id:"O988",
     enfermedad:"Otras enfermedades infecciosas y parasitarias maternas que complican el embarazo, el parto y el puerperio"
  },
  {
     id:"O989",
     enfermedad:"Enfermedad infecciosa y parasitaria materna no especificada que complica el embarazo, el parto y el puerperio"
  },
  {
     id:"O99",
     enfermedad:"Otras enfermedades maternas clasificables en otra parte, pero que complican el embarazo, el parto y el puerperio"
  },
  {
     id:"O990",
     enfermedad:"Anemia que complica el embarazo, el parto y el puerperio"
  },
  {
     id:"O991",
     enfermedad:"Otras enfermedades de la sangre y de los organos hematopoyeticos y ciertos trastornos que afectan el sistema inmunitario cuando complican el embarazo, el parto y el puerperio"
  },
  {
     id:"O992",
     enfermedad:"Enfermedades endocrinas, de la nutricion y del metabolismo que complican el embarazo, el parto y el puerperio"
  },
  {
     id:"O993",
     enfermedad:"Trastornos mentales y enfermedades del sistema nervioso que complican el embarazo, el parto y el puerperio"
  },
  {
     id:"O994",
     enfermedad:"Enfermedades del sistema circulatorio que complican el embarazo, el parto y el puerperio"
  },
  {
     id:"O995",
     enfermedad:"Enfermedades del sistema respiratorio que complican el embarazo, el parto y el puerperio"
  },
  {
     id:"O996",
     enfermedad:"Enfermedades del sistema digestivo que complican el embarazo, el parto y el puerperio"
  },
  {
     id:"O997",
     enfermedad:"Enfermedades de la piel y de tejido subcutaneo que complican el embarazo, el parto y el puerperio"
  },
  {
     id:"O998",
     enfermedad:"Otras enfermedades especificadas y afecciones que complican el embarazo, el parto y el puerperio"
  },
  {
     id:"P00",
     enfermedad:"Feto y recien nacido afectados por condiciones de la madre no necesariamente relacionadas con el embarazo presente"
  },
  {
     id:"P000",
     enfermedad:"Feto y recien nacido afectados por trastornos hipertensivos de la madre"
  },
  {
     id:"P001",
     enfermedad:"Feto y recien nacido afectados por enfermedades renales y de las vias urinarias de la madre"
  },
  {
     id:"P002",
     enfermedad:"Feto y recien nacido afectados por enfermedades infecciosas y parasitarias de la madre"
  },
  {
     id:"P003",
     enfermedad:"Feto y recien nacido afectados por enfermedades circulatorias y respiratorias de la madre"
  },
  {
     id:"P004",
     enfermedad:"Feto y recien nacido afectados por trastornos nutricionales de la madre"
  },
  {
     id:"P005",
     enfermedad:"Feto y recien nacido afectados por traumatismo de la madre"
  },
  {
     id:"P006",
     enfermedad:"Feto y recien nacido afectados por procedimiento quirúrgico de la madre"
  },
  {
     id:"P007",
     enfermedad:"Feto y recien nacido afectados por otro procedimiento medico de la madre, no clasificado en otra parte"
  },
  {
     id:"P008",
     enfermedad:"Feto y recien nacido afectados por otras afecciones maternas"
  },
  {
     id:"P009",
     enfermedad:"Feto y recien nacido afectados por afeccion materna no especificada"
  },
  {
     id:"P01",
     enfermedad:"Feto y recien nacido afectados por complicaciones maternas del embarazo"
  },
  {
     id:"P010",
     enfermedad:"Feto y recien nacido afectados por incompetencia del cuello uterino"
  },
  {
     id:"P011",
     enfermedad:"Feto y recien nacido afectados por ruptura prematura de las membranas"
  },
  {
     id:"P012",
     enfermedad:"Feto y recien nacido afectados por oligohidramnios"
  },
  {
     id:"P013",
     enfermedad:"Feto y recien nacido afectados por polihidramnios"
  },
  {
     id:"P014",
     enfermedad:"Feto y recien nacido afectados por embarazo ectopico"
  },
  {
     id:"P015",
     enfermedad:"Feto y recien nacido afectados por embarazo multiple"
  },
  {
     id:"P016",
     enfermedad:"Feto y recien nacido afectados por muerte materna"
  },
  {
     id:"P017",
     enfermedad:"Feto y recien nacido afectados por presentación anomala antes del trabajo de parto"
  },
  {
     id:"P018",
     enfermedad:"Feto y recien nacido afectados por otras complicaciones maternas del embarazo"
  },
  {
     id:"P019",
     enfermedad:"Feto y recien nacido afectados por complicaciones maternas no especificadas del embarazo"
  },
  {
     id:"P02",
     enfermedad:"Feto y recien nacido afectados por complicaciones de la placenta, del cordon umbilical y de las membranas"
  },
  {
     id:"P020",
     enfermedad:"Feto y recien nacido afectados por placenta previa"
  },
  {
     id:"P021",
     enfermedad:"Feto y recien nacido afectados por otras formas de desprendimiento y de hemorragia placentarios"
  },
  {
     id:"P022",
     enfermedad:"Feto y recien nacido afectados por otras anomalias morfologicas y funcionales de la placenta y las no especificadas"
  },
  {
     id:"P023",
     enfermedad:"Feto y recien nacido afectados por sindromes de transfusion placentaria"
  },
  {
     id:"P024",
     enfermedad:"Feto y recien nacido afectados por prolapso del cordon umbilical"
  },
  {
     id:"P025",
     enfermedad:"Feto y recien nacido afectados por otra compresion del cordon umbilical"
  },
  {
     id:"P026",
     enfermedad:"Feto y recien nacido afectados por otras complicaciones del cordon umbilical y las no especificadas"
  },
  {
     id:"P027",
     enfermedad:"Feto y recien nacido afectados por corioamnionitis"
  },
  {
     id:"P028",
     enfermedad:"Feto y recien nacido afectados por otras anormalidades de las membranas"
  },
  {
     id:"P029",
     enfermedad:"Feto y recien nacido afectados por anormalidad no especificada de las membranas"
  },
  {
     id:"P03",
     enfermedad:"Feto y recien nacido afectados por otras complicaciones del trabajo de parto y del parto"
  },
  {
     id:"P030",
     enfermedad:"Feto y recien nacido afectados por parto y extraccion de nalgas"
  },
  {
     id:"P031",
     enfermedad:"Feto y recien nacido afectados por otra presentación anomala, posicion anomala y desproporcion durante el trabajo de parto y el parto"
  },
  {
     id:"P032",
     enfermedad:"Feto y recien nacido afectados por parto con forceps"
  },
  {
     id:"P033",
     enfermedad:"Feto y recien nacido afectados por parto con ventosa extractora"
  },
  {
     id:"P034",
     enfermedad:"Feto y recien nacido afectados por parto por cesarea"
  },
  {
     id:"P035",
     enfermedad:"Feto y recien nacido afectados por parto precipitado"
  },
  {
     id:"P036",
     enfermedad:"Feto y recien nacido afectados por contracciones uterinas anormales"
  },
  {
     id:"P038",
     enfermedad:"Feto y recien nacido afectados por otras complicaciones especificadas del trabajo de parto y del parto"
  },
  {
     id:"P039",
     enfermedad:"Feto y recien nacido afectados por complicaciones no especificadas del trabajo de parto y del parto"
  },
  {
     id:"P04",
     enfermedad:"Feto y recien nacido afectados por influencias nocivas transmitidas a traves de la placenta o de la leche materna"
  },
  {
     id:"P040",
     enfermedad:"Feto y recien nacido afectados por anestesia y analgesia materna en el embarazo, en el trabajo de parto y en el parto"
  },
  {
     id:"P041",
     enfermedad:"Feto y recien nacido afectados por otras medicaciones maternas"
  },
  {
     id:"P042",
     enfermedad:"Feto y recien nacido afectados por tabaquismo de la madre"
  },
  {
     id:"P043",
     enfermedad:"Feto y recien nacido afectados por alcoholismo de la madre"
  },
  {
     id:"P044",
     enfermedad:"Feto y recien nacido afectados por drogadiccion materna"
  },
  {
     id:"P045",
     enfermedad:"Feto y recien nacido afectados por el uso materno de sustancias quimicas nutricionales"
  },
  {
     id:"P046",
     enfermedad:"Feto y recien nacido afectados por exposicion materna a sustancias quimicas ambientales"
  },
  {
     id:"P048",
     enfermedad:"Feto y recien nacido afectados por otras influencias nocivas de la madre"
  },
  {
     id:"P049",
     enfermedad:"Feto y recien nacido afectados por influencias nocivas de la madre, no especificadas"
  },
  {
     id:"P05",
     enfermedad:"Retardo del crecimiento fetal y desnutricion fetal"
  },
  {
     id:"P050",
     enfermedad:"Bajo peso para la edad gestacional"
  },
  {
     id:"P051",
     enfermedad:"Pequeño para edad gestacional"
  },
  {
     id:"P052",
     enfermedad:"Desnutricion fetal, sin mencion de peso o talla bajos para la edad gestacional"
  },
  {
     id:"P059",
     enfermedad:"Retardo del crecimiento fetal, no especificado"
  },
  {
     id:"P07",
     enfermedad:"Trastornos relacionados con la duracion corta de la gestacion y con bajo peso al nacer, no clasificados en otra parte"
  },
  {
     id:"P070",
     enfermedad:"Peso extremadamente bajo al nacer"
  },
  {
     id:"P071",
     enfermedad:"Otro peso bajo al nacer"
  },
  {
     id:"P072",
     enfermedad:"Inmaturidad extrema"
  },
  {
     id:"P073",
     enfermedad:"Otros recien nacidos pretermino"
  },
  {
     id:"P08",
     enfermedad:"Trastornos relacionados con el embarazo prolongado y con sobrepeso al nacer"
  },
  {
     id:"P080",
     enfermedad:"Recien nacido excepcionalmente grande"
  },
  {
     id:"P081",
     enfermedad:"Otros recien nacidos con sobrepeso para la edad gestacional"
  },
  {
     id:"P082",
     enfermedad:"Recien nacido postermino sin sobrepeso para su edad gestacional"
  },
  {
     id:"P10",
     enfermedad:"Hemorragia y laceracion intracraneal debidas a traumatismo del nacimiento"
  },
  {
     id:"P100",
     enfermedad:"Hemorragia subdural debida a traumatismo del nacimiento"
  },
  {
     id:"P101",
     enfermedad:"Hemorragia cerebral debida a traumatismo del nacimiento"
  },
  {
     id:"P102",
     enfermedad:"Hemorragia intraventricular debida a traumatismo del nacimiento"
  },
  {
     id:"P103",
     enfermedad:"Hemorragia subaracnoidea debida a traumatismo del nacimiento"
  },
  {
     id:"P104",
     enfermedad:"Desgarro tentorial debido a traumatismo del nacimiento"
  },
  {
     id:"P108",
     enfermedad:"Otras hemorragias y laceraciones intracraneales debidas a traumatismo del nacimiento"
  },
  {
     id:"P109",
     enfermedad:"Hemorragia y laceracion intracraneales no especificadas, debidas a traumatismo del nacimiento"
  },
  {
     id:"P11",
     enfermedad:"Otros traumatismos del nacimiento en el sistema nervioso central"
  },
  {
     id:"P110",
     enfermedad:"Edema cerebral debido a traumatismo del nacimiento"
  },
  {
     id:"P111",
     enfermedad:"Otras lesiones especificadas del encefalo debidas a traumatismo del nacimiento"
  },
  {
     id:"P112",
     enfermedad:"Lesion no especificada del encefalo, debida a traumatismo del nacimiento"
  },
  {
     id:"P113",
     enfermedad:"Traumatismo del nacimiento en el nervio facial"
  },
  {
     id:"P114",
     enfermedad:"Traumatismo del nacimiento en otros nervios craneales"
  },
  {
     id:"P115",
     enfermedad:"Traumatismo del nacimiento en la columna vertebral y en la medula espinal"
  },
  {
     id:"P119",
     enfermedad:"Traumatismo del nacimiento en el sistema nervioso central, no especificado"
  },
  {
     id:"P12",
     enfermedad:"Traumatismo del nacimiento en el cuero cabelludo"
  },
  {
     id:"P120",
     enfermedad:"Cefalohematoma debido a traumatismo del nacimiento"
  },
  {
     id:"P121",
     enfermedad:"Caput succedaneum debido a traumatismo del nacimiento"
  },
  {
     id:"P122",
     enfermedad:"Hemorragia epicraneal subaponeurotica debida a traumatismo del nacimiento"
  },
  {
     id:"P123",
     enfermedad:"Equimosis del cuero cabelludo debida a traumatismo del nacimiento"
  },
  {
     id:"P124",
     enfermedad:"Traumatismo en el cuero cabelludo del recien nacido por monitoreo fetal"
  },
  {
     id:"P128",
     enfermedad:"Otros traumatismos del nacimiento en el cuero cabelludo"
  },
  {
     id:"P129",
     enfermedad:"Traumatismo del nacimiento en el cuero cabelludo, no especificado"
  },
  {
     id:"P13",
     enfermedad:"Traumatismo del esqueleto durante el nacimiento"
  },
  {
     id:"P130",
     enfermedad:"Fractura del craneo debida a traumatismo del nacimiento"
  },
  {
     id:"P131",
     enfermedad:"Otros traumatismos del craneo durante el nacimiento"
  },
  {
     id:"P132",
     enfermedad:"Traumatismo del femur durante el nacimiento"
  },
  {
     id:"P133",
     enfermedad:"Traumatismo de otros huesos largos durante el nacimiento"
  },
  {
     id:"P134",
     enfermedad:"Fractura de la clavicula debida a traumatismo del nacimiento"
  },
  {
     id:"P138",
     enfermedad:"Traumatismo del nacimiento en otras partes del esqueleto"
  },
  {
     id:"P139",
     enfermedad:"Traumatismo no especificado del esqueleto durante el nacimiento"
  },
  {
     id:"P14",
     enfermedad:"Traumatismos del sistema nervioso periferico durante el nacimiento"
  },
  {
     id:"P140",
     enfermedad:"Paralisis de erb debida a traumatismo del nacimiento"
  },
  {
     id:"P141",
     enfermedad:"Paralisis de klumpke debida a traumatismo dl nacimiento"
  },
  {
     id:"P142",
     enfermedad:"Paralisis del nervio frenico debida a traumatismo del nacimiento"
  },
  {
     id:"P143",
     enfermedad:"Otro traumatismo del plexo braquial durante el nacimiento"
  },
  {
     id:"P148",
     enfermedad:"Traumatismo durante el nacimiento en otras partes del sistema nervioso periferico"
  },
  {
     id:"P149",
     enfermedad:"Traumatismo no especificado del sistema nervioso periferico durante el nacimiento"
  },
  {
     id:"P15",
     enfermedad:"Otros traumatismos del nacimiento"
  },
  {
     id:"P150",
     enfermedad:"Lesion del higado durante el nacimiento"
  },
  {
     id:"P151",
     enfermedad:"Lesion del bazo durante el nacimiento"
  },
  {
     id:"P152",
     enfermedad:"Traumatismo del musculo esternocleidomastoideo durante el nacimiento"
  },
  {
     id:"P153",
     enfermedad:"Traumatismo ocular durante el nacimiento"
  },
  {
     id:"P154",
     enfermedad:"Traumatismo facial durante el nacimiento"
  },
  {
     id:"P155",
     enfermedad:"Traumatismo de los genitales externos durante el nacimiento"
  },
  {
     id:"P156",
     enfermedad:"Necrosis grasa subcutanea debida a traumatismo del nacimiento"
  },
  {
     id:"P158",
     enfermedad:"Otros traumatismos especificados, durante el nacimiento"
  },
  {
     id:"P159",
     enfermedad:"Traumatismo no especificado, durante el nacimiento"
  },
  {
     id:"P20",
     enfermedad:"Hipoxia intrauterina"
  },
  {
     id:"P200",
     enfermedad:"Hipoxia intrauterina notada por primera vez antes del inicio del trabajo de parto"
  },
  {
     id:"P201",
     enfermedad:"Hipoxia intrauterina notada por primera vez durante el trabajo de parto"
  },
  {
     id:"P209",
     enfermedad:"Hipoxia intrauterina, no especificada"
  },
  {
     id:"P21",
     enfermedad:"Asfixia del nacimiento"
  },
  {
     id:"P210",
     enfermedad:"Asfixia del nacimiento, severa"
  },
  {
     id:"P211",
     enfermedad:"Asfixia del nacimiento, leve y moderada"
  },
  {
     id:"P219",
     enfermedad:"Asfixia del nacimiento, no especificada"
  },
  {
     id:"P22",
     enfermedad:"Dificultad respiratoria del recien nacido"
  },
  {
     id:"P220",
     enfermedad:"Sindrome de dificultad respiratoria del recien nacido"
  },
  {
     id:"P221",
     enfermedad:"Taquipnea transitoria del recien nacido"
  },
  {
     id:"P228",
     enfermedad:"Otras dificultades respiratorias del recien nacido"
  },
  {
     id:"P229",
     enfermedad:"Dificultad respiratoria del recien nacido, no especificada"
  },
  {
     id:"P23",
     enfermedad:"Neumonia congenita"
  },
  {
     id:"P230",
     enfermedad:"Neumonia congenita debida a agente viral"
  },
  {
     id:"P231",
     enfermedad:"Neumonia congenita debida a chlamydia"
  },
  {
     id:"P232",
     enfermedad:"Neumonia congenita debida a estafilococos"
  },
  {
     id:"P233",
     enfermedad:"Neumonia congenita debida a estreptococos del grupo b"
  },
  {
     id:"P234",
     enfermedad:"Neumonia congenita debida a escherichia coli"
  },
  {
     id:"P235",
     enfermedad:"Neumonia congenita debida a pseudomonas"
  },
  {
     id:"P236",
     enfermedad:"Neumonia congenita debida a otros agentes bacterianos"
  },
  {
     id:"P238",
     enfermedad:"Neumonia congenita debida a otros organismos"
  },
  {
     id:"P239",
     enfermedad:"Neumonia congenita, organismo no especificado"
  },
  {
     id:"P24",
     enfermedad:"Sindromes de aspiracion neonatal"
  },
  {
     id:"P240",
     enfermedad:"Aspiracion neonatal de meconio"
  },
  {
     id:"P241",
     enfermedad:"Aspiracion neonatal del liquido amniotico y de moco"
  },
  {
     id:"P242",
     enfermedad:"Aspiracion neonatal de sangre"
  },
  {
     id:"P243",
     enfermedad:"Aspiracion neonatal de leche y alimento regurgitado"
  },
  {
     id:"P248",
     enfermedad:"Otros sindromes de aspiracion neonatal"
  },
  {
     id:"P249",
     enfermedad:"Sindrome de aspiracion neonatal, sin otra especificacion"
  },
  {
     id:"P25",
     enfermedad:"Enfisema intersticial y afecciones relacionadas, originadas en el periodo perinatal"
  },
  {
     id:"P250",
     enfermedad:"Enfisema intersticial originado en el periodo perinatal"
  },
  {
     id:"P251",
     enfermedad:"Neumotorax originado en el periodo perinatal"
  },
  {
     id:"P252",
     enfermedad:"Neumomediastino originado en el periodo perinatal"
  },
  {
     id:"P253",
     enfermedad:"Neumopericardio originado en el periodo perinatal"
  },
  {
     id:"P258",
     enfermedad:"Otras afecciones relacionadas con el enfisema intersticial, originadas en el periodo perinatal"
  },
  {
     id:"P26",
     enfermedad:"Hemorragia pulmonar originada en el periodo perinatal"
  },
  {
     id:"P260",
     enfermedad:"Hemorragia traqueobronquial originada en el periodo perinatal"
  },
  {
     id:"P261",
     enfermedad:"Hemorragia pulmonar masiva originada en el periodo perinatal"
  },
  {
     id:"P268",
     enfermedad:"Otras hemorragias pulmonares originadas en el periodo perinatal"
  },
  {
     id:"P269",
     enfermedad:"Hemorragia pulmonar no especificada, originada en el periodo perinatal"
  },
  {
     id:"P27",
     enfermedad:"Enfermedades respiratorias cronicas originadas en el periodo perinatal"
  },
  {
     id:"P270",
     enfermedad:"Sindrome de wilson"
  },
  {
     id:"P271",
     enfermedad:"Displasia broncopulmonar originada en el periodo perinatal"
  },
  {
     id:"P278",
     enfermedad:"Otras enfermedades respiratorias cronicas originadas en el periodo perinatal"
  },
  {
     id:"P279",
     enfermedad:"Enfermedad respiratoria cronica no especificada originada en el periodo perinatal"
  },
  {
     id:"P28",
     enfermedad:"Otros problemas respiratorios del recien nacido originados en el periodo perinatal"
  },
  {
     id:"P280",
     enfermedad:"Atelectasia primaria del recien nacido"
  },
  {
     id:"P281",
     enfermedad:"Otras atelectasias del recien nacido y las no especificadas"
  },
  {
     id:"P282",
     enfermedad:"Ataque cianotico del recien nacido"
  },
  {
     id:"P283",
     enfermedad:"Apnea primaria del sueño del recien nacido"
  },
  {
     id:"P284",
     enfermedad:"Otras apneas del recien nacido"
  },
  {
     id:"P285",
     enfermedad:"Insuficiencia respiratoria del recien nacido"
  },
  {
     id:"P288",
     enfermedad:"Otros problemas respiratorios especificados del recien nacido"
  },
  {
     id:"P289",
     enfermedad:"Afeccion respiratoria no especificada del recien nacido"
  },
  {
     id:"P29",
     enfermedad:"Trastornos cardiovasculares originados en el periodo perinatal"
  },
  {
     id:"P290",
     enfermedad:"Insuficiencia cardiaca neonatal"
  },
  {
     id:"P291",
     enfermedad:"Disritmia cardiaca neonatal"
  },
  {
     id:"P292",
     enfermedad:"Hipertension neonatal"
  },
  {
     id:"P293",
     enfermedad:"Persistencia de la circulacion fetal"
  },
  {
     id:"P294",
     enfermedad:"Isquemia miocardica transitoria del recien nacido"
  },
  {
     id:"P298",
     enfermedad:"Otros trastornos cardiovasculares originados en el periodo perinatal"
  },
  {
     id:"P299",
     enfermedad:"Trastorno cardiovascular no especificado, originado en el periodo perinatal"
  },
  {
     id:"P35",
     enfermedad:"Enfermedades virales congenitas"
  },
  {
     id:"P350",
     enfermedad:"Sindrome de rubeola congenita"
  },
  {
     id:"P351",
     enfermedad:"Infeccion citomegalovirica congenita"
  },
  {
     id:"P352",
     enfermedad:"Infecciones congenitas por virus del herpes simple"
  },
  {
     id:"P353",
     enfermedad:"Hepatitis viral congenita"
  },
  {
     id:"P358",
     enfermedad:"Otras enfermedades virales congenitas"
  },
  {
     id:"P359",
     enfermedad:"Enfermedad viral congenita, sin otra especificacion"
  },
  {
     id:"P36",
     enfermedad:"Sepsis bacteriana del recien nacido"
  },
  {
     id:"P360",
     enfermedad:"Sepsis del recien nacido debida a estreptococo del grupo b"
  },
  {
     id:"P361",
     enfermedad:"Sepsis del recien nacido debida a otros estreptococos y a los no especificados"
  },
  {
     id:"P362",
     enfermedad:"Sepsis del recien nacido debida a staphylococcus aureus"
  },
  {
     id:"P363",
     enfermedad:"Sepsis del recien nacido debida a otros estafilococos y a los no especificados"
  },
  {
     id:"P364",
     enfermedad:"Sepsis del recien nacido debida a escherichia coli"
  },
  {
     id:"P365",
     enfermedad:"Sepsis del recien nacido debida a anaerobios"
  },
  {
     id:"P368",
     enfermedad:"Sepsis del recien nacido debida a otras bacterias"
  },
  {
     id:"P369",
     enfermedad:"Sepsis bacteriana del recien nacido, no especificada"
  },
  {
     id:"P37",
     enfermedad:"Otras enfermedades infecciosas y parasitarias congenitas"
  },
  {
     id:"P370",
     enfermedad:"Tuberculosis congenita"
  },
  {
     id:"P371",
     enfermedad:"Toxoplasmosis congenita"
  },
  {
     id:"P372",
     enfermedad:"Listeriosis congenita (diseminada)"
  },
  {
     id:"P373",
     enfermedad:"Paludismo congenito por plasmodium falciparum"
  },
  {
     id:"P374",
     enfermedad:"Otros paludismos congenitos"
  },
  {
     id:"P375",
     enfermedad:"Candidiasis neonatal"
  },
  {
     id:"P378",
     enfermedad:"Otras enfermedades neonatales infecciosas o parasitarias especificadas"
  },
  {
     id:"P379",
     enfermedad:"Enfermedad infecciosa o parasitaria congenita, no especificada"
  },
  {
     id:"P38",
     enfermedad:"Onfalitis del recien nacido con o sin hemorragia leve"
  },
  {
     id:"P39",
     enfermedad:"Otras infecciones especificadas del periodo perinatal"
  },
  {
     id:"P390",
     enfermedad:"Mastitis infecciosa neonatal"
  },
  {
     id:"P391",
     enfermedad:"Conjuntivitis y dacriocistitis neonatales"
  },
  {
     id:"P392",
     enfermedad:"Infeccion intraamniotica del feto, no clasificada en otra parte"
  },
  {
     id:"P393",
     enfermedad:"Infeccion neonatal de las vias urinarias"
  },
  {
     id:"P394",
     enfermedad:"Infeccion cutanea neonatal"
  },
  {
     id:"P398",
     enfermedad:"Otras infecciones especificadas propias del periodo perinatal"
  },
  {
     id:"P399",
     enfermedad:"Infeccion propia del periodo perinatal, no especificada"
  },
  {
     id:"P50",
     enfermedad:"Perdida de sangre fetal"
  },
  {
     id:"P500",
     enfermedad:"Perdida de sangre fetal por vasa previa"
  },
  {
     id:"P501",
     enfermedad:"Perdida de sangre fetal por ruptura del cordon umbilical"
  },
  {
     id:"P502",
     enfermedad:"Perdida de sangre fetal por la placenta"
  },
  {
     id:"P503",
     enfermedad:"Hemorragia fetal hacia otro gemelo"
  },
  {
     id:"P504",
     enfermedad:"Hemorragia fetal hacia la circulacion materna"
  },
  {
     id:"P505",
     enfermedad:"Perdida de sangre fetal por el corte del cordon umbilical en el otro gemelo"
  },
  {
     id:"P508",
     enfermedad:"Otras perdidas de sangre fetal"
  },
  {
     id:"P509",
     enfermedad:"Perdida de sangre fetal no especificada"
  },
  {
     id:"P51",
     enfermedad:"Hemorragia umbilical del recien nacido"
  },
  {
     id:"P510",
     enfermedad:"Hemorragia umbilical masiva del recien nacido"
  },
  {
     id:"P518",
     enfermedad:"Otras hemorragias umbilicales del recien nacido"
  },
  {
     id:"P519",
     enfermedad:"Hemorragia umbilical del recien nacido, sin otra especificacion"
  },
  {
     id:"P52",
     enfermedad:"Hemorragia intracraneal no traumatica del feto y del recien nacido"
  },
  {
     id:"P520",
     enfermedad:"Hemorragia intraventricular (no traumatica) grado 1, del feto y del recien nacido"
  },
  {
     id:"P521",
     enfermedad:"Hemorragia intraventricular (no traumatica) grado 2, del feto y del recien nacido"
  },
  {
     id:"P522",
     enfermedad:"Hemorragia intraventricular (no traumatica) grado 3, del feto y del recien nacido"
  },
  {
     id:"P523",
     enfermedad:"Hemorragia intraventricular (no traumatica) del feto y del recien nacido, sin otra especificacion"
  },
  {
     id:"P524",
     enfermedad:"Hemorragia intracerebral (no traumatica) del feto y del recien nacido"
  },
  {
     id:"P525",
     enfermedad:"Hemorragia subaracnoidea (no traumatica) del feto y del recien nacido"
  },
  {
     id:"P526",
     enfermedad:"Hemorragia cerebelosa y de la fosa posterior (no traumatica) del feto y del recien nacido"
  },
  {
     id:"P528",
     enfermedad:"Otras hemorragias intracraneales (no traumaticas) del feto y del recien nacido"
  },
  {
     id:"P529",
     enfermedad:"Hemorragia intracraneal (no traumatica) del feto y del recien nacido, sin otra especificacion"
  },
  {
     id:"P53",
     enfermedad:"Enfermedad hemorragica del feto y del recien nacido"
  },
  {
     id:"P54",
     enfermedad:"Otras hemorragias neonatales"
  },
  {
     id:"P540",
     enfermedad:"Hematemesis neonatal"
  },
  {
     id:"P541",
     enfermedad:"Melena neonatal"
  },
  {
     id:"P542",
     enfermedad:"Hemorragia rectal neonatal"
  },
  {
     id:"P543",
     enfermedad:"Otras hemorragias gastrointestinales neonatales"
  },
  {
     id:"P544",
     enfermedad:"Hemorragia suprarrenal neonatal"
  },
  {
     id:"P545",
     enfermedad:"Hemorragia cutanea neonatal"
  },
  {
     id:"P546",
     enfermedad:"Hemorragia vaginal neonatal"
  },
  {
     id:"P548",
     enfermedad:"Otras hemorragias fetales y neonatales especificadas"
  },
  {
     id:"P549",
     enfermedad:"Hemorragia neonatal, no especificada"
  },
  {
     id:"P55",
     enfermedad:"Enfermedad hemolitica del feto y del recien nacido"
  },
  {
     id:"P550",
     enfermedad:"Incompatibilidad rh del feto y del recien nacido"
  },
  {
     id:"P551",
     enfermedad:"Incompatibilidad abo del feto y del recien nacido"
  },
  {
     id:"P558",
     enfermedad:"Otras enfermedades hemoliticas del feto y del recien nacido"
  },
  {
     id:"P559",
     enfermedad:"Enfermedad hemolitica del feto y del recien nacido, no especificada"
  },
  {
     id:"P56",
     enfermedad:"Hidropesia fetal debida a enfermedad hemolitica"
  },
  {
     id:"P560",
     enfermedad:"Hidropesia fetal debida a incompatibilidad"
  },
  {
     id:"P569",
     enfermedad:"Hidropesia fetal debida a otras enfermedades hemoliticas especificadas y a las no especificadas"
  },
  {
     id:"P57",
     enfermedad:"Kernicterus"
  },
  {
     id:"P570",
     enfermedad:"Kernicterus debido a incompatibilidad"
  },
  {
     id:"P578",
     enfermedad:"Kernicterus debido a otras causas especificadas"
  },
  {
     id:"P579",
     enfermedad:"Kernicterus, no especificado"
  },
  {
     id:"P58",
     enfermedad:"Ictericia neonatal debida a otras hemolisis excesivas"
  },
  {
     id:"P580",
     enfermedad:"Ictericia neonatal debida a contusion"
  },
  {
     id:"P581",
     enfermedad:"Ictericia neonatal debida a hemorragia"
  },
  {
     id:"P582",
     enfermedad:"Ictericia neonatal debida a infeccion"
  },
  {
     id:"P583",
     enfermedad:"Ictericia neonatal debida a policitemia"
  },
  {
     id:"P584",
     enfermedad:"Ictericia neonatal debida a drogas o toxinas transmitidas por la madre o administradas al recien nacido"
  },
  {
     id:"P585",
     enfermedad:"Ictericia neonatal debida a deglucion de sangre materna"
  },
  {
     id:"P588",
     enfermedad:"Ictericia neonatal debida a otras hemolisis excesivas especificadas"
  },
  {
     id:"P589",
     enfermedad:"Ictericia neonatal debida a hemolisis excesiva sin otra especificacion"
  },
  {
     id:"P59",
     enfermedad:"Ictericia neonatal por otras causas y por las no especificadas"
  },
  {
     id:"P590",
     enfermedad:"Ictericia neonatal asociada con el parto antes de termino"
  },
  {
     id:"P591",
     enfermedad:"Sindrome de la bilis espesa"
  },
  {
     id:"P592",
     enfermedad:"Ictericia neonatal debida a otra lesion hepatica especificada o no"
  },
  {
     id:"P593",
     enfermedad:"Ictericia neonatal por inhibidor de la leche materna"
  },
  {
     id:"P598",
     enfermedad:"Ictericia neonatal por otras causas especificadas"
  },
  {
     id:"P599",
     enfermedad:"Ictericia neonatal, no especificada"
  },
  {
     id:"P60",
     enfermedad:"Coagulacion intravascular diseminada en el feto y en el recien nacido"
  },
  {
     id:"P61",
     enfermedad:"Otros trastornos hematologicos perinatales"
  },
  {
     id:"P610",
     enfermedad:"Trombocitopenia neonatal transitoria"
  },
  {
     id:"P611",
     enfermedad:"Policitemia neonatal"
  },
  {
     id:"P612",
     enfermedad:"Anemia de la prematuridad"
  },
  {
     id:"P613",
     enfermedad:"Anemia congenita debida a perdida de sangre fetal"
  },
  {
     id:"P614",
     enfermedad:"Otras anemias congenitas, no clasificadas en otra parte"
  },
  {
     id:"P615",
     enfermedad:"Neutropenia neonatal transitoria"
  },
  {
     id:"P616",
     enfermedad:"Otros trastornos neonatales transitorios de la coagulacion"
  },
  {
     id:"P618",
     enfermedad:"Otros trastornos hematologicos perinatales especificados"
  },
  {
     id:"P619",
     enfermedad:"Trastorno hematologico perinatal, no especificado"
  },
  {
     id:"P70",
     enfermedad:"Trastornos transitorios del metabolismo de los carbohidratos especificos del feto y del recien nacido"
  },
  {
     id:"P700",
     enfermedad:"Sindrome del recien nacido de madre con diabetes gestacional"
  },
  {
     id:"P701",
     enfermedad:"Sindrome del recien nacido de madre con diabetica"
  },
  {
     id:"P702",
     enfermedad:"Diabetes mellitus neonatal"
  },
  {
     id:"P703",
     enfermedad:"Hipoglicemia neonatal yatrogenica"
  },
  {
     id:"P704",
     enfermedad:"Otras hipoglicemias neonatales"
  },
  {
     id:"P708",
     enfermedad:"Otros trastornos transitorios del metabolismo de los carbohidratos en el feto y el recien nacido"
  },
  {
     id:"P709",
     enfermedad:"Trastorno transitorio no especificado del metabolismo de los carbohidratos en el feto y el recien nacido"
  },
  {
     id:"P71",
     enfermedad:"Trastornos neonatales transitorios del metabolismo del calcio y del magnesio"
  },
  {
     id:"P710",
     enfermedad:"Hipocalcemia del recien nacido debida a la leche de vaca"
  },
  {
     id:"P711",
     enfermedad:"Otra hipocalcemia neonatal"
  },
  {
     id:"P712",
     enfermedad:"Hipomagnesemia neonatal"
  },
  {
     id:"P713",
     enfermedad:"Tetania neonatal sin mencion de deficiencia de calcio o de magnesio"
  },
  {
     id:"P714",
     enfermedad:"Hipoparatiroidismo neonatal transitorio"
  },
  {
     id:"P718",
     enfermedad:"Otros trastornos neonatales transitorios del metabolismo del calcio y del magnesio"
  },
  {
     id:"P719",
     enfermedad:"Trastorno neonatal transitorio no especificado del metabolismo del calcio y del magnesio"
  },
  {
     id:"P72",
     enfermedad:"Otros trastornos endocrinos neonatales transitorios"
  },
  {
     id:"P720",
     enfermedad:"Bocio neonatal, no clasificado en otra parte"
  },
  {
     id:"P721",
     enfermedad:"Hipertiroidismo neonatal transitorio"
  },
  {
     id:"P722",
     enfermedad:"Otros trastornos neonatales transitorios de la funcion tiroidea, no clasificados en otra parte"
  },
  {
     id:"P728",
     enfermedad:"Otros trastornos endocrinos neonatales transitorios especificados"
  },
  {
     id:"P729",
     enfermedad:"Trastorno endocrino neonatal transitorio, no especificado"
  },
  {
     id:"P74",
     enfermedad:"Otras alteraciones metabolicas y electroliticas neonatales transitorias"
  },
  {
     id:"P740",
     enfermedad:"Acidosis metabolica tardia del recien nacido"
  },
  {
     id:"P741",
     enfermedad:"Deshidratacion del recien nacido"
  },
  {
     id:"P742",
     enfermedad:"Alteraciones del equilibrio del sodio en el recien nacido"
  },
  {
     id:"P743",
     enfermedad:"Alteraciones del equilibrio del potasio en el recien nacido"
  },
  {
     id:"P744",
     enfermedad:"Otras alteraciones electroliticas transitorias del recien nacido"
  },
  {
     id:"P745",
     enfermedad:"Tirosinemia transitoria del recien nacido"
  },
  {
     id:"P748",
     enfermedad:"Otras alteraciones metabolicas transitorias del recien nacido"
  },
  {
     id:"P749",
     enfermedad:"Trastorno metabolico transitorio del recien nacido, no especificado"
  },
  {
     id:"P75",
     enfermedad:"Ileo meconial (e84.1)"
  },
  {
     id:"P76",
     enfermedad:"Otras obstrucciones intestinales del recien nacido"
  },
  {
     id:"P760",
     enfermedad:"Sindrome del tapon de meconio"
  },
  {
     id:"P761",
     enfermedad:"Ileo transitorio del recien nacido"
  },
  {
     id:"P762",
     enfermedad:"Obstruccion intestinal debida a la leche espesa"
  },
  {
     id:"P768",
     enfermedad:"Otras obstrucciones intestinales especificadas del recien nacido"
  },
  {
     id:"P769",
     enfermedad:"Obstruccion intestinal del recien nacido, no especificada"
  },
  {
     id:"P77",
     enfermedad:"Enterocolitis necrotizante del feto y del recien nacido"
  },
  {
     id:"P78",
     enfermedad:"Otros trastornos perinatales del sistema digestivo"
  },
  {
     id:"P780",
     enfermedad:"Perforacion intestinal perinatal"
  },
  {
     id:"P781",
     enfermedad:"Otras peritonitis neonatales"
  },
  {
     id:"P782",
     enfermedad:"Hematemesis y melena neonatales debidas a la deglucion de sangre materna"
  },
  {
     id:"P783",
     enfermedad:"Diarrea neonatal no infecciosa"
  },
  {
     id:"P788",
     enfermedad:"Otros trastornos perinatales especificos del sistema digestivo"
  },
  {
     id:"P789",
     enfermedad:"Trastorno perinatal del sistema digestivo, no especificado"
  },
  {
     id:"P80",
     enfermedad:"Hipotermia del recien nacido"
  },
  {
     id:"P800",
     enfermedad:"Sindrome de enfriamiento"
  },
  {
     id:"P808",
     enfermedad:"Otras hipotermias del recien nacido"
  },
  {
     id:"P809",
     enfermedad:"Hipotermia del recien nacido, no especificada"
  },
  {
     id:"P81",
     enfermedad:"Otras alteraciones de la regulacion de la temperatura del recien nacido"
  },
  {
     id:"P810",
     enfermedad:"Hipertermia del recien nacido inducida por las condiciones ambientales"
  },
  {
     id:"P818",
     enfermedad:"Otras alteraciones especificadas de la regulacion de la temperatura del recien nacido"
  },
  {
     id:"P819",
     enfermedad:"Alteracion no especificada de la regulacion de la temperatura en el recien nacido"
  },
  {
     id:"P83",
     enfermedad:"Otras afecciones de la piel especificas del feto y del recien nacido"
  },
  {
     id:"P830",
     enfermedad:"Esclerema neonatal"
  },
  {
     id:"P831",
     enfermedad:"Eritema toxico neonatal"
  },
  {
     id:"P832",
     enfermedad:"Hidropesia fetal no debida a enfermedad hemolitica"
  },
  {
     id:"P833",
     enfermedad:"Otros edemas y los no especificados, propios del feto y del recien nacido"
  },
  {
     id:"P834",
     enfermedad:"Ingurgitacion mamaria del recien nacido"
  },
  {
     id:"P835",
     enfermedad:"Hidrocele congenito"
  },
  {
     id:"P836",
     enfermedad:"Polipo umbilical del recien nacido"
  },
  {
     id:"P838",
     enfermedad:"Otras afecciones especificadas de la piel, propias del feto y del recien nacido"
  },
  {
     id:"P839",
     enfermedad:"Afeccion no especificada de la piel, propias del feto y del recien nacido"
  },
  {
     id:"P90",
     enfermedad:"Convulsiones del recien nacido"
  },
  {
     id:"P91",
     enfermedad:"Otras alteraciones cerebrales del recien nacido"
  },
  {
     id:"P910",
     enfermedad:"Isquemia cerebral neonatal"
  },
  {
     id:"P911",
     enfermedad:"Quistes periventriculares adquiridos del recien nacido"
  },
  {
     id:"P912",
     enfermedad:"Leucomalacia neonatal"
  },
  {
     id:"P913",
     enfermedad:"Irritabilidad cerebral neonatal"
  },
  {
     id:"P914",
     enfermedad:"Depresion cerebral neonatal"
  },
  {
     id:"P915",
     enfermedad:"Coma neonatal"
  },
  {
     id:"P918",
     enfermedad:"Otras alteraciones cerebrales especificadas del recien nacido"
  },
  {
     id:"P919",
     enfermedad:"Alteracion cerebral no especificada del recien nacido"
  },
  {
     id:"P92",
     enfermedad:"Problemas de la ingestion de alimentos del recien nacido"
  },
  {
     id:"P920",
     enfermedad:"Vomitos del recien nacido"
  },
  {
     id:"P921",
     enfermedad:"Regurgitacion y rumiacion del recien nacido"
  },
  {
     id:"P922",
     enfermedad:"Lentitud en la ingestion de alimentos del recien nacido"
  },
  {
     id:"P923",
     enfermedad:"Hipoalimentacion del recien nacido"
  },
  {
     id:"P924",
     enfermedad:"Hiperalimentacion del recien nacido"
  },
  {
     id:"P925",
     enfermedad:"Dificultad neonatal en la lactancia materna"
  },
  {
     id:"P928",
     enfermedad:"Otros problemas de alimentacion del recien nacido"
  },
  {
     id:"P929",
     enfermedad:"Problema no especificado de la alimentacion del recien nacido"
  },
  {
     id:"P93",
     enfermedad:"Reacciones e intoxicaciones debidas a drogas administradas al feto y al recien nacido"
  },
  {
     id:"P94",
     enfermedad:"Trastornos del tono muscular en el recien nacido"
  },
  {
     id:"P940",
     enfermedad:"Miastenia grave neonatal transitoria"
  },
  {
     id:"P941",
     enfermedad:"Hipertonia congenita"
  },
  {
     id:"P942",
     enfermedad:"Hipotonia congenita"
  },
  {
     id:"P948",
     enfermedad:"Otros trastornos del tono muscular en el recien nacido"
  },
  {
     id:"P949",
     enfermedad:"Trastorno no especificado del tono muscular en el recien nacido"
  },
  {
     id:"P95",
     enfermedad:"Muerte fetal de causa no especificada"
  },
  {
     id:"P96",
     enfermedad:"Otras afecciones originadas en el periodo perinatal"
  },
  {
     id:"P960",
     enfermedad:"Insuficiencia renal congenita"
  },
  {
     id:"P961",
     enfermedad:"Sintomas neonatales de abstinencia por drogadiccion materna"
  },
  {
     id:"P962",
     enfermedad:"Sintomas de abstinencia por el uso terapeutico de drogas en el recien nacido"
  },
  {
     id:"P963",
     enfermedad:"Amplitud de la suturas craneales del recien nacido"
  },
  {
     id:"P964",
     enfermedad:"Terminacion del embarazo, que afecta al feto y al recien nacido"
  },
  {
     id:"P965",
     enfermedad:"Complicaciones de procedimientos intrauterinos, no clasificados en otra parte"
  },
  {
     id:"P968",
     enfermedad:"Otras afecciones especificadas originadas en el periodo perinatal"
  },
  {
     id:"P969",
     enfermedad:"Afeccion no especificada originada en el periodo perinatal"
  },
  {
     id:"Q00",
     enfermedad:"Anencefalia y malformaciones congenitas similares"
  },
  {
     id:"Q000",
     enfermedad:"Anencefalia"
  },
  {
     id:"Q001",
     enfermedad:"Craneorraquisquisis"
  },
  {
     id:"Q002",
     enfermedad:"Iniencefalia"
  },
  {
     id:"Q01",
     enfermedad:"Encefalocele"
  },
  {
     id:"Q010",
     enfermedad:"Encefalocele frontal"
  },
  {
     id:"Q011",
     enfermedad:"Encefalocele nasofrontal"
  },
  {
     id:"Q012",
     enfermedad:"Encefalocele occipital"
  },
  {
     id:"Q018",
     enfermedad:"Encefalocele de otros sitios"
  },
  {
     id:"Q019",
     enfermedad:"Encefalocele, no especificado"
  },
  {
     id:"Q02",
     enfermedad:"Microcefalia"
  },
  {
     id:"Q03",
     enfermedad:"Hidrocefalo congenito"
  },
  {
     id:"Q030",
     enfermedad:"Malformaciones del acueducto de silvio"
  },
  {
     id:"Q031",
     enfermedad:"Atresia de los agujeros de magendie y de luschka"
  },
  {
     id:"Q038",
     enfermedad:"Otros hidrocefalos congenitos"
  },
  {
     id:"Q039",
     enfermedad:"Hidrocefalo congenito, no especificado"
  },
  {
     id:"Q04",
     enfermedad:"Otras malformaciones congenitas del encefalo"
  },
  {
     id:"Q040",
     enfermedad:"Malformaciones congenitas del cuerpo calloso"
  },
  {
     id:"Q041",
     enfermedad:"Arrinencefalia"
  },
  {
     id:"Q042",
     enfermedad:"Holoprosencefalia"
  },
  {
     id:"Q043",
     enfermedad:"Otras anomalias hipoplasicas del encefalo"
  },
  {
     id:"Q044",
     enfermedad:"Displasia opticoseptal"
  },
  {
     id:"Q045",
     enfermedad:"Megalencefalia"
  },
  {
     id:"Q046",
     enfermedad:"Quistes cerebrales congenitos"
  },
  {
     id:"Q048",
     enfermedad:"Otras malformaciones congenitas del encefalo, especificadas"
  },
  {
     id:"Q049",
     enfermedad:"Malformacion congenita del encefalo, no especificada"
  },
  {
     id:"Q05",
     enfermedad:"Espina bifida"
  },
  {
     id:"Q050",
     enfermedad:"Espina bifida cervical con hidrocefalo"
  },
  {
     id:"Q051",
     enfermedad:"Espina bifida toracica con hidrocefalo"
  },
  {
     id:"Q052",
     enfermedad:"Espina bifida lumbar con hidrocefalo"
  },
  {
     id:"Q053",
     enfermedad:"Espina bifida sacra con hidrocefalo"
  },
  {
     id:"Q054",
     enfermedad:"Espina bifida con hidrocefalo, sin otra especificacion"
  },
  {
     id:"Q055",
     enfermedad:"Espina bifida cervical sin hidrocefalo"
  },
  {
     id:"Q056",
     enfermedad:"Espina bifida toracica sin hidrocefalo"
  },
  {
     id:"Q057",
     enfermedad:"Espina bifida lumbar sin hidrocefalo"
  },
  {
     id:"Q058",
     enfermedad:"Espina bifida sacra sin hidrocefalo"
  },
  {
     id:"Q059",
     enfermedad:"Espina bifida, no especificada"
  },
  {
     id:"Q06",
     enfermedad:"Otras malformaciones congenitas de la medula espinal"
  },
  {
     id:"Q060",
     enfermedad:"Amielia"
  },
  {
     id:"Q061",
     enfermedad:"Hipoplasia y displasia de la medula espinal"
  },
  {
     id:"Q062",
     enfermedad:"Diastematomielia"
  },
  {
     id:"Q063",
     enfermedad:"Otras anomalias congenitas de la cola de caballo"
  },
  {
     id:"Q064",
     enfermedad:"Hidromielia"
  },
  {
     id:"Q068",
     enfermedad:"Otras malformaciones congenitas especificadas de la medula espinal"
  },
  {
     id:"Q069",
     enfermedad:"Malformacion congenita de la medula espinal, no especificada"
  },
  {
     id:"Q07",
     enfermedad:"Otras malformaciones congenitas del sistema nervioso"
  },
  {
     id:"Q070",
     enfermedad:"Sindrome de arnold"
  },
  {
     id:"Q078",
     enfermedad:"Otras malformaciones congenitas del sistema nervioso, especificadas"
  },
  {
     id:"Q079",
     enfermedad:"Malformacion congenita del sistema nervioso, no especificada"
  },
  {
     id:"Q10",
     enfermedad:"Malformaciones congenitas de los parpados, del aparato lagrimal y de la orbita"
  },
  {
     id:"Q100",
     enfermedad:"Blefaroptosis congenita"
  },
  {
     id:"Q101",
     enfermedad:"Ectropion congenito"
  },
  {
     id:"Q102",
     enfermedad:"Entropion congenito"
  },
  {
     id:"Q103",
     enfermedad:"Otras malformaciones congenitas de los parpados"
  },
  {
     id:"Q104",
     enfermedad:"Ausencia y agenesia del aparato lagrimal"
  },
  {
     id:"Q105",
     enfermedad:"Estenosis y estrechez congenitas del conducto lagrimal"
  },
  {
     id:"Q106",
     enfermedad:"Otras malformaciones congenitas del aparato lagrimal"
  },
  {
     id:"Q107",
     enfermedad:"Malformacion congenita de la orbita"
  },
  {
     id:"Q11",
     enfermedad:"Anoftalmia, microftalmia y macroftalmia"
  },
  {
     id:"Q110",
     enfermedad:"Globo ocular quistico"
  },
  {
     id:"Q111",
     enfermedad:"Otras anoftalmias"
  },
  {
     id:"Q112",
     enfermedad:"Microftalmia"
  },
  {
     id:"Q113",
     enfermedad:"Macroftalmia"
  },
  {
     id:"Q12",
     enfermedad:"Malformaciones congenitas del cristalino"
  },
  {
     id:"Q120",
     enfermedad:"Catarata congenita"
  },
  {
     id:"Q121",
     enfermedad:"Desplazamiento congenito del cristalino"
  },
  {
     id:"Q122",
     enfermedad:"Coloboma del cristalino"
  },
  {
     id:"Q123",
     enfermedad:"Afaquia congenita"
  },
  {
     id:"Q124",
     enfermedad:"Esferofaquia"
  },
  {
     id:"Q128",
     enfermedad:"Otras malformaciones congenitas del cristalino"
  },
  {
     id:"Q129",
     enfermedad:"Malformacion congenita del cristalino, no especificada"
  },
  {
     id:"Q13",
     enfermedad:"Malformaciones congenitas del segmento anterior del ojo"
  },
  {
     id:"Q130",
     enfermedad:"Coloboma del iris"
  },
  {
     id:"Q131",
     enfermedad:"Ausencia del iris"
  },
  {
     id:"Q132",
     enfermedad:"Otras malformaciones del iris"
  },
  {
     id:"Q133",
     enfermedad:"Opacidad corneal congenita"
  },
  {
     id:"Q134",
     enfermedad:"Otras malformaciones congenitas de la cornea"
  },
  {
     id:"Q135",
     enfermedad:"Esclerotica azul"
  },
  {
     id:"Q138",
     enfermedad:"Otras malformaciones congenitas del segmento anterior del ojo"
  },
  {
     id:"Q139",
     enfermedad:"Malformacion congenita del segmento anterior del ojo, no especificada"
  },
  {
     id:"Q14",
     enfermedad:"Malformaciones congenitas del segmento posterior del ojo"
  },
  {
     id:"Q140",
     enfermedad:"Malformacion congenita del humor vitreo"
  },
  {
     id:"Q141",
     enfermedad:"Malformacion congenita de la retina"
  },
  {
     id:"Q142",
     enfermedad:"Malformacion congenita del disco optico"
  },
  {
     id:"Q143",
     enfermedad:"Malformacion congenita de la coroides"
  },
  {
     id:"Q148",
     enfermedad:"Otras malformaciones congenitas del segmento posterior del ojo"
  },
  {
     id:"Q149",
     enfermedad:"Malformacion congenita del segmento posterior del ojo, no especificada"
  },
  {
     id:"Q15",
     enfermedad:"Otras malformaciones congenitas del ojo"
  },
  {
     id:"Q150",
     enfermedad:"Glaucoma congenito"
  },
  {
     id:"Q158",
     enfermedad:"Otras malformaciones congenitas del ojo, especificadas"
  },
  {
     id:"Q159",
     enfermedad:"Malformaciones congenitas del ojo, no especificadas"
  },
  {
     id:"Q16",
     enfermedad:"Malformaciones congenitas del oido que causan alteracion de la audicion"
  },
  {
     id:"Q160",
     enfermedad:"Ausencia congenita del pabellon (de la oreja)"
  },
  {
     id:"Q161",
     enfermedad:"Ausencia congenita, atresia o estrechez del conducto auditivo (externo)"
  },
  {
     id:"Q162",
     enfermedad:"Ausencia de la trompa de eustaquio"
  },
  {
     id:"Q163",
     enfermedad:"Malformacion congenita de los huesillos del oido"
  },
  {
     id:"Q164",
     enfermedad:"Otras malformaciones congenitas del oido medio"
  },
  {
     id:"Q165",
     enfermedad:"Malformacion congenita del oido interno"
  },
  {
     id:"Q169",
     enfermedad:"Malformacion congenita del oido que causa alteracion de la audicion, sin otra especificacion"
  },
  {
     id:"Q17",
     enfermedad:"Otras malformaciones congenitas del oido"
  },
  {
     id:"Q170",
     enfermedad:"Oreja supernumeraria"
  },
  {
     id:"Q171",
     enfermedad:"Macrotia"
  },
  {
     id:"Q172",
     enfermedad:"Microtia"
  },
  {
     id:"Q173",
     enfermedad:"Otras deformidades del pabellon auricular"
  },
  {
     id:"Q174",
     enfermedad:"Anomalia de la posicion de la oreja"
  },
  {
     id:"Q175",
     enfermedad:"Oreja prominente"
  },
  {
     id:"Q178",
     enfermedad:"Otras malformaciones congenitas del oido, especificadas"
  },
  {
     id:"Q179",
     enfermedad:"Malformacion congenita del oido, no especificada"
  },
  {
     id:"Q18",
     enfermedad:"Otras malformaciones congenitas de la cara y del cuello"
  },
  {
     id:"Q180",
     enfermedad:"Seno, fistula o quiste de la hendidura branquial"
  },
  {
     id:"Q181",
     enfermedad:"Seno y quiste preauricular"
  },
  {
     id:"Q182",
     enfermedad:"Otras malformaciones de las hendiduras branquiales"
  },
  {
     id:"Q183",
     enfermedad:"Pterigion del cuello"
  },
  {
     id:"Q184",
     enfermedad:"Macrostomia"
  },
  {
     id:"Q185",
     enfermedad:"Microstomia"
  },
  {
     id:"Q186",
     enfermedad:"Macroqueilia"
  },
  {
     id:"Q187",
     enfermedad:"Microqueilia"
  },
  {
     id:"Q188",
     enfermedad:"Otras malformaciones congenitas especificadas de cara y cuello"
  },
  {
     id:"Q189",
     enfermedad:"Malformacion congenita de la cara y del cuello, no especificada"
  },
  {
     id:"Q20",
     enfermedad:"Malformaciones congenitas de las camaras cardiacas y sus conexiones"
  },
  {
     id:"Q200",
     enfermedad:"Tronco arterioso comun"
  },
  {
     id:"Q201",
     enfermedad:"Transposicion de los grandes vasos del ventriculo derecho"
  },
  {
     id:"Q202",
     enfermedad:"Transposicion de los grandes vasos del ventriculo izquierdo"
  },
  {
     id:"Q203",
     enfermedad:"Discordancia de la conexion ventriculoarterial"
  },
  {
     id:"Q204",
     enfermedad:"Ventriculo con doble entrada"
  },
  {
     id:"Q205",
     enfermedad:"Discordancia de la conexion auriculoventricular"
  },
  {
     id:"Q206",
     enfermedad:"Isomerismo de los apendices auriculares"
  },
  {
     id:"Q208",
     enfermedad:"Otras malformaciones congenitas de las camaras cardiacas y sus conexiones"
  },
  {
     id:"Q209",
     enfermedad:"Malformacion congenitas de las camaras cardiacas y sus conexiones, no especificada"
  },
  {
     id:"Q21",
     enfermedad:"Malformaciones congenitas de los tabiques cardiacos"
  },
  {
     id:"Q210",
     enfermedad:"Defecto del tabique ventricular"
  },
  {
     id:"Q211",
     enfermedad:"Defecto del tabique auricular"
  },
  {
     id:"Q212",
     enfermedad:"Defecto del tabique auriculoventricular"
  },
  {
     id:"Q213",
     enfermedad:"Tetralogia de fallot"
  },
  {
     id:"Q214",
     enfermedad:"Defecto del tabique aortopulmonar"
  },
  {
     id:"Q218",
     enfermedad:"Otras malformaciones congenitas de los tabiques cardiacos"
  },
  {
     id:"Q219",
     enfermedad:"Malformacion congenita del tabique cardiaco, no especificada"
  },
  {
     id:"Q22",
     enfermedad:"Malformaciones congenitas de las valvulas pulmonar y tricuspide"
  },
  {
     id:"Q220",
     enfermedad:"Atresia de la valvula pulmonar"
  },
  {
     id:"Q221",
     enfermedad:"Estenosis congenita de la valvula pulmonar"
  },
  {
     id:"Q222",
     enfermedad:"Insuficiencia congenita de la valvula pulmonar"
  },
  {
     id:"Q223",
     enfermedad:"Otras malformaciones congenitas de la valvula pulmonar"
  },
  {
     id:"Q224",
     enfermedad:"Estenosis congenita de la valvula tricuspide"
  },
  {
     id:"Q225",
     enfermedad:"Anomalia de ebstein"
  },
  {
     id:"Q226",
     enfermedad:"Sindrome de hipoplasia del corazon derecho"
  },
  {
     id:"Q228",
     enfermedad:"Otras malformaciones congenitas de la valvula tricuspide"
  },
  {
     id:"Q229",
     enfermedad:"Malformacion congenita de la valvula tricuspide, no especificada"
  },
  {
     id:"Q23",
     enfermedad:"Malformaciones congenitas de las valvulas aortica y mitral"
  },
  {
     id:"Q230",
     enfermedad:"Estenosis congenita de la valvula aortica"
  },
  {
     id:"Q231",
     enfermedad:"Insuficiencia congenita de la valvula aortica"
  },
  {
     id:"Q232",
     enfermedad:"Estenosis mitral congenita"
  },
  {
     id:"Q233",
     enfermedad:"Insuficiencia mitral congenita"
  },
  {
     id:"Q234",
     enfermedad:"Sindrome de hipoplasia del corazon izquierdo"
  },
  {
     id:"Q238",
     enfermedad:"Otras malformaciones congenitas de las valvulas aortica y mitral"
  },
  {
     id:"Q239",
     enfermedad:"Malformacion congenita de las valvulas aortica y mitral, no especificada"
  },
  {
     id:"Q24",
     enfermedad:"Otras malformaciones congenitas del corazon"
  },
  {
     id:"Q240",
     enfermedad:"Dextrocardia"
  },
  {
     id:"Q241",
     enfermedad:"Levocardia"
  },
  {
     id:"Q242",
     enfermedad:"Corazon triauricular"
  },
  {
     id:"Q243",
     enfermedad:"Estenosis del infundibulo pulmonar"
  },
  {
     id:"Q244",
     enfermedad:"Estenosis subaortica congenita"
  },
  {
     id:"Q245",
     enfermedad:"Malformacion de los vasos coronarios"
  },
  {
     id:"Q246",
     enfermedad:"Bloqueo cardiaco congenito"
  },
  {
     id:"Q248",
     enfermedad:"Otras malformaciones congenitas del corazon, especificadas"
  },
  {
     id:"Q249",
     enfermedad:"Malformacion congenita del corazon, no especificada"
  },
  {
     id:"Q25",
     enfermedad:"Malformaciones congenitas de las grandes arterias"
  },
  {
     id:"Q250",
     enfermedad:"Conducto arterioso permeable"
  },
  {
     id:"Q251",
     enfermedad:"Coartacion de la aorta"
  },
  {
     id:"Q252",
     enfermedad:"Atresia de la aorta"
  },
  {
     id:"Q253",
     enfermedad:"Estenosis de la aorta"
  },
  {
     id:"Q254",
     enfermedad:"Otras malformaciones congenitas de la aorta"
  },
  {
     id:"Q255",
     enfermedad:"Atresia de la arteria pulmonar"
  },
  {
     id:"Q256",
     enfermedad:"Estenosis de la arteria pulmonar"
  },
  {
     id:"Q257",
     enfermedad:"Otras malformaciones congenitas de la arteria pulmonar"
  },
  {
     id:"Q258",
     enfermedad:"Otras malformaciones congenitas de las grandes arterias"
  },
  {
     id:"Q259",
     enfermedad:"Malformacion congenita de las grandes arterias, no especificada"
  },
  {
     id:"Q26",
     enfermedad:"Malformaciones congenitas de las grandes venas"
  },
  {
     id:"Q260",
     enfermedad:"Estenosis congenita de la vena cava"
  },
  {
     id:"Q261",
     enfermedad:"Persistencia de la vena cava superior izquierda"
  },
  {
     id:"Q262",
     enfermedad:"Conexion anomala total de las venas pulmonares"
  },
  {
     id:"Q263",
     enfermedad:"Conexion anomala parcial de las venas pulmonares"
  },
  {
     id:"Q264",
     enfermedad:"Conexion anomala de las venas pulmonares, sin otra especificacion"
  },
  {
     id:"Q265",
     enfermedad:"Conexion anomala de la vena porta"
  },
  {
     id:"Q266",
     enfermedad:"Fistula arteria hepatica"
  },
  {
     id:"Q268",
     enfermedad:"Otras malformaciones congenitas de las grandes venas"
  },
  {
     id:"Q269",
     enfermedad:"Malformacion congenita de las grandes venas, no especificada"
  },
  {
     id:"Q27",
     enfermedad:"Otras malformaciones congenitas del sistema vascular periferico"
  },
  {
     id:"Q270",
     enfermedad:"Ausencia de hipoplasia congenita de la arteria umbilical"
  },
  {
     id:"Q271",
     enfermedad:"Estenosis congenita de la arteria renal"
  },
  {
     id:"Q272",
     enfermedad:"Otras malformaciones congenitas de la arteria renal"
  },
  {
     id:"Q273",
     enfermedad:"Malformacion arteriovenosa periferica"
  },
  {
     id:"Q274",
     enfermedad:"Flebectasia congenita"
  },
  {
     id:"Q278",
     enfermedad:"Otras malformaciones congenitas del sistema vascular periferico, especificadas"
  },
  {
     id:"Q279",
     enfermedad:"Malformacion congenita del sistema vascular periferico, no especificada"
  },
  {
     id:"Q28",
     enfermedad:"Otras malformaciones congenitas del sistema circulatorio"
  },
  {
     id:"Q280",
     enfermedad:"Malformacion arteriovenosa de los vasos precerebrales"
  },
  {
     id:"Q281",
     enfermedad:"Otras malformaciones de los vasos precerebrales"
  },
  {
     id:"Q282",
     enfermedad:"Malformacion arteriovenosa de los vasos cerebrales"
  },
  {
     id:"Q283",
     enfermedad:"Otras malformaciones de los vasos cerebrales"
  },
  {
     id:"Q288",
     enfermedad:"Otras malformaciones congenitas del sistema circulatorio, especificadas"
  },
  {
     id:"Q289",
     enfermedad:"Malformacion congenita del sistema circulatorio no especificada"
  },
  {
     id:"Q30",
     enfermedad:"Malformaciones congenitas de la nariz"
  },
  {
     id:"Q300",
     enfermedad:"Atresia de las coanas"
  },
  {
     id:"Q301",
     enfermedad:"Agenesia o hipoplasia de la nariz"
  },
  {
     id:"Q302",
     enfermedad:"Hendidura, fisura o muesca de la nariz"
  },
  {
     id:"Q303",
     enfermedad:"Perforacion congenita del tabique nasal"
  },
  {
     id:"Q308",
     enfermedad:"Otras malformaciones congenitas de la nariz"
  },
  {
     id:"Q309",
     enfermedad:"Malformacion congenita de la nariz, no especificada"
  },
  {
     id:"Q31",
     enfermedad:"Malformaciones congenitas de la laringe"
  },
  {
     id:"Q310",
     enfermedad:"Pterigion de la laringe"
  },
  {
     id:"Q311",
     enfermedad:"Estenosis subglotica congenita"
  },
  {
     id:"Q312",
     enfermedad:"Hipoplasia laringea"
  },
  {
     id:"Q313",
     enfermedad:"Laringocele"
  },
  {
     id:"Q315",
     enfermedad:"Laringomalacia congenita"
  },
  {
     id:"Q318",
     enfermedad:"Otras malformaciones congenitas de la laringe"
  },
  {
     id:"Q319",
     enfermedad:"Malformacion congenita de la laringe, no especificada"
  },
  {
     id:"Q32",
     enfermedad:"Malformaciones congenitas de la traquea y de los bronquios"
  },
  {
     id:"Q320",
     enfermedad:"Traqueomalacia congenita"
  },
  {
     id:"Q321",
     enfermedad:"Otras malformaciones congenitas de la traquea"
  },
  {
     id:"Q322",
     enfermedad:"Broncomalacia congenita"
  },
  {
     id:"Q323",
     enfermedad:"Estenosis congenita de los bronquios"
  },
  {
     id:"Q324",
     enfermedad:"Otras malformaciones congenitas de los bronquios"
  },
  {
     id:"Q33",
     enfermedad:"Malformaciones congenitas del pulmon"
  },
  {
     id:"Q330",
     enfermedad:"Quiste pulmonar congenito"
  },
  {
     id:"Q331",
     enfermedad:"Lobulo pulmonar supernumerario"
  },
  {
     id:"Q332",
     enfermedad:"Secuestro del pulmon"
  },
  {
     id:"Q333",
     enfermedad:"Agenesia del pulmon"
  },
  {
     id:"Q334",
     enfermedad:"Bronquiectasia congenita"
  },
  {
     id:"Q335",
     enfermedad:"Tejido ectopico en el pulmon"
  },
  {
     id:"Q336",
     enfermedad:"Hipoplasia y displasia pulmonar"
  },
  {
     id:"Q338",
     enfermedad:"Otras malformaciones congenitas del pulmon"
  },
  {
     id:"Q339",
     enfermedad:"Malformacion congenita del pulmon, no especificada"
  },
  {
     id:"Q34",
     enfermedad:"Otras malformaciones congenitas del sistema respiratorio"
  },
  {
     id:"Q340",
     enfermedad:"Anomalia de la pleura"
  },
  {
     id:"Q341",
     enfermedad:"Quiste congenito del mediastino"
  },
  {
     id:"Q348",
     enfermedad:"Otras malformaciones congenitas especificadas del sistema respiratorio"
  },
  {
     id:"Q349",
     enfermedad:"Malformacion congenita del sistema respiratorio, no especificada"
  },
  {
     id:"Q35",
     enfermedad:"Fisura del paladar"
  },
  {
     id:"Q351",
     enfermedad:"Fisura del paladar duro"
  },
  {
     id:"Q353",
     enfermedad:"Fisura del paladar blando"
  },
  {
     id:"Q355",
     enfermedad:"Fisura del paladar duro y del paladar blando"
  },
  {
     id:"Q357",
     enfermedad:"Fisura de la uvula"
  },
  {
     id:"Q359",
     enfermedad:"Fisura del paladar, sin otra especificacion"
  },
  {
     id:"Q36",
     enfermedad:"Labio leporino"
  },
  {
     id:"Q360",
     enfermedad:"Labio leporino, bilateral"
  },
  {
     id:"Q361",
     enfermedad:"Labio leporino, linea media"
  },
  {
     id:"Q369",
     enfermedad:"Labio leporino, unilateral"
  },
  {
     id:"Q37",
     enfermedad:"Fisura del paladar con labio leporino"
  },
  {
     id:"Q370",
     enfermedad:"Fisura del paladar duro con labio leporino bilateral"
  },
  {
     id:"Q371",
     enfermedad:"Fisura del paladar duro con labio leporino unilateral"
  },
  {
     id:"Q372",
     enfermedad:"Fisura del paladar blando con labio leporino bilateral"
  },
  {
     id:"Q373",
     enfermedad:"Fisura del paladar blando con labio leporino unilateral"
  },
  {
     id:"Q374",
     enfermedad:"Fisura del paladar duro y del paladar blando con labio leporino bilateral"
  },
  {
     id:"Q375",
     enfermedad:"Fisura del paladar duro y del paladar blando con labio leporino unilateral"
  },
  {
     id:"Q378",
     enfermedad:"Fisura del paladar con labio leporino bilateral, sin otra especificacion"
  },
  {
     id:"Q379",
     enfermedad:"Fisura del paladar con labio leporino unilateral, sin otra especificacion"
  },
  {
     id:"Q38",
     enfermedad:"Otras malformaciones congenitas de la lengua, de la boca y de la faringe"
  },
  {
     id:"Q380",
     enfermedad:"Malformaciones congenitas de los labios, no clasificadas en otra parte"
  },
  {
     id:"Q381",
     enfermedad:"Anquiloglosia"
  },
  {
     id:"Q382",
     enfermedad:"Macroglosia"
  },
  {
     id:"Q383",
     enfermedad:"Otras malformaciones congenitas de la lengua"
  },
  {
     id:"Q384",
     enfermedad:"Malformaciones congenitas de las glandulas y de los conductos salivales"
  },
  {
     id:"Q385",
     enfermedad:"Malformaciones congenitas del paladar, no clasificadas en otra parte"
  },
  {
     id:"Q386",
     enfermedad:"Otras malformaciones congenitas de la boca"
  },
  {
     id:"Q387",
     enfermedad:"Diverticulo faringeo"
  },
  {
     id:"Q388",
     enfermedad:"Otras malformaciones congenitas de la laringe"
  },
  {
     id:"Q39",
     enfermedad:"Malformaciones congenitas del esofago"
  },
  {
     id:"Q390",
     enfermedad:"Atresia del esofago sin mencion de fistula"
  },
  {
     id:"Q391",
     enfermedad:"Atresia del esofago con fistula traqueoesofagica"
  },
  {
     id:"Q392",
     enfermedad:"Fistula traqueoesofagica congenita sin mencion de atresia"
  },
  {
     id:"Q393",
     enfermedad:"Estrechez o estenosis congenita del esofago"
  },
  {
     id:"Q394",
     enfermedad:"Pterigion del esofago"
  },
  {
     id:"Q395",
     enfermedad:"Dilatacion congenita del esofago"
  },
  {
     id:"Q396",
     enfermedad:"Diverticulo del esofago"
  },
  {
     id:"Q398",
     enfermedad:"Otras malformaciones congenitas del esofago"
  },
  {
     id:"Q399",
     enfermedad:"Malformacion congenita del esofago, no especificada"
  },
  {
     id:"Q40",
     enfermedad:"Otras malformaciones congenitas de la parte superior del tubo digestivo"
  },
  {
     id:"Q400",
     enfermedad:"Estenosis hipertrofica congenita del piloro"
  },
  {
     id:"Q401",
     enfermedad:"Hernia hiatal congenita"
  },
  {
     id:"Q402",
     enfermedad:"Otras malformaciones congenitas del estomago, especificadas"
  },
  {
     id:"Q403",
     enfermedad:"Malformacion congenita del estomago, no especificada"
  },
  {
     id:"Q408",
     enfermedad:"Otras malformaciones congenitas de la parte superior del tubo digestivo"
  },
  {
     id:"Q409",
     enfermedad:"Malformacion congenita de la parte superior del tubo digestivo, no especificada"
  },
  {
     id:"Q41",
     enfermedad:"Ausencia, atresia y estenosis congenita del intestino delgado"
  },
  {
     id:"Q410",
     enfermedad:"Ausencia, atresia y estenosis congenita del duodeno"
  },
  {
     id:"Q411",
     enfermedad:"Ausencia, atresia y estenosis congenita del yeyuno"
  },
  {
     id:"Q412",
     enfermedad:"Ausencia, atresia y estenosis congenita del ileon"
  },
  {
     id:"Q418",
     enfermedad:"Ausencia, atresia y estenosis congenita de otras partes especificadas del intestino delgado"
  },
  {
     id:"Q419",
     enfermedad:"Ausencia, atresia y estenosis congenita del intestino delgado, parte no especificada"
  },
  {
     id:"Q42",
     enfermedad:"Ausencia, atresia y estenosis congenita del intestino grueso"
  },
  {
     id:"Q420",
     enfermedad:"Ausencia, atresia y estenosis congenita del recto, con fistula"
  },
  {
     id:"Q421",
     enfermedad:"Ausencia, atresia y estenosis congenita del recto, sin fistula"
  },
  {
     id:"Q422",
     enfermedad:"Ausencia, atresia y estenosis congenita del ano, con fistula"
  },
  {
     id:"Q423",
     enfermedad:"Ausencia, atresia y estenosis congenita del ano, sin fistula"
  },
  {
     id:"Q428",
     enfermedad:"Ausencia, atresia y estenosis congenita de otras partes del intestino grueso"
  },
  {
     id:"Q429",
     enfermedad:"Ausencia, atresia y estenosis congenita del intestino grueso, parte no especificada"
  },
  {
     id:"Q43",
     enfermedad:"Otras malformaciones congenitas del intestino"
  },
  {
     id:"Q430",
     enfermedad:"Diverticulo de meckel"
  },
  {
     id:"Q431",
     enfermedad:"Enfermedad de hirschsprung"
  },
  {
     id:"Q432",
     enfermedad:"Otros trastornos funcionales congenitos del colon"
  },
  {
     id:"Q433",
     enfermedad:"Malformaciones congenitas de la fijacion del intestino"
  },
  {
     id:"Q434",
     enfermedad:"Duplicacion del intestino"
  },
  {
     id:"Q435",
     enfermedad:"Ano ectopico"
  },
  {
     id:"Q436",
     enfermedad:"Fistula congenita del recto y del ano"
  },
  {
     id:"Q437",
     enfermedad:"Persistencia de la cloaca"
  },
  {
     id:"Q438",
     enfermedad:"Otras malformaciones congenitas del intestino, no especificadas"
  },
  {
     id:"Q439",
     enfermedad:"Malformacion congenita del intestino, no especificada"
  },
  {
     id:"Q44",
     enfermedad:"Malformaciones congenitas de la vesicula biliar, de los conductos biliares y del higado"
  },
  {
     id:"Q440",
     enfermedad:"Agenesia, aplasia e hipoplasia de la vesicula biliar"
  },
  {
     id:"Q441",
     enfermedad:"Otras malformaciones congenitas de la vesicula biliar"
  },
  {
     id:"Q442",
     enfermedad:"Atresia de los conductos biliares"
  },
  {
     id:"Q443",
     enfermedad:"Estrechez y estenosis congenita de los conductos biliares"
  },
  {
     id:"Q444",
     enfermedad:"Quiste del coledoco"
  },
  {
     id:"Q445",
     enfermedad:"Otras malformaciones congenitas de los conductos biliares"
  },
  {
     id:"Q446",
     enfermedad:"Enfermedad quistica del higado"
  },
  {
     id:"Q447",
     enfermedad:"Otras malformaciones congenitas del higado"
  },
  {
     id:"Q45",
     enfermedad:"Otras malformaciones congenitas del sistema digestivo"
  },
  {
     id:"Q450",
     enfermedad:"Agenesia, aplasia e hipoplasia del pancreas"
  },
  {
     id:"Q451",
     enfermedad:"Pancreas anular"
  },
  {
     id:"Q452",
     enfermedad:"Quiste congenito del pancreas"
  },
  {
     id:"Q453",
     enfermedad:"Otras malformaciones congenitas del pancreas y del conducto pancreatico"
  },
  {
     id:"Q458",
     enfermedad:"Otras malformaciones congenitas del sistema digestivo, especificadas"
  },
  {
     id:"Q459",
     enfermedad:"Malformacion congenita del sistema digestivo, no especificada"
  },
  {
     id:"Q50",
     enfermedad:"Malformaciones congenitas de los ovarios, de las trompas de falopio y de los ligamentos anchos"
  },
  {
     id:"Q500",
     enfermedad:"Ausencia congenita del ovario"
  },
  {
     id:"Q501",
     enfermedad:"Quiste en desarrollo del ovario"
  },
  {
     id:"Q502",
     enfermedad:"Torsion congenita del ovario"
  },
  {
     id:"Q503",
     enfermedad:"Otras malformaciones congenitas de los ovarios"
  },
  {
     id:"Q504",
     enfermedad:"Quiste embrionario de la trompa de falopio"
  },
  {
     id:"Q505",
     enfermedad:"Quiste embrionario del ligamento ancho"
  },
  {
     id:"Q506",
     enfermedad:"Otras malformaciones congenitas de la trompa de falopio y del ligamento ancho"
  },
  {
     id:"Q51",
     enfermedad:"Malformaciones congenitas del utero y del cuello uterino"
  },
  {
     id:"Q510",
     enfermedad:"Agenesia y aplasia del utero"
  },
  {
     id:"Q511",
     enfermedad:"Duplicacion del utero con duplicacion del cuello uterino y de la vagina"
  },
  {
     id:"Q512",
     enfermedad:"Otra duplicacion del utero"
  },
  {
     id:"Q513",
     enfermedad:"Utero bicorne"
  },
  {
     id:"Q514",
     enfermedad:"Utero unicorne"
  },
  {
     id:"Q515",
     enfermedad:"Agenesia y aplasia del cuello uterino"
  },
  {
     id:"Q516",
     enfermedad:"Quiste embrionario dl cuello uterino"
  },
  {
     id:"Q517",
     enfermedad:"Fistula congenita entre el utero y el tracto digestivo y urinario"
  },
  {
     id:"Q518",
     enfermedad:"Otra malformaciones congenitas del utero y del cuello uterino"
  },
  {
     id:"Q519",
     enfermedad:"Malformacion congenita del utero y del cuello uterino, no especificada"
  },
  {
     id:"Q52",
     enfermedad:"Otras malformaciones congenitas de los organos genitales feneminos"
  },
  {
     id:"Q520",
     enfermedad:"Ausencia congenita de la vagina"
  },
  {
     id:"Q521",
     enfermedad:"Duplicacion de la vagina"
  },
  {
     id:"Q522",
     enfermedad:"Fistula rectovaginal congenita"
  },
  {
     id:"Q523",
     enfermedad:"Himen imperforado"
  },
  {
     id:"Q524",
     enfermedad:"Otras malformaciones congenitas de la vagina"
  },
  {
     id:"Q525",
     enfermedad:"Fusion de labios de la vulva"
  },
  {
     id:"Q526",
     enfermedad:"Malformacion congenita del clitoris"
  },
  {
     id:"Q527",
     enfermedad:"Otras malformaciones congenitas de la vulva"
  },
  {
     id:"Q528",
     enfermedad:"Otras malformaciones congenitas de los organos genitales femeninos, especificadas"
  },
  {
     id:"Q529",
     enfermedad:"Malformacion congenita de los genitales femeninos, no especificada"
  },
  {
     id:"Q53",
     enfermedad:"Testiculo no descendido"
  },
  {
     id:"Q530",
     enfermedad:"Ectopia testicular"
  },
  {
     id:"Q531",
     enfermedad:"Testiculo no descendido, unilateral"
  },
  {
     id:"Q532",
     enfermedad:"Testiculo no descendido, bilateral"
  },
  {
     id:"Q539",
     enfermedad:"Testiculo no descendido, sin otra especificacion"
  },
  {
     id:"Q54",
     enfermedad:"Hipospadias"
  },
  {
     id:"Q540",
     enfermedad:"Hipospadias del glande"
  },
  {
     id:"Q541",
     enfermedad:"Hipospadias peneana"
  },
  {
     id:"Q542",
     enfermedad:"Hipospadias penoscrotal"
  },
  {
     id:"Q543",
     enfermedad:"Hipospadias perineal"
  },
  {
     id:"Q544",
     enfermedad:"Encordamiento congenito del pene"
  },
  {
     id:"Q548",
     enfermedad:"Otras hipospadias"
  },
  {
     id:"Q549",
     enfermedad:"Hipospadias, no especificada"
  },
  {
     id:"Q55",
     enfermedad:"Otras malformaciones congenitas de los organos genitales masculinos"
  },
  {
     id:"Q550",
     enfermedad:"Ausencia y aplasia del testiculo"
  },
  {
     id:"Q551",
     enfermedad:"Hipoplasia del testiculo y del escroto"
  },
  {
     id:"Q552",
     enfermedad:"Otras malformaciones congenitas de los testiculos y del escroto"
  },
  {
     id:"Q553",
     enfermedad:"Atresia del conducto deferente"
  },
  {
     id:"Q554",
     enfermedad:"Otras malformaciones congenitas de los conductos deferentes, del epididimo, de las vesiculas seminales y de la prostata"
  },
  {
     id:"Q555",
     enfermedad:"Aplasia y ausencia congenita del pene"
  },
  {
     id:"Q556",
     enfermedad:"Otras malformaciones congenitas del pene"
  },
  {
     id:"Q558",
     enfermedad:"Otras malformaciones congenitas de los organos genitales masculinos, especificadas"
  },
  {
     id:"Q559",
     enfermedad:"Malformacion congenita de los organos genitales masculinos, no especificada"
  },
  {
     id:"Q56",
     enfermedad:"Sexo indeterminado y seudohermafroditismo"
  },
  {
     id:"Q560",
     enfermedad:"Hermafroditismo, no clasificado en otra parte"
  },
  {
     id:"Q561",
     enfermedad:"Seudohermafroditismo masculino, no clasificado en otra parte"
  },
  {
     id:"Q562",
     enfermedad:"Seudohermafroditismo femenino, no clasificado en otra parte"
  },
  {
     id:"Q563",
     enfermedad:"Seudohermafroditismo, no especificado"
  },
  {
     id:"Q564",
     enfermedad:"Sexo indeterminado, sin otra especificacion"
  },
  {
     id:"Q60",
     enfermedad:"Agenesia renal y otras malformaciones hipoplasicas del rinon"
  },
  {
     id:"Q600",
     enfermedad:"Agenesia renal, unilateral"
  },
  {
     id:"Q601",
     enfermedad:"Agenesia renal, bilateral"
  },
  {
     id:"Q602",
     enfermedad:"Agenesia renal, sin otra especificacion"
  },
  {
     id:"Q603",
     enfermedad:"Hipoplasia renal, unilateral"
  },
  {
     id:"Q604",
     enfermedad:"Hipoplasia renal, bilateral"
  },
  {
     id:"Q605",
     enfermedad:"Hipoplasia renal, no especificada"
  },
  {
     id:"Q606",
     enfermedad:"Sindrome de potter"
  },
  {
     id:"Q61",
     enfermedad:"Enfermedad quistica del rinon"
  },
  {
     id:"Q610",
     enfermedad:"Quiste renal solitario congenito"
  },
  {
     id:"Q611",
     enfermedad:"Riñon poliquistico, autosomico recesivo"
  },
  {
     id:"Q612",
     enfermedad:"Riñon poliquistico, autosomico dominante"
  },
  {
     id:"Q613",
     enfermedad:"Riñon poliquistico, tipo no especificado"
  },
  {
     id:"Q614",
     enfermedad:"Displasia renal"
  },
  {
     id:"Q615",
     enfermedad:"Riñon quistico medular"
  },
  {
     id:"Q618",
     enfermedad:"Otras enfermedades renales quisticas"
  },
  {
     id:"Q619",
     enfermedad:"Enfermedad quistica del riñon, no especificada"
  },
  {
     id:"Q62",
     enfermedad:"Defectos obstructivos congenitos de la pelvis renal y malformaciones congenitas del ureter"
  },
  {
     id:"Q620",
     enfermedad:"Hidronefrosis congenita"
  },
  {
     id:"Q621",
     enfermedad:"Atresia y estenosis del ureter"
  },
  {
     id:"Q622",
     enfermedad:"Megaloureter congenito"
  },
  {
     id:"Q623",
     enfermedad:"Otros defectos obstructivos de la pelvis renal y del ureter"
  },
  {
     id:"Q624",
     enfermedad:"Agenesia del ureter"
  },
  {
     id:"Q625",
     enfermedad:"Duplicacion del ureter"
  },
  {
     id:"Q626",
     enfermedad:"Mala posicion del ureter"
  },
  {
     id:"Q627",
     enfermedad:"Reflujo vesico"
  },
  {
     id:"Q628",
     enfermedad:"Otras malformaciones congenitas del ureter"
  },
  {
     id:"Q63",
     enfermedad:"Otras malformaciones congenitas del riñon"
  },
  {
     id:"Q630",
     enfermedad:"Riñon supernumerario"
  },
  {
     id:"Q631",
     enfermedad:"Riñon lobulado, fusionado y en herradura"
  },
  {
     id:"Q632",
     enfermedad:"Riñon ectopico"
  },
  {
     id:"Q633",
     enfermedad:"Hiperplasia renal y riñon gigante"
  },
  {
     id:"Q638",
     enfermedad:"Otras malformaciones congenitas del riñon, especificadas"
  },
  {
     id:"Q639",
     enfermedad:"Malformacion congenita del riñon, no especificada"
  },
  {
     id:"Q64",
     enfermedad:"Otras malformaciones congenitas del sistema urinario"
  },
  {
     id:"Q640",
     enfermedad:"Epispadias"
  },
  {
     id:"Q641",
     enfermedad:"Extrofia de la vejiga urinaria"
  },
  {
     id:"Q642",
     enfermedad:"Valvulas uretrales posteriores congenitas"
  },
  {
     id:"Q643",
     enfermedad:"Otras atresias y estenosis de la uretra y del cuello de la vejiga"
  },
  {
     id:"Q644",
     enfermedad:"Malformacion del uraco"
  },
  {
     id:"Q645",
     enfermedad:"Ausencia congenita de la vejiga y de la uretra"
  },
  {
     id:"Q646",
     enfermedad:"Diverticulo congenito de la vejiga"
  },
  {
     id:"Q647",
     enfermedad:"Otras malformaciones congenitas de la vejiga y de la uretra"
  },
  {
     id:"Q648",
     enfermedad:"Otras malformaciones congenitas del aparato urinario, especificadas"
  },
  {
     id:"Q649",
     enfermedad:"Malformacion congenita del aparato urinario, no especificada"
  },
  {
     id:"Q65",
     enfermedad:"Deformidades congenitas de la cadera"
  },
  {
     id:"Q650",
     enfermedad:"Luxacion congenita de la cadera, unilateral"
  },
  {
     id:"Q651",
     enfermedad:"Luxacion congenita de la cadera, bilateral"
  },
  {
     id:"Q652",
     enfermedad:"Luxacion congenita de la cadera, no especificada"
  },
  {
     id:"Q653",
     enfermedad:"Subluxacion congenita de la cadera, unilateral"
  },
  {
     id:"Q654",
     enfermedad:"Subluxacion congenita de la cadera, bilateral"
  },
  {
     id:"Q655",
     enfermedad:"Subluxacion congenita de la cadera, no especificada"
  },
  {
     id:"Q656",
     enfermedad:"Cadera inestable"
  },
  {
     id:"Q658",
     enfermedad:"Otras deformidades congenitas de la cadera"
  },
  {
     id:"Q659",
     enfermedad:"Deformidad congenita de la cadera, no especificada"
  },
  {
     id:"Q66",
     enfermedad:"Deformidades congenitas de los pies"
  },
  {
     id:"Q660",
     enfermedad:"Talipes equinovarus"
  },
  {
     id:"Q661",
     enfermedad:"Talipes calcaneovarus"
  },
  {
     id:"Q662",
     enfermedad:"Metatarsus varus"
  },
  {
     id:"Q663",
     enfermedad:"Otras deformidades varus congenitas de los pies"
  },
  {
     id:"Q664",
     enfermedad:"Talipes calcaneovalgus"
  },
  {
     id:"Q665",
     enfermedad:"Pie plano congenito"
  },
  {
     id:"Q666",
     enfermedad:"Otras deformidades valgus congenitas de los pies"
  },
  {
     id:"Q667",
     enfermedad:"Pie cavus"
  },
  {
     id:"Q668",
     enfermedad:"Otras deformidades congenitas de los pies"
  },
  {
     id:"Q669",
     enfermedad:"Deformidad congenita de los pies, no especificada"
  },
  {
     id:"Q67",
     enfermedad:"Deformidades osteomusculares congenitas de la cabeza, de la cara, de la columna vertebral y del torax"
  },
  {
     id:"Q670",
     enfermedad:"Asimetria facial"
  },
  {
     id:"Q671",
     enfermedad:"Facies comprimida"
  },
  {
     id:"Q672",
     enfermedad:"Dolicocefalia"
  },
  {
     id:"Q673",
     enfermedad:"Plagiocefalia"
  },
  {
     id:"Q674",
     enfermedad:"Otras deformidades congenitas del craneo, de la cara y de la mandibula"
  },
  {
     id:"Q675",
     enfermedad:"Deformidad congenita de la columna vertebral"
  },
  {
     id:"Q676",
     enfermedad:"Torax excavado"
  },
  {
     id:"Q677",
     enfermedad:"Torax en quilla"
  },
  {
     id:"Q678",
     enfermedad:"Otras deformidades congenitas del torax"
  },
  {
     id:"Q68",
     enfermedad:"Otras deformidades osteomusculares congenitas"
  },
  {
     id:"Q680",
     enfermedad:"Deformidad congenita del musculo esternocleidomastoideo"
  },
  {
     id:"Q681",
     enfermedad:"Deformidad congenita de la mano"
  },
  {
     id:"Q682",
     enfermedad:"Deformidad congenita de la rodilla"
  },
  {
     id:"Q683",
     enfermedad:"Curvatura congenita del femur"
  },
  {
     id:"Q684",
     enfermedad:"Curvatura congenita de la tibia y el perone"
  },
  {
     id:"Q685",
     enfermedad:"Curvatura congenita de hueso(s) largo(s) del miembro inferior, sin otra especificacion"
  },
  {
     id:"Q688",
     enfermedad:"Otras deformidades congenitas osteomusculares, especificadas"
  },
  {
     id:"Q69",
     enfermedad:"Polidactilia"
  },
  {
     id:"Q690",
     enfermedad:"Dedo(s) supernumerario(s) de la mano"
  },
  {
     id:"Q691",
     enfermedad:"Pulgar(es) supernumerario(s)"
  },
  {
     id:"Q692",
     enfermedad:"Dedo(s) supernumerario(s) del pie"
  },
  {
     id:"Q699",
     enfermedad:"Polidactilia, no especificada"
  },
  {
     id:"Q70",
     enfermedad:"Sindactilia"
  },
  {
     id:"Q700",
     enfermedad:"Fusion de los dedos de la mano"
  },
  {
     id:"Q701",
     enfermedad:"Membrana interdigital de la mano"
  },
  {
     id:"Q702",
     enfermedad:"Fusion de los dedos del pie"
  },
  {
     id:"Q703",
     enfermedad:"Membrana interdigital del pie"
  },
  {
     id:"Q704",
     enfermedad:"Polisindactilia"
  },
  {
     id:"Q709",
     enfermedad:"Sindactilia, no especificada"
  },
  {
     id:"Q71",
     enfermedad:"Defectos por reduccion del miembro superior"
  },
  {
     id:"Q710",
     enfermedad:"Ausencia congenita completa del (de los) miembro(s) superior(es)"
  },
  {
     id:"Q711",
     enfermedad:"Ausencia congenita del brazo y del antebrazo con presencia de la mano"
  },
  {
     id:"Q712",
     enfermedad:"Ausencia congenita del antebrazo y de la mano"
  },
  {
     id:"Q713",
     enfermedad:"Ausencia congenita de la mano y el (los) dedo(s)"
  },
  {
     id:"Q714",
     enfermedad:"Defecto por reduccion longitudinal del radio"
  },
  {
     id:"Q715",
     enfermedad:"Defecto por reduccion longitudinal del cubito"
  },
  {
     id:"Q716",
     enfermedad:"Mano en pinza de langosta"
  },
  {
     id:"Q718",
     enfermedad:"Otros defectos por reduccion del (de los) miembro(s) superior(es)"
  },
  {
     id:"Q719",
     enfermedad:"Defecto por reduccion del miembro superior, no especificado"
  },
  {
     id:"Q72",
     enfermedad:"Defectos por reduccion del miembro inferior"
  },
  {
     id:"Q720",
     enfermedad:"Ausencia congenita completa del (de los) miembro(s) inferior(es)"
  },
  {
     id:"Q721",
     enfermedad:"Ausencia congenita del muslo y de la pierna con presencia del pie"
  },
  {
     id:"Q722",
     enfermedad:"Ausencia congenita de la pierna y del pie"
  },
  {
     id:"Q723",
     enfermedad:"Ausencia congenita del pie y dedo(s) del pie"
  },
  {
     id:"Q724",
     enfermedad:"Defecto por reduccion longitudinal del femur"
  },
  {
     id:"Q725",
     enfermedad:"Defecto por reduccion longitudinal de la tibia"
  },
  {
     id:"Q726",
     enfermedad:"Defecto por reduccion longitudinal del perone"
  },
  {
     id:"Q727",
     enfermedad:"Pie hendido"
  },
  {
     id:"Q728",
     enfermedad:"Otros defectos por reduccion del (de los) miembro(s) inferior(es)"
  },
  {
     id:"Q729",
     enfermedad:"Defecto por reduccion del miembro inferior, no especificado"
  },
  {
     id:"Q73",
     enfermedad:"Defectos por reduccion de miembro no especificado"
  },
  {
     id:"Q730",
     enfermedad:"Ausencia completa de miembro(s) no especificado(s)"
  },
  {
     id:"Q731",
     enfermedad:"Focomelia, miembro(s) no especificado(s)"
  },
  {
     id:"Q738",
     enfermedad:"Otros defectos por reduccion de miembro(s) no especificado(s)"
  },
  {
     id:"Q74",
     enfermedad:"Otras anomalias congenitas del (de los) miembro(s)"
  },
  {
     id:"Q740",
     enfermedad:"Otras malformaciones congenitas de (de los) miembro(s) superior(es), incluida la cintura escapular"
  },
  {
     id:"Q741",
     enfermedad:"Malformacion congenita de la rodilla"
  },
  {
     id:"Q742",
     enfermedad:"Otras malformaciones congenitas del (de los) miembro(s) inferior(es), incluida la cintura pelviana"
  },
  {
     id:"Q743",
     enfermedad:"Artrogriposis multiple congenita"
  },
  {
     id:"Q748",
     enfermedad:"Otras malformaciones congenitas especificadas del (de los) miembro(s)"
  },
  {
     id:"Q749",
     enfermedad:"Malformacion congenita de miembro(s), no especificada"
  },
  {
     id:"Q75",
     enfermedad:"Otras malformaciones congenitas de los huesos del craneo y de la cara"
  },
  {
     id:"Q750",
     enfermedad:"Craneosinostosis"
  },
  {
     id:"Q751",
     enfermedad:"Disostosis craneofacial"
  },
  {
     id:"Q752",
     enfermedad:"Hipertelorismo"
  },
  {
     id:"Q753",
     enfermedad:"Macrocefalia"
  },
  {
     id:"Q754",
     enfermedad:"Disostosis maxilofacial"
  },
  {
     id:"Q755",
     enfermedad:"Disostosis oculomaxilar"
  },
  {
     id:"Q758",
     enfermedad:"Otras malformaciones congenitas especificadas de los huesos del craneo y de la cara"
  },
  {
     id:"Q759",
     enfermedad:"Malformacion congenita no especificada de los huesos del craneo y de la cara"
  },
  {
     id:"Q76",
     enfermedad:"Malformaciones congenitas de la columna vertebral y torax oseo"
  },
  {
     id:"Q760",
     enfermedad:"Espina bifida oculta"
  },
  {
     id:"Q761",
     enfermedad:"Sindrome de klippel"
  },
  {
     id:"Q762",
     enfermedad:"Espondilolistesis congenita"
  },
  {
     id:"Q763",
     enfermedad:"Escoliosis congenita debida a malformacion congenita osea"
  },
  {
     id:"Q764",
     enfermedad:"Otra malformacion congenita de la columna vertebral, no asociada con escoliosis"
  },
  {
     id:"Q765",
     enfermedad:"Costilla cervical"
  },
  {
     id:"Q766",
     enfermedad:"Otras malformaciones congenitas de las costillas"
  },
  {
     id:"Q767",
     enfermedad:"Malformacion congenita del esternon"
  },
  {
     id:"Q768",
     enfermedad:"Otras malformaciones congenitas del torax oseo"
  },
  {
     id:"Q769",
     enfermedad:"Malformacion congenita del torax oseo, no especificada"
  },
  {
     id:"Q77",
     enfermedad:"Osteocondrodisplasia con defecto del crecimiento de los huesos largos y de la columna vertebral"
  },
  {
     id:"Q770",
     enfermedad:"Acondrogenesis"
  },
  {
     id:"Q771",
     enfermedad:"Enanismo tanatoforico"
  },
  {
     id:"Q772",
     enfermedad:"Sindrome de costilla corta"
  },
  {
     id:"Q773",
     enfermedad:"Condrodisplasia punctata"
  },
  {
     id:"Q774",
     enfermedad:"Acondroplasia"
  },
  {
     id:"Q775",
     enfermedad:"Displasia distrofica"
  },
  {
     id:"Q776",
     enfermedad:"Displasia condroectodermica"
  },
  {
     id:"Q777",
     enfermedad:"Displasia espondiloepifisaria"
  },
  {
     id:"Q778",
     enfermedad:"Otras osteocondrodisplasias con defectos del crecimiento de los huesos largos y de la columna vertebral"
  },
  {
     id:"Q779",
     enfermedad:"Osteocondrodisplasia con defectos del crecimiento de los huesos largos y de la columna vertebral, sin otra especificacion"
  },
  {
     id:"Q78",
     enfermedad:"Otras osteocondrodisplasias"
  },
  {
     id:"Q780",
     enfermedad:"Osteogenesis imperfecta"
  },
  {
     id:"Q781",
     enfermedad:"Displasia poliostotica fibrosa"
  },
  {
     id:"Q782",
     enfermedad:"Osteopetrosis"
  },
  {
     id:"Q783",
     enfermedad:"Displasia diafisaria progresiva"
  },
  {
     id:"Q784",
     enfermedad:"Encondromatosis"
  },
  {
     id:"Q785",
     enfermedad:"Displasia metafisaria"
  },
  {
     id:"Q786",
     enfermedad:"Exostosis congenita multiple"
  },
  {
     id:"Q788",
     enfermedad:"Otras osteocondrodisplasias especificadas"
  },
  {
     id:"Q789",
     enfermedad:"Osteocondrodisplasia, no especificada"
  },
  {
     id:"Q79",
     enfermedad:"Malformaciones congenitas del sistema osteomuscular, no clasificadas en otra parte"
  },
  {
     id:"Q790",
     enfermedad:"Hernia diafragmatica congenita"
  },
  {
     id:"Q791",
     enfermedad:"Otras malformaciones congenitas del diafragma"
  },
  {
     id:"Q792",
     enfermedad:"Exonfalos"
  },
  {
     id:"Q793",
     enfermedad:"Gastrosquisis"
  },
  {
     id:"Q794",
     enfermedad:"Sindrome del abdomen en ciruela pasa"
  },
  {
     id:"Q795",
     enfermedad:"Otras malformaciones congenitas de la pared abdominal"
  },
  {
     id:"Q796",
     enfermedad:"Sindrome de ehlers"
  },
  {
     id:"Q798",
     enfermedad:"Otras malformaciones congenitas del sistema osteomuscular"
  },
  {
     id:"Q799",
     enfermedad:"Malformacion congenita del sistema osteomuscular, no especificada"
  },
  {
     id:"Q80",
     enfermedad:"Ictiosis congenita"
  },
  {
     id:"Q800",
     enfermedad:"Ictiosis vulgar"
  },
  {
     id:"Q801",
     enfermedad:"Ictiosis ligada al cromosoma x"
  },
  {
     id:"Q802",
     enfermedad:"Ictiosis lamelar"
  },
  {
     id:"Q803",
     enfermedad:"Eritrodermia ictiosiforme vesicular congenita"
  },
  {
     id:"Q804",
     enfermedad:"Feto arlequin"
  },
  {
     id:"Q808",
     enfermedad:"Otras ictiosis congenitas"
  },
  {
     id:"Q809",
     enfermedad:"Ictiosis congenita, no especificada"
  },
  {
     id:"Q81",
     enfermedad:"Epidermolisis bullosa"
  },
  {
     id:"Q810",
     enfermedad:"Epidermolisis bullosa simple"
  },
  {
     id:"Q811",
     enfermedad:"Epidermolisis bullosa letal"
  },
  {
     id:"Q812",
     enfermedad:"Epidermolisis bullosa distrofica"
  },
  {
     id:"Q818",
     enfermedad:"Otras epidermolisis bullosas"
  },
  {
     id:"Q819",
     enfermedad:"Epidermolisis bullosa, no especificada"
  },
  {
     id:"Q82",
     enfermedad:"Otras malformaciones congenitas de la piel"
  },
  {
     id:"Q820",
     enfermedad:"Linfedema hereditario"
  },
  {
     id:"Q821",
     enfermedad:"Xeroderma pigmentoso"
  },
  {
     id:"Q822",
     enfermedad:"Mastocitosis"
  },
  {
     id:"Q823",
     enfermedad:"Incontinencia pigmentaria"
  },
  {
     id:"Q824",
     enfermedad:"Displasia ectodermica (anhidrotica)"
  },
  {
     id:"Q825",
     enfermedad:"Nevo no neoplasico, congenito"
  },
  {
     id:"Q828",
     enfermedad:"Otras malformaciones congenitas de la piel, especificadas"
  },
  {
     id:"Q829",
     enfermedad:"Malformacion congenita de la piel, no especificada"
  },
  {
     id:"Q83",
     enfermedad:"Malformaciones congenitas de la mama"
  },
  {
     id:"Q830",
     enfermedad:"Ausencia congenita de la mama con ausencia del pezon"
  },
  {
     id:"Q831",
     enfermedad:"Mama supernumeraria"
  },
  {
     id:"Q832",
     enfermedad:"Ausencia del pezon"
  },
  {
     id:"Q833",
     enfermedad:"Pezon supernumerario"
  },
  {
     id:"Q838",
     enfermedad:"Otras malformaciones congenitas de la mama"
  },
  {
     id:"Q839",
     enfermedad:"Malformacion congenita de la mama, no especificada"
  },
  {
     id:"Q84",
     enfermedad:"Otras malformaciones congenitas de las faneras"
  },
  {
     id:"Q840",
     enfermedad:"Alopecia congenita"
  },
  {
     id:"Q841",
     enfermedad:"Alteraciones morfologicas congenitas del pelo, no clasificadas en otra parte"
  },
  {
     id:"Q842",
     enfermedad:"Otras malformaciones congenitas del pelo"
  },
  {
     id:"Q843",
     enfermedad:"Anoniquia"
  },
  {
     id:"Q844",
     enfermedad:"Leuconiquia congenita"
  },
  {
     id:"Q845",
     enfermedad:"Agrandamiento e hipertrofia de las uñas"
  },
  {
     id:"Q846",
     enfermedad:"Otras malformaciones congenitas de las uñas"
  },
  {
     id:"Q848",
     enfermedad:"Otras malformaciones congenitas de las faneras, especificadas"
  },
  {
     id:"Q849",
     enfermedad:"Malformacion congenita de las faneras, no especificada"
  },
  {
     id:"Q85",
     enfermedad:"Facomatosis no clasificadas en otra parte"
  },
  {
     id:"Q850",
     enfermedad:"Neurofibromatosis (no maligna)"
  },
  {
     id:"Q851",
     enfermedad:"Esclerosis tuberosa"
  },
  {
     id:"Q858",
     enfermedad:"Otras facomatosis, no clasificadas en otra parte"
  },
  {
     id:"Q859",
     enfermedad:"Facomatosis, no especificada"
  },
  {
     id:"Q86",
     enfermedad:"Sindromes de malformaciones congenitas debidos a causas exogenas conocidas no clasificadas en otra parte"
  },
  {
     id:"Q860",
     enfermedad:"Sindrome fetal (dismorfico) debido al alcohol"
  },
  {
     id:"Q861",
     enfermedad:"Sindrome de hidantoina fetal"
  },
  {
     id:"Q862",
     enfermedad:"Dismorfismo debido a warfarina"
  },
  {
     id:"Q868",
     enfermedad:"Otros sindromes de malformaciones congenitas debidos a causas exogenas conocidas"
  },
  {
     id:"Q87",
     enfermedad:"Otros sindromes de malformaciones congenitas especificados que afectan multiples sistemas"
  },
  {
     id:"Q870",
     enfermedad:"Sindromes de malformaciones congenitas que afectan principalmente la apariencia facial"
  },
  {
     id:"Q871",
     enfermedad:"Sindromes de malformaciones congenitas asociadas principalmente con estatura baja"
  },
  {
     id:"Q872",
     enfermedad:"Sindromes de malformaciones congenitas que afectan principalmente los miembros"
  },
  {
     id:"Q873",
     enfermedad:"Sindromes de malformaciones congenitas con exceso de crecimiento precoz"
  },
  {
     id:"Q874",
     enfermedad:"Sindrome de marfan"
  },
  {
     id:"Q875",
     enfermedad:"Otros sindromes de malformaciones congenitas con otros cambios esqueleticos"
  },
  {
     id:"Q878",
     enfermedad:"Otros sindromes de malformaciones congenitas especificados, no clasificados en otra parte"
  },
  {
     id:"Q89",
     enfermedad:"Otras malformaciones congenitas no clasificadas en otra parte"
  },
  {
     id:"Q890",
     enfermedad:"Malformaciones congenitas del bazo"
  },
  {
     id:"Q891",
     enfermedad:"Malformaciones congenitas de la glandula suprarrenal"
  },
  {
     id:"Q892",
     enfermedad:"Malformaciones congenitas otras glandulas endocrinas"
  },
  {
     id:"Q893",
     enfermedad:"Situs inversus"
  },
  {
     id:"Q894",
     enfermedad:"Gemelos siameses"
  },
  {
     id:"Q897",
     enfermedad:"Malformaciones congenitas multiples, no clasificadas en otra parte"
  },
  {
     id:"Q898",
     enfermedad:"Otras malformaciones congenitas, especificadas"
  },
  {
     id:"Q899",
     enfermedad:"Malformacion congenita, no especificada"
  },
  {
     id:"Q90",
     enfermedad:"Sindrome de down"
  },
  {
     id:"Q900",
     enfermedad:"Trisomia 21, por falta de disyuncion meiotica"
  },
  {
     id:"Q901",
     enfermedad:"Trisomia 21, mosaico (por falta de disyuncion mitotica)"
  },
  {
     id:"Q902",
     enfermedad:"Trisomia 21, por translocacion"
  },
  {
     id:"Q909",
     enfermedad:"Sindrome de down, no especificado"
  },
  {
     id:"Q91",
     enfermedad:"Sindrome de edwards y sindrome de patau"
  },
  {
     id:"Q910",
     enfermedad:"Trisomia 18, por falta de disyuncion meiotica"
  },
  {
     id:"Q911",
     enfermedad:"Trisomia 18, mosaico (por falta de disyuncion mitotica)"
  },
  {
     id:"Q912",
     enfermedad:"Trisomia 18, por translocacion"
  },
  {
     id:"Q913",
     enfermedad:"Sindrome de edwards, no especificado"
  },
  {
     id:"Q914",
     enfermedad:"Trisomia 13, por falta de disyuncion meiotica"
  },
  {
     id:"Q915",
     enfermedad:"Trisomia 13, mosaico (por falta de disyuncion mitotica)"
  },
  {
     id:"Q916",
     enfermedad:"Trisomia 13, por translocacion"
  },
  {
     id:"Q917",
     enfermedad:"Sindrome de patau, no especificado"
  },
  {
     id:"Q92",
     enfermedad:"Otras trisomias y trisomias parciales de los autosomas no clasificadas en otra parte"
  },
  {
     id:"Q920",
     enfermedad:"Trisomia de un cromosoma completo, por falta de disyuncion meiotica"
  },
  {
     id:"Q921",
     enfermedad:"Trisomia de un cromosoma completo, mosaico (por falta de disyuncion mitotica)"
  },
  {
     id:"Q922",
     enfermedad:"Trisomia parcial mayor"
  },
  {
     id:"Q923",
     enfermedad:"Trisomia parcial menor"
  },
  {
     id:"Q924",
     enfermedad:"Duplicaciones visibles solo en la prometafase"
  },
  {
     id:"Q925",
     enfermedad:"Duplicaciones con otros reordenamientos complejos"
  },
  {
     id:"Q926",
     enfermedad:"Cromosomas marcadores suplementarios"
  },
  {
     id:"Q927",
     enfermedad:"Triploidia y poliploidia"
  },
  {
     id:"Q928",
     enfermedad:"Otras trisomias y trisomias parciales de los autosomas, especificadas"
  },
  {
     id:"Q929",
     enfermedad:"Trisomia y trisomia parcial de los autosomas, sin otra especificacion"
  },
  {
     id:"Q93",
     enfermedad:"Monosomias y supresiones de los autosomas, no clasificadas en otra parte"
  },
  {
     id:"Q930",
     enfermedad:"Monosomia completa de un cromosoma, por falta de disyuncion meiotica"
  },
  {
     id:"Q931",
     enfermedad:"Monosomia completa de un cromosoma, mosaico (por falta de disyuncion mitotica)"
  },
  {
     id:"Q932",
     enfermedad:"Cromosoma reemplazado por anillo o dicentrico"
  },
  {
     id:"Q933",
     enfermedad:"Supresion del brazo corto del cromosoma 4"
  },
  {
     id:"Q934",
     enfermedad:"Supresion del brazo corto del cromosoma 5"
  },
  {
     id:"Q935",
     enfermedad:"Otras supresiones de parte de un cromosoma"
  },
  {
     id:"Q936",
     enfermedad:"Supresiones visibles solo en la prometafase"
  },
  {
     id:"Q937",
     enfermedad:"Supresiones con otros reordenamientos complejos"
  },
  {
     id:"Q938",
     enfermedad:"Otras supresiones de los autosomas"
  },
  {
     id:"Q939",
     enfermedad:"Supresion de los autosomas, no especificada"
  },
  {
     id:"Q95",
     enfermedad:"Reordenamientos equilibrados y marcadores estructurales, no clasificadas en otra parte"
  },
  {
     id:"Q950",
     enfermedad:"Translocacion equilibrada e insercion en individuo normal"
  },
  {
     id:"Q951",
     enfermedad:"Inversion cromosomica en individuo normal"
  },
  {
     id:"Q952",
     enfermedad:"Reordenamiento autosomico equilibrado en individuo anormal"
  },
  {
     id:"Q953",
     enfermedad:"Reordenamiento autosomico/sexual equilibrado en individuo anormal"
  },
  {
     id:"Q954",
     enfermedad:"Individuos con heterocromatina marcadora"
  },
  {
     id:"Q955",
     enfermedad:"Individuos con sitio fragil autosomico"
  },
  {
     id:"Q958",
     enfermedad:"Otros reordenamientos equilibrados y marcadores estructurales"
  },
  {
     id:"Q959",
     enfermedad:"Reordenamiento equilibrado y marcador estructural, sin otra especificacion"
  },
  {
     id:"Q96",
     enfermedad:"Sindrome de turner"
  },
  {
     id:"Q960",
     enfermedad:"Cariotipo 45,x"
  },
  {
     id:"Q961",
     enfermedad:"Cariotipo 46,x iso (xq)"
  },
  {
     id:"Q962",
     enfermedad:"Cariotipo 46,x con cromosoma sexual anormal excepto iso (xq)"
  },
  {
     id:"Q963",
     enfermedad:"Mosaico 45,x/46,xx o xy"
  },
  {
     id:"Q964",
     enfermedad:"Mosaico 45,x/otra(s) linea(s) celular(es) con cromosoma sexual anormal"
  },
  {
     id:"Q968",
     enfermedad:"Otras variantes del sindrome de turner"
  },
  {
     id:"Q969",
     enfermedad:"Sindrome de turner, no especificado"
  },
  {
     id:"Q97",
     enfermedad:"Otras anomalias de los cromosomas sexuales, con fenotipo femenino no clasificadas en otra parte"
  },
  {
     id:"Q970",
     enfermedad:"Cariotipo 47,xxx"
  },
  {
     id:"Q971",
     enfermedad:"Mujer con mas de tres cromosomas x"
  },
  {
     id:"Q972",
     enfermedad:"Mosaico, lineas con numero variable de cromosomas x"
  },
  {
     id:"Q973",
     enfermedad:"Mujer con cariotipo 46,xy"
  },
  {
     id:"Q978",
     enfermedad:"Otras anomalias de los cromosomas sexuales, con fenotipo femenino, especificadas"
  },
  {
     id:"Q979",
     enfermedad:"Anomalia de los cromosomas sexuales, con fenotipo femenino, sin otra especificacion"
  },
  {
     id:"Q98",
     enfermedad:"Otras anomalias de los cromosomas sexuales, con fenotipo masculino no clasificadas en otra parte"
  },
  {
     id:"Q980",
     enfermedad:"Sindrome de klinefelter, cariotipo 47,xxy"
  },
  {
     id:"Q981",
     enfermedad:"Sindrome de klinefelter, hombre con mas de dos cromosomas x"
  },
  {
     id:"Q982",
     enfermedad:"Sindrome de klinefelter, hombre con cariotipo 46,xx"
  },
  {
     id:"Q983",
     enfermedad:"Otro hombre con cariotipo 46,xx"
  },
  {
     id:"Q984",
     enfermedad:"Sindrome de klinefelter, no especificado"
  },
  {
     id:"Q985",
     enfermedad:"Cariotipo 47,xyy"
  },
  {
     id:"Q986",
     enfermedad:"Hombre con cromosoma sexual estructuralmente anormal"
  },
  {
     id:"Q987",
     enfermedad:"Hombre con mosaico de cromosomas sexuales"
  },
  {
     id:"Q988",
     enfermedad:"Otras anomalias de los cromosomas sexuales, con fenotipo masculino, especificadas"
  },
  {
     id:"Q989",
     enfermedad:"Anomalia de los cromosomas sexuales, fenotipo masculino, sin otra especificacion"
  },
  {
     id:"Q99",
     enfermedad:"Otras anomalias cromosomicas no clasificadas en otra parte"
  },
  {
     id:"Q990",
     enfermedad:"Quimera 46,xx/46,xy"
  },
  {
     id:"Q991",
     enfermedad:"Hermafrodita verdadero 46,xx"
  },
  {
     id:"Q992",
     enfermedad:"Cromosoma x fragil"
  },
  {
     id:"Q998",
     enfermedad:"Otras anomalias de los cromosomas. especificadas"
  },
  {
     id:"Q999",
     enfermedad:"Anomalia cromosomica, no especificada"
  },
  {
     id:"R00",
     enfermedad:"Anormalidades del latido cardiaco"
  },
  {
     id:"R000",
     enfermedad:"Taquicardia, no especificada"
  },
  {
     id:"R001",
     enfermedad:"Bradicardia, no especificada"
  },
  {
     id:"R002",
     enfermedad:"Palpitaciones"
  },
  {
     id:"R008",
     enfermedad:"Otras anormalidades del latido cardiaco y las no especificadas"
  },
  {
     id:"R01",
     enfermedad:"Soplos y otros sonidos cardiacos"
  },
  {
     id:"R010",
     enfermedad:"Soplos cardiacos benignos o inocentes"
  },
  {
     id:"R011",
     enfermedad:"Soplo cardiaco, no especificado"
  },
  {
     id:"R012",
     enfermedad:"Otros sonidos cardiacos"
  },
  {
     id:"R02",
     enfermedad:"Gangrena no clasificada en otra parte"
  },
  {
     id:"R03",
     enfermedad:"Lectura de presion sanguinea anormal, sin diagnostico"
  },
  {
     id:"R030",
     enfermedad:"Lectura elevada de la presion sanguinea, sin diagnostico de hipertension"
  },
  {
     id:"R031",
     enfermedad:"Lectura de presion baja no especifica"
  },
  {
     id:"R04",
     enfermedad:"Hemorragias de las vias respiratorias"
  },
  {
     id:"R040",
     enfermedad:"Epistaxis"
  },
  {
     id:"R041",
     enfermedad:"Hemorragia de la garganta"
  },
  {
     id:"R042",
     enfermedad:"Hemoptisis"
  },
  {
     id:"R048",
     enfermedad:"Hemorragia de otros sitios de las vias respiratorias"
  },
  {
     id:"R049",
     enfermedad:"Hemorragia de las vias respiratorias, no especificada"
  },
  {
     id:"R05",
     enfermedad:"Tos"
  },
  {
     id:"R06",
     enfermedad:"Anormalidades de la respiracion"
  },
  {
     id:"R060",
     enfermedad:"Disnea"
  },
  {
     id:"R061",
     enfermedad:"Estridor"
  },
  {
     id:"R062",
     enfermedad:"Silbido"
  },
  {
     id:"R063",
     enfermedad:"Respiracion periodica"
  },
  {
     id:"R064",
     enfermedad:"Hiperventilacion"
  },
  {
     id:"R065",
     enfermedad:"Respiracion con la boca"
  },
  {
     id:"R066",
     enfermedad:"Hipo"
  },
  {
     id:"R067",
     enfermedad:"Estornudo"
  },
  {
     id:"R068",
     enfermedad:"Otras anormalidades de la respiracion y las no especificadas"
  },
  {
     id:"R07",
     enfermedad:"Dolor de garganta y en el pecho"
  },
  {
     id:"R070",
     enfermedad:"Dolor de garganta"
  },
  {
     id:"R071",
     enfermedad:"Dolor en el pecho al respirar"
  },
  {
     id:"R072",
     enfermedad:"Dolor precordial"
  },
  {
     id:"R073",
     enfermedad:"Otros dolores en el pecho"
  },
  {
     id:"R074",
     enfermedad:"Dolor en el pecho, no especificado"
  },
  {
     id:"R09",
     enfermedad:"Otros sintomas y signos que involucran los sistemas circulatorio y respiratorio"
  },
  {
     id:"R090",
     enfermedad:"Asfixia"
  },
  {
     id:"R091",
     enfermedad:"Pleuresia"
  },
  {
     id:"R092",
     enfermedad:"Paro respiratorio"
  },
  {
     id:"R093",
     enfermedad:"Esputo anormal"
  },
  {
     id:"R098",
     enfermedad:"Otros sintomas y signos especificados que involucran los sistemas circulatorio y respiratorio"
  },
  {
     id:"R10",
     enfermedad:"Dolor abdominal y pelvico"
  },
  {
     id:"R100",
     enfermedad:"Abdomen agudo"
  },
  {
     id:"R101",
     enfermedad:"Dolor abdominal localizado en parte superior"
  },
  {
     id:"R102",
     enfermedad:"Dolor pelvico y perineal"
  },
  {
     id:"R103",
     enfermedad:"Dolor localizado en otras partes inferiores del abdomen"
  },
  {
     id:"R104",
     enfermedad:"Otros dolores abdominales y los no especificados"
  },
  {
     id:"R11",
     enfermedad:"Nausea y vomito"
  },
  {
     id:"R12",
     enfermedad:"Acidez"
  },
  {
     id:"R13",
     enfermedad:"Disfagia"
  },
  {
     id:"R14",
     enfermedad:"Flatulencia y afecciones afines"
  },
  {
     id:"R15",
     enfermedad:"Incontinencia fecal"
  },
  {
     id:"R16",
     enfermedad:"Hepatomegalia y esplenomegalia no clasificadas en otra parte"
  },
  {
     id:"R160",
     enfermedad:"Hepatomegalia, no clasificada en otra parte"
  },
  {
     id:"R161",
     enfermedad:"Esplenomegalia, no clasificada en otra parte"
  },
  {
     id:"R162",
     enfermedad:"Hepatomegalia con esplenomegalia, no clasificadas en otra parte"
  },
  {
     id:"R17",
     enfermedad:"Ictericia no especificada"
  },
  {
     id:"R18",
     enfermedad:"Ascitis"
  },
  {
     id:"R19",
     enfermedad:"Otros sintomas y signos que involucran el sistema digestivo y el abdomen"
  },
  {
     id:"R190",
     enfermedad:"Tumefaccion, masa o prominencia intraabdominal y pelvica"
  },
  {
     id:"R191",
     enfermedad:"Sonidos intestinales anormales"
  },
  {
     id:"R192",
     enfermedad:"Peristalsis visible"
  },
  {
     id:"R193",
     enfermedad:"Rigidez abdominal"
  },
  {
     id:"R194",
     enfermedad:"Cambios en los hábitos intestinales"
  },
  {
     id:"R195",
     enfermedad:"Otras anormalidades fecales"
  },
  {
     id:"R196",
     enfermedad:"Halitosis"
  },
  {
     id:"R198",
     enfermedad:"Otros sintomas y signos especificados que involucran el sistema digestivo y el abdomen"
  },
  {
     id:"R20",
     enfermedad:"Alteraciones de la sensibilidad cutanea"
  },
  {
     id:"R200",
     enfermedad:"Anestesia de la piel"
  },
  {
     id:"R201",
     enfermedad:"Hipoestesia de la piel"
  },
  {
     id:"R202",
     enfermedad:"Parestesia de la piel"
  },
  {
     id:"R203",
     enfermedad:"Hiperestesia"
  },
  {
     id:"R208",
     enfermedad:"Otras alteraciones de la sensibilidad cutanea y las no especificadas"
  },
  {
     id:"R21",
     enfermedad:"Salpullido y otras erupciones cutaneas no especificadas"
  },
  {
     id:"R22",
     enfermedad:"Tumefaccion, masa o prominencia de la piel y del tejido subcutaneo localizado"
  },
  {
     id:"R220",
     enfermedad:"Tumefaccion, masa o prominencia localizada en la cabeza"
  },
  {
     id:"R221",
     enfermedad:"Tumefaccion, masa o prominencia localizada en el cuello"
  },
  {
     id:"R222",
     enfermedad:"Tumefaccion, masa o prominencia localizada en el tronco"
  },
  {
     id:"R223",
     enfermedad:"Tumefaccion, masa o prominencia localizada en el miembro superior"
  },
  {
     id:"R224",
     enfermedad:"Tumefaccion, masa o prominencia localizada en el miembro inferior"
  },
  {
     id:"R227",
     enfermedad:"Tumefaccion, masa o prominencia localizada en sitios multiples"
  },
  {
     id:"R229",
     enfermedad:"Tumefaccion, masa o prominencia localizada en parte no especificada"
  },
  {
     id:"R23",
     enfermedad:"Otros cambios en la piel"
  },
  {
     id:"R230",
     enfermedad:"Cianosis"
  },
  {
     id:"R231",
     enfermedad:"Palidez"
  },
  {
     id:"R232",
     enfermedad:"Rubor"
  },
  {
     id:"R233",
     enfermedad:"Equimosis espontanea"
  },
  {
     id:"R234",
     enfermedad:"Cambios en la textura de la piel"
  },
  {
     id:"R238",
     enfermedad:"Otros cambios de la piel y los no especificados"
  },
  {
     id:"R25",
     enfermedad:"Movimientos involuntarios anormales"
  },
  {
     id:"R250",
     enfermedad:"Movimientos anormales de la cabeza"
  },
  {
     id:"R251",
     enfermedad:"Temblor no especificado"
  },
  {
     id:"R252",
     enfermedad:"Calambres y espasmos"
  },
  {
     id:"R253",
     enfermedad:"Fasciculacion"
  },
  {
     id:"R258",
     enfermedad:"Otros movimientos anormales involuntarios y los no especificados"
  },
  {
     id:"R26",
     enfermedad:"Anormalidades de la marcha y de la movilidad"
  },
  {
     id:"R260",
     enfermedad:"Marcha ataxica"
  },
  {
     id:"R261",
     enfermedad:"Marcha paralitica"
  },
  {
     id:"R262",
     enfermedad:"Dificultad para caminar, no clasificada en otra parte"
  },
  {
     id:"R268",
     enfermedad:"Otras anormalidades de la marcha y de la movilidad y las no especificadas"
  },
  {
     id:"R27",
     enfermedad:"Otras fallas de coordinacion"
  },
  {
     id:"R270",
     enfermedad:"Ataxia, no especificada"
  },
  {
     id:"R278",
     enfermedad:"Otras fallas de la coordinacion y las no especificadas"
  },
  {
     id:"R29",
     enfermedad:"Otros sintomas y signos que involucran los sistemas nervioso y osteomuscular"
  },
  {
     id:"R290",
     enfermedad:"Tetania"
  },
  {
     id:"R291",
     enfermedad:"Meningismo"
  },
  {
     id:"R292",
     enfermedad:"Reflejos anormales"
  },
  {
     id:"R293",
     enfermedad:"Postura anormal"
  },
  {
     id:"R294",
     enfermedad:"Chasquido de la cadera"
  },
  {
     id:"R298",
     enfermedad:"Otros sintomas y signos que involucran los sistemas nervioso y osteomuscular y los no especificados"
  },
  {
     id:"R30",
     enfermedad:"Dolor asociado con la miccion"
  },
  {
     id:"R300",
     enfermedad:"Disuria"
  },
  {
     id:"R301",
     enfermedad:"Tenesmo vesical"
  },
  {
     id:"R309",
     enfermedad:"Miccion dolorosa, no especificada"
  },
  {
     id:"R31",
     enfermedad:"Hematuria no especificada"
  },
  {
     id:"R32",
     enfermedad:"Incontinencia urinaria no especificada"
  },
  {
     id:"R33",
     enfermedad:"Retencion de orina"
  },
  {
     id:"R34",
     enfermedad:"Anuria y oliguria"
  },
  {
     id:"R35",
     enfermedad:"Poliuria"
  },
  {
     id:"R36",
     enfermedad:"Descarga uretral"
  },
  {
     id:"R39",
     enfermedad:"Otros sintomas y signos que involucran el sistema urinario"
  },
  {
     id:"R390",
     enfermedad:"Extravasacion de la orina"
  },
  {
     id:"R391",
     enfermedad:"Otras dificultades de la miccion"
  },
  {
     id:"R392",
     enfermedad:"Uremia extrarrenal"
  },
  {
     id:"R398",
     enfermedad:"Otros sintomas y signos que involucran el sistema urinario y los no especificados"
  },
  {
     id:"R40",
     enfermedad:"Somnolencia, estupor y coma"
  },
  {
     id:"R400",
     enfermedad:"Somnolencia"
  },
  {
     id:"R401",
     enfermedad:"Estupor"
  },
  {
     id:"R402",
     enfermedad:"Coma, no especificado"
  },
  {
     id:"R41",
     enfermedad:"Otros sintomas y signos que involucran la funcion cognoscitiva y la conciencia"
  },
  {
     id:"R410",
     enfermedad:"Desorientacion no especificada"
  },
  {
     id:"R411",
     enfermedad:"Amnesia anterograda"
  },
  {
     id:"R412",
     enfermedad:"Amnesia retrograda"
  },
  {
     id:"R413",
     enfermedad:"Otra amnesia"
  },
  {
     id:"R418",
     enfermedad:"Otros sintomas y signos que involucran la funcion cognoscitiva y la conciencia y los no especificados"
  },
  {
     id:"R42",
     enfermedad:"Mareo y desvanecimiento"
  },
  {
     id:"R43",
     enfermedad:"Trastornos del olfato y del gusto"
  },
  {
     id:"R430",
     enfermedad:"Anosmia"
  },
  {
     id:"R431",
     enfermedad:"Parosmia"
  },
  {
     id:"R432",
     enfermedad:"Parageusia"
  },
  {
     id:"R438",
     enfermedad:"Otras alteraciones del gusto y del olfato y las no especificadas"
  },
  {
     id:"R44",
     enfermedad:"Otros sintomas y signos que involucran las sensaciones y percepciones generales"
  },
  {
     id:"R440",
     enfermedad:"Alucinaciones auditivas"
  },
  {
     id:"R441",
     enfermedad:"Alucinaciones visuales"
  },
  {
     id:"R442",
     enfermedad:"Otras alucinaciones"
  },
  {
     id:"R443",
     enfermedad:"Alucinaciones, no especificadas"
  },
  {
     id:"R448",
     enfermedad:"Otros sintomas y signos que involucran las sensaciones y percepciones generales y los no especificados"
  },
  {
     id:"R45",
     enfermedad:"Sintomas y signos que involucran el estado emocional"
  },
  {
     id:"R450",
     enfermedad:"Nerviosismo"
  },
  {
     id:"R451",
     enfermedad:"Inquietud y agitacion"
  },
  {
     id:"R452",
     enfermedad:"Infelicidad"
  },
  {
     id:"R453",
     enfermedad:"Desmoralizacion y apatia"
  },
  {
     id:"R454",
     enfermedad:"Irritabilidad y enojo"
  },
  {
     id:"R455",
     enfermedad:"Hostilidad"
  },
  {
     id:"R456",
     enfermedad:"Violencia fisica"
  },
  {
     id:"R457",
     enfermedad:"Tension y estado de choque emocional, no especificado"
  },
  {
     id:"R458",
     enfermedad:"Otros sintomas y signos que involucran el estado emocional"
  },
  {
     id:"R46",
     enfermedad:"Sintomas y signos que involucran la apariencia y el comportamiento"
  },
  {
     id:"R460",
     enfermedad:"Muy bajo nivel de higiene personal"
  },
  {
     id:"R461",
     enfermedad:"Apariencia personal extraña"
  },
  {
     id:"R462",
     enfermedad:"Conducta extraña e inexplicable"
  },
  {
     id:"R463",
     enfermedad:"Hiperactividad"
  },
  {
     id:"R464",
     enfermedad:"Lentitud y pobre respuesta"
  },
  {
     id:"R465",
     enfermedad:"Suspicacia y evasividad marcadas"
  },
  {
     id:"R466",
     enfermedad:"Preocupación indebida por sucesos que causan tension"
  },
  {
     id:"R467",
     enfermedad:"Verbosidad y detalles circunstanciales que oscurecen la razon de la consulta o el contacto"
  },
  {
     id:"R468",
     enfermedad:"Otros sintomas y signos que involucran la apariencia y el comportamiento"
  },
  {
     id:"R47",
     enfermedad:"Alteraciones del habla no clasificadas en otra parte"
  },
  {
     id:"R470",
     enfermedad:"Disfasia y afasia"
  },
  {
     id:"R471",
     enfermedad:"Disartria y anartria"
  },
  {
     id:"R478",
     enfermedad:"Otras alteraciones del habla y las no especificadas"
  },
  {
     id:"R48",
     enfermedad:"Dislexia y otras disfunciones simbolicas no clasificadas en otra parte"
  },
  {
     id:"R480",
     enfermedad:"Dislexia y alexia"
  },
  {
     id:"R481",
     enfermedad:"Agnosia"
  },
  {
     id:"R482",
     enfermedad:"Apraxia"
  },
  {
     id:"R488",
     enfermedad:"Otras disfunciones simbolicas y las no especificadas"
  },
  {
     id:"R49",
     enfermedad:"Alteraciones de la voz"
  },
  {
     id:"R490",
     enfermedad:"Disfonia"
  },
  {
     id:"R491",
     enfermedad:"Afonia"
  },
  {
     id:"R492",
     enfermedad:"Hipernasalidad e hiponasalidad"
  },
  {
     id:"R498",
     enfermedad:"Otras alteraciones de la voz y las no especificadas"
  },
  {
     id:"R50",
     enfermedad:"Fiebre de origen desconocido"
  },
  {
     id:"R500",
     enfermedad:"Fiebre con escalofrio"
  },
  {
     id:"R501",
     enfermedad:"Fiebre persistente"
  },
  {
     id:"R509",
     enfermedad:"Fiebre, no especificada"
  },
  {
     id:"R51",
     enfermedad:"Cefalea"
  },
  {
     id:"R52",
     enfermedad:"Dolor no clasificadas en otra parte"
  },
  {
     id:"R520",
     enfermedad:"Dolor agudo"
  },
  {
     id:"R521",
     enfermedad:"Dolor cronico intratable"
  },
  {
     id:"R522",
     enfermedad:"Otro dolor cronico"
  },
  {
     id:"R529",
     enfermedad:"Dolor, no especificado"
  },
  {
     id:"R53",
     enfermedad:"Malestar y fatiga"
  },
  {
     id:"R54",
     enfermedad:"Senilidad"
  },
  {
     id:"R55",
     enfermedad:"Sincope y colapso"
  },
  {
     id:"R56",
     enfermedad:"Convulsiones no clasificadas en otra parte"
  },
  {
     id:"R560",
     enfermedad:"Convulsiones febriles"
  },
  {
     id:"R568",
     enfermedad:"Otras convulsiones y las no especificadas"
  },
  {
     id:"R57",
     enfermedad:"Choque no clasificado en otra parte"
  },
  {
     id:"R570",
     enfermedad:"Choque cardiogenico"
  },
  {
     id:"R571",
     enfermedad:"Choque hipovolemico"
  },
  {
     id:"R578",
     enfermedad:"Otras formas de choque"
  },
  {
     id:"R579",
     enfermedad:"Choque , no especificado"
  },
  {
     id:"R58",
     enfermedad:"Hemorragia no clasificadas en otra parte"
  },
  {
     id:"R59",
     enfermedad:"Adenomegalia"
  },
  {
     id:"R590",
     enfermedad:"Adenomegalia localizada"
  },
  {
     id:"R591",
     enfermedad:"Adenomegalia generalizada"
  },
  {
     id:"R599",
     enfermedad:"Adenomegalia, no especificada"
  },
  {
     id:"R60",
     enfermedad:"Edema no clasificado en otra parte"
  },
  {
     id:"R600",
     enfermedad:"Edema localizado"
  },
  {
     id:"R601",
     enfermedad:"Edema generalizado"
  },
  {
     id:"R609",
     enfermedad:"Edema, no especificado"
  },
  {
     id:"R61",
     enfermedad:"Hiperhidrosis"
  },
  {
     id:"R610",
     enfermedad:"Hiperhidrosis localizada"
  },
  {
     id:"R611",
     enfermedad:"Hiperhidrosis generalizada"
  },
  {
     id:"R619",
     enfermedad:"Hiperhidrosis, no especificada"
  },
  {
     id:"R62",
     enfermedad:"Falta del desarrollo fisiologico normal esperado"
  },
  {
     id:"R620",
     enfermedad:"Retardo en desarrollo"
  },
  {
     id:"R628",
     enfermedad:"Otras faltas del desarrollo fisiologico normal esperado"
  },
  {
     id:"R629",
     enfermedad:"Falta del desarrollo fisiologico normal esperado sin otra especificacion"
  },
  {
     id:"R63",
     enfermedad:"Sintomas y signos que involucran concernientes a la alimentacion e ingestion de liquidos"
  },
  {
     id:"R630",
     enfermedad:"Anorexia"
  },
  {
     id:"R631",
     enfermedad:"Polidipsia"
  },
  {
     id:"R632",
     enfermedad:"Polifagia"
  },
  {
     id:"R633",
     enfermedad:"Dificultades y mala administracion de la alimentacion"
  },
  {
     id:"R634",
     enfermedad:"Perdida anormal de peso"
  },
  {
     id:"R635",
     enfermedad:"Aumento anormal de peso"
  },
  {
     id:"R638",
     enfermedad:"Otros sintomas y signos concernientes a la alimentacion y a la ingestion de liquidos"
  },
  {
     id:"R64",
     enfermedad:"Caquexia"
  },
  {
     id:"R68",
     enfermedad:"Otros sintomas y signos generales"
  },
  {
     id:"R680",
     enfermedad:"Hipotermia no asociada con baja temperatura del ambiente"
  },
  {
     id:"R681",
     enfermedad:"Sintomas no especificos propios de la infancia"
  },
  {
     id:"R682",
     enfermedad:"Boca seca, no especificada"
  },
  {
     id:"R683",
     enfermedad:"Dedos de la mano deformes"
  },
  {
     id:"R688",
     enfermedad:"Otros sintomas y signos generales especificados"
  },
  {
     id:"R69",
     enfermedad:"Causas de morbilidad desconocidas y no especificadas"
  },
  {
     id:"R70",
     enfermedad:"Velocidad de eritrosedimentacion elevada y otras anormalidades de la viscosidad del plasma"
  },
  {
     id:"R700",
     enfermedad:"Velocidad de eritrosedimentacion elevada"
  },
  {
     id:"R701",
     enfermedad:"Viscosidad plasmatica anormal"
  },
  {
     id:"R71",
     enfermedad:"Anormalidad de los eritrocitos"
  },
  {
     id:"R72",
     enfermedad:"Anormalidades de los leucocitos no clasificadas en otra parte"
  },
  {
     id:"R73",
     enfermedad:"Nivel elevado de glucosa en sangre"
  },
  {
     id:"R730",
     enfermedad:"Anormalidades en la prueba de tolerancia a la glucosa"
  },
  {
     id:"R739",
     enfermedad:"Hiperglicemia, no especificada"
  },
  {
     id:"R74",
     enfermedad:"Nivel anormal de enzimas en suero"
  },
  {
     id:"R740",
     enfermedad:"Elevacion de los niveles de transaminasas o deshidrogenasa lactica [dhl]"
  },
  {
     id:"R748",
     enfermedad:"Niveles anormales de otras enzimas en suero"
  },
  {
     id:"R749",
     enfermedad:"Nivel anormal de enzimas en suero, no especificado"
  },
  {
     id:"R75",
     enfermedad:"Evidencias de laboratorio del virus de la inmunodeficiencia humana [vih]"
  },
  {
     id:"R76",
     enfermedad:"Otros hallazgos inmunologicos anormales en suero"
  },
  {
     id:"R760",
     enfermedad:"Titulacion elevada de anticuerpos"
  },
  {
     id:"R761",
     enfermedad:"Reaccion anormal a la prueba con tuberculina"
  },
  {
     id:"R762",
     enfermedad:"Falso positivo en la prueba serologica para sifilis"
  },
  {
     id:"R768",
     enfermedad:"Otros hallazgos inmunologicos anormales especificados en suero"
  },
  {
     id:"R769",
     enfermedad:"Hallazgos inmunologicos anormales especificados en suero"
  },
  {
     id:"R77",
     enfermedad:"Otras anormalidades de las proteinas plasmaticas"
  },
  {
     id:"R770",
     enfermedad:"Anormalidad de la albumina"
  },
  {
     id:"R771",
     enfermedad:"Anormalidad de la globulina"
  },
  {
     id:"R772",
     enfermedad:"Anormalidad de la alfafetoproteina"
  },
  {
     id:"R778",
     enfermedad:"Otras anormalidades especificadas de las proteinas plasmaticas"
  },
  {
     id:"R779",
     enfermedad:"Anormalidades no especificadas de las proteinas plasmaticas"
  },
  {
     id:"R78",
     enfermedad:"Hallazgos de drogas y otras sustancias que normalmente no se encuentran en la sangre"
  },
  {
     id:"R780",
     enfermedad:"Hallazgo de alcohol en la sangre"
  },
  {
     id:"R781",
     enfermedad:"Hallazgo de drogas opiaceas en la sangre"
  },
  {
     id:"R782",
     enfermedad:"Hallazgo de cocaina en la sangre"
  },
  {
     id:"R783",
     enfermedad:"Hallazgo de alucinogenos en la sangre"
  },
  {
     id:"R784",
     enfermedad:"Hallazgo de otras drogas potencialmente adictivas en la sangre"
  },
  {
     id:"R785",
     enfermedad:"Hallazgo de drogas psicotropicas en la sangre"
  },
  {
     id:"R786",
     enfermedad:"Hallazgo de agentes esteroides en la sangre"
  },
  {
     id:"R787",
     enfermedad:"Hallazgo de niveles anormales de metales pesados en la sangre"
  },
  {
     id:"R788",
     enfermedad:"Hallazgo de otras sustancias especificas que normalmente no se encuentran en la sangre"
  },
  {
     id:"R789",
     enfermedad:"Hallazgo de sustancia no especifica que normalmente no se encuentran en la sangre"
  },
  {
     id:"R79",
     enfermedad:"Otros hallazgos anormales en la quimica sanguinea"
  },
  {
     id:"R790",
     enfermedad:"Nivel anormal de mineral en la sangre"
  },
  {
     id:"R798",
     enfermedad:"Otros hallazgos anormales especificados en la quimica sanguinea"
  },
  {
     id:"R799",
     enfermedad:"Hallazgo anormal en la quimica sanguinea, sin otra especificacion"
  },
  {
     id:"R80",
     enfermedad:"Proteinuria aislada"
  },
  {
     id:"R81",
     enfermedad:"Glucosuria"
  },
  {
     id:"R82",
     enfermedad:"Otros hallazgos anormales en la orina"
  },
  {
     id:"R820",
     enfermedad:"Quiluria"
  },
  {
     id:"R821",
     enfermedad:"Mioglobinuria"
  },
  {
     id:"R822",
     enfermedad:"Biliuria"
  },
  {
     id:"R823",
     enfermedad:"Hemoglobinuria"
  },
  {
     id:"R824",
     enfermedad:"Acetonuria"
  },
  {
     id:"R825",
     enfermedad:"Elevacion de los niveles de drogas, medicamentos y sustancias biologicas en la orina"
  },
  {
     id:"R826",
     enfermedad:"Niveles anormales en la orina de sustancias de origen principalmente no medicinal"
  },
  {
     id:"R827",
     enfermedad:"Hallazgos anormales en el examen microbiologico de la orina"
  },
  {
     id:"R828",
     enfermedad:"Hallazgos anormales en el examen citologico e histologico de la orina"
  },
  {
     id:"R829",
     enfermedad:"Otros hallazgos anormales en la orina y los no especificados"
  },
  {
     id:"R83",
     enfermedad:"Hallazgos anormales en el liquido cefalorraquideo"
  },
  {
     id:"R830",
     enfermedad:"Hallazgos anormales en el liquido cefalorraquideo: nivel anormal de enzimas"
  },
  {
     id:"R831",
     enfermedad:"Hallazgos anormales en el liquido cefalorraquideo: nivel anormal de hormonas"
  },
  {
     id:"R832",
     enfermedad:"Hallazgos anormales en el liquido cefalorraquideo: nivel anormal de otras drogas, medicamentos y sustancias biologicas"
  },
  {
     id:"R833",
     enfermedad:"Hallazgos anormales en el liquido cefalorraquideo: nivel anormal de sustancias de origen fundamentalmente no medicinal"
  },
  {
     id:"R834",
     enfermedad:"Hallazgos anormales en el liquido cefalorraquideo: hallazgos inmunologicos anormales"
  },
  {
     id:"R835",
     enfermedad:"Hallazgos anormales en el liquido cefalorraquideo: hallazgos microbiologicos anormales hallazgos positivos en el cultivo"
  },
  {
     id:"R836",
     enfermedad:"Hallazgos anormales en el liquido cefalorraquideo: hallazgos citologicos anormales, frotis anormal de papanicolaou"
  },
  {
     id:"R837",
     enfermedad:"Hallazgos anormales en el liquido cefalorraquideo: hallazgos histologicos anormales"
  },
  {
     id:"R838",
     enfermedad:"Hallazgos anormales en el liquido cefalorraquideo: otros hallazgos anormales, hallazgos cromosomicos anormales"
  },
  {
     id:"R839",
     enfermedad:"Hallazgos anormales en el liquido cefalorraquideo: hallazgos anormales, no especificados"
  },
  {
     id:"R84",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos respiratorios y toracicoss"
  },
  {
     id:"R840",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos respiratorios y toracicos: nivel anormal de enzimas"
  },
  {
     id:"R841",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos respiratorios y toracicos: nivel anormal de hormonas"
  },
  {
     id:"R842",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos respiratorios y toracicos: nivel anormal de otras drogas, medicamentos y sustancias biologicas"
  },
  {
     id:"R843",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos respiratorios y toracicos: nivel anormal de sustancias de origen fundamentalmente no medicinal"
  },
  {
     id:"R844",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos respiratorios y toracicos: hallazgos inmunologicos anormales"
  },
  {
     id:"R845",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos respiratorios y toracicos: hallazgos microbiologicos anormales, hallazgos positivos en el cultivo"
  },
  {
     id:"R846",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos respiratorios y toracicos: hallazgos citologicos anormales, frotis anormal de papanicolaou"
  },
  {
     id:"R847",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos respiratorios y toracicos: hallazgos histologicos anormales"
  },
  {
     id:"R848",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos respiratorios y toracicos: otros hallazgos anormales, hallazgos cromosomicos anormales"
  },
  {
     id:"R849",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos respiratorios y toracicos: hallazgos anormales, no especificados"
  },
  {
     id:"R85",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos digestivos y de la cavidad abdominal"
  },
  {
     id:"R850",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos digestivos y de la cavidad abdominal: nivel anormal de enzimas"
  },
  {
     id:"R851",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos digestivos y de la cavidad abdominal: nivel anormal de hormonas"
  },
  {
     id:"R852",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos digestivos y de la cavidad abdominal: nivel anormal de otras drogas, medicamentos y sustancias biologicas"
  },
  {
     id:"R853",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos digestivos y de la cavidad abdominal: nivel anormal de sustancias de origen fundamentalmente no medicinal"
  },
  {
     id:"R854",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos digestivos y de la cavidad abdominal: hallazgos inmunologicos anormales"
  },
  {
     id:"R855",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos digestivos y de la cavidad abdominal: hallazgos microbiologicos anormales, hallazgos positivos en el cultivo"
  },
  {
     id:"R856",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos digestivos y de la cavidad abdominal: hallazgos citologicos anormales, frotis anormal de papanicolaou"
  },
  {
     id:"R857",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos digestivos y de la cavidad abdominal: hallazgos histologicos anormales"
  },
  {
     id:"R858",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos digestivos y de la cavidad abdominal: otros hallazgos anormales, hallazgos cromosomicos anormales"
  },
  {
     id:"R859",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos digestivos y de la cavidad abdominal: hallazgos anormales, no especificados"
  },
  {
     id:"R86",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos genitales masculinos"
  },
  {
     id:"R860",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos genitales masculinos: nivel anormal de enzimas"
  },
  {
     id:"R861",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos genitales masculinos: nivel anormal de hormonas"
  },
  {
     id:"R862",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos genitales masculinos : nivel anormal de otras drogas, medicamentos y sustancias biologicas"
  },
  {
     id:"R863",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos genitales masculinos: nivel anormal de sustancias de origen fundamentalmente no medicinal"
  },
  {
     id:"R864",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos genitales masculinos: hallazgos inmunologicos anormales"
  },
  {
     id:"R865",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos genitales masculinos: hallazgos microbiologicos anormales, hallazgos positivos en el cultivo"
  },
  {
     id:"R866",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos genitales masculinos: hallazgos citologicos anormales, frotis anormal de papanicolaou"
  },
  {
     id:"R867",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos genitales masculinos: hallazgos histologicos anormales"
  },
  {
     id:"R868",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos genitales masculinos: otros hallazgos anormales, hallazgos cromosomicos anormales"
  },
  {
     id:"R869",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos genitales masculinos: hallazgos anormales, no especificados"
  },
  {
     id:"R87",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos genitales femeninos"
  },
  {
     id:"R870",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos genitales femeninos: nivel anormal de enzimas"
  },
  {
     id:"R871",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos genitales femeninos: nivel anormal de hormonas"
  },
  {
     id:"R872",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos genitales femeninos: nivel anormal de otras drogas, medicamentos y sustancias biologicas"
  },
  {
     id:"R873",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos genitales femeninos: nivel anormal de sustancias de origen fundamentalmente no medicinal"
  },
  {
     id:"R874",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos genitales femeninos: hallazgos inmunologicos anormales"
  },
  {
     id:"R875",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos genitales femeninos: hallazgos microbiologicos anormales, hallazgos positivos en el cultivo"
  },
  {
     id:"R876",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos genitales femeninos: hallazgos citologicos anormales"
  },
  {
     id:"R877",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos genitales femeninos: hallazgos histologicos anormales"
  },
  {
     id:"R878",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos genitales femeninos: otros hallazgos anormales, hallazgos cromosomicos anormales"
  },
  {
     id:"R879",
     enfermedad:"Hallazgos anormales en muestras tomadas de organos genitales femeninos: hallazgos anormales, no especificados"
  },
  {
     id:"R89",
     enfermedad:"Hallazgos anormales en muestras tomadas de otros organos, sistemas y tejidos"
  },
  {
     id:"R890",
     enfermedad:"Hallazgos anormales en muestras tomadas de otros organos, sistemas y tejidos: nivel anormal de enzimas"
  },
  {
     id:"R891",
     enfermedad:"Hallazgos anormales en muestras tomadas de otros organos, sistemas y tejidos: nivel anormal de hormonas"
  },
  {
     id:"R892",
     enfermedad:"Hallazgos anormales en muestras tomadas de otros organos, sistemas y tejidos: nivel anormal de otras drogas, medicamentos y sustancias biologicas"
  },
  {
     id:"R893",
     enfermedad:"Hallazgos anormales en muestras tomadas de otros organos, sistemas y tejidos: nivel anormal de sustancias de origen fundamentalmente no medicinal"
  },
  {
     id:"R894",
     enfermedad:"Hallazgos anormales en muestras tomadas de otros organos, sistemas y tejidos: hallazgos inmunologicos anormales"
  },
  {
     id:"R895",
     enfermedad:"Hallazgos anormales en muestras tomadas de otros organos, sistemas y tejidos: hallazgos microbiologicos anormales, hallazgos positivos en el cultivo"
  },
  {
     id:"R896",
     enfermedad:"Hallazgos anormales en muestras tomadas de otros organos, sistemas y tejidos: hallazgos citologicos anormales, frotis anormal de papanicolaou"
  },
  {
     id:"R897",
     enfermedad:"Hallazgos anormales en muestras tomadas de otros organos, sistemas y tejidos: hallazgos histologicos anormales"
  },
  {
     id:"R898",
     enfermedad:"Hallazgos anormales en muestras tomadas de otros organos, sistemas y tejidos: otros hallazgos anormales, hallazgos cromosomicos anormales"
  },
  {
     id:"R899",
     enfermedad:"Hallazgos anormales en muestras tomadas de otros organos, sistemas y tejidos: hallazgos anormales, no especificados"
  },
  {
     id:"R90",
     enfermedad:"Hallazgos anormales en diagnostico por imagen del sistema nervioso central"
  },
  {
     id:"R900",
     enfermedad:"Lesion que ocupa el espacio intracraneal"
  },
  {
     id:"R908",
     enfermedad:"Otros hallazgos anormales en diagnostico por imagen y del sistema nervioso central"
  },
  {
     id:"R91",
     enfermedad:"Hallazgos anormales en diagnostico por imagen del pulmon"
  },
  {
     id:"R92",
     enfermedad:"Hallazgos anormales en diagnostico por imagen de la mama"
  },
  {
     id:"R93",
     enfermedad:"Hallazgos anormales en diagnostico por imagen de otras estructuras del cuerpo"
  },
  {
     id:"R930",
     enfermedad:"Hallazgos anormales en diagnostico por imagen del craneo y de la cabeza, no clasificados en otra parte"
  },
  {
     id:"R931",
     enfermedad:"Hallazgos anormales en diagnostico por imagen del corazon y de la circulacion coronaria"
  },
  {
     id:"R932",
     enfermedad:"Hallazgos anormales en diagnostico por imagen del higado y de las vias biliares"
  },
  {
     id:"R933",
     enfermedad:"Hallazgos anormales en diagnostico por imagen de otras partes de las vias digestivas"
  },
  {
     id:"R934",
     enfermedad:"Hallazgos anormales en diagnostico por imagen de los organos urinarios"
  },
  {
     id:"R935",
     enfermedad:"Hallazgos anormales en diagnostico por imagen de otras regiones abdominales, incluido el retroperitoneo"
  },
  {
     id:"R936",
     enfermedad:"Hallazgos anormales en diagnostico por imagen de los miembros"
  },
  {
     id:"R937",
     enfermedad:"Hallazgos anormales en diagnostico por imagen de otras partes del sistema osteomuscular"
  },
  {
     id:"R938",
     enfermedad:"Hallazgos anormales en diagnostico por imagen de otras estructuras especificadas del cuerpo"
  },
  {
     id:"R94",
     enfermedad:"Resultados anormales de estudios funcionales"
  },
  {
     id:"R940",
     enfermedad:"Resultados anormales en estudios funcionales del sistema nervioso central"
  },
  {
     id:"R941",
     enfermedad:"Resultados anormales en estudios funcionales del sistema nervioso periferico y sentidos especiales"
  },
  {
     id:"R942",
     enfermedad:"Resultados anormales en estudios funcionales del pulmon"
  },
  {
     id:"R943",
     enfermedad:"Resultados anormales en estudios funcionales cardiovasculares"
  },
  {
     id:"R944",
     enfermedad:"Resultados anormales en estudios funcionales del riñon"
  },
  {
     id:"R945",
     enfermedad:"Resultados anormales en estudios funcionales del higado"
  },
  {
     id:"R946",
     enfermedad:"Resultados anormales en estudios funcionales de la tiroides"
  },
  {
     id:"R947",
     enfermedad:"Resultados anormales en otros estudios funcionales endocrinos"
  },
  {
     id:"R948",
     enfermedad:"Resultados anormales en los estudios funcionales de otros organos y sistemas"
  },
  {
     id:"R95",
     enfermedad:"Sindrome de la muerte subita infantil"
  },
  {
     id:"R96",
     enfermedad:"Otras muertes subitas de causa desconocida"
  },
  {
     id:"R960",
     enfermedad:"Muerte instantanea"
  },
  {
     id:"R961",
     enfermedad:"Muerte que ocurre en menos de 24 horas del inicio de los sintomas, no explicada de otra forma"
  },
  {
     id:"R98",
     enfermedad:"Muerte sin asistencia"
  },
  {
     id:"R99",
     enfermedad:"Otras causas mal definidas y las no especificadas de mortalidad"
  },
  {
     id:"S00",
     enfermedad:"Traumatismo superficial de la cabeza"
  },
  {
     id:"S000",
     enfermedad:"Traumatismo superficial del cuero cabelludo"
  },
  {
     id:"S001",
     enfermedad:"Contusion de los parpados y de la region periocular"
  },
  {
     id:"S002",
     enfermedad:"Otros traumatismos superficiales del parpado y de la region periocular"
  },
  {
     id:"S003",
     enfermedad:"Traumatismo superficial de la nariz"
  },
  {
     id:"S004",
     enfermedad:"Traumatismo superficial del oido"
  },
  {
     id:"S005",
     enfermedad:"Traumatismo superficial del labio y de la cavidad bucal"
  },
  {
     id:"S007",
     enfermedad:"Traumatismos superficiales multiples de la cabeza"
  },
  {
     id:"S008",
     enfermedad:"Traumatismo superficial de otras partes de la cabeza"
  },
  {
     id:"S009",
     enfermedad:"Traumatismo superficial de la cabeza, parte no especificada"
  },
  {
     id:"S01",
     enfermedad:"Herida de la cabeza"
  },
  {
     id:"S010",
     enfermedad:"Herida del cuero cabelludo"
  },
  {
     id:"S011",
     enfermedad:"Herida del parpado y de la region periocular"
  },
  {
     id:"S012",
     enfermedad:"Herida de la nariz"
  },
  {
     id:"S013",
     enfermedad:"Herida del oido"
  },
  {
     id:"S014",
     enfermedad:"Herida de la mejilla y de la region temporomandibular"
  },
  {
     id:"S015",
     enfermedad:"Herida del labio y de la cavidad bucal"
  },
  {
     id:"S017",
     enfermedad:"Heridas multiples de la cabeza"
  },
  {
     id:"S018",
     enfermedad:"Herida de otras partes de la cabeza"
  },
  {
     id:"S019",
     enfermedad:"Herida de la cabeza, parte no especificada"
  },
  {
     id:"S02",
     enfermedad:"Fractura de huesos del craneo y de la cara"
  },
  {
     id:"S020",
     enfermedad:"Fractura de la boveda del craneo"
  },
  {
     id:"S021",
     enfermedad:"Fractura de la base del craneo"
  },
  {
     id:"S022",
     enfermedad:"Fractura de los huesos de la nariz"
  },
  {
     id:"S023",
     enfermedad:"Fractura del suelo de la orbita"
  },
  {
     id:"S024",
     enfermedad:"Fractura del malar y del hueso maxilar superior"
  },
  {
     id:"S025",
     enfermedad:"Fractura de los dientes"
  },
  {
     id:"S026",
     enfermedad:"Fractura del maxilar inferior"
  },
  {
     id:"S027",
     enfermedad:"Fracturas multiples que comprometen el craneo y los huesos de la cara"
  },
  {
     id:"S028",
     enfermedad:"Fractura de otros huesos del craneo y de la cara"
  },
  {
     id:"S029",
     enfermedad:"Fractura del craneo y de los huesos de la cara, parte no especificada"
  },
  {
     id:"S03",
     enfermedad:"Luxacion, esguince y torcedura de articulaciones y de ligamentos de la cabeza"
  },
  {
     id:"S030",
     enfermedad:"Luxacion del maxilar"
  },
  {
     id:"S031",
     enfermedad:"Luxacion del cartilago septal de la nariz"
  },
  {
     id:"S032",
     enfermedad:"Luxacion de diente"
  },
  {
     id:"S033",
     enfermedad:"Luxacion de otras partes y de las no especificadas de la cabeza"
  },
  {
     id:"S034",
     enfermedad:"Esguinces y torceduras del maxilar"
  },
  {
     id:"S035",
     enfermedad:"Esguinces y torceduras de articulaciones y ligamentos de otras partes y las no especificadas de la cabeza"
  },
  {
     id:"S04",
     enfermedad:"Traumatismo de nervios craneales"
  },
  {
     id:"S040",
     enfermedad:"Traumatismo del nervio optico [ii par] y de las vias opticas"
  },
  {
     id:"S041",
     enfermedad:"Traumatismo del nervio motor ocular comun [iii par]"
  },
  {
     id:"S042",
     enfermedad:"Traumatismo del nervio patetico [iv par]"
  },
  {
     id:"S043",
     enfermedad:"Traumatismo del nervio trigemino [v par]"
  },
  {
     id:"S044",
     enfermedad:"Traumatismo del nervio motor ocular externo [vi par]"
  },
  {
     id:"S045",
     enfermedad:"Traumatismo del nervio facial [vii par]"
  },
  {
     id:"S046",
     enfermedad:"Traumatismo del nervio acustico [viii par]"
  },
  {
     id:"S047",
     enfermedad:"Traumatismo del nervio espinal [xi par]"
  },
  {
     id:"S048",
     enfermedad:"Traumatismo de otros nervios craneales"
  },
  {
     id:"S049",
     enfermedad:"Traumatismo del nervios craneales, no especificado"
  },
  {
     id:"S05",
     enfermedad:"Traumatismo del ojo y de la orbita"
  },
  {
     id:"S050",
     enfermedad:"Traumatismo de la conjuntiva y abrasion corneal sin mencion de cuerpo extraño"
  },
  {
     id:"S051",
     enfermedad:"Contusion del globo ocular y del tejido orbitario"
  },
  {
     id:"S052",
     enfermedad:"Laceracion y ruptura ocular con prolapso o perdida del tejido intraocular"
  },
  {
     id:"S053",
     enfermedad:"Laceracion ocular sin prolapso o perdida del tejido intraocular"
  },
  {
     id:"S054",
     enfermedad:"Herida penetrante de la orbita con o sin cuerpo extraño"
  },
  {
     id:"S055",
     enfermedad:"Herida penetrante del globo ocular con cuerpo extraño"
  },
  {
     id:"S056",
     enfermedad:"Herida penetrante del globo ocular sin cuerpo extraño"
  },
  {
     id:"S057",
     enfermedad:"Avulsion del ojo"
  },
  {
     id:"S058",
     enfermedad:"Otros traumatismos del ojo y de la orbita"
  },
  {
     id:"S059",
     enfermedad:"Traumatismo del ojo y de la orbita, no especificado"
  },
  {
     id:"S06",
     enfermedad:"Traumatismo intracraneal"
  },
  {
     id:"S060",
     enfermedad:"Concusion"
  },
  {
     id:"S061",
     enfermedad:"Edema cerebral traumatico"
  },
  {
     id:"S062",
     enfermedad:"Traumatismo cerebral difuso"
  },
  {
     id:"S063",
     enfermedad:"Traumatismo cerebral focal"
  },
  {
     id:"S064",
     enfermedad:"Hemorragia epidural"
  },
  {
     id:"S065",
     enfermedad:"Hemorragia subdural traumatica"
  },
  {
     id:"S066",
     enfermedad:"Hemorragia subaracnoidea traumatica"
  },
  {
     id:"S067",
     enfermedad:"Traumatismo intracraneal con coma prolongado"
  },
  {
     id:"S068",
     enfermedad:"Otros traumatismos intracraneales"
  },
  {
     id:"S069",
     enfermedad:"Traumatismo intracraneal, no especificado"
  },
  {
     id:"S07",
     enfermedad:"Traumatismo por aplastamiento de la cabeza"
  },
  {
     id:"S070",
     enfermedad:"Traumatismo por aplastamiento de la cara"
  },
  {
     id:"S071",
     enfermedad:"Traumatismo por aplastamiento del craneo"
  },
  {
     id:"S078",
     enfermedad:"Traumatismo por aplastamiento de otras partes de la cabeza"
  },
  {
     id:"S079",
     enfermedad:"Traumatismo por aplastamiento de la cabeza, parte no especificada"
  },
  {
     id:"S08",
     enfermedad:"Amputacion traumatica de parte de la cabeza"
  },
  {
     id:"S080",
     enfermedad:"Avulsion del cuero cabelludo"
  },
  {
     id:"S081",
     enfermedad:"Amputacion traumatica de la oreja"
  },
  {
     id:"S088",
     enfermedad:"Amputacion traumatica de otras partes de la cabeza"
  },
  {
     id:"S089",
     enfermedad:"Amputacion traumatica de parte no especificada de la cabeza"
  },
  {
     id:"S09",
     enfermedad:"Otros traumatismo y los no especificados de cabeza"
  },
  {
     id:"S090",
     enfermedad:"Traumatismo de los vasos sanguíneos de la cabeza no clasificados en otra parte"
  },
  {
     id:"S091",
     enfermedad:"Traumatismo de tendon y musculos de la cabeza"
  },
  {
     id:"S092",
     enfermedad:"Ruptura traumatica del timpano del oido"
  },
  {
     id:"S097",
     enfermedad:"Traumatismos multiples de la cabeza"
  },
  {
     id:"S098",
     enfermedad:"Otros traumatismos de la cabeza, especificados"
  },
  {
     id:"S099",
     enfermedad:"Traumatismo de la cabeza, no especificado"
  },
  {
     id:"S10",
     enfermedad:"Traumatismo superficial del cuello"
  },
  {
     id:"S100",
     enfermedad:"Contusion de la garganta"
  },
  {
     id:"S101",
     enfermedad:"Otros traumatismos superficiales y los no especificados de la garganta"
  },
  {
     id:"S107",
     enfermedad:"Traumatismo superficial multiple del cuello"
  },
  {
     id:"S108",
     enfermedad:"Traumatismo superficial de otras partes del cuello"
  },
  {
     id:"S109",
     enfermedad:"Traumatismo superficial del cuello, parte no especificada"
  },
  {
     id:"S11",
     enfermedad:"Herida del cuello"
  },
  {
     id:"S110",
     enfermedad:"Herida que compromete la laringe y la traquea"
  },
  {
     id:"S111",
     enfermedad:"Herida que compromete la glandula tiroides"
  },
  {
     id:"S112",
     enfermedad:"Herida que compromete la faringe y el esofago cervical"
  },
  {
     id:"S117",
     enfermedad:"Heridas multiples del cuello"
  },
  {
     id:"S118",
     enfermedad:"Heridas de otras partes del cuello"
  },
  {
     id:"S119",
     enfermedad:"Herida de cuello, parte no especificada"
  },
  {
     id:"S12",
     enfermedad:"Fractura del cuello"
  },
  {
     id:"S120",
     enfermedad:"Fractura de la primera vertebra cervical"
  },
  {
     id:"S121",
     enfermedad:"Fractura de la segunda vertebra cervical"
  },
  {
     id:"S122",
     enfermedad:"Fractura de otras vertebras cervicales especificadas"
  },
  {
     id:"S127",
     enfermedad:"Fracturas multiples de columna cervical"
  },
  {
     id:"S128",
     enfermedad:"Fractura de otras partes del cuello"
  },
  {
     id:"S129",
     enfermedad:"Fractura del cuello, parte no especificada"
  },
  {
     id:"S13",
     enfermedad:"Luxacion, esguince y torcedura de articulaciones y de ligamentos del cuello"
  },
  {
     id:"S130",
     enfermedad:"Ruptura traumatica de disco cervical intervertebral"
  },
  {
     id:"S131",
     enfermedad:"Luxacion de vertebra cervical"
  },
  {
     id:"S132",
     enfermedad:"Luxaciones de otras partes y de las no especificadas del cuello"
  },
  {
     id:"S133",
     enfermedad:"Luxaciones multiples del cuello"
  },
  {
     id:"S134",
     enfermedad:"Esguinces y torceduras de la columna cervical"
  },
  {
     id:"S135",
     enfermedad:"Esguinces y torceduras de la region tiroidea"
  },
  {
     id:"S136",
     enfermedad:"Esguinces y torceduras de articulaciones y ligamentos de otros sitios especificados y de los no especificados del cuello"
  },
  {
     id:"S14",
     enfermedad:"Traumatismo de la medula espinal y de nervios a nivel del cuello"
  },
  {
     id:"S140",
     enfermedad:"Concusion y edema de la medula espinal cervical"
  },
  {
     id:"S141",
     enfermedad:"Otros traumatismos de la medula espinal cervical y los no especificados"
  },
  {
     id:"S142",
     enfermedad:"Traumatismo de raiz nerviosa de columna cervical"
  },
  {
     id:"S143",
     enfermedad:"Traumatismo de plexo braquial"
  },
  {
     id:"S144",
     enfermedad:"Traumatismo de nervios perifericos del cuello"
  },
  {
     id:"S145",
     enfermedad:"Traumatismo de nervios cervicales simpaticos"
  },
  {
     id:"S146",
     enfermedad:"Traumatismo de otros nervios y de los no especificados del cuello"
  },
  {
     id:"S15",
     enfermedad:"Traumatismo de vasos sanguíneos a nivel del cuello"
  },
  {
     id:"S150",
     enfermedad:"Traumatismo de la arteria carotida"
  },
  {
     id:"S151",
     enfermedad:"Traumatismo de la arteria vertebral"
  },
  {
     id:"S152",
     enfermedad:"Traumatismo de la vena yugular externa"
  },
  {
     id:"S153",
     enfermedad:"Traumatismo de la vena yugular interna"
  },
  {
     id:"S157",
     enfermedad:"Traumatismo de multiples vasos sanguíneos a nivel del cuello"
  },
  {
     id:"S158",
     enfermedad:"Traumatismo de otros vasos sanguíneos a nivel del cuello"
  },
  {
     id:"S159",
     enfermedad:"Traumatismo de vasos sanguíneos no especificados a nivel del cuello"
  },
  {
     id:"S16",
     enfermedad:"Traumatismo de tendon y musculos a nivel del cuello"
  },
  {
     id:"S17",
     enfermedad:"Traumatismo por aplastamiento del cuello"
  },
  {
     id:"S170",
     enfermedad:"Traumatismo por aplastamiento de la laringe y de la traquea"
  },
  {
     id:"S178",
     enfermedad:"Traumatismo por aplastamiento de otras partes del cuello"
  },
  {
     id:"S179",
     enfermedad:"Traumatismo por aplastamiento del cuello, parte no especificada"
  },
  {
     id:"S18",
     enfermedad:"Amputacion traumatica a nivel del cuello"
  },
  {
     id:"S19",
     enfermedad:"Otros traumatismos y los no especificados del cuello"
  },
  {
     id:"S197",
     enfermedad:"Traumatismos multiples del cuello"
  },
  {
     id:"S198",
     enfermedad:"Otros traumatismos del cuello, especificados"
  },
  {
     id:"S199",
     enfermedad:"Traumatismo del cuello, no especificado"
  },
  {
     id:"S20",
     enfermedad:"Traumatismo superficial del torax"
  },
  {
     id:"S200",
     enfermedad:"Contusion de la mama"
  },
  {
     id:"S201",
     enfermedad:"Otros traumatismos superficiales y los no especificados de la mama"
  },
  {
     id:"S202",
     enfermedad:"Contusion del torax"
  },
  {
     id:"S203",
     enfermedad:"Otros traumatismos superficiales de la pared anterior del torax"
  },
  {
     id:"S204",
     enfermedad:"Otros traumatismos superficiales de la pared posterior del torax"
  },
  {
     id:"S207",
     enfermedad:"Traumatismos superficiales multiples del torax"
  },
  {
     id:"S208",
     enfermedad:"Traumatismo superficial de otras partes y de las no especificadas del torax"
  },
  {
     id:"S21",
     enfermedad:"Herida del torax"
  },
  {
     id:"S210",
     enfermedad:"Herida de la mama"
  },
  {
     id:"S211",
     enfermedad:"Herida de la pared anterior del torax"
  },
  {
     id:"S212",
     enfermedad:"Herida de la pared posterior del torax"
  },
  {
     id:"S217",
     enfermedad:"Herida multiple de la pared toracica"
  },
  {
     id:"S218",
     enfermedad:"Heridas de otras partes del torax"
  },
  {
     id:"S219",
     enfermedad:"Heridas del torax, parte no especificada"
  },
  {
     id:"S22",
     enfermedad:"Fractura de las costillas, del esternon y de la columna toracica [dorsal]"
  },
  {
     id:"S220",
     enfermedad:"Fractura de vertebra toracica"
  },
  {
     id:"S221",
     enfermedad:"Fracturas multiples de columna toracica"
  },
  {
     id:"S222",
     enfermedad:"Fractura de esternon"
  },
  {
     id:"S223",
     enfermedad:"Fractura de costilla"
  },
  {
     id:"S224",
     enfermedad:"Fracturas multiples de costilla"
  },
  {
     id:"S225",
     enfermedad:"Torax azotado"
  },
  {
     id:"S228",
     enfermedad:"Fractura de otras partes del torax oseo"
  },
  {
     id:"S229",
     enfermedad:"Fractura del torax oseo, parte no especificada"
  },
  {
     id:"S23",
     enfermedad:"Luxacion, esguince y torcedura de articulaciones y ligamentos del torax"
  },
  {
     id:"S230",
     enfermedad:"Ruptura traumatica de disco intervertebral toracico"
  },
  {
     id:"S231",
     enfermedad:"Luxacion de vertebra toracica"
  },
  {
     id:"S232",
     enfermedad:"Luxacion de otras partes y de las no especificadas del torax"
  },
  {
     id:"S233",
     enfermedad:"Esguinces y torceduras de columna toracica"
  },
  {
     id:"S234",
     enfermedad:"Esguinces y torceduras de costillas y esternon"
  },
  {
     id:"S235",
     enfermedad:"Esguinces y torceduras de otras partes y de las no especificadas del torax"
  },
  {
     id:"S24",
     enfermedad:"Traumatismo de nervios y de la medula espinal a nivel de torax"
  },
  {
     id:"S240",
     enfermedad:"Concusion y edema de la medula espinal toracica"
  },
  {
     id:"S241",
     enfermedad:"Otros traumatismos y los no especificados de la medula espinal toracica"
  },
  {
     id:"S242",
     enfermedad:"Traumatismo de raices nerviosas de la columna toracica"
  },
  {
     id:"S243",
     enfermedad:"Traumatismo de nervios perifericos del torax"
  },
  {
     id:"S244",
     enfermedad:"Traumatismo de nervios simpaticos toracicos"
  },
  {
     id:"S245",
     enfermedad:"Traumatismo de otros nervios del torax"
  },
  {
     id:"S246",
     enfermedad:"Traumatismo de nervio no especificado del torax"
  },
  {
     id:"S25",
     enfermedad:"Traumatismo de los vasos sanguíneos del torax"
  },
  {
     id:"S250",
     enfermedad:"Traumatismo de la aorta toracica"
  },
  {
     id:"S251",
     enfermedad:"Traumatismo de la arteria innominada o subclavia"
  },
  {
     id:"S252",
     enfermedad:"Traumatismo de la vena cava superior"
  },
  {
     id:"S253",
     enfermedad:"Traumatismo de la vena innominada o subclavia"
  },
  {
     id:"S254",
     enfermedad:"Traumatismo de vasos sanguíneos pulmonares"
  },
  {
     id:"S255",
     enfermedad:"Traumatismo de vasos sanguíneos intercostales"
  },
  {
     id:"S257",
     enfermedad:"Traumatismo de multiples vasos sanguíneos del torax"
  },
  {
     id:"S258",
     enfermedad:"Traumatismo de otros vasos sanguíneos del torax"
  },
  {
     id:"S259",
     enfermedad:"Traumatismo de vasos sanguíneos no especificados del torax"
  },
  {
     id:"S26",
     enfermedad:"Traumatismo del corazon"
  },
  {
     id:"S260",
     enfermedad:"Traumatismo del corazon con hemopericardio"
  },
  {
     id:"S268",
     enfermedad:"Otros traumatismos del corazon"
  },
  {
     id:"S269",
     enfermedad:"Traumatismos del corazon, no especificado"
  },
  {
     id:"S27",
     enfermedad:"Traumatismode otros organos intratoracicos y de los no especados"
  },
  {
     id:"S270",
     enfermedad:"Neumotorax traumatico"
  },
  {
     id:"S271",
     enfermedad:"Hemotorax traumatico"
  },
  {
     id:"S272",
     enfermedad:"Hemoneumotorax traumatico"
  },
  {
     id:"S273",
     enfermedad:"Otros traumatismos del pulmon"
  },
  {
     id:"S274",
     enfermedad:"Traumatismo de los bronquios"
  },
  {
     id:"S275",
     enfermedad:"Traumatismo de la traquea toracica"
  },
  {
     id:"S276",
     enfermedad:"Traumatismo de la pleura"
  },
  {
     id:"S277",
     enfermedad:"Traumatismo de multiples de organos intratoracicos"
  },
  {
     id:"S278",
     enfermedad:"Traumatismo de otros organos intratoracicos, especificados"
  },
  {
     id:"S279",
     enfermedad:"Traumatismo de organo intratoracico, no especificados"
  },
  {
     id:"S28",
     enfermedad:"Traumatismo por aplastamiento del torax y amputacion traumatica de parte del torax"
  },
  {
     id:"S280",
     enfermedad:"Aplastamiento del torax"
  },
  {
     id:"S281",
     enfermedad:"Amputacion traumatica de parte del torax"
  },
  {
     id:"S29",
     enfermedad:"Otros traumatismos y los no especificados del torax"
  },
  {
     id:"S290",
     enfermedad:"Traumatismo del tendon y musculos a nivel del torax"
  },
  {
     id:"S297",
     enfermedad:"Traumatismos multiples del torax"
  },
  {
     id:"S298",
     enfermedad:"Otros traumatismos del torax, especificados"
  },
  {
     id:"S299",
     enfermedad:"Traumatismo del torax, no especificado"
  },
  {
     id:"S30",
     enfermedad:"Traumatismo superficial del abdomen, de la region lumbosacra y de la pelvis"
  },
  {
     id:"S300",
     enfermedad:"Contusion de la region lumbosacra y de la pelvis"
  },
  {
     id:"S301",
     enfermedad:"Contusion de la pared abdominal"
  },
  {
     id:"S302",
     enfermedad:"Contusion de los organos genitales externos"
  },
  {
     id:"S307",
     enfermedad:"Traumatismos superficiales multiples del abdomen, de la region lumbosacra y de la pelvis"
  },
  {
     id:"S308",
     enfermedad:"Otros traumatismos superficiales del abdomen, de la region lumbosacra y de la pelvis"
  },
  {
     id:"S309",
     enfermedad:"Traumatismo superficial del abdomen, de la region lumbosacra y de la pelvis, parte no especificada"
  },
  {
     id:"S31",
     enfermedad:"Herida del abdomen, de la region lumbosacra y de la pelvis"
  },
  {
     id:"S310",
     enfermedad:"Herida de la region lumbosacra y de la pelvis"
  },
  {
     id:"S311",
     enfermedad:"Herida de la pared abdominal"
  },
  {
     id:"S312",
     enfermedad:"Herida del pene"
  },
  {
     id:"S313",
     enfermedad:"Herida del escroto y de los testiculos"
  },
  {
     id:"S314",
     enfermedad:"Herida de la vagina y de la vulva"
  },
  {
     id:"S315",
     enfermedad:"Herida de otros organos genitales externos y de los no especificados"
  },
  {
     id:"S317",
     enfermedad:"Heridas multiples del abdomen, de la region lumbosacra y de la pelvis"
  },
  {
     id:"S318",
     enfermedad:"Heridas de otras partes y de las no especificadas del abdomen"
  },
  {
     id:"S32",
     enfermedad:"Fractura de la columna lumbar y de la pelvis"
  },
  {
     id:"S320",
     enfermedad:"Fractura de vertebra lumbar"
  },
  {
     id:"S321",
     enfermedad:"Fractura del sacro"
  },
  {
     id:"S322",
     enfermedad:"Fractura del coccix"
  },
  {
     id:"S323",
     enfermedad:"Fractura del hueso iliaco"
  },
  {
     id:"S324",
     enfermedad:"Fractura del acetabulo"
  },
  {
     id:"S325",
     enfermedad:"Fractura del pubis"
  },
  {
     id:"S327",
     enfermedad:"Fracturas multiples de la columna lumbar y de la pelvis"
  },
  {
     id:"S328",
     enfermedad:"Fractura de otras partes y de las no especificadas de la columna lumbar y de la pelvis"
  },
  {
     id:"S33",
     enfermedad:"Luxacion, esguince y torcedura de articulaciones y ligamentos de la columna lumbar y de la pelvis"
  },
  {
     id:"S330",
     enfermedad:"Ruptura traumatica de disco intervertebral lumbar"
  },
  {
     id:"S331",
     enfermedad:"Luxacion de vertebra lumbar"
  },
  {
     id:"S332",
     enfermedad:"Luxacion de articulacion sacrococcigea y sacroiliaca"
  },
  {
     id:"S333",
     enfermedad:"Luxacion de otras partes y de las no especificadas de la columna lumbar y de la pelvis"
  },
  {
     id:"S334",
     enfermedad:"Ruptura traumatica de la sinfisis del pubis"
  },
  {
     id:"S335",
     enfermedad:"Esguinces y torceduras de la columna lumbar"
  },
  {
     id:"S336",
     enfermedad:"Esguinces y torceduras de la articulacion sacroiliaca"
  },
  {
     id:"S337",
     enfermedad:"Esguinces y torceduras de otras partes y de las no especificadas de la columna lumbar y de la pelvis"
  },
  {
     id:"S34",
     enfermedad:"Traumatismode los nervios y de la medula espinal lumbar, a nivel del abdomen, de la region lumbosacra y de la pelvis"
  },
  {
     id:"S340",
     enfermedad:"Concusion y edema de la medula espinal lumbar"
  },
  {
     id:"S341",
     enfermedad:"Otro traumatismo de la medula espinal lumbar"
  },
  {
     id:"S342",
     enfermedad:"Traumatismo de raiz nerviosa de la columna lumbar y sacra"
  },
  {
     id:"S343",
     enfermedad:"Traumatismo de la cola de caballo"
  },
  {
     id:"S344",
     enfermedad:"Traumatismo del plexo lumbosacro"
  },
  {
     id:"S345",
     enfermedad:"Traumatismo de nervio(s) simpatico(s) lumbar(es), sacro(s) y pelvico(s)"
  },
  {
     id:"S346",
     enfermedad:"Traumatismo de nervio(s) periferico(s) del abdomen, de la region lumbosacra y de la pelvis"
  },
  {
     id:"S348",
     enfermedad:"Traumatismo de otros nervios a nivel del abdomen, de la region lumbosacra y de la pelvis y de los no especificados"
  },
  {
     id:"S35",
     enfermedad:"Traumatismo de los vasos sanguíneos a nivel del abdomen, de la region lumbosacra y de la pelvis"
  },
  {
     id:"S350",
     enfermedad:"Traumatismo de la aorta abdominal"
  },
  {
     id:"S351",
     enfermedad:"Traumatismo de la vena cava inferior"
  },
  {
     id:"S352",
     enfermedad:"Traumatismo de las arterias celiacas y mesentericas"
  },
  {
     id:"S353",
     enfermedad:"Traumatismo de venas porta y esplenica"
  },
  {
     id:"S354",
     enfermedad:"Traumatismo de vasos sanguíneos renales"
  },
  {
     id:"S355",
     enfermedad:"Traumatismo de vasos sanguíneos iliacos"
  },
  {
     id:"S357",
     enfermedad:"Traumatismo de multiples vasos sanguíneos a nivel del abdomen, de la region lumbosacra y de la pelvis"
  },
  {
     id:"S358",
     enfermedad:"Traumatismo de otros vasos sanguíneos a nivel del abdomen, de la region lumbosacra y de la pelvis"
  },
  {
     id:"S359",
     enfermedad:"Traumatismo de vasos sanguíneos no especificados del abdomen, de la region lumbosacra y de la pelvis"
  },
  {
     id:"S36",
     enfermedad:"Traumatismo de organos intraabdominales"
  },
  {
     id:"S360",
     enfermedad:"Traumatismo del bazo"
  },
  {
     id:"S361",
     enfermedad:"Traumatismo del higado y de la vesicula biliar"
  },
  {
     id:"S362",
     enfermedad:"Traumatismo del pancreas"
  },
  {
     id:"S363",
     enfermedad:"Traumatismo del estomago"
  },
  {
     id:"S364",
     enfermedad:"Traumatismo del intestino delgado"
  },
  {
     id:"S365",
     enfermedad:"Traumatismo del colon"
  },
  {
     id:"S366",
     enfermedad:"Traumatismo del recto"
  },
  {
     id:"S367",
     enfermedad:"Traumatismo de multiples organos intraabdominales"
  },
  {
     id:"S368",
     enfermedad:"Traumatismo de otros organos intraabdominales"
  },
  {
     id:"S369",
     enfermedad:"Traumatismo de organo intraabdominal no especificado"
  },
  {
     id:"S37",
     enfermedad:"Traumatismo del aparato urinario y de los organos pelvicos"
  },
  {
     id:"S370",
     enfermedad:"Traumatismo del riñon"
  },
  {
     id:"S371",
     enfermedad:"Traumatismo del ureter"
  },
  {
     id:"S372",
     enfermedad:"Traumatismo de la vejiga"
  },
  {
     id:"S373",
     enfermedad:"Traumatismo de la uretra"
  },
  {
     id:"S374",
     enfermedad:"Traumatismo del ovario"
  },
  {
     id:"S375",
     enfermedad:"Traumatismo de la trompa de falopio"
  },
  {
     id:"S376",
     enfermedad:"Traumatismo del utero"
  },
  {
     id:"S377",
     enfermedad:"Traumatismo de multiples organos pelvicos"
  },
  {
     id:"S378",
     enfermedad:"Traumatismo de otros organos pelvicos"
  },
  {
     id:"S379",
     enfermedad:"Traumatismo de organo pelvico no especificado"
  },
  {
     id:"S38",
     enfermedad:"Traumatismo por aplastamiento y amputacion traumatica de parte del abdomen, de la region lumbosacra y de la pelvis"
  },
  {
     id:"S380",
     enfermedad:"Traumatismo por aplastamiento de organos genitales externos"
  },
  {
     id:"S381",
     enfermedad:"Traumatismo por aplastamiento de otras partes y de las no especificadas del abdomen, de la region lumbosacra y de la pelvis"
  },
  {
     id:"S382",
     enfermedad:"Amputacion traumatica de organos genitales externos"
  },
  {
     id:"S383",
     enfermedad:"Amputacion traumatica de otras partes y de las no especificadas del abdomen, region lumbosacra y pelvis"
  },
  {
     id:"S39",
     enfermedad:"Otros traumatismos y los no especificados del abdomen, de la region lumbosacra y de la pelvis"
  },
  {
     id:"S390",
     enfermedad:"Traumatismo del tendon y de musculos del abdomen, de la region lumbosacra y de la pelvis"
  },
  {
     id:"S396",
     enfermedad:"Traumatismo de organo(s) intraabdominal(es) con organo(s) pelvico(s)"
  },
  {
     id:"S397",
     enfermedad:"Otros traumatismos multiples del abdomen, de la region lumbosacra y de la pelvis"
  },
  {
     id:"S398",
     enfermedad:"Otros traumatismos especificados del abdomen, de la region lumbosacra y de la pelvis"
  },
  {
     id:"S399",
     enfermedad:"Traumatismo no especificado del abdomen, de la region lumbosacra y de la pelvis"
  },
  {
     id:"S40",
     enfermedad:"Traumatismo superficial del hombro y del brazo"
  },
  {
     id:"S400",
     enfermedad:"Contusion del hombro y del brazo"
  },
  {
     id:"S407",
     enfermedad:"Traumatismos superficiales multiples del hombro y del brazo"
  },
  {
     id:"S408",
     enfermedad:"Otros traumatismos superficiales del hombro y del brazo"
  },
  {
     id:"S409",
     enfermedad:"Traumatismo superficial no especificado del hombro y del brazo"
  },
  {
     id:"S41",
     enfermedad:"Herida del hombro y del brazo"
  },
  {
     id:"S410",
     enfermedad:"Herida del hombro"
  },
  {
     id:"S411",
     enfermedad:"Herida del brazo"
  },
  {
     id:"S417",
     enfermedad:"Heridas multiples del hombro y del brazo"
  },
  {
     id:"S418",
     enfermedad:"Heridas de otras partes y de las no especificadas del hombro y del brazo"
  },
  {
     id:"S42",
     enfermedad:"Fractura del hombro y del brazo"
  },
  {
     id:"S420",
     enfermedad:"Fractura de la clavicula"
  },
  {
     id:"S421",
     enfermedad:"Fractura del omoplato"
  },
  {
     id:"S422",
     enfermedad:"Fractura de la epifisis superior del humero"
  },
  {
     id:"S423",
     enfermedad:"Fractura de la diafisis del humero"
  },
  {
     id:"S424",
     enfermedad:"Fractura de la epifisis inferior del humero"
  },
  {
     id:"S427",
     enfermedad:"Fracturas multiples de la clavicula, del omoplato y del humero"
  },
  {
     id:"S428",
     enfermedad:"Fractura de otras partes del hombro y del brazo"
  },
  {
     id:"S429",
     enfermedad:"Fractura del hombro y del brazo, parte no especificada"
  },
  {
     id:"S43",
     enfermedad:"Luxacion, esguince y torcedura de articulaciones y ligamentos de la cintura escapular"
  },
  {
     id:"S430",
     enfermedad:"Luxacion de la articulacion del hombro"
  },
  {
     id:"S431",
     enfermedad:"Luxacion de la articulacion acromioclavicular"
  },
  {
     id:"S432",
     enfermedad:"Luxacion de la articulacion esternoclavicular"
  },
  {
     id:"S433",
     enfermedad:"Luxacion de otras partes de la cintura escapular y de las no especificadas"
  },
  {
     id:"S434",
     enfermedad:"Esguinces y torceduras de la articulacion del hombro"
  },
  {
     id:"S435",
     enfermedad:"Esguinces y torceduras de la articulacion acromioclavicular"
  },
  {
     id:"S436",
     enfermedad:"Esguinces y torceduras de la articulacion esternoclavicular"
  },
  {
     id:"S437",
     enfermedad:"Esguinces y torceduras de otras partes y de las no especificadas de la cintura escapular"
  },
  {
     id:"S44",
     enfermedad:"Traumatismo de nervios a nivel del hombro y del brazo"
  },
  {
     id:"S440",
     enfermedad:"Traumatismo del nervio cubital a nivel del brazo"
  },
  {
     id:"S441",
     enfermedad:"Traumatismo del nervio mediano a nivel del brazo"
  },
  {
     id:"S442",
     enfermedad:"Traumatismo del nervio radial a nivel del brazo"
  },
  {
     id:"S443",
     enfermedad:"Traumatismo del nervio axilar"
  },
  {
     id:"S444",
     enfermedad:"Traumatismo del nervio musculocutaneo"
  },
  {
     id:"S445",
     enfermedad:"Traumatismo del nervio sensitivo cutaneo a nivel del hombro y del brazo"
  },
  {
     id:"S447",
     enfermedad:"Traumatismo del multiples nervios a nivel del hombro y del brazo"
  },
  {
     id:"S448",
     enfermedad:"Traumatismo de otros nervios a nivel del hombro y del brazo"
  },
  {
     id:"S449",
     enfermedad:"Traumatismo de nervio no especificado a nivel del hombro y del brazo"
  },
  {
     id:"S45",
     enfermedad:"Traumatismo de vasos sanguíneos a nivel del hombro y del brazo"
  },
  {
     id:"S450",
     enfermedad:"Traumatismo de la arteria axilar"
  },
  {
     id:"S451",
     enfermedad:"Traumatismo de la arteria braquial"
  },
  {
     id:"S452",
     enfermedad:"Traumatismo de la vena axilar o braquial"
  },
  {
     id:"S453",
     enfermedad:"Traumatismo de venas superficial a nivel del hombro y del brazo"
  },
  {
     id:"S457",
     enfermedad:"Traumatismo de multiples vasos sanguíneos a nivel del hombro y del brazo"
  },
  {
     id:"S458",
     enfermedad:"Traumatismo de otros vasos sanguíneos a nivel del hombro y del brazo"
  },
  {
     id:"S459",
     enfermedad:"Traumatismo de vaso sanguíneo no especificado a nivel del hombro y del brazo"
  },
  {
     id:"S46",
     enfermedad:"Traumatismo tendon y musculo a nivel del hombro y del brazo"
  },
  {
     id:"S460",
     enfermedad:"Traumatismo de tendon del manguito rotatorio del hombro"
  },
  {
     id:"S461",
     enfermedad:"Traumatismo de tendon y musculo de la cabeza larga del biceps"
  },
  {
     id:"S462",
     enfermedad:"Traumatismo de tendon y musculo de otras partes del biceps"
  },
  {
     id:"S463",
     enfermedad:"Traumatismo de tendon y musculo del triceps"
  },
  {
     id:"S467",
     enfermedad:"Traumatismo de multiples tendones y musculos a nivel del hombro y del brazo"
  },
  {
     id:"S468",
     enfermedad:"Traumatismo de otros tendones y musculos a nivel del hombro y del brazo"
  },
  {
     id:"S469",
     enfermedad:"Traumatismo de tendon y musculo no especificado nivel del hombro y del brazo"
  },
  {
     id:"S47",
     enfermedad:"Traumatismo por aplastamiento del hombro y del brazo"
  },
  {
     id:"S48",
     enfermedad:"Amputacion traumatica del hombro y del brazo"
  },
  {
     id:"S480",
     enfermedad:"Amputacion traumatica en la articulacion del hombro y del brazo"
  },
  {
     id:"S481",
     enfermedad:"Amputacion traumatica a nivel entre el hombro y el codo"
  },
  {
     id:"S489",
     enfermedad:"Amputacion traumatica del hombro y del brazo, nivel no especificado"
  },
  {
     id:"S49",
     enfermedad:"Otros traumatismos y los no especificados del hombro y del brazo"
  },
  {
     id:"S497",
     enfermedad:"Traumatismos multiples del hombro y del brazo"
  },
  {
     id:"S498",
     enfermedad:"Otros traumatismos especificados del hombro y del brazo"
  },
  {
     id:"S499",
     enfermedad:"Traumatismos no especificados del hombro y del brazo"
  },
  {
     id:"S50",
     enfermedad:"Traumatismo superficial del antebrazo y del codo"
  },
  {
     id:"S500",
     enfermedad:"Contusion del codo"
  },
  {
     id:"S501",
     enfermedad:"Contusion de otras partes del antebrazo y de las no especificadas"
  },
  {
     id:"S507",
     enfermedad:"Traumatismos superficiales multiples del antebrazo"
  },
  {
     id:"S508",
     enfermedad:"Otros traumatismos superficiales del antebrazo"
  },
  {
     id:"S509",
     enfermedad:"Traumatismo superficial del antebrazo, no especificado"
  },
  {
     id:"S51",
     enfermedad:"Herida del antebrazo y del codo"
  },
  {
     id:"S510",
     enfermedad:"Herida del codo"
  },
  {
     id:"S517",
     enfermedad:"Heridas multiples del antebrazo"
  },
  {
     id:"S518",
     enfermedad:"Heridas de otras partes del antebrazo"
  },
  {
     id:"S519",
     enfermedad:"Heridas del antebrazo, parte no especificada"
  },
  {
     id:"S52",
     enfermedad:"Fractura del antebrazo"
  },
  {
     id:"S520",
     enfermedad:"Fractura de la epifisis superior del cubito"
  },
  {
     id:"S521",
     enfermedad:"Fractura de la epifisis superior del radio"
  },
  {
     id:"S522",
     enfermedad:"Fractura de la diafisis del cubito"
  },
  {
     id:"S523",
     enfermedad:"Fractura de la diafisis del radio"
  },
  {
     id:"S524",
     enfermedad:"Fractura de la diafisis del cubito y del radio"
  },
  {
     id:"S525",
     enfermedad:"Fractura de la epifisis inferior del radio"
  },
  {
     id:"S526",
     enfermedad:"Fractura de la epifisis inferior del cubito y del radio"
  },
  {
     id:"S527",
     enfermedad:"Fracturas multiples del antebrazo"
  },
  {
     id:"S528",
     enfermedad:"Fractura de otras partes del antebrazo"
  },
  {
     id:"S529",
     enfermedad:"Fractura del antebrazo, parte no especificada"
  },
  {
     id:"S53",
     enfermedad:"Luxacion, esguince y torcedura de articulaciones y ligamentos del codo"
  },
  {
     id:"S530",
     enfermedad:"Luxacion de la cabeza del radio"
  },
  {
     id:"S531",
     enfermedad:"Luxacion del codo, no especificada"
  },
  {
     id:"S532",
     enfermedad:"Ruptura traumatica del ligamento lateral del radio"
  },
  {
     id:"S533",
     enfermedad:"Ruptura traumatica del ligamento lateral del cubito"
  },
  {
     id:"S534",
     enfermedad:"Esguinces y torceduras del codo"
  },
  {
     id:"S54",
     enfermedad:"Traumatismo de nervios a nivel del antebrazo"
  },
  {
     id:"S540",
     enfermedad:"Traumatismo del nervio cubital a nivel del antebrazo"
  },
  {
     id:"S541",
     enfermedad:"Traumatismo del nervio mediano a nivel del antebrazo"
  },
  {
     id:"S542",
     enfermedad:"Traumatismo del nervio radial a nivel del antebrazo"
  },
  {
     id:"S543",
     enfermedad:"Traumatismo del nervio sensorial cutaneo a nivel del antebrazo"
  },
  {
     id:"S547",
     enfermedad:"Traumatismo del multiples nervios a nivel del antebrazo"
  },
  {
     id:"S548",
     enfermedad:"Traumatismo de otros nervios a nivel del antebrazo"
  },
  {
     id:"S549",
     enfermedad:"Traumatismo de nervio no especificado a nivel del antebrazo"
  },
  {
     id:"S55",
     enfermedad:"Traumatismo de los vasos sanguin a nivel del antebrazo"
  },
  {
     id:"S550",
     enfermedad:"Traumatismo de la arteria cubital a nivel del antebrazo"
  },
  {
     id:"S551",
     enfermedad:"Traumatismo de la arteria radial a nivel del antebrazo"
  },
  {
     id:"S552",
     enfermedad:"Traumatismo de vena a nivel del antebrazo"
  },
  {
     id:"S557",
     enfermedad:"Traumatismo de multiples vasos sanguíneos a nivel del antebrazo"
  },
  {
     id:"S558",
     enfermedad:"Traumatismo de otros vasos sanguíneos a nivel del antebrazo"
  },
  {
     id:"S559",
     enfermedad:"Traumatismo de vaso sanguíneo no especificado a nivel del antebrazo"
  },
  {
     id:"S56",
     enfermedad:"Traumatismo del tendon y musculo a nivel del antebrazo"
  },
  {
     id:"S560",
     enfermedad:"Traumatismo del tendon y musculo flexor del pulgar a nivel del antebrazo"
  },
  {
     id:"S561",
     enfermedad:"Traumatismo del tendon y musculo flexor de otro(s) dedo(s) a nivel del antebrazo"
  },
  {
     id:"S562",
     enfermedad:"Traumatismo del otro tendon y musculo flexor a nivel del antebrazo"
  },
  {
     id:"S563",
     enfermedad:"Traumatismo de tendones y musculos abductores y extensores del pulgar a nivel del antebrazo"
  },
  {
     id:"S564",
     enfermedad:"Traumatismo del tendon y musculo extensor de otro(s) dedo(s) a nivel del antebrazo"
  },
  {
     id:"S565",
     enfermedad:"Traumatismo de otro tendon y musculo extensor a nivel del antebrazo"
  },
  {
     id:"S567",
     enfermedad:"Traumatismo de multiples tendones y musculos a nivel del antebrazo"
  },
  {
     id:"S568",
     enfermedad:"Traumatismo de otros tendones y musculos y de los no especificados, a nivel del antebrazo"
  },
  {
     id:"S57",
     enfermedad:"Traumatismo por aplastamiento del antebrazo"
  },
  {
     id:"S570",
     enfermedad:"Traumatismo por aplastamiento del codo"
  },
  {
     id:"S578",
     enfermedad:"Traumatismo por aplastamiento de otras partes del antebrazo"
  },
  {
     id:"S579",
     enfermedad:"Traumatismo por aplastamiento del antebrazo, parte no especificada"
  },
  {
     id:"S58",
     enfermedad:"Amputacion traumatica del antebrazo"
  },
  {
     id:"S580",
     enfermedad:"Amputacion traumatica a nivel del codo"
  },
  {
     id:"S581",
     enfermedad:"Amputacion traumatica a nivel entre el codo y la muñeca"
  },
  {
     id:"S589",
     enfermedad:"Amputacion traumatica del antebrazo, nivel no especificado"
  },
  {
     id:"S59",
     enfermedad:"Otros traumatismos y los no especificados del antebrazo"
  },
  {
     id:"S597",
     enfermedad:"Traumatismos multiples del antebrazo"
  },
  {
     id:"S598",
     enfermedad:"Otros traumatismos especificados del antebrazo"
  },
  {
     id:"S599",
     enfermedad:"Traumatismos no especificados del antebrazo"
  },
  {
     id:"S60",
     enfermedad:"Traumatismo superficial de la muneca y de la mano"
  },
  {
     id:"S600",
     enfermedad:"Contusion de dedo(s) de la mano, sin daño de la(s) uña(s)"
  },
  {
     id:"S601",
     enfermedad:"Contusion de dedo(s) de la mano, con daño de la(s) uña(s)"
  },
  {
     id:"S602",
     enfermedad:"Contusion de otras partes de la muñeca y de la mano"
  },
  {
     id:"S607",
     enfermedad:"Traumatismos superficiales multiples de la muñeca y de la mano"
  },
  {
     id:"S608",
     enfermedad:"Otros traumatismos superficiales de la muñeca y de la mano"
  },
  {
     id:"S609",
     enfermedad:"Traumatismo superficial de la muñeca y de la mano, no especificado"
  },
  {
     id:"S61",
     enfermedad:"Herida de la muneca y de la mano"
  },
  {
     id:"S610",
     enfermedad:"Herida de dedo(s) de la mano, sin daño de la(s) uña(s)"
  },
  {
     id:"S611",
     enfermedad:"Herida de dedo(s) de la mano, con daño de la(s) uña(s)"
  },
  {
     id:"S617",
     enfermedad:"Heridas multiples de la muñeca y de la mano"
  },
  {
     id:"S618",
     enfermedad:"Heridas de otras partes de la muñeca y de la mano"
  },
  {
     id:"S619",
     enfermedad:"Herida de la muñeca y de la mano, parte no especificada"
  },
  {
     id:"S62",
     enfermedad:"Fractura a nivel de la muneca y de la mano"
  },
  {
     id:"S620",
     enfermedad:"Fractura del hueso escafoides [navicular] de la mano"
  },
  {
     id:"S621",
     enfermedad:"Fractura de otro(s) hueso(s) del carpo"
  },
  {
     id:"S622",
     enfermedad:"Fractura del primer metacarpiano"
  },
  {
     id:"S623",
     enfermedad:"Fractura de otros huesos metacarpianos"
  },
  {
     id:"S624",
     enfermedad:"Fracturas multiples de huesos metacarpianos"
  },
  {
     id:"S625",
     enfermedad:"Fractura del pulgar"
  },
  {
     id:"S626",
     enfermedad:"Fractura de otro dedo de la mano"
  },
  {
     id:"S627",
     enfermedad:"Fracturas multiples de los dedos de la mano"
  },
  {
     id:"S628",
     enfermedad:"Fractura de otras partes y de las no especificadas de la muñeca y de la mano"
  },
  {
     id:"S63",
     enfermedad:"Luxacion, esguince y torcedura de articulaciones y ligamentos a nivel de la muneca y de la mano"
  },
  {
     id:"S630",
     enfermedad:"Luxacion de la muñeca"
  },
  {
     id:"S631",
     enfermedad:"Luxacion de dedos de la mano"
  },
  {
     id:"S632",
     enfermedad:"Luxaciones multiples de dedos de la mano"
  },
  {
     id:"S633",
     enfermedad:"Ruptura traumatica de ligamentos de la muñeca y del carpo"
  },
  {
     id:"S634",
     enfermedad:"Ruptura traumatica de ligamentos del dedo de la mano en la(s) articulacion(es) metacarpofalangica e interfalangica"
  },
  {
     id:"S635",
     enfermedad:"Esguinces y torceduras de la muñeca"
  },
  {
     id:"S636",
     enfermedad:"Esguinces y torceduras de dedo(s) de la mano"
  },
  {
     id:"S637",
     enfermedad:"Esguinces y torceduras de otras partes y de las no especificadas de la muñeca y de la mano"
  },
  {
     id:"S64",
     enfermedad:"Traumatismo de nervios a nivel de la muneca y de la mano"
  },
  {
     id:"S640",
     enfermedad:"Traumatismo del nervio cubital a nivel de la muñeca y de la mano"
  },
  {
     id:"S641",
     enfermedad:"Traumatismo del nervio mediano a nivel de la muñeca y de la mano"
  },
  {
     id:"S642",
     enfermedad:"Traumatismo del nervio radial a nivel de la muñeca y de la mano"
  },
  {
     id:"S643",
     enfermedad:"Traumatismo del nervio digital del pulgar"
  },
  {
     id:"S644",
     enfermedad:"Traumatismo del nervio digital de otro dedo"
  },
  {
     id:"S647",
     enfermedad:"Traumatismo de multiples nervios a nivel de la muñeca y de la mano"
  },
  {
     id:"S648",
     enfermedad:"Traumatismo de otros nervios a nivel de la muñeca y de la mano"
  },
  {
     id:"S649",
     enfermedad:"Traumatismo de nervio no especificado a nivel de la muñeca y de la mano"
  },
  {
     id:"S65",
     enfermedad:"Traumatismo de los vasos sanguíneos a nivel de la muneca y de la mano"
  },
  {
     id:"S650",
     enfermedad:"Traumatismo de la arteria cubital a nivel de la muñeca y de la mano"
  },
  {
     id:"S651",
     enfermedad:"Traumatismo de la arteria radial a nivel de la muñeca y de la mano"
  },
  {
     id:"S652",
     enfermedad:"Traumatismo del arco palmar superficial"
  },
  {
     id:"S653",
     enfermedad:"Traumatismo del arco palmar profundo"
  },
  {
     id:"S654",
     enfermedad:"Traumatismo de vaso(s) sanguíneo(s) del pulgar"
  },
  {
     id:"S655",
     enfermedad:"Traumatismo de vaso(s) sanguíneo(s) de otro dedo"
  },
  {
     id:"S657",
     enfermedad:"Traumatismo de multiples vasos sanguíneos a nivel de la muñeca y de la mano"
  },
  {
     id:"S658",
     enfermedad:"Traumatismo de otros vasos sanguíneos a nivel de la muñeca y de la mano"
  },
  {
     id:"S659",
     enfermedad:"Traumatismo de vaso sanguíneo no especificado a nivel de la muñeca y de la mano"
  },
  {
     id:"S66",
     enfermedad:"Traumatismo de tendon y musculo a nivel de la muneca y de la mano"
  },
  {
     id:"S660",
     enfermedad:"Traumatismo del tendon y musculo flexor largo del pulgar a nivel de la muñeca y de la mano"
  },
  {
     id:"S661",
     enfermedad:"Traumatismo del tendon y musculo flexor de otro dedo a nivel de la muñeca y de la mano"
  },
  {
     id:"S662",
     enfermedad:"Traumatismo del tendon y musculo extensor del pulgar a nivel de la muñeca y de la mano"
  },
  {
     id:"S663",
     enfermedad:"Traumatismo del tendon y musculo extensor de otro(s) dedo(s) a nivel de la muñeca y de la mano"
  },
  {
     id:"S664",
     enfermedad:"Traumatismo del musculo y tendon intrinseco del pulgar a nivel de la muñeca y de la mano"
  },
  {
     id:"S665",
     enfermedad:"Traumatismo del musculo y tendon intrinseco de otro(s) dedo(s) a nivel de la muñeca y de la mano"
  },
  {
     id:"S666",
     enfermedad:"Traumatismo de multiples tendones y musculos flexores a nivel de la muñeca y de la mano"
  },
  {
     id:"S667",
     enfermedad:"Traumatismo de multiples tendones y musculos extensores a nivel de la muñeca y de la mano"
  },
  {
     id:"S668",
     enfermedad:"Traumatismo de otros tendones y musculos a nivel de la muñeca y de la mano"
  },
  {
     id:"S669",
     enfermedad:"Traumatismo de tendon y musculo no especificado, a nivel de la muñeca y de la mano"
  },
  {
     id:"S67",
     enfermedad:"Traumatismo por aplastamiento de la muneca y de la mano"
  },
  {
     id:"S670",
     enfermedad:"Traumatismo por aplastamiento del pulgar y otro(s) dedo(s)"
  },
  {
     id:"S678",
     enfermedad:"Traumatismo por aplastamiento de otras partes y de las no especificadas de la muñeca y de la mano"
  },
  {
     id:"S68",
     enfermedad:"Amputacion traumatica de la muneca y de la mano"
  },
  {
     id:"S680",
     enfermedad:"Amputacion traumatica del pulgar (completa) (parcial)"
  },
  {
     id:"S681",
     enfermedad:"Amputacion traumatica de otro dedo unico (completa) (parcial)"
  },
  {
     id:"S682",
     enfermedad:"Amputacion traumatica de dos o mas dedos solamente (completa) (parcial)"
  },
  {
     id:"S683",
     enfermedad:"Amputacion traumatica combinada (de parte) de dedo(s) con otras partes de la muñeca y de la mano"
  },
  {
     id:"S684",
     enfermedad:"Amputacion traumatica de la mano a nivel de la muñeca"
  },
  {
     id:"S688",
     enfermedad:"Amputacion traumatica de otras partes de la muñeca y de la mano"
  },
  {
     id:"S689",
     enfermedad:"Amputacion traumatica de la muñeca y de la mano, nivel no especificado"
  },
  {
     id:"S69",
     enfermedad:"Otros traumatismo y no especificados de la muneca y de la mano"
  },
  {
     id:"S697",
     enfermedad:"Traumatismos multiples de la muñeca y de la mano"
  },
  {
     id:"S698",
     enfermedad:"Otros traumatismos especificados de la muñeca y de la mano"
  },
  {
     id:"S699",
     enfermedad:"Traumatismo no especificado de la muñeca y de la mano"
  },
  {
     id:"S70",
     enfermedad:"Traumatismo superficial de la cadera y del muslo"
  },
  {
     id:"S700",
     enfermedad:"Contusion de la cadera"
  },
  {
     id:"S701",
     enfermedad:"Contusion del muslo"
  },
  {
     id:"S707",
     enfermedad:"Traumatismos superficiales multiples de la cadera y del muslo"
  },
  {
     id:"S708",
     enfermedad:"Otros traumatismos superficiales de la cadera y del muslo"
  },
  {
     id:"S709",
     enfermedad:"Traumatismo superficial de la cadera y del muslo, no especificado"
  },
  {
     id:"S71",
     enfermedad:"Herida de la cadera y del muslo"
  },
  {
     id:"S710",
     enfermedad:"Herida de la cadera"
  },
  {
     id:"S711",
     enfermedad:"Herida del muslo"
  },
  {
     id:"S717",
     enfermedad:"Heridas multiples de la cadera y del muslo"
  },
  {
     id:"S718",
     enfermedad:"Heridas de otras partes y de las no especificadas de la cintura pelvica"
  },
  {
     id:"S72",
     enfermedad:"Fractura del femur"
  },
  {
     id:"S720",
     enfermedad:"Fractura del cuello del femur"
  },
  {
     id:"S721",
     enfermedad:"Fractura pertrocanteriana"
  },
  {
     id:"S722",
     enfermedad:"Fractura subtrocanteriana"
  },
  {
     id:"S723",
     enfermedad:"Fractura de la diafisis del femur"
  },
  {
     id:"S724",
     enfermedad:"Fractura de la epifisis inferior del femur"
  },
  {
     id:"S727",
     enfermedad:"Fracturas multiples del femur"
  },
  {
     id:"S728",
     enfermedad:"Fractura de otras partes del femur"
  },
  {
     id:"S729",
     enfermedad:"Fractura del femur, parte no especificada"
  },
  {
     id:"S73",
     enfermedad:"Luxacion, esguince y torcedura de la articulacion y de los ligamentos de la cadera"
  },
  {
     id:"S730",
     enfermedad:"Luxacion de cadera"
  },
  {
     id:"S731",
     enfermedad:"Esguinces y torceduras de la cadera"
  },
  {
     id:"S74",
     enfermedad:"Traumatismo de nervios a nivel de la cadera y del muslo"
  },
  {
     id:"S740",
     enfermedad:"Traumatismo del nervio ciatico a nivel de la cadera y del muslo"
  },
  {
     id:"S741",
     enfermedad:"Traumatismo del nervio femorocutaneo a nivel de la cadera y del muslo"
  },
  {
     id:"S742",
     enfermedad:"Traumatismo del nervio sensorial cutaneo a nivel de la cadera y del muslo"
  },
  {
     id:"S747",
     enfermedad:"Traumatismo de nervios multiples a nivel de la cadera y del muslo"
  },
  {
     id:"S748",
     enfermedad:"Traumatismo de otros nervios a nivel de la cadera y del muslo"
  },
  {
     id:"S749",
     enfermedad:"Traumatismo de nervio no especificado a nivel de la cadera y del muslo"
  },
  {
     id:"S75",
     enfermedad:"Traumatismo de vasos sanguíneos a de la nivel cadera y del muslo"
  },
  {
     id:"S750",
     enfermedad:"Traumatismo de la arteria femoral"
  },
  {
     id:"S751",
     enfermedad:"Traumatismo de la vena femoral a nivel de la cadera y del muslo"
  },
  {
     id:"S752",
     enfermedad:"Traumatismo de la gran vena safena a nivel de la cadera y del muslo"
  },
  {
     id:"S757",
     enfermedad:"Traumatismo de multiples vasos sanguíneos a nivel de la cadera y del muslo"
  },
  {
     id:"S758",
     enfermedad:"Traumatismo de otros vasos sanguíneos a nivel de la cadera y del muslo"
  },
  {
     id:"S759",
     enfermedad:"Traumatismo de vaso sanguíneo no especificado a nivel de la cadera y del muslo"
  },
  {
     id:"S76",
     enfermedad:"Traumatismo de tendon y musculo a nivel de la cadera y del muslo"
  },
  {
     id:"S760",
     enfermedad:"Traumatismo del tendon y musculo de la cadera"
  },
  {
     id:"S761",
     enfermedad:"Traumatismo del tendon y musculo cuadriceps"
  },
  {
     id:"S762",
     enfermedad:"Traumatismo del tendon y musculo aductor mayor del muslo"
  },
  {
     id:"S763",
     enfermedad:"Traumatismo del tendon y musculo del grupo muscular posterior a nivel del muslo"
  },
  {
     id:"S764",
     enfermedad:"Traumatismo de otros tendones y musculos y los no especificados a nivel del muslo"
  },
  {
     id:"S767",
     enfermedad:"Traumatismo de multiples tendones y musculos y los no especificados a nivel de la cadera y del muslo"
  },
  {
     id:"S77",
     enfermedad:"Traumatismo aplastamiento de la cadera y del muslo"
  },
  {
     id:"S770",
     enfermedad:"Traumatismo por aplastamiento de la cadera"
  },
  {
     id:"S771",
     enfermedad:"Traumatismo por aplastamiento del muslo"
  },
  {
     id:"S772",
     enfermedad:"Traumatismo por aplastamiento de la cadera con el muslo"
  },
  {
     id:"S78",
     enfermedad:"Amputacion traumatica de la cadera y del muslo"
  },
  {
     id:"S780",
     enfermedad:"Amputacion traumatica de la articulacion de la cadera"
  },
  {
     id:"S781",
     enfermedad:"Amputacion traumatica en algun nivel entre la cadera y la rodilla"
  },
  {
     id:"S789",
     enfermedad:"Amputacion traumatica de cadera y muslo, nivel no especificado"
  },
  {
     id:"S79",
     enfermedad:"Otros traumatismo y los no especificados de la cadera y del muslo"
  },
  {
     id:"S797",
     enfermedad:"Traumatismos multiples de la cadera y del muslo"
  },
  {
     id:"S798",
     enfermedad:"Otros traumatismos especificados de la cadera y del muslo"
  },
  {
     id:"S799",
     enfermedad:"Traumatismo no especificado de la cadera y del muslo"
  },
  {
     id:"S80",
     enfermedad:"Traumatismo superficial de la pierna"
  },
  {
     id:"S800",
     enfermedad:"Contusion de la rodilla"
  },
  {
     id:"S801",
     enfermedad:"Contusion de otras partes y las no especificadas de la pierna"
  },
  {
     id:"S807",
     enfermedad:"Traumatismos superficiales multiples de la pierna"
  },
  {
     id:"S808",
     enfermedad:"Otros traumatismos superficiales de la pierna"
  },
  {
     id:"S809",
     enfermedad:"Traumatismo superficial de la pierna, no especificado"
  },
  {
     id:"S81",
     enfermedad:"Herida de la pierna"
  },
  {
     id:"S810",
     enfermedad:"Herida de la rodilla"
  },
  {
     id:"S817",
     enfermedad:"Heridas multiples de la pierna"
  },
  {
     id:"S818",
     enfermedad:"Herida de otras partes de la pierna"
  },
  {
     id:"S819",
     enfermedad:"Herida de la pierna, parte no especificada"
  },
  {
     id:"S82",
     enfermedad:"Fractura pierna, inclusive el tobillo"
  },
  {
     id:"S820",
     enfermedad:"Fractura de la rotula"
  },
  {
     id:"S821",
     enfermedad:"Fractura de la epifisis superior de la tibia"
  },
  {
     id:"S822",
     enfermedad:"Fractura de la diafisis de la tibia"
  },
  {
     id:"S823",
     enfermedad:"Fractura de la epifisis inferior de la tibia"
  },
  {
     id:"S824",
     enfermedad:"Fractura del perone solamente"
  },
  {
     id:"S825",
     enfermedad:"Fractura del maleolo interno"
  },
  {
     id:"S826",
     enfermedad:"Fractura del maleolo externo"
  },
  {
     id:"S827",
     enfermedad:"Fracturas multiples de la pierna"
  },
  {
     id:"S828",
     enfermedad:"Fractura de otras partes de la pierna"
  },
  {
     id:"S829",
     enfermedad:"Fractura de la pierna, parte no especificada"
  },
  {
     id:"S83",
     enfermedad:"Luxacion, esguince y torcedura de articulaciones y ligamentos de la rodilla"
  },
  {
     id:"S830",
     enfermedad:"Luxacion de la rotula"
  },
  {
     id:"S831",
     enfermedad:"Luxacion de la rodilla"
  },
  {
     id:"S832",
     enfermedad:"Desgarro de meniscos, presente"
  },
  {
     id:"S833",
     enfermedad:"Desgarro del cartilago articular de la rodilla, presente"
  },
  {
     id:"S834",
     enfermedad:"Esguinces y torceduras que comprometen los ligamentos laterales (externo) (interno) de la rodilla"
  },
  {
     id:"S835",
     enfermedad:"Esguinces y torceduras que comprometen el ligamento cruzado (anterior) (posterior) de la rodilla"
  },
  {
     id:"S836",
     enfermedad:"Esguinces y torceduras de otras partes y las no especificadas de la rodilla"
  },
  {
     id:"S837",
     enfermedad:"Traumatismo de estructuras multiples de la rodilla"
  },
  {
     id:"S84",
     enfermedad:"Traumatismo de nervios a nivel de la pierna"
  },
  {
     id:"S840",
     enfermedad:"Traumatismo del nervio tibial a nivel de la pierna"
  },
  {
     id:"S841",
     enfermedad:"Traumatismo del nervio peroneo a nivel de la pierna"
  },
  {
     id:"S842",
     enfermedad:"Traumatismo del nervio sensorial cutaneo a nivel de la pierna"
  },
  {
     id:"S847",
     enfermedad:"Traumatismo de nervios multiples a nivel de la pierna"
  },
  {
     id:"S848",
     enfermedad:"Traumatismo de otros nervios a nivel de la pierna"
  },
  {
     id:"S849",
     enfermedad:"Traumatismo de nervio no especificado a nivel de la pierna"
  },
  {
     id:"S85",
     enfermedad:"Traumatismo de vasos sanguíneos a nivel de la pierna"
  },
  {
     id:"S850",
     enfermedad:"Traumatismo de la arteria poplitea"
  },
  {
     id:"S851",
     enfermedad:"Traumatismo de la arteria tibial (anterior) (posterior)"
  },
  {
     id:"S852",
     enfermedad:"Traumatismo de la arteria peronea"
  },
  {
     id:"S853",
     enfermedad:"Traumatismo de la gran vena safena a nivel de la pierna"
  },
  {
     id:"S854",
     enfermedad:"Traumatismo de la vena safena externa nivel de la pierna"
  },
  {
     id:"S855",
     enfermedad:"Traumatismo de la vena poplitea"
  },
  {
     id:"S857",
     enfermedad:"Traumatismo de vasos sanguíneos multiples a nivel de la pierna"
  },
  {
     id:"S858",
     enfermedad:"Traumatismo de otros vasos sanguíneos a nivel de la pierna"
  },
  {
     id:"S859",
     enfermedad:"Traumatismo de vaso sanguíneo no especificado a nivel de la pierna"
  },
  {
     id:"S86",
     enfermedad:"Traumatismo de tendon y musculo a nivel de la pierna"
  },
  {
     id:"S860",
     enfermedad:"Traumatismo del tendon de aquiles"
  },
  {
     id:"S861",
     enfermedad:"Traumatismo de otro tendon(es) y musculo(s) del grupo muscular posterior a nivel de la pierna"
  },
  {
     id:"S862",
     enfermedad:"Traumatismo del tendon(es) y musculo(s) del grupo muscular anterior a nivel de la pierna"
  },
  {
     id:"S863",
     enfermedad:"Traumatismo del tendon(es) y musculo(s) del grupo muscular peroneo a nivel de la pierna"
  },
  {
     id:"S867",
     enfermedad:"Traumatismo de multiples tendones y musculos a nivel de la pierna"
  },
  {
     id:"S868",
     enfermedad:"Traumatismo de otros tendones y musculos a nivel de la pierna"
  },
  {
     id:"S869",
     enfermedad:"Traumatismo de tendon y musculo no especificados a nivel de la pierna"
  },
  {
     id:"S87",
     enfermedad:"Traumatismo por aplastamiento de la pierna"
  },
  {
     id:"S870",
     enfermedad:"Traumatismo por aplastamiento de la rodilla"
  },
  {
     id:"S878",
     enfermedad:"Traumatismo por aplastamiento de otras partes y de las no especificadas de la pierna"
  },
  {
     id:"S88",
     enfermedad:"Amputacion traumatica de la pierna"
  },
  {
     id:"S880",
     enfermedad:"Amputacion traumatica a nivel de la rodilla"
  },
  {
     id:"S881",
     enfermedad:"Amputacion traumatica en algun nivel entre la rodilla y el tobillo"
  },
  {
     id:"S889",
     enfermedad:"Amputacion traumatica de la pierna, nivel no especificado"
  },
  {
     id:"S89",
     enfermedad:"Otros traumatismos y los no especificados de la pierna"
  },
  {
     id:"S897",
     enfermedad:"Traumatismos multiples de la pierna"
  },
  {
     id:"S898",
     enfermedad:"Otros traumatismos de la pierna, especificados"
  },
  {
     id:"S899",
     enfermedad:"Traumatismo de la pierna, no especificado"
  },
  {
     id:"S90",
     enfermedad:"Traumatismo superficial del tobillo y del pie"
  },
  {
     id:"S900",
     enfermedad:"Contusion del tobillo"
  },
  {
     id:"S901",
     enfermedad:"Contusion de dedo(s) del pie, sin daño de la(s) uña(s)"
  },
  {
     id:"S902",
     enfermedad:"Contusion de dedo(s) del pie, con daño de la(s) uña(s)"
  },
  {
     id:"S903",
     enfermedad:"Contusion de otras partes y de las no especificadas del pie"
  },
  {
     id:"S907",
     enfermedad:"Traumatismos superficiales multiples del pie y del tobillo"
  },
  {
     id:"S908",
     enfermedad:"Otros traumatismos superficiales del pie y del tobillo"
  },
  {
     id:"S909",
     enfermedad:"Traumatismo superficial del pie y del tobillo, no especificado"
  },
  {
     id:"S91",
     enfermedad:"Herida del tobillo y del pie"
  },
  {
     id:"S910",
     enfermedad:"Herida del tobillo"
  },
  {
     id:"S911",
     enfermedad:"Herida de dedo(s) del pie sin daño de la(s) uña(s)"
  },
  {
     id:"S912",
     enfermedad:"Herida de dedo(s) del pie con daño de la(s) uña(s)"
  },
  {
     id:"S913",
     enfermedad:"Heridas de otras partes del pie"
  },
  {
     id:"S917",
     enfermedad:"Heridas multiples del tobillo y del pie"
  },
  {
     id:"S92",
     enfermedad:"Fractura del pie, excepto del tobillo"
  },
  {
     id:"S920",
     enfermedad:"Fractura del calcaneo"
  },
  {
     id:"S921",
     enfermedad:"Fractura del astragalo"
  },
  {
     id:"S922",
     enfermedad:"Fractura de otro(s) hueso(s) del tarso"
  },
  {
     id:"S923",
     enfermedad:"Fractura de hueso del metatarso"
  },
  {
     id:"S924",
     enfermedad:"Fractura de los huesos del dedo gordo del pie"
  },
  {
     id:"S925",
     enfermedad:"Fractura de los huesos de otro(s) dedo(s) del pie"
  },
  {
     id:"S927",
     enfermedad:"Fracturas multiples del pie"
  },
  {
     id:"S929",
     enfermedad:"Fractura del pie, no especificada"
  },
  {
     id:"S93",
     enfermedad:"Luxacion, esguince y torcedura de articulaciones y ligamentos del tobillo y del pie"
  },
  {
     id:"S930",
     enfermedad:"Luxacion de la articulacion del tobillo"
  },
  {
     id:"S931",
     enfermedad:"Luxacion de dedo(s) del pie"
  },
  {
     id:"S932",
     enfermedad:"Ruptura de ligamentos a nivel del tobillo y del pie"
  },
  {
     id:"S933",
     enfermedad:"Luxacion de otros sitios y los no especificados del pie"
  },
  {
     id:"S934",
     enfermedad:"Esguinces y torceduras del tobillo"
  },
  {
     id:"S935",
     enfermedad:"Esguinces y torceduras de dedo(s) del pie"
  },
  {
     id:"S936",
     enfermedad:"Esguinces y torceduras de otros sitios y de los no especificados del pie"
  },
  {
     id:"S94",
     enfermedad:"Traumatismo nervioso a nivel del pie y del tobillo"
  },
  {
     id:"S940",
     enfermedad:"Traumatismo del nervio plantar externo"
  },
  {
     id:"S941",
     enfermedad:"Traumatismo del nervio plantar interno"
  },
  {
     id:"S942",
     enfermedad:"Traumatismo del nervio peroneal profundo a nivel del pie y del tobillo"
  },
  {
     id:"S943",
     enfermedad:"Traumatismo del nervio sensorial cutaneo a nivel del pie y del tobillo"
  },
  {
     id:"S947",
     enfermedad:"Traumatismo de multiples nervios a nivel del pie y del tobillo"
  },
  {
     id:"S948",
     enfermedad:"Traumatismo de otros nervios a nivel del pie y del tobillo"
  },
  {
     id:"S949",
     enfermedad:"Traumatismo de nervio no especificado a nivel del pie y del tobillo"
  },
  {
     id:"S95",
     enfermedad:"Traumatismo de vasos sanguíneos a nivel del tobillo y del pie"
  },
  {
     id:"S950",
     enfermedad:"Traumatismo de la arteria dorsal del pie"
  },
  {
     id:"S951",
     enfermedad:"Traumatismo de la arteria plantar del pie"
  },
  {
     id:"S952",
     enfermedad:"Traumatismo de la vena dorsal del pie"
  },
  {
     id:"S957",
     enfermedad:"Traumatismo de multiples vasos sanguíneos a nivel del pie y del tobillo"
  },
  {
     id:"S958",
     enfermedad:"Traumatismo de otros vasos sanguíneos a nivel del pie y del tobillo"
  },
  {
     id:"S959",
     enfermedad:"Traumatismo de vaso sanguíneo no especificado a nivel del pie y del tobillo"
  },
  {
     id:"S96",
     enfermedad:"Traumatismo de tendon y musculo a nivel del tobillo y del pie"
  },
  {
     id:"S960",
     enfermedad:"Traumatismo del tendon y musculo del flexor largo del dedo a nivel del pie y del tobillo"
  },
  {
     id:"S961",
     enfermedad:"Traumatismo del tendon y musculo del extensor largo del (de los) dedo(s) a nivel del pie y del tobillo"
  },
  {
     id:"S962",
     enfermedad:"Traumatismo de tendones y musculos intrinsecos a nivel del pie y del tobillo"
  },
  {
     id:"S967",
     enfermedad:"Traumatismo de multiples tendones y musculos a nivel del pie y del tobillo"
  },
  {
     id:"S968",
     enfermedad:"Traumatismo de otros tendones y musculos a nivel del pie y del tobillo"
  },
  {
     id:"S969",
     enfermedad:"Traumatismo de tendones y musculos no especificados a nivel del pie y del tobillo"
  },
  {
     id:"S97",
     enfermedad:"Traumatismo por aplastamiento del pie y del tobillo"
  },
  {
     id:"S970",
     enfermedad:"Traumatismo por aplastamiento del tobillo"
  },
  {
     id:"S971",
     enfermedad:"Traumatismo por aplastamiento de dedo(s) del pie"
  },
  {
     id:"S978",
     enfermedad:"Traumatismo por aplastamiento de otras partes del pie y del tobillo"
  },
  {
     id:"S98",
     enfermedad:"Amputacion traumatica del pie y del tobillo"
  },
  {
     id:"S980",
     enfermedad:"Amputacion traumatica del pie a nivel del tobillo"
  },
  {
     id:"S981",
     enfermedad:"Amputacion traumatica de un dedo del pie"
  },
  {
     id:"S982",
     enfermedad:"Amputacion traumatica de dos o mas dedos del pie"
  },
  {
     id:"S983",
     enfermedad:"Amputacion traumatica de otras partes del pie"
  },
  {
     id:"S984",
     enfermedad:"Amputacion del pie, nivel no especificado"
  },
  {
     id:"S99",
     enfermedad:"Otros traumatismo y los no especificados del tobillo y del pie"
  },
  {
     id:"S997",
     enfermedad:"Traumatismos multiples del pie y del tobillo"
  },
  {
     id:"S998",
     enfermedad:"Otros traumatismos del pie y del tobillo, especificados"
  },
  {
     id:"S999",
     enfermedad:"Traumatismo del pie y del tobillo, no especificado"
  },
  {
     id:"T00",
     enfermedad:"Traumatismos superficiales que afectan multiples regiones del cuerpo"
  },
  {
     id:"T000",
     enfermedad:"Traumatismos superficiales que afectan la cabeza con el cuello"
  },
  {
     id:"T001",
     enfermedad:"Traumatismos superficiales que afectan el torax con el abdomen, la region lumbosacra y la pelvis"
  },
  {
     id:"T002",
     enfermedad:"Traumatismos superficiales que afectan multiples regiones del(os) miembro(s) superior(es)"
  },
  {
     id:"T003",
     enfermedad:"Traumatismos superficiales que afectan multiples regiones del(os) miembro(s) inferior(es)"
  },
  {
     id:"T006",
     enfermedad:"Traumatismos superficiales que afectan multiples regiones del(os) miembro(s) superior(es) con miembro(s) inferior(es)"
  },
  {
     id:"T008",
     enfermedad:"Traumatismos superficiales que afectan otras combinaciones de regiones del cuerpo"
  },
  {
     id:"T009",
     enfermedad:"Traumatismos superficiales multiples, no especificados"
  },
  {
     id:"T01",
     enfermedad:"Heridas que afectan multiples regiones del cuerpo"
  },
  {
     id:"T010",
     enfermedad:"Heridas que afectan la cabeza con el cuello"
  },
  {
     id:"T011",
     enfermedad:"Heridas que afectan el torax con el abdomen, la region lumbosacra y la pelvis"
  },
  {
     id:"T012",
     enfermedad:"Heridas que afectan multiples regiones del(de los) miembro(s) superior(es)"
  },
  {
     id:"T013",
     enfermedad:"Heridas que afectan multiples regiones del(de los) miembro(s) inferior(es)"
  },
  {
     id:"T016",
     enfermedad:"Heridas que afectan multiples regiones del(de los) miembro(s) superior(es) con miembro(s) inferior(es)"
  },
  {
     id:"T018",
     enfermedad:"Heridas que afectan otras combinaciones de regiones del cuerpo"
  },
  {
     id:"T019",
     enfermedad:"Heridas multiples, no especificados"
  },
  {
     id:"T02",
     enfermedad:"Fracturas que afectan multiples regiones del cuerpo"
  },
  {
     id:"T020",
     enfermedad:"Fracturas que afectan la cabeza con el cuello"
  },
  {
     id:"T021",
     enfermedad:"Fracturas que afectan el torax con el abdomen, la region lumbosacra y la pelvis"
  },
  {
     id:"T022",
     enfermedad:"Fracturas que afectan multiples regiones de un miembro superior"
  },
  {
     id:"T023",
     enfermedad:"Fracturas que afectan multiples regiones de un miembro inferior"
  },
  {
     id:"T024",
     enfermedad:"Fracturas que afectan multiples regiones de ambos miembros superiores"
  },
  {
     id:"T025",
     enfermedad:"Fracturas que afectan multiples regiones de ambos miembros inferiores"
  },
  {
     id:"T026",
     enfermedad:"Fracturas que afectan multiples regiones de miembro(s) superior(es) con miembro(s) inferior(es)"
  },
  {
     id:"T027",
     enfermedad:"Fracturas que afectan el torax con la region lumbosacra y la pelvis con miembro(s)"
  },
  {
     id:"T028",
     enfermedad:"Fracturas que afectan otras combinaciones de regiones del cuerpo"
  },
  {
     id:"T029",
     enfermedad:"Fracturas multiples, no especificados"
  },
  {
     id:"T03",
     enfermedad:"Luxaciones, torceduras y esguinces que afectan multiples regiones del cuerpo"
  },
  {
     id:"T030",
     enfermedad:"Luxaciones, torceduras y esguinces que afectan la cabeza con el cuello"
  },
  {
     id:"T031",
     enfermedad:"Luxaciones, torceduras y esguinces que afectan el torax con la region lumbosacra y la pelvis"
  },
  {
     id:"T032",
     enfermedad:"Luxaciones, torceduras y esguinces que afectan multiples regiones del(de los) miembro(s) superior(es)"
  },
  {
     id:"T033",
     enfermedad:"Luxaciones, torceduras y esguinces que afectan multiples regiones del(de los) miembro(s) inferior(es)"
  },
  {
     id:"T034",
     enfermedad:"Luxaciones, torceduras y esguinces que afectan multiples regiones del(de los) miembro(s) superior(es) con miembro(s) inferior(es)"
  },
  {
     id:"T038",
     enfermedad:"Luxaciones, torceduras y esguinces que afectan otras combinaciones de regiones del cuerpo"
  },
  {
     id:"T039",
     enfermedad:"Luxaciones, torceduras y esguinces multiples, no especificados"
  },
  {
     id:"T04",
     enfermedad:"Traumatismos por aplastamiento que afectan multiples regiones del cuerpo"
  },
  {
     id:"T040",
     enfermedad:"Traumatismos por aplastamiento que afectan la cabeza con el cuello"
  },
  {
     id:"T041",
     enfermedad:"Traumatismos por aplastamiento que afectan el torax con el abdomen, la region lumbosacra y la pelvis"
  },
  {
     id:"T042",
     enfermedad:"Traumatismos por aplastamiento que afectan multiples regiones del(de los) miembro(s) superior(es)"
  },
  {
     id:"T043",
     enfermedad:"Traumatismos por aplastamiento que afectan multiples regiones del(de los) miembro(s) inferior(es)"
  },
  {
     id:"T044",
     enfermedad:"Traumatismos por aplastamiento que afectan multiples regiones del(de los) miembro(s) superior(es) con miembro(s) inferior(es)"
  },
  {
     id:"T047",
     enfermedad:"Traumatismos por aplastamiento del torax con el abdomen, de la region lumbosacra y de la pelvis con miembro(s)"
  },
  {
     id:"T048",
     enfermedad:"Traumatismos por aplastamiento que afectan otras combinaciones de regiones del cuerpo"
  },
  {
     id:"T049",
     enfermedad:"Traumatismos por aplastamiento multiples, no especificados"
  },
  {
     id:"T05",
     enfermedad:"Amputaciones traumaticas que afectan multiples regiones del cuerpo"
  },
  {
     id:"T050",
     enfermedad:"Amputacion traumatica de ambas manos"
  },
  {
     id:"T051",
     enfermedad:"Amputacion traumatica de una mano y el otro brazo [cualquier nivel, excepto mano]"
  },
  {
     id:"T052",
     enfermedad:"Amputacion traumatica de ambos brazos [cualquier nivel]"
  },
  {
     id:"T053",
     enfermedad:"Amputacion traumatica de ambos pies"
  },
  {
     id:"T054",
     enfermedad:"Amputacion traumatica de un pie y la otra pierna [cualquier nivel, excepto pie]"
  },
  {
     id:"T055",
     enfermedad:"Amputacion traumatica de ambas piernas [cualquier nivel]"
  },
  {
     id:"T056",
     enfermedad:"Amputacion traumatica de miembros superior(es) e inferior(es), cualquier combinacion [cualquier nivel]"
  },
  {
     id:"T058",
     enfermedad:"Amputacion traumatica que afecta otras combinaciones de regiones del cuerpo"
  },
  {
     id:"T059",
     enfermedad:"Amputacion traumatica multiples, no especificadas"
  },
  {
     id:"T06",
     enfermedad:"Otros traumatismos que afectan multiples regiones del cuerpo no clasificadas en otra parte"
  },
  {
     id:"T060",
     enfermedad:"Traumatismos del encefalo y de los nervios craneales con traumatismo de nervios y medula espinal a nivel del cuello"
  },
  {
     id:"T061",
     enfermedad:"Traumatismos de nervios y medula espinal que afectan otras multiples regiones del cuerpo"
  },
  {
     id:"T062",
     enfermedad:"Traumatismos de nervios que afectan multiples regiones del cuerpo"
  },
  {
     id:"T063",
     enfermedad:"Traumatismos de vasos sanguíneos que afectan multiples regiones del cuerpo"
  },
  {
     id:"T064",
     enfermedad:"Traumatismos de tendones y musculos que afectan multiples regiones del cuerpo"
  },
  {
     id:"T065",
     enfermedad:"Traumatismos de organos intratoracicos con organos intraabdominales y pelvicos"
  },
  {
     id:"T068",
     enfermedad:"Otros traumatismos especificados que afectan multiples regiones del cuerpo"
  },
  {
     id:"T07",
     enfermedad:"Traumatismos multiples no especificados"
  },
  {
     id:"T08",
     enfermedad:"Fractura de la columna vertebral, nivel no especificado"
  },
  {
     id:"T09",
     enfermedad:"Otros traumatismos de la columna vertebral y del tronco, nivel no especificado"
  },
  {
     id:"T090",
     enfermedad:"Traumatismos superficial del tronco, nivel no especificado"
  },
  {
     id:"T091",
     enfermedad:"Herida del tronco, nivel no especificado"
  },
  {
     id:"T092",
     enfermedad:"Luxacion, esguince o torcedura de articulacion y ligamentos del tronco, no especificado"
  },
  {
     id:"T093",
     enfermedad:"Traumatismo de la medula espinal, nivel no especificado"
  },
  {
     id:"T094",
     enfermedad:"Traumatismo de nervios, raiz de nervio espinal y plexos del tronco no especificados"
  },
  {
     id:"T095",
     enfermedad:"Traumatismo de tendones y musculos del tronco no especificados"
  },
  {
     id:"T096",
     enfermedad:"Amputacion traumatica del tronco, nivel no especificado"
  },
  {
     id:"T098",
     enfermedad:"Otros traumatismos especificados del tronco, nivel no especificado"
  },
  {
     id:"T099",
     enfermedad:"Traumatismo no especificado del tronco, nivel no especificado"
  },
  {
     id:"T10",
     enfermedad:"Fractura de miembro superior, nivel no especificado"
  },
  {
     id:"T11",
     enfermedad:"Otros traumatismos de miembro superior, nivel no especificado"
  },
  {
     id:"T110",
     enfermedad:"Traumatismos superficial de miembro superior, nivel no especificado"
  },
  {
     id:"T111",
     enfermedad:"Herida de miembro superior, nivel no especificado"
  },
  {
     id:"T112",
     enfermedad:"Luxacion, esguince o torcedura de articulacion o ligamento no especificado de miembro superior, nivel no especificado"
  },
  {
     id:"T113",
     enfermedad:"Traumatismo de nervio no especificado de miembro superior, nivel no especificado"
  },
  {
     id:"T114",
     enfermedad:"Traumatismo de vasos sanguíneos no especificados de miembro superior, nivel no especificado"
  },
  {
     id:"T115",
     enfermedad:"Traumatismo de tendon y musculo no especificados de miembro superior, nivel no especificado"
  },
  {
     id:"T116",
     enfermedad:"Amputacion traumatica de miembro superior, nivel no especificado"
  },
  {
     id:"T118",
     enfermedad:"Otros traumatismos especificados de miembro superior, nivel no especificado"
  },
  {
     id:"T119",
     enfermedad:"Traumatismo no especificado de miembro superior, nivel no especificado"
  },
  {
     id:"T12",
     enfermedad:"Fractura de miembro inferior, nivel no especificado"
  },
  {
     id:"T13",
     enfermedad:"Otros traumatismos de miembro inferior, nivel no especificado"
  },
  {
     id:"T130",
     enfermedad:"Traumatismo superficial de miembro inferior, nivel no especificado"
  },
  {
     id:"T131",
     enfermedad:"Herida de miembro inferior, nivel no especificado"
  },
  {
     id:"T132",
     enfermedad:"Luxacion, esguince o torcedura de articulacion y ligamentos no especificados de miembro inferior, nivel no especificado"
  },
  {
     id:"T133",
     enfermedad:"Traumatismo de nervios no especificados de miembro inferior, nivel no especificado"
  },
  {
     id:"T134",
     enfermedad:"Traumatismo de vasos sanguíneos no especificados de miembro inferior, nivel no especificado"
  },
  {
     id:"T135",
     enfermedad:"Traumatismo de tendones y musculos no especificados de miembro inferior, nivel no especificado"
  },
  {
     id:"T136",
     enfermedad:"Amputacion traumatica de miembro inferior, nivel no especificado"
  },
  {
     id:"T138",
     enfermedad:"Otros traumatismos especificados de miembro inferior, nivel no especificado"
  },
  {
     id:"T139",
     enfermedad:"Traumatismo no especificado de miembro inferior, nivel no especificado"
  },
  {
     id:"T14",
     enfermedad:"Traumatismo de regiones no especificadas del cuerpo"
  },
  {
     id:"T140",
     enfermedad:"Traumatismos superficial de region no especificada del cuerpo"
  },
  {
     id:"T141",
     enfermedad:"Herida de region no especificada del cuerpo"
  },
  {
     id:"T142",
     enfermedad:"Fractura de region no especificada del cuerpo"
  },
  {
     id:"T143",
     enfermedad:"Luxacion, esguince o torcedura de region no especificada del cuerpo"
  },
  {
     id:"T144",
     enfermedad:"Traumatismo de nervio(s) de region no especificada del cuerpo"
  },
  {
     id:"T145",
     enfermedad:"Traumatismo de vaso(s) sanguíneo(s) de region no especificada del cuerpo"
  },
  {
     id:"T146",
     enfermedad:"Traumatismo de tendones y musculos de region no especificada del cuerpo"
  },
  {
     id:"T147",
     enfermedad:"Traumatismo por aplastamiento y amputacion traumatica de regiones no especificadas del cuerpo"
  },
  {
     id:"T148",
     enfermedad:"Otros traumatismos de region no especificada del cuerpo"
  },
  {
     id:"T149",
     enfermedad:"Traumatismo, no especificado"
  },
  {
     id:"T15",
     enfermedad:"Cuerpo extrano en parte externa del ojo"
  },
  {
     id:"T150",
     enfermedad:"Cuerpo extraño en la cornea"
  },
  {
     id:"T151",
     enfermedad:"Cuerpo extraño en el saco conjuntival"
  },
  {
     id:"T158",
     enfermedad:"Cuerpo extraño en otras y en multiples partes de la parte externa del ojo"
  },
  {
     id:"T159",
     enfermedad:"Cuerpo extraño en parte externa del ojo, sitio no especificado"
  },
  {
     id:"T16",
     enfermedad:"Cuerpo extrano en el oido"
  },
  {
     id:"T17",
     enfermedad:"Cuerpo extrano en las vias respiratorias"
  },
  {
     id:"T170",
     enfermedad:"Cuerpo extraño en seno paranasal"
  },
  {
     id:"T171",
     enfermedad:"Cuerpo extraño en el orificio nasal"
  },
  {
     id:"T172",
     enfermedad:"Cuerpo extraño en la faringe"
  },
  {
     id:"T173",
     enfermedad:"Cuerpo extraño en la laringe"
  },
  {
     id:"T174",
     enfermedad:"Cuerpo extraño en la traquea"
  },
  {
     id:"T175",
     enfermedad:"Cuerpo extraño en bronquios"
  },
  {
     id:"T178",
     enfermedad:"Cuerpo extraño en otras y en multiples partes de las vias respiratorias"
  },
  {
     id:"T179",
     enfermedad:"Cuerpo extraño en las vias respiratorias, parte no especificada"
  },
  {
     id:"T18",
     enfermedad:"Cuerpo extrano en el tubo digestivo"
  },
  {
     id:"T180",
     enfermedad:"Cuerpo extraño en la boca"
  },
  {
     id:"T181",
     enfermedad:"Cuerpo extraño en el esofago"
  },
  {
     id:"T182",
     enfermedad:"Cuerpo extraño en el estomago"
  },
  {
     id:"T183",
     enfermedad:"Cuerpo extraño en el intestino delgado"
  },
  {
     id:"T184",
     enfermedad:"Cuerpo extraño en el colon"
  },
  {
     id:"T185",
     enfermedad:"Cuerpo extraño en el ano y en el recto"
  },
  {
     id:"T188",
     enfermedad:"Cuerpo extraño en otras y en multiples partes del tubo digestivo"
  },
  {
     id:"T189",
     enfermedad:"Cuerpo extraño en el tubo digestivo, parte no especificada"
  },
  {
     id:"T19",
     enfermedad:"Cuerpo extrano en las vias genitourinarias"
  },
  {
     id:"T190",
     enfermedad:"Cuerpo extraño en la uretra"
  },
  {
     id:"T191",
     enfermedad:"Cuerpo extraño en la vejiga"
  },
  {
     id:"T192",
     enfermedad:"Cuerpo extraño en la vulva y en la vagina"
  },
  {
     id:"T193",
     enfermedad:"Cuerpo extraño en el utero [cualquier parte]"
  },
  {
     id:"T198",
     enfermedad:"Cuerpo extraño en otras y en multiples partes de las vias genitourinarias"
  },
  {
     id:"T199",
     enfermedad:"Cuerpo extraño en las vias genitourinarias, parte no especificada"
  },
  {
     id:"T20",
     enfermedad:"Quemadura y corrosion de la cabeza y cuello"
  },
  {
     id:"T200",
     enfermedad:"Quemadura de la cabeza y del cuello, grado no especificado"
  },
  {
     id:"T201",
     enfermedad:"Quemadura de la cabeza y del cuello, de primer grado"
  },
  {
     id:"T202",
     enfermedad:"Quemadura de la cabeza y del cuello, de segundo grado"
  },
  {
     id:"T203",
     enfermedad:"Quemadura de la cabeza y del cuello, de tercer grado"
  },
  {
     id:"T204",
     enfermedad:"Corrosion de la cabeza y del cuello, grado no especificado"
  },
  {
     id:"T205",
     enfermedad:"Corrosion de la cabeza y del cuello, de primer grado"
  },
  {
     id:"T206",
     enfermedad:"Corrosion de la cabeza y del cuello, de segundo grado"
  },
  {
     id:"T207",
     enfermedad:"Corrosion de la cabeza y del cuello, de tercer grado"
  },
  {
     id:"T21",
     enfermedad:"Quemadura y corrosion del tronco"
  },
  {
     id:"T210",
     enfermedad:"Quemadura del tronco, grado no especificado"
  },
  {
     id:"T211",
     enfermedad:"Quemadura del tronco, de primer grado"
  },
  {
     id:"T212",
     enfermedad:"Quemadura del tronco, de segundo grado"
  },
  {
     id:"T213",
     enfermedad:"Quemadura del tronco, de tercer grado"
  },
  {
     id:"T214",
     enfermedad:"Corrosion del tronco, grado no especificado"
  },
  {
     id:"T215",
     enfermedad:"Corrosion del tronco, de primer grado"
  },
  {
     id:"T216",
     enfermedad:"Corrosion del tronco, de segundo grado"
  },
  {
     id:"T217",
     enfermedad:"Corrosion del tronco, de tercer grado"
  },
  {
     id:"T22",
     enfermedad:"Quemadura y corrosion del hombro y del miembro superior, excepto de la muñeca y de la mano"
  },
  {
     id:"T220",
     enfermedad:"Quemadura del hombro y miembro superior, grado no especificado, excepto de la muñeca y de la mano"
  },
  {
     id:"T221",
     enfermedad:"Quemadura del hombro y miembro superior, de primer grado, excepto de la muñeca y de la mano"
  },
  {
     id:"T222",
     enfermedad:"Quemadura del hombro y miembro superior, de segundo grado, excepto de la muñeca y de la mano"
  },
  {
     id:"T223",
     enfermedad:"Quemadura del hombro y miembro superior, de tercer grado, excepto de la muñeca y de la mano"
  },
  {
     id:"T224",
     enfermedad:"Corrosion del hombro y miembro superior, grado no especificado, excepto de la muñeca y de la mano"
  },
  {
     id:"T225",
     enfermedad:"Corrosion del hombro y miembro superior, de primer grado, excepto de la muñeca y de la mano"
  },
  {
     id:"T226",
     enfermedad:"Corrosion del hombro y miembro superior, de segundo grado, excepto de la muñeca y de la mano"
  },
  {
     id:"T227",
     enfermedad:"Corrosion del hombro y miembro superior, de tercer grado, excepto de la muñeca y de la mano"
  },
  {
     id:"T23",
     enfermedad:"Quemadura y corrosion de la muneca y de la mano"
  },
  {
     id:"T230",
     enfermedad:"Quemadura de la muñeca y de la mano, grado no especificado"
  },
  {
     id:"T231",
     enfermedad:"Quemadura de la muñeca y de la mano, de primer grado"
  },
  {
     id:"T232",
     enfermedad:"Quemadura de la muñeca y de la mano, de segundo grado"
  },
  {
     id:"T233",
     enfermedad:"Quemadura de la muñeca y de la mano, de tercer grado"
  },
  {
     id:"T234",
     enfermedad:"Corrosion de la muñeca y de la mano, grado no especificado"
  },
  {
     id:"T235",
     enfermedad:"Corrosion de la muñeca y de la mano, de primer grado"
  },
  {
     id:"T236",
     enfermedad:"Corrosion de la muñeca y de la mano, de segundo grado"
  },
  {
     id:"T237",
     enfermedad:"Corrosion de la muñeca y de la mano, de tercer grado"
  },
  {
     id:"T24",
     enfermedad:"Quemadura y corrosion de la cadera y miembro inferior, excepto tobillo y pie"
  },
  {
     id:"T240",
     enfermedad:"Quemadura de la cadera y del miembro inferior, grado no especificado, excepto tobillo y pie"
  },
  {
     id:"T241",
     enfermedad:"Quemadura de la cadera y del miembro inferior, de primer grado, excepto tobillo y pie"
  },
  {
     id:"T242",
     enfermedad:"Quemadura de la cadera y del miembro inferior, de segundo grado, excepto tobillo y pie"
  },
  {
     id:"T243",
     enfermedad:"Quemadura de la cadera y del miembro inferior, de tercer grado, excepto tobillo y pie"
  },
  {
     id:"T244",
     enfermedad:"Corrosion de la cadera y del miembro inferior, grado no especificado, excepto tobillo y pie"
  },
  {
     id:"T245",
     enfermedad:"Corrosion de la cadera y del miembro inferior, de primer grado, excepto tobillo y pie"
  },
  {
     id:"T246",
     enfermedad:"Corrosion de la cadera y del miembro inferior, de segundo grado, excepto tobillo y pie"
  },
  {
     id:"T247",
     enfermedad:"Corrosion de la cadera y del miembro inferior, de tercer grado, excepto tobillo y pie"
  },
  {
     id:"T25",
     enfermedad:"Quemadura y corrosion del tobillo y del pie"
  },
  {
     id:"T250",
     enfermedad:"Quemadura del tobillo y del pie, grado no especificado"
  },
  {
     id:"T251",
     enfermedad:"Quemadura del tobillo y del pie, de primer grado"
  },
  {
     id:"T252",
     enfermedad:"Quemadura del tobillo y del pie, de segundo grado"
  },
  {
     id:"T253",
     enfermedad:"Quemadura del tobillo y del pie, de tercer grado"
  },
  {
     id:"T254",
     enfermedad:"Corrosion del tobillo y del pie, grado no especificado"
  },
  {
     id:"T255",
     enfermedad:"Corrosion del tobillo y del pie, de primer grado"
  },
  {
     id:"T256",
     enfermedad:"Corrosion del tobillo y del pie, de segundo grado"
  },
  {
     id:"T257",
     enfermedad:"Corrosion del tobillo y del pie, de tercer grado"
  },
  {
     id:"T26",
     enfermedad:"Quemadura y corrosion limitada al ojo y sus anexos"
  },
  {
     id:"T260",
     enfermedad:"Quemadura del parpado y area periocular"
  },
  {
     id:"T261",
     enfermedad:"Quemadura de la cornea y saco conjuntival"
  },
  {
     id:"T262",
     enfermedad:"Quemadura con ruptura y destruccion resultantes del globo ocular"
  },
  {
     id:"T263",
     enfermedad:"Quemadura de otras partes del ojo y sus anexos"
  },
  {
     id:"T264",
     enfermedad:"Quemadura del ojo y anexos, parte no especificada"
  },
  {
     id:"T265",
     enfermedad:"Corrosion del parpado y area periocular"
  },
  {
     id:"T266",
     enfermedad:"Corrosion de la cornea y saco conjuntival"
  },
  {
     id:"T267",
     enfermedad:"Corrosion con ruptura y destruccion resultantes del globo ocular"
  },
  {
     id:"T268",
     enfermedad:"Corrosion de otras partes del ojo y sus anexos"
  },
  {
     id:"T269",
     enfermedad:"Corrosion del ojo y anexos, parte no especificada"
  },
  {
     id:"T27",
     enfermedad:"Quemadura y corrosion de las vias respiratorias"
  },
  {
     id:"T270",
     enfermedad:"Quemadura de la laringe y la traquea"
  },
  {
     id:"T271",
     enfermedad:"Quemadura que afecta la laringe y la traquea con el pulmon"
  },
  {
     id:"T272",
     enfermedad:"Quemadura de otras partes de las vias respiratorias"
  },
  {
     id:"T273",
     enfermedad:"Quemadura de las vias respiratorias, parte no especificada"
  },
  {
     id:"T274",
     enfermedad:"Corrosion de la laringe y de la traquea"
  },
  {
     id:"T275",
     enfermedad:"Corrosion que afecta la laringe y la traquea con el pulmon"
  },
  {
     id:"T276",
     enfermedad:"Corrosion de otras partes de las vias respiratorias"
  },
  {
     id:"T277",
     enfermedad:"Corrosion de las vias respiratorias, parte no especificada"
  },
  {
     id:"T28",
     enfermedad:"Quemadura y corrosion de otros organos internos"
  },
  {
     id:"T280",
     enfermedad:"Quemadura de la boca y de la faringe"
  },
  {
     id:"T281",
     enfermedad:"Quemadura del esofago"
  },
  {
     id:"T282",
     enfermedad:"Quemadura de otras partes del tubo digestivo"
  },
  {
     id:"T283",
     enfermedad:"Quemadura de organos genitourinarios internos"
  },
  {
     id:"T284",
     enfermedad:"Quemadura de otros organos internos y de los no especificados"
  },
  {
     id:"T285",
     enfermedad:"Corrosion de la boca y de la faringe"
  },
  {
     id:"T286",
     enfermedad:"Corrosion del esofago"
  },
  {
     id:"T287",
     enfermedad:"Corrosion de otras partes del tubo digestivo"
  },
  {
     id:"T288",
     enfermedad:"Corrosion de organos genitourinarios internos"
  },
  {
     id:"T289",
     enfermedad:"Corrosion de otros organos internos y de los no especificados"
  },
  {
     id:"T29",
     enfermedad:"Quemadura y corrosion de multiples regiones del cuerpo"
  },
  {
     id:"T290",
     enfermedad:"Quemaduras de multiples regiones, grado no especificado"
  },
  {
     id:"T291",
     enfermedad:"Quemaduras de multiples regiones, mencionadas como de no mas de primer grado"
  },
  {
     id:"T292",
     enfermedad:"Quemaduras de multiples regiones, mencionadas como de no mas de segundo grado"
  },
  {
     id:"T293",
     enfermedad:"Quemaduras de multiples regiones, con mencion al menos de una quemadura de tercer grado"
  },
  {
     id:"T294",
     enfermedad:"Corrosiones de multiples regiones, grado no especificado"
  },
  {
     id:"T295",
     enfermedad:"Corrosiones multiples, mencionadas como de no mas de primer grado"
  },
  {
     id:"T296",
     enfermedad:"Corrosiones multiples, mencionadas como de no mas de segundo grado"
  },
  {
     id:"T297",
     enfermedad:"Corrosiones multiples, con mencion al menos de una quemadura de tercer grado"
  },
  {
     id:"T30",
     enfermedad:"Quemadura y corrosion, region del cuerpo no especificada"
  },
  {
     id:"T300",
     enfermedad:"Quemadura de region del cuerpo y grado no especificados"
  },
  {
     id:"T301",
     enfermedad:"Quemadura de primer grado, region del cuerpo no especificada"
  },
  {
     id:"T302",
     enfermedad:"Quemadura de segundo grado, region del cuerpo no especificada"
  },
  {
     id:"T303",
     enfermedad:"Quemadura de tercer grado, region del cuerpo no especificada"
  },
  {
     id:"T304",
     enfermedad:"Corrosion de region del cuerpo y grado no especificados"
  },
  {
     id:"T305",
     enfermedad:"Corrosion de primer grado, region del cuerpo no especificada"
  },
  {
     id:"T306",
     enfermedad:"Corrosion de segundo grado, region del cuerpo no especificada"
  },
  {
     id:"T307",
     enfermedad:"Corrosion de tercer grado, region del cuerpo no especificada"
  },
  {
     id:"T31",
     enfermedad:"Quemaduras clasificadas segun la extension de la superficie del cuerpo afectada"
  },
  {
     id:"T310",
     enfermedad:"Quemaduras que afectan menos del 10% de la superficie del cuerpo"
  },
  {
     id:"T311",
     enfermedad:"Quemaduras que afectan del 10% al 19% de la superficie del cuerpo"
  },
  {
     id:"T312",
     enfermedad:"Quemaduras que afectan del 20% al 29% de la superficie del cuerpo"
  },
  {
     id:"T313",
     enfermedad:"Quemaduras que afectan del 30% al 39% de la superficie del cuerpo"
  },
  {
     id:"T314",
     enfermedad:"Quemaduras que afectan del 40% al 49% de la superficie del cuerpo"
  },
  {
     id:"T315",
     enfermedad:"Quemaduras que afectan del 50% al 59% de la superficie del cuerpo"
  },
  {
     id:"T316",
     enfermedad:"Quemaduras que afectan del 60% al 69% de la superficie del cuerpo"
  },
  {
     id:"T317",
     enfermedad:"Quemaduras que afectan del 70% al 79% de la superficie del cuerpo"
  },
  {
     id:"T318",
     enfermedad:"Quemaduras que afectan del 80% al 89% de la superficie del cuerpo"
  },
  {
     id:"T319",
     enfermedad:"Quemaduras que afectan del 90% o mas de la superficie del cuerpo"
  },
  {
     id:"T32",
     enfermedad:"Corrosiones clasificadas segun la extension de la superficie del cuerpo afectada"
  },
  {
     id:"T320",
     enfermedad:"Corrosiones que afectan menos del 10% de la superficie del cuerpo"
  },
  {
     id:"T321",
     enfermedad:"Corrosiones que afectan del 10% al 19% de la superficie del cuerpo"
  },
  {
     id:"T322",
     enfermedad:"Corrosiones que afectan del 20% al 29% de la superficie del cuerpo"
  },
  {
     id:"T323",
     enfermedad:"Corrosiones que afectan del 30% al 39% de la superficie del cuerpo"
  },
  {
     id:"T324",
     enfermedad:"Corrosiones que afectan del 40% al 49% de la superficie del cuerpo"
  },
  {
     id:"T325",
     enfermedad:"Corrosiones que afectan del 50% al 59% de la superficie del cuerpo"
  },
  {
     id:"T326",
     enfermedad:"Corrosiones que afectan del 60% al 69% de la superficie del cuerpo"
  },
  {
     id:"T327",
     enfermedad:"Corrosiones que afectan del 70% al 79% de la superficie del cuerpo"
  },
  {
     id:"T328",
     enfermedad:"Corrosiones que afectan del 80% al 89% de la superficie del cuerpo"
  },
  {
     id:"T329",
     enfermedad:"Corrosiones que afectan del 90% o mas de la superficie del cuerpo"
  },
  {
     id:"T33",
     enfermedad:"Congelamiento superficial"
  },
  {
     id:"T330",
     enfermedad:"Congelamiento superficial de la cabeza"
  },
  {
     id:"T331",
     enfermedad:"Congelamiento superficial del cuello"
  },
  {
     id:"T332",
     enfermedad:"Congelamiento superficial del torax"
  },
  {
     id:"T333",
     enfermedad:"Congelamiento superficial de la pared abdominal, region lumbosacra y pelvis"
  },
  {
     id:"T334",
     enfermedad:"Congelamiento superficial del brazo"
  },
  {
     id:"T335",
     enfermedad:"Congelamiento superficial de la muñeca y de la mano"
  },
  {
     id:"T336",
     enfermedad:"Congelamiento superficial de la cadera y del muslo"
  },
  {
     id:"T337",
     enfermedad:"Congelamiento superficial de la rodilla y de la pierna"
  },
  {
     id:"T338",
     enfermedad:"Congelamiento superficial del tobillo y del pie"
  },
  {
     id:"T339",
     enfermedad:"Congelamiento superficial de otros sitios y de los no especificados"
  },
  {
     id:"T34",
     enfermedad:"Congelamiento con necrosis tisular"
  },
  {
     id:"T340",
     enfermedad:"Congelamiento con necrosis tisular de la cabeza"
  },
  {
     id:"T341",
     enfermedad:"Congelamiento con necrosis tisular del cuello"
  },
  {
     id:"T342",
     enfermedad:"Congelamiento con necrosis tisular del torax"
  },
  {
     id:"T343",
     enfermedad:"Congelamiento con necrosis tisular de la pared abdominal, region lumbosacra y pelvis"
  },
  {
     id:"T344",
     enfermedad:"Congelamiento con necrosis tisular del brazo"
  },
  {
     id:"T345",
     enfermedad:"Congelamiento con necrosis tisular de la muñeca y de la mano"
  },
  {
     id:"T346",
     enfermedad:"Congelamiento con necrosis tisular de la cadera y del muslo"
  },
  {
     id:"T347",
     enfermedad:"Congelamiento con necrosis tisular de la rodilla y de la pierna"
  },
  {
     id:"T348",
     enfermedad:"Congelamiento con necrosis tisular del tobillo y del pie"
  },
  {
     id:"T349",
     enfermedad:"Congelamiento con necrosis tisular de otros sitios y de los no especificados"
  },
  {
     id:"T35",
     enfermedad:"Congelamiento que afecta multiples regiones del cuerpo y congelamiento no especificado"
  },
  {
     id:"T350",
     enfermedad:"Congelamiento superficial que afecta multiples regiones del cuerpo"
  },
  {
     id:"T351",
     enfermedad:"Congelamiento con necrosis tisular que afecta multiples regiones del cuerpo"
  },
  {
     id:"T352",
     enfermedad:"Congelamiento no especificado de la cabeza y del cuello"
  },
  {
     id:"T353",
     enfermedad:"Congelamiento no especificado del torax, del abdomen, de la region lumbosacra y de la pelvis"
  },
  {
     id:"T354",
     enfermedad:"Congelamiento no especificado del miembro superior"
  },
  {
     id:"T355",
     enfermedad:"Congelamiento no especificado del miembro inferior"
  },
  {
     id:"T356",
     enfermedad:"Congelamiento no especificado que afecta multiples regiones del cuerpo"
  },
  {
     id:"T357",
     enfermedad:"Congelamiento no especificado, de sitio no especificado"
  },
  {
     id:"T36",
     enfermedad:"Envenenamiento por antibioticos sistemicos"
  },
  {
     id:"T360",
     enfermedad:"Envenenamiento por antibioticos sistemicos: penicilinas"
  },
  {
     id:"T361",
     enfermedad:"Envenenamiento por antibioticos sistemicos: cefalosporinas y otros antibioticos beta"
  },
  {
     id:"T362",
     enfermedad:"Envenenamiento por antibioticos sistemicos: grupo del cloramfenicol"
  },
  {
     id:"T363",
     enfermedad:"Envenenamiento por antibioticos sistemicos: macrolidos"
  },
  {
     id:"T364",
     enfermedad:"Envenenamiento por antibioticos sistemicos: tetraciclinas"
  },
  {
     id:"T365",
     enfermedad:"Envenenamiento por antibioticos sistemicos: aminoglucosidos"
  },
  {
     id:"T366",
     enfermedad:"Envenenamiento por antibioticos sistemicos: rifamicinas"
  },
  {
     id:"T367",
     enfermedad:"Envenenamiento por antibioticos sistemicos: antibioticos antimicoticos usados sistemicamente"
  },
  {
     id:"T368",
     enfermedad:"Envenenamiento por antibioticos sistemicos: otros antibioticos sistemicos"
  },
  {
     id:"T369",
     enfermedad:"Envenenamiento por antibioticos sistemicos: antibioticos sistemicos, no especificados"
  },
  {
     id:"T37",
     enfermedad:"Envenenamiento por otros antiinfecciosos y antiparasitarios sistemicos"
  },
  {
     id:"T370",
     enfermedad:"Envenenamiento por otros antiinfecciosos y antiparasitarios sistemicos: sulfonamidas"
  },
  {
     id:"T371",
     enfermedad:"Envenenamiento por otros antiinfecciosos y antiparasitarios sistemicos: drogas antimicobacterianas"
  },
  {
     id:"T372",
     enfermedad:"Envenenamiento por otros antiinfecciosos y antiparasitarios sistemicos: antipaludicos y drogas de accion contra otros protozoarios sanguíneos"
  },
  {
     id:"T373",
     enfermedad:"Envenenamiento por otros antiinfecciosos y antiparasitarios sistemicos: otras drogas antiprotozoarias"
  },
  {
     id:"T374",
     enfermedad:"Envenenamiento por otros antiinfecciosos y antiparasitarios sistemicos: antihelminticos"
  },
  {
     id:"T375",
     enfermedad:"Envenenamiento por otros antiinfecciosos y antiparasitarios sistemicos: drogas antivirales"
  },
  {
     id:"T378",
     enfermedad:"Envenenamiento por otros antiinfecciosos y antiparasitarios sistemicos: otros antiinfecciosos y antiparasitarios sistemicos especificados"
  },
  {
     id:"T379",
     enfermedad:"Envenenamiento por otros antiinfecciosos y antiparasitarios sistemicos: otros antiinfecciosos y antiparasitarios sistemicos, no especificados"
  },
  {
     id:"T38",
     enfermedad:"Envenenamiento por hormonas y sus sustitutos y antagonistas sinteticos no clasificados en otra parte"
  },
  {
     id:"T380",
     enfermedad:"Envenenamiento por hormonas y sus sustitutos y antagonistas sinteticos, no clasificados en otra parte: glucocorticoides y analogos sinteticos"
  },
  {
     id:"T381",
     enfermedad:"Envenenamiento por hormonas y sus sustitutos y antagonistas sinteticos, no clasificados en otra parte: hormonas tiroideas y sustitutos"
  },
  {
     id:"T382",
     enfermedad:"Envenenamiento por hormonas y sus sustitutos y antagonistas sinteticos, no clasificados en otra parte: drogas antitiroideas"
  },
  {
     id:"T383",
     enfermedad:"Envenenamiento por hormonas y sus sustitutos y antagonistas sinteticos, no clasificados en otra parte: insulina y drogas hipoglucemiantes orales [antidiabeticas]"
  },
  {
     id:"T384",
     enfermedad:"Envenenamiento por hormonas y sus sustitutos y antagonistas sinteticos, no clasificados en otra parte: anticonceptivos orales"
  },
  {
     id:"T385",
     enfermedad:"Envenenamiento por hormonas y sus sustitutos y antagonistas sinteticos, no clasificados en otra parte: otros estrogenos y progestogenos"
  },
  {
     id:"T386",
     enfermedad:"Envenenamiento por hormonas y sus sustitutos y antagonistas sinteticos, no clasificados en otra parte: antigonadotrofinas, antiestrogenos y antiandrogenos, no clasificados en otra parte"
  },
  {
     id:"T387",
     enfermedad:"Envenenamiento por hormonas y sus sustitutos y antagonistas sinteticos, no clasificados en otra parte: androgenos y sus congeneres anabolicos"
  },
  {
     id:"T388",
     enfermedad:"Envenenamiento por hormonas y sus sustitutos y antagonistas sinteticos, no clasificados en otra parte: otras hormonas y sustitutos sinteticos y los no especificados"
  },
  {
     id:"T389",
     enfermedad:"Envenenamiento por hormonas y sus sustitutos y antagonistas sinteticos, no clasificados en otra parte: otros antagonistas de las hormonas y los no especificados"
  },
  {
     id:"T39",
     enfermedad:"Envenenamiento por analgesicos no narcoticos, antipireticos y antirreumaticos"
  },
  {
     id:"T390",
     enfermedad:"Envenenamiento por analgesicos no narcoticos, antipireticos y antirreumaticos: salicilatos"
  },
  {
     id:"T391",
     enfermedad:"Envenenamiento por analgesicos no narcoticos, antipireticos y antirreumaticos: derivados del paraaminofenol"
  },
  {
     id:"T392",
     enfermedad:"Envenenamiento por analgesicos no narcoticos, antipireticos y antirreumaticos: derivados de la pirazolona"
  },
  {
     id:"T393",
     enfermedad:"Envenenamiento por analgesicos no narcoticos, antipireticos y antirreumaticos: otras drogas antiinflamatorias no esteroideas [daine]"
  },
  {
     id:"T394",
     enfermedad:"Envenenamiento por analgesicos no narcoticos, antipireticos y antirreumaticos: antirreumaticos, no clasificados en otra parte"
  },
  {
     id:"T398",
     enfermedad:"Envenenamiento por analgesicos no narcoticos, antipireticos y antirreumaticos: otros analgesicos no narcoticos y antipireticos, no clasificados en otra parte"
  },
  {
     id:"T399",
     enfermedad:"Envenenamiento por analgesicos no narcoticos, antipireticos y antirreumaticos: analgesicos no narcoticos, antipireticos y antirreumaticos, no especificados"
  },
  {
     id:"T40",
     enfermedad:"Envenenamiento por narcoticos y psicodislepticos"
  },
  {
     id:"T400",
     enfermedad:"Envenenamiento por narcoticos y psicodislepticos [alucinogenos]: opio"
  },
  {
     id:"T401",
     enfermedad:"Envenenamiento por narcoticos y psicodislepticos [alucinogenos]: heroina"
  },
  {
     id:"T402",
     enfermedad:"Envenenamiento por narcoticos y psicodislepticos [alucinogenos]: otros opiaceos"
  },
  {
     id:"T403",
     enfermedad:"Envenenamiento por narcoticos y psicodislepticos [alucinogenos]: metadona"
  },
  {
     id:"T404",
     enfermedad:"Envenenamiento por narcoticos y psicodislepticos [alucinogenos]: otros narcoticos sinteticos"
  },
  {
     id:"T405",
     enfermedad:"Envenenamiento por narcoticos y psicodislepticos [alucinogenos]: cocaina"
  },
  {
     id:"T406",
     enfermedad:"Envenenamiento por narcoticos y psicodislepticos [alucinogenos]: otros narcoticos y los no especificados"
  },
  {
     id:"T407",
     enfermedad:"Envenenamiento por narcoticos y psicodislepticos [alucinogenos]: cannabis (derivados)"
  },
  {
     id:"T408",
     enfermedad:"Envenenamiento por narcoticos y psicodislepticos [alucinogenos]: acido lisergico [lsd]"
  },
  {
     id:"T409",
     enfermedad:"Envenenamiento por narcoticos y psicodislepticos [alucinogenos]: otros psicodislepticos y los no especificados [alucinogenos]"
  },
  {
     id:"T41",
     enfermedad:"Envenenamiento por anestesicos y gases terapeuticos"
  },
  {
     id:"T410",
     enfermedad:"Envenenamiento por anestesicos y gases terapeuticos: anestesicos por inhalacion"
  },
  {
     id:"T411",
     enfermedad:"Envenenamiento por anestesicos y gases terapeuticos: anestesicos intravenosos"
  },
  {
     id:"T412",
     enfermedad:"Envenenamiento por anestesicos y gases terapeuticos: otros anestesicos generales y los no especificados"
  },
  {
     id:"T413",
     enfermedad:"Envenenamiento por anestesicos y gases terapeuticos: anestesicos locales"
  },
  {
     id:"T414",
     enfermedad:"Envenenamiento por anestesicos y gases terapeuticos: anestesicos, no especificados"
  },
  {
     id:"T415",
     enfermedad:"Envenenamiento por anestesicos y gases terapeuticos: gases terapeuticos"
  },
  {
     id:"T42",
     enfermedad:"Envenenamiento por antiepilepticos, hipnotico"
  },
  {
     id:"T420",
     enfermedad:"Envenenamiento por antiepilepticos, hipnoticos"
  },
  {
     id:"T421",
     enfermedad:"Envenenamiento por antiepilepticos, hipnoticos"
  },
  {
     id:"T422",
     enfermedad:"Envenenamiento por antiepilepticos, hipnoticos"
  },
  {
     id:"T423",
     enfermedad:"Envenenamiento por antiepilepticos, hipnoticos"
  },
  {
     id:"T424",
     enfermedad:"Envenenamiento por antiepilepticos, hipnoticos"
  },
  {
     id:"T425",
     enfermedad:"Envenenamiento por antiepilepticos, hipnoticos"
  },
  {
     id:"T426",
     enfermedad:"Envenenamiento por antiepilepticos, hipnoticos"
  },
  {
     id:"T427",
     enfermedad:"Envenenamiento por antiepilepticos, hipnoticos"
  },
  {
     id:"T428",
     enfermedad:"Envenenamiento por antiepilepticos, hipnoticos"
  },
  {
     id:"T43",
     enfermedad:"Envenenamiento por psicotropicos no clasificados en otra parte"
  },
  {
     id:"T430",
     enfermedad:"Envenenamiento por psicotropicos, no clasificados en otra parte: antidepresivos triciclicos y tetraciclicos"
  },
  {
     id:"T431",
     enfermedad:"Envenenamiento por psicotropicos, no clasificados en otra parte: antidepresivos inhibidores de la monoaminoxidasa"
  },
  {
     id:"T432",
     enfermedad:"Envenenamiento por psicotropicos, no clasificados en otra parte: otros antidepresivos y los no especificados"
  },
  {
     id:"T433",
     enfermedad:"Envenenamiento por psicotropicos, no clasificados en otra parte: antipsicoticos y neurolepticos fenotiacinicos"
  },
  {
     id:"T434",
     enfermedad:"Envenenamiento por psicotropicos, no clasificados en otra parte: butirofenona y neurolepticos tioxantenicos"
  },
  {
     id:"T435",
     enfermedad:"Envenenamiento por psicotropicos, no clasificados en otra parte: otros antipsicoticos y neurolepticos y los no especificados"
  },
  {
     id:"T436",
     enfermedad:"Envenenamiento por psicotropicos, no clasificados en otra parte: psicoestimulantes con abuso potencial"
  },
  {
     id:"T438",
     enfermedad:"Envenenamiento por psicotropicos, no clasificados en otra parte: otras drogas psicotropicas, no clasificadas en otra parte"
  },
  {
     id:"T439",
     enfermedad:"Envenenamiento por psicotropicos, no clasificados en otra parte: droga psicotropica no especificada"
  },
  {
     id:"T44",
     enfermedad:"Envenenamiento por drogas que afectan principalmente el sistema nervioso autonomo"
  },
  {
     id:"T440",
     enfermedad:"Envenenamiento por drogas que afectan principalmente el sistema nervioso autonomo: agentes anticolinesterasa"
  },
  {
     id:"T441",
     enfermedad:"Envenenamiento por drogas que afectan principalmente el sistema nervioso autonomo: otros parasimpaticomimeticos [colinergicos]"
  },
  {
     id:"T442",
     enfermedad:"Envenenamiento por drogas que afectan principalmente el sistema nervioso autonomo: drogas bloqueadoras ganglionares, no clasificadas en otra parte"
  },
  {
     id:"T443",
     enfermedad:"Envenenamiento por drogas que afectan principalmente el sistema nervioso autonomo: otros parasimpaticoliticos [anticolinergicos y antimuscarinicos] y espasmoliticos, no clasificados en otra parte"
  },
  {
     id:"T444",
     enfermedad:"Envenenamiento por drogas que afectan principalmente el sistema nervioso autonomo: agonistas, predominantemente alfa"
  },
  {
     id:"T445",
     enfermedad:"Envenenamiento por drogas que afectan principalmente el sistema nervioso autonomo: agonistas, predominantemente beta"
  },
  {
     id:"T446",
     enfermedad:"Envenenamiento por drogas que afectan principalmente el sistema nervioso autonomo: antagonistas, alfa"
  },
  {
     id:"T447",
     enfermedad:"Envenenamiento por drogas que afectan principalmente el sistema nervioso autonomo: antagonistas, beta"
  },
  {
     id:"T448",
     enfermedad:"Envenenamiento por drogas que afectan principalmente el sistema nervioso autonomo: agentes de accion central y bloqueadores neuronales adrenergicos, no clasificados en otra parte"
  },
  {
     id:"T449",
     enfermedad:"Envenenamiento por drogas que afectan principalmente el sistema nervioso autonomo: otras drogas y las no especificadas que afectan principalmente el sistema nervioso autonomo"
  },
  {
     id:"T45",
     enfermedad:"Envenenamiento por agentes principalmente sistemicos y hematologicos no clasificados en otra parte"
  },
  {
     id:"T450",
     enfermedad:"Envenenamiento por agentes principalmente sistemicos y hematologicos, no clasificados en otra parte: drogas antialergicas y antiemeticas"
  },
  {
     id:"T451",
     enfermedad:"Envenenamiento por agentes principalmente sistemicos y hematologicos, no clasificados en otra parte: drogas antineoplasicas e inmunosupresoras"
  },
  {
     id:"T452",
     enfermedad:"Envenenamiento por agentes principalmente sistemicos y hematologicos, no clasificados en otra parte: vitaminas, no clasificadas en otra parte"
  },
  {
     id:"T453",
     enfermedad:"Envenenamiento por agentes principalmente sistemicos y hematologicos, no clasificados en otra parte: enzimas, no clasificadas en otra parte"
  },
  {
     id:"T454",
     enfermedad:"Envenenamiento por agentes principalmente sistemicos y hematologicos, no clasificados en otra parte: hierro y sus compuestos"
  },
  {
     id:"T455",
     enfermedad:"Envenenamiento por agentes principalmente sistemicos y hematologicos, no clasificados en otra parte: anticoagulantes"
  },
  {
     id:"T456",
     enfermedad:"Envenenamiento por agentes principalmente sistemicos y hematologicos, no clasificados en otra parte: drogas que afectan la fibrinolisis"
  },
  {
     id:"T457",
     enfermedad:"Envenenamiento por agentes principalmente sistemicos y hematologicos, no clasificados en otra parte: antagonistas de anticoagulantes, vitamina k y otros coagulantes"
  },
  {
     id:"T458",
     enfermedad:"Envenenamiento por agentes principalmente sistemicos y hematologicos, no clasificados en otra parte: otros agentes principalmente sistemicos y hematologicos"
  },
  {
     id:"T459",
     enfermedad:"Envenenamiento por agentes principalmente sistemicos y hematologicos, no clasificados en otra parte: agentes principalmente sistemicos y hematologicos, no especificados"
  },
  {
     id:"T46",
     enfermedad:"Envenenamiento por agentes que afectan principalmente el sistema cardiovascular"
  },
  {
     id:"T460",
     enfermedad:"Envenenamiento por agentes que afectan principalmente el sistema cardiovascular: glucosidos cardiotonicos y medicamentos de accion similar"
  },
  {
     id:"T461",
     enfermedad:"Envenenamiento por agentes que afectan principalmente el sistema cardiovascular: bloqueadores del canal del calcio"
  },
  {
     id:"T462",
     enfermedad:"Envenenamiento por agentes que afectan principalmente el sistema cardiovascular: otras drogas antiarritmicas, no clasificadas en otra parte"
  },
  {
     id:"T463",
     enfermedad:"Envenenamiento por agentes que afectan principalmente el sistema cardiovascular: vasodilatadores coronarios, no clasificados en otra parte"
  },
  {
     id:"T464",
     enfermedad:"Envenenamiento por agentes que afectan principalmente el sistema cardiovascular: inhibidores de la enzima convertidora de la angiotensina"
  },
  {
     id:"T465",
     enfermedad:"Envenenamiento por agentes que afectan principalmente el sistema cardiovascular: otras drogas antihipertensivas, no clasificadas en otra parte"
  },
  {
     id:"T466",
     enfermedad:"Envenenamiento por agentes que afectan principalmente el sistema cardiovascular: drogas antilipemicas y antiarterioscleroticas"
  },
  {
     id:"T467",
     enfermedad:"Envenenamiento por agentes que afectan principalmente el sistema cardiovascular: vasodilatadores perifericos"
  },
  {
     id:"T468",
     enfermedad:"Envenenamiento por agentes que afectan principalmente el sistema cardiovascular: drogas antivaricosas, inclusive agentes esclerosantes"
  },
  {
     id:"T469",
     enfermedad:"Envenenamiento por agentes que afectan principalmente el sistema cardiovascular: otros agentes y los no especificados que afectan principalmente el sistema cardiovascular"
  },
  {
     id:"T47",
     enfermedad:"Envenenamiento por agentes que afectan principalmente el sistema gastrointestinal"
  },
  {
     id:"T470",
     enfermedad:"Envenenamiento por agentes que afectan principalmente el sistema gastrointestinal: antagonistas del receptor h2 de histamina"
  },
  {
     id:"T471",
     enfermedad:"Envenenamiento por agentes que afectan principalmente el sistema gastrointestinal: otras drogas antiacidas y que inhiben la secrecion gastrica"
  },
  {
     id:"T472",
     enfermedad:"Envenenamiento por agentes que afectan principalmente el sistema gastrointestinal: laxantes estimulantes"
  },
  {
     id:"T473",
     enfermedad:"Envenenamiento por agentes que afectan principalmente el sistema gastrointestinal: laxantes salinos y osmoticos"
  },
  {
     id:"T474",
     enfermedad:"Envenenamiento por agentes que afectan principalmente el sistema gastrointestinal: otros laxantes"
  },
  {
     id:"T475",
     enfermedad:"Envenenamiento por agentes que afectan principalmente el sistema gastrointestinal: digestivos"
  },
  {
     id:"T476",
     enfermedad:"Envenenamiento por agentes que afectan principalmente el sistema gastrointestinal: drogas antidiarreicas"
  },
  {
     id:"T477",
     enfermedad:"Envenenamiento por agentes que afectan principalmente el sistema gastrointestinal: emeticos"
  },
  {
     id:"T478",
     enfermedad:"Envenenamiento por agentes que afectan principalmente el sistema gastrointestinal: otros agentes que afectan principalmente el sistema gastrointestinal"
  },
  {
     id:"T479",
     enfermedad:"Envenenamiento por agentes que afectan principalmente el sistema gastrointestinal: agentes no especificados que afectan principalmente el sistema gastrointestinal"
  },
  {
     id:"T48",
     enfermedad:"Envenenamiento por agentes con accion principal sobre los musculos lisos y esqueleticos y sobre el sistema respiratorio"
  },
  {
     id:"T480",
     enfermedad:"Envenenamiento por agentes con accion principal sobre los musculos lisos y esqueleticos y sobre el sistema respiratorio: drogas oxitocicas"
  },
  {
     id:"T481",
     enfermedad:"Envenenamiento por agentes con accion principal sobre los musculos lisos y esqueleticos y sobre el sistema respiratorio: relajantes musculoesqueleticos [agentes bloqueadores neuromusculares]"
  },
  {
     id:"T482",
     enfermedad:"Envenenamiento por agentes con accion principal sobre los musculos lisos y esqueleticos y sobre el sistema respiratorio: otros medicamentos y los no especificados de accion principal sobre los musculos"
  },
  {
     id:"T483",
     enfermedad:"Envenenamiento por agentes con accion principal sobre los musculos lisos y esqueleticos y sobre el sistema respiratorio: antitusigenos"
  },
  {
     id:"T484",
     enfermedad:"Envenenamiento por agentes con accion principal sobre los musculos lisos y esqueleticos y sobre el sistema respiratorio: expectorantes"
  },
  {
     id:"T485",
     enfermedad:"Envenenamiento por agentes con accion principal sobre los musculos lisos y esqueleticos y sobre el sistema respiratorio: drogas contra el catarro comun"
  },
  {
     id:"T486",
     enfermedad:"Envenenamiento por agentes con accion principal sobre los musculos lisos y esqueleticos y sobre el sistema respiratorio: antiasmaticos, no clasificados en otra parte"
  },
  {
     id:"T487",
     enfermedad:"Envenenamiento por agentes con accion principal sobre los musculos lisos y esqueleticos y sobre el sistema respiratorio: otros agentes y los no especificados de accion principal sobre el sistema respiratorio"
  },
  {
     id:"T49",
     enfermedad:"Envenenamiento por agentes topicos que afectan principalmente la piel y las membranas mucosas y por drogas oftalmologicas otorrinolaringologas y dentales"
  },
  {
     id:"T490",
     enfermedad:"Envenenamiento por agentes topicos que afectan principalmente la piel y las membranas mucosas y por drogas oftalmologicas, otorrinolaringologicas y dentales: drogas locales antimicoticas, antiinfecciosas y antiinflamatorias, no clasificadas en otra parte"
  },
  {
     id:"T491",
     enfermedad:"Envenenamiento por agentes topicos que afectan principalmente la piel y las membranas mucosas y por drogas oftalmologicas, otorrinolaringologicas y dentales: antipruriticos"
  },
  {
     id:"T492",
     enfermedad:"Envenenamiento por agentes topicos que afectan principalmente la piel y las membranas mucosas y por drogas oftalmologicas, otorrinolaringologicas y dentales: astringentes y detergentes locales"
  },
  {
     id:"T493",
     enfermedad:"Envenenamiento por agentes topicos que afectan principalmente la piel y las membranas mucosas y por drogas oftalmologicas, otorrinolaringologicas y dentales: emolientes, demulcentes y protectores"
  },
  {
     id:"T494",
     enfermedad:"Envenenamiento por agentes topicos que afectan principalmente la piel y las membranas mucosas y por drogas oftalmologicas, otorrinolaringologicas y dentales: queratoliticos, queratoplasticos, drogas y otras preparaciones para el tratamiento del cabello"
  },
  {
     id:"T495",
     enfermedad:"Envenenamiento por agentes topicos que afectan principalmente la piel y las membranas mucosas y por drogas oftalmologicas, otorrinolaringologicas y dentales: drogas y preparaciones oftalmologicas"
  },
  {
     id:"T496",
     enfermedad:"Envenenamiento por agentes topicos que afectan principalmente la piel y las membranas mucosas y por drogas oftalmologicas, otorrinolaringologicas y dentales: drogas y preparaciones otorrinolaringologicas"
  },
  {
     id:"T497",
     enfermedad:"Envenenamiento por agentes topicos que afectan principalmente la piel y las membranas mucosas y por drogas oftalmologicas, otorrinolaringologicas y dentales: drogas dentales, aplicadas topicamente"
  },
  {
     id:"T498",
     enfermedad:"Envenenamiento por agentes topicos que afectan principalmente la piel y las membranas mucosas y por drogas oftalmologicas, otorrinolaringologicas y dentales: otros agentes topicos"
  },
  {
     id:"T499",
     enfermedad:"Envenenamiento por agentes topicos que afectan principalmente la piel y las membranas mucosas y por drogas oftalmologicas, otorrinolaringologicas y dentales: agentes topicos, no especificados"
  },
  {
     id:"T50",
     enfermedad:"Envenenamiento por diureticos y otras drogas, medicamentos y sustancias biologicas no especificadas"
  },
  {
     id:"T500",
     enfermedad:"Envenenamiento por diureticos y otras drogas, medicamentos y sustancias biologicas no especificadas: mineralocorticoides y sus antagonistas"
  },
  {
     id:"T501",
     enfermedad:"Envenenamiento por diureticos y otras drogas, medicamentos y sustancias biologicas no especificadas: diureticos del asa [dintel alto]"
  },
  {
     id:"T502",
     enfermedad:"Envenenamiento por diureticos y otras drogas, medicamentos y sustancias biologicas no especificadas: inhibidores de la anhidrasa del acido carbonico, benzotiazidas y otros diureticos"
  },
  {
     id:"T503",
     enfermedad:"Envenenamiento por diureticos y otras drogas, medicamentos y sustancias biologicas no especificadas: agentes del equilibrio hidroelectrolitico, electrolitico y calorico"
  },
  {
     id:"T504",
     enfermedad:"Envenenamiento por diureticos y otras drogas, medicamentos y sustancias biologicas no especificadas: drogas que afectan el metabolismo del acido urico"
  },
  {
     id:"T505",
     enfermedad:"Envenenamiento por diureticos y otras drogas, medicamentos y sustancias biologicas no especificadas: depresores del apetito"
  },
  {
     id:"T506",
     enfermedad:"Envenenamiento por diureticos y otras drogas, medicamentos y sustancias biologicas no especificadas: antidotos y agentes quelantes, no clasificados en otra parte"
  },
  {
     id:"T507",
     enfermedad:"Envenenamiento por diureticos y otras drogas, medicamentos y sustancias biologicas no especificadas: analepticos y antagonistas del opio"
  },
  {
     id:"T508",
     enfermedad:"Envenenamiento por diureticos y otras drogas, medicamentos y sustancias biologicas no especificadas: agentes diagnosticos"
  },
  {
     id:"T509",
     enfermedad:"Envenenamiento por diureticos y otras drogas, medicamentos y sustancias biologicas no especificadas: otras drogas y sustancias biologicas, y las no especificadas"
  },
  {
     id:"T51",
     enfermedad:"Efecto toxico del alcohol"
  },
  {
     id:"T510",
     enfermedad:"Efecto toxico del alcohol: etanol"
  },
  {
     id:"T511",
     enfermedad:"Efecto toxico del alcohol: metanol"
  },
  {
     id:"T512",
     enfermedad:"Efecto toxico del alcohol: propanol"
  },
  {
     id:"T513",
     enfermedad:"Efecto toxico del alcohol: licor de alcohol insuficientemente destilado"
  },
  {
     id:"T518",
     enfermedad:"Efecto toxico del alcohol: otros alcoholes"
  },
  {
     id:"T519",
     enfermedad:"Efecto toxico del alcohol: alcohol, no especificado"
  },
  {
     id:"T52",
     enfermedad:"Efecto toxico de disolventes organicos"
  },
  {
     id:"T520",
     enfermedad:"Efecto toxico de disolventes organicos: productos del petroleo"
  },
  {
     id:"T521",
     enfermedad:"Efecto toxico de disolventes organicos: benceno"
  },
  {
     id:"T522",
     enfermedad:"Efecto toxico de disolventes organicos: homologos del benceno"
  },
  {
     id:"T523",
     enfermedad:"Efecto toxico de disolventes organicos: glicoles"
  },
  {
     id:"T524",
     enfermedad:"Efecto toxico de disolventes organicos: cetonas"
  },
  {
     id:"T528",
     enfermedad:"Efecto toxico de disolventes organicos: otros disolventes organicos"
  },
  {
     id:"T529",
     enfermedad:"Efecto toxico de disolventes organicos: disolventes organicos, no especificados"
  },
  {
     id:"T53",
     enfermedad:"Efecto toxico de los derivados halogenados de los hidrocarburos alifaticos y aromaticos"
  },
  {
     id:"T530",
     enfermedad:"Efecto toxico de los derivados halogenados de los hidrocarburos alifaticos y aromaticos: tetracloruro de carbono"
  },
  {
     id:"T531",
     enfermedad:"Efecto toxico de los derivados halogenados de los hidrocarburos alifaticos y aromaticos: cloroformo"
  },
  {
     id:"T532",
     enfermedad:"Efecto toxico de los derivados halogenados de los hidrocarburos alifaticos y aromaticos: tricloroetileno"
  },
  {
     id:"T533",
     enfermedad:"Efecto toxico de los derivados halogenados de los hidrocarburos alifaticos y aromaticos: tetracloroetileno"
  },
  {
     id:"T534",
     enfermedad:"Efecto toxico de los derivados halogenados de los hidrocarburos alifaticos y aromaticos: dicloroetano"
  },
  {
     id:"T535",
     enfermedad:"Efecto toxico de los derivados halogenados de los hidrocarburos alifaticos y aromaticos: clorofluorcarburos"
  },
  {
     id:"T536",
     enfermedad:"Efecto toxico de los derivados halogenados de los hidrocarburos alifaticos y aromaticos: otros derivados halogenados de los hidrocarburos alifaticos"
  },
  {
     id:"T537",
     enfermedad:"Efecto toxico de los derivados halogenados de los hidrocarburos alifaticos y aromaticos: otros derivados halogenados de los hidrocarburos aromaticos"
  },
  {
     id:"T539",
     enfermedad:"Efecto toxico de los derivados halogenados de los hidrocarburos alifaticos y aromaticos: derivados halogenados de hidrocarburos alifaticos y aromaticos, no especificados"
  },
  {
     id:"T54",
     enfermedad:"Efecto toxico de sustancias corrosivas"
  },
  {
     id:"T540",
     enfermedad:"Efecto toxico de sustancias corrosivas: fenol y homologos del fenol"
  },
  {
     id:"T541",
     enfermedad:"Efecto toxico de sustancias corrosivas: otros compuestos organicos corrosivos"
  },
  {
     id:"T542",
     enfermedad:"Efecto toxico de sustancias corrosivas: acidos corrosivos y sustancias acidas similares"
  },
  {
     id:"T543",
     enfermedad:"Efecto toxico de sustancias corrosivas: alcalis causticos y sustancias alcalinas similares"
  },
  {
     id:"T549",
     enfermedad:"Efecto toxico de sustancias corrosivas: efecto toxico de sustancia corrosiva, no especificada"
  },
  {
     id:"T55",
     enfermedad:"Efecto toxico de detergentes y jabones"
  },
  {
     id:"T56",
     enfermedad:"Efecto toxico de metales"
  },
  {
     id:"T560",
     enfermedad:"Efecto toxico de metales: plomo y sus compuestos"
  },
  {
     id:"T561",
     enfermedad:"Efecto toxico de metales: mercurio y sus compuestos"
  },
  {
     id:"T562",
     enfermedad:"Efecto toxico de metales: cromo y sus compuestos"
  },
  {
     id:"T563",
     enfermedad:"Efecto toxico de metales: cadmio y sus compuestos"
  },
  {
     id:"T564",
     enfermedad:"Efecto toxico de metales: cobre y sus compuestos"
  },
  {
     id:"T565",
     enfermedad:"Efecto toxico de metales: zinc y sus compuestos"
  },
  {
     id:"T566",
     enfermedad:"Efecto toxico de metales: estaño y sus compuestos"
  },
  {
     id:"T567",
     enfermedad:"Efecto toxico de metales: berilio y sus compuestos"
  },
  {
     id:"T568",
     enfermedad:"Efecto toxico de metales: otros metales"
  },
  {
     id:"T569",
     enfermedad:"Efecto toxico de metales: metal, no especificado"
  },
  {
     id:"T57",
     enfermedad:"Efecto toxico de otras sustancias inorganicas"
  },
  {
     id:"T570",
     enfermedad:"Efecto toxico de otras sustancias inorganicas: arsenico y sus compuestos"
  },
  {
     id:"T571",
     enfermedad:"Efecto toxico de otras sustancias inorganicas: fosforo y sus compuestos"
  },
  {
     id:"T572",
     enfermedad:"Efecto toxico de otras sustancias inorganicas: manganeso y sus compuestos"
  },
  {
     id:"T573",
     enfermedad:"Efecto toxico de otras sustancias inorganicas: acido cianhidrico"
  },
  {
     id:"T578",
     enfermedad:"Efecto toxico de otras sustancias inorganicas: otras sustancias inorganicas, especificadas"
  },
  {
     id:"T579",
     enfermedad:"Efecto toxico de otras sustancias inorganicas: sustancia inorganicas, no especificada"
  },
  {
     id:"T58",
     enfermedad:"Efecto toxico del monoxido de carbono"
  },
  {
     id:"T59",
     enfermedad:"Efecto toxico de otros gases, humos y vapores"
  },
  {
     id:"T590",
     enfermedad:"Efecto toxico de gases, humos y vapores: oxidos de nitrogeno"
  },
  {
     id:"T591",
     enfermedad:"Efecto toxico de gases, humos y vapores: dioxido de sulfuro"
  },
  {
     id:"T592",
     enfermedad:"Efecto toxico de gases, humos y vapores: formaldehido"
  },
  {
     id:"T593",
     enfermedad:"Efecto toxico de gases, humos y vapores: gas lacrimogeno"
  },
  {
     id:"T594",
     enfermedad:"Efecto toxico de gases, humos y vapores: cloro gaseoso"
  },
  {
     id:"T595",
     enfermedad:"Efecto toxico de gases, humos y vapores: gas de fluor y fluoruro de hidrogeno"
  },
  {
     id:"T596",
     enfermedad:"Efecto toxico de gases, humos y vapores: sulfuro de hidrogeno"
  },
  {
     id:"T597",
     enfermedad:"Efecto toxico de gases, humos y vapores: dioxido de carbono"
  },
  {
     id:"T598",
     enfermedad:"Efecto toxico de gases, humos y vapores: otros gases, humos y vapores especificados"
  },
  {
     id:"T599",
     enfermedad:"Efecto toxico de gases, humos y vapores: gases, humos y vapores no especificados"
  },
  {
     id:"T60",
     enfermedad:"Efecto toxico de plaguicidas [pesticidas]"
  },
  {
     id:"T600",
     enfermedad:"Efecto toxico de plaguicidas [pesticidas]: insecticidas fosforados y carbamatos"
  },
  {
     id:"T601",
     enfermedad:"Efecto toxico de plaguicidas [pesticidas]: insecticidas halogenados"
  },
  {
     id:"T602",
     enfermedad:"Efecto toxico de plaguicidas [pesticidas]: otros insecticidas"
  },
  {
     id:"T603",
     enfermedad:"Efecto toxico de plaguicidas [pesticidas]: herbicidas y fungicidas"
  },
  {
     id:"T604",
     enfermedad:"Efecto toxico de plaguicidas [pesticidas]: rodenticidas"
  },
  {
     id:"T608",
     enfermedad:"Efecto toxico de plaguicidas [pesticidas]: otros plaguicidas"
  },
  {
     id:"T609",
     enfermedad:"Efecto toxico de plaguicidas [pesticidas]: plaguicida no especificado"
  },
  {
     id:"T61",
     enfermedad:"Efecto toxico de sustancias nocivas ingeridas como alimentos marinos"
  },
  {
     id:"T610",
     enfermedad:"Efecto toxico de sustancias nocivas ingeridas como alimentos marinos: envenenamiento ciguatero por pescado"
  },
  {
     id:"T611",
     enfermedad:"Efecto toxico de sustancias nocivas ingeridas como alimentos marinos: envenenamiento escombroideo por pescado"
  },
  {
     id:"T612",
     enfermedad:"Efecto toxico de sustancias nocivas ingeridas como alimentos marinos: otros envenenamientos por pescado y mariscos"
  },
  {
     id:"T618",
     enfermedad:"Efecto toxico de sustancias nocivas ingeridas como alimentos marinos: efecto toxico de otros alimentos marinos"
  },
  {
     id:"T619",
     enfermedad:"Efecto toxico de sustancias nocivas ingeridas como alimentos marinos: efecto toxico de alimentos marinos no especificados"
  },
  {
     id:"T62",
     enfermedad:"Efecto toxico de otras sustancias nocivas ingeridas como alimento"
  },
  {
     id:"T620",
     enfermedad:"Efecto toxico de otras sustancias nocivas ingeridas como alimento: hongos ingeridos"
  },
  {
     id:"T621",
     enfermedad:"Efecto toxico de otras sustancias nocivas ingeridas como alimento: bayas ingeridas"
  },
  {
     id:"T622",
     enfermedad:"Efecto toxico de otras sustancias nocivas ingeridas como alimento: otra(s) (partes de) planta(s) ingerida(s)"
  },
  {
     id:"T628",
     enfermedad:"Efecto toxico de otras sustancias nocivas ingeridas como alimento: otras sustancias nocivas ingeridas como alimento"
  },
  {
     id:"T629",
     enfermedad:"Efecto toxico de otras sustancias nocivas ingeridas como alimento: sustancia nociva ingerida como alimento, no especificada"
  },
  {
     id:"T63",
     enfermedad:"Efecto toxico del contacto con animales venenosos"
  },
  {
     id:"T630",
     enfermedad:"Efecto toxico del contacto con animales venenosos: veneno de serpiente"
  },
  {
     id:"T631",
     enfermedad:"Efecto toxico del contacto con animales venenosos: veneno de otros reptiles"
  },
  {
     id:"T632",
     enfermedad:"Efecto toxico del contacto con animales venenosos: veneno de escorpion"
  },
  {
     id:"T633",
     enfermedad:"Efecto toxico del contacto con animales venenosos: veneno de arañas"
  },
  {
     id:"T634",
     enfermedad:"Efecto toxico del contacto con animales venenosos: veneno de otros artropodos"
  },
  {
     id:"T635",
     enfermedad:"Efecto toxico del contacto con animales venenosos: efecto toxico del contacto con peces"
  },
  {
     id:"T636",
     enfermedad:"Efecto toxico del contacto con animales venenosos: efecto toxico del contacto con otros animales marinos"
  },
  {
     id:"T638",
     enfermedad:"Efecto toxico del contacto con animales venenosos: efecto toxico del contacto con otros animales venenosos"
  },
  {
     id:"T639",
     enfermedad:"Efecto toxico del contacto con animales venenosos: efecto toxico del contacto con animal venenoso no especificado"
  },
  {
     id:"T64",
     enfermedad:"Efecto toxico de aflatoxina y otras micotoxinas contaminantes de alimentos"
  },
  {
     id:"T65",
     enfermedad:"Efecto toxico de otras sustancias y las no especificadas"
  },
  {
     id:"T650",
     enfermedad:"Efecto toxico de otras sustancias y las no especificadas: cianuro"
  },
  {
     id:"T651",
     enfermedad:"Efecto toxico de otras sustancias y las no especificadas: estricnina y sus sales"
  },
  {
     id:"T652",
     enfermedad:"Efecto toxico de otras sustancias y las no especificadas: tabaco y nicotina"
  },
  {
     id:"T653",
     enfermedad:"Efecto toxico de otras sustancias y las no especificadas: nitroderivados y aminoderivados del benceno y sus homologos"
  },
  {
     id:"T654",
     enfermedad:"Efecto toxico de otras sustancias y las no especificadas: bisulfuro de carbono"
  },
  {
     id:"T655",
     enfermedad:"Efecto toxico de otras sustancias y las no especificadas: nitroglicerina y otros acidos y esteres nitricos"
  },
  {
     id:"T656",
     enfermedad:"Efecto toxico de otras sustancias y las no especificadas: pinturas y colorantes, no clasificados en otra parte"
  },
  {
     id:"T658",
     enfermedad:"Efecto toxico de otras sustancias y las no especificadas: efectos toxicos de otras sustancias especificadas"
  },
  {
     id:"T659",
     enfermedad:"Efecto toxico de otras sustancias y las no especificadas: efecto toxico de sustancia no especificada"
  },
  {
     id:"T66",
     enfermedad:"Efectos no especificados de la radiacion"
  },
  {
     id:"T67",
     enfermedad:"Efectos del calor y de la luz"
  },
  {
     id:"T670",
     enfermedad:"Golpe de calor e insolacion"
  },
  {
     id:"T671",
     enfermedad:"Sincope de calor"
  },
  {
     id:"T672",
     enfermedad:"Calambre por calor"
  },
  {
     id:"T673",
     enfermedad:"Agotamiento por calor, anhidrotico"
  },
  {
     id:"T674",
     enfermedad:"Agotamiento por calor debido a deplecion de sal"
  },
  {
     id:"T675",
     enfermedad:"Agotamiento por calor no especificado"
  },
  {
     id:"T676",
     enfermedad:"Fatiga por calor, transitoria"
  },
  {
     id:"T677",
     enfermedad:"Edema por calor"
  },
  {
     id:"T678",
     enfermedad:"Otros efectos del calor y de la luz"
  },
  {
     id:"T679",
     enfermedad:"Efecto del calor y de la luz, no especificado"
  },
  {
     id:"T68",
     enfermedad:"Hipotermia"
  },
  {
     id:"T69",
     enfermedad:"Otros efectos de la reduccion de la temperatura"
  },
  {
     id:"T690",
     enfermedad:"Mano y pie de inmersion"
  },
  {
     id:"T691",
     enfermedad:"Sabañon(es)"
  },
  {
     id:"T698",
     enfermedad:"Otros efectos especificados de la reduccion de la temperatura"
  },
  {
     id:"T699",
     enfermedad:"Efecto de la reduccion de la temperatura, no especificado"
  },
  {
     id:"T70",
     enfermedad:"Efectos de la presion del aire y del agua"
  },
  {
     id:"T700",
     enfermedad:"Barotrauma otitico"
  },
  {
     id:"T701",
     enfermedad:"Barotrauma sinusal"
  },
  {
     id:"T702",
     enfermedad:"Otros efectos y los no especificados de la gran altitud"
  },
  {
     id:"T703",
     enfermedad:"Enfermedad por descompresion [de los cajones sumergidos]"
  },
  {
     id:"T704",
     enfermedad:"Efectos de liquidos con alta presion"
  },
  {
     id:"T708",
     enfermedad:"Otros efectos de la presion del aire y del agua"
  },
  {
     id:"T709",
     enfermedad:"Efecto de la presion del aire y del agua, no especificado"
  },
  {
     id:"T71",
     enfermedad:"Asfixia"
  },
  {
     id:"T73",
     enfermedad:"Efectos de otras privaciones"
  },
  {
     id:"T730",
     enfermedad:"Efectos del hambre"
  },
  {
     id:"T731",
     enfermedad:"Efectos de la sed"
  },
  {
     id:"T732",
     enfermedad:"Agotamiento debido a exposicion a la intemperie"
  },
  {
     id:"T733",
     enfermedad:"Agotamiento debido a esfuerzo excesivo"
  },
  {
     id:"T738",
     enfermedad:"Otros efectos de privacion"
  },
  {
     id:"T739",
     enfermedad:"Efectos de privacion, no especificados"
  },
  {
     id:"T74",
     enfermedad:"Sindromes del maltrato"
  },
  {
     id:"T740",
     enfermedad:"Negligencia o abandono"
  },
  {
     id:"T741",
     enfermedad:"Abuso físico"
  },
  {
     id:"T742",
     enfermedad:"Abuso sexual"
  },
  {
     id:"T743",
     enfermedad:"Abuso psicologico"
  },
  {
     id:"T748",
     enfermedad:"Otros sindromes de maltrato"
  },
  {
     id:"T749",
     enfermedad:"Sindrome de maltrato, no especificado"
  },
  {
     id:"T75",
     enfermedad:"Efectos de otras causas externas"
  },
  {
     id:"T750",
     enfermedad:"Efectos del rayo"
  },
  {
     id:"T751",
     enfermedad:"Ahogamiento y sumersion no mortal"
  },
  {
     id:"T752",
     enfermedad:"Efectos de la vibracion"
  },
  {
     id:"T753",
     enfermedad:"Mal del movimiento"
  },
  {
     id:"T754",
     enfermedad:"Efectos de la corriente electrica"
  },
  {
     id:"T758",
     enfermedad:"Otros efectos especificados de causas externas"
  },
  {
     id:"T78",
     enfermedad:"Efectos adversos no clasificados en otra parte"
  },
  {
     id:"T780",
     enfermedad:"Choque anafilactico debido a reaccion adversa a alimentos"
  },
  {
     id:"T781",
     enfermedad:"Otra reaccion adversa a alimentos, no clasificada en otra parte"
  },
  {
     id:"T782",
     enfermedad:"Choque anafilactico, no especificado"
  },
  {
     id:"T783",
     enfermedad:"Edema angioneurotico"
  },
  {
     id:"T784",
     enfermedad:"Alergia no especificada"
  },
  {
     id:"T788",
     enfermedad:"Otros efectos adversos, no clasificados en otra parte"
  },
  {
     id:"T789",
     enfermedad:"Efectos adversos no especificado"
  },
  {
     id:"T79",
     enfermedad:"Algunas complicaciones precoces de traumatismos no clasificadas en otra parte"
  },
  {
     id:"T790",
     enfermedad:"Embolia gaseosa (traumatica)"
  },
  {
     id:"T791",
     enfermedad:"Embolia grasa (traumatica)"
  },
  {
     id:"T792",
     enfermedad:"Hemorragia traumatica secundaria y recurrente"
  },
  {
     id:"T793",
     enfermedad:"Infeccion postraumatica de herida, no clasificada en otra parte"
  },
  {
     id:"T794",
     enfermedad:"Choque traumatico"
  },
  {
     id:"T795",
     enfermedad:"Anuria traumatica"
  },
  {
     id:"T796",
     enfermedad:"Isquemia traumatica del musculo"
  },
  {
     id:"T797",
     enfermedad:"Enfisema subcutaneo traumatico"
  },
  {
     id:"T798",
     enfermedad:"Otras complicaciones precoces de los traumatismos"
  },
  {
     id:"T799",
     enfermedad:"Complicaciones precoces no especificadas de los traumatismos"
  },
  {
     id:"T80",
     enfermedad:"Complicaciones consecutivas a infusion, transfus e inyeccion terapeutica"
  },
  {
     id:"T800",
     enfermedad:"Embolia gaseosa consecutivas a infusion, transfusion e inyeccion terapeutica"
  },
  {
     id:"T801",
     enfermedad:"Complicaciones vasculares consecutivas a infusion, transfusion e inyeccion terapeutica"
  },
  {
     id:"T802",
     enfermedad:"Infecciones consecutivas a infusion, transfusion e inyeccion terapeutica"
  },
  {
     id:"T803",
     enfermedad:"Reaccion de incompatibilidad al grupo abo"
  },
  {
     id:"T804",
     enfermedad:"Reaccion de incompatibilidad a rh"
  },
  {
     id:"T805",
     enfermedad:"Choque anafilactico debido a suero"
  },
  {
     id:"T806",
     enfermedad:"Otras reacciones al suero"
  },
  {
     id:"T808",
     enfermedad:"Otras complicaciones consecutivas a infusion, transfusion e inyeccion terapeutica"
  },
  {
     id:"T809",
     enfermedad:"Complicaciones no especificadas consecutivas a infusion, transfusion e inyeccion terapeutica"
  },
  {
     id:"T81",
     enfermedad:"Complicaciones de procedimientos no clasificadas en otra parte"
  },
  {
     id:"T810",
     enfermedad:"Hemorragia y hematoma que complican un procedimiento, no clasificados en otra parte"
  },
  {
     id:"T811",
     enfermedad:"Choque durante o resultante de un procedimiento, no clasificado en otra parte"
  },
  {
     id:"T812",
     enfermedad:"Puncion o laceracion accidental durante un procedimiento, no clasificadas en otra parte"
  },
  {
     id:"T813",
     enfermedad:"Desgarro de herida operatoria, no clasificado en otra parte"
  },
  {
     id:"T814",
     enfermedad:"Infeccion consecutiva a procedimiento, no clasificada en otra parte"
  },
  {
     id:"T815",
     enfermedad:"Cuerpo extraño dejado accidentalmente en cavidad corporal o en herida operatoria consecutiva a procedimiento"
  },
  {
     id:"T816",
     enfermedad:"Reaccion aguda a sustancia extraña dejada accidentalmente durante un procedimiento"
  },
  {
     id:"T817",
     enfermedad:"Complicaciones vasculares consecutivas a procedimientos, no clasificadas en otra parte"
  },
  {
     id:"T818",
     enfermedad:"Otras complicaciones de procedimientos, no clasificadas en otra parte"
  },
  {
     id:"T819",
     enfermedad:"Complicaciones de procedimientos, no especificada"
  },
  {
     id:"T82",
     enfermedad:"Complicaciones de dispositivos protesicos, implantes e injertos cardiovasculares"
  },
  {
     id:"T820",
     enfermedad:"Complicacion mecanica de protesis de valvula cardiaca"
  },
  {
     id:"T821",
     enfermedad:"Complicacion mecanica de dispositivo electronico cardiaco"
  },
  {
     id:"T822",
     enfermedad:"Complicacion mecanica de derivacion de arteria coronaria e injerto vascular"
  },
  {
     id:"T823",
     enfermedad:"Complicacion mecanica de otros injertos vasculares"
  },
  {
     id:"T824",
     enfermedad:"Complicacion mecanica de cateter para dialisis vascular"
  },
  {
     id:"T825",
     enfermedad:"Complicacion mecanica de otros dispositivos e implantes cardiovasculares"
  },
  {
     id:"T826",
     enfermedad:"Infeccion y reaccion inflamatoria debidas a protesis de valvula cardiaca"
  },
  {
     id:"T827",
     enfermedad:"Infeccion y reaccion inflamatoria debidas a otros dispositivos, implantes e injertos cardiovasculares"
  },
  {
     id:"T828",
     enfermedad:"Otras complicaciones de dispositivos protesicos, implantes e injertos cardiovasculares"
  },
  {
     id:"T829",
     enfermedad:"Complicacion no especificada de dispositivo protesico, implante e injerto cardiovascular"
  },
  {
     id:"T83",
     enfermedad:"Complicaciones de dispositivos, implantes e injertos genitourinarios"
  },
  {
     id:"T830",
     enfermedad:"Complicacion mecanica de cateter urinario (fijo)"
  },
  {
     id:"T831",
     enfermedad:"Complicacion mecanica de otros dispositivos e implantes urinarios"
  },
  {
     id:"T832",
     enfermedad:"Complicacion mecanica de injerto en organo urinario"
  },
  {
     id:"T833",
     enfermedad:"Complicacion mecanica de dispositivo anticonceptivo intrauterino"
  },
  {
     id:"T834",
     enfermedad:"Complicacion mecanica de otros dispositivos, implantes e injertos en el tracto genital"
  },
  {
     id:"T835",
     enfermedad:"Infeccion y reaccion inflamatoria debidas a dispositivo protesico, implante e injerto en el sistema urinario"
  },
  {
     id:"T836",
     enfermedad:"Infeccion y reaccion inflamatoria debidas a dispositivo protesico, implante e injerto en el tracto genital"
  },
  {
     id:"T838",
     enfermedad:"Otras complicaciones de dispositivos protesicos, implantes e injertos genitourinarios:"
  },
  {
     id:"T839",
     enfermedad:"Complicacion no especificada de dispositivo protesico, implante e injerto genitourinario"
  },
  {
     id:"T84",
     enfermedad:"Complicaciones de dispositivos protesicos, implantes e injertos ortopedicos internos"
  },
  {
     id:"T840",
     enfermedad:"Complicacion mecanica de protesis articular interna"
  },
  {
     id:"T841",
     enfermedad:"Complicacion mecanica de dispositivo de fijacion interna de huesos de un miembro"
  },
  {
     id:"T842",
     enfermedad:"Complicacion mecanica de dispositivo de fijacion interna de otros huesos"
  },
  {
     id:"T843",
     enfermedad:"Complicacion mecanica de otros dispositivos oseos, implantes e injertos"
  },
  {
     id:"T844",
     enfermedad:"Complicacion mecanica de otros dispositivos protesicos, implantes e injertos ortopedicos internos"
  },
  {
     id:"T845",
     enfermedad:"Infeccion y reaccion inflamatoria debidas a protesis articular interna"
  },
  {
     id:"T846",
     enfermedad:"Infeccion y reaccion inflamatoria debidas a dispositivo de fijacion interna (cualquier sitio)"
  },
  {
     id:"T847",
     enfermedad:"Infeccion y reaccion inflamatoria debidas a otros dispositivo de fijacion interna (cualquier sitio) dispositivos protesicos, implantes e injertos ortopedicos internos"
  },
  {
     id:"T848",
     enfermedad:"Otras complicaciones de dispositivos protesicos, implantes e injertos ortopedicos internos"
  },
  {
     id:"T849",
     enfermedad:"Complicaciones no especificadas de dispositivos protesicos, implantes e injertos ortopedicos internos"
  },
  {
     id:"T85",
     enfermedad:"Complicaciones de dispositivos protesicos, implantes e injertos internos"
  },
  {
     id:"T850",
     enfermedad:"Complicacion mecanica de derivacion (anastomotica) ventricular intracraneal"
  },
  {
     id:"T851",
     enfermedad:"Complicacion mecanica de implante de estimulador electronico del sistema nervioso"
  },
  {
     id:"T852",
     enfermedad:"Complicacion mecanica de lentes intraoculares"
  },
  {
     id:"T853",
     enfermedad:"Complicacion mecanica de otros dispositivos protesicos, implantes e injertos oculares"
  },
  {
     id:"T854",
     enfermedad:"Complicacion mecanica de protesis e implante de mama"
  },
  {
     id:"T855",
     enfermedad:"Complicacion mecanica de dispositivo protesico, implante e injerto gastrointestinal"
  },
  {
     id:"T856",
     enfermedad:"Complicacion mecanica de otros dispositivos protesicos, implantes e injertos internos especificados"
  },
  {
     id:"T857",
     enfermedad:"Infeccion y reaccion inflamatoria debidas a otros dispositivos protesicos, implantes e injertos internos"
  },
  {
     id:"T858",
     enfermedad:"Otras complicaciones de otros dispositivos protesicos, implantes e injertos internos, no clasificados en otra parte"
  },
  {
     id:"T859",
     enfermedad:"Complicacion no especificada de dispositivo protesico, implante e injerto interno"
  },
  {
     id:"T86",
     enfermedad:"Falla y rechazo del trasplante de organos y tejidos"
  },
  {
     id:"T860",
     enfermedad:"Rechazo de trasplante de medula osea"
  },
  {
     id:"T861",
     enfermedad:"Falla y rechazo de trasplante de riñon"
  },
  {
     id:"T862",
     enfermedad:"Falla y rechazo de trasplante de corazon"
  },
  {
     id:"T863",
     enfermedad:"Falla y rechazo de trasplante de pulmon"
  },
  {
     id:"T864",
     enfermedad:"Falla y rechazo de trasplante de higado"
  },
  {
     id:"T868",
     enfermedad:"Falla y rechazo de otros organos y tejidos trasplantados"
  },
  {
     id:"T869",
     enfermedad:"Falla y rechazo del trasplante de organos y tejidos no especificado"
  },
  {
     id:"T87",
     enfermedad:"Complicaciones peculiares de la reinsersion y amputacion"
  },
  {
     id:"T870",
     enfermedad:"Complicaciones de la reinsercion (de parte) de extremidad superior"
  },
  {
     id:"T871",
     enfermedad:"Complicaciones de la reinsercion (de parte) de extremidad inferior"
  },
  {
     id:"T872",
     enfermedad:"Complicaciones de otras partes del cuerpo reinsertadas"
  },
  {
     id:"T873",
     enfermedad:"Neuroma de muñon de amputacion"
  },
  {
     id:"T874",
     enfermedad:"Infeccion de muñon de amputacion"
  },
  {
     id:"T875",
     enfermedad:"Necrosis de muñon de amputacion"
  },
  {
     id:"T876",
     enfermedad:"Otras complicaciones y las no especificadas de muñon de amputacion"
  },
  {
     id:"T88",
     enfermedad:"Otras complicaciones de la atención medica y quirurgica, no clasificadas en otra parte"
  },
  {
     id:"T880",
     enfermedad:"Infeccion consecutiva a inmunizacion"
  },
  {
     id:"T881",
     enfermedad:"Otras complicaciones consecutivas a inmunizacion, no clasificadas en otra parte"
  },
  {
     id:"T882",
     enfermedad:"Choque debida a anestesia"
  },
  {
     id:"T883",
     enfermedad:"Hipertermia maligna debida a anestesia"
  },
  {
     id:"T884",
     enfermedad:"Falla o dificultad de la intubacion"
  },
  {
     id:"T885",
     enfermedad:"Otras complicaciones de la anestesia"
  },
  {
     id:"T886",
     enfermedad:"Choque anafilactico debido a efecto adverso de droga o medicamento correcto administrado apropiadamente"
  },
  {
     id:"T887",
     enfermedad:"Efecto adverso no especificado de droga o medicamento"
  },
  {
     id:"T888",
     enfermedad:"Otras complicaciones especificadas de la atención medica y quirurgica, no clasificadas en otra parte"
  },
  {
     id:"T889",
     enfermedad:"Complicaciones no especificadas de la atención medica y quirurgica"
  },
  {
     id:"T90",
     enfermedad:"Secuelas de traumatismos de la cabeza"
  },
  {
     id:"T900",
     enfermedad:"Secuelas de traumatismo superficial de la cabeza"
  },
  {
     id:"T901",
     enfermedad:"Secuelas de herida de la cabeza"
  },
  {
     id:"T902",
     enfermedad:"Secuelas de fractura del craneo y de huesos faciales"
  },
  {
     id:"T903",
     enfermedad:"Secuelas de traumatismo de nervios craneales"
  },
  {
     id:"T904",
     enfermedad:"Secuelas de traumatismo del ojo y de la orbita"
  },
  {
     id:"T905",
     enfermedad:"Secuelas de traumatismo intracraneal"
  },
  {
     id:"T908",
     enfermedad:"Secuelas de otros traumatismos especificados de la cabeza"
  },
  {
     id:"T909",
     enfermedad:"Secuelas de traumatismo no especificado de la cabeza"
  },
  {
     id:"T91",
     enfermedad:"Secuelas de traumatismos del cuello y del tronco"
  },
  {
     id:"T910",
     enfermedad:"Secuelas de traumatismo superficial y heridas del cuello y del tronco"
  },
  {
     id:"T911",
     enfermedad:"Secuelas de fractura de la columna vertebral"
  },
  {
     id:"T912",
     enfermedad:"Secuelas de otra fractura del torax y de la pelvis"
  },
  {
     id:"T913",
     enfermedad:"Secuelas de traumatismo de la medula espinal"
  },
  {
     id:"T914",
     enfermedad:"Secuelas de traumatismo de organos intratoracicos"
  },
  {
     id:"T915",
     enfermedad:"Secuelas de traumatismo de organos intraabdominales y pelvicos"
  },
  {
     id:"T918",
     enfermedad:"Secuelas de otros traumatismos especificados del cuello y del tronco"
  },
  {
     id:"T919",
     enfermedad:"Secuelas de traumatismo no especificados del cuello y del tronco"
  },
  {
     id:"T92",
     enfermedad:"Secuelas de traumatismos de miembro superior"
  },
  {
     id:"T920",
     enfermedad:"Secuelas de herida de miembro superior"
  },
  {
     id:"T921",
     enfermedad:"Secuelas de fractura del brazo"
  },
  {
     id:"T922",
     enfermedad:"Secuelas de fractura de la muñeca y de la mano"
  },
  {
     id:"T923",
     enfermedad:"Secuelas de luxacion, torcedura y esguince de miembro superior"
  },
  {
     id:"T924",
     enfermedad:"Secuelas de traumatismo de nervio de miembro superior"
  },
  {
     id:"T925",
     enfermedad:"Secuelas de traumatismo de tendon y musculo de miembro superior"
  },
  {
     id:"T926",
     enfermedad:"Secuelas de aplastamiento y amputacion traumaticas de miembro superior"
  },
  {
     id:"T928",
     enfermedad:"Secuelas de otros traumatismos especificados de miembro superior"
  },
  {
     id:"T929",
     enfermedad:"Secuelas de traumatismo no especificado de miembro superior"
  },
  {
     id:"T93",
     enfermedad:"Secuelas de traumatismos de miembro inferior"
  },
  {
     id:"T930",
     enfermedad:"Secuelas de herida de miembro inferior"
  },
  {
     id:"T931",
     enfermedad:"Secuelas de fractura de femur"
  },
  {
     id:"T932",
     enfermedad:"Secuelas de otras fracturas de miembro inferior"
  },
  {
     id:"T933",
     enfermedad:"Secuelas de luxacion, torcedura y esguince de miembro inferior"
  },
  {
     id:"T934",
     enfermedad:"Secuelas de traumatismo de nervio de miembro inferior"
  },
  {
     id:"T935",
     enfermedad:"Secuelas de traumatismo de tendon y musculo de miembro inferior"
  },
  {
     id:"T936",
     enfermedad:"Secuelas de aplastamiento y amputacion traumaticas de miembro inferior"
  },
  {
     id:"T938",
     enfermedad:"Secuelas de otros traumatismos especificados de miembro inferior"
  },
  {
     id:"T939",
     enfermedad:"Secuelas de traumatismo no especificado de miembro inferior"
  },
  {
     id:"T94",
     enfermedad:"Secuelas de traumatismos que afectan multiples regiones del cuerpo y las no especificadas"
  },
  {
     id:"T940",
     enfermedad:"Secuelas de traumatismos que afectan multiples regiones del cuerpo"
  },
  {
     id:"T941",
     enfermedad:"Secuelas de traumatismos de regiones no especificadas del cuerpo"
  },
  {
     id:"T95",
     enfermedad:"Secuelas de quemaduras, corrosiones y congelamientos"
  },
  {
     id:"T950",
     enfermedad:"Secuelas de quemadura, corrosion y congelamiento de la cabeza y del cuello"
  },
  {
     id:"T951",
     enfermedad:"Secuelas de quemadura, corrosion y congelamiento del tronco"
  },
  {
     id:"T952",
     enfermedad:"Secuelas de quemadura, corrosion y congelamiento de miembro superior"
  },
  {
     id:"T953",
     enfermedad:"Secuelas de quemadura, corrosion y congelamiento de miembro inferior"
  },
  {
     id:"T954",
     enfermedad:"Secuelas de quemadura y corrosion clasificables solo de acuerdo con la extension de la superficie del cuerpo afectada"
  },
  {
     id:"T958",
     enfermedad:"Secuelas de otras quemaduras, corrosiones y congelamientos especificados"
  },
  {
     id:"T959",
     enfermedad:"Secuelas de quemadura, corrosion y congelamiento no especificados"
  },
  {
     id:"T96",
     enfermedad:"Secuelas de envenenamientos por drogas, medicamentos y sustancias biologicas"
  },
  {
     id:"T97",
     enfermedad:"Secuelas de efectos toxicos de sustancias de procedencia no medicinal"
  },
  {
     id:"T98",
     enfermedad:"Secuelas de otros efectos y los no especificados de causas externas"
  },
  {
     id:"T980",
     enfermedad:"Secuelas de efectos de cuerpos extraños que penetran en orificios naturales"
  },
  {
     id:"T981",
     enfermedad:"Secuelas de otros efectos y los no especificados de causas externas"
  },
  {
     id:"T982",
     enfermedad:"Secuelas de ciertas complicaciones precoces de los traumatismos"
  },
  {
     id:"T983",
     enfermedad:"Secuelas de complicaciones de la atención medica y quirurgica, no clasificadas en otra parte"
  },
  {
     id:"U04",
     enfermedad:"Sindrome respiratorio agudo severo (SARS)"
  },
  {
     id:"U049",
     enfermedad:"Sindrome respiratorio agudo severo, sin especificar"
  },
  {
     id:"U800",
     enfermedad:"Agente resistente a penicilina"
  },
  {
     id:"U801",
     enfermedad:"Agente resistente a meticilina"
  },
  {
     id:"U808",
     enfermedad:"Agente resistente a otros antibioticos relacionados con la penicilina"
  },
  {
     id:"U810",
     enfermedad:"Agente resistente a vancomicina"
  },
  {
     id:"U818",
     enfermedad:"Agente resistente a otros antibioticos relacionados con la vancomicina"
  },
  {
     id:"U88",
     enfermedad:"Agente resistente a multiples antibioticos"
  },
  {
     id:"U898",
     enfermedad:"Agente resistente a otro antibiotico especificado"
  },
  {
     id:"U899",
     enfermedad:"Agente resistente a antibiotico sin especificar"
  },
  {
     id:"V",
     enfermedad:"Trastornos mentales y del comportamiento"
  },
  {
     id:"V01",
     enfermedad:"Peaton lesionado por colision con vehiculo de pedal"
  },
  {
     id:"V010",
     enfermedad:"Peaton lesionado por colision con vehiculo de pedal: accidente no de transito"
  },
  {
     id:"V011",
     enfermedad:"Peaton lesionado por colision con vehiculo de pedal: accidente de transito"
  },
  {
     id:"V019",
     enfermedad:"Peaton lesionado por colision con vehiculo de pedal: accidente no especificado como de transito o no de transito"
  },
  {
     id:"V02",
     enfermedad:"Peaton lesionado por colision con vehiculo de dos o tres ruedas"
  },
  {
     id:"V020",
     enfermedad:"Peaton lesionado por colision con vehiculo de motor de dos o tres ruedas: accidente no de transito"
  },
  {
     id:"V021",
     enfermedad:"Peaton lesionado por colision con vehiculo de motor de dos o tres ruedas: accidente de transito"
  },
  {
     id:"V029",
     enfermedad:"Peaton lesionado por colision con vehiculo de motor de dos o tres ruedas: accidente no especificado como de transito o no de transito"
  },
  {
     id:"V03",
     enfermedad:"Peaton lesionado por colision con automovil camioneta furgoneta"
  },
  {
     id:"V030",
     enfermedad:"Peaton lesionado por colision con automovil, camioneta o furgoneta: accidente no de transito"
  },
  {
     id:"V031",
     enfermedad:"Peaton lesionado por colision con automovil, camioneta o furgoneta: accidente de transito"
  },
  {
     id:"V039",
     enfermedad:"Peaton lesionado por colision con automovil, camioneta o furgoneta: no especificado como de transito o no de transito"
  },
  {
     id:"V04",
     enfermedad:"Peaton lesionado por colision con vehiculo de transporte pesado o autobus"
  },
  {
     id:"V040",
     enfermedad:"Peaton lesionado por colision con vehiculo de transporte pesado o autobus: accidente no de transito"
  },
  {
     id:"V041",
     enfermedad:"Peaton lesionado por colision con vehiculo de transporte pesado o autobus: accidente de transito"
  },
  {
     id:"V049",
     enfermedad:"Peaton lesionado por colision con vehiculo de transporte pesado o autobus: accidente no especificado como de transito o no de transito"
  },
  {
     id:"V05",
     enfermedad:"Peaton lesionado por colision con tren o vehiculo de rieles"
  },
  {
     id:"V050",
     enfermedad:"Peaton lesionado por colision con tren o vehiculo de rieles: accidente no de transito"
  },
  {
     id:"V051",
     enfermedad:"Peaton lesionado por colision con tren o vehiculo de rieles: accidente de transito"
  },
  {
     id:"V059",
     enfermedad:"Peaton lesionado por colision con tren o vehiculo de rieles: accidente no especificado como de transito o no de transito"
  },
  {
     id:"V06",
     enfermedad:"Peaton lesionado por colision con otros vehiculo sin motor"
  },
  {
     id:"V060",
     enfermedad:"Peaton lesionado por colision con otros vehiculos sin motor: accidente no de transito"
  },
  {
     id:"V061",
     enfermedad:"Peaton lesionado por colision con otros vehiculos sin motor: accidente de transito"
  },
  {
     id:"V069",
     enfermedad:"Peaton lesionado por colision con otros vehiculos sin motor: accidente no especificado como de transito o no de transito"
  },
  {
     id:"V09",
     enfermedad:"Peaton lesionado en otros accidentes de transporte, y en los no especificados"
  },
  {
     id:"V090",
     enfermedad:"Peaton lesionado en accidente no de transito que involucra otros vehiculos de motor, y los no especificados"
  },
  {
     id:"V091",
     enfermedad:"Peaton lesionado en accidente no de transito no especificado"
  },
  {
     id:"V092",
     enfermedad:"Peaton lesionado en accidente de transito que involucra otros vehiculos de motor, y los no especificados"
  },
  {
     id:"V093",
     enfermedad:"Peaton lesionado en accidente de transito no especificado"
  },
  {
     id:"V099",
     enfermedad:"Peaton lesionado en accidente de transporte no especificado"
  },
  {
     id:"V10",
     enfermedad:"Ciclista lesionado por colision con peaton o animal"
  },
  {
     id:"V100",
     enfermedad:"Ciclista lesionado por colision con peaton o animal: conductor lesionado en accidente no de transito"
  },
  {
     id:"V101",
     enfermedad:"Ciclista lesionado por colision con peaton o animal: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V102",
     enfermedad:"Ciclista lesionado por colision con peaton o animal: ciclista no especificado, lesionado en accidente no de transito"
  },
  {
     id:"V103",
     enfermedad:"Ciclista lesionado por colision con peaton o animal: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V104",
     enfermedad:"Ciclista lesionado por colision con peaton o animal: conductor lesionado en accidente de transito"
  },
  {
     id:"V105",
     enfermedad:"Ciclista lesionado por colision con peaton o animal: pasajero lesionado en accidente de transito"
  },
  {
     id:"V109",
     enfermedad:"Ciclista lesionado por colision con peaton o animal: ciclista no especificado, lesionado en accidente de transito"
  },
  {
     id:"V11",
     enfermedad:"Ciclista lesionado por colision con otro ciclista"
  },
  {
     id:"V110",
     enfermedad:"Ciclista lesionado por colision con otro ciclista: conductor lesionado en accidente no de transito"
  },
  {
     id:"V111",
     enfermedad:"Ciclista lesionado por colision con otro ciclista: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V112",
     enfermedad:"Ciclista lesionado por colision con otro ciclista: no especificado, lesionado en accidente no de transito"
  },
  {
     id:"V113",
     enfermedad:"Ciclista lesionado por colision con otro ciclista: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V114",
     enfermedad:"Ciclista lesionado por colision con otro ciclista: conductor lesionado en accidente de transito"
  },
  {
     id:"V115",
     enfermedad:"Ciclista lesionado por colision con otro ciclista: pasajero lesionado en accidente de transito"
  },
  {
     id:"V119",
     enfermedad:"Ciclista lesionado por colision con otro ciclista: ciclista no especificado, lesionado en accidente de transito"
  },
  {
     id:"V12",
     enfermedad:"Ciclista lesionado por colision con vehiculo de dos o tre ruedas"
  },
  {
     id:"V120",
     enfermedad:"Ciclista lesionado por colision con vehiculo de motor de dos o tres ruedas: conductor lesionado en accidente no de transito"
  },
  {
     id:"V121",
     enfermedad:"Ciclista lesionado por colision con vehiculo de motor de dos o tres ruedas: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V122",
     enfermedad:"Ciclista lesionado por colision con vehiculo de motor de dos o tres ruedas: ciclista no especificado, lesionado en accidente no de transito"
  },
  {
     id:"V123",
     enfermedad:"Ciclista lesionado por colision con vehiculo de motor de dos o tres ruedas: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V124",
     enfermedad:"Ciclista lesionado por colision con vehiculo de motor de dos o tres ruedas: conductor lesionado en accidente de transito"
  },
  {
     id:"V125",
     enfermedad:"Ciclista lesionado por colision con vehiculo de motor de dos o tres ruedas: pasajero lesionado en accidente de transito"
  },
  {
     id:"V129",
     enfermedad:"Ciclista lesionado por colision con vehiculo de motor de dos o tres ruedas: ciclista no especificado, lesionado en accidente de transito"
  },
  {
     id:"V13",
     enfermedad:"Ciclista lesionado por colision con automovil, camioneta o furgoneta"
  },
  {
     id:"V130",
     enfermedad:"Ciclista lesionado por colision con automovil, camioneta o furgoneta: conductor lesionado en accidente no de transito"
  },
  {
     id:"V131",
     enfermedad:"Ciclista lesionado por colision con automovil, camioneta o furgoneta: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V132",
     enfermedad:"Ciclista lesionado por colision con automovil, camioneta o furgoneta: ciclista no especificado, lesionado en accidente no de transito"
  },
  {
     id:"V133",
     enfermedad:"Ciclista lesionado por colision con automovil, camioneta o furgoneta: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V134",
     enfermedad:"Ciclista lesionado por colision con automovil, camioneta o furgoneta: conductor lesionado en accidente de transito"
  },
  {
     id:"V135",
     enfermedad:"Ciclista lesionado por colision con automovil, camioneta o furgoneta: pasajero lesionado en accidente de transito"
  },
  {
     id:"V139",
     enfermedad:"Ciclista lesionado por colision con automovil, camioneta o furgoneta: ciclista no especificado, lesionado en accidente de transito"
  },
  {
     id:"V14",
     enfermedad:"Ciclista lesionado por colision con vehiculo de transporte pesado o bus"
  },
  {
     id:"V140",
     enfermedad:"Ciclista lesionado por colision con vehiculo de transporte pesado o autobus: conductor lesionado en accidente no de transito"
  },
  {
     id:"V141",
     enfermedad:"Ciclista lesionado por colision con vehiculo de transporte pesado o autobus: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V142",
     enfermedad:"Ciclista lesionado por colision con vehiculo de transporte pesado o autobus: ciclista no especificado, lesionado en accidente no de transito"
  },
  {
     id:"V143",
     enfermedad:"Ciclista lesionado por colision con vehiculo de transporte pesado o autobus: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V144",
     enfermedad:"Ciclista lesionado por colision con vehiculo de transporte pesado o autobus: conductor lesionado en accidente de transito"
  },
  {
     id:"V145",
     enfermedad:"Ciclista lesionado por colision con vehiculo de transporte pesado o autobus: pasajero lesionado en accidente de transito"
  },
  {
     id:"V149",
     enfermedad:"Ciclista lesionado por colision con vehiculo de transporte pesado o autobus: ciclista no especificado, lesionado en accidente de transito"
  },
  {
     id:"V15",
     enfermedad:"Ciclista lesionado por colision con tren o vehiculo de rieles"
  },
  {
     id:"V150",
     enfermedad:"Ciclista lesionado por colision con tren o vehiculo de rieles: conductor lesionado en accidente no de transito"
  },
  {
     id:"V151",
     enfermedad:"Ciclista lesionado por colision con tren o vehiculo de rieles: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V152",
     enfermedad:"Ciclista lesionado por colision con tren o vehiculo de rieles: ciclista no especificado, lesionado en accidente no de transito"
  },
  {
     id:"V153",
     enfermedad:"Ciclista lesionado por colision con tren o vehiculo de rieles: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V154",
     enfermedad:"Ciclista lesionado por colision con tren o vehiculo de rieles: conductor lesionado en accidente de transito"
  },
  {
     id:"V155",
     enfermedad:"Ciclista lesionado por colision con tren o vehiculo de rieles: pasajero lesionado en accidente de transito"
  },
  {
     id:"V159",
     enfermedad:"Ciclista lesionado por colision con tren o vehiculo de rieles: ciclista no especificado, lesionado en accidente de transito"
  },
  {
     id:"V16",
     enfermedad:"Ciclista lesionado por colision con otros vehiculo sin motor"
  },
  {
     id:"V160",
     enfermedad:"Ciclista lesionado por colision con otros vehiculos sin motor: conductor lesionado en accidente no de transito"
  },
  {
     id:"V161",
     enfermedad:"Ciclista lesionado por colision con otros vehiculos sin motor: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V162",
     enfermedad:"Ciclista lesionado por colision con otros vehiculos sin motor: ciclista no especificado, lesionado en accidente no de transito"
  },
  {
     id:"V163",
     enfermedad:"Ciclista lesionado por colision con otros vehiculos sin motor: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V164",
     enfermedad:"Ciclista lesionado por colision con otros vehiculos sin motor: conductor lesionado en accidente de transito"
  },
  {
     id:"V165",
     enfermedad:"Ciclista lesionado por colision con otros vehiculos sin motor: pasajero lesionado en accidente de transito"
  },
  {
     id:"V169",
     enfermedad:"Ciclista lesionado por colision con otros vehiculos sin motor: ciclista no especificado, lesionado en accidente de transito"
  },
  {
     id:"V17",
     enfermedad:"Ciclista lesionado por colision con objeto estacionado o fijo"
  },
  {
     id:"V170",
     enfermedad:"Ciclista lesionado por colision con objeto estacionado o fijo: conductor lesionado en accidente no de transito"
  },
  {
     id:"V171",
     enfermedad:"Ciclista lesionado por colision con objeto estacionado o fijo: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V172",
     enfermedad:"Ciclista lesionado por colision con objeto estacionado o fijo: ciclista no especificado, lesionado en accidente no de transito"
  },
  {
     id:"V173",
     enfermedad:"Ciclista lesionado por colision con objeto estacionado o fijo: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V174",
     enfermedad:"Ciclista lesionado por colision con objeto estacionado o fijo conductor lesionado en accidente de transito"
  },
  {
     id:"V175",
     enfermedad:"Ciclista lesionado por colision con objeto estacionado o fijo: pasajero lesionado en accidente de transito"
  },
  {
     id:"V179",
     enfermedad:"Ciclista lesionado por colision con objeto estacionado o fijo: ciclista no especificado, lesionado en accidente de transito"
  },
  {
     id:"V18",
     enfermedad:"Ciclista lesionado en accidente de transporte sin colision"
  },
  {
     id:"V180",
     enfermedad:"Ciclista lesionado en accidente de transporte sin colision: conductor lesionado en accidente no de transito"
  },
  {
     id:"V181",
     enfermedad:"Ciclista lesionado en accidente de transporte sin colision: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V182",
     enfermedad:"Ciclista lesionado en accidente de transporte sin colision: ciclista no especificado, lesionado en accidente no de transito"
  },
  {
     id:"V183",
     enfermedad:"Ciclista lesionado en accidente de transporte sin colision: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V184",
     enfermedad:"Ciclista lesionado en accidente de transporte sin colision: conductor lesionado en accidente de transito"
  },
  {
     id:"V185",
     enfermedad:"Ciclista lesionado en accidente de transporte sin colision: pasajero lesionado en accidente de transito"
  },
  {
     id:"V189",
     enfermedad:"Ciclista lesionado en accidente de transporte sin colision: ciclista no especificado, lesionado en accidente de transito"
  },
  {
     id:"V19",
     enfermedad:"Ciclista lesionado en otros accidentes de transporte y en los no especificados"
  },
  {
     id:"V190",
     enfermedad:"Conductor de vehiculo de pedal lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente no de transito"
  },
  {
     id:"V191",
     enfermedad:"Pasajero de vehiculo de pedal lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente no de transito"
  },
  {
     id:"V192",
     enfermedad:"Ciclista no especificado lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente no de transito"
  },
  {
     id:"V193",
     enfermedad:"Ciclista [cualquiera] lesionado en accidente no de transito, no especificado"
  },
  {
     id:"V194",
     enfermedad:"Conductor de vehiculo de pedal lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente de transito"
  },
  {
     id:"V195",
     enfermedad:"Pasajero de vehiculo de pedal lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente de transito"
  },
  {
     id:"V196",
     enfermedad:"Ciclista no especificado lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente de transito"
  },
  {
     id:"V198",
     enfermedad:"Ciclista [cualquiera] lesionado en otros accidentes de transporte especificados"
  },
  {
     id:"V199",
     enfermedad:"Ciclista [cualquiera] lesionado en accidente de transito no especificado"
  },
  {
     id:"V20",
     enfermedad:"Motociclista lesionado por colision con peaton o animal"
  },
  {
     id:"V200",
     enfermedad:"Motociclista lesionado por colision con peaton o animal: conductor lesionado en accidente no de transito"
  },
  {
     id:"V201",
     enfermedad:"Motociclista lesionado por colision con peaton o animal: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V202",
     enfermedad:"Motociclista lesionado por colision con peaton o animal: motociclista no especificado, lesionado en accidente no de transito"
  },
  {
     id:"V203",
     enfermedad:"Motociclista lesionado por colision con peaton o animal: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V204",
     enfermedad:"Motociclista lesionado por colision con peaton o animal: conductor lesionado en accidente de transito"
  },
  {
     id:"V205",
     enfermedad:"Motociclista lesionado por colision con peaton o animal: pasajero lesionado en accidente de transito"
  },
  {
     id:"V209",
     enfermedad:"Motociclista lesionado por colision con peaton o animal: motociclista no especificado, lesionado en accidente de transito"
  },
  {
     id:"V21",
     enfermedad:"Motociclista lesionado por colision con vehiculo de pedal"
  },
  {
     id:"V210",
     enfermedad:"Motociclista lesionado por colision con vehiculo de pedal: conductor lesionado en accidente no de transito"
  },
  {
     id:"V211",
     enfermedad:"Motociclista lesionado por colision con vehiculo de pedal: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V212",
     enfermedad:"Motociclista lesionado por colision con vehiculo de pedal: motociclista no especificado, lesionado en accidente no de transito"
  },
  {
     id:"V213",
     enfermedad:"Motociclista lesionado por colision con vehiculo de pedal: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V214",
     enfermedad:"Motociclista lesionado por colision con vehiculo de pedal: conductor lesionado en accidente de transito"
  },
  {
     id:"V215",
     enfermedad:"Motociclista lesionado por colision con vehiculo de pedal: pasajero lesionado en accidente de transito"
  },
  {
     id:"V219",
     enfermedad:"Motociclista lesionado por colision con vehiculo de pedal: motociclista no especificado, lesionado en accidente de transito"
  },
  {
     id:"V22",
     enfermedad:"Motociclista lesionado por colision con vehiculo de dos o tres ruedas"
  },
  {
     id:"V220",
     enfermedad:"Motociclista lesionado por colision con vehiculo de motor de dos o tres ruedas: conductor lesionado en accidente no de transito"
  },
  {
     id:"V221",
     enfermedad:"Motociclista lesionado por colision con vehiculo de motor de dos o tres ruedas: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V222",
     enfermedad:"Motociclista lesionado por colision con vehiculo de motor de dos o tres ruedas: motociclista no especificado, lesionado en accidente no de transito"
  },
  {
     id:"V223",
     enfermedad:"Motociclista lesionado por colision con vehiculo de motor de dos o tres ruedas: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V224",
     enfermedad:"Motociclista lesionado por colision con vehiculo de motor de dos o tres ruedas: conductor lesionado en accidente de transito"
  },
  {
     id:"V225",
     enfermedad:"Motociclista lesionado por colision con vehiculo de motor de dos o tres ruedas: pasajero lesionado en accidente de transito"
  },
  {
     id:"V229",
     enfermedad:"Motociclista lesionado por colision con vehiculo de motor de dos o tres ruedas: motociclista no especificado, lesionado en accidente de transito"
  },
  {
     id:"V23",
     enfermedad:"Motociclista lesionado por colision con automovil camioneta o furgonetaa"
  },
  {
     id:"V230",
     enfermedad:"Motociclista lesionado por colision con automovil, camioneta o furgoneta: conductor lesionado en accidente no de transito"
  },
  {
     id:"V231",
     enfermedad:"Motociclista lesionado por colision con automovil, camioneta o furgoneta: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V232",
     enfermedad:"Motociclista lesionado por colision con automovil, camioneta o furgoneta: motociclista no especificado, lesionado en accidente no de transito"
  },
  {
     id:"V233",
     enfermedad:"Motociclista lesionado por colision con automovil, camioneta o furgoneta: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V234",
     enfermedad:"Motociclista lesionado por colision con automovil, camioneta o furgoneta: conductor lesionado en accidente de transito"
  },
  {
     id:"V235",
     enfermedad:"Motociclista lesionado por colision con automovil, camioneta o furgoneta: pasajero lesionado en accidente de transito"
  },
  {
     id:"V239",
     enfermedad:"Motociclista lesionado por colision con automovil, camioneta o furgoneta: motociclista no especificado, lesionado en accidente de transito"
  },
  {
     id:"V24",
     enfermedad:"Motociclista lesionado por colision con vehiculo de transporte pesado o bus"
  },
  {
     id:"V240",
     enfermedad:"Motociclista lesionado por colision con vehiculo de transporte pesado o autobus: conductor lesionado en accidente no de transito"
  },
  {
     id:"V241",
     enfermedad:"Motociclista lesionado por colision con vehiculo de transporte pesado o autobus: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V242",
     enfermedad:"Motociclista lesionado por colision con vehiculo de transporte pesado o autobus: motociclista no especificado, lesionado en accidente no de transito"
  },
  {
     id:"V243",
     enfermedad:"Motociclista lesionado por colision con vehiculo de transporte pesado o autobus: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V244",
     enfermedad:"Motociclista lesionado por colision con vehiculo de transporte pesado o autobus: conductor lesionado en accidente de transito"
  },
  {
     id:"V245",
     enfermedad:"Motociclista lesionado por colision con vehiculo de transporte pesado o autobus: pasajero lesionado en accidente de transito"
  },
  {
     id:"V249",
     enfermedad:"Motociclista lesionado por colision con vehiculo de transporte pesado o autobus: motociclista no especificado, lesionado en accidente de transito"
  },
  {
     id:"V25",
     enfermedad:"Motociclista lesionado por colision con tren o vehiculo de rieles"
  },
  {
     id:"V250",
     enfermedad:"Motociclista lesionado por colision con tren o vehiculo de rieles: conductor lesionado en accidente no de transito"
  },
  {
     id:"V251",
     enfermedad:"Motociclista lesionado por colision con tren o vehiculo de rieles: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V252",
     enfermedad:"Motociclista lesionado por colision con tren o vehiculo de rieles: motociclista no especificado, lesionado en accidente no de transito"
  },
  {
     id:"V253",
     enfermedad:"Motociclista lesionado por colision con tren o vehiculo de rieles: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V254",
     enfermedad:"Motociclista lesionado por colision con tren o vehiculo de rieles: conductor lesionado en accidente de transito"
  },
  {
     id:"V255",
     enfermedad:"Motociclista lesionado por colision con tren o vehiculo de rieles: pasajero lesionado en accidente de transito"
  },
  {
     id:"V259",
     enfermedad:"Motociclista lesionado por colision con tren o vehiculo de rieles: motociclista no especificado, lesionado en accidente de transito"
  },
  {
     id:"V26",
     enfermedad:"Motociclista lesionado por colision con otros vehiculo sin motor"
  },
  {
     id:"V260",
     enfermedad:"Motociclista lesionado por colision con otros vehiculos sin motor: conductor lesionado en accidente no de transito"
  },
  {
     id:"V261",
     enfermedad:"Motociclista lesionado por colision con otros vehiculos sin motor: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V262",
     enfermedad:"Motociclista lesionado por colision con otros vehiculos sin motor: motociclista no especificado, lesionado en accidente no de transito"
  },
  {
     id:"V263",
     enfermedad:"Motociclista lesionado por colision con otros vehiculos sin motor: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V264",
     enfermedad:"Motociclista lesionado por colision con otros vehiculos sin motor: conductor lesionado en accidente de transito"
  },
  {
     id:"V265",
     enfermedad:"Motociclista lesionado por colision con otros vehiculos sin motor: pasajero lesionado en accidente de transito"
  },
  {
     id:"V269",
     enfermedad:"Motociclista lesionado por colision con otros vehiculos sin motor: motociclista no especificado, lesionado en accidente de transito"
  },
  {
     id:"V27",
     enfermedad:"Motociclista lesionado por colision con objeto fijo o estacionado"
  },
  {
     id:"V270",
     enfermedad:"Motociclista lesionado por colision con objeto fijo o estacionado: conductor lesionado en accidente no de transito"
  },
  {
     id:"V271",
     enfermedad:"Motociclista lesionado por colision con objeto fijo o estacionado: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V272",
     enfermedad:"Motociclista lesionado por colision con objeto fijo o estacionado: motociclista no especificado, lesionado en accidente no de transito"
  },
  {
     id:"V273",
     enfermedad:"Motociclista lesionado por colision con objeto fijo o estacionado: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V274",
     enfermedad:"Motociclista lesionado por colision con objeto fijo o estacionado: conductor lesionado en accidente de transito"
  },
  {
     id:"V275",
     enfermedad:"Motociclista lesionado por colision con objeto fijo o estacionado: pasajero lesionado en accidente de transito"
  },
  {
     id:"V279",
     enfermedad:"Motociclista lesionado por colision con objeto fijo o estacionado: motociclista no especificado, lesionado en accidente de transito"
  },
  {
     id:"V28",
     enfermedad:"Motociclista lesionado accidente transporte sin colision"
  },
  {
     id:"V280",
     enfermedad:"Motociclista lesionado en accidente de transporte sin colision: conductor lesionado en accidente no de transito"
  },
  {
     id:"V281",
     enfermedad:"Motociclista lesionado en accidente de transporte sin colision: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V282",
     enfermedad:"Motociclista lesionado en accidente de transporte sin colision: motociclista no especificado, lesionado en accidente no de transito"
  },
  {
     id:"V283",
     enfermedad:"Motociclista lesionado en accidente de transporte sin colision: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V284",
     enfermedad:"Motociclista lesionado en accidente de transporte sin colision: conductor lesionado en accidente de transito"
  },
  {
     id:"V285",
     enfermedad:"Motociclista lesionado en accidente de transporte sin colision: pasajero lesionado en accidente de transito"
  },
  {
     id:"V289",
     enfermedad:"Motociclista lesionado en accidente de transporte sin colision: motociclista no especificado, lesionado en accidente de transito"
  },
  {
     id:"V29",
     enfermedad:"Motociclista lesionado en otros accidentes de transporte y en los no especificados"
  },
  {
     id:"V290",
     enfermedad:"Conductor de motocicleta lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente no de transito"
  },
  {
     id:"V291",
     enfermedad:"Pasajero de motocicleta lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente no de transito"
  },
  {
     id:"V292",
     enfermedad:"Motociclista no especificado lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente no de transito"
  },
  {
     id:"V293",
     enfermedad:"Motociclista [cualquiera] lesionado en accidente no de transito, no especificado"
  },
  {
     id:"V294",
     enfermedad:"Conductor de motocicleta lesionado por colision con otros vehiculo de motor, y con los no especificados, en accidente de transito"
  },
  {
     id:"V295",
     enfermedad:"Pasajero de motocicleta lesionado por colision con otros vehiculo de motor, y con los no especificados, en accidente de transito"
  },
  {
     id:"V296",
     enfermedad:"Motociclista no especificado lesionado por colision con otros vehiculo de motor, y con los no especificados, en accidente de transito"
  },
  {
     id:"V298",
     enfermedad:"Motociclista [cualquiera] lesionado en otros accidentes de transporte especificados"
  },
  {
     id:"V299",
     enfermedad:"Motociclista [cualquiera] lesionado en accidente de transito no especificado"
  },
  {
     id:"V30",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con peaton o animal"
  },
  {
     id:"V300",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con peaton o animal: conductor lesionado en accidente no de transito"
  },
  {
     id:"V301",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con peaton o animal: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V302",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con peaton o animal: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V303",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con peaton o animal: ocupante no especificado de vehiculo de motor de tres ruedas, lesionado en accidente no de transito"
  },
  {
     id:"V304",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con peaton o animal: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V305",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con peaton o animal: conductor lesionado en accidente de transito"
  },
  {
     id:"V306",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con peaton o animal: pasajero lesionado en accidente de transito"
  },
  {
     id:"V307",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con peaton o animal: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V309",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con peaton o animal: ocupante no especificado de vehiculo de motor de tres ruedas, lesionado en accidente de transito"
  },
  {
     id:"V31",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con vehiculo de pedal"
  },
  {
     id:"V310",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con vehiculo de pedal: conductor lesionado en accidente no de transito"
  },
  {
     id:"V311",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con vehiculo de pedal: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V312",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con vehiculo de pedal: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V313",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con vehiculo de pedal: ocupante no especificado de vehiculo de motor de tres ruedas, lesionado en accidente no de transito"
  },
  {
     id:"V314",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con vehiculo de pedal: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V315",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con vehiculo de pedal: conductor lesionado en accidente de transito"
  },
  {
     id:"V316",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con vehiculo de pedal: pasajero lesionado en accidente de transito"
  },
  {
     id:"V317",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con vehiculo de pedal: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V319",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con vehiculo de pedal: ocupante no especificado de vehiculo de motor de tres ruedas, lesionado en accidente de transito"
  },
  {
     id:"V32",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con vehiculo de motor de dos o tres ruedas"
  },
  {
     id:"V320",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con otro vehiculo de motor de dos o tres ruedas: conductor lesionado en accidente no de transito"
  },
  {
     id:"V321",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con otro vehiculo de motor de dos o tres ruedas: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V322",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con otro vehiculo de motor de dos o tres ruedas: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V323",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con otro vehiculo de motor de dos o tres ruedas: ocupante no especificado de vehiculo de motor de tres ruedas, lesionado en accidente no de transito"
  },
  {
     id:"V324",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con otro vehiculo de motor de dos o tres ruedas: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V325",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con otro vehiculo de motor de dos o tres ruedas: conductor lesionado en accidente de transito"
  },
  {
     id:"V326",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con otro vehiculo de motor de dos o tres ruedas: pasajero lesionado en accidente de transito"
  },
  {
     id:"V327",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con otro vehiculo de motor de dos o tres ruedas: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V329",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con otro vehiculo de motor de dos o tres ruedas: ocupante no especificado de vehiculo de motor de tres ruedas, lesionado en accidente de transito"
  },
  {
     id:"V33",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con automovil, camioneta o furgoneta"
  },
  {
     id:"V330",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con automovil, camioneta o furgoneta: conductor lesionado en accidente no de transito"
  },
  {
     id:"V331",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con automovil, camioneta o furgoneta: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V332",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con automovil, camioneta o furgoneta: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V333",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con automovil, camioneta o furgoneta: ocupante no especificado de vehiculo de motor de tres ruedas, lesionado en accidente no de transito"
  },
  {
     id:"V334",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con automovil, camioneta o furgoneta: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V335",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con automovil, camioneta o furgoneta: conductor lesionado en accidente de transito"
  },
  {
     id:"V336",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con automovil, camioneta o furgoneta: pasajero lesionado en accidente de transito"
  },
  {
     id:"V337",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con automovil, camioneta o furgoneta: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V339",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con automovil, camioneta o furgoneta: ocupante no especificado de vehiculo de motor de tres ruedas, lesionado en accidente de transito"
  },
  {
     id:"V34",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con vehiculo de transporte pesado o autobus"
  },
  {
     id:"V340",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con vehiculo de transporte pesado o autobus: conductor lesionado en accidente no de transito"
  },
  {
     id:"V341",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con vehiculo de transporte pesado o autobus: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V342",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con vehiculo de transporte pesado o autobus: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V343",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con vehiculo de transporte pesado o autobus: ocupante no especificado de vehiculo de motor de tres ruedas, lesionado en accidente no de transito"
  },
  {
     id:"V344",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con vehiculo de transporte pesado o autobus: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V345",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con vehiculo de transporte pesado o autobus: conductor lesionado en accidente de transito"
  },
  {
     id:"V346",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con vehiculo de transporte pesado o autobus: pasajero lesionado en accidente de transito"
  },
  {
     id:"V347",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con vehiculo de transporte pesado o autobus: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V349",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con vehiculo de transporte pesado o autobus: ocupante no especificado de vehiculo de motor de tres ruedas, lesionado en accidente de transito"
  },
  {
     id:"V35",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con tren o vehiculo de rieles"
  },
  {
     id:"V350",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con tren o vehiculo de rieles: conductor lesionado en accidente no de transito"
  },
  {
     id:"V351",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con tren o vehiculo de rieles: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V352",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con tren o vehiculo de rieles: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V353",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con tren o vehiculo de rieles: ocupante no especificado de vehiculo de motor de tres ruedas, lesionado en accidente no de transito"
  },
  {
     id:"V354",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con tren o vehiculo de rieles: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V355",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con tren o vehiculo de rieles: conductor lesionado en accidente de transito"
  },
  {
     id:"V356",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con tren o vehiculo de rieles: pasajero lesionado en accidente de transito"
  },
  {
     id:"V357",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con tren o vehiculo de rieles: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V359",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con tren o vehiculo de rieles: ocupante no especificado de vehiculo de motor de tres ruedas, lesionado en accidente de transito"
  },
  {
     id:"V36",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas por colision con otro vehiculo sin motor"
  },
  {
     id:"V360",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con otros vehiculos sin motor: conductor lesionado en accidente no de transito"
  },
  {
     id:"V361",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con otros vehiculos sin motor: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V362",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con otros vehiculos sin motor: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V363",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con otros vehiculos sin motor: ocupante no especificado de vehiculo de motor de tres ruedas, lesionado en accidente no de transito"
  },
  {
     id:"V364",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con otros vehiculos sin motor: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V365",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con otros vehiculos sin motor: conductor lesionado en accidente de transito"
  },
  {
     id:"V366",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con otros vehiculos sin motor: pasajero lesionado en accidente de transito"
  },
  {
     id:"V367",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con otros vehiculos sin motor: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V369",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con otros vehiculos sin motor: ocupante no especificado de vehiculo de motor de tres ruedas, lesionado en accidente de transito"
  },
  {
     id:"V37",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con objeto fijo o estacionado"
  },
  {
     id:"V370",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con objeto fijo o estacionado: conductor lesionado en accidente no de transito"
  },
  {
     id:"V371",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con objeto fijo o estacionado: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V372",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con objeto fijo o estacionado: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V373",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con objeto fijo o estacionado: ocupante no especificado de vehiculo de motor de tres ruedas, lesionado en accidente no de transito"
  },
  {
     id:"V374",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con objeto fijo o estacionado: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V375",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con objeto fijo o estacionado: conductor lesionado en accidente de transito"
  },
  {
     id:"V376",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con objeto fijo o estacionado: pasajero lesionado en accidente de transito"
  },
  {
     id:"V377",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con objeto fijo o estacionado: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V379",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado por colision con objeto fijo o estacionado: ocupante no especificado de vehiculo de motor de tres ruedas, lesionado en accidente de transito"
  },
  {
     id:"V38",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado en accidente de transito sin colision"
  },
  {
     id:"V380",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado en accidente de transporte sin colision: conductor lesionado en accidente no de transito"
  },
  {
     id:"V381",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado en accidente de transporte sin colision: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V382",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado en accidente de transporte sin colision: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V383",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado en accidente de transporte sin colision: ocupante no especificado de vehiculo de motor de tres ruedas, lesionado en accidente no de transito"
  },
  {
     id:"V384",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado en accidente de transporte sin colision: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V385",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado en accidente de transporte sin colision: conductor lesionado en accidente de transito"
  },
  {
     id:"V386",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado en accidente de transporte sin colision: pasajero lesionado en accidente de transito"
  },
  {
     id:"V387",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado en accidente de transporte sin colision: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V389",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado en accidente de transporte sin colision: ocupante no especificado de vehiculo de motor de tres ruedas, lesionado en accidente de transito"
  },
  {
     id:"V39",
     enfermedad:"Ocupante de vehiculo de motor de tres ruedas lesionado en otros accidentes y en los no especificados"
  },
  {
     id:"V390",
     enfermedad:"Conductor de vehiculo de motor de tres ruedas lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente no de transito"
  },
  {
     id:"V391",
     enfermedad:"Pasajero de vehiculo de motor de tres ruedas lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente no de transito"
  },
  {
     id:"V392",
     enfermedad:"Ocupante no especificado de vehiculo de motor de tres ruedas lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente no de transito"
  },
  {
     id:"V393",
     enfermedad:"Ocupante [cualquiera] de vehiculo de motor de tres ruedas lesionado en accidente no de transito, no especificado"
  },
  {
     id:"V394",
     enfermedad:"Conductor de vehiculo de motor de tres ruedas lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente de transito"
  },
  {
     id:"V395",
     enfermedad:"Pasajero de vehiculo de motor de tres ruedas lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente de transito"
  },
  {
     id:"V396",
     enfermedad:"Ocupante no especificado de vehiculo de motor de tres ruedas lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente de transito"
  },
  {
     id:"V398",
     enfermedad:"Ocupante [cualquiera] de vehiculo de motor de tres ruedas lesionado en otros accidentes de transporte especificados"
  },
  {
     id:"V399",
     enfermedad:"Ocupante [cualquiera] de vehiculo de motor de tres ruedas lesionado en accidente de transito, no especificado"
  },
  {
     id:"V40",
     enfermedad:"Ocupante de automovil lesionado por colision con peaton o animal"
  },
  {
     id:"V400",
     enfermedad:"Ocupante de automovil lesionado por colision con peaton o animal: conductor lesionado en accidente no de transito"
  },
  {
     id:"V401",
     enfermedad:"Ocupante de automovil lesionado por colision con peaton o animal: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V402",
     enfermedad:"Ocupante de automovil lesionado por colision con peaton o animal: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V403",
     enfermedad:"Ocupante de automovil lesionado por colision con peaton o animal: ocupante no especificado de automovil, lesionado en accidente no de transito"
  },
  {
     id:"V404",
     enfermedad:"Ocupante de automovil lesionado por colision con peaton o animal: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V405",
     enfermedad:"Ocupante de automovil lesionado por colision con peaton o animal: conductor lesionado en accidente de transito"
  },
  {
     id:"V406",
     enfermedad:"Ocupante de automovil lesionado por colision con peaton o animal: pasajero lesionado en accidente de transito"
  },
  {
     id:"V407",
     enfermedad:"Ocupante de automovil lesionado por colision con peaton o animal: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V409",
     enfermedad:"Ocupante de automovil lesionado por colision con peaton o animal: ocupante no especificado de automovil, lesionado en accidente de transito"
  },
  {
     id:"V41",
     enfermedad:"Ocupante de automovil lesionado por colision con vehiculo de pedal"
  },
  {
     id:"V410",
     enfermedad:"Ocupante de automovil lesionado por colision con vehiculo de pedal: conductor lesionado en accidente no de transito"
  },
  {
     id:"V411",
     enfermedad:"Ocupante de automovil lesionado por colision con vehiculo de pedal: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V412",
     enfermedad:"Ocupante de automovil lesionado por colision con vehiculo de pedal: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V413",
     enfermedad:"Ocupante de automovil lesionado por colision con vehiculo de pedal: ocupante no especificado de automovil, lesionado en accidente no de transito"
  },
  {
     id:"V414",
     enfermedad:"Ocupante de automovil lesionado por colision con vehiculo de pedal: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V415",
     enfermedad:"Ocupante de automovil lesionado por colision con vehiculo de pedal: conductor lesionado en accidente de transito"
  },
  {
     id:"V416",
     enfermedad:"Ocupante de automovil lesionado por colision con vehiculo de pedal: pasajero lesionado en accidente de transito"
  },
  {
     id:"V417",
     enfermedad:"Ocupante de automovil lesionado por colision con vehiculo de pedal: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V419",
     enfermedad:"Ocupante de automovil lesionado por colision con vehiculo de pedal: ocupante no especificado de automovil, lesionado en accidente de transito"
  },
  {
     id:"V42",
     enfermedad:"Ocupante de automovil lesionado por colision con vehiculo de motor de dos o tres ruedas"
  },
  {
     id:"V420",
     enfermedad:"Ocupante de automovil lesionado por colision con vehiculo de motor de dos o tres ruedas: conductor lesionado en accidente no de transito"
  },
  {
     id:"V421",
     enfermedad:"Ocupante de automovil lesionado por colision con vehiculo de motor de dos o tres ruedas: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V422",
     enfermedad:"Ocupante de automovil lesionado por colision con vehiculo de motor de dos o tres ruedas: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V423",
     enfermedad:"Ocupante de automovil lesionado por colision con vehiculo de motor de dos o tres ruedas: ocupante no especificado de automovil, lesionado en accidente no de transito"
  },
  {
     id:"V424",
     enfermedad:"Ocupante de automovil lesionado por colision con vehiculo de motor de dos o tres ruedas: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V425",
     enfermedad:"Ocupante de automovil lesionado por colision con vehiculo de motor de dos o tres ruedas: conductor lesionado en accidente de transito"
  },
  {
     id:"V426",
     enfermedad:"Ocupante de automovil lesionado por colision con vehiculo de motor de dos o tres ruedas: pasajero lesionado en accidente de transito"
  },
  {
     id:"V427",
     enfermedad:"Ocupante de automovil lesionado por colision con vehiculo de motor de dos o tres ruedas: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V429",
     enfermedad:"Ocupante de automovil lesionado por colision con vehiculo de motor de dos o tres ruedas: ocupante no especificado de automovil, lesionado en accidente de transito"
  },
  {
     id:"V43",
     enfermedad:"Ocupante de automovil lesionado por colision con auto camioneta o furgoneta"
  },
  {
     id:"V430",
     enfermedad:"Ocupante de automovil lesionado por colision con otro automovil, camioneta o furgoneta: conductor lesionado en accidente no de transito"
  },
  {
     id:"V431",
     enfermedad:"Ocupante de automovil lesionado por colision con otro automovil, camioneta o furgoneta: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V432",
     enfermedad:"Ocupante de automovil lesionado por colision con otro automovil, camioneta o furgoneta: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V433",
     enfermedad:"Ocupante de automovil lesionado por colision con otro automovil, camioneta o furgoneta: ocupante no especificado de automovil, lesionado en accidente no de transito"
  },
  {
     id:"V434",
     enfermedad:"Ocupante de automovil lesionado por colision con otro automovil, camioneta o furgoneta: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V435",
     enfermedad:"Ocupante de automovil lesionado por colision con otro automovil, camioneta o furgoneta: conductor lesionado en accidente de transito"
  },
  {
     id:"V436",
     enfermedad:"Ocupante de automovil lesionado por colision con otro automovil, camioneta o furgoneta: pasajero lesionado en accidente de transito"
  },
  {
     id:"V437",
     enfermedad:"Ocupante de automovil lesionado por colision con otro automovil, camioneta o furgoneta: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V439",
     enfermedad:"Ocupante de automovil lesionado por colision con otro automovil, camioneta o furgoneta: ocupante no especificado de automovil, lesionado en accidente de transito"
  },
  {
     id:"V44",
     enfermedad:"Ocupante de automovil lesionado por colision con vehiculo de transporte pesado o autobus"
  },
  {
     id:"V440",
     enfermedad:"Ocupante de automovil lesionado por colision con vehiculo de transporte pesado o autobus: conductor lesionado en accidente no de transito"
  },
  {
     id:"V441",
     enfermedad:"Ocupante de automovil lesionado por colision con vehiculo de transporte pesado o autobus: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V442",
     enfermedad:"Ocupante de automovil lesionado por colision con vehiculo de transporte pesado o autobus: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V443",
     enfermedad:"Ocupante de automovil lesionado por colision con vehiculo de transporte pesado o autobus: ocupante no especificado de automovil, lesionado en accidente no de transito"
  },
  {
     id:"V444",
     enfermedad:"Ocupante de automovil lesionado por colision con vehiculo de transporte pesado o autobus: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V445",
     enfermedad:"Ocupante de automovil lesionado por colision con vehiculo de transporte pesado o autobus: conductor lesionado en accidente de transito"
  },
  {
     id:"V446",
     enfermedad:"Ocupante de automovil lesionado por colision con vehiculo de transporte pesado o autobus: pasajero lesionado en accidente de transito"
  },
  {
     id:"V447",
     enfermedad:"Ocupante de automovil lesionado por colision con vehiculo de transporte pesado o autobus: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V449",
     enfermedad:"Ocupante de automovil lesionado por colision con vehiculo de transporte pesado o autobus: ocupante no especificado de automovil, lesionado en accidente de transito"
  },
  {
     id:"V45",
     enfermedad:"Ocupante de automovil lesionado por colision con tren o vehiculo de rieles"
  },
  {
     id:"V450",
     enfermedad:"Ocupante de automovil lesionado por colision con tren o vehiculo de rieles: conductor lesionado en accidente no de transito"
  },
  {
     id:"V451",
     enfermedad:"Ocupante de automovil lesionado por colision con tren o vehiculo de rieles: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V452",
     enfermedad:"Ocupante de automovil lesionado por colision con tren o vehiculo de rieles: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V453",
     enfermedad:"Ocupante de automovil lesionado por colision con tren o vehiculo de rieles: ocupante no especificado de automovil, lesionado en accidente no de transito"
  },
  {
     id:"V454",
     enfermedad:"Ocupante de automovil lesionado por colision con tren o vehiculo de rieles: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V455",
     enfermedad:"Ocupante de automovil lesionado por colision con tren o vehiculo de rieles: conductor lesionado en accidente de transito"
  },
  {
     id:"V456",
     enfermedad:"Ocupante de automovil lesionado por colision con tren o vehiculo de rieles: pasajero lesionado en accidente de transito"
  },
  {
     id:"V457",
     enfermedad:"Ocupante de automovil lesionado por colision con tren o vehiculo de rieles: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V459",
     enfermedad:"Ocupante de automovil lesionado por colision con tren o vehiculo de rieles: ocupante no especificado de automovil, lesionado en accidente de transito"
  },
  {
     id:"V46",
     enfermedad:"Ocupante de automovil lesionado por colision con otro vehiculo sin motor"
  },
  {
     id:"V460",
     enfermedad:"Ocupante de automovil lesionado por colision con otros vehiculos sin motor: conductor lesionado en accidente no de transito"
  },
  {
     id:"V461",
     enfermedad:"Ocupante de automovil lesionado por colision con otros vehiculos sin motor: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V462",
     enfermedad:"Ocupante de automovil lesionado por colision con otros vehiculos sin motor: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V463",
     enfermedad:"Ocupante de automovil lesionado por colision con otros vehiculos sin motor: ocupante no especificado de automovil, lesionado en accidente no de transito"
  },
  {
     id:"V464",
     enfermedad:"Ocupante de automovil lesionado por colision con otros vehiculos sin motor: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V465",
     enfermedad:"Ocupante de automovil lesionado por colision con otros vehiculos sin motor: conductor lesionado en accidente de transito"
  },
  {
     id:"V466",
     enfermedad:"Ocupante de automovil lesionado por colision con otros vehiculos sin motor: pasajero lesionado en accidente de transito"
  },
  {
     id:"V467",
     enfermedad:"Ocupante de automovil lesionado por colision con otros vehiculos sin motor: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V469",
     enfermedad:"Ocupante de automovil lesionado por colision con otros vehiculos sin motor: ocupante no especificado de automovil, lesionado en accidente de transito"
  },
  {
     id:"V47",
     enfermedad:"Ocupante de automovil lesionado por colision con objeto fijo o estacionado"
  },
  {
     id:"V470",
     enfermedad:"Ocupante de automovil lesionado por colision con objeto fijo o estacionado: conductor lesionado en accidente no de transito"
  },
  {
     id:"V471",
     enfermedad:"Ocupante de automovil lesionado por colision con objeto fijo o estacionado: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V472",
     enfermedad:"Ocupante de automovil lesionado por colision con objeto fijo o estacionado: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V473",
     enfermedad:"Ocupante de automovil lesionado por colision con objeto fijo o estacionado: ocupante no especificado de automovil, lesionado en accidente no de transito"
  },
  {
     id:"V474",
     enfermedad:"Ocupante de automovil lesionado por colision con objeto fijo o estacionado: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V475",
     enfermedad:"Ocupante de automovil lesionado por colision con objeto fijo o estacionado: conductor lesionado en accidente de transito"
  },
  {
     id:"V476",
     enfermedad:"Ocupante de automovil lesionado por colision con objeto fijo o estacionado: pasajero lesionado en accidente de transito"
  },
  {
     id:"V477",
     enfermedad:"Ocupante de automovil lesionado por colision con objeto fijo o estacionado: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V479",
     enfermedad:"Ocupante de automovil lesionado por colision con objeto fijo o estacionado: ocupante no especificado de automovil, lesionado en accidente de transito"
  },
  {
     id:"V48",
     enfermedad:"Ocupante de automovil lesionado en accidente de transporte sin colision"
  },
  {
     id:"V480",
     enfermedad:"Ocupante de automovil lesionado en accidente de transporte sin colision: conductor lesionado en accidente no de transito"
  },
  {
     id:"V481",
     enfermedad:"Ocupante de automovil lesionado en accidente de transporte sin colision: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V482",
     enfermedad:"Ocupante de automovil lesionado en accidente de transporte sin colision: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V483",
     enfermedad:"Ocupante de automovil lesionado en accidente de transporte sin colision: ocupante no especificado de automovil, lesionado en accidente no de transito"
  },
  {
     id:"V484",
     enfermedad:"Ocupante de automovil lesionado en accidente de transporte sin colision: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V485",
     enfermedad:"Ocupante de automovil lesionado en accidente de transporte sin colision: conductor lesionado en accidente de transito"
  },
  {
     id:"V486",
     enfermedad:"Ocupante de automovil lesionado en accidente de transporte sin colision: pasajero lesionado en accidente de transito"
  },
  {
     id:"V487",
     enfermedad:"Ocupante de automovil lesionado en accidente de transporte sin colision: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V489",
     enfermedad:"Ocupante de automovil lesionado en accidente de transporte sin colision: ocupante no especificado de automovil, lesionado en accidente de transito"
  },
  {
     id:"V49",
     enfermedad:"Ocupante de automovil lesionado en otros accidentes de transporte, y en los no especificados"
  },
  {
     id:"V490",
     enfermedad:"Conductor de automovil lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente no de transito"
  },
  {
     id:"V491",
     enfermedad:"Pasajero de automovil lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente no de transito"
  },
  {
     id:"V492",
     enfermedad:"Ocupante no especificado de automovil lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente no de transito"
  },
  {
     id:"V493",
     enfermedad:"Ocupante [cualquiera] de automovil lesionado en accidente no de transito, no especificado"
  },
  {
     id:"V494",
     enfermedad:"Conductor de automovil lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente de transito"
  },
  {
     id:"V495",
     enfermedad:"Pasajero de automovil lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente de transito"
  },
  {
     id:"V496",
     enfermedad:"Ocupante no especificado de automovil lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente de transito"
  },
  {
     id:"V498",
     enfermedad:"Ocupante [cualquiera]) de automovil lesionado en otros accidentes de transporte especificados"
  },
  {
     id:"V499",
     enfermedad:"Ocupante [cualquiera] de automovil lesionado en accidente de transito no especificado"
  },
  {
     id:"V50",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con peaton o animal"
  },
  {
     id:"V500",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con peaton o animal: conductor lesionado en accidente no de transito"
  },
  {
     id:"V501",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con peaton o animal: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V502",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con peaton o animal: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V503",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con peaton o animal: ocupante no especificado de camioneta o furgoneta, lesionado en accidente no de transito"
  },
  {
     id:"V504",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con peaton o animal: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V505",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con peaton o animal: conductor lesionado en accidente de transito"
  },
  {
     id:"V506",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con peaton o animal: pasajero lesionado en accidente de transito"
  },
  {
     id:"V507",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con peaton o animal: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V509",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con peaton o animal: ocupante no especificado de camioneta o furgoneta, lesionado en accidente de transito"
  },
  {
     id:"V51",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con vehiculo de pedal"
  },
  {
     id:"V510",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con vehiculo de pedal: conductor lesionado en accidente no de transito"
  },
  {
     id:"V511",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con vehiculo de pedal: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V512",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con vehiculo de pedal: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V513",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con vehiculo de pedal: ocupante no especificado de camioneta o furgoneta, lesionado en accidente no de transito"
  },
  {
     id:"V514",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con vehiculo de pedal: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V515",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con vehiculo de pedal: conductor lesionado en accidente de transito"
  },
  {
     id:"V516",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con vehiculo de pedal: pasajero lesionado en accidente de transito"
  },
  {
     id:"V517",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con vehiculo de pedal: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V519",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con vehiculo de pedal: ocupante no especificado de camioneta o furgoneta, lesionado en accidente de transito"
  },
  {
     id:"V52",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con vehiculo de motor de dos o tres ruedas"
  },
  {
     id:"V520",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con vehiculo de motor de dos o tres ruedas: conductor lesionado en accidente no de transito"
  },
  {
     id:"V521",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con vehiculo de motor de dos o tres ruedas: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V522",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con vehiculo de motor de dos o tres ruedas: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V523",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con vehiculo de motor de dos o tres ruedas: ocupante no especificado de camioneta o furgoneta, lesionado en accidente no de transito"
  },
  {
     id:"V524",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con vehiculo de motor de dos o tres ruedas: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V525",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con vehiculo de motor de dos o tres ruedas: conductor lesionado en accidente de transito"
  },
  {
     id:"V526",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con vehiculo de motor de dos o tres ruedas: pasajero lesionado en accidente de transito"
  },
  {
     id:"V527",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con vehiculo de motor de dos o tres ruedas: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V529",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con vehiculo de motor de dos o tres ruedas: ocupante no especificado de camioneta o furgoneta, lesionado en accidente de transito"
  },
  {
     id:"V53",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con automovil camioneta furgonetaa"
  },
  {
     id:"V530",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con automovil, camioneta o furgoneta: conductor lesionado en accidente no de transito"
  },
  {
     id:"V531",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con automovil, camioneta o furgoneta: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V532",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con automovil, camioneta o furgoneta: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V533",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con automovil, camioneta o furgoneta: ocupante no especificado de camioneta o furgoneta, lesionado en accidente no de transito"
  },
  {
     id:"V534",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con automovil, camioneta o furgoneta: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V535",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con automovil, camioneta o furgoneta: conductor lesionado en accidente de transito"
  },
  {
     id:"V536",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con automovil, camioneta o furgoneta: pasajero lesionado en accidente de transito"
  },
  {
     id:"V537",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con automovil, camioneta o furgoneta: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V539",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con automovil, camioneta o furgoneta: ocupante no especificado de camioneta o furgoneta, lesionado en accidente de transito"
  },
  {
     id:"V54",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con vehiculo de transporte pesado o autobus"
  },
  {
     id:"V540",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con vehiculo de transporte pesado o autobus: conductor lesionado en accidente no de transito"
  },
  {
     id:"V541",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con vehiculo de transporte pesado o autobus: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V542",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con vehiculo de transporte pesado o autobus: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V543",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con vehiculo de transporte pesado o autobus: ocupante no especificado de camioneta o furgoneta, lesionado en accidente no de transito"
  },
  {
     id:"V544",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con vehiculo de transporte pesado o autobus: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V545",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con vehiculo de transporte pesado o autobus: conductor lesionado en accidente de transito"
  },
  {
     id:"V546",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con vehiculo de transporte pesado o autobus: pasajero lesionado en accidente de transito"
  },
  {
     id:"V547",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con vehiculo de transporte pesado o autobus: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V549",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con vehiculo de transporte pesado o autobus: ocupante no especificado de camioneta o furgoneta, lesionado en accidente de transito"
  },
  {
     id:"V55",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con tren o vehiculo de rieles"
  },
  {
     id:"V550",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con tren o vehiculo de rieles: conductor lesionado en accidente no de transito"
  },
  {
     id:"V551",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con tren o vehiculo de rieles: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V552",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con tren o vehiculo de rieles: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V553",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con tren o vehiculo de rieles: ocupante no especificado de camioneta o furgoneta, lesionado en accidente no de transito"
  },
  {
     id:"V554",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con tren o vehiculo de rieles: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V555",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con tren o vehiculo de rieles: conductor lesionado en accidente de transito"
  },
  {
     id:"V556",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con tren o vehiculo de rieles: pasajero lesionado en accidente de transito"
  },
  {
     id:"V557",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con tren o vehiculo de rieles: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V559",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con tren o vehiculo de rieles: ocupante no especificado de camioneta o furgoneta, lesionado en accidente de transito"
  },
  {
     id:"V56",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con otros vehiculos sin motor"
  },
  {
     id:"V560",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con otros vehiculos sin motor: conductor lesionado en accidente no de transito"
  },
  {
     id:"V561",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con otros vehiculos sin motor: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V562",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con otros vehiculos sin motor: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V563",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con otros vehiculos sin motor: ocupante no especificado de camioneta o furgoneta, lesionado en accidente no de transito"
  },
  {
     id:"V564",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con otros vehiculos sin motor: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V565",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con otros vehiculos sin motor: conductor lesionado en accidente de transito"
  },
  {
     id:"V566",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con otros vehiculos sin motor: pasajero lesionado en accidente de transito"
  },
  {
     id:"V567",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con otros vehiculos sin motor: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V569",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con otros vehiculos sin motor: ocupante no especificado de camioneta o furgoneta, lesionado en accidente de transito"
  },
  {
     id:"V57",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con objeto estacionado fijo"
  },
  {
     id:"V570",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con objeto fijo o estacionado: conductor lesionado en accidente no de transito"
  },
  {
     id:"V571",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con objeto fijo o estacionado: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V572",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con objeto fijo o estacionado: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V573",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con objeto fijo o estacionado: ocupante no especificado de camioneta o furgoneta, lesionado en accidente no de transito"
  },
  {
     id:"V574",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con objeto fijo o estacionado: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V575",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con objeto fijo o estacionado: conductor lesionado en accidente de transito"
  },
  {
     id:"V576",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con objeto fijo o estacionado: pasajero lesionado en accidente de transito"
  },
  {
     id:"V577",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con objeto fijo o estacionado: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V579",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado por colision con objeto fijo o estacionado: ocupante no especificado de camioneta o furgoneta, lesionado en accidente de transito"
  },
  {
     id:"V58",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado en accidente transporte sin colision"
  },
  {
     id:"V580",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado en accidente de transporte sin colision: conductor lesionado en accidente no de transito"
  },
  {
     id:"V581",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado en accidente de transporte sin colision: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V582",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado en accidente de transporte sin colision: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V583",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado en accidente de transporte sin colision: ocupante no especificado de camioneta o furgoneta, lesionado en accidente no de transito"
  },
  {
     id:"V584",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado en accidente de transporte sin colision: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V585",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado en accidente de transporte sin colision: conductor lesionado en accidente de transito"
  },
  {
     id:"V586",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado en accidente de transporte sin colision: pasajero lesionado en accidente de transito"
  },
  {
     id:"V587",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado en accidente de transporte sin colision: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V589",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado en accidente de transporte sin colision: ocupante no especificado de camioneta o furgoneta, lesionado en accidente de transito"
  },
  {
     id:"V59",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado en otros accidentes de transporte, y en los no especificados"
  },
  {
     id:"V590",
     enfermedad:"Conductor de camioneta o furgoneta lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente no de transito"
  },
  {
     id:"V591",
     enfermedad:"Pasajero de camioneta o furgoneta lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente no de transito"
  },
  {
     id:"V592",
     enfermedad:"Ocupante no especificado de camioneta o furgoneta lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente no de transito"
  },
  {
     id:"V593",
     enfermedad:"Ocupante [cualquiera] de camioneta o furgoneta lesionado en accidente no de transito, no especificado"
  },
  {
     id:"V594",
     enfermedad:"Conductor de camioneta o furgoneta lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente de transito"
  },
  {
     id:"V595",
     enfermedad:"Pasajero de camioneta o furgoneta lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente de transito"
  },
  {
     id:"V596",
     enfermedad:"Ocupante no especificado de camioneta o furgoneta lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente de transito"
  },
  {
     id:"V598",
     enfermedad:"Ocupante [cualquiera] de camioneta o furgoneta lesionado en otros accidentes de transporte especificados"
  },
  {
     id:"V599",
     enfermedad:"Ocupante [cualquiera] de camioneta o furgoneta lesionado en accidente de transito no especificado"
  },
  {
     id:"V60",
     enfermedad:"Ocupante de vehiculo de transporte pesado pesado lesionado por colision con peaton o animal"
  },
  {
     id:"V600",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con peaton o animal: conductor lesionado en accidente no de transito"
  },
  {
     id:"V601",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con peaton o animal: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V602",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con peaton o animal: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V603",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con peaton o animal: ocupante no especificado de vehiculo de transporte pesado lesionado en accidente no de transito"
  },
  {
     id:"V604",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con peaton o animal: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V605",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con peaton o animal: conductor lesionado en accidente de transito"
  },
  {
     id:"V606",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con peaton o animal: pasajero lesionado en accidente de transito"
  },
  {
     id:"V607",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con peaton o animal: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V609",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con peaton o animal: ocupante no especificado de vehiculo de transporte pesado, lesionado en accidente de transito"
  },
  {
     id:"V61",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con vehiculo de pedal"
  },
  {
     id:"V610",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con vehiculo de pedal: conductor lesionado en accidente no de transito"
  },
  {
     id:"V611",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con vehiculo de pedal: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V612",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con vehiculo de pedal: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V613",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con vehiculo de pedal: ocupante no especificado de vehiculo de transporte pesado lesionado en accidente no de transito"
  },
  {
     id:"V614",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con vehiculo de pedal: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V615",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con vehiculo de pedal: conductor lesionado en accidente de transito"
  },
  {
     id:"V616",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con vehiculo de pedal: pasajero lesionado en accidente de transito"
  },
  {
     id:"V617",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con vehiculo de pedal: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V619",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con vehiculo de pedal: ocupante no especificado de vehiculo de transporte pesado, lesionado en accidente de transito"
  },
  {
     id:"V62",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con vehiculo de motor de dos o tres ruedas"
  },
  {
     id:"V620",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con vehiculo de motor de dos o tres ruedas: conductor lesionado en accidente no de transito"
  },
  {
     id:"V621",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con vehiculo de motor de dos o tres ruedas: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V622",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con vehiculo de motor de dos o tres ruedas: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V623",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con vehiculo de motor de dos o tres ruedas: ocupante no especificado de vehiculo de transporte pesado lesionado en accidente no de transito"
  },
  {
     id:"V624",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con vehiculo de motor de dos o tres ruedas: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V625",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con vehiculo de motor de dos o tres ruedas: conductor lesionado en accidente de transito"
  },
  {
     id:"V626",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con vehiculo de motor de dos o tres ruedas: pasajero lesionado en accidente de transito"
  },
  {
     id:"V627",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con vehiculo de motor de dos o tres ruedas: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V629",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con vehiculo de motor de dos o tres ruedas: ocupante no especificado de vehiculo de transporte pesado, lesionado en accidente de transito"
  },
  {
     id:"V63",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con automovil, camioneta o furgoneta"
  },
  {
     id:"V630",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con automovil, camioneta o furgoneta: conductor lesionado en accidente no de transito"
  },
  {
     id:"V631",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con automovil, camioneta o furgoneta: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V632",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con automovil, camioneta o furgoneta: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V633",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con automovil, camioneta o furgoneta: ocupante no especificado de vehiculo de transporte pesado lesionado en accidente no de transito"
  },
  {
     id:"V634",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con automovil, camioneta o furgoneta: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V635",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con automovil, camioneta o furgoneta: conductor lesionado en accidente de transito"
  },
  {
     id:"V636",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con automovil, camioneta o furgoneta: pasajero lesionado en accidente de transito"
  },
  {
     id:"V637",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con automovil, camioneta o furgoneta: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V639",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con automovil, camioneta o furgoneta: ocupante no especificado de vehiculo de transporte pesado, lesionado en accidente de transito"
  },
  {
     id:"V64",
     enfermedad:"Ocupante de vehiculo de transporte pesa lesionado por colision con vehiculo de transporte pesado o autobus"
  },
  {
     id:"V640",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con otro vehiculo de transporte pesado o autobus: conductor lesionado en accidente no de transito"
  },
  {
     id:"V641",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con otro vehiculo de transporte pesado o autobus: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V642",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con otro vehiculo de transporte pesado o autobus: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V643",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con otro vehiculo de transporte pesado o autobus: ocupante no especificado de vehiculo de transporte pesado lesionado en accidente no de transito"
  },
  {
     id:"V644",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con otro vehiculo de transporte pesado o autobus: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V645",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con otro vehiculo de transporte pesado o autobus: conductor lesionado en accidente de transito"
  },
  {
     id:"V646",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con otro vehiculo de transporte pesado o autobus: pasajero lesionado en accidente de transito"
  },
  {
     id:"V647",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con otro vehiculo de transporte pesado o autobus: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V649",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con otro vehiculo de transporte pesado o autobus: ocupante no especificado de vehiculo de transporte pesado, lesionado en accidente de transito"
  },
  {
     id:"V65",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con tren o vehiculo de rieles"
  },
  {
     id:"V650",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con tren o vehiculo de rieles: conductor lesionado en accidente no de transito"
  },
  {
     id:"V651",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con tren o vehiculo de rieles: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V652",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con tren o vehiculo de rieles: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V653",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con tren o vehiculo de rieles: ocupante no especificado de vehiculo de transporte pesado lesionado en accidente no de transito"
  },
  {
     id:"V654",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con tren o vehiculo de rieles: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V655",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con tren o vehiculo de rieles: conductor lesionado en accidente de transito"
  },
  {
     id:"V656",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con tren o vehiculo de rieles: pasajero lesionado en accidente de transito"
  },
  {
     id:"V657",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con tren o vehiculo de rieles: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V659",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con tren o vehiculo de rieles: ocupante no especificado de vehiculo de transporte pesado, lesionado en accidente de transito"
  },
  {
     id:"V66",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con otro vehiculo sin motor"
  },
  {
     id:"V660",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con otros vehiculos sin motor: conductor lesionado en accidente no de transito"
  },
  {
     id:"V661",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con otros vehiculos sin motor: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V662",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con otros vehiculos sin motor: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V663",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con otros vehiculos sin motor: ocupante no especificado de vehiculo de transporte pesado lesionado en accidente no de transito"
  },
  {
     id:"V664",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con otros vehiculos sin motor: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V665",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con otros vehiculos sin motor: conductor lesionado en accidente de transito"
  },
  {
     id:"V666",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con otros vehiculos sin motor: pasajero lesionado en accidente de transito"
  },
  {
     id:"V667",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con otros vehiculos sin motor: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V669",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con otros vehiculos sin motor: ocupante no especificado de vehiculo de transporte pesado, lesionado en accidente de transito"
  },
  {
     id:"V67",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con objeto estacionado o fijo"
  },
  {
     id:"V670",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con objeto fijo o estacionado: conductor lesionado en accidente no de transito"
  },
  {
     id:"V671",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con objeto fijo o estacionado: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V672",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con objeto fijo o estacionado: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V673",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con objeto fijo o estacionado: ocupante no especificado de vehiculo de transporte pesado lesionado en accidente no de transito"
  },
  {
     id:"V674",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con objeto fijo o estacionado: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V675",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con objeto fijo o estacionado: conductor lesionado en accidente de transito"
  },
  {
     id:"V676",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con objeto fijo o estacionado: pasajero lesionado en accidente de transito"
  },
  {
     id:"V677",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con objeto fijo o estacionado: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V679",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado por colision con objeto fijo o estacionado: ocupante no especificado de vehiculo de transporte pesado, lesionado en accidente de transito"
  },
  {
     id:"V68",
     enfermedad:"Ocupante de vehiculo de transporteansporte pesado lesionado accidente transporte sin colision"
  },
  {
     id:"V680",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado en accidente de transporte sin colision: conductor lesionado en accidente no de transito"
  },
  {
     id:"V681",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado en accidente de transporte sin colision: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V682",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado en accidente de transporte sin colision: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V683",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado en accidente de transporte sin colision: ocupante no especificado de vehiculo de transporte pesado lesionado en accidente no de transito"
  },
  {
     id:"V684",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado en accidente de transporte sin colision: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V685",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado en accidente de transporte sin colision: conductor lesionado en accidente de transito"
  },
  {
     id:"V686",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado en accidente de transporte sin colision: pasajero lesionado en accidente de transito"
  },
  {
     id:"V687",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado en accidente de transporte sin colision: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V689",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado en accidente de transporte sin colision: ocupante no especificado de vehiculo de transporte pesado, lesionado en accidente de transito"
  },
  {
     id:"V69",
     enfermedad:"Ocupante de vehiculo de transporte pesado lesionado en otros accidentes de transporte, y en los no especificados"
  },
  {
     id:"V690",
     enfermedad:"Conductor de vehiculo de transporte pesado lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente no de transito"
  },
  {
     id:"V691",
     enfermedad:"Pasajero de vehiculo de transporte pesado lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente no de transito"
  },
  {
     id:"V692",
     enfermedad:"Ocupante no especificado de vehiculo de transporte pesado lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente no de transito"
  },
  {
     id:"V693",
     enfermedad:"Ocupante [cualquiera] de vehiculo de transporte pesado lesionado en accidente no de transito, no especificado"
  },
  {
     id:"V694",
     enfermedad:"Conductor de vehiculo de transporte pesado lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente de transito"
  },
  {
     id:"V695",
     enfermedad:"Pasajero de vehiculo de transporte pesado lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente de transito"
  },
  {
     id:"V696",
     enfermedad:"Ocupante no especificado de vehiculo de transporte pesado lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente de transito"
  },
  {
     id:"V698",
     enfermedad:"Ocupante [cualquiera] de vehiculo de transporte pesado lesionado en otros accidentes de transporte especificados"
  },
  {
     id:"V699",
     enfermedad:"Ocupante [cualquiera] de vehiculo de transporte pesado lesionado en accidente de transito no especificado"
  },
  {
     id:"V70",
     enfermedad:"Ocupante deupante autobus lesionado por colision con peaton o animal"
  },
  {
     id:"V700",
     enfermedad:"Ocupante de autobus lesionado por colision con peaton o animal: conductor lesionado en accidente no de transito"
  },
  {
     id:"V701",
     enfermedad:"Ocupante de autobus lesionado por colision con peaton o animal: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V702",
     enfermedad:"Ocupante de autobus lesionado por colision con peaton o animal: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V703",
     enfermedad:"Ocupante de autobus lesionado por colision con peaton o animal: ocupante no especificado de autobus, lesionado en accidente no de transito"
  },
  {
     id:"V704",
     enfermedad:"Ocupante de autobus lesionado por colision con peaton o animal: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V705",
     enfermedad:"Ocupante de autobus lesionado por colision con peaton o animal: conductor lesionado en accidente de transito"
  },
  {
     id:"V706",
     enfermedad:"Ocupante de autobus lesionado por colision con peaton o animal: pasajero lesionado en accidente de transito"
  },
  {
     id:"V707",
     enfermedad:"Ocupante de autobus lesionado por colision con peaton o animal: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V709",
     enfermedad:"Ocupante de autobus lesionado por colision con peaton o animal: ocupante no especificado de autobus, lesionado en accidente de transito"
  },
  {
     id:"V71",
     enfermedad:"Ocupante de autobus lesionado por colision con vehiculo de pedal"
  },
  {
     id:"V710",
     enfermedad:"Ocupante de autobus lesionado por colision con vehiculo de pedal: conductor lesionado en accidente no de transito"
  },
  {
     id:"V711",
     enfermedad:"Ocupante de autobus lesionado por colision con vehiculo de pedal: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V712",
     enfermedad:"Ocupante de autobus lesionado por colision con vehiculo de pedal: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V713",
     enfermedad:"Ocupante de autobus lesionado por colision con vehiculo de pedal: ocupante no especificado de autobus, lesionado en accidente no de transito"
  },
  {
     id:"V714",
     enfermedad:"Ocupante de autobus lesionado por colision con vehiculo de pedal: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V715",
     enfermedad:"Ocupante de autobus lesionado por colision con vehiculo de pedal: conductor lesionado en accidente de transito"
  },
  {
     id:"V716",
     enfermedad:"Ocupante de autobus lesionado por colision con vehiculo de pedal: pasajero lesionado en accidente de transito"
  },
  {
     id:"V717",
     enfermedad:"Ocupante de autobus lesionado por colision con vehiculo de pedal: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V719",
     enfermedad:"Ocupante de autobus lesionado por colision con vehiculo de pedal: ocupante no especificado de autobus, lesionado en accidente de transito"
  },
  {
     id:"V72",
     enfermedad:"Ocupante de autobus lesionado por colision con vehiculo de motor de dos o tres ruedas"
  },
  {
     id:"V720",
     enfermedad:"Ocupante de autobus lesionado por colision con vehiculo de motor de dos o tres ruedas: conductor lesionado en accidente no de transito"
  },
  {
     id:"V721",
     enfermedad:"Ocupante de autobus lesionado por colision con vehiculo de motor de dos o tres ruedas: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V722",
     enfermedad:"Ocupante de autobus lesionado por colision con vehiculo de motor de dos o tres ruedas: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V723",
     enfermedad:"Ocupante de autobus lesionado por colision con vehiculo de motor de dos o tres ruedas: ocupante no especificado de autobus, lesionado en accidente no de transito"
  },
  {
     id:"V724",
     enfermedad:"Ocupante de autobus lesionado por colision con vehiculo de motor de dos o tres ruedas: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V725",
     enfermedad:"Ocupante de autobus lesionado por colision con vehiculo de motor de dos o tres ruedas: conductor lesionado en accidente de transito"
  },
  {
     id:"V726",
     enfermedad:"Ocupante de autobus lesionado por colision con vehiculo de motor de dos o tres ruedas: pasajero lesionado en accidente de transito"
  },
  {
     id:"V727",
     enfermedad:"Ocupante de autobus lesionado por colision con vehiculo de motor de dos o tres ruedas: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V729",
     enfermedad:"Ocupante de autobus lesionado por colision con vehiculo de motor de dos o tres ruedas: ocupante no especificado de autobus, lesionado en accidente de transito"
  },
  {
     id:"V73",
     enfermedad:"Ocupante de autobus lesionado por colision con auto camioneta o furgoneta"
  },
  {
     id:"V730",
     enfermedad:"Ocupante de autobus lesionado por colision con automovil, camioneta o furgoneta: conductor lesionado en accidente no de transito"
  },
  {
     id:"V731",
     enfermedad:"Ocupante de autobus lesionado por colision con automovil, camioneta o furgoneta: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V732",
     enfermedad:"Ocupante de autobus lesionado por colision con automovil, camioneta o furgoneta: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V733",
     enfermedad:"Ocupante de autobus lesionado por colision con automovil, camioneta o furgoneta: ocupante no especificado de autobus, lesionado en accidente no de transito"
  },
  {
     id:"V734",
     enfermedad:"Ocupante de autobus lesionado por colision con automovil, camioneta o furgoneta: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V735",
     enfermedad:"Ocupante de autobus lesionado por colision con automovil, camioneta o furgoneta: conductor lesionado en accidente de transito"
  },
  {
     id:"V736",
     enfermedad:"Ocupante de autobus lesionado por colision con automovil, camioneta o furgoneta: pasajero lesionado en accidente de transito"
  },
  {
     id:"V737",
     enfermedad:"Ocupante de autobus lesionado por colision con automovil, camioneta o furgoneta: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V739",
     enfermedad:"Ocupante de autobus lesionado por colision con automovil, camioneta o furgoneta: ocupante no especificado de autobus, lesionado en accidente de transito"
  },
  {
     id:"V74",
     enfermedad:"Ocupante de autobus lesionado por colision con vehiculo de transporte pesado o autobus"
  },
  {
     id:"V740",
     enfermedad:"Ocupante de autobus lesionado por colision con vehiculo de transporte pesado o autobus: conductor lesionado en accidente no de transito"
  },
  {
     id:"V741",
     enfermedad:"Ocupante de autobus lesionado por colision con vehiculo de transporte pesado o autobus: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V742",
     enfermedad:"Ocupante de autobus lesionado por colision con vehiculo de transporte pesado o autobus: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V743",
     enfermedad:"Ocupante de autobus lesionado por colision con vehiculo de transporte pesado o autobus: ocupante no especificado de autobus, lesionado en accidente no de transito"
  },
  {
     id:"V744",
     enfermedad:"Ocupante de autobus lesionado por colision con vehiculo de transporte pesado o autobus: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V745",
     enfermedad:"Ocupante de autobus lesionado por colision con vehiculo de transporte pesado o autobus: conductor lesionado en accidente de transito"
  },
  {
     id:"V746",
     enfermedad:"Ocupante de autobus lesionado por colision con vehiculo de transporte pesado o autobus: pasajero lesionado en accidente de transito"
  },
  {
     id:"V747",
     enfermedad:"Ocupante de autobus lesionado por colision con vehiculo de transporte pesado o autobus: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V749",
     enfermedad:"Ocupante de autobus lesionado por colision con vehiculo de transporte pesado o autobus: ocupante no especificado de autobus, lesionado en accidente de transito"
  },
  {
     id:"V75",
     enfermedad:"Ocupante de autobus lesionado por colision con tren o vehiculo de rieles"
  },
  {
     id:"V750",
     enfermedad:"Ocupante de autobus lesionado por colision con tren o vehiculo de rieles: conductor lesionado en accidente no de transito"
  },
  {
     id:"V751",
     enfermedad:"Ocupante de autobus lesionado por colision con tren o vehiculo de rieles: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V752",
     enfermedad:"Ocupante de autobus lesionado por colision con tren o vehiculo de rieles: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V753",
     enfermedad:"Ocupante de autobus lesionado por colision con tren o vehiculo de rieles: ocupante no especificado de autobus, lesionado en accidente no de transito"
  },
  {
     id:"V754",
     enfermedad:"Ocupante de autobus lesionado por colision con tren o vehiculo de rieles: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V755",
     enfermedad:"Ocupante de autobus lesionado por colision con tren o vehiculo de rieles: conductor lesionado en accidente de transito"
  },
  {
     id:"V756",
     enfermedad:"Ocupante de autobus lesionado por colision con tren o vehiculo de rieles: pasajero lesionado en accidente de transito"
  },
  {
     id:"V757",
     enfermedad:"Ocupante de autobus lesionado por colision con tren o vehiculo de rieles: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V759",
     enfermedad:"Ocupante de autobus lesionado por colision con tren o vehiculo de rieles: ocupante no especificado de autobus, lesionado en accidente de transito"
  },
  {
     id:"V76",
     enfermedad:"Ocupante de autobus lesionado por colision con otr vehiculo sin motor"
  },
  {
     id:"V760",
     enfermedad:"Ocupante de autobus lesionado por colision con otros vehiculos sin motor: conductor lesionado en accidente no de transito"
  },
  {
     id:"V761",
     enfermedad:"Ocupante de autobus lesionado por colision con otros vehiculos sin motor: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V762",
     enfermedad:"Ocupante de autobus lesionado por colision con otros vehiculos sin motor: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V763",
     enfermedad:"Ocupante de autobus lesionado por colision con otros vehiculos sin motor: ocupante no especificado de autobus, lesionado en accidente no de transito"
  },
  {
     id:"V764",
     enfermedad:"Ocupante de autobus lesionado por colision con otros vehiculos sin motor: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V765",
     enfermedad:"Ocupante de autobus lesionado por colision con otros vehiculos sin motor: conductor lesionado en accidente de transito"
  },
  {
     id:"V766",
     enfermedad:"Ocupante de autobus lesionado por colision con otros vehiculos sin motor: pasajero lesionado en accidente de transito"
  },
  {
     id:"V767",
     enfermedad:"Ocupante de autobus lesionado por colision con otros vehiculos sin motor: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V769",
     enfermedad:"Ocupante de autobus lesionado por colision con otros vehiculos sin motor: ocupante de autobus lesionado por colision con otros vehiculos sin motor"
  },
  {
     id:"V77",
     enfermedad:"Ocupante de autobus lesionado por colision con objeto estacionado o fijo"
  },
  {
     id:"V770",
     enfermedad:"Ocupante de autobus lesionado por colision con objeto fijo o estacionado: conductor lesionado en accidente no de transito"
  },
  {
     id:"V771",
     enfermedad:"Ocupante de autobus lesionado por colision con objeto fijo o estacionado: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V772",
     enfermedad:"Ocupante de autobus lesionado por colision con objeto fijo o estacionado: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V773",
     enfermedad:"Ocupante de autobus lesionado por colision con objeto fijo o estacionado: ocupante no especificado de autobus, lesionado en accidente no de transito"
  },
  {
     id:"V774",
     enfermedad:"Ocupante de autobus lesionado por colision con objeto fijo o estacionado: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V775",
     enfermedad:"Ocupante de autobus lesionado por colision con objeto fijo o estacionado: conductor lesionado en accidente de transito"
  },
  {
     id:"V776",
     enfermedad:"Ocupante de autobus lesionado por colision con objeto fijo o estacionado: pasajero lesionado en accidente de transito"
  },
  {
     id:"V777",
     enfermedad:"Ocupante de autobus lesionado por colision con objeto fijo o estacionado: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V779",
     enfermedad:"Ocupante de autobus lesionado por colision con objeto fijo o estacionado: ocupante no especificado de autobus, lesionado en accidente de transito"
  },
  {
     id:"V78",
     enfermedad:"Ocupante de autobus lesionado accidente detransporte sin colision"
  },
  {
     id:"V780",
     enfermedad:"Ocupante de autobus lesionado en accidente de transporte sin colision: conductor lesionado en accidente no de transito"
  },
  {
     id:"V781",
     enfermedad:"Ocupante de autobus lesionado en accidente de transporte sin colision: pasajero lesionado en accidente no de transito"
  },
  {
     id:"V782",
     enfermedad:"Ocupante de autobus lesionado en accidente de transporte sin colision: persona que viaja fuera del vehiculo, lesionada en accidente no de transito"
  },
  {
     id:"V783",
     enfermedad:"Ocupante de autobus lesionado en accidente de transporte sin colision: ocupante no especificado de autobus, lesionado en accidente no de transito"
  },
  {
     id:"V784",
     enfermedad:"Ocupante de autobus lesionado en accidente de transporte sin colision: persona lesionada al subir o bajar del vehiculo"
  },
  {
     id:"V785",
     enfermedad:"Ocupante de autobus lesionado en accidente de transporte sin colision: conductor lesionado en accidente de transito"
  },
  {
     id:"V786",
     enfermedad:"Ocupante de autobus lesionado en accidente de transporte sin colision: pasajero lesionado en accidente de transito"
  },
  {
     id:"V787",
     enfermedad:"Ocupante de autobus lesionado en accidente de transporte sin colision: persona que viaja fuera del vehiculo, lesionada en accidente de transito"
  },
  {
     id:"V789",
     enfermedad:"Ocupante de autobus lesionado en accidente de transporte sin colision: ocupante no especificado de autobus, lesionado en accidente de transito"
  },
  {
     id:"V79",
     enfermedad:"Ocupante de autobus lesionado en otros accidentes de transporte, y en los no especificados"
  },
  {
     id:"V790",
     enfermedad:"Conductor de autobus lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente no de transito"
  },
  {
     id:"V791",
     enfermedad:"Pasajero de autobus lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente no de transito"
  },
  {
     id:"V792",
     enfermedad:"Ocupante no especificado de autobus lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente no de transito"
  },
  {
     id:"V793",
     enfermedad:"Ocupante [cualquiera] de autobus lesionado en accidente no de transito, no especificado"
  },
  {
     id:"V794",
     enfermedad:"Conductor de autobus lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente de transito"
  },
  {
     id:"V795",
     enfermedad:"Pasajero de autobus lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente de transito"
  },
  {
     id:"V796",
     enfermedad:"Ocupante no especificado de autobus lesionado por colision con otros vehiculos de motor, y con los no especificados, en accidente de transito"
  },
  {
     id:"V798",
     enfermedad:"Ocupante [cualquiera] de autobus lesionado en otros accidentes de transporte especificados"
  },
  {
     id:"V799",
     enfermedad:"Ocupante [cualquiera] de autobus lesionado en accidente de transito no especificado"
  },
  {
     id:"V80",
     enfermedad:"Jinete u ocupante de vehiculo de traccion animal, lesionado en accidente de transporte"
  },
  {
     id:"V800",
     enfermedad:"Jinete u ocupante de vehiculo de traccion animal lesionado por caida (o por ser despedido) del animal o del vehiculo de traccion animal, en accidente sin colision"
  },
  {
     id:"V801",
     enfermedad:"Jinete u ocupante de vehiculo de traccion animal lesionado por colision con peaton o animal"
  },
  {
     id:"V802",
     enfermedad:"Jinete u ocupante de vehiculo de traccion animal lesionado por colision con vehiculo de pedal"
  },
  {
     id:"V803",
     enfermedad:"Jinete u ocupante de vehiculo de traccion animal lesionado por colision con vehiculo de motor de dos o tres ruedas"
  },
  {
     id:"V804",
     enfermedad:"Jinete u ocupante de vehiculo de traccion animal lesionado por colision con automovil, camioneta o furgoneta, vehiculo de transporte pesado, o autobus"
  },
  {
     id:"V805",
     enfermedad:"Jinete u ocupante de vehiculo de traccion animal lesionado por colision con otros vehiculos de motor especificados"
  },
  {
     id:"V806",
     enfermedad:"Jinete u ocupante de vehiculo de traccion animal lesionado por colision con tren o vehiculo de rieles"
  },
  {
     id:"V807",
     enfermedad:"Jinete u ocupante de vehiculo de traccion animal lesionado por colision con vehiculos sin motor"
  },
  {
     id:"V808",
     enfermedad:"Jinete u ocupante de vehiculo de traccion animal lesionado por colision con objeto fijo o estacionado"
  },
  {
     id:"V809",
     enfermedad:"Jinete u ocupante de vehiculo de traccion animal lesionado en otros accidentes de transporte, y en los no especificados"
  },
  {
     id:"V81",
     enfermedad:"Ocupante de tren o vehiculo de rieles lesionado en accidente de transporte"
  },
  {
     id:"V810",
     enfermedad:"Ocupante de tren o vehiculo de rieles lesionado por colision con vehiculo de motor, en accidente no de transito"
  },
  {
     id:"V811",
     enfermedad:"Ocupante de tren o vehiculo de rieles lesionado por colision con vehiculo de motor, en accidente de transito"
  },
  {
     id:"V812",
     enfermedad:"Ocupante de tren o vehiculo de rieles lesionado por colision con, o golpeado por vagon"
  },
  {
     id:"V813",
     enfermedad:"Ocupante de tren o vehiculo de rieles lesionado por colision con otros objetos"
  },
  {
     id:"V814",
     enfermedad:"Persona lesionada al subir o bajar de tren o vehiculo de rieles"
  },
  {
     id:"V815",
     enfermedad:"Ocupante de tren o vehiculo de rieles lesionado por caida dentro del tren o vehiculo de rieles"
  },
  {
     id:"V816",
     enfermedad:"Ocupante de tren o vehiculo de rieles lesionado por caida desde el tren o vehiculo de rieles"
  },
  {
     id:"V817",
     enfermedad:"Ocupante de tren o vehiculo de rieles lesionado en descarrilamiento sin colision anterior"
  },
  {
     id:"V818",
     enfermedad:"Ocupante de tren o vehiculo de rieles lesionado en otros accidentes ferroviarios especificados"
  },
  {
     id:"V819",
     enfermedad:"Ocupante de tren o vehiculo de rieles lesionado en accidente ferroviario no especificado"
  },
  {
     id:"V82",
     enfermedad:"Ocupante de tranvia lesionado en accidente de transporte"
  },
  {
     id:"V820",
     enfermedad:"Ocupante de tranvia lesionado por colision con vehiculo de motor, en accidente no de transito"
  },
  {
     id:"V821",
     enfermedad:"Ocupante de tranvia lesionado por colision con vehiculo de motor, en accidente de transito"
  },
  {
     id:"V822",
     enfermedad:"Ocupante de tranvia lesionado por colision con, o golpeado por vagon"
  },
  {
     id:"V823",
     enfermedad:"Ocupante de tranvia lesionado por colision con otros objetos"
  },
  {
     id:"V824",
     enfermedad:"Persona lesionada al subir o bajar del tranvia"
  },
  {
     id:"V825",
     enfermedad:"Ocupante de tranvia lesionado por caida dentro del tranvia"
  },
  {
     id:"V826",
     enfermedad:"Ocupante de tranvia lesionado por caida desde del tranvia"
  },
  {
     id:"V827",
     enfermedad:"Ocupante de tranvia lesionado por descarrilamiento, sin colision anterior"
  },
  {
     id:"V828",
     enfermedad:"Ocupante de tranvia lesionado otros accidentes de transporte, especificados"
  },
  {
     id:"V829",
     enfermedad:"Ocupante de tranvia lesionado en accidente de transito no especificado"
  },
  {
     id:"V83",
     enfermedad:"Ocupante de vehiculo de uso industrial lesionado en accidente de transporte"
  },
  {
     id:"V830",
     enfermedad:"Conductor de vehiculo industrial especial lesionado en accidente de transito"
  },
  {
     id:"V831",
     enfermedad:"Pasajero de vehiculo industrial especial lesionado en accidente de transito"
  },
  {
     id:"V832",
     enfermedad:"Persona que viaja fuera de vehiculo industrial especial lesionado en accidente de transito"
  },
  {
     id:"V833",
     enfermedad:"Ocupante no especificado de vehiculo industrial especial lesionado en accidente de transito"
  },
  {
     id:"V834",
     enfermedad:"Persona lesionada al subir o bajar del vehiculo industrial especial"
  },
  {
     id:"V835",
     enfermedad:"Conductor de vehiculo industrial especial lesionado en accidente no de transito"
  },
  {
     id:"V836",
     enfermedad:"Pasajero de vehiculo industrial especial lesionado en accidente no de transito"
  },
  {
     id:"V837",
     enfermedad:"Persona que viaja fuera de vehiculo industrial especial lesionado en accidente no de transito"
  },
  {
     id:"V839",
     enfermedad:"Ocupante no especificado de vehiculo industrial especial lesionado en accidente no de transito"
  },
  {
     id:"V84",
     enfermedad:"Ocupante de vehiculo de uso agricola lesionado accidente de transporte"
  },
  {
     id:"V840",
     enfermedad:"Conductor de vehiculo agricola especial lesionado en accidente de transito"
  },
  {
     id:"V841",
     enfermedad:"Pasajero de vehiculo agricola especial lesionado en accidente de transito"
  },
  {
     id:"V842",
     enfermedad:"Persona que viaja fuera del vehiculo agricola especial lesionada en accidente de transito"
  },
  {
     id:"V843",
     enfermedad:"Ocupante no especificado de vehiculo agricola especial lesionado en accidente de transito"
  },
  {
     id:"V844",
     enfermedad:"Persona lesionada al subir o bajar del vehiculo agricola especial"
  },
  {
     id:"V845",
     enfermedad:"Conductor de vehiculo agricola especial lesionado en accidente no de transito"
  },
  {
     id:"V846",
     enfermedad:"Pasajero de vehiculo agricola especial lesionado en accidente no de transito"
  },
  {
     id:"V847",
     enfermedad:"Persona que viaja fuera del vehiculo agricola especial lesionada en accidente no de transito"
  },
  {
     id:"V849",
     enfermedad:"Ocupante no especificado de vehiculo agricola especial lesionado en accidente no de transito"
  },
  {
     id:"V85",
     enfermedad:"Ocupante de vehiculo de construccion lesionado accidente de transporte"
  },
  {
     id:"V850",
     enfermedad:"Conductor de vehiculo especial para construccion lesionado en accidente de transito"
  },
  {
     id:"V851",
     enfermedad:"Pasajero de vehiculo especial para construccion lesionado en accidente de transito"
  },
  {
     id:"V852",
     enfermedad:"Persona que viaja fuera del vehiculo especial para construccion lesionada en accidente de transito"
  },
  {
     id:"V853",
     enfermedad:"Ocupante no especificado de vehiculo especial para construccion lesionado en accidente de transito"
  },
  {
     id:"V854",
     enfermedad:"Persona lesionada al subir o bajar del vehiculo especial para construccion"
  },
  {
     id:"V855",
     enfermedad:"Conductor de vehiculo especial para construccion lesionado en accidente no de transito"
  },
  {
     id:"V856",
     enfermedad:"Pasajero de vehiculo especial para construccion lesionado en accidente no de transito"
  },
  {
     id:"V857",
     enfermedad:"Persona que viaja fuera del vehiculo especial para construccion lesionada en accidente no de transito"
  },
  {
     id:"V859",
     enfermedad:"Ocupante no especificado de vehiculo especial para construccion lesionado en accidente no de transito"
  },
  {
     id:"V86",
     enfermedad:"Ocupante de vehiculo de todo terreno lesionado accidente de transporte"
  },
  {
     id:"V860",
     enfermedad:"Conductor de vehiculo para todo terreno o de otro vehiculo de motor para uso fuera de la carretera lesionado en accidente de transito"
  },
  {
     id:"V861",
     enfermedad:"Pasajero de vehiculo para todo terreno o de otro vehiculo de motor para uso fuera de la carretera lesionado en accidente de transito"
  },
  {
     id:"V862",
     enfermedad:"Persona que viaja fuera del vehiculo para todo terreno o de otro vehiculo de motor para uso fuera de la carretera lesionada en accidente de transito"
  },
  {
     id:"V863",
     enfermedad:"Ocupante no especificado de vehiculo para todo terreno o de otro vehiculo de motor para uso fuera de la carretera lesionado en accidente de transito"
  },
  {
     id:"V864",
     enfermedad:"Persona lesionada en accidente de transito al subir o bajar de vehiculo para todo terreno o de otro vehiculo de motor para uso fuera de la carretera"
  },
  {
     id:"V865",
     enfermedad:"Conductor de vehiculo para todo terreno o de otro vehiculo de motor para uso fuera de la carretera lesionado en accidente no de transito"
  },
  {
     id:"V866",
     enfermedad:"Pasajero de vehiculo para todo terreno o de otro vehiculo de motor para uso fuera de la carretera lesionado en accidente no de transito"
  },
  {
     id:"V867",
     enfermedad:"Persona que viaja fuera del vehiculo para todo terreno o de otro vehiculo de motor para uso fuera de la carretera lesionada en accidente no de transito"
  },
  {
     id:"V869",
     enfermedad:"Ocupante no especificado del vehiculo para todo terreno o de otro vehiculo de motor para uso fuera de la carretera lesionado en accidente no de transito"
  },
  {
     id:"V87",
     enfermedad:"Accidente de transito modo transporte victim desconoc"
  },
  {
     id:"V870",
     enfermedad:"Persona lesionada por colision entre automovil y vehiculo de motor de dos o tres ruedas (transito)"
  },
  {
     id:"V871",
     enfermedad:"Persona lesionada por colision entre otros vehiculos de motor y un vehiculo de motor de dos o tres ruedas (transito)"
  },
  {
     id:"V872",
     enfermedad:"Persona lesionada por colision entre automovil y camioneta o furgoneta (transito)"
  },
  {
     id:"V873",
     enfermedad:"Persona lesionada por colision entre automovil y autobus (transito)"
  },
  {
     id:"V874",
     enfermedad:"Persona lesionada por colision entre automovil y vehiculo de motor de transporte pesado (transito)"
  },
  {
     id:"V875",
     enfermedad:"Persona lesionada por colision entre vehiculo de transporte pesado y autobus (transito)"
  },
  {
     id:"V876",
     enfermedad:"Persona lesionada por colision entre tren o vehiculo de rieles y automovil (transito)"
  },
  {
     id:"V877",
     enfermedad:"Persona lesionada por colision entre otros vehiculos de motor especificados (transito)"
  },
  {
     id:"V878",
     enfermedad:"Persona lesionada en otros accidentes especificados de transporte de vehiculo de motor sin colision (transito)"
  },
  {
     id:"V879",
     enfermedad:"Persona lesionada en otros accidentes especificados de transporte de vehiculo sin motor (con colision) (sin colision) (transito)"
  },
  {
     id:"V88",
     enfermedad:"Accidente de no trans modo transporte victim desconoc"
  },
  {
     id:"V880",
     enfermedad:"Persona lesionada por colision entre automovil y vehiculo de motor de dos o tres ruedas, no de transito"
  },
  {
     id:"V881",
     enfermedad:"Persona lesionada por colision entre otros vehiculos de motor y vehiculo de motor de dos o tres ruedas, no de transito"
  },
  {
     id:"V882",
     enfermedad:"Persona lesionada por colision entre automovil y camioneta o furgoneta, no de transito"
  },
  {
     id:"V883",
     enfermedad:"Persona lesionada por colision entre automovil y autobus, no de transito"
  },
  {
     id:"V884",
     enfermedad:"Persona lesionada por colision entre automovil y vehiculo de transporte pesado, no de transito"
  },
  {
     id:"V885",
     enfermedad:"Persona lesionada por colision entre vehiculo de transporte pesado y autobus, no de transito"
  },
  {
     id:"V886",
     enfermedad:"Persona lesionada por colision entre tren o vehiculo de rieles o automovil, no de transito"
  },
  {
     id:"V887",
     enfermedad:"Persona lesionada por colision entre otros vehiculos de motor especificados, no de transito"
  },
  {
     id:"V888",
     enfermedad:"Persona lesionada en otros accidentes especificados de transporte de vehiculo de motor sin colision, no de transito"
  },
  {
     id:"V889",
     enfermedad:"Persona lesionada en otros accidentes especificados de transporte de vehiculo sin motor (con colision) (sin colision), no de transito"
  },
  {
     id:"V89",
     enfermedad:"Accidente de tipo de vehiculo no especificado"
  },
  {
     id:"V890",
     enfermedad:"Persona lesionada en accidente no de transito, de vehiculo de motor no especificado"
  },
  {
     id:"V891",
     enfermedad:"Persona lesionada en accidente no de transito, de vehiculo sin motor no especificado"
  },
  {
     id:"V892",
     enfermedad:"Persona lesionada en accidente de transito, de vehiculo de motor no especificado"
  },
  {
     id:"V893",
     enfermedad:"Persona lesionada en accidente de transito, de vehiculo sin motor no especificado"
  },
  {
     id:"V899",
     enfermedad:"Persona lesionada en accidente de vehiculo no especificado"
  },
  {
     id:"V90",
     enfermedad:"Accidente de embarcacion que causa ahogamiento y sumersion"
  },
  {
     id:"V900",
     enfermedad:"Accidente de embarcacion que causa ahogamiento y sumersion: barco mercante"
  },
  {
     id:"V901",
     enfermedad:"Accidente de embarcacion que causa ahogamiento y sumersion: barco de pasajeros"
  },
  {
     id:"V902",
     enfermedad:"Accidente de embarcacion que causa ahogamiento y sumersion: bote de pesca"
  },
  {
     id:"V903",
     enfermedad:"Accidente de embarcacion que causa ahogamiento y sumersion: otro vehiculo acuatico con motor"
  },
  {
     id:"V904",
     enfermedad:"Accidente de embarcacion que causa ahogamiento y sumersion: velero"
  },
  {
     id:"V905",
     enfermedad:"Accidente de embarcacion que causa ahogamiento y sumersion: canoa o kayak"
  },
  {
     id:"V906",
     enfermedad:"Accidente de embarcacion que causa ahogamiento y sumersion: balsa inflable (sin motor)"
  },
  {
     id:"V907",
     enfermedad:"Accidente de embarcacion que causa ahogamiento y sumersion: esqui acuatico"
  },
  {
     id:"V908",
     enfermedad:"Accidente de embarcacion que causa ahogamiento y sumersion: otro vehiculo acuatico sin motor"
  },
  {
     id:"V909",
     enfermedad:"Accidente de embarcacion que causa ahogamiento y sumersion: vehiculo acuatico no especificado"
  },
  {
     id:"V91",
     enfermedad:"Accidente de embarcacion que causa otros tipos de traumatismo"
  },
  {
     id:"V910",
     enfermedad:"Accidente de embarcacion que causa otros tipos de traumatismo: barco mercante"
  },
  {
     id:"V911",
     enfermedad:"Accidente de embarcacion que causa otros tipos de traumatismo: barco de pasajeros"
  },
  {
     id:"V912",
     enfermedad:"Accidente de embarcacion que causa otros tipos de traumatismo: bote de pesca"
  },
  {
     id:"V913",
     enfermedad:"Accidente de embarcacion que causa otros tipos de traumatismo: otro vehiculo acuatico con motor"
  },
  {
     id:"V914",
     enfermedad:"Accidente de embarcacion que causa otros tipos de traumatismo: velero"
  },
  {
     id:"V915",
     enfermedad:"Accidente de embarcacion que causa otros tipos de traumatismo: canoa o kayak"
  },
  {
     id:"V916",
     enfermedad:"Accidente de embarcacion que causa otros tipos de traumatismo: balsa inflable (sin motor)"
  },
  {
     id:"V917",
     enfermedad:"Accidente de embarcacion que causa otros tipos de traumatismo: esqui acuatico"
  },
  {
     id:"V918",
     enfermedad:"Accidente de embarcacion que causa otros tipos de traumatismo: otro vehiculo acuatico sin motor"
  },
  {
     id:"V919",
     enfermedad:"Accidente de embarcacion que causa otros tipos de traumatismo: vehiculo acuatico no especificado"
  },
  {
     id:"V92",
     enfermedad:"Ahogamiento y sumersion realcionados con transporte por agua, sin accidente de embarcacion"
  },
  {
     id:"V920",
     enfermedad:"Ahogamiento y sumersion relacionados con transporte por agua, sin accidente a la embarcacion: barco mercante"
  },
  {
     id:"V921",
     enfermedad:"Ahogamiento y sumersion relacionados con transporte por agua, sin accidente a la embarcacion: barco de pasajeros"
  },
  {
     id:"V922",
     enfermedad:"Ahogamiento y sumersion relacionados con transporte por agua, sin accidente a la embarcacion: bote de pesca"
  },
  {
     id:"V923",
     enfermedad:"Ahogamiento y sumersion relacionados con transporte por agua, sin accidente a la embarcacion: otro vehiculo acuatico con motor"
  },
  {
     id:"V924",
     enfermedad:"Ahogamiento y sumersion relacionados con transporte por agua, sin accidente a la embarcacion: velero"
  },
  {
     id:"V925",
     enfermedad:"Ahogamiento y sumersion relacionados con transporte por agua, sin accidente a la embarcacion: canoa o kayak"
  },
  {
     id:"V926",
     enfermedad:"Ahogamiento y sumersion relacionados con transporte por agua, sin accidente a la embarcacion: balsa inflable (sin motor)"
  },
  {
     id:"V927",
     enfermedad:"Ahogamiento y sumersion relacionados con transporte por agua, sin accidente a la embarcacion: esqui acuatico"
  },
  {
     id:"V928",
     enfermedad:"Ahogamiento y sumersion relacionados con transporte por agua, sin accidente a la embarcacion: otro vehiculo acuatico sin motor"
  },
  {
     id:"V929",
     enfermedad:"Ahogamiento y sumersion relacionados con transporte por agua, sin accidente a la embarcacion: vehiculo acuatico no especificado"
  },
  {
     id:"V93",
     enfermedad:"Accidente en una embarcacion, sin accidente a la embarcacion, que no causa ahogamiento y sumersion"
  },
  {
     id:"V930",
     enfermedad:"Accidente en una embarcacion, sin accidente a la embarcacion, que no causa ahogamiento o sumersion: barco mercante"
  },
  {
     id:"V931",
     enfermedad:"Accidente en una embarcacion, sin accidente a la embarcacion, que no causa ahogamiento o sumersion: barco de pasajeros"
  },
  {
     id:"V932",
     enfermedad:"Accidente en una embarcacion, sin accidente a la embarcacion, que no causa ahogamiento o sumersion: bote de pesca"
  },
  {
     id:"V933",
     enfermedad:"Accidente en una embarcacion, sin accidente a la embarcacion, que no causa ahogamiento o sumersion: otro vehiculo acuatico con motor"
  },
  {
     id:"V934",
     enfermedad:"Accidente en una embarcacion, sin accidente a la embarcacion, que no causa ahogamiento o sumersion: velero"
  },
  {
     id:"V935",
     enfermedad:"Accidente en una embarcacion, sin accidente a la embarcacion, que no causa ahogamiento o sumersion: canoa o kayak"
  },
  {
     id:"V936",
     enfermedad:"Accidente en una embarcacion, sin accidente a la embarcacion, que no causa ahogamiento o sumersion: balsa inflable (sin motor)"
  },
  {
     id:"V937",
     enfermedad:"Accidente en una embarcacion, sin accidente a la embarcacion, que no causa ahogamiento o sumersion: esqui acuatico"
  },
  {
     id:"V938",
     enfermedad:"Accidente en una embarcacion, sin accidente a la embarcacion, que no causa ahogamiento o sumersion: otro vehiculo acuatico sin motor"
  },
  {
     id:"V939",
     enfermedad:"Accidente en una embarcacion, sin accidente a la embarcacion, que no causa ahogamiento o sumersion: vehiculo acuatico no especificado"
  },
  {
     id:"V94",
     enfermedad:"Otros accidentes de transporte por agua, y los no especificados"
  },
  {
     id:"V940",
     enfermedad:"Otros accidentes de transporte por agua, y los no especificados: barco mercante"
  },
  {
     id:"V941",
     enfermedad:"Otros accidentes de transporte por agua, y los no especificados: barco de pasajeros"
  },
  {
     id:"V942",
     enfermedad:"Otros accidentes de transporte por agua, y los no especificados: bote de pesca"
  },
  {
     id:"V943",
     enfermedad:"Otros accidentes de transporte por agua, y los no especificados: otro vehiculo acuatico con motor"
  },
  {
     id:"V944",
     enfermedad:"Otros accidentes de transporte por agua, y los no especificados: velero"
  },
  {
     id:"V945",
     enfermedad:"Otros accidentes de transporte por agua, y los no especificados: canoa o kayak"
  },
  {
     id:"V946",
     enfermedad:"Otros accidentes de transporte por agua, y los no especificados: balsa inflable (sin motor)"
  },
  {
     id:"V947",
     enfermedad:"Otros accidentes de transporte por agua, y los no especificados: esqui acuatico"
  },
  {
     id:"V948",
     enfermedad:"Otros accidentes de transporte por agua, y los no especificados. otro vehiculo acuatico sin motor"
  },
  {
     id:"V949",
     enfermedad:"Otros accidentes de transporte por agua, y los no especificados: vehiculo acuatico no especificado"
  },
  {
     id:"V95",
     enfermedad:"Accidente de aeronave de motor, con ocupante lesionado"
  },
  {
     id:"V950",
     enfermedad:"Accidente de helicoptero con ocupante lesionado"
  },
  {
     id:"V951",
     enfermedad:"Accidente de planeador ultra liviano, micro liviano o motorizado, con ocupante lesionado"
  },
  {
     id:"V952",
     enfermedad:"Accidente de otros vehiculos aereos de alas fijas, privados, con ocupante lesionado"
  },
  {
     id:"V953",
     enfermedad:"Accidente de vehiculo aereo de alas fijas, comercial, con ocupante lesionado"
  },
  {
     id:"V954",
     enfermedad:"Accidente de nave espacial, con ocupante lesionado"
  },
  {
     id:"V958",
     enfermedad:"Accidente de otras aeronaves, con ocupante lesionado"
  },
  {
     id:"V959",
     enfermedad:"Accidente de aeronave no especificada, con ocupante lesionado"
  },
  {
     id:"V96",
     enfermedad:"Accidente de aeronave sin motor, con ocupante lesionado"
  },
  {
     id:"V960",
     enfermedad:"Accidente de globo aerostatico, con ocupante lesionado"
  },
  {
     id:"V961",
     enfermedad:"Accidente de ala delta, con ocupante lesionado"
  },
  {
     id:"V962",
     enfermedad:"Accidente de planeador (sin motor), con ocupante lesionado"
  },
  {
     id:"V968",
     enfermedad:"Accidente de otras aeronaves sin motor, con ocupante lesionado"
  },
  {
     id:"V969",
     enfermedad:"Accidente de aeronave sin motor no especificada, con ocupante lesionado"
  },
  {
     id:"V97",
     enfermedad:"Otros accidentes de transporte aereo especificados"
  },
  {
     id:"V970",
     enfermedad:"Ocupante de aeronave lesionado en otros accidentes especificados de transporte aereo"
  },
  {
     id:"V971",
     enfermedad:"Persona lesionada al subir o bajar de una aeronave"
  },
  {
     id:"V972",
     enfermedad:"Paracaidista lesionado en accidente de transporte aereo"
  },
  {
     id:"V973",
     enfermedad:"Persona en tierra lesionada por accidente de transporte aereo"
  },
  {
     id:"V978",
     enfermedad:"Otros accidentes de transporte aereo, no clasificados en otra parte"
  },
  {
     id:"V98",
     enfermedad:"Otros accidente de transporte especificados"
  },
  {
     id:"V99",
     enfermedad:"Accidente de transporte no especificado"
  },
  {
     id:"VI",
     enfermedad:"Enfermedades del sistema nervioso"
  },
  {
     id:"VII",
     enfermedad:"Enfermedades del ojo y sus anexos"
  },
  {
     id:"VIII",
     enfermedad:"Enfermedades del oido y de la apofisis mastoides"
  },
  {
     id:"W00",
     enfermedad:"Caida en el mismo nivel por hielo o nieve"
  },
  {
     id:"W000",
     enfermedad:"Caida en el mismo nivel por hielo o nieve: vivienda"
  },
  {
     id:"W001",
     enfermedad:"Caida en el mismo nivel por hielo o nieve: institucion residencial"
  },
  {
     id:"W002",
     enfermedad:"Caida en el mismo nivel por hielo o nieve: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W003",
     enfermedad:"Caida en el mismo nivel por hielo o nieve: areas de deporte y atletismo"
  },
  {
     id:"W004",
     enfermedad:"Caida en el mismo nivel por hielo o nieve: calles y carreteras"
  },
  {
     id:"W005",
     enfermedad:"Caida en el mismo nivel por hielo o nieve: comercio y area de servicios"
  },
  {
     id:"W006",
     enfermedad:"Caida en el mismo nivel por hielo o nieve: area industrial y de la construccion"
  },
  {
     id:"W007",
     enfermedad:"Caida en el mismo nivel por hielo o nieve: granja"
  },
  {
     id:"W008",
     enfermedad:"Caida en el mismo nivel por hielo o nieve: otro lugar especificado"
  },
  {
     id:"W009",
     enfermedad:"Caida en el mismo nivel por hielo o nieve: lugar no especificado"
  },
  {
     id:"W01",
     enfermedad:"Caida en el mismo nivel por deslizamiento, tropezon o traspie"
  },
  {
     id:"W010",
     enfermedad:"Caida en el mismo nivel por deslizamiento, tropezon y traspie: vivienda"
  },
  {
     id:"W011",
     enfermedad:"Caida en el mismo nivel por deslizamiento, tropezon y traspie: institucion residencial"
  },
  {
     id:"W012",
     enfermedad:"Caida en el mismo nivel por deslizamiento, tropezon y traspie: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W013",
     enfermedad:"Caida en el mismo nivel por deslizamiento, tropezon y traspie: areas de deporte y atletismo"
  },
  {
     id:"W014",
     enfermedad:"Caida en el mismo nivel por deslizamiento, tropezon y traspie: calles y carreteras"
  },
  {
     id:"W015",
     enfermedad:"Caida en el mismo nivel por deslizamiento, tropezon y traspie: comercio y area de servicios"
  },
  {
     id:"W016",
     enfermedad:"Caida en el mismo nivel por deslizamiento, tropezon y traspie: area industrial y de la construccion"
  },
  {
     id:"W017",
     enfermedad:"Caida en el mismo nivel por deslizamiento, tropezon y traspie: granja"
  },
  {
     id:"W018",
     enfermedad:"Caida en el mismo nivel por deslizamiento, tropezon y traspie: otro lugar especificado"
  },
  {
     id:"W019",
     enfermedad:"Caida en el mismo nivel por deslizamiento, tropezon y traspie: lugar no especificado"
  },
  {
     id:"W02",
     enfermedad:"Caida por patines para hielo, esquis, patines de ruedas o patineta"
  },
  {
     id:"W020",
     enfermedad:"Caida por patines para hielo, esquis, patines de ruedas o patineta: vivienda"
  },
  {
     id:"W021",
     enfermedad:"Caida por patines para hielo, esquis, patines de ruedas o patineta: institucion residencial"
  },
  {
     id:"W022",
     enfermedad:"Caida por patines para hielo, esquis, patines de ruedas o patineta: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W023",
     enfermedad:"Caida por patines para hielo, esquis, patines de ruedas o patineta: areas de deporte y atletismo"
  },
  {
     id:"W024",
     enfermedad:"Caida por patines para hielo, esquis, patines de ruedas o patineta: calles y carreteras"
  },
  {
     id:"W025",
     enfermedad:"Caida por patines para hielo, esquis, patines de ruedas o patineta: comercio y area de servicios"
  },
  {
     id:"W026",
     enfermedad:"Caida por patines para hielo, esquis, patines de ruedas o patineta: area industrial y de la construccion"
  },
  {
     id:"W027",
     enfermedad:"Caida por patines para hielo, esquis, patines de ruedas o patineta: granja"
  },
  {
     id:"W028",
     enfermedad:"Caida por patines para hielo, esquis, patines de ruedas o patineta: otro lugar especificado"
  },
  {
     id:"W029",
     enfermedad:"Caida por patines para hielo, esquis, patines de ruedas o patineta: lugar no especificado"
  },
  {
     id:"W03",
     enfermedad:"Otras caidas en el mismo nivel por colision o por empujon de otra persona"
  },
  {
     id:"W030",
     enfermedad:"Otras caidas en el mismo nivel por colision con o por empujon de otra persona: vivienda"
  },
  {
     id:"W031",
     enfermedad:"Otras caidas en el mismo nivel por colision con o por empujon de otra persona: institucion residencial"
  },
  {
     id:"W032",
     enfermedad:"Otras caidas en el mismo nivel por colision con o por empujon de otra persona: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W033",
     enfermedad:"Otras caidas en el mismo nivel por colision con o por empujon de otra persona: areas de deporte y atletismo"
  },
  {
     id:"W034",
     enfermedad:"Otras caidas en el mismo nivel por colision con o por empujon de otra persona: calles y carreteras"
  },
  {
     id:"W035",
     enfermedad:"Otras caidas en el mismo nivel por colision con o por empujon de otra persona: comercio y area de servicios"
  },
  {
     id:"W036",
     enfermedad:"Otras caidas en el mismo nivel por colision con o por empujon de otra persona: area industrial y de la construccion"
  },
  {
     id:"W037",
     enfermedad:"Otras caidas en el mismo nivel por colision con o por empujon de otra persona: granja"
  },
  {
     id:"W038",
     enfermedad:"Otras caidas en el mismo nivel por colision con o por empujon de otra persona: otro lugar especificado"
  },
  {
     id:"W039",
     enfermedad:"Otras caidas en el mismo nivel por colision con o por empujon de otra persona: lugar no especificado"
  },
  {
     id:"W04",
     enfermedad:"Caida al ser trasladado o sostenido por otras personas"
  },
  {
     id:"W040",
     enfermedad:"Caida al ser trasladado o sostenido por otras personas: vivienda"
  },
  {
     id:"W041",
     enfermedad:"Caida al ser trasladado o sostenido por otras personas: institucion residencial"
  },
  {
     id:"W042",
     enfermedad:"Caida al ser trasladado o sostenido por otras personas: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W043",
     enfermedad:"Caida al ser trasladado o sostenido por otras personas: areas de deporte y atletismo"
  },
  {
     id:"W044",
     enfermedad:"Caida al ser trasladado o sostenido por otras personas: calles y carreteras"
  },
  {
     id:"W045",
     enfermedad:"Caida al ser trasladado o sostenido por otras personas: comercio y area de servicios"
  },
  {
     id:"W046",
     enfermedad:"Caida al ser trasladado o sostenido por otras personas: area industrial y de la construccion"
  },
  {
     id:"W047",
     enfermedad:"Caida al ser trasladado o sostenido por otras personas: granja"
  },
  {
     id:"W048",
     enfermedad:"Caida al ser trasladado o sostenido por otras personas: otro lugar especificado"
  },
  {
     id:"W049",
     enfermedad:"Caida al ser trasladado o sostenido por otras personas: lugar no especificado"
  },
  {
     id:"W05",
     enfermedad:"Caida que implica silla de ruedas"
  },
  {
     id:"W050",
     enfermedad:"Caida que implica silla de ruedas: vivienda"
  },
  {
     id:"W051",
     enfermedad:"Caida que implica silla de ruedas: institucion residencial"
  },
  {
     id:"W052",
     enfermedad:"Caida que implica silla de ruedas: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W053",
     enfermedad:"Caida que implica silla de ruedas: areas de deporte y atletismo"
  },
  {
     id:"W054",
     enfermedad:"Caida que implica silla de ruedas: calles y carreteras"
  },
  {
     id:"W055",
     enfermedad:"Caida que implica silla de ruedas: comercio y area de servicios"
  },
  {
     id:"W056",
     enfermedad:"Caida que implica silla de ruedas: area industrial y de la construccion"
  },
  {
     id:"W057",
     enfermedad:"Caida que implica silla de ruedas: granja"
  },
  {
     id:"W058",
     enfermedad:"Caida que implica silla de ruedas: otro lugar especificado"
  },
  {
     id:"W059",
     enfermedad:"Caida que implica silla de ruedas: lugar no especificado"
  },
  {
     id:"W06",
     enfermedad:"Caida que implica cama"
  },
  {
     id:"W060",
     enfermedad:"Caida que implica cama: vivienda"
  },
  {
     id:"W061",
     enfermedad:"Caida que implica cama: institucion residencial"
  },
  {
     id:"W062",
     enfermedad:"Caida que implica cama: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W063",
     enfermedad:"Caida que implica cama: areas de deporte y atletismo"
  },
  {
     id:"W064",
     enfermedad:"Caida que implica cama: calles y carreteras"
  },
  {
     id:"W065",
     enfermedad:"Caida que implica cama: comercio y area de servicios"
  },
  {
     id:"W066",
     enfermedad:"Caida que implica cama: area industrial y de la construccion"
  },
  {
     id:"W067",
     enfermedad:"Caida que implica cama: granja"
  },
  {
     id:"W068",
     enfermedad:"Caida que implica cama: otro lugar especificado"
  },
  {
     id:"W069",
     enfermedad:"Caida que implica cama: lugar no especificado"
  },
  {
     id:"W07",
     enfermedad:"Caida que implica silla"
  },
  {
     id:"W070",
     enfermedad:"Caida que implica silla: vivienda"
  },
  {
     id:"W071",
     enfermedad:"Caida que implica silla: institucion residencial"
  },
  {
     id:"W072",
     enfermedad:"Caida que implica silla: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W073",
     enfermedad:"Caida que implica silla: areas de deporte y atletismo"
  },
  {
     id:"W074",
     enfermedad:"Caida que implica silla: calles y carreteras"
  },
  {
     id:"W075",
     enfermedad:"Caida que implica silla: comercio y area de servicios"
  },
  {
     id:"W076",
     enfermedad:"Caida que implica silla: area industrial y de la construccion"
  },
  {
     id:"W077",
     enfermedad:"Caida que implica silla: granja"
  },
  {
     id:"W078",
     enfermedad:"Caida que implica silla: otro lugar especificado"
  },
  {
     id:"W079",
     enfermedad:"Caida que implica silla: lugar no especificado"
  },
  {
     id:"W08",
     enfermedad:"Caida que implica otro mueble"
  },
  {
     id:"W080",
     enfermedad:"Caida que implica otro mueble: vivienda"
  },
  {
     id:"W081",
     enfermedad:"Caida que implica otro mueble: institucion residencial"
  },
  {
     id:"W082",
     enfermedad:"Caida que implica otro mueble: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W083",
     enfermedad:"Caida que implica otro mueble: areas de deporte y atletismo"
  },
  {
     id:"W084",
     enfermedad:"Caida que implica otro mueble: calles y carreteras"
  },
  {
     id:"W085",
     enfermedad:"Caida que implica otro mueble: comercio y area de servicios"
  },
  {
     id:"W086",
     enfermedad:"Caida que implica otro mueble: area industrial y de la construccion"
  },
  {
     id:"W087",
     enfermedad:"Caida que implica otro mueble: granja"
  },
  {
     id:"W088",
     enfermedad:"Caida que implica otro mueble: otro lugar especificado"
  },
  {
     id:"W089",
     enfermedad:"Caida que implica otro mueble: lugar no especificado"
  },
  {
     id:"W09",
     enfermedad:"Caida que implica equipos para juegos infantiles"
  },
  {
     id:"W090",
     enfermedad:"Caida que implica equipos para juegos infantiles: vivienda"
  },
  {
     id:"W091",
     enfermedad:"Caida que implica equipos para juegos infantiles: institucion residencial"
  },
  {
     id:"W092",
     enfermedad:"Caida que implica equipos para juegos infantiles: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W093",
     enfermedad:"Caida que implica equipos para juegos infantiles: areas de deporte y atletismo"
  },
  {
     id:"W094",
     enfermedad:"Caida que implica equipos para juegos infantiles: calles y carreteras"
  },
  {
     id:"W095",
     enfermedad:"Caida que implica equipos para juegos infantiles: comercio y area de servicios"
  },
  {
     id:"W096",
     enfermedad:"Caida que implica equipos para juegos infantiles: area industrial y de la construccion"
  },
  {
     id:"W097",
     enfermedad:"Caida que implica equipos para juegos infantiles: granja"
  },
  {
     id:"W098",
     enfermedad:"Caida que implica equipos para juegos infantiles: otro lugar especificado"
  },
  {
     id:"W099",
     enfermedad:"Caida que implica equipos para juegos infantiles: lugar no especificado"
  },
  {
     id:"W10",
     enfermedad:"Caida en o desde escalera y escalones"
  },
  {
     id:"W100",
     enfermedad:"Caida en o desde escalera y escalones: vivienda"
  },
  {
     id:"W101",
     enfermedad:"Caida en o desde escalera y escalones: institucion residencial"
  },
  {
     id:"W102",
     enfermedad:"Caida en o desde escalera y escalones: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W103",
     enfermedad:"Caida en o desde escalera y escalones: areas de deporte y atletismo"
  },
  {
     id:"W104",
     enfermedad:"Caida en o desde escalera y escalones: calles y carreteras"
  },
  {
     id:"W105",
     enfermedad:"Caida en o desde escalera y escalones: comercio y area de servicios"
  },
  {
     id:"W106",
     enfermedad:"Caida en o desde escalera y escalones: area industrial y de la construccion"
  },
  {
     id:"W107",
     enfermedad:"Caida en o desde escalera y escalones: granja"
  },
  {
     id:"W108",
     enfermedad:"Caida en o desde escalera y escalones: otro lugar especificado"
  },
  {
     id:"W109",
     enfermedad:"Caida en o desde escalera y escalones: lugar no especificado"
  },
  {
     id:"W11",
     enfermedad:"Caida en o desde escaleras manuales"
  },
  {
     id:"W110",
     enfermedad:"Caida en o desde escaleras manuales: vivienda"
  },
  {
     id:"W111",
     enfermedad:"Caida en o desde escaleras manuales: institucion residencial"
  },
  {
     id:"W112",
     enfermedad:"Caida en o desde escaleras manuales: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W113",
     enfermedad:"Caida en o desde escaleras manuales: areas de deporte y atletismo"
  },
  {
     id:"W114",
     enfermedad:"Caida en o desde escaleras manuales: calles y carreteras"
  },
  {
     id:"W115",
     enfermedad:"Caida en o desde escaleras manuales: comercio y area de servicios"
  },
  {
     id:"W116",
     enfermedad:"Caida en o desde escaleras manuales: area industrial y de la construccion"
  },
  {
     id:"W117",
     enfermedad:"Caida en o desde escaleras manuales: granja"
  },
  {
     id:"W118",
     enfermedad:"Caida en o desde escaleras manuales: otro lugar especificado"
  },
  {
     id:"W119",
     enfermedad:"Caida en o desde escaleras manuales: lugar no especificado"
  },
  {
     id:"W12",
     enfermedad:"Caida en o desde andamio"
  },
  {
     id:"W120",
     enfermedad:"Caida en o desde andamio: vivienda"
  },
  {
     id:"W121",
     enfermedad:"Caida en o desde andamio: institucion residencial"
  },
  {
     id:"W122",
     enfermedad:"Caida en o desde andamio: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W123",
     enfermedad:"Caida en o desde andamio: areas de deporte y atletismo"
  },
  {
     id:"W124",
     enfermedad:"Caida en o desde andamio: calles y carreteras"
  },
  {
     id:"W125",
     enfermedad:"Caida en o desde andamio: comercio y area de servicios"
  },
  {
     id:"W126",
     enfermedad:"Caida en o desde andamio: area industrial y de la construccion"
  },
  {
     id:"W127",
     enfermedad:"Caida en o desde andamio: granja"
  },
  {
     id:"W128",
     enfermedad:"Caida en o desde andamio: otro lugar especificado"
  },
  {
     id:"W129",
     enfermedad:"Caida en o desde andamio: lugar no especificado"
  },
  {
     id:"W13",
     enfermedad:"Caida desde, fuera o a traves de un edificio u otra construccion"
  },
  {
     id:"W130",
     enfermedad:"Caida desde, fuera o a traves de un edificio u otra construccion: vivienda"
  },
  {
     id:"W131",
     enfermedad:"Caida desde, fuera o a traves de un edificio u otra construccion: institucion residencial"
  },
  {
     id:"W132",
     enfermedad:"Caida desde, fuera o a traves de un edificio u otra construccion: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W133",
     enfermedad:"Caida desde, fuera o a traves de un edificio u otra construccion: areas de deporte y atletismo"
  },
  {
     id:"W134",
     enfermedad:"Caida desde, fuera o a traves de un edificio u otra construccion: calles y carreteras"
  },
  {
     id:"W135",
     enfermedad:"Caida desde, fuera o a traves de un edificio u otra construccion: comercio y area de servicios"
  },
  {
     id:"W136",
     enfermedad:"Caida desde, fuera o a traves de un edificio u otra construccion: area industrial y de la construccion"
  },
  {
     id:"W137",
     enfermedad:"Caida desde, fuera o a traves de un edificio u otra construccion: granja"
  },
  {
     id:"W138",
     enfermedad:"Caida desde, fuera o a traves de un edificio u otra construccion: otro lugar especificado"
  },
  {
     id:"W139",
     enfermedad:"Caida desde, fuera o a traves de un edificio u otra construccion: lugar no especificado"
  },
  {
     id:"W14",
     enfermedad:"Caida desde un arbol"
  },
  {
     id:"W140",
     enfermedad:"Caida desde un arbol: vivienda"
  },
  {
     id:"W141",
     enfermedad:"Caida desde un arbol: institucion residencial"
  },
  {
     id:"W142",
     enfermedad:"Caida desde un arbol: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W143",
     enfermedad:"Caida desde un arbol: areas de deporte y atletismo"
  },
  {
     id:"W144",
     enfermedad:"Caida desde un arbol: calles y carreteras"
  },
  {
     id:"W145",
     enfermedad:"Caida desde un arbol: comercio y area de servicios"
  },
  {
     id:"W146",
     enfermedad:"Caida desde un arbol: area industrial y de la construccion"
  },
  {
     id:"W147",
     enfermedad:"Caida desde un arbol: granja"
  },
  {
     id:"W148",
     enfermedad:"Caida desde un arbol: otro lugar especificado"
  },
  {
     id:"W149",
     enfermedad:"Caida desde un arbol: lugar no especificado"
  },
  {
     id:"W15",
     enfermedad:"Caida desde peñasco"
  },
  {
     id:"W150",
     enfermedad:"Caida desde peñasco: vivienda"
  },
  {
     id:"W151",
     enfermedad:"Caida desde peñasco: institucion residencial"
  },
  {
     id:"W152",
     enfermedad:"Caida desde peñasco: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W153",
     enfermedad:"Caida desde peñasco: areas de deporte y atletismo"
  },
  {
     id:"W154",
     enfermedad:"Caida desde peñasco: calles y carreteras"
  },
  {
     id:"W155",
     enfermedad:"Caida desde peñasco: comercio y area de servicios"
  },
  {
     id:"W156",
     enfermedad:"Caida desde peñasco: area industrial y de la construccion"
  },
  {
     id:"W157",
     enfermedad:"Caida desde peñasco: granja"
  },
  {
     id:"W158",
     enfermedad:"Caida desde peñasco: otro lugar especificado"
  },
  {
     id:"W159",
     enfermedad:"Caida desde peñasco: lugar no especificado"
  },
  {
     id:"W16",
     enfermedad:"Salto o zambullida dentro del agua que causa otro traumatismo sin sumersion o ahogamiento"
  },
  {
     id:"W160",
     enfermedad:"Salto o zambullida dentro del agua que causa otro traumatismo sin sumersion o ahogamiento: vivienda"
  },
  {
     id:"W161",
     enfermedad:"Salto o zambullida dentro del agua que causa otro traumatismo sin sumersion o ahogamiento: institucion residencial"
  },
  {
     id:"W162",
     enfermedad:"Salto o zambullida dentro del agua que causa otro traumatismo sin sumersion o ahogamiento: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W163",
     enfermedad:"Salto o zambullida dentro del agua que causa otro traumatismo sin sumersion o ahogamiento: areas de deporte y atletismo"
  },
  {
     id:"W164",
     enfermedad:"Salto o zambullida dentro del agua que causa otro traumatismo sin sumersion o ahogamiento: calles y carreteras"
  },
  {
     id:"W165",
     enfermedad:"Salto o zambullida dentro del agua que causa otro traumatismo sin sumersion o ahogamiento: comercio y area de servicios"
  },
  {
     id:"W166",
     enfermedad:"Salto o zambullida dentro del agua que causa otro traumatismo sin sumersion o ahogamiento: area industrial y de la construccion"
  },
  {
     id:"W167",
     enfermedad:"Salto o zambullida dentro del agua que causa otro traumatismo sin sumersion o ahogamiento: granja"
  },
  {
     id:"W168",
     enfermedad:"Salto o zambullida dentro del agua que causa otro traumatismo sin sumersion o ahogamiento: otro lugar especificado"
  },
  {
     id:"W169",
     enfermedad:"Salto o zambullida dentro del agua que causa otro traumatismo sin sumersion o ahogamiento: lugar no especificado"
  },
  {
     id:"W17",
     enfermedad:"Otras caidas de un nivel a otro"
  },
  {
     id:"W170",
     enfermedad:"Otras caidas de un nivel a otro: vivienda"
  },
  {
     id:"W171",
     enfermedad:"Otras caidas de un nivel a otro: institucion residencial"
  },
  {
     id:"W172",
     enfermedad:"Otras caidas de un nivel a otro: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W173",
     enfermedad:"Otras caidas de un nivel a otro: areas de deporte y atletismo"
  },
  {
     id:"W174",
     enfermedad:"Otras caidas de un nivel a otro: calles y carreteras"
  },
  {
     id:"W175",
     enfermedad:"Otras caidas de un nivel a otro: comercio y area de servicios"
  },
  {
     id:"W176",
     enfermedad:"Otras caidas de un nivel a otro: area industrial y de la construccion"
  },
  {
     id:"W177",
     enfermedad:"Otras caidas de un nivel a otro: granja"
  },
  {
     id:"W178",
     enfermedad:"Otras caidas de un nivel a otro: otro lugar especificado"
  },
  {
     id:"W179",
     enfermedad:"Otras caidas de un nivel a otro: lugar no especificado"
  },
  {
     id:"W18",
     enfermedad:"Otras caidas en el mismo nivel"
  },
  {
     id:"W180",
     enfermedad:"Otras caidas en el mismo nivel: vivienda"
  },
  {
     id:"W181",
     enfermedad:"Otras caidas en el mismo nivel: institucion residencial"
  },
  {
     id:"W182",
     enfermedad:"Otras caidas en el mismo nivel: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W183",
     enfermedad:"Otras caidas en el mismo nivel: areas de deporte y atletismo"
  },
  {
     id:"W184",
     enfermedad:"Otras caidas en el mismo nivel: calles y carreteras"
  },
  {
     id:"W185",
     enfermedad:"Otras caidas en el mismo nivel: comercio y area de servicios"
  },
  {
     id:"W186",
     enfermedad:"Otras caidas en el mismo nivel: area industrial y de la construccion"
  },
  {
     id:"W187",
     enfermedad:"Otras caidas en el mismo nivel: granja"
  },
  {
     id:"W188",
     enfermedad:"Otras caidas en el mismo nivel: otro lugar especificado"
  },
  {
     id:"W189",
     enfermedad:"Otras caidas en el mismo nivel: lugar no especificado"
  },
  {
     id:"W19",
     enfermedad:"Caida no especificada"
  },
  {
     id:"W190",
     enfermedad:"Caida no especificada: vivienda"
  },
  {
     id:"W191",
     enfermedad:"Caida no especificada: institucion residencial"
  },
  {
     id:"W192",
     enfermedad:"Caida no especificada: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W193",
     enfermedad:"Caida no especificada: areas de deporte y atletismo"
  },
  {
     id:"W194",
     enfermedad:"Caida no especificada: calles y carreteras"
  },
  {
     id:"W195",
     enfermedad:"Caida no especificada: comercio y area de servicios"
  },
  {
     id:"W196",
     enfermedad:"Caida no especificada: area industrial y de la construccion"
  },
  {
     id:"W197",
     enfermedad:"Caida no especificada: granja"
  },
  {
     id:"W198",
     enfermedad:"Caida no especificada: otro lugar especificado"
  },
  {
     id:"W199",
     enfermedad:"Caida no especificada: lugar no especificado"
  },
  {
     id:"W20",
     enfermedad:"Golpe por objeto arrojado, proyectado o que cae"
  },
  {
     id:"W200",
     enfermedad:"Golpe por objeto arrojado, proyectado o que cae: vivienda"
  },
  {
     id:"W201",
     enfermedad:"Golpe por objeto arrojado, proyectado o que cae: institucion residencial"
  },
  {
     id:"W202",
     enfermedad:"Golpe por objeto arrojado, proyectado o que cae: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W203",
     enfermedad:"Golpe por objeto arrojado, proyectado o que cae: areas de deporte y atletismo"
  },
  {
     id:"W204",
     enfermedad:"Golpe por objeto arrojado, proyectado o que cae: calles y carreteras"
  },
  {
     id:"W205",
     enfermedad:"Golpe por objeto arrojado, proyectado o que cae: comercio y area de servicios"
  },
  {
     id:"W206",
     enfermedad:"Golpe por objeto arrojado, proyectado o que cae: area industrial y de la construccion"
  },
  {
     id:"W207",
     enfermedad:"Golpe por objeto arrojado, proyectado o que cae: granja"
  },
  {
     id:"W208",
     enfermedad:"Golpe por objeto arrojado, proyectado o que cae: otro lugar especificado"
  },
  {
     id:"W209",
     enfermedad:"Golpe por objeto arrojado, proyectado o que cae: lugar no especificado"
  },
  {
     id:"W21",
     enfermedad:"Golpe contra o golpeado por equipo para deportes"
  },
  {
     id:"W210",
     enfermedad:"Golpe contra o golpeado por equipo para deportes: vivienda"
  },
  {
     id:"W211",
     enfermedad:"Golpe contra o golpeado por equipo para deportes: institucion residencial"
  },
  {
     id:"W212",
     enfermedad:"Golpe contra o golpeado por equipo para deportes: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W213",
     enfermedad:"Golpe contra o golpeado por equipo para deportes: areas de deporte y atletismo"
  },
  {
     id:"W214",
     enfermedad:"Golpe contra o golpeado por equipo para deportes: calles y carreteras"
  },
  {
     id:"W215",
     enfermedad:"Golpe contra o golpeado por equipo para deportes: comercio y area de servicios"
  },
  {
     id:"W216",
     enfermedad:"Golpe contra o golpeado por equipo para deportes: area industrial y de la construccion"
  },
  {
     id:"W217",
     enfermedad:"Golpe contra o golpeado por equipo para deportes: granja"
  },
  {
     id:"W218",
     enfermedad:"Golpe contra o golpeado por equipo para deportes: otro lugar especificado"
  },
  {
     id:"W219",
     enfermedad:"Golpe contra o golpeado por equipo para deportes: lugar no especificado"
  },
  {
     id:"W22",
     enfermedad:"Golpe contra o golpeado por otros objetos"
  },
  {
     id:"W220",
     enfermedad:"Golpe contra o golpeado por otros objetos: vivienda"
  },
  {
     id:"W221",
     enfermedad:"Golpe contra o golpeado por otros objetos: institucion residencial"
  },
  {
     id:"W222",
     enfermedad:"Golpe contra o golpeado por otros objetos: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W223",
     enfermedad:"Golpe contra o golpeado por otros objetos: areas de deporte y atletismo"
  },
  {
     id:"W224",
     enfermedad:"Golpe contra o golpeado por otros objetos: calles y carreteras"
  },
  {
     id:"W225",
     enfermedad:"Golpe contra o golpeado por otros objetos: comercio y area de servicios"
  },
  {
     id:"W226",
     enfermedad:"Golpe contra o golpeado por otros objetos: area industrial y de la construccion"
  },
  {
     id:"W227",
     enfermedad:"Golpe contra o golpeado por otros objetos: granja"
  },
  {
     id:"W228",
     enfermedad:"Golpe contra o golpeado por otros objetos: otro lugar especificado"
  },
  {
     id:"W229",
     enfermedad:"Golpe contra o golpeado por otros objetos: lugar no especificado"
  },
  {
     id:"W23",
     enfermedad:"Atrapado, aplastado, trabado o apretado en o entre objetos"
  },
  {
     id:"W230",
     enfermedad:"Atrapado, aplastado, trabado o apretado en o entre objetos: vivienda"
  },
  {
     id:"W231",
     enfermedad:"Atrapado, aplastado, trabado o apretado en o entre objetos: institucion residencial"
  },
  {
     id:"W232",
     enfermedad:"Atrapado, aplastado, trabado o apretado en o entre objetos: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W233",
     enfermedad:"Atrapado, aplastado, trabado o apretado en o entre objetos: areas de deporte y atletismo"
  },
  {
     id:"W234",
     enfermedad:"Atrapado, aplastado, trabado o apretado en o entre objetos: calles y carreteras"
  },
  {
     id:"W235",
     enfermedad:"Atrapado, aplastado, trabado o apretado en o entre objetos: comercio y area de servicios"
  },
  {
     id:"W236",
     enfermedad:"Atrapado, aplastado, trabado o apretado en o entre objetos: area industrial y de la construccion"
  },
  {
     id:"W237",
     enfermedad:"Atrapado, aplastado, trabado o apretado en o entre objetos: granja"
  },
  {
     id:"W238",
     enfermedad:"Atrapado, aplastado, trabado o apretado en o entre objetos: otro lugar especificado"
  },
  {
     id:"W239",
     enfermedad:"Atrapado, aplastado, trabado o apretado en o entre objetos: lugar no especificado"
  },
  {
     id:"W24",
     enfermedad:"Contacto traumatico con dispositivos de elevacion y transmision, no clasificadas en otra parte"
  },
  {
     id:"W240",
     enfermedad:"Contacto traumatico con dispositivos de elevacion y transmision, no clasificados en otra parte: vivienda"
  },
  {
     id:"W241",
     enfermedad:"Contacto traumatico con dispositivos de elevacion y transmision, no clasificados en otra parte: institucion residencial"
  },
  {
     id:"W242",
     enfermedad:"Contacto traumatico con dispositivos de elevacion y transmision, no clasificados en otra parte: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W243",
     enfermedad:"Contacto traumatico con dispositivos de elevacion y transmision, no clasificados en otra parte: areas de deporte y atletismo"
  },
  {
     id:"W244",
     enfermedad:"Contacto traumatico con dispositivos de elevacion y transmision, no clasificados en otra parte: calles y carreteras"
  },
  {
     id:"W245",
     enfermedad:"Contacto traumatico con dispositivos de elevacion y transmision, no clasificados en otra parte: comercio y area de servicios"
  },
  {
     id:"W246",
     enfermedad:"Contacto traumatico con dispositivos de elevacion y transmision, no clasificados en otra parte: area industrial y de la construccion"
  },
  {
     id:"W247",
     enfermedad:"Contacto traumatico con dispositivos de elevacion y transmision, no clasificados en otra parte: granja"
  },
  {
     id:"W248",
     enfermedad:"Contacto traumatico con dispositivos de elevacion y transmision, no clasificados en otra parte: otro lugar especificado"
  },
  {
     id:"W249",
     enfermedad:"Contacto traumatico con dispositivos de elevacion y transmision, no clasificados en otra parte: lugar no especificado"
  },
  {
     id:"W25",
     enfermedad:"Contacto traumatico con vidrio cortante"
  },
  {
     id:"W250",
     enfermedad:"Contacto traumatico con vidrio cortante: vivienda"
  },
  {
     id:"W251",
     enfermedad:"Contacto traumatico con vidrio cortante: institucion residencial"
  },
  {
     id:"W252",
     enfermedad:"Contacto traumatico con vidrio cortante: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W253",
     enfermedad:"Contacto traumatico con vidrio cortante: areas de deporte y atletismo"
  },
  {
     id:"W254",
     enfermedad:"Contacto traumatico con vidrio cortante: calles y carreteras"
  },
  {
     id:"W255",
     enfermedad:"Contacto traumatico con vidrio cortante: comercio y area de servicios"
  },
  {
     id:"W256",
     enfermedad:"Contacto traumatico con vidrio cortante: area industrial y de la construccion"
  },
  {
     id:"W257",
     enfermedad:"Contacto traumatico con vidrio cortante: granja"
  },
  {
     id:"W258",
     enfermedad:"Contacto traumatico con vidrio cortante: otro lugar especificado"
  },
  {
     id:"W259",
     enfermedad:"Contacto traumatico con vidrio cortante: lugar no especificado"
  },
  {
     id:"W26",
     enfermedad:"Contacto traumatico con cuchillo, espada, daga o puñal"
  },
  {
     id:"W260",
     enfermedad:"Contacto traumatico con cuchillo, espada, daga o puñal: vivienda"
  },
  {
     id:"W261",
     enfermedad:"Contacto traumatico con cuchillo, espada, daga o puñal: institucion residencial"
  },
  {
     id:"W262",
     enfermedad:"Contacto traumatico con cuchillo, espada, daga o puñal: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W263",
     enfermedad:"Contacto traumatico con cuchillo, espada, daga o puñal: areas de deporte y atletismo"
  },
  {
     id:"W264",
     enfermedad:"Contacto traumatico con cuchillo, espada, daga o puñal: calles y carreteras"
  },
  {
     id:"W265",
     enfermedad:"Contacto traumatico con cuchillo, espada, daga o puñal: comercio y area de servicios"
  },
  {
     id:"W266",
     enfermedad:"Contacto traumatico con cuchillo, espada, daga o puñal: area industrial y de la construccion"
  },
  {
     id:"W267",
     enfermedad:"Contacto traumatico con cuchillo, espada, daga o puñal: granja"
  },
  {
     id:"W268",
     enfermedad:"Contacto traumatico con cuchillo, espada, daga o puñal: otro lugar especificado"
  },
  {
     id:"W269",
     enfermedad:"Contacto traumatico con cuchillo, espada, daga o puñal: lugar no especificado"
  },
  {
     id:"W27",
     enfermedad:"Contacto traumatico con herramientas manuales sin motor"
  },
  {
     id:"W270",
     enfermedad:"Contacto traumatico con herramientas manuales sin motor: vivienda"
  },
  {
     id:"W271",
     enfermedad:"Contacto traumatico con herramientas manuales sin motor: institucion residencial"
  },
  {
     id:"W272",
     enfermedad:"Contacto traumatico con herramientas manuales sin motor: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W273",
     enfermedad:"Contacto traumatico con herramientas manuales sin motor: areas de deporte y atletismo"
  },
  {
     id:"W274",
     enfermedad:"Contacto traumatico con herramientas manuales sin motor: calles y carreteras"
  },
  {
     id:"W275",
     enfermedad:"Contacto traumatico con herramientas manuales sin motor: comercio y area de servicios"
  },
  {
     id:"W276",
     enfermedad:"Contacto traumatico con herramientas manuales sin motor: area industrial y de la construccion"
  },
  {
     id:"W277",
     enfermedad:"Contacto traumatico con herramientas manuales sin motor: granja"
  },
  {
     id:"W278",
     enfermedad:"Contacto traumatico con herramientas manuales sin motor: otro lugar especificado"
  },
  {
     id:"W279",
     enfermedad:"Contacto traumatico con herramientas manuales sin motor: lugar no especificado"
  },
  {
     id:"W28",
     enfermedad:"Contacto traumatico con cortadora de cesped, con motor"
  },
  {
     id:"W280",
     enfermedad:"Contacto traumatico con cortadora de cesped, con motor: vivienda"
  },
  {
     id:"W281",
     enfermedad:"Contacto traumatico con cortadora de cesped, con motor: institucion residencial"
  },
  {
     id:"W282",
     enfermedad:"Contacto traumatico con cortadora de cesped, con motor: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W283",
     enfermedad:"Contacto traumatico con cortadora de cesped, con motor: areas de deporte y atletismo"
  },
  {
     id:"W284",
     enfermedad:"Contacto traumatico con cortadora de cesped, con motor: calles y carreteras"
  },
  {
     id:"W285",
     enfermedad:"Contacto traumatico con cortadora de cesped, con motor: comercio y area de servicios"
  },
  {
     id:"W286",
     enfermedad:"Contacto traumatico con cortadora de cesped, con motor: area industrial y de la construccion"
  },
  {
     id:"W287",
     enfermedad:"Contacto traumatico con cortadora de cesped, con motor: granja"
  },
  {
     id:"W288",
     enfermedad:"Contacto traumatico con cortadora de cesped, con motor: otro lugar especificado"
  },
  {
     id:"W289",
     enfermedad:"Contacto traumatico con cortadora de cesped, con motor: lugar no especificado"
  },
  {
     id:"W29",
     enfermedad:"Contacto traumatico con otras herramientas manuales y artefactos de hogar, con motor"
  },
  {
     id:"W290",
     enfermedad:"Contacto traumatico con otras herramientas manuales y artefactos del hogar, con motor: vivienda"
  },
  {
     id:"W291",
     enfermedad:"Contacto traumatico con otras herramientas manuales y artefactos del hogar, con motor: institucion residencial"
  },
  {
     id:"W292",
     enfermedad:"Contacto traumatico con otras herramientas manuales y artefactos del hogar, con motor: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W293",
     enfermedad:"Contacto traumatico con otras herramientas manuales y artefactos del hogar, con motor: areas de deporte y atletismo"
  },
  {
     id:"W294",
     enfermedad:"Contacto traumatico con otras herramientas manuales y artefactos del hogar, con motor: calles y carreteras"
  },
  {
     id:"W295",
     enfermedad:"Contacto traumatico con otras herramientas manuales y artefactos del hogar, con motor: comercio y area de servicios"
  },
  {
     id:"W296",
     enfermedad:"Contacto traumatico con otras herramientas manuales y artefactos del hogar, con motor: area industrial y de la construccion"
  },
  {
     id:"W297",
     enfermedad:"Contacto traumatico con otras herramientas manuales y artefactos del hogar, con motor: granja"
  },
  {
     id:"W298",
     enfermedad:"Contacto traumatico con otras herramientas manuales y artefactos del hogar, con motor: otro lugar especificado"
  },
  {
     id:"W299",
     enfermedad:"Contacto traumatico con otras herramientas manuales y artefactos del hogar, con motor: lugar no especificado"
  },
  {
     id:"W30",
     enfermedad:"Contacto traumatico con maquinaria agricola"
  },
  {
     id:"W300",
     enfermedad:"Contacto traumatico con maquinaria agricola: vivienda"
  },
  {
     id:"W301",
     enfermedad:"Contacto traumatico con maquinaria agricola: institucion residencial"
  },
  {
     id:"W302",
     enfermedad:"Contacto traumatico con maquinaria agricola: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W303",
     enfermedad:"Contacto traumatico con maquinaria agricola: areas de deporte y atletismo"
  },
  {
     id:"W304",
     enfermedad:"Contacto traumatico con maquinaria agricola: calles y carreteras"
  },
  {
     id:"W305",
     enfermedad:"Contacto traumatico con maquinaria agricola: comercio y area de servicios"
  },
  {
     id:"W306",
     enfermedad:"Contacto traumatico con maquinaria agricola: area industrial y de la construccion"
  },
  {
     id:"W307",
     enfermedad:"Contacto traumatico con maquinaria agricola: granja"
  },
  {
     id:"W308",
     enfermedad:"Contacto traumatico con maquinaria agricola: otro lugar especificado"
  },
  {
     id:"W309",
     enfermedad:"Contacto traumatico con maquinaria agricola: lugar no especificado"
  },
  {
     id:"W31",
     enfermedad:"Contacto traumatico con otras maquinarias y las no especificadas"
  },
  {
     id:"W310",
     enfermedad:"Contacto traumatico con otras maquinarias y las no especificadas: vivienda"
  },
  {
     id:"W311",
     enfermedad:"Contacto traumatico con otras maquinarias y las no especificadas: institucion residencial"
  },
  {
     id:"W312",
     enfermedad:"Contacto traumatico con otras maquinarias y las no especificadas: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W313",
     enfermedad:"Contacto traumatico con otras maquinarias y las no especificadas: areas de deporte y atletismo"
  },
  {
     id:"W314",
     enfermedad:"Contacto traumatico con otras maquinarias y las no especificadas: calles y carreteras"
  },
  {
     id:"W315",
     enfermedad:"Contacto traumatico con otras maquinarias y las no especificadas: comercio y area de servicios"
  },
  {
     id:"W316",
     enfermedad:"Contacto traumatico con otras maquinarias y las no especificadas: area industrial y de la construccion"
  },
  {
     id:"W317",
     enfermedad:"Contacto traumatico con otras maquinarias y las no especificadas: granja"
  },
  {
     id:"W318",
     enfermedad:"Contacto traumatico con otras maquinarias y las no especificadas: otro lugar especificado"
  },
  {
     id:"W319",
     enfermedad:"Contacto traumatico con otras maquinarias y las no especificadas: lugar no especificado"
  },
  {
     id:"W32",
     enfermedad:"Disparo de arma corta"
  },
  {
     id:"W320",
     enfermedad:"Disparo de arma corta: vivienda"
  },
  {
     id:"W321",
     enfermedad:"Disparo de arma corta: institucion residencial"
  },
  {
     id:"W322",
     enfermedad:"Disparo de arma corta: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W323",
     enfermedad:"Disparo de arma corta: areas de deporte y atletismo"
  },
  {
     id:"W324",
     enfermedad:"Disparo de arma corta: calles y carreteras"
  },
  {
     id:"W325",
     enfermedad:"Disparo de arma corta: comercio y areas de servicio"
  },
  {
     id:"W326",
     enfermedad:"Disparo de arma corta: area industrial y de la construccion"
  },
  {
     id:"W327",
     enfermedad:"Disparo de arma corta: granja"
  },
  {
     id:"W328",
     enfermedad:"Disparo de arma corta: otro lugar especificado"
  },
  {
     id:"W329",
     enfermedad:"Disparo de arma corta: lugar no especificado"
  },
  {
     id:"W33",
     enfermedad:"Disparo de rifle, escopeta y arma larga"
  },
  {
     id:"W330",
     enfermedad:"Disparo de rifle, escopeta y arma larga: vivienda"
  },
  {
     id:"W331",
     enfermedad:"Disparo de rifle, escopeta y arma larga: institucion residencial"
  },
  {
     id:"W332",
     enfermedad:"Disparo de rifle, escopeta y arma larga: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W333",
     enfermedad:"Disparo de rifle, escopeta y arma larga: areas de deporte y atletismo"
  },
  {
     id:"W334",
     enfermedad:"Disparo de rifle, escopeta y arma larga: calles y carreteras"
  },
  {
     id:"W335",
     enfermedad:"Disparo de rifle, escopeta y arma larga: comercio y areas de servicio"
  },
  {
     id:"W336",
     enfermedad:"Disparo de rifle, escopeta y arma larga: area industrial y de la construccion"
  },
  {
     id:"W337",
     enfermedad:"Disparo de rifle, escopeta y arma larga: granja"
  },
  {
     id:"W338",
     enfermedad:"Disparo de rifle, escopeta y arma larga: otro lugar especificado"
  },
  {
     id:"W339",
     enfermedad:"Disparo de rifle, escopeta y arma larga: lugar no especificado"
  },
  {
     id:"W34",
     enfermedad:"Disparo con otras armas fuego y las no especificadas"
  },
  {
     id:"W340",
     enfermedad:"Disparo de otras armas de fuego, y las no especificadas: vivienda"
  },
  {
     id:"W341",
     enfermedad:"Disparo de otras armas de fuego, y las no especificadas: institucion residencial"
  },
  {
     id:"W342",
     enfermedad:"Disparo de otras armas de fuego, y las no especificadas: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W343",
     enfermedad:"Disparo de otras armas de fuego, y las no especificadas: areas de deporte y atletismo"
  },
  {
     id:"W344",
     enfermedad:"Disparo de otras armas de fuego, y las no especificadas: calles y carreteras"
  },
  {
     id:"W345",
     enfermedad:"Disparo de otras armas de fuego, y las no especificadas: comercio y areas de servicio"
  },
  {
     id:"W346",
     enfermedad:"Disparo de otras armas de fuego, y las no especificadas: area industrial y de la construccion"
  },
  {
     id:"W347",
     enfermedad:"Disparo de otras armas de fuego, y las no especificadas: granja"
  },
  {
     id:"W348",
     enfermedad:"Disparo de otras armas de fuego, y las no especificadas: otro lugar especificado"
  },
  {
     id:"W349",
     enfermedad:"Disparo de otras armas de fuego, y las no especificadas: lugar no especificado"
  },
  {
     id:"W35",
     enfermedad:"Explosion y rotura de caldera"
  },
  {
     id:"W350",
     enfermedad:"Explosion y rotura de caldera: vivienda"
  },
  {
     id:"W351",
     enfermedad:"Explosion y rotura de caldera: institucion residencial"
  },
  {
     id:"W352",
     enfermedad:"Explosion y rotura de caldera: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W353",
     enfermedad:"Explosion y rotura de caldera: areas de deporte y atletismo"
  },
  {
     id:"W354",
     enfermedad:"Explosion y rotura de caldera: calles y carreteras"
  },
  {
     id:"W355",
     enfermedad:"Explosion y rotura de caldera: comercio y areas de servicio"
  },
  {
     id:"W356",
     enfermedad:"Explosion y rotura de caldera: area industrial y de la construccion"
  },
  {
     id:"W357",
     enfermedad:"Explosion y rotura de caldera: granja"
  },
  {
     id:"W358",
     enfermedad:"Explosion y rotura de caldera: otro lugar especificado"
  },
  {
     id:"W359",
     enfermedad:"Explosion y rotura de caldera: lugar no especificado"
  },
  {
     id:"W36",
     enfermedad:"Explosion y rotura de cilindro con gas"
  },
  {
     id:"W360",
     enfermedad:"Explosion y rotura de cilindro con gas: vivienda"
  },
  {
     id:"W361",
     enfermedad:"Explosion y rotura de cilindro con gas: institucion residencial"
  },
  {
     id:"W362",
     enfermedad:"Explosion y rotura de cilindro con gas: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W363",
     enfermedad:"Explosion y rotura de cilindro con gas: areas de deporte y atletismo"
  },
  {
     id:"W364",
     enfermedad:"Explosion y rotura de cilindro con gas: calles y carreteras"
  },
  {
     id:"W365",
     enfermedad:"Explosion y rotura de cilindro con gas: comercio y areas de servicio"
  },
  {
     id:"W366",
     enfermedad:"Explosion y rotura de cilindro con gas: area industrial y de la construccion"
  },
  {
     id:"W367",
     enfermedad:"Explosion y rotura de cilindro con gas: granja"
  },
  {
     id:"W368",
     enfermedad:"Explosion y rotura de cilindro con gas: otro lugar especificado"
  },
  {
     id:"W369",
     enfermedad:"Explosion y rotura de cilindro con gas: lugar no especificado"
  },
  {
     id:"W37",
     enfermedad:"Explosion y rotura de neumatico, tubo o manguera de goma presurizada"
  },
  {
     id:"W370",
     enfermedad:"Explosion y rotura de neumatico, tubo o manguera de goma presurizada: vivienda"
  },
  {
     id:"W371",
     enfermedad:"Explosion y rotura de neumatico, tubo o manguera de goma presurizada: institucion residencial"
  },
  {
     id:"W372",
     enfermedad:"Explosion y rotura de neumatico, tubo o manguera de goma presurizada: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W373",
     enfermedad:"Explosion y rotura de neumatico, tubo o manguera de goma presurizada: areas de deporte y atletismo"
  },
  {
     id:"W374",
     enfermedad:"Explosion y rotura de neumatico, tubo o manguera de goma presurizada: calles y carreteras"
  },
  {
     id:"W375",
     enfermedad:"Explosion y rotura de neumatico, tubo o manguera de goma presurizada: comercio y areas de servicio"
  },
  {
     id:"W376",
     enfermedad:"Explosion y rotura de neumatico, tubo o manguera de goma presurizada: area industrial y de la construccion"
  },
  {
     id:"W377",
     enfermedad:"Explosion y rotura de neumatico, tubo o manguera de goma presurizada: granja"
  },
  {
     id:"W378",
     enfermedad:"Explosion y rotura de neumatico, tubo o manguera de goma presurizada: otro lugar especificado"
  },
  {
     id:"W379",
     enfermedad:"Explosion y rotura de neumatico, tubo o manguera de goma presurizada: lugar no especificado"
  },
  {
     id:"W38",
     enfermedad:"Explosion y rotura de otros dispositivos presurizados especificados"
  },
  {
     id:"W380",
     enfermedad:"Explosion y rotura de otros dispositivos presurizados especificados: vivienda"
  },
  {
     id:"W381",
     enfermedad:"Explosion y rotura de otros dispositivos presurizados especificados: institucion residencial"
  },
  {
     id:"W382",
     enfermedad:"Explosion y rotura de otros dispositivos presurizados especificados: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W383",
     enfermedad:"Explosion y rotura de otros dispositivos presurizados especificados: areas de deporte y atletismo"
  },
  {
     id:"W384",
     enfermedad:"Explosion y rotura de otros dispositivos presurizados especificados: calles y carreteras"
  },
  {
     id:"W385",
     enfermedad:"Explosion y rotura de otros dispositivos presurizados especificados: comercio y areas de servicio"
  },
  {
     id:"W386",
     enfermedad:"Explosion y rotura de otros dispositivos presurizados especificados: area industrial y de la construccion"
  },
  {
     id:"W387",
     enfermedad:"Explosion y rotura de otros dispositivos presurizados especificados: granja"
  },
  {
     id:"W388",
     enfermedad:"Explosion y rotura de otros dispositivos presurizados especificados: otro lugar especificado"
  },
  {
     id:"W389",
     enfermedad:"Explosion y rotura de otros dispositivos presurizados especificados: lugar no especificado"
  },
  {
     id:"W39",
     enfermedad:"Explosion de fuegos artificiales"
  },
  {
     id:"W390",
     enfermedad:"Explosion de fuegos artificiales: vivienda"
  },
  {
     id:"W391",
     enfermedad:"Explosion de fuegos artificiales: institucion residencial"
  },
  {
     id:"W392",
     enfermedad:"Explosion de fuegos artificiales: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W393",
     enfermedad:"Explosion de fuegos artificiales: areas de deporte y atletismo"
  },
  {
     id:"W394",
     enfermedad:"Explosion de fuegos artificiales: calles y carreteras"
  },
  {
     id:"W395",
     enfermedad:"Explosion de fuegos artificiales: comercio y areas de servicio"
  },
  {
     id:"W396",
     enfermedad:"Explosion de fuegos artificiales: area industrial y de la construccion"
  },
  {
     id:"W397",
     enfermedad:"Explosion de fuegos artificiales: granja"
  },
  {
     id:"W398",
     enfermedad:"Explosion de fuegos artificiales: otro lugar especificado"
  },
  {
     id:"W399",
     enfermedad:"Explosion de fuegos artificiales: lugar no especificado"
  },
  {
     id:"W40",
     enfermedad:"Explosion de otros materiales"
  },
  {
     id:"W400",
     enfermedad:"Explosion de otros materiales: vivienda"
  },
  {
     id:"W401",
     enfermedad:"Explosion de otros materiales: institucion residencial"
  },
  {
     id:"W402",
     enfermedad:"Explosion de otros materiales: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W403",
     enfermedad:"Explosion de otros materiales: areas de deporte y atletismo"
  },
  {
     id:"W404",
     enfermedad:"Explosion de otros materiales: calles y carreteras"
  },
  {
     id:"W405",
     enfermedad:"Explosion de otros materiales: comercio y areas de servicio"
  },
  {
     id:"W406",
     enfermedad:"Explosion de otros materiales: area industrial y de la construccion"
  },
  {
     id:"W407",
     enfermedad:"Explosion de otros materiales: granja"
  },
  {
     id:"W408",
     enfermedad:"Explosion de otros materiales: otro lugar especificado"
  },
  {
     id:"W409",
     enfermedad:"Explosion de otros materiales: lugar no especificado"
  },
  {
     id:"W41",
     enfermedad:"Exposicion a chorro de alta presion"
  },
  {
     id:"W410",
     enfermedad:"Exposicion a chorro de alta presion: vivienda"
  },
  {
     id:"W411",
     enfermedad:"Exposicion a chorro de alta presion: institucion residencial"
  },
  {
     id:"W412",
     enfermedad:"Exposicion a chorro de alta presion: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W413",
     enfermedad:"Exposicion a chorro de alta presion: areas de deporte y atletismo"
  },
  {
     id:"W414",
     enfermedad:"Exposicion a chorro de alta presion: calles y carreteras"
  },
  {
     id:"W415",
     enfermedad:"Exposicion a chorro de alta presion: comercio y areas de servicio"
  },
  {
     id:"W416",
     enfermedad:"Exposicion a chorro de alta presion: area industrial y de la construccion"
  },
  {
     id:"W417",
     enfermedad:"Exposicion a chorro de alta presion: granja"
  },
  {
     id:"W418",
     enfermedad:"Exposicion a chorro de alta presion: otro lugar especificado"
  },
  {
     id:"W419",
     enfermedad:"Exposicion a chorro de alta presion: lugar no especificado"
  },
  {
     id:"W42",
     enfermedad:"Exposicion al ruido"
  },
  {
     id:"W420",
     enfermedad:"Exposicion al ruido: vivienda"
  },
  {
     id:"W421",
     enfermedad:"Exposicion al ruido: institucion residencial"
  },
  {
     id:"W422",
     enfermedad:"Exposicion al ruido: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W423",
     enfermedad:"Exposicion al ruido: areas de deporte y atletismo"
  },
  {
     id:"W424",
     enfermedad:"Exposicion al ruido: calles y carreteras"
  },
  {
     id:"W425",
     enfermedad:"Exposicion al ruido: comercio y areas de servicio"
  },
  {
     id:"W426",
     enfermedad:"Exposicion al ruido: area industrial y de la construccion"
  },
  {
     id:"W427",
     enfermedad:"Exposicion al ruido: granja"
  },
  {
     id:"W428",
     enfermedad:"Exposicion al ruido: otro lugar especificado"
  },
  {
     id:"W429",
     enfermedad:"Exposicion al ruido: lugar no especificado"
  },
  {
     id:"W43",
     enfermedad:"Exposicion a vibraciones"
  },
  {
     id:"W430",
     enfermedad:"Exposicion a vibraciones: vivienda"
  },
  {
     id:"W431",
     enfermedad:"Exposicion a vibraciones: institucion residencial"
  },
  {
     id:"W432",
     enfermedad:"Exposicion a vibraciones: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W433",
     enfermedad:"Exposicion a vibraciones: areas de deporte y atletismo"
  },
  {
     id:"W434",
     enfermedad:"Exposicion a vibraciones: calles y carreteras"
  },
  {
     id:"W435",
     enfermedad:"Exposicion a vibraciones: comercio y areas de servicio"
  },
  {
     id:"W436",
     enfermedad:"Exposicion a vibraciones: area industrial y de la construccion"
  },
  {
     id:"W437",
     enfermedad:"Exposicion a vibraciones: granja"
  },
  {
     id:"W438",
     enfermedad:"Exposicion a vibraciones: otro lugar especificado"
  },
  {
     id:"W439",
     enfermedad:"Exposicion a vibraciones: lugar no especificado"
  },
  {
     id:"W44",
     enfermedad:"Cuerpo extraño que penetra por el ojo u orificio natural"
  },
  {
     id:"W440",
     enfermedad:"Cuerpo extraño que penetra por el ojo u orificio natural: vivienda"
  },
  {
     id:"W441",
     enfermedad:"Cuerpo extraño que penetra por el ojo u orificio natural: institucion residencial"
  },
  {
     id:"W442",
     enfermedad:"Cuerpo extraño que penetra por el ojo u orificio natural: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W443",
     enfermedad:"Cuerpo extraño que penetra por el ojo u orificio natural: areas de deporte y atletismo"
  },
  {
     id:"W444",
     enfermedad:"Cuerpo extraño que penetra por el ojo u orificio natural: calles y carreteras"
  },
  {
     id:"W445",
     enfermedad:"Cuerpo extraño que penetra por el ojo u orificio natural: comercio y areas de servicio"
  },
  {
     id:"W446",
     enfermedad:"Cuerpo extraño que penetra por el ojo u orificio natural: area industrial y de la construccion"
  },
  {
     id:"W447",
     enfermedad:"Cuerpo extraño que penetra por el ojo u orificio natural: granja"
  },
  {
     id:"W448",
     enfermedad:"Cuerpo extraño que penetra por el ojo u orificio natural: otro lugar especificado"
  },
  {
     id:"W449",
     enfermedad:"Cuerpo extraño que penetra por el ojo u orificio natural: lugar no especificado"
  },
  {
     id:"W45",
     enfermedad:"Cuerpo extraño que penetra a traves de la piel"
  },
  {
     id:"W450",
     enfermedad:"Cuerpo extraño que penetra a traves de la piel: vivienda"
  },
  {
     id:"W451",
     enfermedad:"Cuerpo extraño que penetra a traves de la piel: institucion residencial"
  },
  {
     id:"W452",
     enfermedad:"Cuerpo extraño que penetra a traves de la piel: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W453",
     enfermedad:"Cuerpo extraño que penetra a traves de la piel: areas de deporte y atletismo"
  },
  {
     id:"W454",
     enfermedad:"Cuerpo extraño que penetra a traves de la piel: calles y carreteras"
  },
  {
     id:"W455",
     enfermedad:"Cuerpo extraño que penetra a traves de la piel: comercio y areas de servicio"
  },
  {
     id:"W456",
     enfermedad:"Cuerpo extraño que penetra a traves de la piel: area industrial y de la construccion"
  },
  {
     id:"W457",
     enfermedad:"Cuerpo extraño que penetra a traves de la piel: granja"
  },
  {
     id:"W458",
     enfermedad:"Cuerpo extraño que penetra a traves de la piel: otro lugar especificado"
  },
  {
     id:"W459",
     enfermedad:"Cuerpo extraño que penetra a traves de la piel: lugar no especificado"
  },
  {
     id:"W49",
     enfermedad:"Exposicion a otras fuerzas mecanicas inanimadas y las no especificadas"
  },
  {
     id:"W490",
     enfermedad:"Exposicion a otras fuerzas mecanicas inanimadas, y las no especificadas: vivienda"
  },
  {
     id:"W491",
     enfermedad:"Exposicion a otras fuerzas mecanicas inanimadas, y las no especificadas: institucion residencial"
  },
  {
     id:"W492",
     enfermedad:"Exposicion a otras fuerzas mecanicas inanimadas, y las no especificadas: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W493",
     enfermedad:"Exposicion a otras fuerzas mecanicas inanimadas, y las no especificadas: areas de deporte y atletismo"
  },
  {
     id:"W494",
     enfermedad:"Exposicion a otras fuerzas mecanicas inanimadas, y las no especificadas: calles y carreteras"
  },
  {
     id:"W495",
     enfermedad:"Exposicion a otras fuerzas mecanicas inanimadas, y las no especificadas: comercio y areas de servicio"
  },
  {
     id:"W496",
     enfermedad:"Exposicion a otras fuerzas mecanicas inanimadas, y las no especificadas: area industrial y de la construccion"
  },
  {
     id:"W497",
     enfermedad:"Exposicion a otras fuerzas mecanicas inanimadas, y las no especificadas: granja"
  },
  {
     id:"W498",
     enfermedad:"Exposicion a otras fuerzas mecanicas inanimadas, y las no especificadas: otro lugar especificado"
  },
  {
     id:"W499",
     enfermedad:"Exposicion a otras fuerzas mecanicas inanimadas, y las no especificadas: lugar no especificado"
  },
  {
     id:"W50",
     enfermedad:"Aporreo, golpe, mordedura, patada, rasguño o torcedura infligidos por otras personas"
  },
  {
     id:"W500",
     enfermedad:"Aporreo, golpe, mordedura, patada, rasguño o torcedura infligidos por otra persona: vivienda"
  },
  {
     id:"W501",
     enfermedad:"Aporreo, golpe, mordedura, patada, rasguño o torcedura infligidos por otra persona: institucion residencial"
  },
  {
     id:"W502",
     enfermedad:"Aporreo, golpe, mordedura, patada, rasguño o torcedura infligidos por otra persona: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W503",
     enfermedad:"Aporreo, golpe, mordedura, patada, rasguño o torcedura infligidos por otra persona: areas de deporte y atletismo"
  },
  {
     id:"W504",
     enfermedad:"Aporreo, golpe, mordedura, patada, rasguño o torcedura infligidos por otra persona: calles y carreteras"
  },
  {
     id:"W505",
     enfermedad:"Aporreo, golpe, mordedura, patada, rasguño o torcedura infligidos por otra persona: comercio y areas de servicio"
  },
  {
     id:"W506",
     enfermedad:"Aporreo, golpe, mordedura, patada, rasguño o torcedura infligidos por otra persona: area industrial y de la construccion"
  },
  {
     id:"W507",
     enfermedad:"Aporreo, golpe, mordedura, patada, rasguño o torcedura infligidos por otra persona: granja"
  },
  {
     id:"W508",
     enfermedad:"Aporreo, golpe, mordedura, patada, rasguño o torcedura infligidos por otra persona: otro lugar especificado"
  },
  {
     id:"W509",
     enfermedad:"Aporreo, golpe, mordedura, patada, rasguño o torcedura infligidos por otra persona: lugar no especificado"
  },
  {
     id:"W51",
     enfermedad:"Choque o empellon contra otra persona"
  },
  {
     id:"W510",
     enfermedad:"Choque o empellon contra otra persona: vivienda"
  },
  {
     id:"W511",
     enfermedad:"Choque o empellon contra otra persona: institucion residencial"
  },
  {
     id:"W512",
     enfermedad:"Choque o empellon contra otra persona: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W513",
     enfermedad:"Choque o empellon contra otra persona: areas de deporte y atletismo"
  },
  {
     id:"W514",
     enfermedad:"Choque o empellon contra otra persona: calles y carreteras"
  },
  {
     id:"W515",
     enfermedad:"Choque o empellon contra otra persona: comercio y areas de servicio"
  },
  {
     id:"W516",
     enfermedad:"Choque o empellon contra otra persona: area industrial y de la construccion"
  },
  {
     id:"W517",
     enfermedad:"Choque o empellon contra otra persona: granja"
  },
  {
     id:"W518",
     enfermedad:"Choque o empellon contra otra persona: otro lugar especificado"
  },
  {
     id:"W519",
     enfermedad:"Choque o empellon contra otra persona: lugar no especificado"
  },
  {
     id:"W52",
     enfermedad:"Persona aplastada, empujada o pisoteada por una multitud o estampida humana"
  },
  {
     id:"W520",
     enfermedad:"Persona aplastada, empujada o pisoteada por una multitud o estampida humana: vivienda"
  },
  {
     id:"W521",
     enfermedad:"Persona aplastada, empujada o pisoteada por una multitud o estampida humana: institucion residencial"
  },
  {
     id:"W522",
     enfermedad:"Persona aplastada, empujada o pisoteada por una multitud o estampida humana: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W523",
     enfermedad:"Persona aplastada, empujada o pisoteada por una multitud o estampida humana: areas de deporte y atletismo"
  },
  {
     id:"W524",
     enfermedad:"Persona aplastada, empujada o pisoteada por una multitud o estampida humana: calles y carreteras"
  },
  {
     id:"W525",
     enfermedad:"Persona aplastada, empujada o pisoteada por una multitud o estampida humana: comercio y areas de servicio"
  },
  {
     id:"W526",
     enfermedad:"Persona aplastada, empujada o pisoteada por una multitud o estampida humana: area industrial y de la construccion"
  },
  {
     id:"W527",
     enfermedad:"Persona aplastada, empujada o pisoteada por una multitud o estampida humana: granja"
  },
  {
     id:"W528",
     enfermedad:"Persona aplastada, empujada o pisoteada por una multitud o estampida humana: otro lugar especificado"
  },
  {
     id:"W529",
     enfermedad:"Persona aplastada, empujada o pisoteada por una multitud o estampida humana: lugar no especificado"
  },
  {
     id:"W53",
     enfermedad:"Mordedura de rata"
  },
  {
     id:"W530",
     enfermedad:"Mordedura de rata: vivienda"
  },
  {
     id:"W531",
     enfermedad:"Mordedura de rata: institucion residencial"
  },
  {
     id:"W532",
     enfermedad:"Mordedura de rata: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W533",
     enfermedad:"Mordedura de rata: areas de deporte y atletismo"
  },
  {
     id:"W534",
     enfermedad:"Mordedura de rata: calles y carreteras"
  },
  {
     id:"W535",
     enfermedad:"Mordedura de rata: comercio y areas de servicio"
  },
  {
     id:"W536",
     enfermedad:"Mordedura de rata: area industrial y de la construccion"
  },
  {
     id:"W537",
     enfermedad:"Mordedura de rata: granja"
  },
  {
     id:"W538",
     enfermedad:"Mordedura de rata: otro lugar especificado"
  },
  {
     id:"W539",
     enfermedad:"Mordedura de rata: lugar no especificado"
  },
  {
     id:"W54",
     enfermedad:"Mordedura o ataque de perro"
  },
  {
     id:"W540",
     enfermedad:"Mordedura o ataque de perro: vivienda"
  },
  {
     id:"W541",
     enfermedad:"Mordedura o ataque de perro: institucion residencial"
  },
  {
     id:"W542",
     enfermedad:"Mordedura o ataque de perro: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W543",
     enfermedad:"Mordedura o ataque de perro: areas de deporte y atletismo"
  },
  {
     id:"W544",
     enfermedad:"Mordedura o ataque de perro: calles y carreteras"
  },
  {
     id:"W545",
     enfermedad:"Mordedura o ataque de perro: comercio y areas de servicio"
  },
  {
     id:"W546",
     enfermedad:"Mordedura o ataque de perro: area industrial y de la construccion"
  },
  {
     id:"W547",
     enfermedad:"Mordedura o ataque de perro: granja"
  },
  {
     id:"W548",
     enfermedad:"Mordedura o ataque de perro: otro lugar especificado"
  },
  {
     id:"W549",
     enfermedad:"Mordedura o ataque de perro: lugar no especificado"
  },
  {
     id:"W55",
     enfermedad:"Mordedura o ataque de otros mamiferos"
  },
  {
     id:"W550",
     enfermedad:"Mordedura o ataque de otros mamiferos: vivienda"
  },
  {
     id:"W551",
     enfermedad:"Mordedura o ataque de otros mamiferos: institucion residencial"
  },
  {
     id:"W552",
     enfermedad:"Mordedura o ataque de otros mamiferos: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W553",
     enfermedad:"Mordedura o ataque de otros mamiferos: areas de deporte y atletismo"
  },
  {
     id:"W554",
     enfermedad:"Mordedura o ataque de otros mamiferos: calles y carreteras"
  },
  {
     id:"W555",
     enfermedad:"Mordedura o ataque de otros mamiferos: comercio y areas de servicio"
  },
  {
     id:"W556",
     enfermedad:"Mordedura o ataque de otros mamiferos: area industrial y de la construccion"
  },
  {
     id:"W557",
     enfermedad:"Mordedura o ataque de otros mamiferos: granja"
  },
  {
     id:"W558",
     enfermedad:"Mordedura o ataque de otros mamiferos: otro lugar especificado"
  },
  {
     id:"W559",
     enfermedad:"Mordedura o ataque de otros mamiferos: lugar no especificado"
  },
  {
     id:"W56",
     enfermedad:"Contacto traumatico con animales marinos"
  },
  {
     id:"W560",
     enfermedad:"Contacto traumatico con animales marinos: vivienda"
  },
  {
     id:"W561",
     enfermedad:"Contacto traumatico con animales marinos: institucion residencial"
  },
  {
     id:"W562",
     enfermedad:"Contacto traumatico con animales marinos: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W563",
     enfermedad:"Contacto traumatico con animales marinos: areas de deporte y atletismo"
  },
  {
     id:"W564",
     enfermedad:"Contacto traumatico con animales marinos: calles y carreteras"
  },
  {
     id:"W565",
     enfermedad:"Contacto traumatico con animales marinos: comercio y areas de servicio"
  },
  {
     id:"W566",
     enfermedad:"Contacto traumatico con animales marinos: area industrial y de la construccion"
  },
  {
     id:"W567",
     enfermedad:"Contacto traumatico con animales marinos: granja"
  },
  {
     id:"W568",
     enfermedad:"Contacto traumatico con animales marinos: otro lugar especificado"
  },
  {
     id:"W569",
     enfermedad:"Contacto traumatico con animales marinos: lugar no especificado"
  },
  {
     id:"W57",
     enfermedad:"Mordedura o picadura de insectos y otros artropodos no venenosos"
  },
  {
     id:"W570",
     enfermedad:"Mordedura o picadura de insectos y otros artropodos no venenosos: vivienda"
  },
  {
     id:"W571",
     enfermedad:"Mordedura o picadura de insectos y otros artropodos no venenosos: institucion residencial"
  },
  {
     id:"W572",
     enfermedad:"Mordedura o picadura de insectos y otros artropodos no venenosos: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W573",
     enfermedad:"Mordedura o picadura de insectos y otros artropodos no venenosos: areas de deporte y atletismo"
  },
  {
     id:"W574",
     enfermedad:"Mordedura o picadura de insectos y otros artropodos no venenosos: calles y carreteras"
  },
  {
     id:"W575",
     enfermedad:"Mordedura o picadura de insectos y otros artropodos no venenosos: comercio y areas de servicio"
  },
  {
     id:"W576",
     enfermedad:"Mordedura o picadura de insectos y otros artropodos no venenosos: area industrial y de la construccion"
  },
  {
     id:"W577",
     enfermedad:"Mordedura o picadura de insectos y otros artropodos no venenosos: granja"
  },
  {
     id:"W578",
     enfermedad:"Mordedura o picadura de insectos y otros artropodos no venenosos: otro lugar especificado"
  },
  {
     id:"W579",
     enfermedad:"Mordedura o picadura de insectos y otros artropodos no venenosos: lugar no especificado"
  },
  {
     id:"W58",
     enfermedad:"Mordedura o ataque de cocodrilo o caiman"
  },
  {
     id:"W580",
     enfermedad:"Mordedura o ataque de cocodrilo o caiman: vivienda"
  },
  {
     id:"W581",
     enfermedad:"Mordedura o ataque de cocodrilo o caiman: institucion residencial"
  },
  {
     id:"W582",
     enfermedad:"Mordedura o ataque de cocodrilo o caiman: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W583",
     enfermedad:"Mordedura o ataque de cocodrilo o caiman: areas de deporte y atletismo"
  },
  {
     id:"W584",
     enfermedad:"Mordedura o ataque de cocodrilo o caiman: calles y carreteras"
  },
  {
     id:"W585",
     enfermedad:"Mordedura o ataque de cocodrilo o caiman: comercio y areas de servicio"
  },
  {
     id:"W586",
     enfermedad:"Mordedura o ataque de cocodrilo o caiman: area industrial y de la construccion"
  },
  {
     id:"W587",
     enfermedad:"Mordedura o ataque de cocodrilo o caiman: granja"
  },
  {
     id:"W588",
     enfermedad:"Mordedura o ataque de cocodrilo o caiman: otro lugar especificado"
  },
  {
     id:"W589",
     enfermedad:"Mordedura o ataque de cocodrilo o caiman: lugar no especificado"
  },
  {
     id:"W59",
     enfermedad:"Mordedura o aplastamiento por otros reptiles"
  },
  {
     id:"W590",
     enfermedad:"Mordedura o aplastamiento por otros reptiles: vivienda"
  },
  {
     id:"W591",
     enfermedad:"Mordedura o aplastamiento por otros reptiles: institucion residencial"
  },
  {
     id:"W592",
     enfermedad:"Mordedura o aplastamiento por otros reptiles: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W593",
     enfermedad:"Mordedura o aplastamiento por otros reptiles: areas de deporte y atletismo"
  },
  {
     id:"W594",
     enfermedad:"Mordedura o aplastamiento por otros reptiles: calles y carreteras"
  },
  {
     id:"W595",
     enfermedad:"Mordedura o aplastamiento por otros reptiles: comercio y areas de servicio"
  },
  {
     id:"W596",
     enfermedad:"Mordedura o aplastamiento por otros reptiles: area industrial y de la construccion"
  },
  {
     id:"W597",
     enfermedad:"Mordedura o aplastamiento por otros reptiles: granja"
  },
  {
     id:"W598",
     enfermedad:"Mordedura o aplastamiento por otros reptiles: otro lugar especificado"
  },
  {
     id:"W599",
     enfermedad:"Mordedura o aplastamiento por otros reptiles: lugar no especificado"
  },
  {
     id:"W60",
     enfermedad:"Contacto traumatico con aguijones, espinas u hojas cortantes de plantas"
  },
  {
     id:"W600",
     enfermedad:"Contacto traumatico con aguijones, espinas u hojas cortantes de plantas: vivienda"
  },
  {
     id:"W601",
     enfermedad:"Contacto traumatico con aguijones, espinas u hojas cortantes de plantas: institucion residencial"
  },
  {
     id:"W602",
     enfermedad:"Contacto traumatico con aguijones, espinas u hojas cortantes de plantas: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W603",
     enfermedad:"Contacto traumatico con aguijones, espinas u hojas cortantes de plantas: areas de deporte y atletismo"
  },
  {
     id:"W604",
     enfermedad:"Contacto traumatico con aguijones, espinas u hojas cortantes de plantas: calles y carreteras"
  },
  {
     id:"W605",
     enfermedad:"Contacto traumatico con aguijones, espinas u hojas cortantes de plantas: comercio y areas de servicio"
  },
  {
     id:"W606",
     enfermedad:"Contacto traumatico con aguijones, espinas u hojas cortantes de plantas: area industrial y de la construccion"
  },
  {
     id:"W607",
     enfermedad:"Contacto traumatico con aguijones, espinas u hojas cortantes de plantas: granja"
  },
  {
     id:"W608",
     enfermedad:"Contacto traumatico con aguijones, espinas u hojas cortantes de plantas: otro lugar especificado"
  },
  {
     id:"W609",
     enfermedad:"Contacto traumatico con aguijones, espinas u hojas cortantes de plantas: lugar no especificado"
  },
  {
     id:"W64",
     enfermedad:"Exposicion a otras fuerzas mecanicas animadas y las no especificadas"
  },
  {
     id:"W640",
     enfermedad:"Exposicion a otras fuerzas mecanicas animadas, y las no especificadas: vivienda"
  },
  {
     id:"W641",
     enfermedad:"Exposicion a otras fuerzas mecanicas animadas, y las no especificadas: institucion residencial"
  },
  {
     id:"W642",
     enfermedad:"Exposicion a otras fuerzas mecanicas animadas, y las no especificadas: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W643",
     enfermedad:"Exposicion a otras fuerzas mecanicas animadas, y las no especificadas: areas de deporte y atletismo"
  },
  {
     id:"W644",
     enfermedad:"Exposicion a otras fuerzas mecanicas animadas, y las no especificadas: calles y carreteras"
  },
  {
     id:"W645",
     enfermedad:"Exposicion a otras fuerzas mecanicas animadas, y las no especificadas: comercio y areas de servicio"
  },
  {
     id:"W646",
     enfermedad:"Exposicion a otras fuerzas mecanicas animadas, y las no especificadas: area industrial y de la construccion"
  },
  {
     id:"W647",
     enfermedad:"Exposicion a otras fuerzas mecanicas animadas, y las no especificadas: granja"
  },
  {
     id:"W648",
     enfermedad:"Exposicion a otras fuerzas mecanicas animadas, y las no especificadas: otro lugar especificado"
  },
  {
     id:"W649",
     enfermedad:"Exposicion a otras fuerzas mecanicas animadas, y las no especificadas: lugar no especificado"
  },
  {
     id:"W65",
     enfermedad:"Ahogamiento y sumersion mientras se esta en la banera"
  },
  {
     id:"W650",
     enfermedad:"Ahogamiento y sumersion mientras se esta en la bañera: vivienda"
  },
  {
     id:"W651",
     enfermedad:"Ahogamiento y sumersion mientras se esta en la bañera: institucion residencial"
  },
  {
     id:"W652",
     enfermedad:"Ahogamiento y sumersion mientras se esta en la bañera: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W653",
     enfermedad:"Ahogamiento y sumersion mientras se esta en la bañera: areas de deporte y atletismo"
  },
  {
     id:"W654",
     enfermedad:"Ahogamiento y sumersion mientras se esta en la bañera: calles y carreteras"
  },
  {
     id:"W655",
     enfermedad:"Ahogamiento y sumersion mientras se esta en la bañera: comercio y areas de servicio"
  },
  {
     id:"W656",
     enfermedad:"Ahogamiento y sumersion mientras se esta en la bañera: area industrial y de la construccion"
  },
  {
     id:"W657",
     enfermedad:"Ahogamiento y sumersion mientras se esta en la bañera: granja"
  },
  {
     id:"W658",
     enfermedad:"Ahogamiento y sumersion mientras se esta en la bañera: otro lugar especificado"
  },
  {
     id:"W659",
     enfermedad:"Ahogamiento y sumersion mientras se esta en la bañera: lugar no especificado"
  },
  {
     id:"W66",
     enfermedad:"Ahogamiento y sumersion consecutivos a caida en la banera"
  },
  {
     id:"W660",
     enfermedad:"Ahogamiento y sumersion consecutivos a caida en la bañera: vivienda"
  },
  {
     id:"W661",
     enfermedad:"Ahogamiento y sumersion consecutivos a caida en la bañera: institucion residencial"
  },
  {
     id:"W662",
     enfermedad:"Ahogamiento y sumersion consecutivos a caida en la bañera: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W663",
     enfermedad:"Ahogamiento y sumersion consecutivos a caida en la bañera: areas de deporte y atletismo"
  },
  {
     id:"W664",
     enfermedad:"Ahogamiento y sumersion consecutivos a caida en la bañera: calles y carreteras"
  },
  {
     id:"W665",
     enfermedad:"Ahogamiento y sumersion consecutivos a caida en la bañera: comercio y areas de servicio"
  },
  {
     id:"W666",
     enfermedad:"Ahogamiento y sumersion consecutivos a caida en la bañera: area industrial y de la construccion"
  },
  {
     id:"W667",
     enfermedad:"Ahogamiento y sumersion consecutivos a caida en la bañera: granja"
  },
  {
     id:"W668",
     enfermedad:"Ahogamiento y sumersion consecutivos a caida en la bañera: otro lugar especificado"
  },
  {
     id:"W669",
     enfermedad:"Ahogamiento y sumersion consecutivos a caida en la bañera: lugar no especificado"
  },
  {
     id:"W67",
     enfermedad:"Ahogamiento y sumersion mientras se esta en una piscina"
  },
  {
     id:"W670",
     enfermedad:"Ahogamiento y sumersion mientras se esta en una piscina: vivienda"
  },
  {
     id:"W671",
     enfermedad:"Ahogamiento y sumersion mientras se esta en una piscina: institucion residencial"
  },
  {
     id:"W672",
     enfermedad:"Ahogamiento y sumersion mientras se esta en una piscina: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W673",
     enfermedad:"Ahogamiento y sumersion mientras se esta en una piscina: areas de deporte y atletismo"
  },
  {
     id:"W674",
     enfermedad:"Ahogamiento y sumersion mientras se esta en una piscina: calles y carreteras"
  },
  {
     id:"W675",
     enfermedad:"Ahogamiento y sumersion mientras se esta en una piscina: comercio y areas de servicio"
  },
  {
     id:"W676",
     enfermedad:"Ahogamiento y sumersion mientras se esta en una piscina: area industrial y de la construccion"
  },
  {
     id:"W677",
     enfermedad:"Ahogamiento y sumersion mientras se esta en una piscina: granja"
  },
  {
     id:"W678",
     enfermedad:"Ahogamiento y sumersion mientras se esta en una piscina: otro lugar especificado"
  },
  {
     id:"W679",
     enfermedad:"Ahogamiento y sumersion mientras se esta en una piscina: lugar no especificado"
  },
  {
     id:"W68",
     enfermedad:"Ahogamiento y sumersion consecutivos a caida en una piscina"
  },
  {
     id:"W680",
     enfermedad:"Ahogamiento y sumersion consecutivos a caida en una piscina: vivienda"
  },
  {
     id:"W681",
     enfermedad:"Ahogamiento y sumersion consecutivos a caida en una piscina: institucion residencial"
  },
  {
     id:"W682",
     enfermedad:"Ahogamiento y sumersion consecutivos a caida en una piscina: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W683",
     enfermedad:"Ahogamiento y sumersion consecutivos a caida en una piscina: areas de deporte y atletismo"
  },
  {
     id:"W684",
     enfermedad:"Ahogamiento y sumersion consecutivos a caida en una piscina: calles y carreteras"
  },
  {
     id:"W685",
     enfermedad:"Ahogamiento y sumersion consecutivos a caida en una piscina: comercio y areas de servicio"
  },
  {
     id:"W686",
     enfermedad:"Ahogamiento y sumersion consecutivos a caida en una piscina: area industrial y de la construccion"
  },
  {
     id:"W687",
     enfermedad:"Ahogamiento y sumersion consecutivos a caida en una piscina: granja"
  },
  {
     id:"W688",
     enfermedad:"Ahogamiento y sumersion consecutivos a caida en una piscina: otro lugar especificado"
  },
  {
     id:"W689",
     enfermedad:"Ahogamiento y sumersion consecutivos a caida en una piscina: lugar no especificado"
  },
  {
     id:"W69",
     enfermedad:"Ahogamiento y sumersion mientras se esta en aguas naturales"
  },
  {
     id:"W690",
     enfermedad:"Ahogamiento y sumersion mientras se esta en aguas naturales: vivienda"
  },
  {
     id:"W691",
     enfermedad:"Ahogamiento y sumersion mientras se esta en aguas naturales: institucion residencial"
  },
  {
     id:"W692",
     enfermedad:"Ahogamiento y sumersion mientras se esta en aguas naturales: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W693",
     enfermedad:"Ahogamiento y sumersion mientras se esta en aguas naturales: areas de deporte y atletismo"
  },
  {
     id:"W694",
     enfermedad:"Ahogamiento y sumersion mientras se esta en aguas naturales: calles y carreteras"
  },
  {
     id:"W695",
     enfermedad:"Ahogamiento y sumersion mientras se esta en aguas naturales: comercio y areas de servicio"
  },
  {
     id:"W696",
     enfermedad:"Ahogamiento y sumersion mientras se esta en aguas naturales: area industrial y de la construccion"
  },
  {
     id:"W697",
     enfermedad:"Ahogamiento y sumersion mientras se esta en aguas naturales: granja"
  },
  {
     id:"W698",
     enfermedad:"Ahogamiento y sumersion mientras se esta en aguas naturales: otro lugar especificado"
  },
  {
     id:"W699",
     enfermedad:"Ahogamiento y sumersion mientras se esta en aguas naturales: lugar no especificado"
  },
  {
     id:"W70",
     enfermedad:"Ahogamiento y sumersion posterior a caida en aguas naturales"
  },
  {
     id:"W700",
     enfermedad:"Ahogamiento y sumersion posterior a caida en aguas naturales: vivienda"
  },
  {
     id:"W701",
     enfermedad:"Ahogamiento y sumersion posterior a caida en aguas naturales: institucion residencial"
  },
  {
     id:"W702",
     enfermedad:"Ahogamiento y sumersion posterior a caida en aguas naturales: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W703",
     enfermedad:"Ahogamiento y sumersion posterior a caida en aguas naturales: areas de deporte y atletismo"
  },
  {
     id:"W704",
     enfermedad:"Ahogamiento y sumersion posterior a caida en aguas naturales: calles y carreteras"
  },
  {
     id:"W705",
     enfermedad:"Ahogamiento y sumersion posterior a caida en aguas naturales: comercio y areas de servicio"
  },
  {
     id:"W706",
     enfermedad:"Ahogamiento y sumersion posterior a caida en aguas naturales: area industrial y de la construccion"
  },
  {
     id:"W707",
     enfermedad:"Ahogamiento y sumersion posterior a caida en aguas naturales: granja"
  },
  {
     id:"W708",
     enfermedad:"Ahogamiento y sumersion posterior a caida en aguas naturales: otro lugar especificado"
  },
  {
     id:"W709",
     enfermedad:"Ahogamiento y sumersion posterior a caida en aguas naturales: lugar no especificado"
  },
  {
     id:"W73",
     enfermedad:"Otros ahogamientos y sumersiones especificados"
  },
  {
     id:"W730",
     enfermedad:"Otros ahogamientos y sumersiones especificados: vivienda"
  },
  {
     id:"W731",
     enfermedad:"Otros ahogamientos y sumersiones especificados: institucion residencial"
  },
  {
     id:"W732",
     enfermedad:"Otros ahogamientos y sumersiones especificados: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W733",
     enfermedad:"Otros ahogamientos y sumersiones especificados: areas de deporte y atletismo"
  },
  {
     id:"W734",
     enfermedad:"Otros ahogamientos y sumersiones especificados: calles y carreteras"
  },
  {
     id:"W735",
     enfermedad:"Otros ahogamientos y sumersiones especificados: comercio y areas de servicio"
  },
  {
     id:"W736",
     enfermedad:"Otros ahogamientos y sumersiones especificados: area industrial y de la construccion"
  },
  {
     id:"W737",
     enfermedad:"Otros ahogamientos y sumersiones especificados: granja"
  },
  {
     id:"W738",
     enfermedad:"Otros ahogamientos y sumersiones especificados: otro lugar especificado"
  },
  {
     id:"W739",
     enfermedad:"Otros ahogamientos y sumersiones especificados: lugar no especificado"
  },
  {
     id:"W74",
     enfermedad:"Ahogamiento y sumersion no especificados"
  },
  {
     id:"W740",
     enfermedad:"Ahogamiento y sumersion no especificados: vivienda"
  },
  {
     id:"W741",
     enfermedad:"Ahogamiento y sumersion no especificados: institucion residencial"
  },
  {
     id:"W742",
     enfermedad:"Ahogamiento y sumersion no especificados: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W743",
     enfermedad:"Ahogamiento y sumersion no especificados: areas de deporte y atletismo"
  },
  {
     id:"W744",
     enfermedad:"Ahogamiento y sumersion no especificados: calles y carreteras"
  },
  {
     id:"W745",
     enfermedad:"Ahogamiento y sumersion no especificados: comercio y areas de servicio"
  },
  {
     id:"W746",
     enfermedad:"Ahogamiento y sumersion no especificados: area industrial y de la construccion"
  },
  {
     id:"W747",
     enfermedad:"Ahogamiento y sumersion no especificados: granja"
  },
  {
     id:"W748",
     enfermedad:"Ahogamiento y sumersion no especificados: otro lugar especificado"
  },
  {
     id:"W749",
     enfermedad:"Ahogamiento y sumersion no especificados: lugar no especificado"
  },
  {
     id:"W75",
     enfermedad:"Sofocacion y estrangulamiento accidental en la cama"
  },
  {
     id:"W750",
     enfermedad:"Sofocacion y estrangulamiento accidental en la cama: vivienda"
  },
  {
     id:"W751",
     enfermedad:"Sofocacion y estrangulamiento accidental en la cama: institucion residencial"
  },
  {
     id:"W752",
     enfermedad:"Sofocacion y estrangulamiento accidental en la cama: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W753",
     enfermedad:"Sofocacion y estrangulamiento accidental en la cama: areas de deporte y atletismo"
  },
  {
     id:"W754",
     enfermedad:"Sofocacion y estrangulamiento accidental en la cama: calles y carreteras"
  },
  {
     id:"W755",
     enfermedad:"Sofocacion y estrangulamiento accidental en la cama: comercio y areas de servicio"
  },
  {
     id:"W756",
     enfermedad:"Sofocacion y estrangulamiento accidental en la cama: area industrial y de la construccion"
  },
  {
     id:"W757",
     enfermedad:"Sofocacion y estrangulamiento accidental en la cama: granja"
  },
  {
     id:"W758",
     enfermedad:"Sofocacion y estrangulamiento accidental en la cama: otro lugar especificado"
  },
  {
     id:"W759",
     enfermedad:"Sofocacion y estrangulamiento accidental en la cama: lugar no especificado"
  },
  {
     id:"W76",
     enfermedad:"Otros estrangulamientos y ahorcamientos accidentales"
  },
  {
     id:"W760",
     enfermedad:"Otros estrangulamientos y ahorcamientos accidentales: vivienda"
  },
  {
     id:"W761",
     enfermedad:"Otros estrangulamientos y ahorcamientos accidentales: institucion residencial"
  },
  {
     id:"W762",
     enfermedad:"Otros estrangulamientos y ahorcamientos accidentales: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W763",
     enfermedad:"Otros estrangulamientos y ahorcamientos accidentales: areas de deporte y atletismo"
  },
  {
     id:"W764",
     enfermedad:"Otros estrangulamientos y ahorcamientos accidentales: calles y carreteras"
  },
  {
     id:"W765",
     enfermedad:"Otros estrangulamientos y ahorcamientos accidentales: comercio y areas de servicio"
  },
  {
     id:"W766",
     enfermedad:"Otros estrangulamientos y ahorcamientos accidentales: area industrial y de la construccion"
  },
  {
     id:"W767",
     enfermedad:"Otros estrangulamientos y ahorcamientos accidentales: granja"
  },
  {
     id:"W768",
     enfermedad:"Otros estrangulamientos y ahorcamientos accidentales: otro lugar especificado"
  },
  {
     id:"W769",
     enfermedad:"Otros estrangulamientos y ahorcamientos accidentales: lugar no especificado"
  },
  {
     id:"W77",
     enfermedad:"Obstruccion de la respiracion debida a hundimiento, caida de tierra u otras sustancias"
  },
  {
     id:"W770",
     enfermedad:"Obstruccion de la respiracion debida a hundimiento, caida de tierra u otras sustancias: vivienda"
  },
  {
     id:"W771",
     enfermedad:"Obstruccion de la respiracion debida a hundimiento, caida de tierra u otras sustancias: institucion residencial"
  },
  {
     id:"W772",
     enfermedad:"Obstruccion de la respiracion debida a hundimiento, caida de tierra u otras sustancias: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W773",
     enfermedad:"Obstruccion de la respiracion debida a hundimiento, caida de tierra u otras sustancias: areas de deporte y atletismo"
  },
  {
     id:"W774",
     enfermedad:"Obstruccion de la respiracion debida a hundimiento, caida de tierra u otras sustancias: calles y carreteras"
  },
  {
     id:"W775",
     enfermedad:"Obstruccion de la respiracion debida a hundimiento, caida de tierra u otras sustancias: comercio y areas de servicio"
  },
  {
     id:"W776",
     enfermedad:"Obstruccion de la respiracion debida a hundimiento, caida de tierra u otras sustancias: area industrial y de la construccion"
  },
  {
     id:"W777",
     enfermedad:"Obstruccion de la respiracion debida a hundimiento, caida de tierra u otras sustancias: granja"
  },
  {
     id:"W778",
     enfermedad:"Obstruccion de la respiracion debida a hundimiento, caida de tierra u otras sustancias: otro lugar especificado"
  },
  {
     id:"W779",
     enfermedad:"Obstruccion de la respiracion debida a hundimiento, caida de tierra u otras sustancias: lugar no especificado"
  },
  {
     id:"W78",
     enfermedad:"Inhalacion de contenidos gastricos"
  },
  {
     id:"W780",
     enfermedad:"Inhalacion de contenidos gastricos: vivienda"
  },
  {
     id:"W781",
     enfermedad:"Inhalacion de contenidos gastricos: institucion residencial"
  },
  {
     id:"W782",
     enfermedad:"Inhalacion de contenidos gastricos: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W783",
     enfermedad:"Inhalacion de contenidos gastricos: areas de deporte y atletismo"
  },
  {
     id:"W784",
     enfermedad:"Inhalacion de contenidos gastricos: calles y carreteras"
  },
  {
     id:"W785",
     enfermedad:"Inhalacion de contenidos gastricos: comercio y areas de servicio"
  },
  {
     id:"W786",
     enfermedad:"Inhalacion de contenidos gastricos: area industrial y de la construccion"
  },
  {
     id:"W787",
     enfermedad:"Inhalacion de contenidos gastricos: granja"
  },
  {
     id:"W788",
     enfermedad:"Inhalacion de contenidos gastricos: otro lugar especificado"
  },
  {
     id:"W789",
     enfermedad:"Inhalacion de contenidos gastricos: lugar no especificado"
  },
  {
     id:"W79",
     enfermedad:"Inhalacionl e ingestion de alimento que causa obstruccion de las vias respiratorias"
  },
  {
     id:"W790",
     enfermedad:"Inhalacion e ingestion de alimento que causa obstruccion de las vias respiratorias: vivienda"
  },
  {
     id:"W791",
     enfermedad:"Inhalacion e ingestion de alimento que causa obstruccion de las vias respiratorias: institucion residencial"
  },
  {
     id:"W792",
     enfermedad:"Inhalacion e ingestion de alimento que causa obstruccion de las vias respiratorias: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W793",
     enfermedad:"Inhalacion e ingestion de alimento que causa obstruccion de las vias respiratorias: areas de deporte y atletismo"
  },
  {
     id:"W794",
     enfermedad:"Inhalacion e ingestion de alimento que causa obstruccion de las vias respiratorias: calles y carreteras"
  },
  {
     id:"W795",
     enfermedad:"Inhalacion e ingestion de alimento que causa obstruccion de las vias respiratorias: comercio y areas de servicio"
  },
  {
     id:"W796",
     enfermedad:"Inhalacion e ingestion de alimento que causa obstruccion de las vias respiratorias: area industrial y de la construccion"
  },
  {
     id:"W797",
     enfermedad:"Inhalacion e ingestion de alimento que causa obstruccion de las vias respiratorias: granja"
  },
  {
     id:"W798",
     enfermedad:"Inhalacion e ingestion de alimento que causa obstruccion de las vias respiratorias: otro lugar especificado"
  },
  {
     id:"W799",
     enfermedad:"Inhalacion e ingestion de alimento que causa obstruccion de las vias respiratorias: lugar no especificado"
  },
  {
     id:"W80",
     enfermedad:"Inhalacion e ingestion de otros objetos que causan obstruccion de las vias respiratorias"
  },
  {
     id:"W800",
     enfermedad:"Inhalacion e ingestion de otros objetos que causan obstruccion de las vias respiratorias: vivienda"
  },
  {
     id:"W801",
     enfermedad:"Inhalacion e ingestion de otros objetos que causan obstruccion de las vias respiratorias: institucion residencial"
  },
  {
     id:"W802",
     enfermedad:"Inhalacion e ingestion de otros objetos que causan obstruccion de las vias respiratorias: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W803",
     enfermedad:"Inhalacion e ingestion de otros objetos que causan obstruccion de las vias respiratorias: areas de deporte y atletismo"
  },
  {
     id:"W804",
     enfermedad:"Inhalacion e ingestion de otros objetos que causan obstruccion de las vias respiratorias: calles y carreteras"
  },
  {
     id:"W805",
     enfermedad:"Inhalacion e ingestion de otros objetos que causan obstruccion de las vias respiratorias: comercio y areas de servicio"
  },
  {
     id:"W806",
     enfermedad:"Inhalacion e ingestion de otros objetos que causan obstruccion de las vias respiratorias: area industrial y de la construccion"
  },
  {
     id:"W807",
     enfermedad:"Inhalacion e ingestion de otros objetos que causan obstruccion de las vias respiratorias: granja"
  },
  {
     id:"W808",
     enfermedad:"Inhalacion e ingestion de otros objetos que causan obstruccion de las vias respiratorias: otro lugar especificado"
  },
  {
     id:"W809",
     enfermedad:"Inhalacion e ingestion de otros objetos que causan obstruccion de las vias respiratorias: lugar no especificado"
  },
  {
     id:"W81",
     enfermedad:"Confinado o atrapado en un ambiente con bajo contenido de oxigeno"
  },
  {
     id:"W810",
     enfermedad:"Confinado o atrapado en un ambiente con bajo contenido de oxigeno: vivienda"
  },
  {
     id:"W811",
     enfermedad:"Confinado o atrapado en un ambiente con bajo contenido de oxigeno: institucion residencial"
  },
  {
     id:"W812",
     enfermedad:"Confinado o atrapado en un ambiente con bajo contenido de oxigeno: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W813",
     enfermedad:"Confinado o atrapado en un ambiente con bajo contenido de oxigeno: areas de deporte y atletismo"
  },
  {
     id:"W814",
     enfermedad:"Confinado o atrapado en un ambiente con bajo contenido de oxigeno: calles y carreteras"
  },
  {
     id:"W815",
     enfermedad:"Confinado o atrapado en un ambiente con bajo contenido de oxigeno: comercio y areas de servicio"
  },
  {
     id:"W816",
     enfermedad:"Confinado o atrapado en un ambiente con bajo contenido de oxigeno: area industrial y de la construccion"
  },
  {
     id:"W817",
     enfermedad:"Confinado o atrapado en un ambiente con bajo contenido de oxigeno: granja"
  },
  {
     id:"W818",
     enfermedad:"Confinado o atrapado en un ambiente con bajo contenido de oxigeno: otro lugar especificado"
  },
  {
     id:"W819",
     enfermedad:"Confinado o atrapado en un ambiente con bajo contenido de oxigeno: lugar no especificado"
  },
  {
     id:"W83",
     enfermedad:"Otras obstrucciones especificadas de la respiracion"
  },
  {
     id:"W830",
     enfermedad:"Otras obstrucciones especificadas de la respiracion: vivienda"
  },
  {
     id:"W831",
     enfermedad:"Otras obstrucciones especificadas de la respiracion: institucion residencial"
  },
  {
     id:"W832",
     enfermedad:"Otras obstrucciones especificadas de la respiracion: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W833",
     enfermedad:"Otras obstrucciones especificadas de la respiracion: areas de deporte y atletismo"
  },
  {
     id:"W834",
     enfermedad:"Otras obstrucciones especificadas de la respiracion: calles y carreteras"
  },
  {
     id:"W835",
     enfermedad:"Otras obstrucciones especificadas de la respiracion: comercio y areas de servicio"
  },
  {
     id:"W836",
     enfermedad:"Otras obstrucciones especificadas de la respiracion: area industrial y de la construccion"
  },
  {
     id:"W837",
     enfermedad:"Otras obstrucciones especificadas de la respiracion: granja"
  },
  {
     id:"W838",
     enfermedad:"Otras obstrucciones especificadas de la respiracion: otro lugar especificado"
  },
  {
     id:"W839",
     enfermedad:"Otras obstrucciones especificadas de la respiracion: lugar no especificado"
  },
  {
     id:"W84",
     enfermedad:"Obstruccion no especificada de la respiracion"
  },
  {
     id:"W840",
     enfermedad:"Obstrucciones no especificada de la respiracion: vivienda"
  },
  {
     id:"W841",
     enfermedad:"Obstrucciones no especificada de la respiracion: institucion residencial"
  },
  {
     id:"W842",
     enfermedad:"Obstrucciones no especificada de la respiracion: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W843",
     enfermedad:"Obstrucciones no especificada de la respiracion: areas de deporte y atletismo"
  },
  {
     id:"W844",
     enfermedad:"Obstrucciones no especificada de la respiracion: calles y carreteras"
  },
  {
     id:"W845",
     enfermedad:"Obstrucciones no especificada de la respiracion: comercio y areas de servicio"
  },
  {
     id:"W846",
     enfermedad:"Obstrucciones no especificada de la respiracion: area industrial y de la construccion"
  },
  {
     id:"W847",
     enfermedad:"Obstrucciones no especificada de la respiracion: granja"
  },
  {
     id:"W848",
     enfermedad:"Obstrucciones no especificada de la respiracion: otro lugar especificado"
  },
  {
     id:"W849",
     enfermedad:"Obstrucciones no especificada de la respiracion: lugar no especificado"
  },
  {
     id:"W85",
     enfermedad:"Exposicion a lineas de transmision electrica"
  },
  {
     id:"W850",
     enfermedad:"Exposicion a lineas de transmision electrica: vivienda"
  },
  {
     id:"W851",
     enfermedad:"Exposicion a lineas de transmision electrica: institucion residencial"
  },
  {
     id:"W852",
     enfermedad:"Exposicion a lineas de transmision electrica: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W853",
     enfermedad:"Exposicion a lineas de transmision electrica: areas de deporte y atletismo"
  },
  {
     id:"W854",
     enfermedad:"Exposicion a lineas de transmision electrica: calles y carreteras"
  },
  {
     id:"W855",
     enfermedad:"Exposicion a lineas de transmision electrica: comercio y areas de servicio"
  },
  {
     id:"W856",
     enfermedad:"Exposicion a lineas de transmision electrica: area industrial y de la construccion"
  },
  {
     id:"W857",
     enfermedad:"Exposicion a lineas de transmision electrica: granja"
  },
  {
     id:"W858",
     enfermedad:"Exposicion a lineas de transmision electrica: otro lugar especificado"
  },
  {
     id:"W859",
     enfermedad:"Exposicion a lineas de transmision electrica: lugar no especificado"
  },
  {
     id:"W86",
     enfermedad:"Exposicion a otras corrientes electricas especificadas"
  },
  {
     id:"W860",
     enfermedad:"Exposicion a otras corrientes electricas especificadas: vivienda"
  },
  {
     id:"W861",
     enfermedad:"Exposicion a otras corrientes electricas especificadas: institucion residencial"
  },
  {
     id:"W862",
     enfermedad:"Exposicion a otras corrientes electricas especificadas: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W863",
     enfermedad:"Exposicion a otras corrientes electricas especificadas: areas de deporte y atletismo"
  },
  {
     id:"W864",
     enfermedad:"Exposicion a otras corrientes electricas especificadas: calles y carreteras"
  },
  {
     id:"W865",
     enfermedad:"Exposicion a otras corrientes electricas especificadas: comercio y areas de servicio"
  },
  {
     id:"W866",
     enfermedad:"Exposicion a otras corrientes electricas especificadas: area industrial y de la construccion"
  },
  {
     id:"W867",
     enfermedad:"Exposicion a otras corrientes electricas especificadas: granja"
  },
  {
     id:"W868",
     enfermedad:"Exposicion a otras corrientes electricas especificadas: otro lugar especificado"
  },
  {
     id:"W869",
     enfermedad:"Exposicion a otras corrientes electricas especificadas: lugar no especificado"
  },
  {
     id:"W87",
     enfermedad:"Exposicion a corriente electrica no especificada"
  },
  {
     id:"W870",
     enfermedad:"Exposicion a corriente electrica no especificada: vivienda"
  },
  {
     id:"W871",
     enfermedad:"Exposicion a corriente electrica no especificada: institucion residencial"
  },
  {
     id:"W872",
     enfermedad:"Exposicion a corriente electrica no especificada: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W873",
     enfermedad:"Exposicion a corriente electrica no especificada: areas de deporte y atletismo"
  },
  {
     id:"W874",
     enfermedad:"Exposicion a corriente electrica no especificada: calles y carreteras"
  },
  {
     id:"W875",
     enfermedad:"Exposicion a corriente electrica no especificada: comercio y areas de servicio"
  },
  {
     id:"W876",
     enfermedad:"Exposicion a corriente electrica no especificada: area industrial y de la construccion"
  },
  {
     id:"W877",
     enfermedad:"Exposicion a corriente electrica no especificada: granja"
  },
  {
     id:"W878",
     enfermedad:"Exposicion a corriente electrica no especificada: otro lugar especificado"
  },
  {
     id:"W879",
     enfermedad:"Exposicion a corriente electrica no especificada: lugar no especificado"
  },
  {
     id:"W88",
     enfermedad:"Exposicion a radiacion ionizante"
  },
  {
     id:"W880",
     enfermedad:"Exposicion a radiacion ionizante: vivienda"
  },
  {
     id:"W881",
     enfermedad:"Exposicion a radiacion ionizante: institucion residencial"
  },
  {
     id:"W882",
     enfermedad:"Exposicion a radiacion ionizante: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W883",
     enfermedad:"Exposicion a radiacion ionizante: areas de deporte y atletismo"
  },
  {
     id:"W884",
     enfermedad:"Exposicion a radiacion ionizante: calles y carreteras"
  },
  {
     id:"W885",
     enfermedad:"Exposicion a radiacion ionizante: comercio y areas de servicio"
  },
  {
     id:"W886",
     enfermedad:"Exposicion a radiacion ionizante: area industrial y de la construccion"
  },
  {
     id:"W887",
     enfermedad:"Exposicion a radiacion ionizante: granja"
  },
  {
     id:"W888",
     enfermedad:"Exposicion a radiacion ionizante: otro lugar especificado"
  },
  {
     id:"W889",
     enfermedad:"Exposicion a radiacion ionizante: lugar no especificado"
  },
  {
     id:"W89",
     enfermedad:"Exposicion a fuente de luz visible y ultravioleta, de origen artificial"
  },
  {
     id:"W890",
     enfermedad:"Exposicion a fuente de luz visible y ultravioleta, de origen artificial: vivienda"
  },
  {
     id:"W891",
     enfermedad:"Exposicion a fuente de luz visible y ultravioleta, de origen artificial: institucion residencial"
  },
  {
     id:"W892",
     enfermedad:"Exposicion a fuente de luz visible y ultravioleta, de origen artificial: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W893",
     enfermedad:"Exposicion a fuente de luz visible y ultravioleta, de origen artificial: areas de deporte y atletismo"
  },
  {
     id:"W894",
     enfermedad:"Exposicion a fuente de luz visible y ultravioleta, de origen artificial: calles y carreteras"
  },
  {
     id:"W895",
     enfermedad:"Exposicion a fuente de luz visible y ultravioleta, de origen artificial: comercio y areas de servicio"
  },
  {
     id:"W896",
     enfermedad:"Exposicion a fuente de luz visible y ultravioleta, de origen artificial: area industrial y de la construccion"
  },
  {
     id:"W897",
     enfermedad:"Exposicion a fuente de luz visible y ultravioleta, de origen artificial: granja"
  },
  {
     id:"W898",
     enfermedad:"Exposicion a fuente de luz visible y ultravioleta, de origen artificial: otro lugar especificado"
  },
  {
     id:"W899",
     enfermedad:"Exposicion a fuente de luz visible y ultravioleta, de origen artificial: lugar no especificado"
  },
  {
     id:"W90",
     enfermedad:"Exposicion a otros tipos de radiacion no ionizante"
  },
  {
     id:"W900",
     enfermedad:"Exposicion a otros tipos de radiacion no ionizante: vivienda"
  },
  {
     id:"W901",
     enfermedad:"Exposicion a otros tipos de radiacion no ionizante: institucion residencial"
  },
  {
     id:"W902",
     enfermedad:"Exposicion a otros tipos de radiacion no ionizante: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W903",
     enfermedad:"Exposicion a otros tipos de radiacion no ionizante: areas de deporte y atletismo"
  },
  {
     id:"W904",
     enfermedad:"Exposicion a otros tipos de radiacion no ionizante: calles y carreteras"
  },
  {
     id:"W905",
     enfermedad:"Exposicion a otros tipos de radiacion no ionizante: comercio y areas de servicio"
  },
  {
     id:"W906",
     enfermedad:"Exposicion a otros tipos de radiacion no ionizante: area industrial y de la construccion"
  },
  {
     id:"W907",
     enfermedad:"Exposicion a otros tipos de radiacion no ionizante: granja"
  },
  {
     id:"W908",
     enfermedad:"Exposicion a otros tipos de radiacion no ionizante: otro lugar especificado"
  },
  {
     id:"W909",
     enfermedad:"Exposicion a otros tipos de radiacion no ionizante: lugar no especificado"
  },
  {
     id:"W91",
     enfermedad:"Exposicion a radiacion de tipo no especificado"
  },
  {
     id:"W910",
     enfermedad:"Exposicion a radiacion de tipo no especificado: vivienda"
  },
  {
     id:"W911",
     enfermedad:"Exposicion a radiacion de tipo no especificado: institucion residencial"
  },
  {
     id:"W912",
     enfermedad:"Exposicion a radiacion de tipo no especificado: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W913",
     enfermedad:"Exposicion a radiacion de tipo no especificado: areas de deporte y atletismo"
  },
  {
     id:"W914",
     enfermedad:"Exposicion a radiacion de tipo no especificado: calles y carreteras"
  },
  {
     id:"W915",
     enfermedad:"Exposicion a radiacion de tipo no especificado: comercio y areas de servicio"
  },
  {
     id:"W916",
     enfermedad:"Exposicion a radiacion de tipo no especificado: area industrial y de la construccion"
  },
  {
     id:"W917",
     enfermedad:"Exposicion a radiacion de tipo no especificado: granja"
  },
  {
     id:"W918",
     enfermedad:"Exposicion a radiacion de tipo no especificado: otro lugar especificado"
  },
  {
     id:"W919",
     enfermedad:"Exposicion a radiacion de tipo no especificado: lugar no especificado"
  },
  {
     id:"W92",
     enfermedad:"Exposicion a calor excesivo de origen artificial"
  },
  {
     id:"W920",
     enfermedad:"Exposicion a calor excesivo de origen artificial: vivienda"
  },
  {
     id:"W921",
     enfermedad:"Exposicion a calor excesivo de origen artificial: institucion residencial"
  },
  {
     id:"W922",
     enfermedad:"Exposicion a calor excesivo de origen artificial: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W923",
     enfermedad:"Exposicion a calor excesivo de origen artificial: areas de deporte y atletismo"
  },
  {
     id:"W924",
     enfermedad:"Exposicion a calor excesivo de origen artificial: calles y carreteras"
  },
  {
     id:"W925",
     enfermedad:"Exposicion a calor excesivo de origen artificial: comercio y areas de servicio"
  },
  {
     id:"W926",
     enfermedad:"Exposicion a calor excesivo de origen artificial: area industrial y de la construccion"
  },
  {
     id:"W927",
     enfermedad:"Exposicion a calor excesivo de origen artificial: granja"
  },
  {
     id:"W928",
     enfermedad:"Exposicion a calor excesivo de origen artificial: otro lugar especificado"
  },
  {
     id:"W929",
     enfermedad:"Exposicion a calor excesivo de origen artificial: lugar no especificado"
  },
  {
     id:"W93",
     enfermedad:"Exposicion a frio excesivo de origen artificial"
  },
  {
     id:"W930",
     enfermedad:"Exposicion a frio excesivo de origen artificial: vivienda"
  },
  {
     id:"W931",
     enfermedad:"Exposicion a frio excesivo de origen artificial: institucion residencial"
  },
  {
     id:"W932",
     enfermedad:"Exposicion a frio excesivo de origen artificial: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W933",
     enfermedad:"Exposicion a frio excesivo de origen artificial: areas de deporte y atletismo"
  },
  {
     id:"W934",
     enfermedad:"Exposicion a frio excesivo de origen artificial: calles y carreteras"
  },
  {
     id:"W935",
     enfermedad:"Exposicion a frio excesivo de origen artificial: comercio y areas de servicio"
  },
  {
     id:"W936",
     enfermedad:"Exposicion a frio excesivo de origen artificial: area industrial y de la construccion"
  },
  {
     id:"W937",
     enfermedad:"Exposicion a frio excesivo de origen artificial: granja"
  },
  {
     id:"W938",
     enfermedad:"Exposicion a frio excesivo de origen artificial: otro lugar especificado"
  },
  {
     id:"W939",
     enfermedad:"Exposicion a frio excesivo de origen artificial: lugar no especificado"
  },
  {
     id:"W94",
     enfermedad:"Exposicion a presion de aire alta baja y a cambios en la presion de aire"
  },
  {
     id:"W940",
     enfermedad:"Exposicion a presion de aire alta y baja y a cambios en la presion del aire: vivienda"
  },
  {
     id:"W941",
     enfermedad:"Exposicion a presion de aire alta y baja y a cambios en la presion del aire: institucion residencial"
  },
  {
     id:"W942",
     enfermedad:"Exposicion a presion de aire alta y baja y a cambios en la presion del aire: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W943",
     enfermedad:"Exposicion a presion de aire alta y baja y a cambios en la presion del aire: areas de deporte y atletismo"
  },
  {
     id:"W944",
     enfermedad:"Exposicion a presion de aire alta y baja y a cambios en la presion del aire: calles y carreteras"
  },
  {
     id:"W945",
     enfermedad:"Exposicion a presion de aire alta y baja y a cambios en la presion del aire: comercio y areas de servicio"
  },
  {
     id:"W946",
     enfermedad:"Exposicion a presion de aire alta y baja y a cambios en la presion del aire: area industrial y de la construccion"
  },
  {
     id:"W947",
     enfermedad:"Exposicion a presion de aire alta y baja y a cambios en la presion del aire: granja"
  },
  {
     id:"W948",
     enfermedad:"Exposicion a presion de aire alta y baja y a cambios en la presion del aire: otro lugar especificado"
  },
  {
     id:"W949",
     enfermedad:"Exposicion a presion de aire alta y baja y a cambios en la presion del aire: lugar no especificado"
  },
  {
     id:"W99",
     enfermedad:"Exposicion a otros factores ambientales y a los no especificados de origen artificial"
  },
  {
     id:"W990",
     enfermedad:"Exposicion a otros factores ambientales y a los no especificados, de origen artificial: vivienda"
  },
  {
     id:"W991",
     enfermedad:"Exposicion a otros factores ambientales y a los no especificados, de origen artificial: institucion residencial"
  },
  {
     id:"W992",
     enfermedad:"Exposicion a otros factores ambientales y a los no especificados, de origen artificial: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"W993",
     enfermedad:"Exposicion a otros factores ambientales y a los no especificados, de origen artificial: areas de deporte y atletismo"
  },
  {
     id:"W994",
     enfermedad:"Exposicion a otros factores ambientales y a los no especificados, de origen artificial: calles y carreteras"
  },
  {
     id:"W995",
     enfermedad:"Exposicion a otros factores ambientales y a los no especificados, de origen artificial: comercio y areas de servicio"
  },
  {
     id:"W996",
     enfermedad:"Exposicion a otros factores ambientales y a los no especificados, de origen artificial: area industrial y de la construccion"
  },
  {
     id:"W997",
     enfermedad:"Exposicion a otros factores ambientales y a los no especificados, de origen artificial: granja"
  },
  {
     id:"W998",
     enfermedad:"Exposicion a otros factores ambientales y a los no especificados, de origen artificial: otro lugar especificado"
  },
  {
     id:"W999",
     enfermedad:"Exposicion a otros factores ambientales y a los no especificados, de origen artificial: lugar no especificado"
  },
  {
     id:"X",
     enfermedad:"Enfermedades del sistema respiratorio"
  },
  {
     id:"X00",
     enfermedad:"Exposicion a fuego no controlado en edificio u otra construccion"
  },
  {
     id:"X000",
     enfermedad:"Exposicion a fuego no controlado en edificio u otra construccion: vivienda"
  },
  {
     id:"X001",
     enfermedad:"Exposicion a fuego no controlado en edificio u otra construccion: institucion residencial"
  },
  {
     id:"X002",
     enfermedad:"Exposicion a fuego no controlado en edificio u otra construccion: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X003",
     enfermedad:"Exposicion a fuego no controlado en edificio u otra construccion: areas de deporte y atletismo"
  },
  {
     id:"X004",
     enfermedad:"Exposicion a fuego no controlado en edificio u otra construccion: calles y carreteras"
  },
  {
     id:"X005",
     enfermedad:"Exposicion a fuego no controlado en edificio u otra construccion: comercio y areas de servicio"
  },
  {
     id:"X006",
     enfermedad:"Exposicion a fuego no controlado en edificio u otra construccion: area industrial y de la construccion"
  },
  {
     id:"X007",
     enfermedad:"Exposicion a fuego no controlado en edificio u otra construccion: granja"
  },
  {
     id:"X008",
     enfermedad:"Exposicion a fuego no controlado en edificio u otra construccion: otro lugar especificado"
  },
  {
     id:"X009",
     enfermedad:"Exposicion a fuego no controlado en edificio u otra construccion: lugar no especificado"
  },
  {
     id:"X01",
     enfermedad:"Exposicion a fuego no controlado en lugar que no es edificio u otra construccion"
  },
  {
     id:"X010",
     enfermedad:"Exposicion a fuego no controlado en lugar que no es edificio u otra construccion: vivienda"
  },
  {
     id:"X011",
     enfermedad:"Exposicion a fuego no controlado en lugar que no es edificio u otra construccion: institucion residencial"
  },
  {
     id:"X012",
     enfermedad:"Exposicion a fuego no controlado en lugar que no es edificio u otra construccion: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X013",
     enfermedad:"Exposicion a fuego no controlado en lugar que no es edificio u otra construccion: areas de deporte y atletismo"
  },
  {
     id:"X014",
     enfermedad:"Exposicion a fuego no controlado en lugar que no es edificio u otra construccion: calles y carreteras"
  },
  {
     id:"X015",
     enfermedad:"Exposicion a fuego no controlado en lugar que no es edificio u otra construccion: comercio y areas de servicio"
  },
  {
     id:"X016",
     enfermedad:"Exposicion a fuego no controlado en lugar que no es edificio u otra construccion: areas industrial y de la construccion"
  },
  {
     id:"X017",
     enfermedad:"Exposicion a fuego no controlado en lugar que no es edificio u otra construccion: granja"
  },
  {
     id:"X018",
     enfermedad:"Exposicion a fuego no controlado en lugar que no es edificio u otra construccion: otro lugar especificado"
  },
  {
     id:"X019",
     enfermedad:"Exposicion a fuego no controlado en lugar que no es edificio u otra construccion: lugar no especificado"
  },
  {
     id:"X02",
     enfermedad:"Exposicion a fuego controlado en edificio u otra construccion"
  },
  {
     id:"X020",
     enfermedad:"Exposicion a fuego controlado en edificio u otra construccion: vivienda"
  },
  {
     id:"X021",
     enfermedad:"Exposicion a fuego controlado en edificio u otra construccion: institucion residencial"
  },
  {
     id:"X022",
     enfermedad:"Exposicion a fuego controlado en edificio u otra construccion: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X023",
     enfermedad:"Exposicion a fuego controlado en edificio u otra construccion: areas de deporte y atletismo"
  },
  {
     id:"X024",
     enfermedad:"Exposicion a fuego controlado en edificio u otra construccion: calles y carreteras"
  },
  {
     id:"X025",
     enfermedad:"Exposicion a fuego controlado en edificio u otra construccion: comercio y area de servicio"
  },
  {
     id:"X026",
     enfermedad:"Exposicion a fuego controlado en edificio u otra construccion: area industrial y de la construccion"
  },
  {
     id:"X027",
     enfermedad:"Exposicion a fuego controlado en edificio u otra construccion: granja"
  },
  {
     id:"X028",
     enfermedad:"Exposicion a fuego controlado en edificio u otra construccion: otro lugar especificado"
  },
  {
     id:"X029",
     enfermedad:"Exposicion a fuego controlado en edificio u otra construccion: lugar no especificado"
  },
  {
     id:"X03",
     enfermedad:"Exposicion a fuego controlado en lugar que no es edificio u otra construccion"
  },
  {
     id:"X030",
     enfermedad:"Exposicion a fuego controlado en lugar que no es edificio u otra construccion: vivienda"
  },
  {
     id:"X031",
     enfermedad:"Exposicion a fuego controlado en lugar que no es edificio u otra construccion: institucion residencial"
  },
  {
     id:"X032",
     enfermedad:"Exposicion a fuego controlado en lugar que no es edificio u otra construccion: escuelas, otras instituciones areas administrativas publicas"
  },
  {
     id:"X033",
     enfermedad:"Exposicion a fuego controlado en lugar que no es edificio u otra construccion: areas de deporte y atletismo"
  },
  {
     id:"X034",
     enfermedad:"Exposicion a fuego controlado en lugar que no es edificio u otra construccion: calles y carreteras"
  },
  {
     id:"X035",
     enfermedad:"Exposicion a fuego controlado en lugar que no es edificio u otra construccion: comercio y areas de servicio"
  },
  {
     id:"X036",
     enfermedad:"Exposicion a fuego controlado en lugar que no es edificio u otra construccion: area industrial y de la construccion"
  },
  {
     id:"X037",
     enfermedad:"Exposicion a fuego controlado en lugar que no es edificio u otra construccion: granja"
  },
  {
     id:"X038",
     enfermedad:"Exposicion a fuego controlado en lugar que no es edificio u otra construccion: otro lugar especificado"
  },
  {
     id:"X039",
     enfermedad:"Exposicion a fuego controlado en lugar que no es edificio u otra construccion: lugar no especificado"
  },
  {
     id:"X04",
     enfermedad:"Exposicion a ignicion de material altamente inflamable"
  },
  {
     id:"X040",
     enfermedad:"Exposicion a ignicion de material altamente inflamable: vivienda"
  },
  {
     id:"X041",
     enfermedad:"Exposicion a ignicion de material altamente inflamable: institucion residencial"
  },
  {
     id:"X042",
     enfermedad:"Exposicion a ignicion de material altamente inflamable: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X043",
     enfermedad:"Exposicion a ignicion de material altamente inflamable: areas de deporte y atletismo"
  },
  {
     id:"X044",
     enfermedad:"Exposicion a ignicion de material altamente inflamable: calles y carreteras"
  },
  {
     id:"X045",
     enfermedad:"Exposicion a ignicion de material altamente inflamable: comercio y areas de servicio"
  },
  {
     id:"X046",
     enfermedad:"Exposicion a ignicion de material altamente inflamable: area industrial y de la construccion"
  },
  {
     id:"X047",
     enfermedad:"Exposicion a ignicion de material altamente inflamable: granja"
  },
  {
     id:"X048",
     enfermedad:"Exposicion a ignicion de material altamente inflamable: otro lugar especificado"
  },
  {
     id:"X049",
     enfermedad:"Exposicion a ignicion de material altamente inflamable: lugar no especificado"
  },
  {
     id:"X05",
     enfermedad:"Exposicion a ignicion o fusion de ropas de dormir"
  },
  {
     id:"X050",
     enfermedad:"Exposicion a ignicion o fusion de ropas de dormir: vivienda"
  },
  {
     id:"X051",
     enfermedad:"Exposicion a ignicion o fusion de ropas de dormir: institucion residencial"
  },
  {
     id:"X052",
     enfermedad:"Exposicion a ignicion o fusion de ropas de dormir: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X053",
     enfermedad:"Exposicion a ignicion o fusion de ropas de dormir: areas de deporte y atletismo"
  },
  {
     id:"X054",
     enfermedad:"Exposicion a ignicion o fusion de ropas de dormir: calles y carreteras"
  },
  {
     id:"X055",
     enfermedad:"Exposicion a ignicion o fusion de ropas de dormir: comercio y area de servicios"
  },
  {
     id:"X056",
     enfermedad:"Exposicion a ignicion o fusion de ropas de dormir: area industrial y de la construccion"
  },
  {
     id:"X057",
     enfermedad:"Exposicion a ignicion o fusion de ropas de dormir: granja"
  },
  {
     id:"X058",
     enfermedad:"Exposicion a ignicion o fusion de ropas de dormir: otro lugar especificado"
  },
  {
     id:"X059",
     enfermedad:"Exposicion a ignicion o fusion de ropas de dormir: lugar no especificado"
  },
  {
     id:"X06",
     enfermedad:"Exposicion a ignicion o fusion de otras ropas y accesorios"
  },
  {
     id:"X060",
     enfermedad:"Exposicion a ignicion o fusion de otras ropas y accesorios: vivienda"
  },
  {
     id:"X061",
     enfermedad:"Exposicion a ignicion o fusion de otras ropas y accesorios: institucion residencial"
  },
  {
     id:"X062",
     enfermedad:"Exposicion a ignicion o fusion de otras ropas y accesorios: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X063",
     enfermedad:"Exposicion a ignicion o fusion de otras ropas y accesorios: areas de deporte y atletismo"
  },
  {
     id:"X064",
     enfermedad:"Exposicion a ignicion o fusion de otras ropas y accesorios: calles y carreteras"
  },
  {
     id:"X065",
     enfermedad:"Exposicion a ignicion o fusion de otras ropas y accesorios: comercio y areas de servicio"
  },
  {
     id:"X066",
     enfermedad:"Exposicion a ignicion o fusion de otras ropas y accesorios: area industrial y de la construccion"
  },
  {
     id:"X067",
     enfermedad:"Exposicion a ignicion o fusion de otras ropas y accesorios: granja"
  },
  {
     id:"X068",
     enfermedad:"Exposicion a ignicion o fusion de otras ropas y accesorios: otro lugar especificado"
  },
  {
     id:"X069",
     enfermedad:"Exposicion a ignicion o fusion de otras ropas y accesorios: lugar no especificado"
  },
  {
     id:"X08",
     enfermedad:"Exposicion a otros humos, fuegos o llamas especificados"
  },
  {
     id:"X080",
     enfermedad:"Exposicion a otros humos, fuegos o llamas especificados: vivienda"
  },
  {
     id:"X081",
     enfermedad:"Exposicion a otros humos, fuegos o llamas especificados: institucion residencial"
  },
  {
     id:"X082",
     enfermedad:"Exposicion a otros humos, fuegos o llamas especificados: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X083",
     enfermedad:"Exposicion a otros humos, fuegos o llamas especificados: areas de deporte y atletismo"
  },
  {
     id:"X084",
     enfermedad:"Exposicion a otros humos, fuegos o llamas especificados: calles y carreteras"
  },
  {
     id:"X085",
     enfermedad:"Exposicion a otros humos, fuegos o llamas especificados: comercio y areas de servicio"
  },
  {
     id:"X086",
     enfermedad:"Exposicion a otros humos, fuegos o llamas especificados: area industrial y de la construccion"
  },
  {
     id:"X087",
     enfermedad:"Exposicion a otros humos, fuegos o llamas especificados: granja"
  },
  {
     id:"X088",
     enfermedad:"Exposicion a otros humos, fuegos o llamas especificados: otro lugar especificado"
  },
  {
     id:"X089",
     enfermedad:"Exposicion a otros humos, fuegos o llamas especificados: lugar no especificado"
  },
  {
     id:"X09",
     enfermedad:"Exposicion a humos fuegos llamas no especificados"
  },
  {
     id:"X090",
     enfermedad:"Exposicion a humos, fuegos o llamas no especificados: vivienda"
  },
  {
     id:"X091",
     enfermedad:"Exposicion a humos, fuegos o llamas no especificados: institucion residencial"
  },
  {
     id:"X092",
     enfermedad:"Exposicion a humos, fuegos o llamas no especificados: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X093",
     enfermedad:"Exposicion a humos, fuegos o llamas no especificados: areas de deporte y atletismo"
  },
  {
     id:"X094",
     enfermedad:"Exposicion a humos, fuegos o llamas no especificados: calles y carreteras"
  },
  {
     id:"X095",
     enfermedad:"Exposicion a humos, fuegos o llamas no especificados: comercio y areas de servicio"
  },
  {
     id:"X096",
     enfermedad:"Exposicion a humos, fuegos o llamas no especificados: area industrial y de la construccion"
  },
  {
     id:"X097",
     enfermedad:"Exposicion a humos, fuegos o llamas no especificados: granja"
  },
  {
     id:"X098",
     enfermedad:"Exposicion a humos, fuegos o llamas no especificados: otro lugar especificado"
  },
  {
     id:"X099",
     enfermedad:"Exposicion a humos, fuegos o llamas no especificados: lugar no especificado"
  },
  {
     id:"X10",
     enfermedad:"Contacto con bebidas, alimentos, grasas y aceites para cocinar, calientes"
  },
  {
     id:"X100",
     enfermedad:"Contacto con bebidas, alimentos, grasas y aceites para cocinar, calientes: vivienda"
  },
  {
     id:"X101",
     enfermedad:"Contacto con bebidas, alimentos, grasas y aceites para cocinar, calientes: institucion residencial"
  },
  {
     id:"X102",
     enfermedad:"Contacto con bebidas, alimentos, grasas y aceites para cocinar, calientes: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X103",
     enfermedad:"Contacto con bebidas, alimentos, grasas y aceites para cocinar, calientes: areas de deporte y atletismo"
  },
  {
     id:"X104",
     enfermedad:"Contacto con bebidas, alimentos, grasas y aceites para cocinar, calientes: calles y carreteras"
  },
  {
     id:"X105",
     enfermedad:"Contacto con bebidas, alimentos, grasas y aceites para cocinar, calientes: comercio y areas de servicio"
  },
  {
     id:"X106",
     enfermedad:"Contacto con bebidas, alimentos, grasas y aceites para cocinar, calientes: area industrial y de la construccion"
  },
  {
     id:"X107",
     enfermedad:"Contacto con bebidas, alimentos, grasas y aceites para cocinar, calientes: granja"
  },
  {
     id:"X108",
     enfermedad:"Contacto con bebidas, alimentos, grasas y aceites para cocinar, calientes: otro lugar especificado"
  },
  {
     id:"X109",
     enfermedad:"Contacto con bebidas, alimentos, grasas y aceites para cocinar, calientes: lugar no especificado"
  },
  {
     id:"X11",
     enfermedad:"Contacto con agua caliente corriente"
  },
  {
     id:"X110",
     enfermedad:"Contacto con agua caliente corriente: vivienda"
  },
  {
     id:"X111",
     enfermedad:"Contacto con agua caliente corriente: institucion residencial"
  },
  {
     id:"X112",
     enfermedad:"Contacto con agua caliente corriente: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X113",
     enfermedad:"Contacto con agua caliente corriente: areas de deporte y atletismo"
  },
  {
     id:"X114",
     enfermedad:"Contacto con agua caliente corriente: calles y carreteras"
  },
  {
     id:"X115",
     enfermedad:"Contacto con agua caliente corriente: comercio y areas de servicio"
  },
  {
     id:"X116",
     enfermedad:"Contacto con agua caliente corriente: area industrial y de la construccion"
  },
  {
     id:"X117",
     enfermedad:"Contacto con agua caliente corriente: granja"
  },
  {
     id:"X118",
     enfermedad:"Contacto con agua caliente corriente: otro lugar especificado"
  },
  {
     id:"X119",
     enfermedad:"Contacto con agua caliente corriente: lugar no especificado"
  },
  {
     id:"X12",
     enfermedad:"Contacto con otros liquidos calientes"
  },
  {
     id:"X120",
     enfermedad:"Contacto con otros liquidos calientes: vivienda"
  },
  {
     id:"X121",
     enfermedad:"Contacto con otros liquidos calientes: institucion residencial"
  },
  {
     id:"X122",
     enfermedad:"Contacto con otros liquidos calientes: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X123",
     enfermedad:"Contacto con otros liquidos calientes: areas de deporte y atletismo"
  },
  {
     id:"X124",
     enfermedad:"Contacto con otros liquidos calientes: calles y carreteras"
  },
  {
     id:"X125",
     enfermedad:"Contacto con otros liquidos calientes: comercio y areas de servicio"
  },
  {
     id:"X126",
     enfermedad:"Contacto con otros liquidos calientes: area industrial y de la construccion"
  },
  {
     id:"X127",
     enfermedad:"Contacto con otros liquidos calientes: granja"
  },
  {
     id:"X128",
     enfermedad:"Contacto con otros liquidos calientes: otro lugar especificado"
  },
  {
     id:"X129",
     enfermedad:"Contacto con otros liquidos calientes: lugar no especificado"
  },
  {
     id:"X13",
     enfermedad:"Contacto con vapor de agua y otros vapores calientes"
  },
  {
     id:"X130",
     enfermedad:"Contacto con vapor de agua y otros vapores calientes: vivienda"
  },
  {
     id:"X131",
     enfermedad:"Contacto con vapor de agua y otros vapores calientes: institucion residencial"
  },
  {
     id:"X132",
     enfermedad:"Contacto con vapor de agua y otros vapores calientes: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X133",
     enfermedad:"Contacto con vapor de agua y otros vapores calientes: areas de deporte y atletismo"
  },
  {
     id:"X134",
     enfermedad:"Contacto con vapor de agua y otros vapores calientes: calles y carreteras"
  },
  {
     id:"X135",
     enfermedad:"Contacto con vapor de agua y otros vapores calientes: comercio y areas de servicio"
  },
  {
     id:"X136",
     enfermedad:"Contacto con vapor de agua y otros vapores calientes: area industrial y de la construccion"
  },
  {
     id:"X137",
     enfermedad:"Contacto con vapor de agua y otros vapores calientes: granja"
  },
  {
     id:"X138",
     enfermedad:"Contacto con vapor de agua y otros vapores calientes: otro lugar especificado"
  },
  {
     id:"X139",
     enfermedad:"Contacto con vapor de agua y otros vapores calientes: lugar no especificado"
  },
  {
     id:"X14",
     enfermedad:"Contacto con aire y gases calientes"
  },
  {
     id:"X140",
     enfermedad:"Contacto con aire y gases calientes: vivienda"
  },
  {
     id:"X141",
     enfermedad:"Contacto con aire y gases calientes: institucion residencial"
  },
  {
     id:"X142",
     enfermedad:"Contacto con aire y gases calientes: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X143",
     enfermedad:"Contacto con aire y gases calientes: areas de deporte y atletismo"
  },
  {
     id:"X144",
     enfermedad:"Contacto con aire y gases calientes: calles y carreteras"
  },
  {
     id:"X145",
     enfermedad:"Contacto con aire y gases calientes: comercio y areas de servicio"
  },
  {
     id:"X146",
     enfermedad:"Contacto con aire y gases calientes: area industrial y de la construccion"
  },
  {
     id:"X147",
     enfermedad:"Contacto con aire y gases calientes: granja"
  },
  {
     id:"X148",
     enfermedad:"Contacto con aire y gases calientes: otro lugar especificado"
  },
  {
     id:"X149",
     enfermedad:"Contacto con aire y gases calientes: lugar no especificado"
  },
  {
     id:"X15",
     enfermedad:"Contacto con utensilios domesticos calientes"
  },
  {
     id:"X150",
     enfermedad:"Contacto con utensilios domesticos calientes: vivienda"
  },
  {
     id:"X151",
     enfermedad:"Contacto con utensilios domesticos calientes: institucion residencial"
  },
  {
     id:"X152",
     enfermedad:"Contacto con utensilios domesticos calientes: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X153",
     enfermedad:"Contacto con utensilios domesticos calientes: areas de deporte y atletismo"
  },
  {
     id:"X154",
     enfermedad:"Contacto con utensilios domesticos calientes: calles y carreteras"
  },
  {
     id:"X155",
     enfermedad:"Contacto con utensilios domesticos calientes: comercio y areas de servicio"
  },
  {
     id:"X156",
     enfermedad:"Contacto con utensilios domesticos calientes: area industrial y de la construccion"
  },
  {
     id:"X157",
     enfermedad:"Contacto con utensilios domesticos calientes: granja"
  },
  {
     id:"X158",
     enfermedad:"Contacto con utensilios domesticos calientes otro lugar especificado:"
  },
  {
     id:"X159",
     enfermedad:"Contacto con utensilios domesticos calientes: lugar no especificado"
  },
  {
     id:"X16",
     enfermedad:"Contacto con radiadores, cañerias y artefactos para calefaccion, calientes"
  },
  {
     id:"X160",
     enfermedad:"Contacto con radiadores, cañerias y artefactos para calefaccion, calientes: vivienda"
  },
  {
     id:"X161",
     enfermedad:"Contacto con radiadores, cañerias y artefactos para calefaccion, calientes: institucion residencial"
  },
  {
     id:"X162",
     enfermedad:"Contacto con radiadores, cañerias y artefactos para calefaccion, calientes: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X163",
     enfermedad:"Contacto con radiadores, cañerias y artefactos para calefaccion, calientes: areas de deporte y atletismo"
  },
  {
     id:"X164",
     enfermedad:"Contacto con radiadores, cañerias y artefactos para calefaccion, calientes: calles y carreteras"
  },
  {
     id:"X165",
     enfermedad:"Contacto con radiadores, cañerias y artefactos para calefaccion, calientes: comercio y areas de servicio"
  },
  {
     id:"X166",
     enfermedad:"Contacto con radiadores, cañerias y artefactos para calefaccion, calientes: area industrial y de la construccion"
  },
  {
     id:"X167",
     enfermedad:"Contacto con radiadores, cañerias y artefactos para calefaccion, calientes: granja"
  },
  {
     id:"X168",
     enfermedad:"Contacto con radiadores, cañerias y artefactos para calefaccion, calientes: otro lugar especificado"
  },
  {
     id:"X169",
     enfermedad:"Contacto con radiadores, cañerias y artefactos para calefaccion, calientes: lugar no especificado"
  },
  {
     id:"X17",
     enfermedad:"Contacto con maquinas, motores y herramientas calientes"
  },
  {
     id:"X170",
     enfermedad:"Contacto con maquinas, motores y herramientas calientes: vivienda"
  },
  {
     id:"X171",
     enfermedad:"Contacto con maquinas, motores y herramientas calientes: institucion residencial"
  },
  {
     id:"X172",
     enfermedad:"Contacto con maquinas, motores y herramientas calientes: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X173",
     enfermedad:"Contacto con maquinas, motores y herramientas calientes: areas de deporte y atletismo"
  },
  {
     id:"X174",
     enfermedad:"Contacto con maquinas, motores y herramientas calientes: calles y carreteras"
  },
  {
     id:"X175",
     enfermedad:"Contacto con maquinas, motores y herramientas calientes: comercio y areas de servicio"
  },
  {
     id:"X176",
     enfermedad:"Contacto con maquinas, motores y herramientas calientes: area industrial y de la construccion"
  },
  {
     id:"X177",
     enfermedad:"Contacto con maquinas, motores y herramientas calientes: granja"
  },
  {
     id:"X178",
     enfermedad:"Contacto con maquinas, motores y herramientas calientes: otro lugar especificado"
  },
  {
     id:"X179",
     enfermedad:"Contacto con maquinas, motores y herramientas calientes: lugar no especificado"
  },
  {
     id:"X18",
     enfermedad:"Contacto con otros metales calientes"
  },
  {
     id:"X180",
     enfermedad:"Contacto con otros metales calientes: vivienda"
  },
  {
     id:"X181",
     enfermedad:"Contacto con otros metales calientes: institucion residencial"
  },
  {
     id:"X182",
     enfermedad:"Contacto con otros metales calientes: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X183",
     enfermedad:"Contacto con otros metales calientes: areas de deporte y atletismo"
  },
  {
     id:"X184",
     enfermedad:"Contacto con otros metales calientes: calles y carreteras"
  },
  {
     id:"X185",
     enfermedad:"Contacto con otros metales calientes: comercio y areas de servicio"
  },
  {
     id:"X186",
     enfermedad:"Contacto con otros metales calientes: area industrial y de la construccion"
  },
  {
     id:"X187",
     enfermedad:"Contacto con otros metales calientes: granja"
  },
  {
     id:"X188",
     enfermedad:"Contacto con otros metales calientes: otro lugar especificado"
  },
  {
     id:"X189",
     enfermedad:"Contacto con otros metales calientes: lugar no especificado"
  },
  {
     id:"X19",
     enfermedad:"Contacto con otras sustancias calientes y las no especificadas"
  },
  {
     id:"X190",
     enfermedad:"Contacto con otras sustancias calientes, y las no especificadas: vivienda"
  },
  {
     id:"X191",
     enfermedad:"Contacto con otras sustancias calientes, y las no especificadas: institucion residencial"
  },
  {
     id:"X192",
     enfermedad:"Contacto con otras sustancias calientes, y las no especificadas: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X193",
     enfermedad:"Contacto con otras sustancias calientes, y las no especificadas: areas de deporte y atletismo"
  },
  {
     id:"X194",
     enfermedad:"Contacto con otras sustancias calientes, y las no especificadas: calles y carreteras"
  },
  {
     id:"X195",
     enfermedad:"Contacto con otras sustancias calientes, y las no especificadas: comercio y areas de servicio"
  },
  {
     id:"X196",
     enfermedad:"Contacto con otras sustancias calientes, y las no especificadas: area industrial y de la construccion"
  },
  {
     id:"X197",
     enfermedad:"Contacto con otras sustancias calientes, y las no especificadas: granja"
  },
  {
     id:"X198",
     enfermedad:"Contacto con otras sustancias calientes, y las no especificadas: otro lugar especificado"
  },
  {
     id:"X199",
     enfermedad:"Contacto con otras sustancias calientes, y las no especificadas: lugar no especificado"
  },
  {
     id:"X20",
     enfermedad:"Contacto traumatico con serpientes y lagartos venenosos"
  },
  {
     id:"X200",
     enfermedad:"Contacto traumatico con serpientes y lagartos venenosos: vivienda"
  },
  {
     id:"X201",
     enfermedad:"Contacto traumatico con serpientes y lagartos venenosos: institucion residencial"
  },
  {
     id:"X202",
     enfermedad:"Contacto traumatico con serpientes y lagartos venenosos: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X203",
     enfermedad:"Contacto traumatico con serpientes y lagartos venenosos: areas de deporte y atletismo"
  },
  {
     id:"X204",
     enfermedad:"Contacto traumatico con serpientes y lagartos venenosos: calles y carreteras"
  },
  {
     id:"X205",
     enfermedad:"Contacto traumatico con serpientes y lagartos venenosos: comercio y areas de servicio"
  },
  {
     id:"X206",
     enfermedad:"Contacto traumatico con serpientes y lagartos venenosos: area industrial y de la construccion"
  },
  {
     id:"X207",
     enfermedad:"Contacto traumatico con serpientes y lagartos venenosos: granja"
  },
  {
     id:"X208",
     enfermedad:"Contacto traumatico con serpientes y lagartos venenosos: otro lugar especificado"
  },
  {
     id:"X209",
     enfermedad:"Contacto traumatico con serpientes y lagartos venenosos: lugar no especificado"
  },
  {
     id:"X21",
     enfermedad:"Contacto traumatico con arañas venenosas"
  },
  {
     id:"X210",
     enfermedad:"Contacto traumatico con arañas venenosas: vivienda"
  },
  {
     id:"X211",
     enfermedad:"Contacto traumatico con arañas venenosas: institucion residencial"
  },
  {
     id:"X212",
     enfermedad:"Contacto traumatico con arañas venenosas: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X213",
     enfermedad:"Contacto traumatico con arañas venenosas: areas de deporte y atletismo"
  },
  {
     id:"X214",
     enfermedad:"Contacto traumatico con arañas venenosas: calles y carreteras"
  },
  {
     id:"X215",
     enfermedad:"Contacto traumatico con arañas venenosas: comercio y areas de servicio"
  },
  {
     id:"X216",
     enfermedad:"Contacto traumatico con arañas venenosas: area industrial y de la construccion"
  },
  {
     id:"X217",
     enfermedad:"Contacto traumatico con arañas venenosas: granja"
  },
  {
     id:"X218",
     enfermedad:"Contacto traumatico con arañas venenosas: otro lugar especificado"
  },
  {
     id:"X219",
     enfermedad:"Contacto traumatico con arañas venenosas: lugar no especificado"
  },
  {
     id:"X22",
     enfermedad:"Contacto traumatico con escorpion"
  },
  {
     id:"X220",
     enfermedad:"Contacto traumatico con escorpion: vivienda"
  },
  {
     id:"X221",
     enfermedad:"Contacto traumatico con escorpion: institucion residencial"
  },
  {
     id:"X222",
     enfermedad:"Contacto traumatico con escorpion: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X223",
     enfermedad:"Contacto traumatico con escorpion: areas de deporte y atletismo"
  },
  {
     id:"X224",
     enfermedad:"Contacto traumatico con escorpion: calles y carreteras"
  },
  {
     id:"X225",
     enfermedad:"Contacto traumatico con escorpion: comercio y areas de servicio"
  },
  {
     id:"X226",
     enfermedad:"Contacto traumatico con escorpion: area industrial y de la construccion"
  },
  {
     id:"X227",
     enfermedad:"Contacto traumatico con escorpion: granja"
  },
  {
     id:"X228",
     enfermedad:"Contacto traumatico con escorpion: otro lugar especificado"
  },
  {
     id:"X229",
     enfermedad:"Contacto traumatico con escorpion: lugar no especificado"
  },
  {
     id:"X23",
     enfermedad:"Contacto traumatico con avispas, avispones y abejas"
  },
  {
     id:"X230",
     enfermedad:"Contacto traumatico con avispones, avispas y abejas: vivienda"
  },
  {
     id:"X231",
     enfermedad:"Contacto traumatico con avispones, avispas y abejas: institucion residencial"
  },
  {
     id:"X232",
     enfermedad:"Contacto traumatico con avispones, avispas y abejas: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X233",
     enfermedad:"Contacto traumatico con avispones, avispas y abejas: areas de deporte y atletismo"
  },
  {
     id:"X234",
     enfermedad:"Contacto traumatico con avispones, avispas y abejas: calles y carreteras"
  },
  {
     id:"X235",
     enfermedad:"Contacto traumatico con avispones, avispas y abejas: comercio y areas de servicio"
  },
  {
     id:"X236",
     enfermedad:"Contacto traumatico con avispones, avispas y abejas: area industrial y de la construccion"
  },
  {
     id:"X237",
     enfermedad:"Contacto traumatico con avispones, avispas y abejas: granja"
  },
  {
     id:"X238",
     enfermedad:"Contacto traumatico con avispones, avispas y abejas: otro lugar especificado"
  },
  {
     id:"X239",
     enfermedad:"Contacto traumatico con avispones, avispas y abejas: lugar no especificado"
  },
  {
     id:"X24",
     enfermedad:"Contacto traumatico con centipodos y miriapodos venenosos"
  },
  {
     id:"X240",
     enfermedad:"Contacto traumatico con centipodos y miriapodos venenosos (tropicales): vivienda"
  },
  {
     id:"X241",
     enfermedad:"Contacto traumatico con centipodos y miriapodos venenosos (tropicales): institucion residencial"
  },
  {
     id:"X242",
     enfermedad:"Contacto traumatico con centipodos y miriapodos venenosos (tropicales): escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X243",
     enfermedad:"Contacto traumatico con centipodos y miriapodos venenosos (tropicales): areas de deporte y atletismo"
  },
  {
     id:"X244",
     enfermedad:"Contacto traumatico con centipodos y miriapodos venenosos (tropicales): calles y carreteras"
  },
  {
     id:"X245",
     enfermedad:"Contacto traumatico con centipodos y miriapodos venenosos (tropicales): comercio y areas de servicio"
  },
  {
     id:"X246",
     enfermedad:"Contacto traumatico con centipodos y miriapodos venenosos (tropicales): area industrial y de la construccion"
  },
  {
     id:"X247",
     enfermedad:"Contacto traumatico con centipodos y miriapodos venenosos (tropicales): granja"
  },
  {
     id:"X248",
     enfermedad:"Contacto traumatico con centipodos y miriapodos venenosos (tropicales): otro lugar especificado"
  },
  {
     id:"X249",
     enfermedad:"Contacto traumatico con centipodos y miriapodos venenosos (tropicales): lugar no especificado"
  },
  {
     id:"X25",
     enfermedad:"Contacto traumatico con otros artropodos venenosos especificados"
  },
  {
     id:"X250",
     enfermedad:"Contacto traumatico con otros artropodos venenosos especificados: vivienda"
  },
  {
     id:"X251",
     enfermedad:"Contacto traumatico con otros artropodos venenosos especificados: institucion residencial"
  },
  {
     id:"X252",
     enfermedad:"Contacto traumatico con otros artropodos venenosos especificados: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X253",
     enfermedad:"Contacto traumatico con otros artropodos venenosos especificados: areas de deporte y atletismo"
  },
  {
     id:"X254",
     enfermedad:"Contacto traumatico con otros artropodos venenosos especificados: calles y carreteras"
  },
  {
     id:"X255",
     enfermedad:"Contacto traumatico con otros artropodos venenosos especificados: comercio y areas de servicio"
  },
  {
     id:"X256",
     enfermedad:"Contacto traumatico con otros artropodos venenosos especificados: area industrial y de la construccion"
  },
  {
     id:"X257",
     enfermedad:"Contacto traumatico con otros artropodos venenosos especificados: granja"
  },
  {
     id:"X258",
     enfermedad:"Contacto traumatico con otros artropodos venenosos especificados: otro lugar especificado"
  },
  {
     id:"X259",
     enfermedad:"Contacto traumatico con otros artropodos venenosos especificados: lugar no especificado"
  },
  {
     id:"X26",
     enfermedad:"Contacto traumatico con animales y plantas marinas venenosos"
  },
  {
     id:"X260",
     enfermedad:"Contacto traumatico con animales y plantas marinas venenosos: vivienda"
  },
  {
     id:"X261",
     enfermedad:"Contacto traumatico con animales y plantas marinas venenosos: institucion residencial"
  },
  {
     id:"X262",
     enfermedad:"Contacto traumatico con animales y plantas marinas venenosos: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X263",
     enfermedad:"Contacto traumatico con animales y plantas marinas venenosos: areas de deporte y atletismo"
  },
  {
     id:"X264",
     enfermedad:"Contacto traumatico con animales y plantas marinas venenosos: calles y carreteras"
  },
  {
     id:"X265",
     enfermedad:"Contacto traumatico con animales y plantas marinas venenosos: comercio y areas de servicio"
  },
  {
     id:"X266",
     enfermedad:"Contacto traumatico con animales y plantas marinas venenosos: area industrial y de la construccion"
  },
  {
     id:"X267",
     enfermedad:"Contacto traumatico con animales y plantas marinas venenosos: granja"
  },
  {
     id:"X268",
     enfermedad:"Contacto traumatico con animales y plantas marinas venenosos: otro lugar especificado"
  },
  {
     id:"X269",
     enfermedad:"Contacto traumatico con animales y plantas marinas venenosos: lugar no especificado"
  },
  {
     id:"X27",
     enfermedad:"Contacto traumatico con otros animales venenosos especificados"
  },
  {
     id:"X270",
     enfermedad:"Contacto traumatico con otros animales venenosos especificados: vivienda"
  },
  {
     id:"X271",
     enfermedad:"Contacto traumatico con otros animales venenosos especificados: institucion residencial"
  },
  {
     id:"X272",
     enfermedad:"Contacto traumatico con otros animales venenosos especificados: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X273",
     enfermedad:"Contacto traumatico con otros animales venenosos especificados: areas de deporte y atletismo"
  },
  {
     id:"X274",
     enfermedad:"Contacto traumatico con otros animales venenosos especificados: calles y carreteras"
  },
  {
     id:"X275",
     enfermedad:"Contacto traumatico con otros animales venenosos especificados: comercio y areas de servicio"
  },
  {
     id:"X276",
     enfermedad:"Contacto traumatico con otros animales venenosos especificados: area industrial y de la construccion"
  },
  {
     id:"X277",
     enfermedad:"Contacto traumatico con otros animales venenosos especificados: granja"
  },
  {
     id:"X278",
     enfermedad:"Contacto traumatico con otros animales venenosos especificados: otro lugar especificado"
  },
  {
     id:"X279",
     enfermedad:"Contacto traumatico con otros animales venenosos especificados: lugar no especificado"
  },
  {
     id:"X28",
     enfermedad:"Contacto traumatico con otras plantas venenosos especificados"
  },
  {
     id:"X280",
     enfermedad:"Contacto traumatico con otras plantas venenosas especificadas: vivienda"
  },
  {
     id:"X281",
     enfermedad:"Contacto traumatico con otras plantas venenosas especificadas: institucion residencial"
  },
  {
     id:"X282",
     enfermedad:"Contacto traumatico con otras plantas venenosas especificadas: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X283",
     enfermedad:"Contacto traumatico con otras plantas venenosas especificadas: areas de deporte y atletismo"
  },
  {
     id:"X284",
     enfermedad:"Contacto traumatico con otras plantas venenosas especificadas: calles y carreteras"
  },
  {
     id:"X285",
     enfermedad:"Contacto traumatico con otras plantas venenosas especificadas: comercio y areas de servicio"
  },
  {
     id:"X286",
     enfermedad:"Contacto traumatico con otras plantas venenosas especificadas: area industrial y de la construccion"
  },
  {
     id:"X287",
     enfermedad:"Contacto traumatico con otras plantas venenosas especificadas: granja"
  },
  {
     id:"X288",
     enfermedad:"Contacto traumatico con otras plantas venenosas especificadas: otro lugar especificado"
  },
  {
     id:"X289",
     enfermedad:"Contacto traumatico con otras plantas venenosas especificadas: lugar no especificado"
  },
  {
     id:"X29",
     enfermedad:"Contacto traumatico con animales y plantas venenosos no especificados"
  },
  {
     id:"X290",
     enfermedad:"Contacto traumatico con animales y plantas venenosos no especificados: vivienda"
  },
  {
     id:"X291",
     enfermedad:"Contacto traumatico con animales y plantas venenosos no especificados: institucion residencial"
  },
  {
     id:"X292",
     enfermedad:"Contacto traumatico con animales y plantas venenosos no especificados: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X293",
     enfermedad:"Contacto traumatico con animales y plantas venenosos no especificados: areas de deporte y atletismo"
  },
  {
     id:"X294",
     enfermedad:"Contacto traumatico con animales y plantas venenosos no especificados: calles y carreteras"
  },
  {
     id:"X295",
     enfermedad:"Contacto traumatico con animales y plantas venenosos no especificados: comercio y areas de servicio"
  },
  {
     id:"X296",
     enfermedad:"Contacto traumatico con animales y plantas venenosos no especificados: area industrial y de la construccion"
  },
  {
     id:"X297",
     enfermedad:"Contacto traumatico con animales y plantas venenosos no especificados: granja"
  },
  {
     id:"X298",
     enfermedad:"Contacto traumatico con animales y plantas venenosos no especificados: otro lugar especificado"
  },
  {
     id:"X299",
     enfermedad:"Contacto traumatico con animales y plantas venenosos no especificados: lugar no especificado"
  },
  {
     id:"X30",
     enfermedad:"Exposicion al calor natural excesivo"
  },
  {
     id:"X300",
     enfermedad:"Exposicion al calor natural excesivo: vivienda"
  },
  {
     id:"X301",
     enfermedad:"Exposicion al calor natural excesivo: institucion residencial"
  },
  {
     id:"X302",
     enfermedad:"Exposicion al calor natural excesivo: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X303",
     enfermedad:"Exposicion al calor natural excesivo: areas de deporte y atletismo"
  },
  {
     id:"X304",
     enfermedad:"Exposicion al calor natural excesivo: calles y carreteras"
  },
  {
     id:"X305",
     enfermedad:"Exposicion al calor natural excesivo: comercio y areas de servicio"
  },
  {
     id:"X306",
     enfermedad:"Exposicion al calor natural excesivo: area industrial y de la construccion"
  },
  {
     id:"X307",
     enfermedad:"Exposicion al calor natural excesivo: granja"
  },
  {
     id:"X308",
     enfermedad:"Exposicion al calor natural excesivo: otro lugar especificado"
  },
  {
     id:"X309",
     enfermedad:"Exposicion al calor natural excesivo: lugar no especificado"
  },
  {
     id:"X31",
     enfermedad:"Exposicion al frio natural excesivo"
  },
  {
     id:"X310",
     enfermedad:"Exposicion al frio natural excesivo: vivienda"
  },
  {
     id:"X311",
     enfermedad:"Exposicion al frio natural excesivo: institucion residencial"
  },
  {
     id:"X312",
     enfermedad:"Exposicion al frio natural excesivo: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X313",
     enfermedad:"Exposicion al frio natural excesivo: areas de deporte y atletismo"
  },
  {
     id:"X314",
     enfermedad:"Exposicion al frio natural excesivo: calles y carreteras"
  },
  {
     id:"X315",
     enfermedad:"Exposicion al frio natural excesivo: comercio y areas de servicio"
  },
  {
     id:"X316",
     enfermedad:"Exposicion al frio natural excesivo: area industrial y de la construccion"
  },
  {
     id:"X317",
     enfermedad:"Exposicion al frio natural excesivo: granja"
  },
  {
     id:"X318",
     enfermedad:"Exposicion al frio natural excesivo: otro lugar especificado"
  },
  {
     id:"X319",
     enfermedad:"Exposicion al frio natural excesivo: lugar no especificado"
  },
  {
     id:"X32",
     enfermedad:"Exposicion a rayos solares"
  },
  {
     id:"X320",
     enfermedad:"Exposicion a rayos solares: vivienda"
  },
  {
     id:"X321",
     enfermedad:"Exposicion a rayos solares: institucion residencial"
  },
  {
     id:"X322",
     enfermedad:"Exposicion a rayos solares: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X323",
     enfermedad:"Exposicion a rayos solares: areas de deporte y atletismo"
  },
  {
     id:"X324",
     enfermedad:"Exposicion a rayos solares: calles y carreteras"
  },
  {
     id:"X325",
     enfermedad:"Exposicion a rayos solares: comercio y areas de servicio"
  },
  {
     id:"X326",
     enfermedad:"Exposicion a rayos solares: area industrial y de la construccion"
  },
  {
     id:"X327",
     enfermedad:"Exposicion a rayos solares: granja"
  },
  {
     id:"X328",
     enfermedad:"Exposicion a rayos solares: otro lugar especificado"
  },
  {
     id:"X329",
     enfermedad:"Exposicion a rayos solares: lugar no especificado"
  },
  {
     id:"X33",
     enfermedad:"Victima de rayo"
  },
  {
     id:"X330",
     enfermedad:"Victima de rayo: vivienda"
  },
  {
     id:"X331",
     enfermedad:"Victima de rayo: institucion residencial"
  },
  {
     id:"X332",
     enfermedad:"Victima de rayo: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X333",
     enfermedad:"Victima de rayo: areas de deporte y atletismo"
  },
  {
     id:"X334",
     enfermedad:"Victima de rayo: calles y carreteras"
  },
  {
     id:"X335",
     enfermedad:"Victima de rayo: comercio y areas de servicio"
  },
  {
     id:"X336",
     enfermedad:"Victima de rayo: area industrial y de la construccion"
  },
  {
     id:"X337",
     enfermedad:"Victima de rayo: granja"
  },
  {
     id:"X338",
     enfermedad:"Victima de rayo: otro lugar especificado"
  },
  {
     id:"X339",
     enfermedad:"Victima de rayo: lugar no especificado"
  },
  {
     id:"X34",
     enfermedad:"Victima de terremoto"
  },
  {
     id:"X340",
     enfermedad:"Victima de terremoto: vivienda"
  },
  {
     id:"X341",
     enfermedad:"Victima de terremoto: institucion residencial"
  },
  {
     id:"X342",
     enfermedad:"Victima de terremoto: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X343",
     enfermedad:"Victima de terremoto: areas de deporte y atletismo"
  },
  {
     id:"X344",
     enfermedad:"Victima de terremoto: calles y carreteras"
  },
  {
     id:"X345",
     enfermedad:"Victima de terremoto: comercio y areas de servicio"
  },
  {
     id:"X346",
     enfermedad:"Victima de terremoto: area industrial y de la construccion"
  },
  {
     id:"X347",
     enfermedad:"Victima de terremoto: granja"
  },
  {
     id:"X348",
     enfermedad:"Victima de terremoto: otro lugar especificado"
  },
  {
     id:"X349",
     enfermedad:"Victima de terremoto: lugar no especificado"
  },
  {
     id:"X35",
     enfermedad:"Victima de erupcion volcanica"
  },
  {
     id:"X350",
     enfermedad:"Victima de erupcion volcanica: vivienda"
  },
  {
     id:"X351",
     enfermedad:"Victima de erupcion volcanica: institucion residencial"
  },
  {
     id:"X352",
     enfermedad:"Victima de erupcion volcanica: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X353",
     enfermedad:"Victima de erupcion volcanica: areas de deporte y atletismo"
  },
  {
     id:"X354",
     enfermedad:"Victima de erupcion volcanica: calles y carreteras"
  },
  {
     id:"X355",
     enfermedad:"Victima de erupcion volcanica: comercio y areas de servicio"
  },
  {
     id:"X356",
     enfermedad:"Victima de erupcion volcanica: area industrial y de la construccion"
  },
  {
     id:"X357",
     enfermedad:"Victima de erupcion volcanica: granja"
  },
  {
     id:"X358",
     enfermedad:"Victima de erupcion volcanica: otro lugar especificado"
  },
  {
     id:"X359",
     enfermedad:"Victima de erupcion volcanica: lugar no especificado"
  },
  {
     id:"X36",
     enfermedad:"Victima de avalancha, derrumbe y otros movimientos de tierra"
  },
  {
     id:"X360",
     enfermedad:"Victima de avalancha, derrumbe y otros movimientos de tierra: vivienda"
  },
  {
     id:"X361",
     enfermedad:"Victima de avalancha, derrumbe y otros movimientos de tierra: institucion residencial"
  },
  {
     id:"X362",
     enfermedad:"Victima de avalancha, derrumbe y otros movimientos de tierra: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X363",
     enfermedad:"Victima de avalancha, derrumbe y otros movimientos de tierra: areas de deporte y atletismo"
  },
  {
     id:"X364",
     enfermedad:"Victima de avalancha, derrumbe y otros movimientos de tierra: calles y carreteras"
  },
  {
     id:"X365",
     enfermedad:"Victima de avalancha, derrumbe y otros movimientos de tierra: comercio y areas de servicio"
  },
  {
     id:"X366",
     enfermedad:"Victima de avalancha, derrumbe y otros movimientos de tierra: area industrial y de la construccion"
  },
  {
     id:"X367",
     enfermedad:"Victima de avalancha, derrumbe y otros movimientos de tierra: granja"
  },
  {
     id:"X368",
     enfermedad:"Victima de avalancha, derrumbe y otros movimientos de tierra: otro lugar especificado"
  },
  {
     id:"X369",
     enfermedad:"Victima de avalancha, derrumbe y otros movimientos de tierra: lugar no especificado"
  },
  {
     id:"X37",
     enfermedad:"Victima de tormenta cataclismica"
  },
  {
     id:"X370",
     enfermedad:"Victima de tormenta cataclismica: vivienda"
  },
  {
     id:"X371",
     enfermedad:"Victima de tormenta cataclismica: institucion residencial"
  },
  {
     id:"X372",
     enfermedad:"Victima de tormenta cataclismica: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X373",
     enfermedad:"Victima de tormenta cataclismica: areas de deporte y atletismo"
  },
  {
     id:"X374",
     enfermedad:"Victima de tormenta cataclismica: calles y carreteras"
  },
  {
     id:"X375",
     enfermedad:"Victima de tormenta cataclismica: comercio y areas de servicio"
  },
  {
     id:"X376",
     enfermedad:"Victima de tormenta cataclismica: area industrial y de la construccion"
  },
  {
     id:"X377",
     enfermedad:"Victima de tormenta cataclismica: granja"
  },
  {
     id:"X378",
     enfermedad:"Victima de tormenta cataclismica: otro lugar especificado"
  },
  {
     id:"X379",
     enfermedad:"Victima de tormenta cataclismica: lugar no especificado"
  },
  {
     id:"X38",
     enfermedad:"Victima de inundacion"
  },
  {
     id:"X380",
     enfermedad:"Victima de inundacion: vivienda"
  },
  {
     id:"X381",
     enfermedad:"Victima de inundacion: institucion residencial"
  },
  {
     id:"X382",
     enfermedad:"Victima de inundacion: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X383",
     enfermedad:"Victima de inundacion: areas de deporte y atletismo"
  },
  {
     id:"X384",
     enfermedad:"Victima de inundacion: calles y carreteras"
  },
  {
     id:"X385",
     enfermedad:"Victima de inundacion: comercio y areas de servicio"
  },
  {
     id:"X386",
     enfermedad:"Victima de inundacion: area industrial y de la construccion"
  },
  {
     id:"X387",
     enfermedad:"Victima de inundacion: granja"
  },
  {
     id:"X388",
     enfermedad:"Victima de inundacion: otro lugar especificado"
  },
  {
     id:"X389",
     enfermedad:"Victima de inundacion: lugar no especificado"
  },
  {
     id:"X39",
     enfermedad:"Exposicion a otras fuerzas naturales y las no especificadas"
  },
  {
     id:"X390",
     enfermedad:"Exposicion a otras fuerzas de la naturaleza, y las no especificadas: vivienda"
  },
  {
     id:"X391",
     enfermedad:"Exposicion a otras fuerzas de la naturaleza, y las no especificadas: institucion residencial"
  },
  {
     id:"X392",
     enfermedad:"Exposicion a otras fuerzas de la naturaleza, y las no especificadas: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X393",
     enfermedad:"Exposicion a otras fuerzas de la naturaleza, y las no especificadas: areas de deporte y atletismo"
  },
  {
     id:"X394",
     enfermedad:"Exposicion a otras fuerzas de la naturaleza, y las no especificadas: calles y carreteras"
  },
  {
     id:"X395",
     enfermedad:"Exposicion a otras fuerzas de la naturaleza, y las no especificadas: comercio y areas de servicio"
  },
  {
     id:"X396",
     enfermedad:"Exposicion a otras fuerzas de la naturaleza, y las no especificadas: area industrial y de la construccion"
  },
  {
     id:"X397",
     enfermedad:"Exposicion a otras fuerzas de la naturaleza, y las no especificadas: granja"
  },
  {
     id:"X398",
     enfermedad:"Exposicion a otras fuerzas de la naturaleza, y las no especificadas: otro lugar especificado"
  },
  {
     id:"X399",
     enfermedad:"Exposicion a otras fuerzas de la naturaleza, y las no especificadas: lugar no especificado"
  },
  {
     id:"X40",
     enfermedad:"Envenenamiento accidental por, y exposicion a analgesicos, antipireticos y antirreumaticos"
  },
  {
     id:"X400",
     enfermedad:"Envenenamiento accidental por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos: vivienda"
  },
  {
     id:"X401",
     enfermedad:"Envenenamiento accidental por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos: institucion residencial"
  },
  {
     id:"X402",
     enfermedad:"Envenenamiento accidental por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X403",
     enfermedad:"Envenenamiento accidental por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos: areas de deporte y atletismo"
  },
  {
     id:"X404",
     enfermedad:"Envenenamiento accidental por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos: calles y carreteras"
  },
  {
     id:"X405",
     enfermedad:"Envenenamiento accidental por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos: comercio y areas de servicio"
  },
  {
     id:"X406",
     enfermedad:"Envenenamiento accidental por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos: area industrial y de la construccion"
  },
  {
     id:"X407",
     enfermedad:"Envenenamiento accidental por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos: granja"
  },
  {
     id:"X408",
     enfermedad:"Envenenamiento accidental por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos: otro lugar especificado"
  },
  {
     id:"X409",
     enfermedad:"Envenenamiento accidental por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos: lugar no especificado"
  },
  {
     id:"X41",
     enfermedad:"Envenenamiento accidental por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparinsonianas y psicotropicas, no clasificadas en otra parte"
  },
  {
     id:"X410",
     enfermedad:"Envenenamiento accidental por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparkinsonianas y psicotropicas, no clasificadas en otra parte: vivienda"
  },
  {
     id:"X411",
     enfermedad:"Envenenamiento accidental por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparkinsonianas y psicotropicas, no clasificadas en otra parte: institucion residencial"
  },
  {
     id:"X412",
     enfermedad:"Envenenamiento accidental por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparkinsonianas y psicotropicas, no clasificadas en otra parte: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X413",
     enfermedad:"Envenenamiento accidental por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparkinsonianas y psicotropicas, no clasificadas en otra parte: areas de deporte y atletismo"
  },
  {
     id:"X414",
     enfermedad:"Envenenamiento accidental por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparkinsonianas y psicotropicas, no clasificadas en otra parte: calles y carreteras"
  },
  {
     id:"X415",
     enfermedad:"Envenenamiento accidental por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparkinsonianas y psicotropicas, no clasificadas en otra parte: comercio y area de servicio"
  },
  {
     id:"X416",
     enfermedad:"Envenenamiento accidental por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparkinsonianas y psicotropicas, no clasificadas en otra parte: area industrial y de la construccion"
  },
  {
     id:"X417",
     enfermedad:"Envenenamiento accidental por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparkinsonianas y psicotropicas, no clasificadas en otra parte: granja"
  },
  {
     id:"X418",
     enfermedad:"Envenenamiento accidental por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparkinsonianas y psicotropicas, no clasificadas en otra parte: otro lugar especificado"
  },
  {
     id:"X419",
     enfermedad:"Envenenamiento accidental por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparkinsonianas y psicotropicas, no clasificadas en otra parte: lugar no especificado"
  },
  {
     id:"X42",
     enfermedad:"Envenenamiento accidental por, y exposicion a narcoticos y psicodislepticos [alucinogenos] no clasificados en otra parte"
  },
  {
     id:"X420",
     enfermedad:"Envenenamiento accidental por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificados en otra parte: vivienda"
  },
  {
     id:"X421",
     enfermedad:"Envenenamiento accidental por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificados en otra parte: institucion residencial"
  },
  {
     id:"X422",
     enfermedad:"Envenenamiento accidental por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificados en otra parte: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X423",
     enfermedad:"Envenenamiento accidental por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificados en otra parte: areas de deporte y atletismo"
  },
  {
     id:"X424",
     enfermedad:"Envenenamiento accidental por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificados en otra parte: calles y carreteras"
  },
  {
     id:"X425",
     enfermedad:"Envenenamiento accidental por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificados en otra parte: comercio y area de servicios"
  },
  {
     id:"X426",
     enfermedad:"Envenenamiento accidental por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificados en otra parte: area industrial y de la construccion"
  },
  {
     id:"X427",
     enfermedad:"Envenenamiento accidental por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificados en otra parte: granja"
  },
  {
     id:"X428",
     enfermedad:"Envenenamiento accidental por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificados en otra parte: otro lugar especificado"
  },
  {
     id:"X429",
     enfermedad:"Envenenamiento accidental por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificados en otra parte: lugar no especificado"
  },
  {
     id:"X43",
     enfermedad:"Envenenamiento accidental por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo"
  },
  {
     id:"X430",
     enfermedad:"Envenenamiento accidental por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo: vivienda"
  },
  {
     id:"X431",
     enfermedad:"Envenenamiento accidental por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo: institucion residencial"
  },
  {
     id:"X432",
     enfermedad:"Envenenamiento accidental por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X433",
     enfermedad:"Envenenamiento accidental por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo: areas de deporte y atletismo"
  },
  {
     id:"X434",
     enfermedad:"Envenenamiento accidental por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo: calles y carreteras"
  },
  {
     id:"X435",
     enfermedad:"Envenenamiento accidental por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo: comercio y area de servicio"
  },
  {
     id:"X436",
     enfermedad:"Envenenamiento accidental por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo: area industrial y de la construccion"
  },
  {
     id:"X437",
     enfermedad:"Envenenamiento accidental por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo: granja"
  },
  {
     id:"X438",
     enfermedad:"Envenenamiento accidental por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo: otro lugar especificado"
  },
  {
     id:"X439",
     enfermedad:"Envenenamiento accidental por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo: lugar no especificado"
  },
  {
     id:"X44",
     enfermedad:"Envenenamiento accidental por, y exposicion a otras drogas, medicamentos y sustancias biologicas y los no especificados"
  },
  {
     id:"X440",
     enfermedad:"Envenenamiento accidental por, y exposicion a otras drogas, medicamentos y sustancias biologicas, y los no especificados: vivienda"
  },
  {
     id:"X441",
     enfermedad:"Envenenamiento accidental por, y exposicion a otras drogas, medicamentos y sustancias biologicas, y los no especificados: institucion residencial"
  },
  {
     id:"X442",
     enfermedad:"Envenenamiento accidental por, y exposicion a otras drogas, medicamentos y sustancias biologicas, y los no especificados: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X443",
     enfermedad:"Envenenamiento accidental por, y exposicion a otras drogas, medicamentos y sustancias biologicas, y los no especificados: areas de deporte y atletismo"
  },
  {
     id:"X444",
     enfermedad:"Envenenamiento accidental por, y exposicion a otras drogas, medicamentos y sustancias biologicas, y los no especificados: calles y carreteras"
  },
  {
     id:"X445",
     enfermedad:"Envenenamiento accidental por, y exposicion a otras drogas, medicamentos y sustancias biologicas, y los no especificados: comercio y area de servicios"
  },
  {
     id:"X446",
     enfermedad:"Envenenamiento accidental por, y exposicion a otras drogas, medicamentos y sustancias biologicas, y los no especificados: area industrial y de la construccion"
  },
  {
     id:"X447",
     enfermedad:"Envenenamiento accidental por, y exposicion a otras drogas, medicamentos y sustancias biologicas, y los no especificados: granja"
  },
  {
     id:"X448",
     enfermedad:"Envenenamiento accidental por, y exposicion a otras drogas, medicamentos y sustancias biologicas, y los no especificados: otro lugar especificado"
  },
  {
     id:"X449",
     enfermedad:"Envenenamiento accidental por, y exposicion a otras drogas, medicamentos y sustancias biologicas, y los no especificados: lugar no especificado"
  },
  {
     id:"X45",
     enfermedad:"Envenenamiento accidental por, y exposicion a alcohol"
  },
  {
     id:"X450",
     enfermedad:"Envenenamiento accidental por, y exposicion al alcohol: vivienda"
  },
  {
     id:"X451",
     enfermedad:"Envenenamiento accidental por, y exposicion al alcohol: institucion residencial"
  },
  {
     id:"X452",
     enfermedad:"Envenenamiento accidental por, y exposicion al alcohol: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X453",
     enfermedad:"Envenenamiento accidental por, y exposicion al alcohol: areas de deporte y atletismo"
  },
  {
     id:"X454",
     enfermedad:"Envenenamiento accidental por, y exposicion al alcohol: calles y carreteras"
  },
  {
     id:"X455",
     enfermedad:"Envenenamiento accidental por, y exposicion al alcohol: comercio y areas de servicio"
  },
  {
     id:"X456",
     enfermedad:"Envenenamiento accidental por, y exposicion al alcohol: area industrial y de la construccion"
  },
  {
     id:"X457",
     enfermedad:"Envenenamiento accidental por, y exposicion al alcohol: granja"
  },
  {
     id:"X458",
     enfermedad:"Envenenamiento accidental por, y exposicion al alcohol: otro lugar especificado"
  },
  {
     id:"X459",
     enfermedad:"Envenenamiento accidental por, y exposicion al alcohol: lugar no especificado"
  },
  {
     id:"X46",
     enfermedad:"Envenenamiento accidental por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores"
  },
  {
     id:"X460",
     enfermedad:"Envenenamiento accidental por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores: vivienda"
  },
  {
     id:"X461",
     enfermedad:"Envenenamiento accidental por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores: institucion residencial"
  },
  {
     id:"X462",
     enfermedad:"Envenenamiento accidental por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores: escuelas otras instituciones y areas administrativas publicas"
  },
  {
     id:"X463",
     enfermedad:"Envenenamiento accidental por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores: areas de deporte y atletismo"
  },
  {
     id:"X464",
     enfermedad:"Envenenamiento accidental por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores: calles y carreteras"
  },
  {
     id:"X465",
     enfermedad:"Envenenamiento accidental por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores: comercio y area de servicios"
  },
  {
     id:"X466",
     enfermedad:"Envenenamiento accidental por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores: area industrial y de la construccion"
  },
  {
     id:"X467",
     enfermedad:"Envenenamiento accidental por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores: granja"
  },
  {
     id:"X468",
     enfermedad:"Envenenamiento accidental por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores: otro lugar especificado"
  },
  {
     id:"X469",
     enfermedad:"Envenenamiento accidental por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores: lugar no especificado"
  },
  {
     id:"X47",
     enfermedad:"Envenenamiento accidental por, y exposicion a otros gases y vapores"
  },
  {
     id:"X470",
     enfermedad:"Envenenamiento accidental por, y exposicion a otros gases y vapores: vivienda"
  },
  {
     id:"X471",
     enfermedad:"Envenenamiento accidental por, y exposicion a otros gases y vapores: institucion residencial"
  },
  {
     id:"X472",
     enfermedad:"Envenenamiento accidental por, y exposicion a otros gases y vapores: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X473",
     enfermedad:"Envenenamiento accidental por, y exposicion a otros gases y vapores: areas de deporte y atletismo"
  },
  {
     id:"X474",
     enfermedad:"Envenenamiento accidental por, y exposicion a otros gases y vapores: calles y carreteras"
  },
  {
     id:"X475",
     enfermedad:"Envenenamiento accidental por, y exposicion a otros gases y vapores: comercio y area de servicios"
  },
  {
     id:"X476",
     enfermedad:"Envenenamiento accidental por, y exposicion a otros gases y vapores: area industrial y de la construccion"
  },
  {
     id:"X477",
     enfermedad:"Envenenamiento accidental por, y exposicion a otros gases y vapores: granja"
  },
  {
     id:"X478",
     enfermedad:"Envenenamiento accidental por, y exposicion a otros gases y vapores: otro lugar especificado"
  },
  {
     id:"X479",
     enfermedad:"Envenenamiento accidental por, y exposicion a otros gases y vapores: lugar no especificado"
  },
  {
     id:"X48",
     enfermedad:"Envenenamiento accidental por, y exposicion a plaguicidas"
  },
  {
     id:"X480",
     enfermedad:"Envenenamiento accidental por, y exposicion a plaguicidas: vivienda"
  },
  {
     id:"X481",
     enfermedad:"Envenenamiento accidental por, y exposicion a plaguicidas: institucion residencial"
  },
  {
     id:"X482",
     enfermedad:"Envenenamiento accidental por, y exposicion a plaguicidas: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X483",
     enfermedad:"Envenenamiento accidental por, y exposicion a plaguicidas: areas de deporte y atletismo"
  },
  {
     id:"X484",
     enfermedad:"Envenenamiento accidental por, y exposicion a plaguicidas: calles y carretera"
  },
  {
     id:"X485",
     enfermedad:"Envenenamiento accidental por, y exposicion a plaguicidas: comercio y area de servicios"
  },
  {
     id:"X486",
     enfermedad:"Envenenamiento accidental por, y exposicion a plaguicidas: area industrial y de la construccion"
  },
  {
     id:"X487",
     enfermedad:"Envenenamiento accidental por, y exposicion a plaguicidas: granja"
  },
  {
     id:"X488",
     enfermedad:"Envenenamiento accidental por, y exposicion a plaguicidas: otro lugar especificado"
  },
  {
     id:"X489",
     enfermedad:"Envenenamiento accidental por, y exposicion a plaguicidas: lugar no especificado"
  },
  {
     id:"X49",
     enfermedad:"Envenenamiento accidental por, y exposicion a otros productos quimicos y sustancias nocivas y los no especificados"
  },
  {
     id:"X490",
     enfermedad:"Envenenamiento accidental por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados: vivienda"
  },
  {
     id:"X491",
     enfermedad:"Envenenamiento accidental por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados: institucion residencial"
  },
  {
     id:"X492",
     enfermedad:"Envenenamiento accidental por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X493",
     enfermedad:"Envenenamiento accidental por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados: areas de deporte y atletismo"
  },
  {
     id:"X494",
     enfermedad:"Envenenamiento accidental por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados: calles y carreteras"
  },
  {
     id:"X495",
     enfermedad:"Envenenamiento accidental por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados: comercio y area de servicios"
  },
  {
     id:"X496",
     enfermedad:"Envenenamiento accidental por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados: area industrial y de la construccion"
  },
  {
     id:"X497",
     enfermedad:"Envenenamiento accidental por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados: granja"
  },
  {
     id:"X498",
     enfermedad:"Envenenamiento accidental por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados: otro lugar especificado"
  },
  {
     id:"X499",
     enfermedad:"Envenenamiento accidental por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados: lugar no especificado"
  },
  {
     id:"X50",
     enfermedad:"Exceso de esfuerzo y movimientos extenuantes y repetitivos"
  },
  {
     id:"X500",
     enfermedad:"Exceso de esfuerzo y movimientos extenuantes y repetitivos: vivienda"
  },
  {
     id:"X501",
     enfermedad:"Exceso de esfuerzo y movimientos extenuantes y repetitivos: institucion residencial"
  },
  {
     id:"X502",
     enfermedad:"Exceso de esfuerzo y movimientos extenuantes y repetitivos: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X503",
     enfermedad:"Exceso de esfuerzo y movimientos extenuantes y repetitivos: areas de deporte y atletismo"
  },
  {
     id:"X504",
     enfermedad:"Exceso de esfuerzo y movimientos extenuantes y repetitivos: calles y carreteras"
  },
  {
     id:"X505",
     enfermedad:"Exceso de esfuerzo y movimientos extenuantes y repetitivos: comercio y areas de servicio"
  },
  {
     id:"X506",
     enfermedad:"Exceso de esfuerzo y movimientos extenuantes y repetitivos: area industrial y de la construccion"
  },
  {
     id:"X507",
     enfermedad:"Exceso de esfuerzo y movimientos extenuantes y repetitivos: granja"
  },
  {
     id:"X508",
     enfermedad:"Exceso de esfuerzo y movimientos extenuantes y repetitivos: otro lugar especificado"
  },
  {
     id:"X509",
     enfermedad:"Exceso de esfuerzo y movimientos extenuantes y repetitivos: lugar no especificado"
  },
  {
     id:"X51",
     enfermedad:"Viajes y desplazamientos"
  },
  {
     id:"X510",
     enfermedad:"Viajes y desplazamientos: vivienda"
  },
  {
     id:"X511",
     enfermedad:"Viajes y desplazamientos: institucion residencial"
  },
  {
     id:"X512",
     enfermedad:"Viajes y desplazamientos: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X513",
     enfermedad:"Viajes y desplazamientos: areas de deporte y atletismo"
  },
  {
     id:"X514",
     enfermedad:"Viajes y desplazamientos: calles y carreteras"
  },
  {
     id:"X515",
     enfermedad:"Viajes y desplazamientos: comercio y areas de servicio"
  },
  {
     id:"X516",
     enfermedad:"Viajes y desplazamientos: area industrial y de la construccion"
  },
  {
     id:"X517",
     enfermedad:"Viajes y desplazamientos: granja"
  },
  {
     id:"X518",
     enfermedad:"Viajes y desplazamientos: otro lugar especificado"
  },
  {
     id:"X519",
     enfermedad:"Viajes y desplazamientos: lugar no especificado"
  },
  {
     id:"X52",
     enfermedad:"Permanencia prolongada en ambiente sin gravedad"
  },
  {
     id:"X520",
     enfermedad:"Permanencia prolongada en ambiente sin gravedad: vivienda"
  },
  {
     id:"X521",
     enfermedad:"Permanencia prolongada en ambiente sin gravedad: institucion residencial"
  },
  {
     id:"X522",
     enfermedad:"Permanencia prolongada en ambiente sin gravedad: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X523",
     enfermedad:"Permanencia prolongada en ambiente sin gravedad: areas de deporte y atletismo"
  },
  {
     id:"X524",
     enfermedad:"Permanencia prolongada en ambiente sin gravedad: calles y carreteras"
  },
  {
     id:"X525",
     enfermedad:"Permanencia prolongada en ambiente sin gravedad: comercio y areas de servicio"
  },
  {
     id:"X526",
     enfermedad:"Permanencia prolongada en ambiente sin gravedad: area industrial y de la construccion"
  },
  {
     id:"X527",
     enfermedad:"Permanencia prolongada en ambiente sin gravedad: granja"
  },
  {
     id:"X528",
     enfermedad:"Permanencia prolongada en ambiente sin gravedad: otro lugar especificado"
  },
  {
     id:"X529",
     enfermedad:"Permanencia prolongada en ambiente sin gravedad: lugar no especificado"
  },
  {
     id:"X53",
     enfermedad:"Privacion de alimentos"
  },
  {
     id:"X530",
     enfermedad:"Privacion de alimentos: vivienda"
  },
  {
     id:"X531",
     enfermedad:"Privacion de alimentos: institucion residencial"
  },
  {
     id:"X532",
     enfermedad:"Privacion de alimentos: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X533",
     enfermedad:"Privacion de alimentos: areas de deporte y atletismo"
  },
  {
     id:"X534",
     enfermedad:"Privacion de alimentos: calles y carreteras"
  },
  {
     id:"X535",
     enfermedad:"Privacion de alimentos: comercio y areas de servicio"
  },
  {
     id:"X536",
     enfermedad:"Privacion de alimentos: area industrial y de la construccion"
  },
  {
     id:"X537",
     enfermedad:"Privacion de alimentos: granja"
  },
  {
     id:"X538",
     enfermedad:"Privacion de alimentos: otro lugar especificado"
  },
  {
     id:"X539",
     enfermedad:"Privacion de alimentos: lugar no especificado"
  },
  {
     id:"X54",
     enfermedad:"Privacion de agua"
  },
  {
     id:"X540",
     enfermedad:"Privacion de agua: vivienda"
  },
  {
     id:"X541",
     enfermedad:"Privacion de agua: institucion residencial"
  },
  {
     id:"X542",
     enfermedad:"Privacion de agua: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X543",
     enfermedad:"Privacion de agua: areas de deporte y atletismo"
  },
  {
     id:"X544",
     enfermedad:"Privacion de agua: calles y carreteras"
  },
  {
     id:"X545",
     enfermedad:"Privacion de agua: comercio y areas de servicio"
  },
  {
     id:"X546",
     enfermedad:"Privacion de agua: area industrial y de la construccion"
  },
  {
     id:"X547",
     enfermedad:"Privacion de agua: granja"
  },
  {
     id:"X548",
     enfermedad:"Privacion de agua: otro lugar especificado"
  },
  {
     id:"X549",
     enfermedad:"Privacion de agua: lugar no especificado"
  },
  {
     id:"X57",
     enfermedad:"Privacion no especificada"
  },
  {
     id:"X570",
     enfermedad:"Privacion no especificada: vivienda"
  },
  {
     id:"X571",
     enfermedad:"Privacion no especificada: institucion residencial"
  },
  {
     id:"X572",
     enfermedad:"Privacion no especificada: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X573",
     enfermedad:"Privacion no especificada: areas de deporte y atletismo"
  },
  {
     id:"X574",
     enfermedad:"Privacion no especificada: calles y carreteras"
  },
  {
     id:"X575",
     enfermedad:"Privacion no especificada: comercio y areas de servicio"
  },
  {
     id:"X576",
     enfermedad:"Privacion no especificada: area industrial y de la construccion"
  },
  {
     id:"X577",
     enfermedad:"Privacion no especificada: granja"
  },
  {
     id:"X578",
     enfermedad:"Privacion no especificada: otro lugar especificado"
  },
  {
     id:"X579",
     enfermedad:"Privacion no especificada: lugar no especificado"
  },
  {
     id:"X58",
     enfermedad:"Exposicion a otros factores especificados"
  },
  {
     id:"X580",
     enfermedad:"Exposicion a factores especificados: vivienda"
  },
  {
     id:"X581",
     enfermedad:"Exposicion a factores especificados: institucion residencial"
  },
  {
     id:"X582",
     enfermedad:"Exposicion a factores especificados: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X583",
     enfermedad:"Exposicion a factores especificados: areas de deporte y atletismo"
  },
  {
     id:"X584",
     enfermedad:"Exposicion a factores especificados: calles y carreteras"
  },
  {
     id:"X585",
     enfermedad:"Exposicion a factores especificados: comercio y areas de servicio"
  },
  {
     id:"X586",
     enfermedad:"Exposicion a factores especificados: area industrial y de la construccion"
  },
  {
     id:"X587",
     enfermedad:"Exposicion a factores especificados: granja"
  },
  {
     id:"X588",
     enfermedad:"Exposicion a factores especificados: otro lugar especificado"
  },
  {
     id:"X589",
     enfermedad:"Exposicion a factores especificados: lugar no especificado"
  },
  {
     id:"X59",
     enfermedad:"Exposicion a factores no especificados"
  },
  {
     id:"X590",
     enfermedad:"Exposicion a factores no especificados: vivienda"
  },
  {
     id:"X591",
     enfermedad:"Exposicion a factores no especificados: institucion residencial"
  },
  {
     id:"X592",
     enfermedad:"Exposicion a factores no especificados: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X593",
     enfermedad:"Exposicion a factores no especificados: areas de deporte y atletismo"
  },
  {
     id:"X594",
     enfermedad:"Exposicion a factores no especificados: calles y carreteras"
  },
  {
     id:"X595",
     enfermedad:"Exposicion a factores no especificados: comercio y areas de servicio"
  },
  {
     id:"X596",
     enfermedad:"Exposicion a factores no especificados: area industrial y de la construccion"
  },
  {
     id:"X597",
     enfermedad:"Exposicion a factores no especificados: granja"
  },
  {
     id:"X598",
     enfermedad:"Exposicion a factores no especificados: otro lugar especificado"
  },
  {
     id:"X599",
     enfermedad:"Exposicion a factores no especificados: lugar no especificado"
  },
  {
     id:"X60",
     enfermedad:"Envenenamiento autoinflingido intencionalmente por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos"
  },
  {
     id:"X600",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos: vivienda"
  },
  {
     id:"X601",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos: institucion residencial"
  },
  {
     id:"X602",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X603",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos: areas de deporte y atletismo"
  },
  {
     id:"X604",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos: calles y carreteras"
  },
  {
     id:"X605",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos: comercio y areas de servicio"
  },
  {
     id:"X606",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos: area industrial y de la construccion"
  },
  {
     id:"X607",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos: granja"
  },
  {
     id:"X608",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos: otro lugar especificado"
  },
  {
     id:"X609",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos: lugar no especificado"
  },
  {
     id:"X61",
     enfermedad:"Envenenamiento autoinflingido intencionalmente por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparinsonianas y psicotropicas, no clasificadas en otra parte"
  },
  {
     id:"X610",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparkinsonianas y psicotropicas, no clasificadas en otra parte: vivienda"
  },
  {
     id:"X611",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparkinsonianas y psicotropicas, no clasificadas en otra parte: institucion residencial"
  },
  {
     id:"X612",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparkinsonianas y psicotropicas, no clasificadas en otra parte: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X613",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparkinsonianas y psicotropicas, no clasificadas en otra parte: areas de deporte y atletismo"
  },
  {
     id:"X614",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparkinsonianas y psicotropicas, no clasificadas en otra parte: calles y carreteras"
  },
  {
     id:"X615",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparkinsonianas y psicotropicas, no clasificadas en otra parte: comercio y areas de servicio"
  },
  {
     id:"X616",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparkinsonianas y psicotropicas, no clasificadas en otra parte: area industrial y de la construccion"
  },
  {
     id:"X617",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparkinsonianas y psicotropicas, no clasificadas en otra parte: granja"
  },
  {
     id:"X618",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparkinsonianas y psicotropicas, no clasificadas en otra parte: otro lugar especificado"
  },
  {
     id:"X619",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparkinsonianas y psicotropicas, no clasificadas en otra parte: lugar no especificado"
  },
  {
     id:"X62",
     enfermedad:"Envenenamiento autoinflingido intencionalmente por, y exposicion a narcoticos y psicodislepticos [alucinogenos] no clasificados en otra parte"
  },
  {
     id:"X620",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificados en otra parte: vivienda"
  },
  {
     id:"X621",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificados en otra parte: institucion residencial"
  },
  {
     id:"X622",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificados en otra parte: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X623",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificados en otra parte: areas de deporte y atletismo"
  },
  {
     id:"X624",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificados en otra parte: calles y carreteras"
  },
  {
     id:"X625",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificados en otra parte: comercio y areas de servicio"
  },
  {
     id:"X626",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificados en otra parte: area industrial y de la construccion"
  },
  {
     id:"X627",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificados en otra parte: granja"
  },
  {
     id:"X628",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificados en otra parte: otro lugar especificado"
  },
  {
     id:"X629",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificados en otra parte: lugar no especificado"
  },
  {
     id:"X63",
     enfermedad:"Envenenamiento autoinflingido intencionalmente por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo"
  },
  {
     id:"X630",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo: vivienda"
  },
  {
     id:"X631",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo: institucion residencial"
  },
  {
     id:"X632",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X633",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo: areas de deporte y atletismo"
  },
  {
     id:"X634",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo: calles y carreteras"
  },
  {
     id:"X635",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo: comercio y areas de servicio"
  },
  {
     id:"X636",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo: area industrial y de la construccion"
  },
  {
     id:"X637",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo: granja"
  },
  {
     id:"X638",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo: otro lugar especificado"
  },
  {
     id:"X639",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo: lugar no especificado"
  },
  {
     id:"X64",
     enfermedad:"Envenenamiento autoinflingido intencionalmente por, y exposicion a otras drogas, medicamentos y sustancias biologicas y los no especificados"
  },
  {
     id:"X640",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otras drogas medicamentos y sustancias biologicas, y los no especificados: vivienda"
  },
  {
     id:"X641",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otras drogas medicamentos y sustancias biologicas, y los no especificados: institucion residencial"
  },
  {
     id:"X642",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otras drogas medicamentos y sustancias biologicas, y los no especificados: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X643",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otras drogas medicamentos y sustancias biologicas, y los no especificados: areas de deporte y atletismo"
  },
  {
     id:"X644",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otras drogas medicamentos y sustancias biologicas, y los no especificados: calles y carreteras"
  },
  {
     id:"X645",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otras drogas medicamentos y sustancias biologicas, y los no especificados: comercio y areas de servicio"
  },
  {
     id:"X646",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otras drogas medicamentos y sustancias biologicas, y los no especificados: area industrial y de la construccion"
  },
  {
     id:"X647",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otras drogas medicamentos y sustancias biologicas, y los no especificados: granja"
  },
  {
     id:"X648",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otras drogas medicamentos y sustancias biologicas, y los no especificados: otro lugar especificado"
  },
  {
     id:"X649",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otras drogas medicamentos y sustancias biologicas, y los no especificados: lugar no especificado"
  },
  {
     id:"X65",
     enfermedad:"Envenenamiento autoinflingido intencionalm por, y exposicion a alcohol"
  },
  {
     id:"X650",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion al alcohol: vivienda"
  },
  {
     id:"X651",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion al alcohol: institucion residencial"
  },
  {
     id:"X652",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion al alcohol: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X653",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion al alcohol: areas de deporte y atletismo"
  },
  {
     id:"X654",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion al alcohol: calles y carreteras"
  },
  {
     id:"X655",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion al alcohol: comercio y areas de servicio"
  },
  {
     id:"X656",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion al alcohol: area industrial y de la construccion"
  },
  {
     id:"X657",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion al alcohol: granja"
  },
  {
     id:"X658",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion al alcohol: otro lugar especificado"
  },
  {
     id:"X659",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion al alcohol: lugar no especificado"
  },
  {
     id:"X66",
     enfermedad:"Envenenamiento autoinflingido intencionalmente por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores"
  },
  {
     id:"X660",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores: vivienda"
  },
  {
     id:"X661",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores: institucion residencial"
  },
  {
     id:"X662",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X663",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores: areas de deporte y atletismo"
  },
  {
     id:"X664",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores: calles y carreteras"
  },
  {
     id:"X665",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores: comercio y areas de servicio"
  },
  {
     id:"X666",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores: area industrial y de la construccion"
  },
  {
     id:"X667",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores: granja"
  },
  {
     id:"X668",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores: otro lugar especificado"
  },
  {
     id:"X669",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores: lugar no especificado"
  },
  {
     id:"X67",
     enfermedad:"Envenenamiento autoinflingido intencionalmente por, y exposicion a otros gases y vapores"
  },
  {
     id:"X670",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otros gases y vapores: vivienda"
  },
  {
     id:"X671",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otros gases y vapores: institucion residencial"
  },
  {
     id:"X672",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otros gases y vapores: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X673",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otros gases y vapores: areas de deporte y atletismo"
  },
  {
     id:"X674",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otros gases y vapores: calles y carreteras"
  },
  {
     id:"X675",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otros gases y vapores: comercio y areas de servicio"
  },
  {
     id:"X676",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otros gases y vapores: area industrial y de la construccion"
  },
  {
     id:"X677",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otros gases y vapores: granja"
  },
  {
     id:"X678",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otros gases y vapores: otro lugar especificado"
  },
  {
     id:"X679",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otros gases y vapores: lugar no especificado"
  },
  {
     id:"X68",
     enfermedad:"Envenenamiento autoinflingido intencionalmente por, y exposicion a plaguicidas"
  },
  {
     id:"X680",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a plaguicidas: vivienda"
  },
  {
     id:"X681",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a plaguicidas: institucion residencial"
  },
  {
     id:"X682",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a plaguicidas: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X683",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a plaguicidas: areas de deporte y atletismo"
  },
  {
     id:"X684",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a plaguicidas: calles y carreteras"
  },
  {
     id:"X685",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a plaguicidas: comercio y areas de servicio"
  },
  {
     id:"X686",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a plaguicidas: area industrial y de la construccion"
  },
  {
     id:"X687",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a plaguicidas: granja"
  },
  {
     id:"X688",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a plaguicidas: otro lugar especificado"
  },
  {
     id:"X689",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a plaguicidas: lugar no especificado"
  },
  {
     id:"X69",
     enfermedad:"Envenenamiento autoinflingido intencionalmente por, y exposicion a otros productos quimicos y sustancias nocivas y los no especificados"
  },
  {
     id:"X690",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados: vivienda"
  },
  {
     id:"X691",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados: institucion residencial"
  },
  {
     id:"X692",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X693",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados: areas de deporte y atletismo"
  },
  {
     id:"X694",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados: calles y carreteras"
  },
  {
     id:"X695",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados: comercio y areas de servicio"
  },
  {
     id:"X696",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados: area industrial y de la construccion"
  },
  {
     id:"X697",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados: granja"
  },
  {
     id:"X698",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados: otro lugar especificado"
  },
  {
     id:"X699",
     enfermedad:"Envenenamiento autoinfligido intencionalmente por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados: lugar no especificado"
  },
  {
     id:"X70",
     enfermedad:"Lesion autoinflingida intencionalmente por ahorcamiento, estrangulamiento o sofocacion"
  },
  {
     id:"X700",
     enfermedad:"Lesion autoinfligida intencionalmente por ahorcamiento, estrangulamiento o sofocacion: vivienda"
  },
  {
     id:"X701",
     enfermedad:"Lesion autoinfligida intencionalmente por ahorcamiento, estrangulamiento o sofocacion: institucion residencial"
  },
  {
     id:"X702",
     enfermedad:"Lesion autoinfligida intencionalmente por ahorcamiento, estrangulamiento o sofocacion: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X703",
     enfermedad:"Lesion autoinfligida intencionalmente por ahorcamiento, estrangulamiento o sofocacion: areas de deporte y atletismo"
  },
  {
     id:"X704",
     enfermedad:"Lesion autoinfligida intencionalmente por ahorcamiento, estrangulamiento o sofocacion: calles y carreteras"
  },
  {
     id:"X705",
     enfermedad:"Lesion autoinfligida intencionalmente por ahorcamiento, estrangulamiento o sofocacion: comercio y areas de servicio"
  },
  {
     id:"X706",
     enfermedad:"Lesion autoinfligida intencionalmente por ahorcamiento, estrangulamiento o sofocacion: area industrial y de la construccion"
  },
  {
     id:"X707",
     enfermedad:"Lesion autoinfligida intencionalmente por ahorcamiento, estrangulamiento o sofocacion: granja"
  },
  {
     id:"X708",
     enfermedad:"Lesion autoinfligida intencionalmente por ahorcamiento, estrangulamiento o sofocacion: otro lugar especificado"
  },
  {
     id:"X709",
     enfermedad:"Lesion autoinfligida intencionalmente por ahorcamiento, estrangulamiento o sofocacion: lugar no especificado"
  },
  {
     id:"X71",
     enfermedad:"Lesion autoinflingida intencionalmente por ahogamiento y sumersion"
  },
  {
     id:"X710",
     enfermedad:"Lesion autoinfligida intencionalmente por ahogamiento y sumersion: vivienda"
  },
  {
     id:"X711",
     enfermedad:"Lesion autoinfligida intencionalmente por ahogamiento y sumersion: institucion residencial"
  },
  {
     id:"X712",
     enfermedad:"Lesion autoinfligida intencionalmente por ahogamiento y sumersion: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X713",
     enfermedad:"Lesion autoinfligida intencionalmente por ahogamiento y sumersion: areas de deporte y atletismo"
  },
  {
     id:"X714",
     enfermedad:"Lesion autoinfligida intencionalmente por ahogamiento y sumersion: calles y carreteras"
  },
  {
     id:"X715",
     enfermedad:"Lesion autoinfligida intencionalmente por ahogamiento y sumersion: comercio y areas de servicio"
  },
  {
     id:"X716",
     enfermedad:"Lesion autoinfligida intencionalmente por ahogamiento y sumersion: area industrial y de la construccion"
  },
  {
     id:"X717",
     enfermedad:"Lesion autoinfligida intencionalmente por ahogamiento y sumersion: granja"
  },
  {
     id:"X718",
     enfermedad:"Lesion autoinfligida intencionalmente por ahogamiento y sumersion: otro lugar especificado"
  },
  {
     id:"X719",
     enfermedad:"Lesion autoinfligida intencionalmente por ahogamiento y sumersion: lugar no especificado"
  },
  {
     id:"X72",
     enfermedad:"Lesionion autoinflingida intencionalmente por disparo de arma corta"
  },
  {
     id:"X720",
     enfermedad:"Lesion autoinfligida intencionalmente por disparo de arma corta: vivienda"
  },
  {
     id:"X721",
     enfermedad:"Lesion autoinfligida intencionalmente por disparo de arma corta: institucion residencial"
  },
  {
     id:"X722",
     enfermedad:"Lesion autoinfligida intencionalmente por disparo de arma corta: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X723",
     enfermedad:"Lesion autoinfligida intencionalmente por disparo de arma corta: areas de deporte y atletismo"
  },
  {
     id:"X724",
     enfermedad:"Lesion autoinfligida intencionalmente por disparo de arma corta: calles y carreteras"
  },
  {
     id:"X725",
     enfermedad:"Lesion autoinfligida intencionalmente por disparo de arma corta: comercio y areas de servicio"
  },
  {
     id:"X726",
     enfermedad:"Lesion autoinfligida intencionalmente por disparo de arma corta: area industrial y de la construccion"
  },
  {
     id:"X727",
     enfermedad:"Lesion autoinfligida intencionalmente por disparo de arma corta: granja"
  },
  {
     id:"X728",
     enfermedad:"Lesion autoinfligida intencionalmente por disparo de arma corta: otro lugar especificado"
  },
  {
     id:"X729",
     enfermedad:"Lesion autoinfligida intencionalmente por disparo de arma corta: lugar no especificado"
  },
  {
     id:"X73",
     enfermedad:"Lesion autoinflingida intencionalmente por disparo de rifle, escopeta y arma larga"
  },
  {
     id:"X730",
     enfermedad:"Lesion autoinfligida intencionalmente por disparo de rifle, escopeta y arma larga: vivienda"
  },
  {
     id:"X731",
     enfermedad:"Lesion autoinfligida intencionalmente por disparo de rifle, escopeta y arma larga: institucion residencial"
  },
  {
     id:"X732",
     enfermedad:"Lesion autoinfligida intencionalmente por disparo de rifle, escopeta y arma larga: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X733",
     enfermedad:"Lesion autoinfligida intencionalmente por disparo de rifle, escopeta y arma larga: areas de deporte y atletismo"
  },
  {
     id:"X734",
     enfermedad:"Lesion autoinfligida intencionalmente por disparo de rifle, escopeta y arma larga: calles y carreteras"
  },
  {
     id:"X735",
     enfermedad:"Lesion autoinfligida intencionalmente por disparo de rifle, escopeta y arma larga: comercio y areas de servicio"
  },
  {
     id:"X736",
     enfermedad:"Lesion autoinfligida intencionalmente por disparo de rifle, escopeta y arma larga: area industrial y de la construccion"
  },
  {
     id:"X737",
     enfermedad:"Lesion autoinfligida intencionalmente por disparo de rifle, escopeta y arma larga: granja"
  },
  {
     id:"X738",
     enfermedad:"Lesion autoinfligida intencionalmente por disparo de rifle, escopeta y arma larga: otro lugar especificado"
  },
  {
     id:"X739",
     enfermedad:"Lesion autoinfligida intencionalmente por disparo de rifle, escopeta y arma larga: lugar no especificado"
  },
  {
     id:"X74",
     enfermedad:"Lesion autoinflingida intencionalmente por disparo de otras armas de fuego, y las no especificadas"
  },
  {
     id:"X740",
     enfermedad:"Lesion autoinfligida intencionalmente por disparo de otras armas de fuego, y las no especificadas: vivienda"
  },
  {
     id:"X741",
     enfermedad:"Lesion autoinfligida intencionalmente por disparo de otras armas de fuego, y las no especificadas: institucion residencial"
  },
  {
     id:"X742",
     enfermedad:"Lesion autoinfligida intencionalmente por disparo de otras armas de fuego, y las no especificadas: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X743",
     enfermedad:"Lesion autoinfligida intencionalmente por disparo de otras armas de fuego, y las no especificadas: areas de deporte y atletismo"
  },
  {
     id:"X744",
     enfermedad:"Lesion autoinfligida intencionalmente por disparo de otras armas de fuego, y las no especificadas: calles y carreteras"
  },
  {
     id:"X745",
     enfermedad:"Lesion autoinfligida intencionalmente por disparo de otras armas de fuego, y las no especificadas: comercio y areas de servicio"
  },
  {
     id:"X746",
     enfermedad:"Lesion autoinfligida intencionalmente por disparo de otras armas de fuego, y las no especificadas: area industrial y de la construccion"
  },
  {
     id:"X747",
     enfermedad:"Lesion autoinfligida intencionalmente por disparo de otras armas de fuego, y las no especificadas: granja"
  },
  {
     id:"X748",
     enfermedad:"Lesion autoinfligida intencionalmente por disparo de otras armas de fuego, y las no especificadas: otro lugar especificado"
  },
  {
     id:"X749",
     enfermedad:"Lesion autoinfligida intencionalmente por disparo de otras armas de fuego, y las no especificadas: lugar no especificado"
  },
  {
     id:"X75",
     enfermedad:"Lesion autoinflingida intencionalmente por material explosivo"
  },
  {
     id:"X750",
     enfermedad:"Lesion autoinfligida intencionalmente por material explosivo: vivienda"
  },
  {
     id:"X751",
     enfermedad:"Lesion autoinfligida intencionalmente por material explosivo: institucion residencial"
  },
  {
     id:"X752",
     enfermedad:"Lesion autoinfligida intencionalmente por material explosivo: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X753",
     enfermedad:"Lesion autoinfligida intencionalmente por material explosivo: areas de deporte y atletismo"
  },
  {
     id:"X754",
     enfermedad:"Lesion autoinfligida intencionalmente por material explosivo: calles y carreteras"
  },
  {
     id:"X755",
     enfermedad:"Lesion autoinfligida intencionalmente por material explosivo: comercio y areas de servicio"
  },
  {
     id:"X756",
     enfermedad:"Lesion autoinfligida intencionalmente por material explosivo: area industrial y de la construccion"
  },
  {
     id:"X757",
     enfermedad:"Lesion autoinfligida intencionalmente por material explosivo: granja"
  },
  {
     id:"X758",
     enfermedad:"Lesion autoinfligida intencionalmente por material explosivo: otro lugar especificado"
  },
  {
     id:"X759",
     enfermedad:"Lesion autoinfligida intencionalmente por material explosivo: lugar no especificado"
  },
  {
     id:"X76",
     enfermedad:"Lesion autoinflingida intencionalmente por humo, fuego y llamas"
  },
  {
     id:"X760",
     enfermedad:"Lesion autoinfligida intencionalmente por humo, fuego y llamas: vivienda"
  },
  {
     id:"X761",
     enfermedad:"Lesion autoinfligida intencionalmente por humo, fuego y llamas: institucion residencial"
  },
  {
     id:"X762",
     enfermedad:"Lesion autoinfligida intencionalmente por humo, fuego y llamas: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X763",
     enfermedad:"Lesion autoinfligida intencionalmente por humo, fuego y llamas: areas de deporte y atletismo"
  },
  {
     id:"X764",
     enfermedad:"Lesion autoinfligida intencionalmente por humo, fuego y llamas: calles y carreteras"
  },
  {
     id:"X765",
     enfermedad:"Lesion autoinfligida intencionalmente por humo, fuego y llamas: comercio y areas de servicio"
  },
  {
     id:"X766",
     enfermedad:"Lesion autoinfligida intencionalmente por humo, fuego y llamas: area industrial y de la construccion"
  },
  {
     id:"X767",
     enfermedad:"Lesion autoinfligida intencionalmente por humo, fuego y llamas: granja"
  },
  {
     id:"X768",
     enfermedad:"Lesion autoinfligida intencionalmente por humo, fuego y llamas: otro lugar especificado"
  },
  {
     id:"X769",
     enfermedad:"Lesion autoinfligida intencionalmente por humo, fuego y llamas: lugar no especificado"
  },
  {
     id:"X77",
     enfermedad:"Lesion autoinflingida intencionalmente por vapor de agua, vapores y objetos calientes"
  },
  {
     id:"X770",
     enfermedad:"Lesion autoinfligida intencionalmente por vapor de agua, vapores y objetos calientes: vivienda"
  },
  {
     id:"X771",
     enfermedad:"Lesion autoinfligida intencionalmente por vapor de agua, vapores y objetos calientes: institucion residencial"
  },
  {
     id:"X772",
     enfermedad:"Lesion autoinfligida intencionalmente por vapor de agua, vapores y objetos calientes: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X773",
     enfermedad:"Lesion autoinfligida intencionalmente por vapor de agua, vapores y objetos calientes: areas de deporte y atletismo"
  },
  {
     id:"X774",
     enfermedad:"Lesion autoinfligida intencionalmente por vapor de agua, vapores y objetos calientes: calles y carreteras"
  },
  {
     id:"X775",
     enfermedad:"Lesion autoinfligida intencionalmente por vapor de agua, vapores y objetos calientes: comercio y areas de servicio"
  },
  {
     id:"X776",
     enfermedad:"Lesion autoinfligida intencionalmente por vapor de agua, vapores y objetos calientes: area industrial y de la construccion"
  },
  {
     id:"X777",
     enfermedad:"Lesion autoinfligida intencionalmente por vapor de agua, vapores y objetos calientes: granja"
  },
  {
     id:"X778",
     enfermedad:"Lesion autoinfligida intencionalmente por vapor de agua, vapores y objetos calientes: otro lugar especificado"
  },
  {
     id:"X779",
     enfermedad:"Lesion autoinfligida intencionalmente por vapor de agua, vapores y objetos calientes: lugar no especificado"
  },
  {
     id:"X78",
     enfermedad:"Lesion autoinflingida intencionalmente por objeto cortante"
  },
  {
     id:"X780",
     enfermedad:"Lesion autoinfligida intencionalmente por objeto cortante: vivienda"
  },
  {
     id:"X781",
     enfermedad:"Lesion autoinfligida intencionalmente por objeto cortante: institucion residencial"
  },
  {
     id:"X782",
     enfermedad:"Lesion autoinfligida intencionalmente por objeto cortante: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X783",
     enfermedad:"Lesion autoinfligida intencionalmente por objeto cortante: areas de deporte y atletismo"
  },
  {
     id:"X784",
     enfermedad:"Lesion autoinfligida intencionalmente por objeto cortante: calles y carreteras"
  },
  {
     id:"X785",
     enfermedad:"Lesion autoinfligida intencionalmente por objeto cortante: comercio y areas de servicio"
  },
  {
     id:"X786",
     enfermedad:"Lesion autoinfligida intencionalmente por objeto cortante: area industrial y de la construccion"
  },
  {
     id:"X787",
     enfermedad:"Lesion autoinfligida intencionalmente por objeto cortante: granja"
  },
  {
     id:"X788",
     enfermedad:"Lesion autoinfligida intencionalmente por objeto cortante: otro lugar especificado"
  },
  {
     id:"X789",
     enfermedad:"Lesion autoinfligida intencionalmente por objeto cortante: lugar no especificado"
  },
  {
     id:"X79",
     enfermedad:"Lesion autoinflingida intencionalmente por objeto romo o sin filo"
  },
  {
     id:"X790",
     enfermedad:"Lesion autoinfligida intencionalmente por objeto romo o sin filo: vivienda"
  },
  {
     id:"X791",
     enfermedad:"Lesion autoinfligida intencionalmente por objeto romo o sin filo: institucion residencial"
  },
  {
     id:"X792",
     enfermedad:"Lesion autoinfligida intencionalmente por objeto romo o sin filo: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X793",
     enfermedad:"Lesion autoinfligida intencionalmente por objeto romo o sin filo: areas de deporte y atletismo"
  },
  {
     id:"X794",
     enfermedad:"Lesion autoinfligida intencionalmente por objeto romo o sin filo: calles y carreteras"
  },
  {
     id:"X795",
     enfermedad:"Lesion autoinfligida intencionalmente por objeto romo o sin filo: comercio y areas de servicio"
  },
  {
     id:"X796",
     enfermedad:"Lesion autoinfligida intencionalmente por objeto romo o sin filo: area industrial y de la construccion"
  },
  {
     id:"X797",
     enfermedad:"Lesion autoinfligida intencionalmente por objeto romo o sin filo: granja"
  },
  {
     id:"X798",
     enfermedad:"Lesion autoinfligida intencionalmente por objeto romo o sin filo: otro lugar especificado"
  },
  {
     id:"X799",
     enfermedad:"Lesion autoinfligida intencionalmente por objeto romo o sin filo: lugar no especificado"
  },
  {
     id:"X80",
     enfermedad:"Lesion autoinflingida intencionalmente al saltar desde un lugar elevado"
  },
  {
     id:"X800",
     enfermedad:"Lesion autoinfligida intencionalmente al saltar desde un lugar elevado: vivienda"
  },
  {
     id:"X801",
     enfermedad:"Lesion autoinfligida intencionalmente al saltar desde un lugar elevado: institucion residencial"
  },
  {
     id:"X802",
     enfermedad:"Lesion autoinfligida intencionalmente al saltar desde un lugar elevado: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X803",
     enfermedad:"Lesion autoinfligida intencionalmente al saltar desde un lugar elevado: areas de deporte y atletismo"
  },
  {
     id:"X804",
     enfermedad:"Lesion autoinfligida intencionalmente al saltar desde un lugar elevado: calles y carreteras"
  },
  {
     id:"X805",
     enfermedad:"Lesion autoinfligida intencionalmente al saltar desde un lugar elevado: comercio y areas de servicio"
  },
  {
     id:"X806",
     enfermedad:"Lesion autoinfligida intencionalmente al saltar desde un lugar elevado: area industrial y de la construccion"
  },
  {
     id:"X807",
     enfermedad:"Lesion autoinfligida intencionalmente al saltar desde un lugar elevado: granja"
  },
  {
     id:"X808",
     enfermedad:"Lesion autoinfligida intencionalmente al saltar desde un lugar elevado: otro lugar especificado"
  },
  {
     id:"X809",
     enfermedad:"Lesion autoinfligida intencionalmente al saltar desde un lugar elevado: lugar no especificado"
  },
  {
     id:"X81",
     enfermedad:"Lesion autoinflingida intencionalmente por arrojarse o colocarse delante de objeto en movimiento"
  },
  {
     id:"X810",
     enfermedad:"Lesion autoinfligida intencionalmente por arrojarse o colocarse delante de objeto en movimiento: vivienda"
  },
  {
     id:"X811",
     enfermedad:"Lesion autoinfligida intencionalmente por arrojarse o colocarse delante de objeto en movimiento: institucion residencial"
  },
  {
     id:"X812",
     enfermedad:"Lesion autoinfligida intencionalmente por arrojarse o colocarse delante de objeto en movimiento: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X813",
     enfermedad:"Lesion autoinfligida intencionalmente por arrojarse o colocarse delante de objeto en movimiento: areas de deporte y atletismo"
  },
  {
     id:"X814",
     enfermedad:"Lesion autoinfligida intencionalmente por arrojarse o colocarse delante de objeto en movimiento: calles y carreteras"
  },
  {
     id:"X815",
     enfermedad:"Lesion autoinfligida intencionalmente por arrojarse o colocarse delante de objeto en movimiento: comercio y areas de servicio"
  },
  {
     id:"X816",
     enfermedad:"Lesion autoinfligida intencionalmente por arrojarse o colocarse delante de objeto en movimiento: area industrial y de la construccion"
  },
  {
     id:"X817",
     enfermedad:"Lesion autoinfligida intencionalmente por arrojarse o colocarse delante de objeto en movimiento: granja"
  },
  {
     id:"X818",
     enfermedad:"Lesion autoinfligida intencionalmente por arrojarse o colocarse delante de objeto en movimiento: otro lugar especificado"
  },
  {
     id:"X819",
     enfermedad:"Lesion autoinfligida intencionalmente por arrojarse o colocarse delante de objeto en movimiento: lugar no especificado"
  },
  {
     id:"X82",
     enfermedad:"Lesion autoinflingida intencionalmente por colision de vehiculo de motor"
  },
  {
     id:"X820",
     enfermedad:"Lesion autoinfligida intencionalmente por colision de vehiculo de motor: vivienda"
  },
  {
     id:"X821",
     enfermedad:"Lesion autoinfligida intencionalmente por colision de vehiculo de motor: institucion residencial"
  },
  {
     id:"X822",
     enfermedad:"Lesion autoinfligida intencionalmente por colision de vehiculo de motor: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X823",
     enfermedad:"Lesion autoinfligida intencionalmente por colision de vehiculo de motor: areas de deporte y atletismo"
  },
  {
     id:"X824",
     enfermedad:"Lesion autoinfligida intencionalmente por colision de vehiculo de motor: calles y carreteras"
  },
  {
     id:"X825",
     enfermedad:"Lesion autoinfligida intencionalmente por colision de vehiculo de motor: comercio y areas de servicio"
  },
  {
     id:"X826",
     enfermedad:"Lesion autoinfligida intencionalmente por colision de vehiculo de motor: area industrial y de la construccion"
  },
  {
     id:"X827",
     enfermedad:"Lesion autoinfligida intencionalmente por colision de vehiculo de motor: granja"
  },
  {
     id:"X828",
     enfermedad:"Lesion autoinfligida intencionalmente por colision de vehiculo de motor: otro lugar especificado"
  },
  {
     id:"X829",
     enfermedad:"Lesion autoinfligida intencionalmente por colision de vehiculo de motor: lugar no especificado"
  },
  {
     id:"X83",
     enfermedad:"Lesion autoinflingida intencionalmente por otros medios especificados"
  },
  {
     id:"X830",
     enfermedad:"Lesion autoinfligida intencionalmente por otros medios especificados: vivienda"
  },
  {
     id:"X831",
     enfermedad:"Lesion autoinfligida intencionalmente por otros medios especificados: institucion residencial"
  },
  {
     id:"X832",
     enfermedad:"Lesion autoinfligida intencionalmente por otros medios especificados: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X833",
     enfermedad:"Lesion autoinfligida intencionalmente por otros medios especificados: areas de deporte y atletismo"
  },
  {
     id:"X834",
     enfermedad:"Lesion autoinfligida intencionalmente por otros medios especificados: calles y carreteras"
  },
  {
     id:"X835",
     enfermedad:"Lesion autoinfligida intencionalmente por otros medios especificados: comercio y areas de servicio"
  },
  {
     id:"X836",
     enfermedad:"Lesion autoinfligida intencionalmente por otros medios especificados: area industrial y de la construccion"
  },
  {
     id:"X837",
     enfermedad:"Lesion autoinfligida intencionalmente por otros medios especificados: granja"
  },
  {
     id:"X838",
     enfermedad:"Lesion autoinfligida intencionalmente por otros medios especificados: otro lugar especificado"
  },
  {
     id:"X839",
     enfermedad:"Lesion autoinfligida intencionalmente por otros medios especificados: lugar no especificado"
  },
  {
     id:"X84",
     enfermedad:"Lesion autoinflingida intencionalmente por medios no especificados"
  },
  {
     id:"X840",
     enfermedad:"Lesion autoinfligida intencionalmente por medios no especificados: vivienda"
  },
  {
     id:"X841",
     enfermedad:"Lesion autoinfligida intencionalmente por medios no especificados: institucion residencial"
  },
  {
     id:"X842",
     enfermedad:"Lesion autoinfligida intencionalmente por medios no especificados: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X843",
     enfermedad:"Lesion autoinfligida intencionalmente por medios no especificados: areas de deporte y atletismo"
  },
  {
     id:"X844",
     enfermedad:"Lesion autoinfligida intencionalmente por medios no especificados: calles y carreteras"
  },
  {
     id:"X845",
     enfermedad:"Lesion autoinfligida intencionalmente por medios no especificados: comercio y areas de servicio"
  },
  {
     id:"X846",
     enfermedad:"Lesion autoinfligida intencionalmente por medios no especificados: area industrial y de la construccion"
  },
  {
     id:"X847",
     enfermedad:"Lesion autoinfligida intencionalmente por medios no especificados: granja"
  },
  {
     id:"X848",
     enfermedad:"Lesion autoinfligida intencionalmente por medios no especificados: otro lugar especificado"
  },
  {
     id:"X849",
     enfermedad:"Lesion autoinfligida intencionalmente por medios no especificados: lugar no especificado"
  },
  {
     id:"X85",
     enfermedad:"Agresion con drogas, medicamentos y sustancias biologicas"
  },
  {
     id:"X850",
     enfermedad:"Agresion con drogas, medicamentos y sustancias biologicas: vivienda"
  },
  {
     id:"X851",
     enfermedad:"Agresion con drogas, medicamentos y sustancias biologicas: institucion residencial"
  },
  {
     id:"X852",
     enfermedad:"Agresion con drogas, medicamentos y sustancias biologicas: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X853",
     enfermedad:"Agresion con drogas, medicamentos y sustancias biologicas: areas de deporte y atletismo"
  },
  {
     id:"X854",
     enfermedad:"Agresion con drogas, medicamentos y sustancias biologicas: calles y carreteras"
  },
  {
     id:"X855",
     enfermedad:"Agresion con drogas, medicamentos y sustancias biologicas: comercio y areas de servicio"
  },
  {
     id:"X856",
     enfermedad:"Agresion con drogas, medicamentos y sustancias biologicas: area industrial y de la construccion"
  },
  {
     id:"X857",
     enfermedad:"Agresion con drogas, medicamentos y sustancias biologicas: granja"
  },
  {
     id:"X858",
     enfermedad:"Agresion con drogas, medicamentos y sustancias biologicas: otro lugar especificado"
  },
  {
     id:"X859",
     enfermedad:"Agresion con drogas, medicamentos y sustancias biologicas: lugar no especificado"
  },
  {
     id:"X86",
     enfermedad:"Agresion con sustancia corrosiva"
  },
  {
     id:"X860",
     enfermedad:"Agresion con sustancia corrosiva: vivienda"
  },
  {
     id:"X861",
     enfermedad:"Agresion con sustanciacorrosiva: institucion residencial"
  },
  {
     id:"X862",
     enfermedad:"Agresion con sustancia corrosiva: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X863",
     enfermedad:"Agresion con sustanciacorrosiva: areas de deporte y atletismo"
  },
  {
     id:"X864",
     enfermedad:"Agresion con sustancia corrosiva: calles y carreteras"
  },
  {
     id:"X865",
     enfermedad:"Agresion con sustancia corrosiva: comercio y areas de servicio"
  },
  {
     id:"X866",
     enfermedad:"Agresion con sustancia corrosiva: area industrial y de la construccion"
  },
  {
     id:"X867",
     enfermedad:"Agresion con sustancia corrosiva: granja"
  },
  {
     id:"X868",
     enfermedad:"Agresion con sustanciacorrosiva: otro lugar especificado"
  },
  {
     id:"X869",
     enfermedad:"Agresion con sustancia corrosiva: lugar no especificado"
  },
  {
     id:"X87",
     enfermedad:"Agresion con plaguicidas"
  },
  {
     id:"X870",
     enfermedad:"Agresion con plaguicidas: vivienda"
  },
  {
     id:"X871",
     enfermedad:"Agresion con plaguicidas: institucion residencial"
  },
  {
     id:"X872",
     enfermedad:"Agresion con plaguicidas: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X873",
     enfermedad:"Agresion con plaguicidas: areas de deporte y atletismo"
  },
  {
     id:"X874",
     enfermedad:"Agresion con plaguicidas: calles y carreteras"
  },
  {
     id:"X875",
     enfermedad:"Agresion con plaguicidas: comercio y areas de servicio"
  },
  {
     id:"X876",
     enfermedad:"Agresion con plaguicidas: area industrial y de la construccion"
  },
  {
     id:"X877",
     enfermedad:"Agresion con plaguicidas: granja"
  },
  {
     id:"X878",
     enfermedad:"Agresion con plaguicidas: otro lugar especificado"
  },
  {
     id:"X879",
     enfermedad:"Agresion con plaguicidas: lugar no especificado"
  },
  {
     id:"X88",
     enfermedad:"Agresion con gases y vapores"
  },
  {
     id:"X880",
     enfermedad:"Agresion con gases y vapores: vivienda"
  },
  {
     id:"X881",
     enfermedad:"Agresion con gases y vapores: institucion residencial"
  },
  {
     id:"X882",
     enfermedad:"Agresion con gases y vapores: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X883",
     enfermedad:"Agresion con gases y vapores: areas de deporte y atletismo"
  },
  {
     id:"X884",
     enfermedad:"Agresion con gases y vapores: calles y carreteras"
  },
  {
     id:"X885",
     enfermedad:"Agresion con gases y vapores: comercio y areas de servicio"
  },
  {
     id:"X886",
     enfermedad:"Agresion con gases y vapores: area industrial y de la construccion"
  },
  {
     id:"X887",
     enfermedad:"Agresion con gases y vapores: granja"
  },
  {
     id:"X888",
     enfermedad:"Agresion con gases y vapores: otro lugar especificado"
  },
  {
     id:"X889",
     enfermedad:"Agresion con gases y vapores: lugar no especificado"
  },
  {
     id:"X89",
     enfermedad:"Agresion con otros productos quimicos y sustancias nocivas especificadas"
  },
  {
     id:"X890",
     enfermedad:"Agresion con otros productos quimicos y sustancias nocivas especificadas: vivienda"
  },
  {
     id:"X891",
     enfermedad:"Agresion con otros productos quimicos y sustancias nocivas especificadas: institucion residencial"
  },
  {
     id:"X892",
     enfermedad:"Agresion con otros productos quimicos y sustancias nocivas especificadas: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X893",
     enfermedad:"Agresion con otros productos quimicos y sustancias nocivas especificadas: areas de deporte y atletismo"
  },
  {
     id:"X894",
     enfermedad:"Agresion con otros productos quimicos y sustancias nocivas especificadas: calles y carreteras"
  },
  {
     id:"X895",
     enfermedad:"Agresion con otros productos quimicos y sustancias nocivas especificadas: comercio y areas de servicio"
  },
  {
     id:"X896",
     enfermedad:"Agresion con otros productos quimicos y sustancias nocivas especificadas: area industrial y de la construccion"
  },
  {
     id:"X897",
     enfermedad:"Agresion con otros productos quimicos y sustancias nocivas especificadas: granja"
  },
  {
     id:"X898",
     enfermedad:"Agresion con otros productos quimicos y sustancias nocivas especificadas: otro lugar especificado"
  },
  {
     id:"X899",
     enfermedad:"Agresion con otros productos quimicos y sustancias nocivas especificadas: lugar no especificado"
  },
  {
     id:"X90",
     enfermedad:"Agresion con productos quimicos y sustancias nocivas no especificados"
  },
  {
     id:"X900",
     enfermedad:"Agresion con productos quimicos y sustancias nocivas no especificadas: vivienda"
  },
  {
     id:"X901",
     enfermedad:"Agresion con productos quimicos y sustancias nocivas no especificadas: institucion residencial"
  },
  {
     id:"X902",
     enfermedad:"Agresion con productos quimicos y sustancias nocivas no especificadas: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X903",
     enfermedad:"Agresion con productos quimicos y sustancias nocivas no especificadas: areas de deporte y atletismo"
  },
  {
     id:"X904",
     enfermedad:"Agresion con productos quimicos y sustancias nocivas no especificadas: calles y carreteras"
  },
  {
     id:"X905",
     enfermedad:"Agresion con productos quimicos y sustancias nocivas no especificadas: comercio y areas de servicio"
  },
  {
     id:"X906",
     enfermedad:"Agresion con productos quimicos y sustancias nocivas no especificadas: area industrial y de la construccion"
  },
  {
     id:"X907",
     enfermedad:"Agresion con productos quimicos y sustancias nocivas no especificadas: granja"
  },
  {
     id:"X908",
     enfermedad:"Agresion con productos quimicos y sustancias nocivas no especificadas: otro lugar especificado"
  },
  {
     id:"X909",
     enfermedad:"Agresion con productos quimicos y sustancias nocivas no especificadas: lugar no especificado"
  },
  {
     id:"X91",
     enfermedad:"Agresion por ahorcamiento, estrangulamiento y sofocacion"
  },
  {
     id:"X910",
     enfermedad:"Agresion por ahorcamiento, estrangulamiento y sofocacion: vivienda"
  },
  {
     id:"X911",
     enfermedad:"Agresion por ahorcamiento, estrangulamiento y sofocacion: institucion residencial"
  },
  {
     id:"X912",
     enfermedad:"Agresion por ahorcamiento, estrangulamiento y sofocacion: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X913",
     enfermedad:"Agresion por ahorcamiento, estrangulamiento y sofocacion: areas de deporte y atletismo"
  },
  {
     id:"X914",
     enfermedad:"Agresion por ahorcamiento, estrangulamiento y sofocacion: calles y carreteras"
  },
  {
     id:"X915",
     enfermedad:"Agresion por ahorcamiento, estrangulamiento y sofocacion: comercio y areas de servicio"
  },
  {
     id:"X916",
     enfermedad:"Agresion por ahorcamiento, estrangulamiento y sofocacion: area industrial y de la construccion"
  },
  {
     id:"X917",
     enfermedad:"Agresion por ahorcamiento, estrangulamiento y sofocacion: granja"
  },
  {
     id:"X918",
     enfermedad:"Agresion por ahorcamiento, estrangulamiento y sofocacion: otro lugar especificado"
  },
  {
     id:"X919",
     enfermedad:"Agresion por ahorcamiento, estrangulamiento y sofocacion: lugar no especificado"
  },
  {
     id:"X92",
     enfermedad:"Agresion por ahogamiento y sumersion"
  },
  {
     id:"X920",
     enfermedad:"Agresion por ahogamiento y sumersion: vivienda"
  },
  {
     id:"X921",
     enfermedad:"Agresion por ahogamiento y sumersion: institucion residencial"
  },
  {
     id:"X922",
     enfermedad:"Agresion por ahogamiento y sumersion: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X923",
     enfermedad:"Agresion por ahogamiento y sumersion: areas de deporte y atletismo"
  },
  {
     id:"X924",
     enfermedad:"Agresion por ahogamiento y sumersion: calles y carreteras"
  },
  {
     id:"X925",
     enfermedad:"Agresion por ahogamiento y sumersion: comercio y areas de servicio"
  },
  {
     id:"X926",
     enfermedad:"Agresion por ahogamiento y sumersion: area industrial y de la construccion"
  },
  {
     id:"X927",
     enfermedad:"Agresion por ahogamiento y sumersion: granja"
  },
  {
     id:"X928",
     enfermedad:"Agresion por ahogamiento y sumersion: otro lugar especificado"
  },
  {
     id:"X929",
     enfermedad:"Agresion por ahogamiento y sumersion: lugar no especificado"
  },
  {
     id:"X93",
     enfermedad:"Agresion con disparo de arma corta"
  },
  {
     id:"X930",
     enfermedad:"Agresion con disparo de arma corta: vivienda"
  },
  {
     id:"X931",
     enfermedad:"Agresion con disparo de arma corta: institucion residencial"
  },
  {
     id:"X932",
     enfermedad:"Agresion con disparo de arma corta: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X933",
     enfermedad:"Agresion con disparo de arma corta: areas de deporte y atletismo"
  },
  {
     id:"X934",
     enfermedad:"Agresion con disparo de arma corta: calles y carreteras"
  },
  {
     id:"X935",
     enfermedad:"Agresion con disparo de arma corta: comercio y areas de servicio"
  },
  {
     id:"X936",
     enfermedad:"Agresion con disparo de arma corta: area industrial y de la construccion"
  },
  {
     id:"X937",
     enfermedad:"Agresion con disparo de arma corta: granja"
  },
  {
     id:"X938",
     enfermedad:"Agresion con disparo de arma corta: otro lugar especificado"
  },
  {
     id:"X939",
     enfermedad:"Agresion con disparo de arma corta: lugar no especificado"
  },
  {
     id:"X94",
     enfermedad:"Agresion con disparo de rifle, escopeta y arma larga"
  },
  {
     id:"X940",
     enfermedad:"Agresion con disparo de rifle, escopeta y arma larga: vivienda"
  },
  {
     id:"X941",
     enfermedad:"Agresion con disparo de rifle, escopeta y arma larga: institucion residencial"
  },
  {
     id:"X942",
     enfermedad:"Agresion con disparo de rifle, escopeta y arma larga: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X943",
     enfermedad:"Agresion con disparo de rifle, escopeta y arma larga: areas de deporte y atletismo"
  },
  {
     id:"X944",
     enfermedad:"Agresion con disparo de rifle, escopeta y arma larga: calles y carreteras"
  },
  {
     id:"X945",
     enfermedad:"Agresion con disparo de rifle, escopeta y arma larga: comercio y areas de servicio"
  },
  {
     id:"X946",
     enfermedad:"Agresion con disparo de rifle, escopeta y arma larga: area industrial y de la construccion"
  },
  {
     id:"X947",
     enfermedad:"Agresion con disparo de rifle, escopeta y arma larga: granja"
  },
  {
     id:"X948",
     enfermedad:"Agresion con disparo de rifle, escopeta y arma larga: otro lugar especificado"
  },
  {
     id:"X949",
     enfermedad:"Agresion con disparo de rifle, escopeta y arma larga: lugar no especificado"
  },
  {
     id:"X95",
     enfermedad:"Agresion con disparo de otras armas fuego y las no especificadas"
  },
  {
     id:"X950",
     enfermedad:"Agresion con disparo de otras armas de fuego, y las no especificadas: vivienda"
  },
  {
     id:"X951",
     enfermedad:"Agresion con disparo de otras armas de fuego, y las no especificadas: institucion residencial"
  },
  {
     id:"X952",
     enfermedad:"Agresion con disparo de otras armas de fuego, y las no especificadas: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X953",
     enfermedad:"Agresion con disparo de otras armas de fuego, y las no especificadas: areas de deporte y atletismo"
  },
  {
     id:"X954",
     enfermedad:"Agresion con disparo de otras armas de fuego, y las no especificadas: calles y carreteras"
  },
  {
     id:"X955",
     enfermedad:"Agresion con disparo de otras armas de fuego, y las no especificadas: comercio y areas de servicio"
  },
  {
     id:"X956",
     enfermedad:"Agresion con disparo de otras armas de fuego, y las no especificadas: area industrial y de la construccion"
  },
  {
     id:"X957",
     enfermedad:"Agresion con disparo de otras armas de fuego, y las no especificadas: granja"
  },
  {
     id:"X958",
     enfermedad:"Agresion con disparo de otras armas de fuego, y las no especificadas: otro lugar especificado"
  },
  {
     id:"X959",
     enfermedad:"Agresion con disparo de otras armas de fuego, y las no especificadas: lugar no especificado"
  },
  {
     id:"X96",
     enfermedad:"Agresion con material explosivo"
  },
  {
     id:"X960",
     enfermedad:"Agresion con material explosivo: vivienda"
  },
  {
     id:"X961",
     enfermedad:"Agresion con material explosivo: institucion residencial"
  },
  {
     id:"X962",
     enfermedad:"Agresion con material explosivo: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X963",
     enfermedad:"Agresion con material explosivo: areas de deporte y atletismo"
  },
  {
     id:"X964",
     enfermedad:"Agresion con material explosivo: calles y carreteras"
  },
  {
     id:"X965",
     enfermedad:"Agresion con material explosivo: comercio y areas de servicio"
  },
  {
     id:"X966",
     enfermedad:"Agresion con material explosivo: area industrial y de la construccion"
  },
  {
     id:"X967",
     enfermedad:"Agresion con material explosivo: granja"
  },
  {
     id:"X968",
     enfermedad:"Agresion con material explosivo: otro lugar especificado"
  },
  {
     id:"X969",
     enfermedad:"Agresion con material explosivo: lugar no especificado"
  },
  {
     id:"X97",
     enfermedad:"Agresion con humo, fuego y llamas"
  },
  {
     id:"X970",
     enfermedad:"Agresion con humo, fuego y llamas: vivienda"
  },
  {
     id:"X971",
     enfermedad:"Agresion con humo, fuego y llamas: institucion residencial"
  },
  {
     id:"X972",
     enfermedad:"Agresion con humo, fuego y llamas: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X973",
     enfermedad:"Agresion con humo, fuego y llamas: areas de deporte y atletismo"
  },
  {
     id:"X974",
     enfermedad:"Agresion con humo, fuego y llamas: calles y carreteras"
  },
  {
     id:"X975",
     enfermedad:"Agresion con humo, fuego y llamas: comercio y areas de servicio"
  },
  {
     id:"X976",
     enfermedad:"Agresion con humo, fuego y llamas: area industrial y de la construccion"
  },
  {
     id:"X977",
     enfermedad:"Agresion con humo, fuego y llamas: granja"
  },
  {
     id:"X978",
     enfermedad:"Agresion con humo, fuego y llamas: otro lugar especificado"
  },
  {
     id:"X979",
     enfermedad:"Agresion con humo, fuego y llamas: lugar no especificado"
  },
  {
     id:"X98",
     enfermedad:"Agresion con vapor y objetos calientes"
  },
  {
     id:"X980",
     enfermedad:"Agresion con vapor de agua, vapores y objetos calientes: vivienda"
  },
  {
     id:"X981",
     enfermedad:"Agresion con vapor de agua, vapores y objetos calientes: institucion residencial"
  },
  {
     id:"X982",
     enfermedad:"Agresion con vapor de agua, vapores y objetos calientes: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X983",
     enfermedad:"Agresion con vapor de agua, vapores y objetos calientes: areas de deporte y atletismo"
  },
  {
     id:"X984",
     enfermedad:"Agresion con vapor de agua, vapores y objetos calientes: calles y carreteras"
  },
  {
     id:"X985",
     enfermedad:"Agresion con vapor de agua, vapores y objetos calientes: comercio y areas de servicio"
  },
  {
     id:"X986",
     enfermedad:"Agresion con vapor de agua, vapores y objetos calientes: area industrial y de la construccion"
  },
  {
     id:"X987",
     enfermedad:"Agresion con vapor de agua, vapores y objetos calientes: granja"
  },
  {
     id:"X988",
     enfermedad:"Agresion con vapor de agua, vapores y objetos calientes: otro lugar especificado"
  },
  {
     id:"X989",
     enfermedad:"Agresion con vapor de agua, vapores y objetos calientes: lugar no especificado"
  },
  {
     id:"X99",
     enfermedad:"Agresion con objeto cortante"
  },
  {
     id:"X990",
     enfermedad:"Agresion con objeto cortante: vivienda"
  },
  {
     id:"X991",
     enfermedad:"Agresion con objeto cortante: institucion residencial"
  },
  {
     id:"X992",
     enfermedad:"Agresion con objeto cortante: escuelas, otras instituciones y areas administrativas publicas"
  },
  {
     id:"X993",
     enfermedad:"Agresion con objeto cortante: areas de deporte y atletismo"
  },
  {
     id:"X994",
     enfermedad:"Agresion con objeto cortante: calles y carreteras"
  },
  {
     id:"X995",
     enfermedad:"Agresion con objeto cortante: comercio y areas de servicio"
  },
  {
     id:"X996",
     enfermedad:"Agresion con objeto cortante: area industrial y de la construccion"
  },
  {
     id:"X997",
     enfermedad:"Agresion con objeto cortante: granja"
  },
  {
     id:"X998",
     enfermedad:"Agresion con objeto cortante: otro lugar especificado"
  },
  {
     id:"X999",
     enfermedad:"Agresion con objeto cortante: lugar no especificado"
  },
  {
     id:"XI",
     enfermedad:"Enfermedades del aparato digestivo"
  },
  {
     id:"XII",
     enfermedad:"Enfermedades de la piel y el tejido subcutaneo"
  },
  {
     id:"XIII",
     enfermedad:"Enfermedades del sistema osteomuscular y del tejido conectivo"
  },
  {
     id:"XIV",
     enfermedad:"Enfermedades del aparato genitourinario"
  },
  {
     id:"XIX",
     enfermedad:"Traumatismos, envenenamientos y algunas otras consecuencias de causa externa"
  },
  {
     id:"XV",
     enfermedad:"Embarazo, parto y puerperio"
  },
  {
     id:"XVI",
     enfermedad:"Ciertas afecciones originadas en el periodo perinatal"
  },
  {
     id:"XVII",
     enfermedad:"Malformaciones congenitas, deformidades y anomalias cromosomicas"
  },
  {
     id:"XVIII",
     enfermedad:"Sintomas, signos y hallazgos anormales clinicos y de laboratorio, no clasificados en otra parte"
  },
  {
     id:"XX",
     enfermedad:"Causas extremas de morbilidad y de mortalidad"
  },
  {
     id:"XXI",
     enfermedad:"Factores que influyen en el estado de salud y contacto con los servicios de salud"
  },
  {
     id:"XXII",
     enfermedad:"Codigos para situaciones especiales"
  },
  {
     id:"Y00",
     enfermedad:"Agresion con objeto romo o sin filo"
  },
  {
     id:"Y000",
     enfermedad:"Agresion con objeto romo o sin filo: vivienda"
  },
  {
     id:"Y001",
     enfermedad:"Agresion con objeto romo o sin filo: institucion residencial"
  },
  {
     id:"Y002",
     enfermedad:"Agresion con objeto romo o sin filo: escuelas otras instituciones y areas administrativas"
  },
  {
     id:"Y003",
     enfermedad:"Agresion con objeto romo o sin filo: areas de deporte y atletismo"
  },
  {
     id:"Y004",
     enfermedad:"Agresion con objeto romo o sin filo: calles y carreteras"
  },
  {
     id:"Y005",
     enfermedad:"Agresion con objeto romo o sin filo: comercio y area de servicios"
  },
  {
     id:"Y006",
     enfermedad:"Agresion con objeto romo o sin filo: area industrial y de la construccion"
  },
  {
     id:"Y007",
     enfermedad:"Agresion con objeto romo o sin filo: granja"
  },
  {
     id:"Y008",
     enfermedad:"Agresion con objeto romo o sin filo: otro lugar especificado"
  },
  {
     id:"Y009",
     enfermedad:"Agresion con objeto romo o sin filo: lugar no especificado"
  },
  {
     id:"Y01",
     enfermedad:"Agresion por empujon desde lugar elevado"
  },
  {
     id:"Y010",
     enfermedad:"Agresion por empujon desde un lugar elevado: vivienda"
  },
  {
     id:"Y011",
     enfermedad:"Agresion por empujon desde un lugar elevado: institucion residencial"
  },
  {
     id:"Y012",
     enfermedad:"Agresion por empujon desde un lugar elevado: escuelas otras instituciones y areas administrativas"
  },
  {
     id:"Y013",
     enfermedad:"Agresion por empujon desde un lugar elevado: areas de deporte y atletismo"
  },
  {
     id:"Y014",
     enfermedad:"Agresion por empujon desde un lugar elevado: calles y carreteras"
  },
  {
     id:"Y015",
     enfermedad:"Agresion por empujon desde un lugar elevado: comercio y area de servicios"
  },
  {
     id:"Y016",
     enfermedad:"Agresion por empujon desde un lugar elevado: area industrial y de la construccion"
  },
  {
     id:"Y017",
     enfermedad:"Agresion por empujon desde un lugar elevado: granja"
  },
  {
     id:"Y018",
     enfermedad:"Agresion por empujon desde un lugar elevado: otro lugar especificado"
  },
  {
     id:"Y019",
     enfermedad:"Agresion por empujon desde un lugar elevado: lugar no especificado"
  },
  {
     id:"Y02",
     enfermedad:"Agresion empuj o coloc delant objeto movimie"
  },
  {
     id:"Y020",
     enfermedad:"Agresion por empujar o colocar a la victima delante de objeto en movimiento: vivienda"
  },
  {
     id:"Y021",
     enfermedad:"Agresion por empujar o colocar a la victima delante de objeto en movimiento: institucion residencial"
  },
  {
     id:"Y022",
     enfermedad:"Agresion por empujar o colocar a la victima delante de objeto en movimiento: escuelas otras instituciones y areas administrativas"
  },
  {
     id:"Y023",
     enfermedad:"Agresion por empujar o colocar a la victima delante de objeto en movimiento: areas de deporte y atletismo"
  },
  {
     id:"Y024",
     enfermedad:"Agresion por empujar o colocar a la victima delante de objeto en movimiento: calles y carreteras"
  },
  {
     id:"Y025",
     enfermedad:"Agresion por empujar o colocar a la victima delante de objeto en movimiento: comercio y area de servicios"
  },
  {
     id:"Y026",
     enfermedad:"Agresion por empujar o colocar a la victima delante de objeto en movimiento: area industrial y de la construccion"
  },
  {
     id:"Y027",
     enfermedad:"Agresion por empujar o colocar a la victima delante de objeto en movimiento: granja"
  },
  {
     id:"Y028",
     enfermedad:"Agresion por empujar o colocar a la victima delante de objeto en movimiento: otro lugar especificado"
  },
  {
     id:"Y029",
     enfermedad:"Agresion por empujar o colocar a la victima delante de objeto en movimiento: lugar no especificado"
  },
  {
     id:"Y03",
     enfermedad:"Agresion por colision vehiculo de motor"
  },
  {
     id:"Y030",
     enfermedad:"Agresion por colision de vehiculo de motor: vivienda"
  },
  {
     id:"Y031",
     enfermedad:"Agresion por colision de vehiculo de motor: institucion residencial"
  },
  {
     id:"Y032",
     enfermedad:"Agresion por colision de vehiculo de motor: escuelas, otras instituciones y areas administrativas"
  },
  {
     id:"Y033",
     enfermedad:"Agresion por colision de vehiculo de motor: areas de deporte y atletismo"
  },
  {
     id:"Y034",
     enfermedad:"Agresion por colision de vehiculo de motor: calles y carreteras"
  },
  {
     id:"Y035",
     enfermedad:"Agresion por colision de vehiculo de motor: comercio y area de servicios"
  },
  {
     id:"Y036",
     enfermedad:"Agresion por colision de vehiculo de motor: area industrial y de la construccion"
  },
  {
     id:"Y037",
     enfermedad:"Agresion por colision de vehiculo de motor: granja"
  },
  {
     id:"Y038",
     enfermedad:"Agresion por colision de vehiculo de motor: otro lugar especificado"
  },
  {
     id:"Y039",
     enfermedad:"Agresion por colision de vehiculo de motor: lugar no especificado"
  },
  {
     id:"Y04",
     enfermedad:"Agresion con fuerza corporal"
  },
  {
     id:"Y040",
     enfermedad:"Agresion con fuerza corporal: vivienda"
  },
  {
     id:"Y041",
     enfermedad:"Agresion con fuerza corporal: institucion residencial"
  },
  {
     id:"Y042",
     enfermedad:"Agresion con fuerza corporal: escuelas, otras instituciones y areas administrativas"
  },
  {
     id:"Y043",
     enfermedad:"Agresion con fuerza corporal: areas de deporte y atletismo"
  },
  {
     id:"Y044",
     enfermedad:"Agresion con fuerza corporal: calles y carreteras"
  },
  {
     id:"Y045",
     enfermedad:"Agresion con fuerza corporal: comercio y area de servicios"
  },
  {
     id:"Y046",
     enfermedad:"Agresion con fuerza corporal: area industrial y de la construccion"
  },
  {
     id:"Y047",
     enfermedad:"Agresion con fuerza corporal: granja"
  },
  {
     id:"Y048",
     enfermedad:"Agresion con fuerza corporal: otro lugar especificado"
  },
  {
     id:"Y049",
     enfermedad:"Agresion con fuerza corporal: lugar no especificado"
  },
  {
     id:"Y05",
     enfermedad:"Agresion sexual con fuerza corporal"
  },
  {
     id:"Y050",
     enfermedad:"Agresion sexual con fuerza corporal: vivienda"
  },
  {
     id:"Y051",
     enfermedad:"Agresion sexual con fuerza corporal: institucion residencial"
  },
  {
     id:"Y052",
     enfermedad:"Agresion sexual con fuerza corporal: escuelas, otras instituciones y areas administrativas"
  },
  {
     id:"Y053",
     enfermedad:"Agresion sexual con fuerza corporal: areas de deporte y atletismo"
  },
  {
     id:"Y054",
     enfermedad:"Agresion sexual con fuerza corporal: calles y carreteras"
  },
  {
     id:"Y055",
     enfermedad:"Agresion sexual con fuerza corporal: comercio y area de servicios"
  },
  {
     id:"Y056",
     enfermedad:"Agresion sexual con fuerza corporal: area industrial y de la construccion"
  },
  {
     id:"Y057",
     enfermedad:"Agresion sexual con fuerza corporal: granja"
  },
  {
     id:"Y058",
     enfermedad:"Agresion sexual con fuerza corporal: otro lugar especificado"
  },
  {
     id:"Y059",
     enfermedad:"Agresion sexual con fuerza corporal: lugar no especificado"
  },
  {
     id:"Y06",
     enfermedad:"Negligencia y abandono"
  },
  {
     id:"Y060",
     enfermedad:"Neglicencia y abandono: por esposo o pareja"
  },
  {
     id:"Y061",
     enfermedad:"Neglicencia y abandono: por padre o madre"
  },
  {
     id:"Y062",
     enfermedad:"Neglicencia y abandono: por conocido o amigo"
  },
  {
     id:"Y068",
     enfermedad:"Neglicencia y abandono: por otra persona especificada"
  },
  {
     id:"Y069",
     enfermedad:"Neglicencia y abandono: por persona no especificada"
  },
  {
     id:"Y07",
     enfermedad:"Otros sindromes de maltrato"
  },
  {
     id:"Y070",
     enfermedad:"Otros sindromes de maltrato: por esposo o pareja"
  },
  {
     id:"Y071",
     enfermedad:"Otros sindromes de maltrato: por padre o madre"
  },
  {
     id:"Y072",
     enfermedad:"Otros sindromes de maltrato: por conocido o amigo"
  },
  {
     id:"Y073",
     enfermedad:"Otros sindromes de maltrato: por autoridades oficiales"
  },
  {
     id:"Y078",
     enfermedad:"Otros sindromes de maltrato: por otra persona especificada"
  },
  {
     id:"Y079",
     enfermedad:"Otros sindromes de maltrato: por persona no especificada"
  },
  {
     id:"Y08",
     enfermedad:"Agresion por otros medios especificados"
  },
  {
     id:"Y080",
     enfermedad:"Agresion por otros medios especificados: vivienda"
  },
  {
     id:"Y081",
     enfermedad:"Agresion por otros medios especificados: institucion residencial"
  },
  {
     id:"Y082",
     enfermedad:"Agresion por otros medios especificados: escuelas, otras instituciones y areas administrativas"
  },
  {
     id:"Y083",
     enfermedad:"Agresion por otros medios especificados: areas de deporte y atletismo"
  },
  {
     id:"Y084",
     enfermedad:"Agresion por otros medios especificados: calles y carreteras"
  },
  {
     id:"Y085",
     enfermedad:"Agresion por otros medios especificados: comercio y area de servicios"
  },
  {
     id:"Y086",
     enfermedad:"Agresion por otros medios especificados: area industrial y de la construccion"
  },
  {
     id:"Y087",
     enfermedad:"Agresion por otros medios especificados: granja"
  },
  {
     id:"Y088",
     enfermedad:"Agresion por otros medios especificados: otro lugar especificado"
  },
  {
     id:"Y089",
     enfermedad:"Agresion por otros medios especificados: lugar no especificado"
  },
  {
     id:"Y09",
     enfermedad:"Agresion por medios no especificados"
  },
  {
     id:"Y090",
     enfermedad:"Agresion por medios no especificados: vivienda"
  },
  {
     id:"Y091",
     enfermedad:"Agresion por medios no especificados: institucion residencial"
  },
  {
     id:"Y092",
     enfermedad:"Agresion por medios no especificados: escuelas otras instituciones y areas administrativas"
  },
  {
     id:"Y093",
     enfermedad:"Agresion por medios no especificados: areas de deporte y atletismo"
  },
  {
     id:"Y094",
     enfermedad:"Agresion por medios no especificados: calles y carreteras"
  },
  {
     id:"Y095",
     enfermedad:"Agresion por medios no especificados: comercio y area de servicios"
  },
  {
     id:"Y096",
     enfermedad:"Agresion por medios no especificados: area industrial y de la construccion"
  },
  {
     id:"Y097",
     enfermedad:"Agresion por medios no especificados: granja"
  },
  {
     id:"Y098",
     enfermedad:"Agresion por medios no especificados: otro lugar especificado"
  },
  {
     id:"Y099",
     enfermedad:"Agresion por medios no especificados: lugar no especificado"
  },
  {
     id:"Y10",
     enfermedad:"Envenenamiento por, y exposicion a analgesicos, antipireticos y antirreumaticos, de intencion no determinada"
  },
  {
     id:"Y100",
     enfermedad:"Envenenamiento por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos, de intencion no determinada: vivienda"
  },
  {
     id:"Y101",
     enfermedad:"Envenenamiento por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos, de intencion no determinada: institucion residencial"
  },
  {
     id:"Y102",
     enfermedad:"Envenenamiento por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos, de intencion no determinada: escuelas otras instituciones y areas administrativas"
  },
  {
     id:"Y103",
     enfermedad:"Envenenamiento por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos, de intencion no determinada: areas de deporte y atletismo"
  },
  {
     id:"Y104",
     enfermedad:"Envenenamiento por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos, de intencion no determinada: calles y carreteras"
  },
  {
     id:"Y105",
     enfermedad:"Envenenamiento por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos, de intencion no determinada: comercio y area de servicios"
  },
  {
     id:"Y106",
     enfermedad:"Envenenamiento por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos, de intencion no determinada: area industrial y de la construccion"
  },
  {
     id:"Y107",
     enfermedad:"Envenenamiento por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos, de intencion no determinada: granja"
  },
  {
     id:"Y108",
     enfermedad:"Envenenamiento por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos, de intencion no determinada: otro lugar especificado"
  },
  {
     id:"Y109",
     enfermedad:"Envenenamiento por, y exposicion a analgesicos no narcoticos, antipireticos y antirreumaticos, de intencion no determinada: lugar no especificado"
  },
  {
     id:"Y11",
     enfermedad:"Envenenamiento por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas no clasificadas en otra parte, de intencion no determinada"
  },
  {
     id:"Y110",
     enfermedad:"Envenenamiento por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparkinsonianas y psicotropicas, no clasificadas en otra parte, de intencion no determinada: vivienda"
  },
  {
     id:"Y111",
     enfermedad:"Envenenamiento por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparkinsonianas y psicotropicas, no clasificadas en otra parte, de intencion no determinada: institucion residencial"
  },
  {
     id:"Y112",
     enfermedad:"Envenenamiento por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparkinsonianas y psicotropicas, no clasificadas en otra parte, de intencion no determinada: escuelas otras instituciones y areas administrativas"
  },
  {
     id:"Y113",
     enfermedad:"Envenenamiento por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparkinsonianas y psicotropicas, no clasificadas en otra parte, de intencion no determinada: areas de deporte y atletismo"
  },
  {
     id:"Y114",
     enfermedad:"Envenenamiento por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparkinsonianas y psicotropicas, no clasificadas en otra parte, de intencion no determinada: calles y carreteras"
  },
  {
     id:"Y115",
     enfermedad:"Envenenamiento por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparkinsonianas y psicotropicas, no clasificadas en otra parte, de intencion no determinada: comercio y area de servicios"
  },
  {
     id:"Y116",
     enfermedad:"Envenenamiento por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparkinsonianas y psicotropicas, no clasificadas en otra parte, de intencion no determinada: area industrial y de la construccion"
  },
  {
     id:"Y117",
     enfermedad:"Envenenamiento por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparkinsonianas y psicotropicas, no clasificadas en otra parte, de intencion no determinada: granja"
  },
  {
     id:"Y118",
     enfermedad:"Envenenamiento por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparkinsonianas y psicotropicas, no clasificadas en otra parte, de intencion no determinada: otro lugar especificado"
  },
  {
     id:"Y119",
     enfermedad:"Envenenamiento por, y exposicion a drogas antiepilepticas, sedantes, hipnoticas, antiparkinsonianas y psicotropicas, no clasificadas en otra parte, de intencion no determinada: lugar no especificado"
  },
  {
     id:"Y12",
     enfermedad:"Envenenamiento por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificadas en otra parte, de intencion no determinada"
  },
  {
     id:"Y120",
     enfermedad:"Envenenamiento por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificadas en otra parte, de intencion no determinada: vivienda"
  },
  {
     id:"Y121",
     enfermedad:"Envenenamiento por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificadas en otra parte, de intencion no determinada: institucion residencial"
  },
  {
     id:"Y122",
     enfermedad:"Envenenamiento por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificadas en otra parte, de intencion no determinada: escuelas otras instituciones y areas administrativas"
  },
  {
     id:"Y123",
     enfermedad:"Envenenamiento por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificadas en otra parte, de intencion no determinada: areas de deporte y atletismo"
  },
  {
     id:"Y124",
     enfermedad:"Envenenamiento por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificadas en otra parte, de intencion no determinada: calles y carreteras"
  },
  {
     id:"Y125",
     enfermedad:"Envenenamiento por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificadas en otra parte, de intencion no determinada: comercio y area de servicios"
  },
  {
     id:"Y126",
     enfermedad:"Envenenamiento por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificadas en otra parte, de intencion no determinada: area industrial y de la construccion"
  },
  {
     id:"Y127",
     enfermedad:"Envenenamiento por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificadas en otra parte, de intencion no determinada: granja"
  },
  {
     id:"Y128",
     enfermedad:"Envenenamiento por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificadas en otra parte, de intencion no determinada: otro lugar especificado"
  },
  {
     id:"Y129",
     enfermedad:"Envenenamiento por, y exposicion a narcoticos y psicodislepticos [alucinogenos], no clasificadas en otra parte, de intencion no determinada: lugar no especificado"
  },
  {
     id:"Y13",
     enfermedad:"Envenenamiento por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo, de intencion no determinada"
  },
  {
     id:"Y130",
     enfermedad:"Envenenamiento por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo, de intencion no determinada: vivienda"
  },
  {
     id:"Y131",
     enfermedad:"Envenenamiento por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo, de intencion no determinada: institucion residencial"
  },
  {
     id:"Y132",
     enfermedad:"Envenenamiento por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo, de intencion no determinada: escuelas otras instituciones y areas administrativas"
  },
  {
     id:"Y133",
     enfermedad:"Envenenamiento por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo, de intencion no determinada: areas de deporte y atletismo"
  },
  {
     id:"Y134",
     enfermedad:"Envenenamiento por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo, de intencion no determinada: calles y carreteras"
  },
  {
     id:"Y135",
     enfermedad:"Envenenamiento por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo, de intencion no determinada: comercio y area de servicios"
  },
  {
     id:"Y136",
     enfermedad:"Envenenamiento por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo, de intencion no determinada: area industrial y de la construccion"
  },
  {
     id:"Y137",
     enfermedad:"Envenenamiento por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo, de intencion no determinada: granja"
  },
  {
     id:"Y138",
     enfermedad:"Envenenamiento por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo, de intencion no determinada: otro lugar especificado"
  },
  {
     id:"Y139",
     enfermedad:"Envenenamiento por, y exposicion a otras drogas que actuan sobre el sistema nervioso autonomo, de intencion no determinada: lugar no especificado"
  },
  {
     id:"Y14",
     enfermedad:"Envenenamiento por, y exposicion a otras drogas, medicamentos y sustancias biologicas, y las no especificadas, de intencion no determinada"
  },
  {
     id:"Y140",
     enfermedad:"Envenenamiento por, y exposicion a otras drogas, medicamentos y sustancias biologicas, y las no especificadas, de intencion no determinada: vivienda"
  },
  {
     id:"Y141",
     enfermedad:"Envenenamiento por, y exposicion a otras drogas, medicamentos y sustancias biologicas, y las no especificadas, de intencion no determinada: institucion residencial"
  },
  {
     id:"Y142",
     enfermedad:"Envenenamiento por, y exposicion a otras drogas, medicamentos y sustancias biologicas, y las no especificadas, de intencion no determinada: escuelas otras instituciones y areas administrativas"
  },
  {
     id:"Y143",
     enfermedad:"Envenenamiento por, y exposicion a otras drogas, medicamentos y sustancias biologicas, y las no especificadas, de intencion no determinada: areas de deporte y atletismo"
  },
  {
     id:"Y144",
     enfermedad:"Envenenamiento por, y exposicion a otras drogas, medicamentos y sustancias biologicas, y las no especificadas, de intencion no determinada: calles y carreteras"
  },
  {
     id:"Y145",
     enfermedad:"Envenenamiento por, y exposicion a otras drogas, medicamentos y sustancias biologicas, y las no especificadas, de intencion no determinada: comercio y area de servicios"
  },
  {
     id:"Y146",
     enfermedad:"Envenenamiento por, y exposicion a otras drogas, medicamentos y sustancias biologicas, y las no especificadas, de intencion no determinada: area industrial y de la construccion"
  },
  {
     id:"Y147",
     enfermedad:"Envenenamiento por, y exposicion a otras drogas, medicamentos y sustancias biologicas, y las no especificadas, de intencion no determinada: granja"
  },
  {
     id:"Y148",
     enfermedad:"Envenenamiento por, y exposicion a otras drogas, medicamentos y sustancias biologicas, y las no especificadas, de intencion no determinada: otro lugar especificado"
  },
  {
     id:"Y149",
     enfermedad:"Envenenamiento por, y exposicion a otras drogas, medicamentos y sustancias biologicas, y las no especificadas, de intencion no determinada: lugar no especificado"
  },
  {
     id:"Y15",
     enfermedad:"Envenenamiento por, y exposicion al alcohol intencion no determinada"
  },
  {
     id:"Y150",
     enfermedad:"Envenenamiento por, y exposicion al alcohol , de intencion no determinada: vivienda"
  },
  {
     id:"Y151",
     enfermedad:"Envenenamiento por, y exposicion al alcohol , de intencion no determinada: institucion residencial"
  },
  {
     id:"Y152",
     enfermedad:"Envenenamiento por, y exposicion al alcohol , de intencion no determinada: escuelas otras instituciones y areas administrativas"
  },
  {
     id:"Y153",
     enfermedad:"Envenenamiento por, y exposicion al alcohol , de intencion no determinada: areas de deporte y atletismo"
  },
  {
     id:"Y154",
     enfermedad:"Envenenamiento por, y exposicion al alcohol , de intencion no determinada: calles y carreteras"
  },
  {
     id:"Y155",
     enfermedad:"Envenenamiento por, y exposicion al alcohol , de intencion no determinada: comercio y area de servicios"
  },
  {
     id:"Y156",
     enfermedad:"Envenenamiento por, y exposicion al alcohol , de intencion no determinada: area industrial y de la construccion"
  },
  {
     id:"Y157",
     enfermedad:"Envenenamiento por, y exposicion al alcohol , de intencion no determinada: granja"
  },
  {
     id:"Y158",
     enfermedad:"Envenenamiento por, y exposicion al alcohol , de intencion no determinada: otro lugar especificado"
  },
  {
     id:"Y159",
     enfermedad:"Envenenamiento por, y exposicion al alcohol , de intencion no determinada: lugar no especificado"
  },
  {
     id:"Y16",
     enfermedad:"Envenenamiento por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores, de intencion no determinada"
  },
  {
     id:"Y160",
     enfermedad:"Envenenamiento por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores, de intencion no determinada: vivienda"
  },
  {
     id:"Y161",
     enfermedad:"Envenenamiento por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores, de intencion no determinada: institucion residencial"
  },
  {
     id:"Y162",
     enfermedad:"Envenenamiento por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores, de intencion no determinada: escuelas, otras instituciones y areas administrativas"
  },
  {
     id:"Y163",
     enfermedad:"Envenenamiento por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores, de intencion no determinada: areas de deporte y atletismo"
  },
  {
     id:"Y164",
     enfermedad:"Envenenamiento por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores, de intencion no determinada: calles y carreteras"
  },
  {
     id:"Y165",
     enfermedad:"Envenenamiento por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores, de intencion no determinada: comercio y area de servicios"
  },
  {
     id:"Y166",
     enfermedad:"Envenenamiento por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores, de intencion no determinada: area industrial y de la construccion"
  },
  {
     id:"Y167",
     enfermedad:"Envenenamiento por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores, de intencion no determinada: granja"
  },
  {
     id:"Y168",
     enfermedad:"Envenenamiento por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores, de intencion no determinada: otro lugar especificado"
  },
  {
     id:"Y169",
     enfermedad:"Envenenamiento por, y exposicion a disolventes organicos e hidrocarburos halogenados y sus vapores, de intencion no determinada: lugar no especificado"
  },
  {
     id:"Y17",
     enfermedad:"Envenenamiento por, y exposicion a otros gases y vapores, de intencion no determinada"
  },
  {
     id:"Y170",
     enfermedad:"Envenenamiento por, y exposicion a otros gases y vapores, de intencion no determinada: vivienda"
  },
  {
     id:"Y171",
     enfermedad:"Envenenamiento por, y exposicion a otros gases y vapores, de intencion no determinada: institucion residencial"
  },
  {
     id:"Y172",
     enfermedad:"Envenenamiento por, y exposicion a otros gases y vapores, de intencion no determinada: escuelas, otras instituciones y areas administrativas"
  },
  {
     id:"Y173",
     enfermedad:"Envenenamiento por, y exposicion a otros gases y vapores, de intencion no determinada: areas de deporte y atletismo"
  },
  {
     id:"Y174",
     enfermedad:"Envenenamiento por, y exposicion a otros gases y vapores, de intencion no determinada: calles y carreteras"
  },
  {
     id:"Y175",
     enfermedad:"Envenenamiento por, y exposicion a otros gases y vapores, de intencion no determinada: comercio y area de servicios"
  },
  {
     id:"Y176",
     enfermedad:"Envenenamiento por, y exposicion a otros gases y vapores, de intencion no determinada: area industrial y de la construccion"
  },
  {
     id:"Y177",
     enfermedad:"Envenenamiento por, y exposicion a otros gases y vapores, de intencion no determinada: granja"
  },
  {
     id:"Y178",
     enfermedad:"Envenenamiento por, y exposicion a otros gases y vapores, de intencion no determinada: otro lugar especificado"
  },
  {
     id:"Y179",
     enfermedad:"Envenenamiento por, y exposicion a otros gases y vapores, de intencion no determinada: lugar no especificado"
  },
  {
     id:"Y18",
     enfermedad:"Envenenamiento por, y exposicion a plaguicidas de intencion no determinada"
  },
  {
     id:"Y180",
     enfermedad:"Envenenamiento por, y exposicion a plaguicidas, de intencion no determinada: vivienda"
  },
  {
     id:"Y181",
     enfermedad:"Envenenamiento por, y exposicion a plaguicidas, de intencion no determinada: institucion residencial"
  },
  {
     id:"Y182",
     enfermedad:"Envenenamiento por, y exposicion a plaguicidas, de intencion no determinada: escuelas otras instituciones y areas administrativas"
  },
  {
     id:"Y183",
     enfermedad:"Envenenamiento por, y exposicion a plaguicidas, de intencion no determinada: areas de deporte y atletismo"
  },
  {
     id:"Y184",
     enfermedad:"Envenenamiento por, y exposicion a plaguicidas, de intencion no determinada: calles y carreteras"
  },
  {
     id:"Y185",
     enfermedad:"Envenenamiento por, y exposicion a plaguicidas, de intencion no determinada: comercio y area de servicios"
  },
  {
     id:"Y186",
     enfermedad:"Envenenamiento por, y exposicion a plaguicidas, de intencion no determinada: area industrial y de la construccion"
  },
  {
     id:"Y187",
     enfermedad:"Envenenamiento por, y exposicion a plaguicidas, de intencion no determinada: granja"
  },
  {
     id:"Y188",
     enfermedad:"Envenenamiento por, y exposicion a plaguicidas, de intencion no determinada: otro lugar especificado"
  },
  {
     id:"Y189",
     enfermedad:"Envenenamiento por, y exposicion a plaguicidas, de intencion no determinada: lugar no especificado"
  },
  {
     id:"Y19",
     enfermedad:"Envenenamiento por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados, de intencion no determinada"
  },
  {
     id:"Y190",
     enfermedad:"Envenenamiento por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados, de intencion no determinada: vivienda"
  },
  {
     id:"Y191",
     enfermedad:"Envenenamiento por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados, de intencion no determinada: institucion residencial"
  },
  {
     id:"Y192",
     enfermedad:"Envenenamiento por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados, de intencion no determinada: escuelas otras instituciones y areas administrativas"
  },
  {
     id:"Y193",
     enfermedad:"Envenenamiento por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados, de intencion no determinada: areas de deporte y atletismo"
  },
  {
     id:"Y194",
     enfermedad:"Envenenamiento por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados, de intencion no determinada: calles y carreteras"
  },
  {
     id:"Y195",
     enfermedad:"Envenenamiento por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados, de intencion no determinada: comercio y area de servicios"
  },
  {
     id:"Y196",
     enfermedad:"Envenenamiento por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados, de intencion no determinada: area industrial y de la construccion"
  },
  {
     id:"Y197",
     enfermedad:"Envenenamiento por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados, de intencion no determinada: granja"
  },
  {
     id:"Y198",
     enfermedad:"Envenenamiento por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados, de intencion no determinada: otro lugar especificado"
  },
  {
     id:"Y199",
     enfermedad:"Envenenamiento por, y exposicion a otros productos quimicos y sustancias nocivas, y los no especificados, de intencion no determinada: lugar no especificado"
  },
  {
     id:"Y20",
     enfermedad:"Ahorcamiento, estrangulamiento y sofocacion, de intencion no determinada"
  },
  {
     id:"Y200",
     enfermedad:"Ahorcamiento, estrangulamiento y sofocacion, de intencion no determinada: vivienda"
  },
  {
     id:"Y201",
     enfermedad:"Ahorcamiento, estrangulamiento y sofocacion, de intencion no determinada: institucion residencial"
  },
  {
     id:"Y202",
     enfermedad:"Ahorcamiento, estrangulamiento y sofocacion, de intencion no determinada: escuelas otras instituciones y areas administrativas"
  },
  {
     id:"Y203",
     enfermedad:"Ahorcamiento, estrangulamiento y sofocacion, de intencion no determinada: areas de deporte y atletismo"
  },
  {
     id:"Y204",
     enfermedad:"Ahorcamiento, estrangulamiento y sofocacion, de intencion no determinada: calles y carreteras"
  },
  {
     id:"Y205",
     enfermedad:"Ahorcamiento, estrangulamiento y sofocacion, de intencion no determinada: comercio y area de servicios"
  },
  {
     id:"Y206",
     enfermedad:"Ahorcamiento, estrangulamiento y sofocacion, de intencion no determinada: area industrial y de la construccion"
  },
  {
     id:"Y207",
     enfermedad:"Ahorcamiento, estrangulamiento y sofocacion, de intencion no determinada: granja"
  },
  {
     id:"Y208",
     enfermedad:"Ahorcamiento, estrangulamiento y sofocacion, de intencion no determinada: otro lugar especificado"
  },
  {
     id:"Y209",
     enfermedad:"Ahorcamiento, estrangulamiento y sofocacion, de intencion no determinada: lugar no especificado"
  },
  {
     id:"Y21",
     enfermedad:"Ahogamiento y sumersion de intencion no determinada"
  },
  {
     id:"Y210",
     enfermedad:"Ahogamiento y sumersion, de intencion no determinada: vivienda"
  },
  {
     id:"Y211",
     enfermedad:"Ahogamiento y sumersion, de intencion no determinada: institucion residencial"
  },
  {
     id:"Y212",
     enfermedad:"Ahogamiento y sumersion, de intencion no determinada: escuelas otras instituciones y areas administrativas"
  },
  {
     id:"Y213",
     enfermedad:"Ahogamiento y sumersion, de intencion no determinada: areas de deporte y atletismo"
  },
  {
     id:"Y214",
     enfermedad:"Ahogamiento y sumersion, de intencion no determinada: calles y carreteras"
  },
  {
     id:"Y215",
     enfermedad:"Ahogamiento y sumersion, de intencion no determinada: comercio y area de servicios"
  },
  {
     id:"Y216",
     enfermedad:"Ahogamiento y sumersion, de intencion no determinada: area industrial y de la construccion"
  },
  {
     id:"Y217",
     enfermedad:"Ahogamiento y sumersion, de intencion no determinada: granja"
  },
  {
     id:"Y218",
     enfermedad:"Ahogamiento y sumersion, de intencion no determinada: otro lugar especificado"
  },
  {
     id:"Y219",
     enfermedad:"Ahogamiento y sumersion, de intencion no determinada: lugar no especificado"
  },
  {
     id:"Y22",
     enfermedad:"Disparo de arma corta de intencion no determinada"
  },
  {
     id:"Y220",
     enfermedad:"Disparo de arma corta, de intencion no determinada: vivienda"
  },
  {
     id:"Y221",
     enfermedad:"Disparo de arma corta, de intencion no determinada: institucion residencial"
  },
  {
     id:"Y222",
     enfermedad:"Disparo de arma corta, de intencion no determinada: escuelas, otras instituciones y areas administrativas"
  },
  {
     id:"Y223",
     enfermedad:"Disparo de arma corta, de intencion no determinada: areas de deporte y atletismo"
  },
  {
     id:"Y224",
     enfermedad:"Disparo de arma corta, de intencion no determinada: calles y carreteras"
  },
  {
     id:"Y225",
     enfermedad:"Disparo de arma corta, de intencion no determinada: comercio y area de servicios"
  },
  {
     id:"Y226",
     enfermedad:"Disparo de arma corta, de intencion no determinada: area industrial y de la construccion"
  },
  {
     id:"Y227",
     enfermedad:"Disparo de arma corta, de intencion no determinada: granja"
  },
  {
     id:"Y228",
     enfermedad:"Disparo de arma corta, de intencion no determinada: otro lugar especificado"
  },
  {
     id:"Y229",
     enfermedad:"Disparo de arma corta, de intencion no determinada: lugar no especificado"
  },
  {
     id:"Y23",
     enfermedad:"Disparo de rifle, escopeta y arma larga, de intencion no determinada"
  },
  {
     id:"Y230",
     enfermedad:"Disparo de rifle, escopeta y arma larga, de intencion no determinada: vivienda"
  },
  {
     id:"Y231",
     enfermedad:"Disparo de rifle, escopeta y arma larga, de intencion no determinada: institucion residencial"
  },
  {
     id:"Y232",
     enfermedad:"Disparo de rifle, escopeta y arma larga, de intencion no determinada: escuelas, otras instituciones y areas administrativas"
  },
  {
     id:"Y233",
     enfermedad:"Disparo de rifle, escopeta y arma larga, de intencion no determinada: areas de deporte y atletismo"
  },
  {
     id:"Y234",
     enfermedad:"Disparo de rifle, escopeta y arma larga, de intencion no determinada: calles y carreteras"
  },
  {
     id:"Y235",
     enfermedad:"Disparo de rifle, escopeta y arma larga, de intencion no determinada: comercio y area de servicios"
  },
  {
     id:"Y236",
     enfermedad:"Disparo de rifle, escopeta y arma larga, de intencion no determinada: area industrial y de la construccion"
  },
  {
     id:"Y237",
     enfermedad:"Disparo de rifle, escopeta y arma larga, de intencion no determinada: granja"
  },
  {
     id:"Y238",
     enfermedad:"Disparo de rifle, escopeta y arma larga, de intencion no determinada: otro lugar especificado"
  },
  {
     id:"Y239",
     enfermedad:"Disparo de rifle, escopeta y arma larga, de intencion no determinada: lugar no especificado"
  },
  {
     id:"Y24",
     enfermedad:"Disparo de otras armas de fuego y las no especificadas, de intencion no determinada"
  },
  {
     id:"Y240",
     enfermedad:"Disparo de otras armas de fuego, y las no especificadas, de intencion no determinada: vivienda"
  },
  {
     id:"Y241",
     enfermedad:"Disparo de otras armas de fuego, y las no especificadas, de intencion no determinada: institucion residencial"
  },
  {
     id:"Y242",
     enfermedad:"Disparo de otras armas de fuego, y las no especificadas, de intencion no determinada: escuelas, otras instituciones y areas administrativas"
  },
  {
     id:"Y243",
     enfermedad:"Disparo de otras armas de fuego, y las no especificadas, de intencion no determinada: areas de deporte y atletismo"
  },
  {
     id:"Y244",
     enfermedad:"Disparo de otras armas de fuego, y las no especificadas, de intencion no determinada: calles y carreteras"
  },
  {
     id:"Y245",
     enfermedad:"Disparo de otras armas de fuego, y las no especificadas, de intencion no determinada: comercio y area de servicios"
  },
  {
     id:"Y246",
     enfermedad:"Disparo de otras armas de fuego, y las no especificadas, de intencion no determinada: area industrial y de la construccion"
  },
  {
     id:"Y247",
     enfermedad:"Disparo de otras armas de fuego, y las no especificadas, de intencion no determinada: granja"
  },
  {
     id:"Y248",
     enfermedad:"Disparo de otras armas de fuego, y las no especificadas, de intencion no determinada: otro lugar especificado"
  },
  {
     id:"Y249",
     enfermedad:"Disparo de otras armas de fuego, y las no especificadas, de intencion no determinada: lugar no especificado"
  },
  {
     id:"Y25",
     enfermedad:"Contacto traumatico con material explosivo, de intencion no determinada"
  },
  {
     id:"Y250",
     enfermedad:"Contacto traumatico con material explosivo, de intencion no determinada: vivienda"
  },
  {
     id:"Y251",
     enfermedad:"Contacto traumatico con material explosivo, de intencion no determinada: institucion residencial"
  },
  {
     id:"Y252",
     enfermedad:"Contacto traumatico con material explosivo, de intencion no determinada: escuelas otras instituciones y areas administrativas"
  },
  {
     id:"Y253",
     enfermedad:"Contacto traumatico con material explosivo, de intencion no determinada: areas de deporte y atletismo"
  },
  {
     id:"Y254",
     enfermedad:"Contacto traumatico con material explosivo, de intencion no determinada: calles y carreteras"
  },
  {
     id:"Y255",
     enfermedad:"Contacto traumatico con material explosivo, de intencion no determinada: comercio y area de servicios"
  },
  {
     id:"Y256",
     enfermedad:"Contacto traumatico con material explosivo, de intencion no determinada: area industrial y de la construccion"
  },
  {
     id:"Y257",
     enfermedad:"Contacto traumatico con material explosivo, de intencion no determinada: granja"
  },
  {
     id:"Y258",
     enfermedad:"Contacto traumatico con material explosivo, de intencion no determinada: otro lugar especificado"
  },
  {
     id:"Y259",
     enfermedad:"Contacto traumatico con material explosivo, de intencion no determinada: lugar no especificado"
  },
  {
     id:"Y26",
     enfermedad:"Exposicion a humo, fuego y llamas, de intencion no determinada"
  },
  {
     id:"Y260",
     enfermedad:"Exposicion al humo, fuego y llamas, de intencion no determinada: vivienda"
  },
  {
     id:"Y261",
     enfermedad:"Exposicion al humo, fuego y llamas, de intencion no determinada: institucion residencial"
  },
  {
     id:"Y262",
     enfermedad:"Exposicion al humo, fuego y llamas, de intencion no determinada: escuelas, otras instituciones y areas administrativas"
  },
  {
     id:"Y263",
     enfermedad:"Exposicion al humo, fuego y llamas, de intencion no determinada: areas de deporte y atletismo"
  },
  {
     id:"Y264",
     enfermedad:"Exposicion al humo, fuego y llamas, de intencion no determinada: calles y carreteras"
  },
  {
     id:"Y265",
     enfermedad:"Exposicion al humo, fuego y llamas, de intencion no determinada: comercio y area de servicios"
  },
  {
     id:"Y266",
     enfermedad:"Exposicion al humo, fuego y llamas, de intencion no determinada: area industrial y de la construccion"
  },
  {
     id:"Y267",
     enfermedad:"Exposicion al humo, fuego y llamas, de intencion no determinada: granja"
  },
  {
     id:"Y268",
     enfermedad:"Exposicion al humo, fuego y llamas, de intencion no determinada: otro lugar especificado"
  },
  {
     id:"Y269",
     enfermedad:"Exposicion al humo, fuego y llamas, de intencion no determinada: lugar no especificado"
  },
  {
     id:"Y27",
     enfermedad:"Contacto con vapor de agua, vapores y objetos calientes, de intencion no determinada"
  },
  {
     id:"Y270",
     enfermedad:"Contacto con vapor de agua, vapores y objetos calientes, de intencion no determinada: vivienda"
  },
  {
     id:"Y271",
     enfermedad:"Contacto con vapor de agua, vapores y objetos calientes, de intencion no determinada: institucion residencial"
  },
  {
     id:"Y272",
     enfermedad:"Contacto con vapor de agua, vapores y objetos calientes, de intencion no determinada: escuelas, otras instituciones y areas administrativas"
  },
  {
     id:"Y273",
     enfermedad:"Contacto con vapor de agua, vapores y objetos calientes, de intencion no determinada: areas de deporte y atletismo"
  },
  {
     id:"Y274",
     enfermedad:"Contacto con vapor de agua, vapores y objetos calientes, de intencion no determinada: calles y carreteras"
  },
  {
     id:"Y275",
     enfermedad:"Contacto con vapor de agua, vapores y objetos calientes, de intencion no determinada: comercio y area de servicios"
  },
  {
     id:"Y276",
     enfermedad:"Contacto con vapor de agua, vapores y objetos calientes, de intencion no determinada: area industrial y de la construccion"
  },
  {
     id:"Y277",
     enfermedad:"Contacto con vapor de agua, vapores y objetos calientes, de intencion no determinada: granja"
  },
  {
     id:"Y278",
     enfermedad:"Contacto con vapor de agua, vapores y objetos calientes, de intencion no determinada: otro lugar especificado"
  },
  {
     id:"Y279",
     enfermedad:"Contacto con vapor de agua, vapores y objetos calientes, de intencion no determinada: lugar no especificado"
  },
  {
     id:"Y28",
     enfermedad:"Contacto traumatico con objeto cortante, de intencion no determinada"
  },
  {
     id:"Y280",
     enfermedad:"Contacto traumatico con objeto cortante, de intencion no determinada: vivienda"
  },
  {
     id:"Y281",
     enfermedad:"Contacto traumatico con objeto cortante, de intencion no determinada: institucion residencial"
  },
  {
     id:"Y282",
     enfermedad:"Contacto traumatico con objeto cortante, de intencion no determinada: escuelas, otras instituciones y areas administrativas"
  },
  {
     id:"Y283",
     enfermedad:"Contacto traumatico con objeto cortante, de intencion no determinada: areas de deporte y atletismo"
  },
  {
     id:"Y284",
     enfermedad:"Contacto traumatico con objeto cortante, de intencion no determinada: calles y carreteras"
  },
  {
     id:"Y285",
     enfermedad:"Contacto traumatico con objeto cortante, de intencion no determinada: comercio y area de servicios"
  },
  {
     id:"Y286",
     enfermedad:"Contacto traumatico con objeto cortante, de intencion no determinada: area industrial y de la construccion"
  },
  {
     id:"Y287",
     enfermedad:"Contacto traumatico con objeto cortante, de intencion no determinada: granja"
  },
  {
     id:"Y288",
     enfermedad:"Contacto traumatico con objeto cortante, de intencion no determinada: otro lugar especificado"
  },
  {
     id:"Y289",
     enfermedad:"Contacto traumatico con objeto cortante, de intencion no determinada: lugar no especificado"
  },
  {
     id:"Y29",
     enfermedad:"Contacto traumatico con objeto romo sin filo, de intencion no determinada"
  },
  {
     id:"Y290",
     enfermedad:"Contacto traumatico con objeto romo o sin filo, de intencion no determinada: vivienda"
  },
  {
     id:"Y291",
     enfermedad:"Contacto traumatico con objeto romo o sin filo, de intencion no determinada: institucion residencial"
  },
  {
     id:"Y292",
     enfermedad:"Contacto traumatico con objeto romo o sin filo, de intencion no determinada: escuelas, otras instituciones y areas administrativas"
  },
  {
     id:"Y293",
     enfermedad:"Contacto traumatico con objeto romo o sin filo, de intencion no determinada: areas de deporte y atletismo"
  },
  {
     id:"Y294",
     enfermedad:"Contacto traumatico con objeto romo o sin filo, de intencion no determinada: calles y carreteras"
  },
  {
     id:"Y295",
     enfermedad:"Contacto traumatico con objeto romo o sin filo, de intencion no determinada: comercio y area de servicios"
  },
  {
     id:"Y296",
     enfermedad:"Contacto traumatico con objeto romo o sin filo, de intencion no determinada: area industrial y de la construccion"
  },
  {
     id:"Y297",
     enfermedad:"Contacto traumatico con objeto romo o sin filo, de intencion no determinada: granja"
  },
  {
     id:"Y298",
     enfermedad:"Contacto traumatico con objeto romo o sin filo, de intencion no determinada: otro lugar especificado"
  },
  {
     id:"Y299",
     enfermedad:"Contacto traumatico con objeto romo o sin filo, de intencion no determinada: lugar no especificado"
  },
  {
     id:"Y30",
     enfermedad:"Caida, salto o empujon desde lugar elevado, de intencion no determinada"
  },
  {
     id:"Y300",
     enfermedad:"Caida, salto o empujon desde lugar elevado, de intencion no determinada: vivienda"
  },
  {
     id:"Y301",
     enfermedad:"Caida, salto o empujon desde lugar elevado, de intencion no determinada: institucion residencial"
  },
  {
     id:"Y302",
     enfermedad:"Caida, salto o empujon desde lugar elevado, de intencion no determinada: escuelas, otras instituciones y areas administrativas"
  },
  {
     id:"Y303",
     enfermedad:"Caida, salto o empujon desde lugar elevado, de intencion no determinada: areas de deporte y atletismo"
  },
  {
     id:"Y304",
     enfermedad:"Caida, salto o empujon desde lugar elevado, de intencion no determinada: calles y carreteras"
  },
  {
     id:"Y305",
     enfermedad:"Caida, salto o empujon desde lugar elevado, de intencion no determinada: comercio y area de servicios"
  },
  {
     id:"Y306",
     enfermedad:"Caida, salto o empujon desde lugar elevado, de intencion no determinada: area industrial y de la construccion"
  },
  {
     id:"Y307",
     enfermedad:"Caida, salto o empujon desde lugar elevado, de intencion no determinada: granja"
  },
  {
     id:"Y308",
     enfermedad:"Caida, salto o empujon desde lugar elevado, de intencion no determinada: otro lugar especificado"
  },
  {
     id:"Y309",
     enfermedad:"Caida, salto o empujon desde lugar elevado, de intencion no determinada: lugar no especificado"
  },
  {
     id:"Y31",
     enfermedad:"Caida, permanencia o carrera delante o hacia objeto en movimiento, de intencion no determinada"
  },
  {
     id:"Y310",
     enfermedad:"Caida, permanencia o carrera delante o hacia objeto en movimiento, de intencion no determinada: vivienda"
  },
  {
     id:"Y311",
     enfermedad:"Caida, permanencia o carrera delante o hacia objeto en movimiento, de intencion no determinada: institucion residencial"
  },
  {
     id:"Y312",
     enfermedad:"Caida, permanencia o carrera delante o hacia objeto en movimiento, de intencion no determinada: escuelas, otras instituciones y areas administrativas"
  },
  {
     id:"Y313",
     enfermedad:"Caida, permanencia o carrera delante o hacia objeto en movimiento, de intencion no determinada: areas de deporte y atletismo"
  },
  {
     id:"Y314",
     enfermedad:"Caida, permanencia o carrera delante o hacia objeto en movimiento, de intencion no determinada: calles y carreteras"
  },
  {
     id:"Y315",
     enfermedad:"Caida, permanencia o carrera delante o hacia objeto en movimiento, de intencion no determinada: comercio y area de servicios"
  },
  {
     id:"Y316",
     enfermedad:"Caida, permanencia o carrera delante o hacia objeto en movimiento, de intencion no determinada: area industrial y de la construccion"
  },
  {
     id:"Y317",
     enfermedad:"Caida, permanencia o carrera delante o hacia objeto en movimiento, de intencion no determinada: granja"
  },
  {
     id:"Y318",
     enfermedad:"Caida, permanencia o carrera delante o hacia objeto en movimiento, de intencion no determinada: otro lugar especificado"
  },
  {
     id:"Y319",
     enfermedad:"Caida, permanencia o carrera delante o hacia objeto en movimiento, de intencion no determinada: lugar no especificado"
  },
  {
     id:"Y32",
     enfermedad:"Colision de vehiculo de motor de intencion no determinadas"
  },
  {
     id:"Y320",
     enfermedad:"Colision de vehiculo de motor, de intencion no determinada: vivienda"
  },
  {
     id:"Y321",
     enfermedad:"Colision de vehiculo de motor, de intencion no determinada: institucion residencial"
  },
  {
     id:"Y322",
     enfermedad:"Colision de vehiculo de motor, de intencion no determinada: escuelas, otras instituciones y areas administrativas"
  },
  {
     id:"Y323",
     enfermedad:"Colision de vehiculo de motor, de intencion no determinada: areas de deporte y atletismo"
  },
  {
     id:"Y324",
     enfermedad:"Colision de vehiculo de motor, de intencion no determinada: calles y carreteras"
  },
  {
     id:"Y325",
     enfermedad:"Colision de vehiculo de motor, de intencion no determinada: comercio y area de servicios"
  },
  {
     id:"Y326",
     enfermedad:"Colision de vehiculo de motor, de intencion no determinada: area industrial y de la construccion"
  },
  {
     id:"Y327",
     enfermedad:"Colision de vehiculo de motor, de intencion no determinada: granja"
  },
  {
     id:"Y328",
     enfermedad:"Colision de vehiculo de motor, de intencion no determinada: otro lugar especificado"
  },
  {
     id:"Y329",
     enfermedad:"Colision de vehiculo de motor, de intencion no determinada: lugar no especificado"
  },
  {
     id:"Y33",
     enfermedad:"Otros eventos especificados de intencion no determinada"
  },
  {
     id:"Y330",
     enfermedad:"Otros eventos especificados, de intencion no determinada: vivienda"
  },
  {
     id:"Y331",
     enfermedad:"Otros eventos especificados, de intencion no determinada: institucion residencial"
  },
  {
     id:"Y332",
     enfermedad:"Otros eventos especificados, de intencion no determinada: escuelas, otras instituciones y areas administrativas"
  },
  {
     id:"Y333",
     enfermedad:"Otros eventos especificados, de intencion no determinada: areas de deporte y atletismo"
  },
  {
     id:"Y334",
     enfermedad:"Otros eventos especificados, de intencion no determinada: calles y carreteras"
  },
  {
     id:"Y335",
     enfermedad:"Otros eventos especificados, de intencion no determinada: comercio y area de servicios"
  },
  {
     id:"Y336",
     enfermedad:"Otros eventos especificados, de intencion no determinada: area industrial y de la construccion"
  },
  {
     id:"Y337",
     enfermedad:"Otros eventos especificados, de intencion no determinada: granja"
  },
  {
     id:"Y338",
     enfermedad:"Otros eventos especificados, de intencion no determinada: otro lugar especificado"
  },
  {
     id:"Y339",
     enfermedad:"Otros eventos especificados, de intencion no determinada: lugar no especificado"
  },
  {
     id:"Y34",
     enfermedad:"Evento no especificado de intencion no determinada"
  },
  {
     id:"Y340",
     enfermedad:"Evento no especificado, de intencion no determinada: vivienda"
  },
  {
     id:"Y341",
     enfermedad:"Evento no especificado, de intencion no determinada: institucion residencial"
  },
  {
     id:"Y342",
     enfermedad:"Evento no especificado, de intencion no determinada: escuelas, otras instituciones y areas administrativas"
  },
  {
     id:"Y343",
     enfermedad:"Evento no especificado, de intencion no determinada: areas de deporte y atletismo"
  },
  {
     id:"Y344",
     enfermedad:"Evento no especificado, de intencion no determinada: calles y carreteras"
  },
  {
     id:"Y345",
     enfermedad:"Evento no especificado, de intencion no determinada: comercio y area de servicios"
  },
  {
     id:"Y346",
     enfermedad:"Evento no especificado, de intencion no determinada: area industrial y de la construccion"
  },
  {
     id:"Y347",
     enfermedad:"Evento no especificado, de intencion no determinada: granja"
  },
  {
     id:"Y348",
     enfermedad:"Evento no especificado, de intencion no determinada: otro lugar especificado"
  },
  {
     id:"Y349",
     enfermedad:"Evento no especificado, de intencion no determinada: lugar no especificado"
  },
  {
     id:"Y35",
     enfermedad:"Intervencion legal"
  },
  {
     id:"Y350",
     enfermedad:"Intervencion legal con disparo de arma de fuego"
  },
  {
     id:"Y351",
     enfermedad:"Intervencion legal con explosivos"
  },
  {
     id:"Y352",
     enfermedad:"Intervencion legal con gas"
  },
  {
     id:"Y353",
     enfermedad:"Intervencion legal con objetos romos o sin filo"
  },
  {
     id:"Y354",
     enfermedad:"Intervencion legal con objetos cortantes"
  },
  {
     id:"Y355",
     enfermedad:"Ejecucion legal"
  },
  {
     id:"Y356",
     enfermedad:"Intervencion legal con otros medios especificados"
  },
  {
     id:"Y357",
     enfermedad:"Intervencion legal, medios no especificados"
  },
  {
     id:"Y36",
     enfermedad:"Operaciones de guerra"
  },
  {
     id:"Y360",
     enfermedad:"Operaciones de guerra con explosion de armamento naval"
  },
  {
     id:"Y361",
     enfermedad:"Operaciones de guerra con destruccion de aeronave"
  },
  {
     id:"Y362",
     enfermedad:"Operaciones de guerra con otras explosiones y esquirlas"
  },
  {
     id:"Y363",
     enfermedad:"Operaciones de guerra con fuego y sustancias incendiarias y calientes"
  },
  {
     id:"Y364",
     enfermedad:"Operaciones de guerra con disparo de arma de fuego y otras formas de guerra convencional"
  },
  {
     id:"Y365",
     enfermedad:"Operaciones de guerra con armas nucleares"
  },
  {
     id:"Y366",
     enfermedad:"Operaciones de guerra con armas biologicas"
  },
  {
     id:"Y367",
     enfermedad:"Operaciones de guerra con armas quimicas y otras formas de guerra no convencional"
  },
  {
     id:"Y368",
     enfermedad:"Operaciones de guerra que ocurren despues del cese de hostilidades"
  },
  {
     id:"Y369",
     enfermedad:"Operación de guerra no especificada"
  },
  {
     id:"Y40",
     enfermedad:"Efectos adversos de antibioticos sistemicos"
  },
  {
     id:"Y400",
     enfermedad:"Efectos adversos de penicilinas"
  },
  {
     id:"Y401",
     enfermedad:"Efectos adversos de cefalosporinas y otros antibioticos betalactamicos"
  },
  {
     id:"Y402",
     enfermedad:"Efectos adversos del grupo de cloramfenicol"
  },
  {
     id:"Y403",
     enfermedad:"Efectos adversos de los macrolidos"
  },
  {
     id:"Y404",
     enfermedad:"Efectos adversos de tetraciclinas"
  },
  {
     id:"Y405",
     enfermedad:"Efectos adversos de aminoglicosidos"
  },
  {
     id:"Y406",
     enfermedad:"Efectos adversos de rifamicinas"
  },
  {
     id:"Y407",
     enfermedad:"Efectos adversos de antibioticos antimicoticos usados sistematicamente"
  },
  {
     id:"Y408",
     enfermedad:"Efectos adversos de otros antibioticos sistemicos"
  },
  {
     id:"Y409",
     enfermedad:"Efectos adversos de antibiotico sistemico no especificado"
  },
  {
     id:"Y41",
     enfermedad:"Efectos adversos de otros antiinfecciosos y antiparasitarios sistemicos"
  },
  {
     id:"Y410",
     enfermedad:"Efectos adversos de sulfonamidas"
  },
  {
     id:"Y411",
     enfermedad:"Efectos adversos de drogas antimicobacterianas"
  },
  {
     id:"Y412",
     enfermedad:"Efectos adversos de drogas antipaludicas y agentes que actuan sobre otros protozoarios de la sangre"
  },
  {
     id:"Y413",
     enfermedad:"Efectos adversos de otras drogas antiprotozoarias"
  },
  {
     id:"Y414",
     enfermedad:"Efectos adversos de antihelminticos"
  },
  {
     id:"Y415",
     enfermedad:"Efectos adversos de drogas antivirales"
  },
  {
     id:"Y418",
     enfermedad:"Efectos adversos de otros antiinfecciosos y antiparasitarios sistemicos especificados"
  },
  {
     id:"Y419",
     enfermedad:"Efectos adversos de antiinfecciosos y antiparasitarios sistemicos no especificados"
  },
  {
     id:"Y42",
     enfermedad:"Efectos adversos de hormonas sustitutos sinteticos y antagonistas, no clasificadas en otra parte"
  },
  {
     id:"Y420",
     enfermedad:"Efectos adversos de glucocorticoides y analogos sinteticos"
  },
  {
     id:"Y421",
     enfermedad:"Efectos adversos de hormonas tiroideas y sustitutos"
  },
  {
     id:"Y422",
     enfermedad:"Efectos adversos de drogas antitiroideas"
  },
  {
     id:"Y423",
     enfermedad:"Efectos adversos de drogas hipoglucemiantes orales e insulina [antidiabeticas]"
  },
  {
     id:"Y424",
     enfermedad:"Efectos adversos de anticonceptivos orales"
  },
  {
     id:"Y425",
     enfermedad:"Efectos adversos de otros estrogenos y progestagenos"
  },
  {
     id:"Y426",
     enfermedad:"Efectos adversos de antigonadotropinas, antiestrogenos y antiandrogenos, no clasificados en otra parte"
  },
  {
     id:"Y427",
     enfermedad:"Efectos adversos de androgenos y congeneres anabolicos"
  },
  {
     id:"Y428",
     enfermedad:"Efectos adversos de otras hormonas y sus sustitutos sinteticos, y las no especificadas"
  },
  {
     id:"Y429",
     enfermedad:"Efectos adversos de otras hormonas antagonistas, y las no especificadas"
  },
  {
     id:"Y43",
     enfermedad:"Efectos adversos de agentes sistemicos primarios"
  },
  {
     id:"Y430",
     enfermedad:"Efectos adversos de antialergicos y antiemeticos"
  },
  {
     id:"Y431",
     enfermedad:"Efectos adversos de antimetabolitos antineoplasicos"
  },
  {
     id:"Y432",
     enfermedad:"Efectos adversos de productos naturales antineoplasicos"
  },
  {
     id:"Y433",
     enfermedad:"Efectos adversos de otras drogas antineoplasicas"
  },
  {
     id:"Y434",
     enfermedad:"Efectos adversos de agentes inmunosupresores"
  },
  {
     id:"Y435",
     enfermedad:"Efectos adversos de agentes acidificantes y alcalinizantes"
  },
  {
     id:"Y436",
     enfermedad:"Efectos adversos de enzimas no clasificadas en otra parte"
  },
  {
     id:"Y438",
     enfermedad:"Efectos adversos de otros agentes sistemicos primarios no clasificados en otra parte"
  },
  {
     id:"Y439",
     enfermedad:"Efectos adversos de agente sistemico primario no especificado"
  },
  {
     id:"Y44",
     enfermedad:"Efectos adversos de agent es que afectan primariamente los constituyentes de la sangre"
  },
  {
     id:"Y440",
     enfermedad:"Efectos adversos de preparaciones con hierro y otros preparados contra la anemia hipocromica"
  },
  {
     id:"Y441",
     enfermedad:"Efectos adversos de vitamina b12, acido folico y otros preparados contra la anemia megaloblastica"
  },
  {
     id:"Y442",
     enfermedad:"Efectos adversos de anticoagulantes"
  },
  {
     id:"Y443",
     enfermedad:"Efectos adversos de antagonistas de anticoagulantes, vitamina k y otros coagulantes"
  },
  {
     id:"Y444",
     enfermedad:"Efectos adversos de drogas antitromboticas [inhibidoras de la agregacion plaquetaria]"
  },
  {
     id:"Y445",
     enfermedad:"Efectos adversos de drogas tromboliticas"
  },
  {
     id:"Y446",
     enfermedad:"Efectos adversos de sangre natural y productos sanguíneos"
  },
  {
     id:"Y447",
     enfermedad:"Efectos adversos de los sustitutos del plasma"
  },
  {
     id:"Y449",
     enfermedad:"Efectos adversos de otros agentes que afectan los constituyentes de la sangre, y los no especificados"
  },
  {
     id:"Y45",
     enfermedad:"Efectos adversos de drogas analgesicas, antipireticas y antiinflamatorias"
  },
  {
     id:"Y450",
     enfermedad:"Efectos adversos de opiaceos y analgesicos relacionados"
  },
  {
     id:"Y451",
     enfermedad:"Efectos adversos de salicilatos"
  },
  {
     id:"Y452",
     enfermedad:"Efectos adversos de derivados del acido propionico"
  },
  {
     id:"Y453",
     enfermedad:"Efectos adversos de otras drogas antiinflamatorias no esteroides [daine]"
  },
  {
     id:"Y454",
     enfermedad:"Efectos adversos de los antirreumaticos"
  },
  {
     id:"Y455",
     enfermedad:"Efectos adversos de los derivados del 4"
  },
  {
     id:"Y458",
     enfermedad:"Efectos adversos de otros analgesicos y antipireticos"
  },
  {
     id:"Y459",
     enfermedad:"Efectos adversos de drogas analgesicas, antipireticas y antiinflamatorias no especificadas"
  },
  {
     id:"Y46",
     enfermedad:"Efectos adversos de drogas antiepilepticas y antiparkinsonianas"
  },
  {
     id:"Y460",
     enfermedad:"Efectos adversos de succinamidas"
  },
  {
     id:"Y461",
     enfermedad:"Efectos adversos de oxazolidinadionas"
  },
  {
     id:"Y462",
     enfermedad:"Efectos adversos de derivados de la hidantoina"
  },
  {
     id:"Y463",
     enfermedad:"Efectos adversos de desoxibarbituricos"
  },
  {
     id:"Y464",
     enfermedad:"Efectos adversos de iminoestilbenos"
  },
  {
     id:"Y465",
     enfermedad:"Efectos adversos del acido valproico"
  },
  {
     id:"Y466",
     enfermedad:"Efectos adversos de otros antiepilepticos, y los no especificados"
  },
  {
     id:"Y467",
     enfermedad:"Efectos adversos de drogas antiparkinsonianas"
  },
  {
     id:"Y468",
     enfermedad:"Efectos adversos de drogas antiespasticas"
  },
  {
     id:"Y47",
     enfermedad:"Efectos adversos de drogas sedantes, hipnoticas y ansioliticas"
  },
  {
     id:"Y470",
     enfermedad:"Efectos adversos de barbituricos, no clasificados en otra parte"
  },
  {
     id:"Y471",
     enfermedad:"Efectos adversos de benzodiazepinas"
  },
  {
     id:"Y472",
     enfermedad:"Efectos adversos de derivados clorales"
  },
  {
     id:"Y473",
     enfermedad:"Efectos adversos de paraldehido"
  },
  {
     id:"Y474",
     enfermedad:"Efectos adversos de compuestos de bromo"
  },
  {
     id:"Y475",
     enfermedad:"Efectos adversos de mezclas sedantes e hipnoticas, no clasificadas en otra parte"
  },
  {
     id:"Y478",
     enfermedad:"Efectos adversos de otras drogas sedantes, hipnoticas y ansioliticas"
  },
  {
     id:"Y479",
     enfermedad:"Efectos adversos de drogas sedantes, hipnoticas y ansioliticas no especificadas"
  },
  {
     id:"Y48",
     enfermedad:"Efectos adversos de gases anestesicos y terapeuticos"
  },
  {
     id:"Y480",
     enfermedad:"Efectos adversos de gases anestesicos por inhalacion"
  },
  {
     id:"Y481",
     enfermedad:"Efectos adversos de gases anestesicos parenterales"
  },
  {
     id:"Y482",
     enfermedad:"Efectos adversos de otros gases anestesicos generales, y los no especificados"
  },
  {
     id:"Y483",
     enfermedad:"Efectos adversos de gases anestesicos locales"
  },
  {
     id:"Y484",
     enfermedad:"Efectos adversos de anestesicos no especificados"
  },
  {
     id:"Y485",
     enfermedad:"Efectos adversos de gases terapeuticos"
  },
  {
     id:"Y49",
     enfermedad:"Efectos adversos de drogas psicotropicas no clasificadas en otra parte"
  },
  {
     id:"Y490",
     enfermedad:"Efectos adversos de antidepresivos triciclicos y tetraciclicos"
  },
  {
     id:"Y491",
     enfermedad:"Efectos adversos de antidepresivos inhibidores de la monoaminooxidasa"
  },
  {
     id:"Y492",
     enfermedad:"Efectos adversos de otros antidepresivos y los no especificados"
  },
  {
     id:"Y493",
     enfermedad:"Efectos adversos de antipsicoticos y neurolepticos fenotiazinicos"
  },
  {
     id:"Y494",
     enfermedad:"Efectos adversos de neurolepticos de la butirofenona y tioxantina"
  },
  {
     id:"Y495",
     enfermedad:"Efectos adversos de otros antipsicoticos y neurolepticos"
  },
  {
     id:"Y496",
     enfermedad:"Efectos adversos de psicodislepticos [alucinogenos]"
  },
  {
     id:"Y497",
     enfermedad:"Efectos adversos de psicoestimulantes con abuso potencial"
  },
  {
     id:"Y498",
     enfermedad:"Efectos adversos de otras drogas psicotropicas, no clasificadas en otra parte"
  },
  {
     id:"Y499",
     enfermedad:"Efectos adversos de drogas psicotropicas no especificadas"
  },
  {
     id:"Y50",
     enfermedad:"Efectos adversos de estimulantes del sistema nervioso central, no clasificadas en otra parte"
  },
  {
     id:"Y500",
     enfermedad:"Efectos adversos de analepticos"
  },
  {
     id:"Y501",
     enfermedad:"Efectos adversos de antagonistas de opiaceos"
  },
  {
     id:"Y502",
     enfermedad:"Efectos adversos de metilxantinas, no clasificadas en otra parte"
  },
  {
     id:"Y508",
     enfermedad:"Efectos adversos de otros estimulantes del sistema nervioso central"
  },
  {
     id:"Y509",
     enfermedad:"Efectos adversos de estimulante no especificado del sistema nervioso central"
  },
  {
     id:"Y51",
     enfermedad:"Efectos adversos de drogas que afectan primariamente el sistema nervioso autonomo"
  },
  {
     id:"Y510",
     enfermedad:"Efectos adversos de agentes anticolinesterasa"
  },
  {
     id:"Y511",
     enfermedad:"Efectos adversos de otros parasimpaticomimeticos [colinergicos]"
  },
  {
     id:"Y512",
     enfermedad:"Efectos adversos de drogas bloqueadoras ganglionares, no clasificadas en otra parte"
  },
  {
     id:"Y513",
     enfermedad:"Efectos adversos de otros parasimpaticoliticos [anticolinergicos y antimuscarinicos] y espasmoliticos, no clasificados en otra parte"
  },
  {
     id:"Y514",
     enfermedad:"Efectos adversos de agonistas [estimulantes] predominantemente alfa"
  },
  {
     id:"Y515",
     enfermedad:"Efectos adversos de agonistas [estimulantes] predominantemente beta"
  },
  {
     id:"Y516",
     enfermedad:"Efectos adversos de antagonistas [bloqueadores] alfa"
  },
  {
     id:"Y517",
     enfermedad:"Efectos adversos de antagonistas [bloqueadores] beta"
  },
  {
     id:"Y518",
     enfermedad:"Efectos adversos de agentes bloqueadores neuro"
  },
  {
     id:"Y519",
     enfermedad:"Efectos adversos de otras drogas que afectan primariamente el sistema nervioso autonomo, y las no especificadas"
  },
  {
     id:"Y52",
     enfermedad:"Efectos adversos de agentes que afectan el sistema cardiovascular"
  },
  {
     id:"Y520",
     enfermedad:"Efectos adversos de glucosidos cardiotonicos y drogas de accion similar"
  },
  {
     id:"Y521",
     enfermedad:"Efectos adversos de bloqueadores del canal del calcio"
  },
  {
     id:"Y522",
     enfermedad:"Efectos adversos de otras drogas antiarritmicas, no clasificadas en otras partes"
  },
  {
     id:"Y523",
     enfermedad:"Efectos adversos de vasodilatadores coronarios, no clasificados en otras partes"
  },
  {
     id:"Y524",
     enfermedad:"Efectos adversos de inhibidores de la enzima convertidora de angiotensina"
  },
  {
     id:"Y525",
     enfermedad:"Efectos adversos de otras drogas antihipertensivas, no clasificadas en otra parte"
  },
  {
     id:"Y526",
     enfermedad:"Efectos adversos de drogas antihiperlipidemicas y antiarterioscleroticas"
  },
  {
     id:"Y527",
     enfermedad:"Efectos adversos de vasodilatadores perifericos"
  },
  {
     id:"Y528",
     enfermedad:"Efectos adversos de drogas antivaricosas, inclusive agentes esclerosantes"
  },
  {
     id:"Y529",
     enfermedad:"Efectos adversos de otros agentes que afectan primariamente el sistema cardiovascular, y los no especificados"
  },
  {
     id:"Y53",
     enfermedad:"Efectos adversos de agentes que afectan primariamente el sistema gastrointestinal"
  },
  {
     id:"Y530",
     enfermedad:"Efectos adversos de bloqueadores de los receptores h2 de histamina"
  },
  {
     id:"Y531",
     enfermedad:"Efectos adversos de otras drogas antiacidas e inhibidoras de la secrecion gastrica"
  },
  {
     id:"Y532",
     enfermedad:"Efectos adversos de laxantes estimulantes"
  },
  {
     id:"Y533",
     enfermedad:"Efectos adversos de laxantes salinos y osmoticos"
  },
  {
     id:"Y534",
     enfermedad:"Efectos adversos de otros laxantes"
  },
  {
     id:"Y535",
     enfermedad:"Efectos adversos de digestivos"
  },
  {
     id:"Y536",
     enfermedad:"Efectos adversos de drogas antidiarreicas"
  },
  {
     id:"Y537",
     enfermedad:"Efectos adversos de emeticos"
  },
  {
     id:"Y538",
     enfermedad:"Efectos adversos de otros agentes que afectan primariamente el sistema gastrointestinal"
  },
  {
     id:"Y539",
     enfermedad:"Efectos adversos de agentes que afectan primariamente el sistema gastrointestinal, no especificados"
  },
  {
     id:"Y54",
     enfermedad:"Efectos adversos de agentes que afectan primariamente el equilibrio hidrico y el metabolismo mineral y del acido urico"
  },
  {
     id:"Y540",
     enfermedad:"Efectos adversos de mineralocorticoides"
  },
  {
     id:"Y541",
     enfermedad:"Efectos adversos de los bloqueadores de mineralocorticoides [antagonistas de la aldosterona]"
  },
  {
     id:"Y542",
     enfermedad:"Efectos adversos de los inhibidores de la anhidrasa carbonica"
  },
  {
     id:"Y543",
     enfermedad:"Efectos adversos de los derivados de la benzotiadiazina"
  },
  {
     id:"Y544",
     enfermedad:"Efectos adversos de diureticos de asa [\"\"high"
  },
  {
     id:"Y545",
     enfermedad:"Efectos adversos de otros diureticos"
  },
  {
     id:"Y546",
     enfermedad:"Efectos adversos de agentes electroliticos, caloricos y del equilibrio hidrico"
  },
  {
     id:"Y547",
     enfermedad:"Efectos adversos de agentes que afectan la calcificacion"
  },
  {
     id:"Y548",
     enfermedad:"Efectos adversos de agentes que afectan el metabolismo del acido urico"
  },
  {
     id:"Y549",
     enfermedad:"Efectos adversos de sales minerales no clasificadas en otra parte"
  },
  {
     id:"Y55",
     enfermedad:"Efectos adversos de agentes topicos que actuan primariamente sobre los musculos lisos y estriados y sobre el sistema respiratorio"
  },
  {
     id:"Y550",
     enfermedad:"Efectos adversos de drogas oxitocicas"
  },
  {
     id:"Y551",
     enfermedad:"Efectos adversos de relajantes de los musculos estriados [agentes bloqueadores neuromusculares]"
  },
  {
     id:"Y552",
     enfermedad:"Efectos adversos de otros agentes que actuan primariamente sobre los musculos, y los no especificados"
  },
  {
     id:"Y553",
     enfermedad:"Efectos adversos de antitusigenos"
  },
  {
     id:"Y554",
     enfermedad:"Efectos adversos de expectorantes"
  },
  {
     id:"Y555",
     enfermedad:"Efectos adversos de drogas contra el resfriado comun"
  },
  {
     id:"Y556",
     enfermedad:"Efectos adversos de antiasmaticos, no clasificados en otra parte"
  },
  {
     id:"Y557",
     enfermedad:"Efectos adversos de otros agentes que actuan primariamente sobre el sistema respiratorio, y los no especificados"
  },
  {
     id:"Y56",
     enfermedad:"Efectos adversos de agentes topicos que afectan primariamente la piel y las membranas mucosas, y drogas oftalmologicas, otorrinolaringologicas y dentales"
  },
  {
     id:"Y560",
     enfermedad:"Efectos adversos de drogas antimicoticas, antiinfecciosas y antiinflamatorias de uso local, no clasificadas en otra parte"
  },
  {
     id:"Y561",
     enfermedad:"Efectos adversos de antipruriginosos"
  },
  {
     id:"Y562",
     enfermedad:"Efectos adversos de astringentes y detergentes locales"
  },
  {
     id:"Y563",
     enfermedad:"Efectos adversos de emolientes, demulcentes y protectores"
  },
  {
     id:"Y564",
     enfermedad:"Efectos adversos de drogas y preparados queratoliticos, queratoplasticos y otros para el tratamiento del cabello"
  },
  {
     id:"Y565",
     enfermedad:"Efectos adversos de drogas y preparados oftalmologicos"
  },
  {
     id:"Y566",
     enfermedad:"Efectos adversos de drogas y preparados otorrinolaringologicos"
  },
  {
     id:"Y567",
     enfermedad:"Efectos adversos de drogas dentales, de aplicacion topica"
  },
  {
     id:"Y568",
     enfermedad:"Efectos adversos de otros agentes topicos"
  },
  {
     id:"Y569",
     enfermedad:"Efectos adversos de otros agentes topicos no especificados"
  },
  {
     id:"Y57",
     enfermedad:"Efectos adversos de otras drogas y medicamentos, y no especificados"
  },
  {
     id:"Y570",
     enfermedad:"Efectos adversos de depresores del apetito [anorexicos]"
  },
  {
     id:"Y571",
     enfermedad:"Efectos adversos de drogas lipotropicas"
  },
  {
     id:"Y572",
     enfermedad:"Efectos adversos de antidotos y agentes quelantes, no clasificados en otra parte"
  },
  {
     id:"Y573",
     enfermedad:"Efectos adversos de disuasivos del alcohol"
  },
  {
     id:"Y574",
     enfermedad:"Efectos adversos de excipientes farmaceuticos"
  },
  {
     id:"Y575",
     enfermedad:"Efectos adversos de medios de contraste para rayos x"
  },
  {
     id:"Y576",
     enfermedad:"Efectos adversos de otros agentes diagnosticos"
  },
  {
     id:"Y577",
     enfermedad:"Efectos adversos de vitaminas, no clasificadas en otra parte"
  },
  {
     id:"Y578",
     enfermedad:"Efectos adversos de otras drogas y medicamentos"
  },
  {
     id:"Y579",
     enfermedad:"Efectos adversos de drogas y medicamentos no especificados"
  },
  {
     id:"Y58",
     enfermedad:"Efectos adversos de vacunas bacterianas"
  },
  {
     id:"Y580",
     enfermedad:"Efectos adversos de la vacuna bcg"
  },
  {
     id:"Y581",
     enfermedad:"Efectos adversos de la vacuna tifoidea y paratifoidea"
  },
  {
     id:"Y582",
     enfermedad:"Efectos adversos de la vacuna contra el colera"
  },
  {
     id:"Y583",
     enfermedad:"Efectos adversos de la vacuna contra la peste"
  },
  {
     id:"Y584",
     enfermedad:"Efectos adversos de la vacuna contra el tetanos"
  },
  {
     id:"Y585",
     enfermedad:"Efectos adversos de la vacuna contra la difteria"
  },
  {
     id:"Y586",
     enfermedad:"Efectos adversos de la vacuna contra la tos ferina, inclusive combinaciones con un componente pertusis"
  },
  {
     id:"Y588",
     enfermedad:"Efectos adversos de vacunas bacterianas mixtas, excepto combinaciones con un componente pertusis"
  },
  {
     id:"Y589",
     enfermedad:"Efectos adversos de otras vacunas bacterianas, y las no especificadas"
  },
  {
     id:"Y59",
     enfermedad:"Efectos adversos de otras vacunas y sustancias biologicas y las no especificadas"
  },
  {
     id:"Y590",
     enfermedad:"Efectos adversos de vacunas virales"
  },
  {
     id:"Y591",
     enfermedad:"Efectos adversos de vacunas contra rickettsias"
  },
  {
     id:"Y592",
     enfermedad:"Efectos adversos de vacunas antiprotozoarias"
  },
  {
     id:"Y593",
     enfermedad:"Efectos adversos de la inmunoglobulina"
  },
  {
     id:"Y598",
     enfermedad:"Efectos adversos de otras vacunas y sustancias biologicas especificadas"
  },
  {
     id:"Y599",
     enfermedad:"Efectos adversos de otras vacunas o sustancias biologicas no especificadas"
  },
  {
     id:"Y60",
     enfermedad:"Corte, puncion, perforacion o hemorragia no intencional durante la atención medica y quirurgica"
  },
  {
     id:"Y600",
     enfermedad:"Incidente durante operación quirurgica"
  },
  {
     id:"Y601",
     enfermedad:"Incidente durante infusion o transfusion"
  },
  {
     id:"Y602",
     enfermedad:"Incidente durante dialisis renal u otra perfusion"
  },
  {
     id:"Y603",
     enfermedad:"Incidente durante inyeccion o inmunizacion"
  },
  {
     id:"Y604",
     enfermedad:"Incidente durante examen endoscopico"
  },
  {
     id:"Y605",
     enfermedad:"Incidente durante cateterizacion cardiaca"
  },
  {
     id:"Y606",
     enfermedad:"Incidente durante aspiracion, puncion y otra cateterizacion"
  },
  {
     id:"Y607",
     enfermedad:"Incidente durante administracion de enema"
  },
  {
     id:"Y608",
     enfermedad:"Incidente durante otras atenciones medicas y quirurgicas"
  },
  {
     id:"Y609",
     enfermedad:"Incidente durante atención medica y quirurgica no especificada"
  },
  {
     id:"Y61",
     enfermedad:"Objeto extraño dejado accidentalmente en el cuerpo durante la atención medica y quirurgica"
  },
  {
     id:"Y610",
     enfermedad:"Objeto extraño dejado accidentalmente en el cuerpo durante operación quirurgica"
  },
  {
     id:"Y611",
     enfermedad:"Objeto extraño dejado accidentalmente en el cuerpo durante infusion o transfusion"
  },
  {
     id:"Y612",
     enfermedad:"Objeto extraño dejado accidentalmente en el cuerpo durante dialisis renal u otra perfusion"
  },
  {
     id:"Y613",
     enfermedad:"Objeto extraño dejado accidentalmente en el cuerpo durante inyeccion o inmunizacion"
  },
  {
     id:"Y614",
     enfermedad:"Objeto extraño dejado accidentalmente en el cuerpo durante examen endoscopico"
  },
  {
     id:"Y615",
     enfermedad:"Objeto extraño dejado accidentalmente en el cuerpo durante cateterizacion cardiaca"
  },
  {
     id:"Y616",
     enfermedad:"Objeto extraño dejado accidentalmente en el cuerpo durante aspiracion, puncion y otra cateterizacion"
  },
  {
     id:"Y617",
     enfermedad:"Objeto extraño dejado accidentalmente en el cuerpo durante remocion de cateter o taponamiento"
  },
  {
     id:"Y618",
     enfermedad:"Objeto extraño dejado accidentalmente en el cuerpo durante otras atenciones medicas y quirurgicas"
  },
  {
     id:"Y619",
     enfermedad:"Objeto extraño dejado accidentalmente en el cuerpo durante atención medica y quirurgica no especificada"
  },
  {
     id:"Y62",
     enfermedad:"Fallas en la esterilizacion durante la atención medica y quirurgica"
  },
  {
     id:"Y620",
     enfermedad:"Fallas en la esterilizacion durante operación quirurgica"
  },
  {
     id:"Y621",
     enfermedad:"Fallas en la esterilizacion durante infusion o transfusion"
  },
  {
     id:"Y622",
     enfermedad:"Fallas en la esterilizacion durante dialisis renal u otra perfusion"
  },
  {
     id:"Y623",
     enfermedad:"Fallas en la esterilizacion durante inyeccion o inmunizacion"
  },
  {
     id:"Y624",
     enfermedad:"Fallas en la esterilizacion durante examen endoscopico"
  },
  {
     id:"Y625",
     enfermedad:"Fallas en la esterilizacion durante cateterizacion cardiaca"
  },
  {
     id:"Y626",
     enfermedad:"Fallas en la esterilizacion durante aspiracion, puncion y otra cateterizacion"
  },
  {
     id:"Y628",
     enfermedad:"Fallas en la esterilizacion durante otras atenciones medicas y quirurgicas"
  },
  {
     id:"Y629",
     enfermedad:"Fallas en la esterilizacion durante atención medica y quirurgica no especificada"
  },
  {
     id:"Y63",
     enfermedad:"Falla en dosificacion durante la atención medica y quirurgica"
  },
  {
     id:"Y630",
     enfermedad:"Excesiva cantidad de sangre u otro liquido administrado durante una infusion o transfusion"
  },
  {
     id:"Y631",
     enfermedad:"Dilucion incorrecta de liquido durante una infusion"
  },
  {
     id:"Y632",
     enfermedad:"Sobredosis de radiacion administrada durante terapia"
  },
  {
     id:"Y633",
     enfermedad:"Exposicion inadvertida del paciente a radiacion durante la atención medica"
  },
  {
     id:"Y634",
     enfermedad:"Falla en la dosificacion en electrochoque o en choque insulinico"
  },
  {
     id:"Y635",
     enfermedad:"Falla en el control de la temperatura, en taponamientos y aplicaciones locales"
  },
  {
     id:"Y636",
     enfermedad:"No administracion de drogas, medicamentos o sustancias biologicas necesarias"
  },
  {
     id:"Y638",
     enfermedad:"Falla en la dosificacion durante otras atenciones medicas y quirurgicas"
  },
  {
     id:"Y639",
     enfermedad:"Falla en la dosificacion durante atención medica y quirurgica no especificada"
  },
  {
     id:"Y64",
     enfermedad:"Medicamentos y sustancias biologicas contaminados"
  },
  {
     id:"Y640",
     enfermedad:"Medicamento o sustancia biologica contaminado en infusion o transfusion"
  },
  {
     id:"Y641",
     enfermedad:"Medicamento o sustancia biologica contaminado, inyectado o usado para inmunizacion"
  },
  {
     id:"Y648",
     enfermedad:"Medicamento o sustancia biologica contaminado, administrado por otros medios"
  },
  {
     id:"Y649",
     enfermedad:"Medicamento o sustancia biologica contaminado, administrado por medios no especificados"
  },
  {
     id:"Y65",
     enfermedad:"Otros incidentes durante la atención medica y quirurgica"
  },
  {
     id:"Y650",
     enfermedad:"Sangre incompatible usada en transfusion"
  },
  {
     id:"Y651",
     enfermedad:"Liquido erroneo usado en infusion"
  },
  {
     id:"Y652",
     enfermedad:"Falla en la sutura o ligadura durante operación quirurgica"
  },
  {
     id:"Y653",
     enfermedad:"Tubo endotraqueal colocado erroneamente durante procedimiento anestesico"
  },
  {
     id:"Y654",
     enfermedad:"Falla en la introduccion o remocion de otro tubo o instrumento"
  },
  {
     id:"Y655",
     enfermedad:"Realizacion de una operación inadecuada"
  },
  {
     id:"Y658",
     enfermedad:"Otros incidentes especificados durante la atención medica y quirurgica"
  },
  {
     id:"Y66",
     enfermedad:"No administracion de la atención medica y quirurgica"
  },
  {
     id:"Y69",
     enfermedad:"Incidentes no especificados durante la atención medica y quirurgica"
  },
  {
     id:"Y70",
     enfermedad:"Dispositivos de anestesiologia, asociados con incidentes adversos"
  },
  {
     id:"Y700",
     enfermedad:"Dispositivos de anestesiologia asociados con incidentes adversos: dispositivos de diagnostico y monitoreo"
  },
  {
     id:"Y701",
     enfermedad:"Dispositivos de anestesiologia asociados con incidentes adversos: dispositivos terapeuticos (no quirúrgicos) y de rehabilitacion"
  },
  {
     id:"Y702",
     enfermedad:"Dispositivos de anestesiologia asociados con incidentes adversos: dispositivos protesicos y otros implantes, materiales y accesorios"
  },
  {
     id:"Y703",
     enfermedad:"Dispositivos de anestesiologia asociados con incidentes adversos: instrumentos quirúrgicos, dispositivos y materiales (inclusive suturas)"
  },
  {
     id:"Y708",
     enfermedad:"Dispositivos de anestesiologia asociados con incidentes adversos: dispositivos diversos, no clasificados en otra parte"
  },
  {
     id:"Y71",
     enfermedad:"Dispositivos cardiovasculares, asociados con incidentes adversos"
  },
  {
     id:"Y710",
     enfermedad:"Dispositivos cardiovasculares asociados con incidentes adversos: dispositivos de diagnostico y monitoreo"
  },
  {
     id:"Y711",
     enfermedad:"Dispositivos cardiovasculares asociados con incidentes adversos: dispositivos terapeuticos (no quirúrgicos) y de rehabilitacion"
  },
  {
     id:"Y712",
     enfermedad:"Dispositivos cardiovasculares asociados con incidentes adversos: dispositivos protesicos y otros implantes, materiales y accesorios"
  },
  {
     id:"Y713",
     enfermedad:"Dispositivos cardiovasculares asociados con incidentes adversos: instrumentos quirúrgicos, dispositivos y materiales (inclusive suturas)"
  },
  {
     id:"Y718",
     enfermedad:"Dispositivos cardiovasculares asociados con incidentes adversos: dispositivos diversos, no clasificados en otra parte"
  },
  {
     id:"Y72",
     enfermedad:"Dispositivos otorrinolaringologicos, asociados con incidentes adversos"
  },
  {
     id:"Y720",
     enfermedad:"Dispositivos otorrinolaringologicos asociados con incidentes adversos: dispositivos de diagnostico y monitoreo"
  },
  {
     id:"Y721",
     enfermedad:"Dispositivos otorrinolaringologicos asociados con incidentes adversos: dispositivos terapeuticos (no quirúrgicos) y de rehabilitacion"
  },
  {
     id:"Y722",
     enfermedad:"Dispositivos otorrinolaringologicos asociados con incidentes adversos: dispositivos protesicos y otros implantes, materiales y accesorios"
  },
  {
     id:"Y723",
     enfermedad:"Dispositivos otorrinolaringologicos asociados con incidentes adversos: instrumentos quirúrgicos, dispositivos y materiales (inclusive suturas)"
  },
  {
     id:"Y728",
     enfermedad:"Dispositivos otorrinolaringologicos asociados con incidentes adversos: dispositivos diversos, no clasificados en otra parte"
  },
  {
     id:"Y73",
     enfermedad:"Dispositivos de gastroenterologia y urologia, asociados con incidentes adversos"
  },
  {
     id:"Y730",
     enfermedad:"Dispositivos de gastroenterologia y urologia asociados con incidentes adversos: dispositivos de diagnostico y monitoreo"
  },
  {
     id:"Y731",
     enfermedad:"Dispositivos de gastroenterologia y urologia asociados con incidentes adversos: dispositivos terapeuticos (no quirúrgicos) y de rehabilitacion"
  },
  {
     id:"Y732",
     enfermedad:"Dispositivos de gastroenterologia y urologia asociados con incidentes adversos: dispositivos protesicos y otros implantes, materiales y accesorios"
  },
  {
     id:"Y733",
     enfermedad:"Dispositivos de gastroenterologia y urologia asociados con incidentes adversos: instrumentos quirúrgicos, dispositivos y materiales (inclusive suturas)"
  },
  {
     id:"Y738",
     enfermedad:"Dispositivos de gastroenterologia y urologia asociados con incidentes adversos: dispositivos diversos, no clasificados en otra parte"
  },
  {
     id:"Y74",
     enfermedad:"Dispositivos para uso hospitalario general y personal, asociados con incidentes adversos"
  },
  {
     id:"Y740",
     enfermedad:"Dispositivos para uso hospitalario general y personal asociados con incidentes adversos: dispositivos de diagnostico y monitoreo"
  },
  {
     id:"Y741",
     enfermedad:"Dispositivos para uso hospitalario general y personal asociados con incidentes adversos: dispositivos terapeuticos (no quirúrgicos) y de rehabilitacion"
  },
  {
     id:"Y742",
     enfermedad:"Dispositivos para uso hospitalario general y personal asociados con incidentes adversos: dispositivos protesicos y otros implantes, materiales y accesorios"
  },
  {
     id:"Y743",
     enfermedad:"Dispositivos para uso hospitalario general y personal asociados con incidentes adversos: instrumentos quirúrgicos, dispositivos y materiales (inclusive suturas)"
  },
  {
     id:"Y748",
     enfermedad:"Dispositivos para uso hospitalario general y personal asociados con incidentes adversos: dispositivos diversos, no clasificados en otra parte"
  },
  {
     id:"Y75",
     enfermedad:"Dispositivos os neurologicos asociados con incidentes adversos"
  },
  {
     id:"Y750",
     enfermedad:"Dispositivos neurologicos asociados con incidentes adversos: dispositivos de diagnostico y monitoreo"
  },
  {
     id:"Y751",
     enfermedad:"Dispositivos neurologicos asociados con incidentes adversos: dispositivos terapeuticos (no quirúrgicos) y de rehabilitacion"
  },
  {
     id:"Y752",
     enfermedad:"Dispositivos neurologicos asociados con incidentes adversos: dispositivos protesicos y otros implantes, materiales y accesorios"
  },
  {
     id:"Y753",
     enfermedad:"Dispositivos neurologicos asociados con incidentes adversos: instrumentos quirúrgicos, dispositivos y materiales (inclusive suturas)"
  },
  {
     id:"Y758",
     enfermedad:"Dispositivos neurologicos asociados con incidentes adversos: dispositivos diversos, no clasificados en otra parte"
  },
  {
     id:"Y76",
     enfermedad:"Dispositivos ginecologicos y obstetricos, asociados con incidentes adversos"
  },
  {
     id:"Y760",
     enfermedad:"Dispositivos ginecologicos y obstetricos asociados con incidentes adversos: dispositivos de diagnostico y monitoreo"
  },
  {
     id:"Y761",
     enfermedad:"Dispositivos ginecologicos y obstetricos asociados con incidentes adversos: dispositivos terapeuticos (no quirúrgicos) y de rehabilitacion"
  },
  {
     id:"Y762",
     enfermedad:"Dispositivos ginecologicos y obstetricos asociados con incidentes adversos: dispositivos protesicos y otros implantes, materiales y accesorios"
  },
  {
     id:"Y763",
     enfermedad:"Dispositivos ginecologicos y obstetricos asociados con incidentes adversos: instrumentos quirúrgicos, dispositivos y materiales (inclusive suturas)"
  },
  {
     id:"Y768",
     enfermedad:"Dispositivos ginecologicos y obstetricos asociados con incidentes adversos: dispositivos diversos, no clasificados en otra parte"
  },
  {
     id:"Y77",
     enfermedad:"Dispositivos oftalmicos, asociados con incidentes adversos"
  },
  {
     id:"Y770",
     enfermedad:"Dispositivos oftalmicos asociados con incidentes adversos: dispositivos de diagnostico y monitoreo"
  },
  {
     id:"Y771",
     enfermedad:"Dispositivos oftalmicos asociados con incidentes adversos: dispositivos terapeuticos (no quirúrgicos) y de rehabilitacion"
  },
  {
     id:"Y772",
     enfermedad:"Dispositivos oftalmicos asociados con incidentes adversos: dispositivos protesicos y otros implantes, materiales y accesorios"
  },
  {
     id:"Y773",
     enfermedad:"Dispositivos oftalmicos asociados con incidentes adversos: instrumentos quirúrgicos, dispositivos y materiales (inclusive suturas)"
  },
  {
     id:"Y778",
     enfermedad:"Dispositivos oftalmicos asociados con incidentes adversos: dispositivos diversos, no clasificados en otra parte"
  },
  {
     id:"Y78",
     enfermedad:"Aparatos radiologicos, asociados con incidentes adversos"
  },
  {
     id:"Y780",
     enfermedad:"Aparatos radiologicos asociados con incidentes adversos: dispositivos de diagnostico y monitoreo"
  },
  {
     id:"Y781",
     enfermedad:"Aparatos radiologicos asociados con incidentes adversos: dispositivos terapeuticos (no quirúrgicos) y de rehabilitacion"
  },
  {
     id:"Y782",
     enfermedad:"Aparatos radiologicos asociados con incidentes adversos: dispositivos protesicos y otros implantes, materiales y accesorios"
  },
  {
     id:"Y783",
     enfermedad:"Aparatos radiologicos asociados con incidentes adversos: instrumentos quirúrgicos, dispositivos y materiales (inclusive suturas)"
  },
  {
     id:"Y788",
     enfermedad:"Aparatos radiologicos asociados con incidentes adversos: dispositivos diversos, no clasificados en otra parte"
  },
  {
     id:"Y79",
     enfermedad:"Dispositivos ortopedicos, asociados con incidentes adversos"
  },
  {
     id:"Y790",
     enfermedad:"Dispositivos ortopedicos asociados con incidentes adversos: dispositivos de diagnostico y monitoreo"
  },
  {
     id:"Y791",
     enfermedad:"Dispositivos ortopedicos asociados con incidentes adversos: dispositivos terapeuticos (no quirúrgicos) y de rehabilitacion"
  },
  {
     id:"Y792",
     enfermedad:"Dispositivos ortopedicos asociados con incidentes adversos: dispositivos protesicos y otros implantes, materiales y accesorios"
  },
  {
     id:"Y793",
     enfermedad:"Dispositivos ortopedicos asociados con incidentes adversos: instrumentos quirúrgicos, dispositivos y materiales (inclusive suturas)"
  },
  {
     id:"Y798",
     enfermedad:"Dispositivos ortopedicos asociados con incidentes adversos: dispositivos diversos, no clasificados en otra parte"
  },
  {
     id:"Y80",
     enfermedad:"Aparatos de medicina fisica, asociados con incidentes adversos"
  },
  {
     id:"Y800",
     enfermedad:"Aparatos de medicina fisica asociados con incidentes adversos: dispositivos de diagnostico y monitoreo"
  },
  {
     id:"Y801",
     enfermedad:"Aparatos de medicina fisica asociados con incidentes adversos: dispositivos terapeuticos (no quirúrgicos) y de rehabilitacion"
  },
  {
     id:"Y802",
     enfermedad:"Aparatos de medicina fisica asociados con incidentes adversos: dispositivos protesicos y otros implantes, materiales y accesorios"
  },
  {
     id:"Y803",
     enfermedad:"Aparatos de medicina fisica asociados con incidentes adversos: instrumentos quirúrgicos, dispositivos y materiales (inclusive suturas)"
  },
  {
     id:"Y808",
     enfermedad:"Aparatos de medicina fisica asociados con incidentes adversos: dispositivos diversos, no clasificados en otra parte"
  },
  {
     id:"Y81",
     enfermedad:"Dispositivos de cirugia general y plastica, asociados con incidentes adversos"
  },
  {
     id:"Y810",
     enfermedad:"Dispositivos de cirugia general y plastica asociados con incidentes adversos: dispositivos de diagnostico y monitoreo"
  },
  {
     id:"Y811",
     enfermedad:"Dispositivos de cirugia general y plastica asociados con incidentes adversos: dispositivos terapeuticos (no quirúrgicos) y de rehabilitacion"
  },
  {
     id:"Y812",
     enfermedad:"Dispositivos de cirugia general y plastica asociados con incidentes adversos: dispositivos protesicos y otros implantes, materiales y accesorios"
  },
  {
     id:"Y813",
     enfermedad:"Dispositivos de cirugia general y plastica asociados con incidentes adversos: instrumentos quirúrgicos, dispositivos y materiales (inclusive suturas)"
  },
  {
     id:"Y818",
     enfermedad:"Dispositivos de cirugia general y plastica asociados con incidentes adversos: dispositivos diversos, no clasificados en otra parte"
  },
  {
     id:"Y82",
     enfermedad:"Otros dispositivos medicos y los no especificados, asociados con incidentes adversos"
  },
  {
     id:"Y820",
     enfermedad:"Otros dispositivos medicos, y los no especificados, asociados con incidentes adversos: dispositivos de diagnostico y monitoreo"
  },
  {
     id:"Y821",
     enfermedad:"Otros dispositivos medicos, y los no especificados, asociados con incidentes adversos: dispositivos terapeuticos (no quirúrgicos) y de rehabilitacion"
  },
  {
     id:"Y822",
     enfermedad:"Otros dispositivos medicos, y los no especificados, asociados con incidentes adversos: dispositivos protesicos y otros implantes, materiales y accesorios"
  },
  {
     id:"Y823",
     enfermedad:"Otros dispositivos medicos, y los no especificados, asociados con incidentes adversos: instrumentos quirúrgicos, dispositivos y materiales (inclusive suturas)"
  },
  {
     id:"Y828",
     enfermedad:"Otros dispositivos medicos, y los no especificados, asociados con incidentes adversos: dispositivos diversos, no clasificados en otra parte"
  },
  {
     id:"Y83",
     enfermedad:"Cirugia y otros procedimientos quirúrgicos, como la causa de reaccion anormal del paciente o de complicacion posterior, sin mencion de incidente en el momento de efectuar el procedimiento"
  },
  {
     id:"Y830",
     enfermedad:"Operación quirurgica con trasplante de un organo completo"
  },
  {
     id:"Y831",
     enfermedad:"Operación quirurgica con implante de un dispositivo artificial interno"
  },
  {
     id:"Y832",
     enfermedad:"Operación quirurgica con anastomosis, derivacion o injerto"
  },
  {
     id:"Y833",
     enfermedad:"Operación quirurgica con formacion de estoma externo"
  },
  {
     id:"Y834",
     enfermedad:"Otra cirugia reconstructiva"
  },
  {
     id:"Y835",
     enfermedad:"Amputacion de miembro(s)"
  },
  {
     id:"Y836",
     enfermedad:"Remocion de otro organo (parcial) (total)"
  },
  {
     id:"Y838",
     enfermedad:"Otros procedimientos quirúrgicos"
  },
  {
     id:"Y839",
     enfermedad:"Procedimiento quirúrgico no especificado"
  },
  {
     id:"Y84",
     enfermedad:"Otros procedimientos medicos, como la causa de reaccion anormal del paciente o de complicacion posterior, sin mencion de incidente en el momento de efectuar le procedimiento"
  },
  {
     id:"Y840",
     enfermedad:"Cateterizacion cardiaca"
  },
  {
     id:"Y841",
     enfermedad:"Dialisis renal"
  },
  {
     id:"Y842",
     enfermedad:"Procedimiento radiologico y radioterapia"
  },
  {
     id:"Y843",
     enfermedad:"Terapia por choque"
  },
  {
     id:"Y844",
     enfermedad:"Aspiracion de liquidos"
  },
  {
     id:"Y845",
     enfermedad:"Insercion de sonda gastrica o duodenal"
  },
  {
     id:"Y846",
     enfermedad:"Cateterizacion urinaria"
  },
  {
     id:"Y847",
     enfermedad:"Muestra de sangre"
  },
  {
     id:"Y848",
     enfermedad:"Otros procedimientos medicos"
  },
  {
     id:"Y849",
     enfermedad:"Procedimiento medico no especificado"
  },
  {
     id:"Y85",
     enfermedad:"Secuelas de accidente de transporte"
  },
  {
     id:"Y850",
     enfermedad:"Secuelas de accidente de vehiculo de motor"
  },
  {
     id:"Y859",
     enfermedad:"Secuelas de otros accidentes de transporte, y los no especificados"
  },
  {
     id:"Y86",
     enfermedad:"Secuelas de otros accidentes"
  },
  {
     id:"Y87",
     enfermedad:"Secuelas de lesiones autoinfligidas intencionalmente, agresiones y eventos de intencion no determinada"
  },
  {
     id:"Y870",
     enfermedad:"Secuelas de lesiones autoinfligidas"
  },
  {
     id:"Y871",
     enfermedad:"Secuelas de agresiones"
  },
  {
     id:"Y872",
     enfermedad:"Secuelas de eventos de intencion no determinada"
  },
  {
     id:"Y88",
     enfermedad:"Secuelas con atención medica y quirurgica como causa externa"
  },
  {
     id:"Y880",
     enfermedad:"Secuelas de efectos adversos causados por drogas, medicamentos y sustancias biologicas en su uso terapeutico"
  },
  {
     id:"Y881",
     enfermedad:"Secuelas de incidentes ocurridos al paciente durante procedimientos medicos y quirúrgicos"
  },
  {
     id:"Y882",
     enfermedad:"Secuelas de incidentes adversos asociados con dispositivos medicos en uso diagnostico y terapeutico"
  },
  {
     id:"Y883",
     enfermedad:"Secuelas de procedimientos medicos y quirúrgicos como la causa de reaccion anormal del paciente o de complicacion posterior, sin mencion de incidente en el momento de efectuar el procedimiento"
  },
  {
     id:"Y89",
     enfermedad:"Secuelas de otras causas externas"
  },
  {
     id:"Y890",
     enfermedad:"Secuelas de intervencion legal"
  },
  {
     id:"Y891",
     enfermedad:"Secuelas de operaciones de guerra"
  },
  {
     id:"Y899",
     enfermedad:"Secuelas de causa externa no especificada"
  },
  {
     id:"Y90",
     enfermedad:"Evidencia alcoholismo determinada por el nivel de alcohol en la sangre"
  },
  {
     id:"Y900",
     enfermedad:"Nivel de alcohol en la sangre menor de 20 mg/100 ml"
  },
  {
     id:"Y901",
     enfermedad:"Nivel de alcohol en la sangre de 20 a 39 mg/100 ml"
  },
  {
     id:"Y902",
     enfermedad:"Nivel de alcohol en la sangre de 40 a 59 mg/100 ml"
  },
  {
     id:"Y903",
     enfermedad:"Nivel de alcohol en la sangre de 60 a 79 mg/100 ml"
  },
  {
     id:"Y904",
     enfermedad:"Nivel de alcohol en la sangre de 80 a 99 mg/100 ml"
  },
  {
     id:"Y905",
     enfermedad:"Nivel de alcohol en la sangre de 100 a 119 mg/100 ml"
  },
  {
     id:"Y906",
     enfermedad:"Nivel de alcohol en la sangre de 120 a 199 mg/100 ml"
  },
  {
     id:"Y907",
     enfermedad:"Nivel de alcohol en la sangre de 200 a 239 mg/100 ml"
  },
  {
     id:"Y908",
     enfermedad:"Nivel de alcohol en la sangre de 240 mg/100 ml o mas"
  },
  {
     id:"Y909",
     enfermedad:"Presencia de alcohol en la sangre, nivel no especificado"
  },
  {
     id:"Y91",
     enfermedad:"Evidencia de alcoholismo determinada por el nivel de intoxicacion"
  },
  {
     id:"Y910",
     enfermedad:"Intoxicacion alcoholica leve"
  },
  {
     id:"Y911",
     enfermedad:"Intoxicacion alcoholica moderada"
  },
  {
     id:"Y912",
     enfermedad:"Intoxicacion alcoholica severa"
  },
  {
     id:"Y913",
     enfermedad:"Intoxicacion alcoholica muy severa"
  },
  {
     id:"Y919",
     enfermedad:"Alcoholismo, nivel de intoxicacion no especificado"
  },
  {
     id:"Y95",
     enfermedad:"Afeccion nosocomial"
  },
  {
     id:"Y96",
     enfermedad:"Afeccion relacionada con el trabajo"
  },
  {
     id:"Y97",
     enfermedad:"Afeccion relacionada con la contaminacion ambiental"
  },
  {
     id:"Y98",
     enfermedad:"Afeccion relacionada con el estilo de vida"
  },
  {
     id:"Z00",
     enfermedad:"Examen general e investigacion de personas sin quejas o sin diagnostico informado"
  },
  {
     id:"Z000",
     enfermedad:"Examen medico general"
  },
  {
     id:"Z001",
     enfermedad:"Control de salud de rutina del niño"
  },
  {
     id:"Z002",
     enfermedad:"Examen durante el periodo de crecimiento rapido en la infancia"
  },
  {
     id:"Z003",
     enfermedad:"Examen del estado de desarrollo del adolescente"
  },
  {
     id:"Z004",
     enfermedad:"Examen psiquiatrico general, no clasificado en otra parte"
  },
  {
     id:"Z005",
     enfermedad:"Examen de donante potencial de organo o tejido"
  },
  {
     id:"Z006",
     enfermedad:"Examen para comparación y control normales en programa de investigacion clinica"
  },
  {
     id:"Z008",
     enfermedad:"Otros examenes generales"
  },
  {
     id:"Z01",
     enfermedad:"Otros examenes especiales personas sin quejas o sin diagnostico informado"
  },
  {
     id:"Z010",
     enfermedad:"Examen de ojos y de la vision"
  },
  {
     id:"Z011",
     enfermedad:"Examen de oidos y de la audicion"
  },
  {
     id:"Z012",
     enfermedad:"Examen odontologico"
  },
  {
     id:"Z013",
     enfermedad:"Examen de la presion sanguinea"
  },
  {
     id:"Z014",
     enfermedad:"Examen ginecologico (general) (de rutina)"
  },
  {
     id:"Z015",
     enfermedad:"Pruebas de sensibilizacion y diagnostico cutaneo"
  },
  {
     id:"Z016",
     enfermedad:"Examen radiologico, no clasificado en otra parte"
  },
  {
     id:"Z017",
     enfermedad:"Examen de laboratorio"
  },
  {
     id:"Z018",
     enfermedad:"Otros examenes especiales especificados"
  },
  {
     id:"Z019",
     enfermedad:"Examen especial no especificado"
  },
  {
     id:"Z02",
     enfermedad:"Examenes y contactos fines administrativos"
  },
  {
     id:"Z020",
     enfermedad:"Examen para admision a instituciones educativas"
  },
  {
     id:"Z021",
     enfermedad:"Examen preempleo"
  },
  {
     id:"Z022",
     enfermedad:"Examen para admision a instituciones residenciales"
  },
  {
     id:"Z023",
     enfermedad:"Examen para reclutamiento en las fuerzas armadas"
  },
  {
     id:"Z024",
     enfermedad:"Examen para obtencion de licencia de conducir"
  },
  {
     id:"Z025",
     enfermedad:"Examen para participacion en competencias deportivas"
  },
  {
     id:"Z026",
     enfermedad:"Examen para fines de seguros"
  },
  {
     id:"Z027",
     enfermedad:"Extension de certificado medico"
  },
  {
     id:"Z028",
     enfermedad:"Otros examenes para fines administrativos"
  },
  {
     id:"Z029",
     enfermedad:"Examen para fines administrativos, no especificado"
  },
  {
     id:"Z03",
     enfermedad:"Observacion y evaluacion medicas por sospecha de enfermedades y afecciones"
  },
  {
     id:"Z030",
     enfermedad:"Observacion por sospecha de tuberculosis"
  },
  {
     id:"Z031",
     enfermedad:"Observacion por sospecha de tumor maligno"
  },
  {
     id:"Z032",
     enfermedad:"Observacion por sospecha de trastorno mental y del comportamiento"
  },
  {
     id:"Z033",
     enfermedad:"Observacion por sospecha de trastorno del sistema nervioso"
  },
  {
     id:"Z034",
     enfermedad:"Observacion por sospecha de infarto de miocardio"
  },
  {
     id:"Z035",
     enfermedad:"Observacion por sospecha de otras enfermedades cardiovasculares"
  },
  {
     id:"Z036",
     enfermedad:"Observacion por sospecha de efectos toxicos de sustancias ingeridas"
  },
  {
     id:"Z038",
     enfermedad:"Observacion por sospecha de otras enfermedades y afecciones"
  },
  {
     id:"Z039",
     enfermedad:"Observacion por sospecha de enfermedad o afeccion no especificada"
  },
  {
     id:"Z04",
     enfermedad:"Examen y observacion por otras razones"
  },
  {
     id:"Z040",
     enfermedad:"Prueba de alcohol o drogas en la sangre"
  },
  {
     id:"Z041",
     enfermedad:"Examen y observacion consecutivos a accidente de transporte"
  },
  {
     id:"Z042",
     enfermedad:"Examen y observacion consecutivos a accidente de trabajo"
  },
  {
     id:"Z043",
     enfermedad:"Examen y observacion consecutivos a otro accidente"
  },
  {
     id:"Z044",
     enfermedad:"Examen y observacion consecutivos a denuncia de violacion y seduccion"
  },
  {
     id:"Z045",
     enfermedad:"Examen y observacion consecutivos a otra lesion infligida"
  },
  {
     id:"Z046",
     enfermedad:"Examen psiquiatrico general, solicitado por una autoridad"
  },
  {
     id:"Z048",
     enfermedad:"Examen y observacion por otras razones especificadas"
  },
  {
     id:"Z049",
     enfermedad:"Examen y observacion por razones no especificadas"
  },
  {
     id:"Z08",
     enfermedad:"Examen de seguimiento consecutivo a tratamiento por tumor maligno"
  },
  {
     id:"Z080",
     enfermedad:"Examen de seguimiento consecutivo a cirugia por tumor maligno"
  },
  {
     id:"Z081",
     enfermedad:"Examen de seguimiento consecutivo a radioterapia por tumor maligno"
  },
  {
     id:"Z082",
     enfermedad:"Examen de seguimiento consecutivo a quimioterapia por tumor maligno"
  },
  {
     id:"Z087",
     enfermedad:"Examen de seguimiento consecutivo a tratamiento combinado por tumor maligno"
  },
  {
     id:"Z088",
     enfermedad:"Examen de seguimiento consecutivo a otro tratamiento por tumor maligno"
  },
  {
     id:"Z089",
     enfermedad:"Examen de seguimiento consecutivo a otro tratamiento no especificado por tumor maligno"
  },
  {
     id:"Z09",
     enfermedad:"Examen de seguimiento consecutivo a tratamiento por otras afecciones"
  },
  {
     id:"Z090",
     enfermedad:"Examen de seguimiento consecutivo a cirugia por otras afecciones"
  },
  {
     id:"Z091",
     enfermedad:"Examen de seguimiento consecutivo a radioterapia por otras afecciones"
  },
  {
     id:"Z092",
     enfermedad:"Examen de seguimiento consecutivo a quimioterapia por otras afecciones"
  },
  {
     id:"Z093",
     enfermedad:"Examen de seguimiento consecutivo a psicoterapia"
  },
  {
     id:"Z094",
     enfermedad:"Examen de seguimiento consecutivo a tratamiento de fractura"
  },
  {
     id:"Z097",
     enfermedad:"Examen de seguimiento consecutivo a tratamiento combinado por otras afecciones"
  },
  {
     id:"Z098",
     enfermedad:"Examen de seguimiento consecutivo a otro tratamiento por otras afecciones"
  },
  {
     id:"Z099",
     enfermedad:"Examen de seguimiento consecutivo a tratamiento no especificado por otras afecciones"
  },
  {
     id:"Z10",
     enfermedad:"Control general de salud de rutina de subpopoblaciones definidas"
  },
  {
     id:"Z100",
     enfermedad:"Examen de salud ocupacional"
  },
  {
     id:"Z101",
     enfermedad:"Control general de salud de rutina de residentes de instituciones"
  },
  {
     id:"Z102",
     enfermedad:"Control general de salud de rutina a miembros de las fuerzas armadas"
  },
  {
     id:"Z103",
     enfermedad:"Control general de salud de rutina a integrantes de equipos deportivos"
  },
  {
     id:"Z108",
     enfermedad:"Otros controles generales de salud de rutina de otras subpoblaciones definidas"
  },
  {
     id:"Z11",
     enfermedad:"Examen de pesquisa especial para enfermedades infecciosas y parasitarias"
  },
  {
     id:"Z110",
     enfermedad:"Examen de pesquisa especial para enfermedades infecciosas intestinales"
  },
  {
     id:"Z111",
     enfermedad:"Examen de pesquisa especial para tuberculosis respiratoria"
  },
  {
     id:"Z112",
     enfermedad:"Examen de pesquisa especial para otras enfermedades bacterianas"
  },
  {
     id:"Z113",
     enfermedad:"Examen de pesquisa especial para infecciones de transmision predominantemente sexual"
  },
  {
     id:"Z114",
     enfermedad:"Examen de pesquisa especial para el virus de la inmunodeficiencia humana [vih]"
  },
  {
     id:"Z115",
     enfermedad:"Examen de pesquisa especial para otras enfermedades virales"
  },
  {
     id:"Z116",
     enfermedad:"Examen de pesquisa especial para otras enfermedades debidas a protozoarios y helmintos"
  },
  {
     id:"Z118",
     enfermedad:"Examen de pesquisa especial para otras enfermedades infecciosas y parasitarias especificadas"
  },
  {
     id:"Z119",
     enfermedad:"Examen de pesquisa especial para enfermedades infecciosas y parasitarias no especificadas"
  },
  {
     id:"Z12",
     enfermedad:"Examen de pesquisa especial para tumores"
  },
  {
     id:"Z120",
     enfermedad:"Examen de pesquisa especial para tumor de estomago"
  },
  {
     id:"Z121",
     enfermedad:"Examen de pesquisa especial para tumor de intestino"
  },
  {
     id:"Z122",
     enfermedad:"Examen de pesquisa especial para tumores de organos respiratorios"
  },
  {
     id:"Z123",
     enfermedad:"Examen de pesquisa especial para tumor de la mama"
  },
  {
     id:"Z124",
     enfermedad:"Examen de pesquisa especial para tumor del cuello uterino"
  },
  {
     id:"Z125",
     enfermedad:"Examen de pesquisa especial para tumor de la prostata"
  },
  {
     id:"Z126",
     enfermedad:"Examen de pesquisa especial para tumor de la vejiga"
  },
  {
     id:"Z128",
     enfermedad:"Examen de pesquisa especial para tumores de otros sitios"
  },
  {
     id:"Z129",
     enfermedad:"Examen de pesquisa especial para tumor de sitio no especificado"
  },
  {
     id:"Z13",
     enfermedad:"Examen de pesquisa especial para otras enfermedades y trastornos"
  },
  {
     id:"Z130",
     enfermedad:"Examen de pesquisa especial para enfermedades de la sangre y organos hematopoyeticos y ciertos trastornos del mecanismo de la inmunidad"
  },
  {
     id:"Z131",
     enfermedad:"Examen de pesquisa especial para diabetes mellitus"
  },
  {
     id:"Z132",
     enfermedad:"Examen de pesquisa especial para trastornos de la nutricion"
  },
  {
     id:"Z133",
     enfermedad:"Examen de pesquisa especial para trastornos mentales y del comportamiento"
  },
  {
     id:"Z134",
     enfermedad:"Examen de pesquisa especial para ciertos trastornos del desarrollo en el niño"
  },
  {
     id:"Z135",
     enfermedad:"Examen de pesquisa especial para trastornos del ojo y del oido"
  },
  {
     id:"Z136",
     enfermedad:"Examen de pesquisa especial para trastornos cardiovasculares"
  },
  {
     id:"Z137",
     enfermedad:"Examen de pesquisa especial para malformaciones congenitas, deformidades y anomalias cromosomicas"
  },
  {
     id:"Z138",
     enfermedad:"Examen de pesquisa especial para otras enfermedades y trastornos especificados"
  },
  {
     id:"Z139",
     enfermedad:"Examen de pesquisa especial, no especificado"
  },
  {
     id:"Z20",
     enfermedad:"Contacto con y exposicion a enfermedades transmisibles"
  },
  {
     id:"Z200",
     enfermedad:"Contacto con y exposicion a enfermedades infecciosas intestinales"
  },
  {
     id:"Z201",
     enfermedad:"Contacto con y exposicion a tuberculosis"
  },
  {
     id:"Z202",
     enfermedad:"Contacto con y exposicion a enfermedades infecciosas con un modo de transmision predominantemente sexual"
  },
  {
     id:"Z203",
     enfermedad:"Contacto con y exposicion a rabia"
  },
  {
     id:"Z204",
     enfermedad:"Contacto con y exposicion a rubeola"
  },
  {
     id:"Z205",
     enfermedad:"Contacto con y exposicion a hepatitis viral"
  },
  {
     id:"Z206",
     enfermedad:"Contacto con y exposicion al virus de la inmunodeficiencia humana [vih]"
  },
  {
     id:"Z207",
     enfermedad:"Contacto con y exposicion a pediculosis, acariasis y otras infestaciones"
  },
  {
     id:"Z208",
     enfermedad:"Contacto con y exposicion a otras enfermedades transmisibles"
  },
  {
     id:"Z209",
     enfermedad:"Contacto con y exposicion a enfermedades transmisibles no especificadas"
  },
  {
     id:"Z21",
     enfermedad:"Estado de infeccion asintomatica por el virus de la inmunodeficiencia humana [ vih ]"
  },
  {
     id:"Z22",
     enfermedad:"Portador de enfermedad infecciosa"
  },
  {
     id:"Z220",
     enfermedad:"Portador de fiebre tifoidea"
  },
  {
     id:"Z221",
     enfermedad:"Portador de otras enfermedades infecciosas intestinales"
  },
  {
     id:"Z222",
     enfermedad:"Portador de difteria"
  },
  {
     id:"Z223",
     enfermedad:"Portador de otras enfermedades bacterianas especificadas"
  },
  {
     id:"Z224",
     enfermedad:"Portador de enfermedades infecciosas con un modo de transmision predominantemente sexual"
  },
  {
     id:"Z225",
     enfermedad:"Portador de hepatitis viral"
  },
  {
     id:"Z226",
     enfermedad:"Portador de enfermedad infecciosa debida al virus humano t"
  },
  {
     id:"Z228",
     enfermedad:"Portador de otras enfermedades infecciosas"
  },
  {
     id:"Z229",
     enfermedad:"Portador de enfermedad infecciosa no especificada"
  },
  {
     id:"Z23",
     enfermedad:"Necesidad de inmunizacion contra enfermedad bacteriana unica"
  },
  {
     id:"Z230",
     enfermedad:"Necesidad de inmunizacion solo contra el colera"
  },
  {
     id:"Z231",
     enfermedad:"Necesidad de inmunizacion solo contra la tifoidea"
  },
  {
     id:"Z232",
     enfermedad:"Necesidad de inmunizacion contra la tuberculosis [bcg]"
  },
  {
     id:"Z233",
     enfermedad:"Necesidad de inmunizacion contra la peste"
  },
  {
     id:"Z234",
     enfermedad:"Necesidad de inmunizacion contra la tularemia"
  },
  {
     id:"Z235",
     enfermedad:"Necesidad de inmunizacion solo contra el tetanos"
  },
  {
     id:"Z236",
     enfermedad:"Necesidad de inmunizacion solo contra la difteria"
  },
  {
     id:"Z237",
     enfermedad:"Necesidad de inmunizacion solo contra la tos ferina"
  },
  {
     id:"Z238",
     enfermedad:"Necesidad de inmunizacion solo contra otra enfermedad bacteriana"
  },
  {
     id:"Z24",
     enfermedad:"Necesidad de inmunizacion contra ciertas enfermedades virales"
  },
  {
     id:"Z240",
     enfermedad:"Necesidad de inmunizacion contra la poliomielitis"
  },
  {
     id:"Z241",
     enfermedad:"Necesidad de inmunizacion contra la encefalitis viral transmitida por artropodos"
  },
  {
     id:"Z242",
     enfermedad:"Necesidad de inmunizacion contra la rabia"
  },
  {
     id:"Z243",
     enfermedad:"Necesidad de inmunizacion contra la fiebre amarilla"
  },
  {
     id:"Z244",
     enfermedad:"Necesidad de inmunizacion solo contra el sarampion"
  },
  {
     id:"Z245",
     enfermedad:"Necesidad de inmunizacion solo contra la rubeola"
  },
  {
     id:"Z246",
     enfermedad:"Necesidad de inmunizacion contra la hepatitis viral"
  },
  {
     id:"Z25",
     enfermedad:"Necesidad de inmunizacion contra otras enfermedades virales unicas"
  },
  {
     id:"Z250",
     enfermedad:"Necesidad de inmunizacion solo contra la parotiditis"
  },
  {
     id:"Z251",
     enfermedad:"Necesidad de inmunizacion contra la influenza [gripe]"
  },
  {
     id:"Z258",
     enfermedad:"Necesidad de inmunizacion contra otras enfermedades virales unicas especificadas"
  },
  {
     id:"Z26",
     enfermedad:"Necesidad de inmunizacion contra otras enfermedades infecciosas unicas"
  },
  {
     id:"Z260",
     enfermedad:"Necesidad de inmunizacion contra la leishmaniasis"
  },
  {
     id:"Z268",
     enfermedad:"Necesidad de inmunizacion contra otras enfermedades infecciosas unicas especificadas"
  },
  {
     id:"Z269",
     enfermedad:"Necesidad de inmunizacion contra enfermedad infecciosa no especificadas"
  },
  {
     id:"Z27",
     enfermedad:"Necesidad de inmunizacion contra combinacion de enfermedades infecciosas"
  },
  {
     id:"Z270",
     enfermedad:"Necesidad de inmunizacion contra el colera y la tifoidea"
  },
  {
     id:"Z271",
     enfermedad:"Necesidad de inmunizacion contra difteria"
  },
  {
     id:"Z272",
     enfermedad:"Necesidad de inmunizacion contra difteria"
  },
  {
     id:"Z273",
     enfermedad:"Necesidad de inmunizacion contra difteria"
  },
  {
     id:"Z274",
     enfermedad:"Necesidad de inmunizacion contra sarampion"
  },
  {
     id:"Z278",
     enfermedad:"Necesidad de inmunizacion contra otras combinaciones de enfermedades infecciosas"
  },
  {
     id:"Z279",
     enfermedad:"Necesidad de inmunizacion contra combinaciones no especificadas de enfermedades infecciosas"
  },
  {
     id:"Z28",
     enfermedad:"Inmunizacion no realizada"
  },
  {
     id:"Z280",
     enfermedad:"Inmunizacion no realizada por contraindicacion"
  },
  {
     id:"Z281",
     enfermedad:"Inmunizacion no realizada por decision del paciente, por motivos de creencia o presion del grupo"
  },
  {
     id:"Z282",
     enfermedad:"Inmunizacion no realizada por decision del paciente, por otras razones y las no especificadas"
  },
  {
     id:"Z288",
     enfermedad:"Inmunizacion no realizada por otras razones"
  },
  {
     id:"Z289",
     enfermedad:"Inmunizacion no realizada por razon no especificada"
  },
  {
     id:"Z29",
     enfermedad:"Necesidad de otras medidas profilacticas"
  },
  {
     id:"Z290",
     enfermedad:"Aislamiento"
  },
  {
     id:"Z291",
     enfermedad:"Inmunoterapia profilactica"
  },
  {
     id:"Z292",
     enfermedad:"Otra quimioterapia profilactica"
  },
  {
     id:"Z298",
     enfermedad:"Otras medidas profilacticas especificadas"
  },
  {
     id:"Z299",
     enfermedad:"Medida profilactica no especificada"
  },
  {
     id:"Z30",
     enfermedad:"Atención para la anticoncepcion"
  },
  {
     id:"Z300",
     enfermedad:"Consejo y asesoramiento general sobre la anticoncepcion"
  },
  {
     id:"Z301",
     enfermedad:"Insercion de dispositivo anticonceptivo (intrauterino)"
  },
  {
     id:"Z302",
     enfermedad:"Esterilizacion"
  },
  {
     id:"Z303",
     enfermedad:"Extraccion menstrual"
  },
  {
     id:"Z304",
     enfermedad:"Supervision del uso de drogas anticonceptivas"
  },
  {
     id:"Z305",
     enfermedad:"Supervision del uso de dispositivo anticonceptivo (intrauterino)"
  },
  {
     id:"Z308",
     enfermedad:"Otras atenciones especificadas para la anticoncepcion"
  },
  {
     id:"Z309",
     enfermedad:"Asistencia para la anticoncepcion, no especificada"
  },
  {
     id:"Z31",
     enfermedad:"Atención para la procreacion"
  },
  {
     id:"Z310",
     enfermedad:"Tuboplastia o vasoplastia posterior a esterilizacion"
  },
  {
     id:"Z311",
     enfermedad:"Inseminacion artificial"
  },
  {
     id:"Z312",
     enfermedad:"Fecundacion in vitro"
  },
  {
     id:"Z313",
     enfermedad:"Otros métodos de atención para la fecundacion"
  },
  {
     id:"Z314",
     enfermedad:"Investigacion y prueba para la procreacion"
  },
  {
     id:"Z315",
     enfermedad:"Asesoramiento genetico"
  },
  {
     id:"Z316",
     enfermedad:"Consejo y asesoramiento general sobre la procreacion"
  },
  {
     id:"Z318",
     enfermedad:"Otra atención especificada para la procreacion"
  },
  {
     id:"Z319",
     enfermedad:"Atención no especificada relacionada con la procreacion"
  },
  {
     id:"Z32",
     enfermedad:"Examen y prueba del embarazo"
  },
  {
     id:"Z320",
     enfermedad:"Embarazo (aun) no confirmado"
  },
  {
     id:"Z321",
     enfermedad:"Embarazo confirmado"
  },
  {
     id:"Z33",
     enfermedad:"Estado de embarazo incidental"
  },
  {
     id:"Z34",
     enfermedad:"Supervision de embarazo normal"
  },
  {
     id:"Z340",
     enfermedad:"Supervision de primer embarazo normal"
  },
  {
     id:"Z348",
     enfermedad:"Supervision de otros embarazos normales"
  },
  {
     id:"Z349",
     enfermedad:"Supervision de embarazo normal no especificado"
  },
  {
     id:"Z35",
     enfermedad:"Supervision de embarazo de alto riesgo"
  },
  {
     id:"Z350",
     enfermedad:"Supervision de embarazo con historia de esterilidad"
  },
  {
     id:"Z351",
     enfermedad:"Supervision de embarazo con historia de aborto"
  },
  {
     id:"Z352",
     enfermedad:"Supervision de embarazo con otro riesgo en la historia obstetrica o reproductiva"
  },
  {
     id:"Z353",
     enfermedad:"Supervision de embarazo con historia de insuficiente atención prenatal"
  },
  {
     id:"Z354",
     enfermedad:"Supervision de embarazo con gran multiparidad"
  },
  {
     id:"Z355",
     enfermedad:"Supervision de primigesta añosa"
  },
  {
     id:"Z356",
     enfermedad:"Supervision de primigesta muy joven"
  },
  {
     id:"Z357",
     enfermedad:"Supervision de embarazo de alto riesgo debido a problemas sociales"
  },
  {
     id:"Z358",
     enfermedad:"Supervision de otros embarazos de alto riesgo"
  },
  {
     id:"Z359",
     enfermedad:"Supervision de embarazo de alto riesgo, sin otra especificacion"
  },
  {
     id:"Z36",
     enfermedad:"Pesquisas prenatales"
  },
  {
     id:"Z360",
     enfermedad:"Pesquisa prenatal para anomalias cromosomicas"
  },
  {
     id:"Z361",
     enfermedad:"Pesquisa prenatal para medir niveles elevados de alfafetoproteinas"
  },
  {
     id:"Z362",
     enfermedad:"Otras pesquisas prenatales basadas en amniocentesis"
  },
  {
     id:"Z363",
     enfermedad:"Pesquisa prenatal de malformaciones usando altrasonido y otros métodos físicos"
  },
  {
     id:"Z364",
     enfermedad:"Pesquisa prenatal del retardo del crecimiento fetal usando ultrasonido y otros métodos físicos"
  },
  {
     id:"Z365",
     enfermedad:"Pesquisa prenatal para isoinmunizacion"
  },
  {
     id:"Z368",
     enfermedad:"Otras pesquisas prenatales especificas"
  },
  {
     id:"Z369",
     enfermedad:"Pesquisa prenatal, sin otra especificacion"
  },
  {
     id:"Z37",
     enfermedad:"Producto del parto"
  },
  {
     id:"Z370",
     enfermedad:"Nacido vivo, unico"
  },
  {
     id:"Z371",
     enfermedad:"Nacido muerto, unico"
  },
  {
     id:"Z372",
     enfermedad:"Gemelos, ambos nacidos vivos"
  },
  {
     id:"Z373",
     enfermedad:"Gemelos, un nacido vivo y un nacido muerto"
  },
  {
     id:"Z374",
     enfermedad:"Gemelos, ambos nacidos muertos"
  },
  {
     id:"Z375",
     enfermedad:"Otros nacimientos multiples, todos nacidos vivos"
  },
  {
     id:"Z376",
     enfermedad:"Otros nacimientos multiples, algunos nacidos vivos"
  },
  {
     id:"Z377",
     enfermedad:"Otros nacimientos multiples, todos nacidos muertos"
  },
  {
     id:"Z379",
     enfermedad:"Producto del parto no especificado"
  },
  {
     id:"Z38",
     enfermedad:"Nacidos vivos segun lugar de nacimiento"
  },
  {
     id:"Z380",
     enfermedad:"Producto unico, nacido en hospital"
  },
  {
     id:"Z381",
     enfermedad:"Producto unico, nacido fuera de hospital"
  },
  {
     id:"Z382",
     enfermedad:"Producto unico, lugar de nacimiento no especificado"
  },
  {
     id:"Z383",
     enfermedad:"Gemelos, nacidos en hospital"
  },
  {
     id:"Z384",
     enfermedad:"Gemelos, nacidos fuera de hospital"
  },
  {
     id:"Z385",
     enfermedad:"Gemelos, lugar de nacimiento no especificado"
  },
  {
     id:"Z386",
     enfermedad:"Otros nacimientos multiples, en hospital"
  },
  {
     id:"Z387",
     enfermedad:"Otros nacimientos multiples, fuera del hospital"
  },
  {
     id:"Z388",
     enfermedad:"Otros nacimientos multiples, lugar de nacimiento no especificado"
  },
  {
     id:"Z39",
     enfermedad:"Examen y atención del postparto"
  },
  {
     id:"Z390",
     enfermedad:"Atención y examen inmediatamente despues del parto"
  },
  {
     id:"Z391",
     enfermedad:"Atención y examen de madre en periodo de lactancia"
  },
  {
     id:"Z392",
     enfermedad:"Seguimiento postparto, de rutina"
  },
  {
     id:"Z40",
     enfermedad:"Cirugia profilactica"
  },
  {
     id:"Z400",
     enfermedad:"Cirugia profilactica por factores de riesgo relacionados con tumores malignos"
  },
  {
     id:"Z408",
     enfermedad:"Otra cirugia profilactica"
  },
  {
     id:"Z409",
     enfermedad:"Cirugia profilactica no especificada"
  },
  {
     id:"Z41",
     enfermedad:"Procedimientos para otros propositos que no sean los de mejorar el estado de salud"
  },
  {
     id:"Z410",
     enfermedad:"Trasplante de pelo"
  },
  {
     id:"Z411",
     enfermedad:"Otras cirugias plasticas por razones esteticas"
  },
  {
     id:"Z412",
     enfermedad:"Circuncision ritual o de rutina"
  },
  {
     id:"Z413",
     enfermedad:"Perforacion de la oreja"
  },
  {
     id:"Z418",
     enfermedad:"Otros procedimientos para otros propositos que no sean los de mejorar el estado de salud"
  },
  {
     id:"Z419",
     enfermedad:"Procedimientos no especificado para otros propositos que no sean los de mejorar el estado de salud"
  },
  {
     id:"Z42",
     enfermedad:"Cuidados posteriores a cirugia plastica"
  },
  {
     id:"Z420",
     enfermedad:"Cuidados posteriores a la cirugia plastica de la cabeza y del cuello"
  },
  {
     id:"Z421",
     enfermedad:"Cuidados posteriores a la cirugia plastica de la mama"
  },
  {
     id:"Z422",
     enfermedad:"Cuidados posteriores a la cirugia plastica de otras partes especificadas del tronco"
  },
  {
     id:"Z423",
     enfermedad:"Cuidados posteriores a la cirugia plastica de las extremidades superiores"
  },
  {
     id:"Z424",
     enfermedad:"Cuidados posteriores a la cirugia plastica de las extremidades inferiores"
  },
  {
     id:"Z428",
     enfermedad:"Cuidados posteriores a la cirugia plastica de otras partes especificadas del cuerpo"
  },
  {
     id:"Z429",
     enfermedad:"Cuidados posteriores a la cirugia plastica no especificada"
  },
  {
     id:"Z43",
     enfermedad:"Atención de orificios artificiales"
  },
  {
     id:"Z430",
     enfermedad:"Atención de traqueostomia"
  },
  {
     id:"Z431",
     enfermedad:"Atención de gastrostomia"
  },
  {
     id:"Z432",
     enfermedad:"Atención de ileostomia"
  },
  {
     id:"Z433",
     enfermedad:"Atención de colostomia"
  },
  {
     id:"Z434",
     enfermedad:"Atención de otros orificios artificiales de las vias digestivas"
  },
  {
     id:"Z435",
     enfermedad:"Atención de cistostomia"
  },
  {
     id:"Z436",
     enfermedad:"Atención de otros orificios artificiales de las vias urinarias"
  },
  {
     id:"Z437",
     enfermedad:"Atención de vagina artificial"
  },
  {
     id:"Z438",
     enfermedad:"Atención de otros orificios artificiales"
  },
  {
     id:"Z439",
     enfermedad:"Atención de orificio artificial no especificado"
  },
  {
     id:"Z44",
     enfermedad:"Prueba y ajuste de dispositivos protesicos externos"
  },
  {
     id:"Z440",
     enfermedad:"Prueba y ajuste de brazo artificial (completo) (parcial)"
  },
  {
     id:"Z441",
     enfermedad:"Prueba y ajuste de pierna artificial (completa) (parcial)"
  },
  {
     id:"Z442",
     enfermedad:"Prueba y ajuste de ojo artificial"
  },
  {
     id:"Z443",
     enfermedad:"Prueba y ajuste de protesis mamaria externa"
  },
  {
     id:"Z448",
     enfermedad:"Prueba y ajuste de otros dispositivos protesicos externos"
  },
  {
     id:"Z449",
     enfermedad:"Prueba y ajuste de dispositivo protesico externo no especificado"
  },
  {
     id:"Z45",
     enfermedad:"Asistencia y ajuste de dispositivos implantados"
  },
  {
     id:"Z450",
     enfermedad:"Asistencia y ajuste de marcapaso cardiaco"
  },
  {
     id:"Z451",
     enfermedad:"Asistencia y ajuste de bomba de infusion"
  },
  {
     id:"Z452",
     enfermedad:"Asistencia y ajuste de dispositivos de acceso vascular"
  },
  {
     id:"Z453",
     enfermedad:"Asistencia y ajuste de dispositivo auditivo implantado"
  },
  {
     id:"Z458",
     enfermedad:"Asistencia y ajuste de otros dispositivos implantados"
  },
  {
     id:"Z459",
     enfermedad:"Asistencia y ajuste de dispositivo implantado no especificado"
  },
  {
     id:"Z46",
     enfermedad:"Prueba y ajuste de otros dispositivos"
  },
  {
     id:"Z460",
     enfermedad:"Prueba y ajuste de anteojos y lentes de contacto"
  },
  {
     id:"Z461",
     enfermedad:"Prueba y ajuste de audifonos"
  },
  {
     id:"Z462",
     enfermedad:"Prueba y ajuste de otros dispositivos relacionados con el sistema nervioso y los sentidos especiales"
  },
  {
     id:"Z463",
     enfermedad:"Prueba y ajuste de protesis dental"
  },
  {
     id:"Z464",
     enfermedad:"Prueba y ajuste de dispositivo ortodoncico"
  },
  {
     id:"Z465",
     enfermedad:"Prueba y ajuste de ileostomia u otro dispositivo intestinal"
  },
  {
     id:"Z466",
     enfermedad:"Prueba y ajuste de dispositivo urinario"
  },
  {
     id:"Z467",
     enfermedad:"Prueba y ajuste de dispositivo ortopedico"
  },
  {
     id:"Z468",
     enfermedad:"Prueba y ajuste de otros dispositivos especificados"
  },
  {
     id:"Z469",
     enfermedad:"Prueba y ajuste de dispositivo no especificado"
  },
  {
     id:"Z47",
     enfermedad:"Otros cuidados posteriores a la ortopedia"
  },
  {
     id:"Z470",
     enfermedad:"Cuidados posteriores a la extraccion de placa u otro dispositivo de fijacion interna en fractura"
  },
  {
     id:"Z478",
     enfermedad:"Otros cuidados especificados posteriores a la ortopedia"
  },
  {
     id:"Z479",
     enfermedad:"Cuidado posterior a la ortopedia, no especificado"
  },
  {
     id:"Z48",
     enfermedad:"Otros cuidados posteriores a la cirugia"
  },
  {
     id:"Z480",
     enfermedad:"Atención de los apositos y suturas"
  },
  {
     id:"Z488",
     enfermedad:"Otros cuidados especificados posteriores a la cirugia"
  },
  {
     id:"Z489",
     enfermedad:"Cuidado posterior a la cirugia, no especificado"
  },
  {
     id:"Z49",
     enfermedad:"Cuidados relativos al procedimiento de dialisis"
  },
  {
     id:"Z490",
     enfermedad:"Cuidados preoperatorios para dialisis"
  },
  {
     id:"Z491",
     enfermedad:"Dialisis extracorporea"
  },
  {
     id:"Z492",
     enfermedad:"Otras dialisis"
  },
  {
     id:"Z50",
     enfermedad:"Atención por el uso de procedimientos de rehabilitacion"
  },
  {
     id:"Z500",
     enfermedad:"Rehabilitacion cardiaca"
  },
  {
     id:"Z501",
     enfermedad:"Otras terapias fisicas"
  },
  {
     id:"Z502",
     enfermedad:"Rehabilitacion del alcoholico"
  },
  {
     id:"Z503",
     enfermedad:"Rehabilitacion del drogadicto"
  },
  {
     id:"Z504",
     enfermedad:"Psicoterapia, no clasificada en otra parte"
  },
  {
     id:"Z505",
     enfermedad:"Terapia del lenguaje"
  },
  {
     id:"Z506",
     enfermedad:"Adiestramiento ortoptico"
  },
  {
     id:"Z507",
     enfermedad:"Terapia ocupacional y rehabilitacion vocacional, no clasificada en otra parte"
  },
  {
     id:"Z508",
     enfermedad:"Atención por otros procedimientos de rehabilitacion"
  },
  {
     id:"Z509",
     enfermedad:"Atención por procedimiento de rehabilitacion, no especificada"
  },
  {
     id:"Z51",
     enfermedad:"Otra atención medica"
  },
  {
     id:"Z510",
     enfermedad:"Sesion de radioterapia"
  },
  {
     id:"Z511",
     enfermedad:"Sesion de quimioterapia por tumor"
  },
  {
     id:"Z512",
     enfermedad:"Otra quimioterapia"
  },
  {
     id:"Z513",
     enfermedad:"Transfusion de sangre, sin diagnostico informado"
  },
  {
     id:"Z514",
     enfermedad:"Atención preparatoria para tratamiento subsecuente, no clasificado en otra parte"
  },
  {
     id:"Z515",
     enfermedad:"Atención paliativa"
  },
  {
     id:"Z516",
     enfermedad:"Desensibilizacion a alergenos"
  },
  {
     id:"Z518",
     enfermedad:"Otras atenciones medicas especificadas"
  },
  {
     id:"Z519",
     enfermedad:"Atención medica, no especificada"
  },
  {
     id:"Z52",
     enfermedad:"Donantes de organos y tejidos"
  },
  {
     id:"Z520",
     enfermedad:"Donante de sangre"
  },
  {
     id:"Z521",
     enfermedad:"Donante de piel"
  },
  {
     id:"Z522",
     enfermedad:"Donante de hueso"
  },
  {
     id:"Z523",
     enfermedad:"Donante de medula osea"
  },
  {
     id:"Z524",
     enfermedad:"Donante de riñon"
  },
  {
     id:"Z525",
     enfermedad:"Donante de cornea"
  },
  {
     id:"Z526",
     enfermedad:"Donante de higado"
  },
  {
     id:"Z527",
     enfermedad:"Donante de corazon"
  },
  {
     id:"Z528",
     enfermedad:"Donante de otros organos o tejidos"
  },
  {
     id:"Z529",
     enfermedad:"Donante de organo o tejido no especificado"
  },
  {
     id:"Z53",
     enfermedad:"Persona en contacto con los servicios de salud para procedimientos especiales no realizados"
  },
  {
     id:"Z530",
     enfermedad:"Procedimiento no realizado por contraindicacion"
  },
  {
     id:"Z531",
     enfermedad:"Procedimiento no realizado por decision del paciente, por razones de creencia o presion del grupo"
  },
  {
     id:"Z532",
     enfermedad:"Procedimiento no realizado por decision del paciente, por otras razones y las no especificadas"
  },
  {
     id:"Z538",
     enfermedad:"Procedimiento no realizado por otras razones"
  },
  {
     id:"Z539",
     enfermedad:"Procedimiento no realizado por razon no especificada"
  },
  {
     id:"Z54",
     enfermedad:"Convalecencia"
  },
  {
     id:"Z540",
     enfermedad:"Convalecencia consecutiva a cirugia"
  },
  {
     id:"Z541",
     enfermedad:"Convalecencia consecutiva a radioterapia"
  },
  {
     id:"Z542",
     enfermedad:"Convalecencia consecutiva a quimioterapia"
  },
  {
     id:"Z543",
     enfermedad:"Convalecencia consecutiva a psicoterapia"
  },
  {
     id:"Z544",
     enfermedad:"Convalecencia consecutiva a tratamiento de fractura"
  },
  {
     id:"Z547",
     enfermedad:"Convalecencia consecutiva a tratamiento combinado"
  },
  {
     id:"Z548",
     enfermedad:"Convalecencia consecutiva a otros tratamientos"
  },
  {
     id:"Z549",
     enfermedad:"Convalecencia consecutiva a tratamiento no especificado"
  },
  {
     id:"Z55",
     enfermedad:"Problemas relacionados con la educacion y la alfabetizacion"
  },
  {
     id:"Z550",
     enfermedad:"Problemas relacionados con el analfabetismo o bajo nivel de instruccion"
  },
  {
     id:"Z551",
     enfermedad:"Problemas relacionados con la educacion no disponible o inaccesible"
  },
  {
     id:"Z552",
     enfermedad:"Problemas relacionados con la falla en los examenes"
  },
  {
     id:"Z553",
     enfermedad:"Problemas relacionados con el bajo rendimiento escolar"
  },
  {
     id:"Z554",
     enfermedad:"Problemas relacionados con la inadaptacion educacional y desavenencias con maestros y compañeros"
  },
  {
     id:"Z558",
     enfermedad:"Otros problemas relacionados con la educacion y la alfabetizacion"
  },
  {
     id:"Z559",
     enfermedad:"Problema no especificado relacionado con la educacion y la alfabetizacion"
  },
  {
     id:"Z56",
     enfermedad:"Problemas relacionados con el empleo y el desempleo"
  },
  {
     id:"Z560",
     enfermedad:"Problemas relacionados con el desempleo, no especificados"
  },
  {
     id:"Z561",
     enfermedad:"Problemas relacionados con el cambio de empleo"
  },
  {
     id:"Z562",
     enfermedad:"Problemas relacionados con amenaza de perdida del empleo"
  },
  {
     id:"Z563",
     enfermedad:"Problemas relacionados con horario estresante de trabajo"
  },
  {
     id:"Z564",
     enfermedad:"Problemas relacionados con desavenencias con el jefe y los compañeros de trabajo"
  },
  {
     id:"Z565",
     enfermedad:"Problemas relacionados con el trabajo incompatible"
  },
  {
     id:"Z566",
     enfermedad:"Otros problemas de tension fisica o mental relacionadas con el trabajo"
  },
  {
     id:"Z567",
     enfermedad:"Otros problemas y los no especificados relacionados con el empleo"
  },
  {
     id:"Z57",
     enfermedad:"Exposicion a factores de riesgo ocupacional"
  },
  {
     id:"Z570",
     enfermedad:"Exposicion ocupacional al ruido"
  },
  {
     id:"Z571",
     enfermedad:"Exposicion ocupacional a la radiacion"
  },
  {
     id:"Z572",
     enfermedad:"Exposicion ocupacional al polvo"
  },
  {
     id:"Z573",
     enfermedad:"Exposicion ocupacional a otro contaminante del aire"
  },
  {
     id:"Z574",
     enfermedad:"Exposicion ocupacional a agentes toxicos en agricultura"
  },
  {
     id:"Z575",
     enfermedad:"Exposicion ocupacional a agentes toxicos en otras industrias"
  },
  {
     id:"Z576",
     enfermedad:"Exposicion ocupacional a temperatura extrema"
  },
  {
     id:"Z577",
     enfermedad:"Exposicion ocupacional a la vibracion"
  },
  {
     id:"Z578",
     enfermedad:"Exposicion ocupacional a otros factores de riesgo"
  },
  {
     id:"Z579",
     enfermedad:"Exposicion ocupacional a factor de riesgo no especificado"
  },
  {
     id:"Z58",
     enfermedad:"Problemas relacionados con el ambiente físico"
  },
  {
     id:"Z580",
     enfermedad:"Exposicion al ruido"
  },
  {
     id:"Z581",
     enfermedad:"Exposicion al aire contaminado"
  },
  {
     id:"Z582",
     enfermedad:"Exposicion al agua contaminada"
  },
  {
     id:"Z583",
     enfermedad:"Exposicion al suelo contaminado"
  },
  {
     id:"Z584",
     enfermedad:"Exposicion a la radiacion"
  },
  {
     id:"Z585",
     enfermedad:"Exposicion a otras contaminaciones del ambiente físico"
  },
  {
     id:"Z586",
     enfermedad:"Suministro inadecuado de agua potable"
  },
  {
     id:"Z588",
     enfermedad:"Otros problemas relacionados con el ambiente físico"
  },
  {
     id:"Z589",
     enfermedad:"Problema no especificado relacionado con el ambiente físico"
  },
  {
     id:"Z59",
     enfermedad:"Problemas relacionados con la vivienda y las circunstancias economicas"
  },
  {
     id:"Z590",
     enfermedad:"Problemas relacionados con la falta de vivienda"
  },
  {
     id:"Z591",
     enfermedad:"Problemas relacionados con vivienda inadecuada"
  },
  {
     id:"Z592",
     enfermedad:"Problemas caseros y con vecinos e inquilinos"
  },
  {
     id:"Z593",
     enfermedad:"Problemas relacionados con persona que reside en una institucion"
  },
  {
     id:"Z594",
     enfermedad:"Problemas relacionados con falta de alimentos adecuados"
  },
  {
     id:"Z595",
     enfermedad:"Problemas relacionados con pobreza extrema"
  },
  {
     id:"Z596",
     enfermedad:"Problemas relacionados con bajos ingresos"
  },
  {
     id:"Z597",
     enfermedad:"Problemas relacionados con seguridad social y sostenimiento insuficientes para el bienestar"
  },
  {
     id:"Z598",
     enfermedad:"Otros problemas relacionados con la vivienda y las circunstancias economicas"
  },
  {
     id:"Z599",
     enfermedad:"Problemas no especificados relacionados con la vivienda y las circunstancias economicas"
  },
  {
     id:"Z60",
     enfermedad:"Problemas relacionados con el ambiente social"
  },
  {
     id:"Z600",
     enfermedad:"Problemas relacionados con el ajuste a las transiciones del ciclo vital"
  },
  {
     id:"Z601",
     enfermedad:"Problemas relacionados con situacion familiar atipica"
  },
  {
     id:"Z602",
     enfermedad:"Problemas relacionados con persona que vive sola"
  },
  {
     id:"Z603",
     enfermedad:"Problemas relacionados con la adaptacion cultural"
  },
  {
     id:"Z604",
     enfermedad:"Problemas relacionados con exclusion y rechazo social"
  },
  {
     id:"Z605",
     enfermedad:"Problemas relacionados con la discriminacion y persecucion percibidas"
  },
  {
     id:"Z608",
     enfermedad:"Otros problemas relacionados con el ambiente social"
  },
  {
     id:"Z609",
     enfermedad:"Problema no especificado relacionado con el ambiente social"
  },
  {
     id:"Z61",
     enfermedad:"Problemas relacionados con hechos negativos en la ninez"
  },
  {
     id:"Z610",
     enfermedad:"Problemas relacionados con la perdida de relacion afectiva en la infancia"
  },
  {
     id:"Z611",
     enfermedad:"Problemas relacionados con el alejamiento del hogar en la infancia"
  },
  {
     id:"Z612",
     enfermedad:"Problemas relacionados con alteracion en el patron de la relacion familiar en la infancia"
  },
  {
     id:"Z613",
     enfermedad:"Problemas relacionados con eventos que llevaron a la perdida de la autoestima en la infancia"
  },
  {
     id:"Z614",
     enfermedad:"Problemas relacionados con el abuso sexual del niño por persona dentro del grupo de apoyo primario"
  },
  {
     id:"Z615",
     enfermedad:"Problemas relacionados con el abuso sexual del niño por persona ajena al grupo de apoyo primario"
  },
  {
     id:"Z616",
     enfermedad:"Problemas relacionados con abuso físico del niño"
  },
  {
     id:"Z617",
     enfermedad:"Problemas relacionados con experiencias personales atemorizantes en la infancia"
  },
  {
     id:"Z618",
     enfermedad:"Problemas relacionados con otras experiencias negativas en la infancia"
  },
  {
     id:"Z619",
     enfermedad:"Problemas relacionados con experiencia negativa no especificada en la infancia"
  },
  {
     id:"Z62",
     enfermedad:"Otros problemas relacionados con la crianza del nino"
  },
  {
     id:"Z620",
     enfermedad:"Problemas relacionados con la supervision o el control inadecuados de los padres"
  },
  {
     id:"Z621",
     enfermedad:"Problemas relacionados con la sobreproteccion de los padres"
  },
  {
     id:"Z622",
     enfermedad:"Problemas relacionados con la crianza en institucion"
  },
  {
     id:"Z623",
     enfermedad:"Problemas relacionados con hostilidad y reprobacion al niño"
  },
  {
     id:"Z624",
     enfermedad:"Problemas relacionados con el abandono emocional del niño"
  },
  {
     id:"Z625",
     enfermedad:"Otros problemas relacionados con negligencia en la crianza del niño"
  },
  {
     id:"Z626",
     enfermedad:"Problemas relacionados con presiones inapropiadas de los padres y otras anormalidades en la calidad de la crianza"
  },
  {
     id:"Z628",
     enfermedad:"Otros problemas especificados y relacionados con la crianza del niño"
  },
  {
     id:"Z629",
     enfermedad:"Problema no especificado relacionado con la crianza del niño"
  },
  {
     id:"Z63",
     enfermedad:"Problemas relacionados con el grupo primario de apoyo, inclusive circunstancias familiares"
  },
  {
     id:"Z630",
     enfermedad:"Problemas en la relacion entre esposos o pareja"
  },
  {
     id:"Z631",
     enfermedad:"Problemas en la relacion con los padres y los familiares politicos"
  },
  {
     id:"Z632",
     enfermedad:"Problemas relacionados con el apoyo familiar inadecuado"
  },
  {
     id:"Z633",
     enfermedad:"Problemas relacionados con la ausencia de un miembro de la familia"
  },
  {
     id:"Z634",
     enfermedad:"Problemas relacionados con la desaparicion o muerte de un miembro de la familia"
  },
  {
     id:"Z635",
     enfermedad:"Problemas relacionados con la ruptura familiar por separacion o divorcio"
  },
  {
     id:"Z636",
     enfermedad:"Problemas relacionados con familiar dependiente, necesitado de cuidado en la casa"
  },
  {
     id:"Z637",
     enfermedad:"Problemas relacionados con otros hechos estresantes que afectan a la familia y al hogar"
  },
  {
     id:"Z638",
     enfermedad:"Otros problemas especificados relacionados con el grupo primario de apoyo"
  },
  {
     id:"Z639",
     enfermedad:"Problema no especificado relacionado con el grupo primario de apoyo"
  },
  {
     id:"Z64",
     enfermedad:"Problemas relacionados con ciertas circunstancias psicosociales"
  },
  {
     id:"Z640",
     enfermedad:"Problemas relacionados con embarazo no deseado"
  },
  {
     id:"Z641",
     enfermedad:"Problemas relacionados con la multiparidad"
  },
  {
     id:"Z642",
     enfermedad:"Problemas relacionados con la solicitud o aceptacion de intervenciones fisicas, nutricionales y quimicas, conociendo su riesgo y peligro"
  },
  {
     id:"Z643",
     enfermedad:"Problemas relacionados con la solicitud o aceptacion de intervenciones psicologicas o de la conducta, conociendo su riesgo y peligro"
  },
  {
     id:"Z644",
     enfermedad:"Problemas relacionados con el desacuerdo con consejeros"
  },
  {
     id:"Z65",
     enfermedad:"Problemas relacionados con otras circunstancias psicosociales"
  },
  {
     id:"Z650",
     enfermedad:"Problemas relacionados con culpabilidad en procedimientos civiles o criminales sin prision"
  },
  {
     id:"Z651",
     enfermedad:"Problemas relacionados con prision y otro encarcelamiento"
  },
  {
     id:"Z652",
     enfermedad:"Problemas relacionados con la liberacion de la prision"
  },
  {
     id:"Z653",
     enfermedad:"Problemas relacionados con otras circunstancias legales"
  },
  {
     id:"Z654",
     enfermedad:"Problemas relacionados con victima de crimen o terrorismo"
  },
  {
     id:"Z655",
     enfermedad:"Problemas relacionados con la exposicion a desastre, guerra u otras hostilidades"
  },
  {
     id:"Z658",
     enfermedad:"Otros problemas especificados relacionados con circunstancias psicosociales"
  },
  {
     id:"Z659",
     enfermedad:"Problemas relacionados con circunstancias psicosociales no especificadas"
  },
  {
     id:"Z70",
     enfermedad:"Consulta relacionada con actitud, conducta u orientacion sexual"
  },
  {
     id:"Z700",
     enfermedad:"Consulta relacionada con la actitud sexual"
  },
  {
     id:"Z701",
     enfermedad:"Consulta relacionada con la orientacion y conducta sexual del paciente"
  },
  {
     id:"Z702",
     enfermedad:"Consulta relacionada con la orientacion y conducta sexual de una tercera persona"
  },
  {
     id:"Z703",
     enfermedad:"Consulta relacionada con preocupaciones combinadas sobre la actitud, la conducta y la orientacion sexuales"
  },
  {
     id:"Z708",
     enfermedad:"Otras consultas sexuales especificas"
  },
  {
     id:"Z709",
     enfermedad:"Consulta sexual, no especificada"
  },
  {
     id:"Z71",
     enfermedad:"Persona en contacto con los servicios de salud por otras consultas y consejos medicos, no clasificados en otra parte"
  },
  {
     id:"Z710",
     enfermedad:"Persona que consulta en nombre de otra persona"
  },
  {
     id:"Z711",
     enfermedad:"Persona que teme estar enferma, a quien no se hace diagnostico"
  },
  {
     id:"Z712",
     enfermedad:"Persona que consulta para la explicacion de hallazgos de investigacion"
  },
  {
     id:"Z713",
     enfermedad:"Consulta para instruccion y vigilancia de la dieta"
  },
  {
     id:"Z714",
     enfermedad:"Consulta para asesoria y vigilancia por abuso de alcohol"
  },
  {
     id:"Z715",
     enfermedad:"Consulta para asesoria y vigilancia por abuso de drogas"
  },
  {
     id:"Z716",
     enfermedad:"Consulta para asesoria por abuso de tabaco"
  },
  {
     id:"Z717",
     enfermedad:"Consulta para asesoria sobre el virus de la inmunodeficiencia humana [vih]"
  },
  {
     id:"Z718",
     enfermedad:"Otras consultas especificadas"
  },
  {
     id:"Z719",
     enfermedad:"Consulta, no especificada"
  },
  {
     id:"Z72",
     enfermedad:"Problemas relacionados con el estilo de vida"
  },
  {
     id:"Z720",
     enfermedad:"Problemas relacionados con el uso de tabaco"
  },
  {
     id:"Z721",
     enfermedad:"Problemas relacionados con el uso de alcohol"
  },
  {
     id:"Z722",
     enfermedad:"Problemas relacionados con el uso de drogas"
  },
  {
     id:"Z723",
     enfermedad:"Problemas relacionados con la falta de ejercicio físico"
  },
  {
     id:"Z724",
     enfermedad:"Problemas relacionados con la dieta y hábitos alimentarios inapropiados"
  },
  {
     id:"Z725",
     enfermedad:"Problemas relacionados con la conducta sexual de alto riesgo"
  },
  {
     id:"Z726",
     enfermedad:"Problemas relacionados con el juego y las apuestas"
  },
  {
     id:"Z728",
     enfermedad:"Otros problemas relacionados con el estilo de vida"
  },
  {
     id:"Z729",
     enfermedad:"Problema no especificado relacionado con el estilo de vida"
  },
  {
     id:"Z73",
     enfermedad:"Problemas relacionados con dificultades con el modo de vida"
  },
  {
     id:"Z730",
     enfermedad:"Problemas relacionados con la enfermedad consuntiva"
  },
  {
     id:"Z731",
     enfermedad:"Problemas relacionados con la acentuacion de rasgos de la personalidad"
  },
  {
     id:"Z732",
     enfermedad:"Problemas relacionados con la falta de relajacion y descanso"
  },
  {
     id:"Z733",
     enfermedad:"Problemas relacionados con el estres, no clasificados en otra parte"
  },
  {
     id:"Z734",
     enfermedad:"Problemas relacionados con habilidades sociales inadecuadas, no clasificadas en otra parte"
  },
  {
     id:"Z735",
     enfermedad:"Problemas relacionados con el conflicto del rol social, no clasificados en otra parte"
  },
  {
     id:"Z736",
     enfermedad:"Problemas relacionados con la limitacion de las actividades debido a discapacidad"
  },
  {
     id:"Z738",
     enfermedad:"Otros problemas relacionados con dificultades con el modo de vida"
  },
  {
     id:"Z739",
     enfermedad:"Problemas no especificados relacionados con dificultades con el modo de vida"
  },
  {
     id:"Z74",
     enfermedad:"Problemas relacionados con dependencia del prestador de servicios"
  },
  {
     id:"Z740",
     enfermedad:"Problemas relacionados con movilidad reducida"
  },
  {
     id:"Z741",
     enfermedad:"Problemas relacionados con la necesidad de ayuda para el cuidado personal"
  },
  {
     id:"Z742",
     enfermedad:"Problemas relacionados con la necesidad de asistencia domiciliaria y que ningun otro miembro del hogar puede proporcionar"
  },
  {
     id:"Z743",
     enfermedad:"Problemas relacionados con la necesidad de supervision continua"
  },
  {
     id:"Z748",
     enfermedad:"Otros problemas relacionados con dependencia del prestador de servicios"
  },
  {
     id:"Z749",
     enfermedad:"Problema no especificado relacionado con dependencia del prestador de servicios"
  },
  {
     id:"Z75",
     enfermedad:"Problemas relacionados con facilidades de atención medica u otros servicios de salud"
  },
  {
     id:"Z750",
     enfermedad:"Problemas relacionados con servicio medico no disponible en el domicilio"
  },
  {
     id:"Z751",
     enfermedad:"Problemas relacionados con persona esperando admision en una institucion apropiada en otro lugar"
  },
  {
     id:"Z752",
     enfermedad:"Problemas relacionados con persona en otro periodo de espera para investigacion y tratamiento"
  },
  {
     id:"Z753",
     enfermedad:"Problemas relacionados con atención de salud no disponible o inaccesible"
  },
  {
     id:"Z754",
     enfermedad:"Problemas relacionados con otros servicios asistenciales no disponibles o inaccesibles"
  },
  {
     id:"Z755",
     enfermedad:"Problemas relacionados con la atención durante vacaciones de la familia"
  },
  {
     id:"Z758",
     enfermedad:"Otros problemas relacionados con servicios medicos y de salud"
  },
  {
     id:"Z759",
     enfermedad:"Problema no especificado relacionado con servicios medicos y de salud"
  },
  {
     id:"Z76",
     enfermedad:"Persona en contacto con los servicios de salud por otras circunstancias"
  },
  {
     id:"Z760",
     enfermedad:"Consulta para repeticion de receta"
  },
  {
     id:"Z761",
     enfermedad:"Consulta para atención y supervision de la salud del niño"
  },
  {
     id:"Z762",
     enfermedad:"Consulta para atención y supervision de la salud de otros niños o lactantes sanos"
  },
  {
     id:"Z763",
     enfermedad:"Persona sana que acompaña al enfermo"
  },
  {
     id:"Z764",
     enfermedad:"Otro huesped en servicios de salud"
  },
  {
     id:"Z765",
     enfermedad:"Persona que consulta con simulacion consciente [simulador]"
  },
  {
     id:"Z768",
     enfermedad:"Persona en contacto con los servicios de salud en otras circunstancias especificadas"
  },
  {
     id:"Z769",
     enfermedad:"Persona en contacto con los servicios de salud en circunstancias no especificadas"
  },
  {
     id:"Z80",
     enfermedad:"Historia familiar de tumor maligno"
  },
  {
     id:"Z800",
     enfermedad:"Historia familiar de tumor maligno de organos digestivos"
  },
  {
     id:"Z801",
     enfermedad:"Historia familiar de tumor maligno de traquea, bronquios y pulmon"
  },
  {
     id:"Z802",
     enfermedad:"Historia familiar de tumor maligno de otros organos respiratorios e intratoracicos"
  },
  {
     id:"Z803",
     enfermedad:"Historia familiar de tumor maligno de mama"
  },
  {
     id:"Z804",
     enfermedad:"Historia familiar de tumor maligno de organos genitales"
  },
  {
     id:"Z805",
     enfermedad:"Historia familiar de tumor maligno de vias urinarias"
  },
  {
     id:"Z806",
     enfermedad:"Historia familiar de leucemia"
  },
  {
     id:"Z807",
     enfermedad:"Historia familiar de otros tumores malignos del tejido linfoide, hematopoyetico y tejidos relacionados"
  },
  {
     id:"Z808",
     enfermedad:"Historia familiar de tumor maligno de otros organos o sistemas especificados"
  },
  {
     id:"Z809",
     enfermedad:"Historia familiar de tumor maligno, de sitio no especificado"
  },
  {
     id:"Z81",
     enfermedad:"Historia familiar de trastornos mentales y de comportamiento"
  },
  {
     id:"Z810",
     enfermedad:"Historia familiar de retardo mental"
  },
  {
     id:"Z811",
     enfermedad:"Historia familiar de abuso de alcohol"
  },
  {
     id:"Z812",
     enfermedad:"Historia familiar de abuso de tabaco"
  },
  {
     id:"Z813",
     enfermedad:"Historia familiar de abuso de otras sustancia psicoactivas"
  },
  {
     id:"Z814",
     enfermedad:"Historia familiar de abuso de otras sustancias"
  },
  {
     id:"Z818",
     enfermedad:"Historia familiar de otros trastornos mentales y del comportamiento"
  },
  {
     id:"Z82",
     enfermedad:"Historia familiar de ciertas discapacidades y enfermedades cronicas incapacitantes"
  },
  {
     id:"Z820",
     enfermedad:"Historia familiar de epilepsia y de otras enfermedades del sistema nervioso"
  },
  {
     id:"Z821",
     enfermedad:"Historia familiar de ceguera o perdida de la vision"
  },
  {
     id:"Z822",
     enfermedad:"Historia familiar de sordera o perdida de la audicion"
  },
  {
     id:"Z823",
     enfermedad:"Historia familiar de apoplejia"
  },
  {
     id:"Z824",
     enfermedad:"Historia familiar de enfermedad isquemica del corazon y otras enfermedades del sistema circulatorio"
  },
  {
     id:"Z825",
     enfermedad:"Historia familiar de asma y de otras enfermedades cronicas de las vias respiratorias inferiores"
  },
  {
     id:"Z826",
     enfermedad:"Historia familiar de artritis y otras enfermedades del sistema osteomuscular y tejido conjuntivo"
  },
  {
     id:"Z827",
     enfermedad:"Historia familiar de malformaciones congenitas, deformidades y otras anomalias cromosomicas"
  },
  {
     id:"Z828",
     enfermedad:"Historia familiar de otras discapacidades y enfermedades cronicas incapacitantes no clasificadas en otra parte"
  },
  {
     id:"Z83",
     enfermedad:"Historia familiar de otros trastornos especificos"
  },
  {
     id:"Z830",
     enfermedad:"Historia familiar de infeccion por el virus de la inmunodeficiencia humana [vih]"
  },
  {
     id:"Z831",
     enfermedad:"Historia familiar de otras enfermedades infecciosas y parasitarias"
  },
  {
     id:"Z832",
     enfermedad:"Historia familiar de enfermedades de la sangre y de los organos hematopoyeticos y de ciertos trastornos del mecanismo inmunologico"
  },
  {
     id:"Z833",
     enfermedad:"Historia familiar de diabetes mellitus"
  },
  {
     id:"Z834",
     enfermedad:"Historia familiar de otras enfermedades endocrinas, nutricionales y metabolicas"
  },
  {
     id:"Z835",
     enfermedad:"Historia familiar de trastornos de los ojos y de los oidos"
  },
  {
     id:"Z836",
     enfermedad:"Historia familiar de enfermedades del sistema respiratorio"
  },
  {
     id:"Z837",
     enfermedad:"Historia familiar de enfermedades del sistema digestivo"
  },
  {
     id:"Z84",
     enfermedad:"Historia familiar de otras afecciones"
  },
  {
     id:"Z840",
     enfermedad:"Historia familiar de enfermedades de la piel y del tejido subcutaneo"
  },
  {
     id:"Z841",
     enfermedad:"Historia familiar de trastornos del riñon y del ureter"
  },
  {
     id:"Z842",
     enfermedad:"Historia familiar de otras enfermedades del sistema genitourinario"
  },
  {
     id:"Z843",
     enfermedad:"Historia familiar de consanguinidad"
  },
  {
     id:"Z848",
     enfermedad:"Historia familiar de otras afecciones especificadas"
  },
  {
     id:"Z85",
     enfermedad:"Historia personal de tumor maligno"
  },
  {
     id:"Z850",
     enfermedad:"Historia personal de tumor maligno de organos digestivos"
  },
  {
     id:"Z851",
     enfermedad:"Historia personal de tumor maligno de traquea, bronquios y pulmon"
  },
  {
     id:"Z852",
     enfermedad:"Historia personal de tumor maligno de otros organos respiratorios e intratoracicos"
  },
  {
     id:"Z853",
     enfermedad:"Historia personal de tumor maligno de mama"
  },
  {
     id:"Z854",
     enfermedad:"Historia personal de tumor maligno de organos genitales"
  },
  {
     id:"Z855",
     enfermedad:"Historia personal de tumor maligno de vias urinarias"
  },
  {
     id:"Z856",
     enfermedad:"Historia personal de leucemia"
  },
  {
     id:"Z857",
     enfermedad:"Historia personal de otros tumores malignos del tejido linfoide, hematopoyetico y tejidos relacionados"
  },
  {
     id:"Z858",
     enfermedad:"Historia personal de tumor maligno de otros organos y sistemas"
  },
  {
     id:"Z859",
     enfermedad:"Historia personal de tumor maligno, de sitio no especificado"
  },
  {
     id:"Z86",
     enfermedad:"Historia personal de algunas otras enfermedades"
  },
  {
     id:"Z860",
     enfermedad:"Historia personal de otros tumores"
  },
  {
     id:"Z861",
     enfermedad:"Historia personal de enfermedades infecciosas y parasitarias"
  },
  {
     id:"Z862",
     enfermedad:"Historia personal de enfermedades de la sangre y de los organos hematopoyeticos y de ciertos trastornos del mecanismo inmunologico"
  },
  {
     id:"Z863",
     enfermedad:"Historia personal de enfermedades endocrinas, nutricionales y metabolicas"
  },
  {
     id:"Z864",
     enfermedad:"Historia personal de abuso de sustancias psicoactivas"
  },
  {
     id:"Z865",
     enfermedad:"Historia personal de otros trastornos mentales o del comportamiento"
  },
  {
     id:"Z866",
     enfermedad:"Historia personal de enfermedades del sistema nervioso y de los organos de los sentidos"
  },
  {
     id:"Z867",
     enfermedad:"Historia personal de enfermedades del sistema circulatorio"
  },
  {
     id:"Z87",
     enfermedad:"Historia personal de otras enfermedades y afecciones"
  },
  {
     id:"Z870",
     enfermedad:"Historia personal de enfermedades del sistema respiratorio"
  },
  {
     id:"Z871",
     enfermedad:"Historia personal de enfermedades del sistema digestivo"
  },
  {
     id:"Z872",
     enfermedad:"Historia personal de enfermedades de la piel y del tejido subcutaneo"
  },
  {
     id:"Z873",
     enfermedad:"Historia personal de enfermedades del sistema osteomuscular y del tejido conjuntivo"
  },
  {
     id:"Z874",
     enfermedad:"Historia personal de enfermedades del sistema genitourinario"
  },
  {
     id:"Z875",
     enfermedad:"Historia personal de complicaciones del embarazo, del parto y del puerperio"
  },
  {
     id:"Z876",
     enfermedad:"Historia personal de ciertas afecciones originadas en el periodo perinatal"
  },
  {
     id:"Z877",
     enfermedad:"Historia personal de malformaciones congenitas, deformidades y anomalias cromosomicas"
  },
  {
     id:"Z878",
     enfermedad:"Historia personal de otras afecciones especificadas"
  },
  {
     id:"Z88",
     enfermedad:"Historia personal de alergia a drogas, medicamentos y sustancias biologicas"
  },
  {
     id:"Z880",
     enfermedad:"Historia personal de alergia a penicilina"
  },
  {
     id:"Z881",
     enfermedad:"Historia personal de alergia a otros agentes antibioticos"
  },
  {
     id:"Z882",
     enfermedad:"Historia personal de alergia a sulfonamidas"
  },
  {
     id:"Z883",
     enfermedad:"Historia personal de alergia a otros agentes antiinfecciosos"
  },
  {
     id:"Z884",
     enfermedad:"Historia personal de alergia a agente anestesico"
  },
  {
     id:"Z885",
     enfermedad:"Historia personal de alergia a agente narcotico"
  },
  {
     id:"Z886",
     enfermedad:"Historia personal de alergia a agente analgesico"
  },
  {
     id:"Z887",
     enfermedad:"Historia personal de alergia a suero o vacuna"
  },
  {
     id:"Z888",
     enfermedad:"Historia personal de alergia a otras drogas, medicamentos y sustancias biologicas"
  },
  {
     id:"Z889",
     enfermedad:"Historia personal de alergia a otras drogas, medicamentos y sustancias biologicas no especificadas"
  },
  {
     id:"Z89",
     enfermedad:"Ausencia adquirida de miembros"
  },
  {
     id:"Z890",
     enfermedad:"Ausencia adquirida de dedo(s), [incluido el pulgar], unilateral"
  },
  {
     id:"Z891",
     enfermedad:"Ausencia adquirida de mano y muñeca"
  },
  {
     id:"Z892",
     enfermedad:"Ausencia adquirida de miembro superior por arriba de la muñeca"
  },
  {
     id:"Z893",
     enfermedad:"Ausencia adquirida de ambos miembros superiores [cualquier nivel]"
  },
  {
     id:"Z894",
     enfermedad:"Ausencia adquirida de pie y tobillo"
  },
  {
     id:"Z895",
     enfermedad:"Ausencia adquirida de pierna a nivel de o debajo de la rodilla"
  },
  {
     id:"Z896",
     enfermedad:"Ausencia adquirida de pierna por arriba de la rodilla"
  },
  {
     id:"Z897",
     enfermedad:"Ausencia adquirida de ambos miembros inferiores [cualquier nivel, excepto dedos del pie solamente]"
  },
  {
     id:"Z898",
     enfermedad:"Ausencia adquirida de miembros superiores e inferiores [cualquier nivel]"
  },
  {
     id:"Z899",
     enfermedad:"Ausencia adquirida de miembros no especificados"
  },
  {
     id:"Z90",
     enfermedad:"Ausencia adquirida de organos no clasificadas en otra parte"
  },
  {
     id:"Z900",
     enfermedad:"Ausencia adquirida de parte de la cabeza y del cuello"
  },
  {
     id:"Z901",
     enfermedad:"Ausencia adquirida de mama(s)"
  },
  {
     id:"Z902",
     enfermedad:"Ausencia adquirida (de parte) del pulmon"
  },
  {
     id:"Z903",
     enfermedad:"Ausencia adquirida de parte del estomago"
  },
  {
     id:"Z904",
     enfermedad:"Ausencia adquirida de otras partes del tubo digestivo"
  },
  {
     id:"Z905",
     enfermedad:"Ausencia adquirida de riñon"
  },
  {
     id:"Z906",
     enfermedad:"Ausencia adquirida de otras partes de las vias urinarias"
  },
  {
     id:"Z907",
     enfermedad:"Ausencia adquirida de organo(s) genital(es)"
  },
  {
     id:"Z908",
     enfermedad:"Ausencia adquirida de otros organos"
  },
  {
     id:"Z91",
     enfermedad:"Historia personal de factores de riesgo no clasificadas en otra parte"
  },
  {
     id:"Z910",
     enfermedad:"Historia personal de alergia, no debida a drogas ni a sustancias biologicas"
  },
  {
     id:"Z911",
     enfermedad:"Historia personal de incumplimiento del regimen o tratamiento medico"
  },
  {
     id:"Z912",
     enfermedad:"Historia personal de higiene personal deficiente"
  },
  {
     id:"Z913",
     enfermedad:"Historia personal del ciclo sueño"
  },
  {
     id:"Z914",
     enfermedad:"Historia personal de trauma psicologico, no clasificado en otra parte"
  },
  {
     id:"Z915",
     enfermedad:"Historia personal de lesion autoinfligida intencionalmente"
  },
  {
     id:"Z916",
     enfermedad:"Historia personal de otro trauma físico"
  },
  {
     id:"Z918",
     enfermedad:"Historia personal de otros factores de riesgo, no clasificados en otra parte"
  },
  {
     id:"Z92",
     enfermedad:"Historia personal de tratamiento medico"
  },
  {
     id:"Z920",
     enfermedad:"Historia personal de anticoncepcion"
  },
  {
     id:"Z921",
     enfermedad:"Historia personal de uso (presente) de anticoagulantes por largo tiempo"
  },
  {
     id:"Z922",
     enfermedad:"Historia personal de uso (presente) de otros medicamentos por largo tiempo"
  },
  {
     id:"Z923",
     enfermedad:"Historia personal de irradiacion"
  },
  {
     id:"Z924",
     enfermedad:"Historia personal de cirugia mayor, no clasificada en otra parte"
  },
  {
     id:"Z925",
     enfermedad:"Historia personal de medidas de rehabilitacion"
  },
  {
     id:"Z928",
     enfermedad:"Historia personal de otros tratamientos medicos"
  },
  {
     id:"Z929",
     enfermedad:"Historia personal de tratamiento medico no especificado"
  },
  {
     id:"Z93",
     enfermedad:"Aberturas artificiales"
  },
  {
     id:"Z930",
     enfermedad:"Traqueostomia"
  },
  {
     id:"Z931",
     enfermedad:"Gastrostomia"
  },
  {
     id:"Z932",
     enfermedad:"Ileostomia"
  },
  {
     id:"Z933",
     enfermedad:"Colostomia"
  },
  {
     id:"Z934",
     enfermedad:"Otros orificios artificiales del tubo gastrointestinal"
  },
  {
     id:"Z935",
     enfermedad:"Cistostomia"
  },
  {
     id:"Z936",
     enfermedad:"Otros orificios artificiales de las vias urinarias"
  },
  {
     id:"Z938",
     enfermedad:"Otras aberturas artificiales"
  },
  {
     id:"Z939",
     enfermedad:"Abertura artificial, no especificada"
  },
  {
     id:"Z94",
     enfermedad:"Organos y tejidos trasplantados"
  },
  {
     id:"Z940",
     enfermedad:"Trasplante de riñon"
  },
  {
     id:"Z941",
     enfermedad:"Trasplante de corazon"
  },
  {
     id:"Z942",
     enfermedad:"Trasplante de pulmon"
  },
  {
     id:"Z943",
     enfermedad:"Trasplante de corazon y pulmones"
  },
  {
     id:"Z944",
     enfermedad:"Trasplante de higado"
  },
  {
     id:"Z945",
     enfermedad:"Trasplante de piel"
  },
  {
     id:"Z946",
     enfermedad:"Trasplante de hueso"
  },
  {
     id:"Z947",
     enfermedad:"Trasplante de cornea"
  },
  {
     id:"Z948",
     enfermedad:"Otros organos y tejidos trasplantados"
  },
  {
     id:"Z949",
     enfermedad:"Organo y tejido trasplantado no especificado"
  },
  {
     id:"Z95",
     enfermedad:"Presencia de inplante e injertos cardiovasculares"
  },
  {
     id:"Z950",
     enfermedad:"Presencia de marcapaso cardiaco"
  },
  {
     id:"Z951",
     enfermedad:"Presencia de derivacion aortocoronaria"
  },
  {
     id:"Z952",
     enfermedad:"Presencia de valvula cardiaca protesica"
  },
  {
     id:"Z953",
     enfermedad:"Presencia de valvula cardiaca xenogenica"
  },
  {
     id:"Z954",
     enfermedad:"Presencia de otros reemplazos de valvula cardiaca"
  },
  {
     id:"Z955",
     enfermedad:"Presencia de angioplastia, injertos y protesis cardiovasculares"
  },
  {
     id:"Z958",
     enfermedad:"Presencia de otros injertos y protesis cardiovasculares"
  },
  {
     id:"Z959",
     enfermedad:"Presencia de injertos e implantes cardiovasculares no especificados"
  },
  {
     id:"Z96",
     enfermedad:"Presencia de otros implantes funcionales"
  },
  {
     id:"Z960",
     enfermedad:"Presencia de implante urogenital"
  },
  {
     id:"Z961",
     enfermedad:"Presencia de lentes intraoculares"
  },
  {
     id:"Z962",
     enfermedad:"Presencia de implantes oticos y auditivos"
  },
  {
     id:"Z963",
     enfermedad:"Presencia de laringe artificial"
  },
  {
     id:"Z964",
     enfermedad:"Presencia de implantes endocrinos"
  },
  {
     id:"Z965",
     enfermedad:"Presencia de implantes de raiz de diente y de mandibula"
  },
  {
     id:"Z966",
     enfermedad:"Presencia de implante ortopedico articular"
  },
  {
     id:"Z967",
     enfermedad:"Presencia de otros implantes de tendones y huesos"
  },
  {
     id:"Z968",
     enfermedad:"Presencia de otros implantes funcionales especificados"
  },
  {
     id:"Z969",
     enfermedad:"Presencia de implantes funcionales no especificados"
  },
  {
     id:"Z97",
     enfermedad:"Presencia de otros dispositivos"
  },
  {
     id:"Z970",
     enfermedad:"Presencia de ojo artificial"
  },
  {
     id:"Z971",
     enfermedad:"Presencia de miembro artificial (completo) (parcial)"
  },
  {
     id:"Z972",
     enfermedad:"Presencia de dispositivo protesico dental (completo) (parcial)"
  },
  {
     id:"Z973",
     enfermedad:"Presencia de anteojos y lentes de contacto"
  },
  {
     id:"Z974",
     enfermedad:"Presencia de audifono externo"
  },
  {
     id:"Z975",
     enfermedad:"Presencia de dispositivo anticonceptivo (intrauterino)"
  },
  {
     id:"Z978",
     enfermedad:"Presencia de otros dispositivos especificados"
  },
  {
     id:"Z98",
     enfermedad:"Otros estados postquirúrgicos"
  },
  {
     id:"Z980",
     enfermedad:"Estado de derivacion intestinal o anastomosis"
  },
  {
     id:"Z981",
     enfermedad:"Estado de artrodesis"
  },
  {
     id:"Z982",
     enfermedad:"Presencia de dispositivo para drenaje de liquido cefalorraquideo"
  },
  {
     id:"Z988",
     enfermedad:"Otros estados postquirúrgicos especificados"
  },
  {
     id:"Z99",
     enfermedad:"Dependencia de maquinas y dispositivos capacitantes, no clasificadas en otra parte"
  },
  {
     id:"Z990",
     enfermedad:"Dependencia de aspirador"
  },
  {
     id:"Z991",
     enfermedad:"Dependencia de respirador"
  },
  {
     id:"Z992",
     enfermedad:"Dependencia de dialisis renal"
  },
  {
     id:"Z993",
     enfermedad:"Dependencia de silla de ruedas"
  },
  {
     id:"Z998",
     enfermedad:"Dependencia de otras maquinas y dispositivos capacitantes"
  },
  {
     id:"Z999",
     enfermedad:"Dependencia de maquina y dispositivo capacitante, no especificada"
  },
  {
     id:"I1",
     enfermedad:"Enfermedades infecciosas intestinales"
  },
  {
     id:"I10",
     enfermedad:"Fiebres virales trasmitidas por artrópodos y fiebres virales hemorrágicas"
  },
  {
     id:"I11",
     enfermedad:"Infecciones virales caracterizadas por lesiones de la piel y de las membranas mucosas"
  },
  {
     id:"I12",
     enfermedad:"Hepatitis viral"
  },
  {
     id:"I13",
     enfermedad:"Enfermedad por virus de la inmunodeficiencia humana (HIV)"
  },
  {
     id:"I14",
     enfermedad:"Otras enfermedades virales"
  },
  {
     id:"I15",
     enfermedad:"Micosis"
  },
  {
     id:"I16",
     enfermedad:"Enfermedades debidas a protozoarios"
  },
  {
     id:"I17",
     enfermedad:"Helmintiasis"
  },
  {
     id:"I18",
     enfermedad:"Pediculosis. Acariasis y otras manifestaciones"
  },
  {
     id:"I19",
     enfermedad:"Secuelas de enfermedades infecciosas y parasitarias"
  },
  {
     id:"I2",
     enfermedad:"Tuberculosis"
  },
  {
     id:"I20",
     enfermedad:"Bacterias, virus y otros agentes infecciosos"
  },
  {
     id:"I21",
     enfermedad:"Otras enfermedades infecciosas"
  },
  {
     id:"I3",
     enfermedad:"Ciertas zoonosis bacterianas"
  },
  {
     id:"I4",
     enfermedad:"Otras enfermedades bacterianas"
  },
  {
     id:"I5",
     enfermedad:"Infecciones con modo de transmisión predominantemente sexual"
  },
  {
     id:"I6",
     enfermedad:"Otras enfermedades debidas a espiroquetas"
  },
  {
     id:"I7",
     enfermedad:"Otras enfermedades causadas por clamidias"
  },
  {
     id:"I8",
     enfermedad:"Rickettsiosis"
  },
  {
     id:"I9",
     enfermedad:"Infecciones virales del sistema nervioso central"
  },
  {
     id:"II1",
     enfermedad:"Tumores malignos de labio de la cavidad bucal y de la faringe"
  },
  {
     id:"II10",
     enfermedad:"Tumores malignos de las vías urinarias"
  },
  {
     id:"II11",
     enfermedad:"Tumores malignos del ojo del encéfalo y de otras partes del sistema nervioso"
  },
  {
     id:"II12",
     enfermedad:"Tumores malignos de la glándula tiroides y de otras glándulas endocrinas"
  },
  {
     id:"II13",
     enfermedad:"Tumores malignos de sitios mal definidos secundarios y de sitios no especificados"
  },
  {
     id:"II14",
     enfermedad:"Tumores malignos del tejido linfático, de los órganos hematopoyéticos y de tejidos afines"
  },
  {
     id:"II15",
     enfermedad:"Tumores malignos(primarios) de sitios múltiples independientes"
  },
  {
     id:"II16",
     enfermedad:"Tumores (neoplasias) in situ"
  },
  {
     id:"II17",
     enfermedad:"Tumores (neoplasias) benignos"
  },
  {
     id:"II18",
     enfermedad:"Tumores (neoplasias) de comportamiento incierto o desconocido"
  },
  {
     id:"II2",
     enfermedad:"Tumores malignos de los órganos digestivos"
  },
  {
     id:"II3",
     enfermedad:"Tumores malignos de los órganos respiratorios e intratorácicos"
  },
  {
     id:"II4",
     enfermedad:"Tumores malignos de los huesos y de los cartílagos articulares"
  },
  {
     id:"II5",
     enfermedad:"Melanoma y otros tumores malignos de la piel"
  },
  {
     id:"II6",
     enfermedad:"Tumores malignos de los tejidos mesoteliales y de los tejidos blandos"
  },
  {
     id:"II7",
     enfermedad:"Tumor maligno de mama"
  },
  {
     id:"II8",
     enfermedad:"Tumores malignos de los órganos genitales femeninos"
  },
  {
     id:"II9",
     enfermedad:"Tumores malignos de los órganos genitales masculinos"
  },
  {
     id:"III1",
     enfermedad:"Anemias nutricionales"
  },
  {
     id:"III2",
     enfermedad:"Anemias hemolíticas"
  },
  {
     id:"III3",
     enfermedad:"Anemias aplásticas y otras anemias"
  },
  {
     id:"III4",
     enfermedad:"Defectos de la coagulación, púrpura y otras afecciones hemorrágicas"
  },
  {
     id:"III5",
     enfermedad:"Otras enfermedades de la sangre y de los órganos hematopoyeticos"
  },
  {
     id:"III6",
     enfermedad:"Ciertos trastornos que afectan el mecanismo de la inmunidad"
  },
  {
     id:"IV1",
     enfermedad:"Trastornos de la glándula tiroides"
  },
  {
     id:"IV2",
     enfermedad:"Diabetes mellitus"
  },
  {
     id:"IV3",
     enfermedad:"Otros trastornos de la regulación de la glucosa y de la secreción interna del páncreas"
  },
  {
     id:"IV4",
     enfermedad:"Trastornos de otras glándulas endocrinas."
  },
  {
     id:"IV5",
     enfermedad:"Desnutrición"
  },
  {
     id:"IV6",
     enfermedad:"Otras deficiencias nutricionales"
  },
  {
     id:"IV7",
     enfermedad:"Obesidad y otros tipo de hiperalimentación"
  },
  {
     id:"IV8",
     enfermedad:"Trastornos metabólicos"
  },
  {
     id:"IX1",
     enfermedad:"Fiebre reumática aguda"
  },
  {
     id:"IX10",
     enfermedad:"Otros trastornos y los no especificados del sistema circulatorio"
  },
  {
     id:"IX2",
     enfermedad:"Enfermedades cardíacas reumáticas crónicas"
  },
  {
     id:"IX3",
     enfermedad:"Enfermedades hipertensivas"
  },
  {
     id:"IX4",
     enfermedad:"Enfermedades isquémicas del corazón"
  },
  {
     id:"IX5",
     enfermedad:"Enfermedad cardiopulmonar y enfermedades de la circulación pulmonar"
  },
  {
     id:"IX6",
     enfermedad:"Otras formas de enfermedad del corazón"
  },
  {
     id:"IX7",
     enfermedad:"Enfermedades cerebrovasculares"
  },
  {
     id:"IX8",
     enfermedad:"Enfermedades de las arterias, de las arteriales y de los vasos capilares"
  },
  {
     id:"IX9",
     enfermedad:"Enfermedades de las venas y de los vasos y ganglios linfáticos, no clasificados en otra parte"
  },
  {
     id:"V1",
     enfermedad:"Trastornos mentales orgánicos, incluidos los trastornos sintomáticos"
  },
  {
     id:"V10",
     enfermedad:"Trastorno mental no especificado"
  },
  {
     id:"v2",
     enfermedad:"Esquizofrenia,trastornos esquizotípicos y trastornos delirantes"
  },
  {
     id:"V3",
     enfermedad:"Trastornos del humor (afectivos)"
  },
  {
     id:"V4",
     enfermedad:"Trastornos neuróticos, trastornos relacionados con el stress y trastornos somatomorfos"
  },
  {
     id:"V5",
     enfermedad:"Síndromes del comportamiento asociados con alteraciones fisiológicas y factores físicos"
  },
  {
     id:"V6",
     enfermedad:"Trastornos de la personalidad y del comportamiento en adultos"
  },
  {
     id:"V7",
     enfermedad:"Retraso mental"
  },
  {
     id:"V8",
     enfermedad:"Trastornos del desarrollo psicológico"
  },
  {
     id:"V9",
     enfermedad:"Trastornos emocionales y del comportamiento que aparecen habitualmente en la niñez u en la adolescencia"
  },
  {
     id:"VI1",
     enfermedad:"Enfermedades inflamatorias del sistema nervioso central"
  },
  {
     id:"VI10",
     enfermedad:"Parálisis cerebral y otros síndromes paralíticos"
  },
  {
     id:"VI11",
     enfermedad:"Otros trastornos del sistema nervioso"
  },
  {
     id:"VI2",
     enfermedad:"Atrofias sistémicas que afectan principalmente el sistema nervioso central"
  },
  {
     id:"VI3",
     enfermedad:"Trastornos extrapiramidales y del movimiento"
  },
  {
     id:"VI4",
     enfermedad:"Otras enfermedades degenerativas del sistema nervioso"
  },
  {
     id:"VI5",
     enfermedad:"Enfermedades desmielinizantes del sistema nervioso central"
  },
  {
     id:"VI6",
     enfermedad:"Trastornos episódicos y paroxístico"
  },
  {
     id:"VI7",
     enfermedad:"Trastorno de los nervios,de las raíces y de los plexos nerviosos"
  },
  {
     id:"VI8",
     enfermedad:"Polineuropatías y otros trastornos del sistema nervioso periférico"
  },
  {
     id:"VI9",
     enfermedad:"Enfermedades musculares y de la unión neuromuscular"
  },
  {
     id:"VII1",
     enfermedad:"Trastornos del párpado, aparato lagrimal y órbita"
  },
  {
     id:"VII10",
     enfermedad:"Alteración de la visión y ceguera"
  },
  {
     id:"VII11",
     enfermedad:"Otros trastornos del ojo y sus anexos"
  },
  {
     id:"VII2",
     enfermedad:"Trastornos de la conjuntiva"
  },
  {
     id:"VII3",
     enfermedad:"Trastornos de la esclerótica, córnea, iris y cuerpo ciliar"
  },
  {
     id:"VII4",
     enfermedad:"Trastornos del cristalino"
  },
  {
     id:"VII5",
     enfermedad:"Trastornos de la coroides y retina"
  },
  {
     id:"VII6",
     enfermedad:"Glaucoma"
  },
  {
     id:"VII7",
     enfermedad:"Trastornos del cuerpo vitreo y del globo ocular"
  },
  {
     id:"VII8",
     enfermedad:"Trastornos del nervio óptico y de las vías ópticas"
  },
  {
     id:"VII9",
     enfermedad:"Trastornos de los músculos oculares del movimiento binocular de la acomodación y de la refracción"
  },
  {
     id:"VIII1",
     enfermedad:"Enfermedades del oído externo"
  },
  {
     id:"VIII2",
     enfermedad:"Enfermedades del oído medio y de la mastoides"
  },
  {
     id:"VIII3",
     enfermedad:"Enfermedades del oído interno"
  },
  {
     id:"VIII4",
     enfermedad:"Otros trastornos del oído"
  },
  {
     id:"X1",
     enfermedad:"Infecciones agudas de las vías respiratorias superiores"
  },
  {
     id:"X10",
     enfermedad:"Otras enfermedades del sistema respiratorio"
  },
  {
     id:"X2",
     enfermedad:"Influenza (gripe) y neumonía"
  },
  {
     id:"X3",
     enfermedad:"Otras infecciones agudas de las vías respiratorias inferiores"
  },
  {
     id:"X4",
     enfermedad:"Otras enfermedades de las vías respiratorias superiores"
  },
  {
     id:"X5",
     enfermedad:"Enfermedades crónicas de las vías respiratorias inferiores"
  },
  {
     id:"X6",
     enfermedad:"Enfermedades del pulmón debida a agentes externos"
  },
  {
     id:"X7",
     enfermedad:"Otras enfermedades respiratorias que afectan principalmente al intersticio"
  },
  {
     id:"X8",
     enfermedad:"Afecciones supurativas y necróticas de las vías respiratorias inferiores"
  },
  {
     id:"X9",
     enfermedad:"Otras enfermedades de la pleura"
  },
  {
     id:"XI1",
     enfermedad:"Enfermedades de la cavidad bucal de las glándulas salivales y de los maxilares"
  },
  {
     id:"XI10",
     enfermedad:"Otras enfermedades del sistema digestivo"
  },
  {
     id:"XI2",
     enfermedad:"Enfermedades del esófago, del estómago y del duodeno"
  },
  {
     id:"XI3",
     enfermedad:"Enfermedades del apéndice"
  },
  {
     id:"XI4",
     enfermedad:"Hernia"
  },
  {
     id:"XI5",
     enfermedad:"Enteritis y colitis no infecciosa"
  },
  {
     id:"XI6",
     enfermedad:"Otras enfermedades de los intestinos"
  },
  {
     id:"XI7",
     enfermedad:"Enfermedades del peritoneo"
  },
  {
     id:"XI8",
     enfermedad:"Enfermedades del hígado"
  },
  {
     id:"XI9",
     enfermedad:"Trastornos de la vesícula biliar, de las vías biliares del páncreas"
  },
  {
     id:"XII1",
     enfermedad:"Infecciones de la piel y del tejido subcutáneo"
  },
  {
     id:"XII2",
     enfermedad:"Dermatitis y eczema"
  },
  {
     id:"XII3",
     enfermedad:"Trastornos papuloescamosos"
  },
  {
     id:"XII4",
     enfermedad:"Urticaria y eritema"
  },
  {
     id:"XII5",
     enfermedad:"Trastornos de la piel y del tejido subcutáneo relacionados con radiación"
  },
  {
     id:"XII6",
     enfermedad:"Trastornos de las faneras"
  },
  {
     id:"XII7",
     enfermedad:"Otros trastornos de la piel y del tejido subcutáneo"
  },
  {
     id:"XIII1",
     enfermedad:"Artropatías infecciosas"
  },
  {
     id:"XIII10",
     enfermedad:"Otros trastornos de los tejidos blandos"
  },
  {
     id:"XIII11",
     enfermedad:"Trastornos de la densidad y de la estructura óseas"
  },
  {
     id:"XIII12",
     enfermedad:"Otras osteopatías"
  },
  {
     id:"XIII13",
     enfermedad:"Condropatías"
  },
  {
     id:"XIII14",
     enfermedad:"Otros trastornos del sistema osteomuscular y del tejido conjuntivo"
  },
  {
     id:"XIII2",
     enfermedad:"Artrosis"
  },
  {
     id:"XIII3",
     enfermedad:"Otros trastornos articulares"
  },
  {
     id:"XIII4",
     enfermedad:"Trastornos sistémicos del tejido conjuntivo"
  },
  {
     id:"XIII5",
     enfermedad:"Dorsopatías deformantes"
  },
  {
     id:"XIII6",
     enfermedad:"Espondilopatías"
  },
  {
     id:"XIII7",
     enfermedad:"Otras dorsopatias"
  },
  {
     id:"XIII8",
     enfermedad:"Trastornos de los músculos"
  },
  {
     id:"XIII9",
     enfermedad:"Trastornos de los tendones y de la sinovial"
  },
  {
     id:"XIV1",
     enfermedad:"Enfermedades glomerulares"
  },
  {
     id:"XIV10",
     enfermedad:"Trastornos no inflamatorios de los órganos pélvicos femeninos"
  },
  {
     id:"XIV11",
     enfermedad:"Otros trastornos del sistema genitourinario"
  },
  {
     id:"XIV2",
     enfermedad:"Enfermedad renal tubulointersticial"
  },
  {
     id:"XIV3",
     enfermedad:"Insuficiencia renal"
  },
  {
     id:"XIV4",
     enfermedad:"Litiasis urinaria"
  },
  {
     id:"XIV5",
     enfermedad:"Otros trastornos del riñon y del uréter"
  },
  {
     id:"XIV6",
     enfermedad:"Otras enfermedades del sistema urinario"
  },
  {
     id:"XIV7",
     enfermedad:"Enfermedades de los órganos genitales masculinos"
  },
  {
     id:"XIV8",
     enfermedad:"Trastornos de la mama"
  },
  {
     id:"XIV9",
     enfermedad:"Enfermedades inflamatorias de los órganos pélvicos femeninos"
  },
  {
     id:"XIX1",
     enfermedad:"Traumatismo de la cabeza"
  },
  {
     id:"XIX10",
     enfermedad:"Traumatismos del tobillo y del pie"
  },
  {
     id:"XIX11",
     enfermedad:"Traumatismos que afectan múltiples regiones del cuerpo"
  },
  {
     id:"XIX12",
     enfermedad:"Traumatismos de parte no especificada del tronco, miembro o región del cuerpo"
  },
  {
     id:"XIX13",
     enfermedad:"Efectos de cuerpos extraños que penetran por orificios naturales"
  },
  {
     id:"XIX14",
     enfermedad:"Quemaduras y corrosiones de la superficie externa del cuerpo, especificadas por sitio"
  },
  {
     id:"XIX15",
     enfermedad:"Quemaduras y corrosiones limitadas al ojo y órganos internos"
  },
  {
     id:"XIX16",
     enfermedad:"Quemaduras y corrosiones de múltiples regiones del cuerpo y las no especificadas"
  },
  {
     id:"XIX17",
     enfermedad:"Congelamiento"
  },
  {
     id:"XIX18",
     enfermedad:"Envenenamiento por drogas, medicamentos y sustancias biológicas"
  },
  {
     id:"XIX19",
     enfermedad:"Efectos tóxicos de sustancias de procedencia principalmente no medicinal"
  },
  {
     id:"XIX2",
     enfermedad:"Traumatismos del cuello"
  },
  {
     id:"XIX20",
     enfermedad:"Otros efectos y los no especificados de causas externas"
  },
  {
     id:"XIX21",
     enfermedad:"Algunas complicaciones precoces de traumatismos"
  },
  {
     id:"XIX22",
     enfermedad:"Complicaciones de la atención médica y quirúrgica, no clasificadas en otra parte"
  },
  {
     id:"XIX23",
     enfermedad:"Secuela de traumatismos, de envenenamientos y de otras consecuencia de causa externa"
  },
  {
     id:"XIX3",
     enfermedad:"Traumatismos del tórax"
  },
  {
     id:"XIX4",
     enfermedad:"Traumatismos del abdomen, de la región lumbosacra de la columna lumbar y de la pelvis"
  },
  {
     id:"XIX5",
     enfermedad:"Traumatismos del hombro y del brazo"
  },
  {
     id:"XIX6",
     enfermedad:"Traumatismos del antebrazo y del codo"
  },
  {
     id:"XIX7",
     enfermedad:"Traumatismos de la muñeca y de la mano"
  },
  {
     id:"XIX8",
     enfermedad:"Traumatismos de la cadera y del muslo"
  },
  {
     id:"XIX9",
     enfermedad:"Traumatismos de la rodilla y de la pierna"
  },
  {
     id:"XV1",
     enfermedad:"Embarazo terminado en aborto"
  },
  {
     id:"XV2",
     enfermedad:"Edema, proteinuria y trastornos hipertensivos en el embarazo, el parto y el puerperio"
  },
  {
     id:"XV3",
     enfermedad:"Otros trastornos maternos relacionados principalmente con el embarazo"
  },
  {
     id:"XV4",
     enfermedad:"Atención materna relacionada con el feto y la cavidad amniótica y con posibles problemas del parto"
  },
  {
     id:"XV5",
     enfermedad:"Complicaciones del trabajo de parto y del parto"
  },
  {
     id:"XV6",
     enfermedad:"Parto"
  },
  {
     id:"XV7",
     enfermedad:"Complicaciones principalmente relacionadas con el puerperio"
  },
  {
     id:"XV8",
     enfermedad:"Otras afecciones obstétricas no clasificadas en otra parte"
  },
  {
     id:"XVI1",
     enfermedad:"Feto y recién nacido afectados por factores maternos y por complicaciones del embarazo trabajo de parto y del parto"
  },
  {
     id:"XVI10",
     enfermedad:"Otros trastornos originados en el período perinatal"
  },
  {
     id:"XVI2",
     enfermedad:"Trastornos relacionados con la duración de la gestación y el crecimiento fetal"
  },
  {
     id:"XVI3",
     enfermedad:"Traumatismo del nacimiento"
  },
  {
     id:"XVI4",
     enfermedad:"Trastornos respiratorios y cardiovasculares específicos del período perinatal"
  },
  {
     id:"XVI5",
     enfermedad:"Infecciones específicas del período perinatral"
  },
  {
     id:"XVI6",
     enfermedad:"Trastornos hemorrágicos y hematológicos del feto y del recién nacido"
  },
  {
     id:"XVI7",
     enfermedad:"Trastornos endocrinos y metabólicos transitorios específicos del feto y del recién nacido"
  },
  {
     id:"XVI8",
     enfermedad:"Trastornos del sistema digestivo del feto y del recién nacido"
  },
  {
     id:"XVI9",
     enfermedad:"Afecciones asociadas con la regulación tegumentaria y la temperatura del feto y del recién nacido"
  },
  {
     id:"XVII1",
     enfermedad:"Malfomaciones congénitas del sistema nervioso"
  },
  {
     id:"XVII10",
     enfermedad:"Otras malformaciones congénitas"
  },
  {
     id:"XVII11",
     enfermedad:"Anomalías cromosómicas no clasificadas en otra parte"
  },
  {
     id:"XVII2",
     enfermedad:"Malformaciones congénitas del ojo, del oído de la cara y del cuello"
  },
  {
     id:"XVII3",
     enfermedad:"Malformaciones congénitas del sistema circulatorio"
  },
  {
     id:"XVII4",
     enfermedad:"Malformaciones congénitas del sistema respiratorio"
  },
  {
     id:"XVII5",
     enfermedad:"Fisura del paladar y labio leporino"
  },
  {
     id:"XVII6",
     enfermedad:"Otras malformaciones congénitas del sistema digestivo"
  },
  {
     id:"XVII7",
     enfermedad:"Malformaciones congénitas de los órganos genitales"
  },
  {
     id:"XVII8",
     enfermedad:"Malformaciones congénitas del sistema urinario"
  },
  {
     id:"XVII9",
     enfermedad:"Malformaciones y deformidades congénitas del sistema osteomuscular"
  },
  {
     id:"XVIII1",
     enfermedad:"Síntomas y signos que involucran los sistemas circulatorios y respiratorios"
  },
  {
     id:"XVIII10",
     enfermedad:"Hallazgos anormales en el examen de orina sin diagnóstico"
  },
  {
     id:"XVIII11",
     enfermedad:"Hallazgos anormales en el examen de otros líquidos sustancias y tejidos corporales sin diagnóstico"
  },
  {
     id:"XVIII12",
     enfermedad:"Hallazgos anormales en diagnóstico por imágenes y en estudios funcionales, sin diagnóstico"
  },
  {
     id:"XVIII2",
     enfermedad:"Síntomas y signos que involucran el sistema digestivo y el abdomen"
  },
  {
     id:"XVIII3",
     enfermedad:"Síntomas y signos que involucran la piel y el tejido subcutáneo"
  },
  {
     id:"XVIII4",
     enfermedad:"Síntomas y signos que involucran los sistemas nervioso y osteomuscular"
  },
  {
     id:"XVIII5",
     enfermedad:"Síntomas y signos que involucran el sistema urinario"
  },
  {
     id:"XVIII6",
     enfermedad:"Síntomas y signos que involucran el conocimiento la percepción, el estado emocional y la conducta"
  },
  {
     id:"XVIII7",
     enfermedad:"Síntomas y signos que involucran el habla y la voz"
  },
  {
     id:"XVIII8",
     enfermedad:"Síntomas y signos generales"
  },
  {
     id:"XVIII9",
     enfermedad:"Hallazgos anormales en el examen de sangre sin diagnóstico"
  },
  {
     id:"XX1",
     enfermedad:"Peatón lesionado en accidente de transporte"
  },
  {
     id:"XX10",
     enfermedad:"Accidentes de transporte por agua"
  },
  {
     id:"XX11",
     enfermedad:"Accidentes de transporte aéreo y espacial"
  },
  {
     id:"XX12",
     enfermedad:"Otros accidentes de transporte y los no especificados"
  },
  {
     id:"XX13",
     enfermedad:"Otras causas externas de traumatismos accidentales Caídas"
  },
  {
     id:"XX14",
     enfermedad:"Exposición a fuerzas mecánicas inanimadas"
  },
  {
     id:"XX15",
     enfermedad:"Exposición a fuerzas mecánicas animadas"
  },
  {
     id:"XX16",
     enfermedad:"Ahogamiento y sumersión accidentales"
  },
  {
     id:"XX17",
     enfermedad:"Otros accidentes que obstruyen la respiración"
  },
  {
     id:"XX18",
     enfermedad:"Exposición a la corriente eléctrica radiación y temperatura y presión del aire ambientales extremas"
  },
  {
     id:"XX19",
     enfermedad:"Exposición al fuego humo y llamas"
  },
  {
     id:"XX2",
     enfermedad:"Ciclista lesionado en accidente de transporte"
  },
  {
     id:"XX20",
     enfermedad:"Contacto con calor y sustancias calientes"
  },
  {
     id:"XX21",
     enfermedad:"Contacto traumático con animales y plantas venenosas"
  },
  {
     id:"XX22",
     enfermedad:"Exposición a fuerzas de la naturaleza"
  },
  {
     id:"XX23",
     enfermedad:"Envenenamiento accidental por, y exposición a sustancias nocivas"
  },
  {
     id:"XX24",
     enfermedad:"Exceso de esfuerzo, viajes y privación"
  },
  {
     id:"XX25",
     enfermedad:"Exposición accidental a otros factores y a los no especificados"
  },
  {
     id:"XX26",
     enfermedad:"Lesiones autoinfligidas intencionalmente"
  },
  {
     id:"XX27",
     enfermedad:"Agresiones"
  },
  {
     id:"XX28",
     enfermedad:"Eventos de intención no determinada"
  },
  {
     id:"XX29",
     enfermedad:"Intervención legal y operaciones de guerra"
  },
  {
     id:"XX3",
     enfermedad:"Motociclista lesionado en accidente de transporte"
  },
  {
     id:"XX30",
     enfermedad:"Drogas medicamentos y sustancias biológicas causantes de efectos adversos en su uso terapéutico"
  },
  {
     id:"XX31",
     enfermedad:"Incidentes ocurridos al paciente durante la atención médica y quirúrgica"
  },
  {
     id:"XX32",
     enfermedad:"Dispositivos médicos de diagnóstico y de uso terapéutico asociados con incidentes adversos"
  },
  {
     id:"XX33",
     enfermedad:"Procedimientos quirúrgicos y otros procedimientos médicos como causa de reacción anormal del paciente o complicación posterior, sin mención de incidente en el momento de efectuar el procedimiento"
  },
  {
     id:"XX34",
     enfermedad:"Secuelas de causas externas de morbilidad y mortalidad"
  },
  {
     id:"XX35",
     enfermedad:"Factores suplementarios relacionados con causas de morbilidad y de mortalidad clasificadas en otra parte"
  },
  {
     id:"XX4",
     enfermedad:"Ocupante de vehículo de motor de tres ruedas lesionado en accidente de transporte"
  },
  {
     id:"XX5",
     enfermedad:"Ocupante de automóvil lesionado en accidente de transporte"
  },
  {
     id:"XX6",
     enfermedad:"Ocupante de camioneta o furgoneta lesionado en accidente de transporte"
  },
  {
     id:"XX7",
     enfermedad:"Ocupante de vehículo de transporte pesado lesionado en accidente de transporte"
  },
  {
     id:"XX8",
     enfermedad:"Ocupante de autobús lesionado en accidente de transporte"
  },
  {
     id:"XX9",
     enfermedad:"Otros accidentes de transporte terrestre"
  },
  {
     id:"XXI1",
     enfermedad:"Personas en contacto con los servicios de salud para investigación o exámenes"
  },
  {
     id:"XXI2",
     enfermedad:"Personas con riesgos potenciales para su salud, relacionados con enfermedades transmisibles"
  },
  {
     id:"XXI3",
     enfermedad:"Personas en contacto con los servicios de salud en circunstancias relacionadas con la reproducción"
  },
  {
     id:"XXI4",
     enfermedad:"Personas en contacto con los servicios de salud para procedimientos específicos y cuidados de salud"
  },
  {
     id:"XXI5",
     enfermedad:"Personas con riesgos potenciales para su salud, relacionados con circunstancias económicas y psicosociales"
  },
  {
     id:"XXI6",
     enfermedad:"Personas en contacto con los servicios de salud por otras circunstancias"
  },
  {
     id:"XXI7",
     enfermedad:"Personas con riesgos potenciales para su salud relacionados con su historia familiar y personal y algunas condiciones que influyen en su estado de salud"
  },
  {
     id:"XXII",
     enfermedad:"Codigos para situaciones especiales"
  }
];