import { useEffect, useState } from 'react';
import AddAppointmentModal from '../Modals/AddApointmentModal';
import { AppointmentTable } from '../Tables';
import Modal from '../Modals/Modal';
import NewMedicalRecode from '../../screens/Patients/NewMedicalRecode';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCitas, getCitas, getCitasByPatientId, getCitasStatus } from '../../redux/features/citasSlice';
import { useParams } from 'react-router-dom';
import { resetVisita } from '../../redux/features/visitasSlice';

function AppointmentsUsed({ doctor }) {
  const {id} = useParams();
  const [open, setOpen] = useState(false);
  const [attention, setAttention] = useState(false);
  const [data, setData] = useState({});
  const [cita, setCita] = useState(null);

  const dispatch = useDispatch();
  const citas = useSelector(getAllCitas);
  const citasStatus = useSelector(getCitasStatus);

  useEffect(() => {
    if(id) dispatch(getCitasByPatientId(id));
    else dispatch(getCitas());
  }, []);
  const handleEventClick = (event) => {
    setData(event);
    setOpen(!open);
  };

  const handleAtention = (id) => {
    dispatch(resetVisita());
    setCita(citas.find(item => item.id === id));
    setAttention(!attention);
  }
  // handle modal close
  const handleClose = () => {
    setOpen(!open);
    setData({});
  };
  return (
    <div className="w-full">
      {open && (
        <AddAppointmentModal
          datas={data}
          isOpen={open}
          closeModal={() => {
            handleClose();
          }}
        />
      )}
      {attention && (
        <Modal title={`Atención tipo ${cita.tipo_evento}`} isOpen={attention} closeModal={() => {setAttention();}}>
          <NewMedicalRecode cita={cita}/>
        </Modal>
      )}
      <h1 className="text-sm font-medium mb-6">Agenda de Paciente</h1>
      <div className="w-full overflow-x-scroll">
        <AppointmentTable
          data={citas}
          doctor={doctor}
          functions={{
            preview: handleEventClick,
            attention: handleAtention
          }}
        />
      </div>
    </div>
  );
}

export default AppointmentsUsed;
