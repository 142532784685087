import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Input,
  Select,
  Textarea,
} from "../../components/Form";

import { BiChevronDown } from "react-icons/bi";

import { RiSave3Fill } from "react-icons/ri";
import SearchBar from "../../components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { getAllPatients, getPatients } from "../../redux/features/patientSlice";
import { useForm } from "react-hook-form";
import ConfirmationAlert from "../../components/ConfirmationAlert";
import {
  createPayment,
  getPaymentStatus,
} from "../../redux/features/paymentSlice";
import Loading from "../Loading";

const metodosPago = [
  {
    id: 1,
    name: "Efectivo",
  },
  {
    id: 2,
    name: "Tarjeta",
  },
  {
    id: 3,
    name: "Transferencia",
  },
  {
    id: 4,
    name: "Otro",
  },
];

function EditPaymentForm({ operation = "create" }) {
  const [selected, setSelected] = useState(null);
  const [metodoPago, setMetodoPago] = useState(metodosPago[0]);

  const { id } = useParams();

  const patients = useSelector(getAllPatients);
  const paymentStatus = useSelector(getPaymentStatus);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getPatients());
  }, []);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({});

  const sendPayment = async (data) => {
    const res = await dispatch(createPayment(data));
    if (res?.error) {
      toast.error(
        "No se pudo realizar el registro de pago: " + res?.error?.message,
        { duration: 5000 }
      );
    } else {
      toast.success("Pago realizado con exito!", { duration: 5000 });
      navigate(0);
    }
  };
  const onSubmit = async (data) => {
    data.patient_id = id;
    data.metodo = metodoPago.name;
    ConfirmationAlert({
      title: "Registro de Pago",
      text: "Esta seguro de registrar el pago?",
      onAccept: () => sendPayment(data),
    });
  };
  return (
    <>
      {paymentStatus === "loading" ? (
        <Loading />
      ) : (
        <div
          data-aos-duration="1000"
          data-aos-delay="100"
          data-aos-offset="200"
          className="bg-white my-8 rounded-xl border-[1px] border-border p-5"
        >
          <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-2 items-center">
            <div className="lg:col-span-3 flex items-center gap-2">
              <img
                src="/images/logo.png"
                alt="logo"
                className=" w-32 object-contain"
              />
            </div>

            <div className="flex flex-col gap-4 sm:items-end">
              <div className="flex gap-4">
                <p className="text-sm font-extralight">Fecha:</p>
                <h6 className="text-xs font-medium">01/05/2024</h6>
              </div>
              <div className="flex gap-4">
                <p className="text-sm font-extralight">Id Paciente:</p>
                <h6 className="text-xs font-medium">{id}</h6>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-6 gap-6 mt-8 items-start">
            <div className="lg:col-span-6 col-span-6">
              {!id && (
                <SearchBar
                  placeholder="Escriba nombre o CI para buscar al paciente"
                  data={patients.data}
                  setSelected={setSelected}
                  value={
                    selected
                      ? `${selected.persona.ci} - ${selected.persona.nombre} ${selected.persona.apellidos}`
                      : null
                  }
                />
              )}
              <div className="p-6 border grid grid-cols-1 sm:grid-cols-3 gap-3 border-border rounded-xl w-full my-5">
                <Input
                  label="Monto"
                  type="number"
                  color={true}
                  placeholder="0.0s"
                  register={register("monto", {
                    required: "Este campo es requerido",
                    min: {
                      value: 0.01,
                      message: "El monto debe ser mayor a 0 Bs.",
                    },
                  })}
                  error={errors.monto}
                />

                <div className="flex w-full flex-col gap-3">
                  <p className="text-black text-sm">Método de Pago</p>
                  <Select
                    selectedPerson={metodoPago}
                    setSelectedPerson={setMetodoPago}
                    datas={metodosPago}
                  >
                    <div className="w-full flex-btn text-sm p-4 border border-border font-light rounded-lg focus:border focus:border-subMain">
                      {metodoPago?.name} <BiChevronDown className="text-xl" />
                    </div>
                  </Select>
                </div>
                <Input
                  label="Motivo"
                  type="text"
                  color={true}
                  placeholder="Consulta | Laboratorio"
                  register={register("motivo", {
                    required: "Este campo es requerido",
                  })}
                  error={errors.motivo}
                />
              </div>
              <Textarea
                label="Observaciones"
                placeholder="Detalles u observaciones del Pago"
                color={true}
                rows={3}
                className="col-span-3"
                register={register("observaciones")}
                name="observaciones"
                setValue={setValue}
                getValue={getValues}
              />
            </div>
          </div>
          <Button
            label="Registrar Pago"
            onClick={handleSubmit(onSubmit)}
            Icon={RiSave3Fill}
          />
        </div>
      )}
    </>
  );
}

export default EditPaymentForm;
