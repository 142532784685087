import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import {
  Button,
  DatePickerComp,
  FromToDate,
  Input,
  Select,
  Switchi,
  TimePickerComp,
} from "../components/Form";
import { NotificationTable, Transactiontable } from "../components/Tables";
import { sortsDatas, transactionData } from "../components/Datas";
import { BiChevronDown, BiPlus, BiTime } from "react-icons/bi";
import {
  MdEditCalendar,
  MdFilterList,
  MdOutlineCalendarMonth,
  MdOutlineCloudDownload,
} from "react-icons/md";
import { toast } from "react-hot-toast";
import { BsCalendarMonth } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import ThreeColumRow from "../components/ThreeColumRow";
import TwoColumRow from "../components/TwoColumRow";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import {
  createNotification,
  deleteNotification,
  getAllNotifications,
  getNotifications,
} from "../redux/features/notificationSlice";
import NewPatientFast from "../components/Modals/NewPatientFast";
import { getAllPatients, getPatients } from "../redux/features/patientSlice";
import SearchBar from "../components/SearchBar";
import ConfirmationAlert from "../components/ConfirmationAlert";
import { useForm } from "react-hook-form";

function Notifications() {
  const [edit, setEdit] = useState(null);
  const [openPatient, setOpenPatient] = useState(false);
  const [selected, setSelected] = useState(false);
  const [fecha, setFecha] = useState(new Date());
  const [startTime, setStartTime] = useState(fecha);
  const [sistema, setSistema] = useState(false);
  const [mail, setMail] = useState(false);

  const notifications = useSelector(getAllNotifications);
  const patients = useSelector(getAllPatients);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(getPatients());
    dispatch(getNotifications());
  }, [dispatch, notifications.length]);

  const editNotification = (item) => {
    setEdit(item);
    setFecha(new Date(item.fecha));
    setSelected(item.patient);
    setSistema(item.sistema);
    setStartTime(new Date(item.fecha));
    setMail(item.mail);
    setValue("motivo", item.motivo);
  };
  // preview
  const bajaNotification = (id) => {
    ConfirmationAlert({
      title: `Baja Notificación`,
      text: `Esta seguro de dar de baja la Notificación?`,
      onAccept: async () => {
        const resDelete = await dispatch(deleteNotification(id));
        if (resDelete.error) {
          toast.error(
            "Ocurrio un error al dar baja la notificación: " + resDelete.error,
            {
              duration: 5000,
            }
          );
        } else {
          dispatch(getNotifications());
          toast.success(
            "Se realizo la baja de la notificación correctamente!",
            { duration: 5000 }
          );
        }
      },
    });
  };

  const onSubmit = (body) => {
    if (selected?.id) {
      ConfirmationAlert({
        title: `${edit?.id ? "Editar" : "Registrar"} Notificación`,
        text: `Esta seguro de ${
          edit?.id ? "editar" : "registrar"
        } la Notificación?`,
        onAccept: async () => {
          let auxFecha = new Date(fecha.setHours(startTime.getHours()));
          auxFecha = new Date(
            auxFecha.setMinutes(startTime.getMinutes())
          ).toISOString();
          body = {
            ...body,
            fecha: auxFecha,
            estado: edit?.estado ?? "pendiente",
            sistema,
            mail,
            tipo: "manual",
            patient_id: selected.id,
          };
          if (edit?.id) {
            body.id = edit.id;
          }

          const res = await dispatch(createNotification(body));
          if (res.error) {
            toast.error(
              `Ocurrio un error al ${
                edit?.id ? "editar" : "registrar"
              } la notificación: ` + res.error,
              {
                duration: 5000,
              }
            );
          } else {
            setEdit(null);
            dispatch(getNotifications());
            toast.success(
              `Notificación ${edit?.id ? "editada" : "creada"} correctamente!`,
              { duration: 5000 }
            );
            setSelected(null);
            setFecha(new Date());
            setStartTime(new Date());
            setSistema(false);
            setMail(false);
            setValue("motivo", "");
          }
        },
      });
    } else {
      toast.error("Seleccione al Paciente");
    }
  };
  return (
    <Layout>
      <h1 className="text-xl font-semibold">Notificaciones</h1>

      {/* datas */}
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="10"
        data-aos-offset="200"
        className="bg-white my-8 rounded-xl border-[1px] border-border p-5"
      >
        <div className="border rounded-lg p-5">
          <h2 className=" font-semibold text-md py-5">
            {`${edit?.id ? "Editar" : "Nueva"}`} Notificación
          </h2>
          <ThreeColumRow>
            <div className="gap-4 w-full items-center">
              {openPatient && (
                <NewPatientFast
                  open={openPatient}
                  setOpen={setOpenPatient}
                  setPatient={setSelected}
                />
              )}
              <div className="z-50">
                <label className={"text-black text-sm block"}>Paciente:</label>
                <SearchBar
                  key={selected ?? 1}
                  placeholder="Escriba nombre o CI para buscar al paciente"
                  data={patients?.data}
                  setSelected={setSelected}
                  value={
                    selected
                      ? `${
                          selected.persona.ci.length > 10
                            ? selected.persona.ci.slice(0, 8)
                            : selected.persona.ci
                        } - ${selected.persona.nombre} ${
                          selected.persona.apellidos
                        }`
                      : ""
                  }
                  setOpen={setOpenPatient}
                />
              </div>
            </div>

            <TwoColumRow>
              <DatePickerComp
                label="Fecha"
                startDate={fecha}
                onChange={(date) => setFecha(date)}
              />
              <div>
                <TimePickerComp
                  label="Hora"
                  startDate={startTime}
                  onChange={(date) => setStartTime(date)}
                />
              </div>
            </TwoColumRow>
            <div className="mt-8">
              <Button
                label={edit?.id ? "Guardar Cambios" : "Registrar"}
                Icon={HiOutlineCheckCircle}
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </ThreeColumRow>
          <TwoColumRow>
            <div className="w-1/2">
              <Input
                label="Motivo"
                type="text"
                register={register("motivo", {
                  required: "Este campo es requerido",
                })}
                error={errors.motivo}
                color={true}
              />
            </div>
            <div className="flex gap-5 p-5 justify-center">
              <TwoColumRow>
                <h2>Tipo de Notificación</h2>
                <TwoColumRow>
                  <Switchi checked={mail} label="Email" onChange={setMail} />
                  <Switchi
                    checked={sistema}
                    label="Web"
                    onChange={setSistema}
                  />
                </TwoColumRow>
              </TwoColumRow>
            </div>
          </TwoColumRow>
        </div>
        <div className="mt-8 w-full overflow-x-scroll">
          <NotificationTable
            notifications={notifications}
            action={true}
            functions={{
              edit: editNotification,
              baja: bajaNotification,
            }}
          />
        </div>
      </div>
    </Layout>
  );
}

export default Notifications;
