import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { BiUserPlus } from "react-icons/bi";
import { RiLockPasswordLine } from "react-icons/ri";
import PersonalInfo from "../components/UsedComp/PersonalInfo";
import ChangePassword from "../components/UsedComp/ChangePassword";
import { Button, Input, Switchi, Textarea } from "../components/Form";
import { HiOutlineCheckCircle } from "react-icons/hi";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllConfiguration,
  getConfiguration,
  getConfigurationStatus,
  updateConfiguration,
} from "../redux/features/configurationSlice";
import { useForm } from "react-hook-form";
import TwoColumRow from "../components/TwoColumRow";
import ThreeColumRow from "../components/ThreeColumRow";
import MultiSelect from "../components/MultiSelect";
import ConfirmationAlert from "../components/ConfirmationAlert";
import Loading from "./Loading";

const options = [
  {
    id: "tiempo1",
    name: "30 minutos",
  },
  {
    id: "tiempo2",
    name: "1 hora",
  },
  {
    id: "tiempo3",
    name: "3 horas",
  },
  {
    id: "tiempo4",
    name: "6 horas",
  },
  {
    id: "tiempo5",
    name: "12 horas",
  },
  {
    id: "tiempo6",
    name: "1 día",
  },
  {
    id: "tiempo7",
    name: "2 días",
  },
  {
    id: "tiempo8",
    name: "1 semana",
  },
  {
    id: "tiempo9",
    name: "1 mes",
  },
];
function Settings() {
  const [selected, setSelected] = useState([]);
  const [sistema, setSistema] = useState(false);
  const [mail, setMail] = useState(false);
  const dispatch = useDispatch();

  const configuration = useSelector(getAllConfiguration);
  const status = useSelector(getConfigurationStatus);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    if (configuration === "idle") {
      dispatch(getConfiguration());
    }

    if (configuration !== "idle") {
      setValue("email", configuration?.email);
      setValue("password", configuration?.password);
      setValue("email_secretaria", configuration?.email_secretaria);
      setValue("port", configuration?.port);
      setValue("host", configuration?.host);
      setValue("template", configuration?.template);
      setValue("mailer", configuration?.mailer);
      if (configuration.tiempo1 && !selected.includes(options[0])) {
        setSelected((prev) => [...prev, options[0]]);
      }
      if (configuration.tiempo2 && !selected.includes(options[1])) {
        setSelected((prev) => [...prev, options[1]]);
      }
      if (configuration.tiempo3 && !selected.includes(options[2])) {
        setSelected((prev) => [...prev, options[2]]);
      }
      if (configuration.tiempo4 && !selected.includes(options[3])) {
        setSelected((prev) => [...prev, options[3]]);
      }
      if (configuration.tiempo5 && !selected.includes(options[4])) {
        setSelected((prev) => [...prev, options[4]]);
      }
      if (configuration.tiempo6 && !selected.includes(options[5])) {
        setSelected((prev) => [...prev, options[5]]);
      }
      if (configuration.tiempo7 && !selected.includes(options[6])) {
        setSelected((prev) => [...prev, options[6]]);
      }
      if (configuration.tiempo8 && !selected.includes(options[7])) {
        setSelected((prev) => [...prev, options[7]]);
      }
      if (configuration.tiempo9 && !selected.includes(options[8])) {
        setSelected((prev) => [...prev, options[8]]);
      }
      if (configuration.notificacion_system) {
        setSistema(true);
      }
      if (configuration.notificacion_email) {
        setMail(true);
      }
    }
  }, [dispatch, configuration.id]);

  const onSubmit = (body) => {
    ConfirmationAlert({
      title: "Guardar Configuracion",
      text: "Esta seguro en guardar la configuracion?",
      onAccept: () => {
        options.forEach((option) => {
          body[option?.id] = selected.includes(option);
        });

        body.notificacion_system = sistema;
        body.notificacion_email = mail;

        dispatch(updateConfiguration(body));
      },
    });
  };

  return (
    <Layout>
      <h1 className="text-xl font-semibold ">Configuracion</h1>
      {status === "loading" ? (
        <Loading />
      ) : (
        <div className="flex flex-col items-center">
          <div className="flex flex-col gap-2 mt-2 w-full max-w-[48rem] bg-white p-8 rounded-xl border">
            <TwoColumRow>
              <Input
                label="Email"
                color={true}
                type="email"
                register={register("email", {
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "No coincide con el formato de correo electronico",
                  },
                })}
                error={errors.email}
              />
              <Input
                label="Password"
                color={true}
                type="text"
                register={register("password")}
              />
            </TwoColumRow>
            <ThreeColumRow>
              <Input
                label="Host"
                color={true}
                type="text"
                register={register("host")}
              />
              <Input
                label="Mailer"
                color={true}
                type="text"
                register={register("mailer")}
              />
              <Input
                label="Puerto"
                color={true}
                type="number"
                register={register("port")}
              />
            </ThreeColumRow>

            <p>Tiempo Antes de cada Notificación</p>
            <MultiSelect
              options={options}
              value={selected}
              setSelected={setSelected}
              placeholder="Notificaciones antes de la cita ..."
            />
            <TwoColumRow>
              <Input
                label="Email Secreataria"
                color={true}
                type="email"
                register={register("email_secretaria", {
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "No coincide con el formato de correo electronico",
                  },
                })}
                error={errors.email_secretaria}
              />
              <div className="mt-8 p-3 border rounded-xl">
                <TwoColumRow>
                  <Switchi checked={mail} label="Email" onChange={setMail} />
                  <Switchi
                    checked={sistema}
                    label="Web"
                    onChange={setSistema}
                  />
                </TwoColumRow>
              </div>
            </TwoColumRow>

            <Textarea
              register={register("template", {
                required: "El motivo de la cita es requerido.",
              })}
              name="template"
              setValue={setValue}
              getValue={getValues}
              label="Template de mensaje Email"
              placeholder="El template debe estar en formato html"
              color={true}
              rows={5}
            />
            <Button
              label={"Guardar parametros"}
              Icon={HiOutlineCheckCircle}
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      )}
    </Layout>
  );
}

export default Settings;
