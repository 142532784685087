import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useNavigate } from "react-router-dom";

import {
  Button,
  DinamicInput,
  DinamicInputWithSearchBar,
  Input,
  Select,
  Textarea,
} from "../../components/Form";
import { getAge } from "../../utils/age";
import { BiChevronDown, BiPlus } from "react-icons/bi";
import { LaboratoryTable, MedicineDosageTable } from "../../components/Tables";
import { toast } from "react-hot-toast";
import MedicineDosageModal from "../../components/Modals/MedicineDosage";
import { FaFileDownload } from "react-icons/fa";
import { HiOutlineCheckCircle } from "react-icons/hi";
import ThreeColumRow from "../../components/ThreeColumRow";
import TwoColumRow from "../../components/TwoColumRow";
import LaboratoryServiceModal from "../../components/Modals/LaboratoryServiceModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getVisita,
  getVisitasByCitaId,
  updateVisita,
} from "../../redux/features/visitasSlice";
import { useForm } from "react-hook-form";
import ConfirmationAlert from "../../components/ConfirmationAlert";
import { Enfermedades } from "../../components/EnfermedadesCIE";
import {
  createReceta,
  deleteRecetaByVisitaId,
  getAllRecetas,
  getRecetasById,
} from "../../redux/features/recetaSlice";
import { pdf } from "@react-pdf/renderer";
import RecetaPdf from "../../pdf/RecetaPdf";
import { saveAs } from "file-saver";
import {
  deleteEstudio,
  getAllEstudiosByCita,
  getEstudiosByCitaId,
} from "../../redux/features/estudiosSlice";
import OrdenPdf from "../../pdf/OrdenPdf";
import { RiDeleteBinLine } from "react-icons/ri";
import HistoriaPdf from "../../pdf/HistoriaPdf";

const lugaresAtencion = [
  {
    id: 0,
    name: 'Ninguno',
  },
  {
    id: 1,
    name: "Área Médica",
  },
  {
    id: 2,
    name: "Medical Center",
  },
  {
    id: 3,
    name: "CDLA (Clínica de Las Américas)",
  },
];

function NewMedicalRecode({ cita }) {
  const [isOpen, setIsOpen] = useState(false);
  const [labOpen, setLabOpen] = useState(false);
  const [motivo, setMotivo] = useState("");
  const [lugarAtencion, setLugarAtencion] = useState(lugaresAtencion[0]);
  const [valueIMC, setValueIMC] = useState("Ingrese el peso y la altura.");
  const [diagnostico, setDiagnostico] = useState("");
  const [receta, setReceta] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const visita = useSelector(getVisita);
  const recetasFromBack = useSelector(getAllRecetas);
  const estudios = useSelector(getAllEstudiosByCita);

  //const {data: estudios, isLoading, error } = useEstudiosCita(cita?.id);


  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(getVisitasByCitaId(cita?.id));
    dispatch(getEstudiosByCitaId(cita?.id));
    if (visita?.id) {
      dispatch(getRecetasById(visita.id));
      if (visita?.motivo) {
        setValue("motivo", visita.motivo);
      }
      setDiagnostico(visita.diagnostico);
      if (visita.lugar_atencion)
        setLugarAtencion(
          lugaresAtencion.find((lugar) => lugar.name === visita.lugar_atencion)
        );
      setValue("altura", visita.altura);
      setValue("comentarios", visita.comentarios);
      setValue("conducta", visita.conducta);
      setValue("desglose_motivo", visita.desglose_motivo);
      setValue("examen_fisico", visita.examen_fisico);
      setValue("f_cardiaca", visita.f_cardiaca);
      setValue("f_respiratoria", visita.f_respiratoria);
      setValue("p_arterial_1", visita.p_arterial_1);
      setValue("p_arterial_5", visita.p_arterial_5);
      setValue("peso", visita.peso);
      setValue("temperatura", visita.temperatura);
    }
  }, [visita?.id, recetasFromBack.lenght]);

  const onSubmit = async (body) => {
    ConfirmationAlert({
      title: "Guardar Visita",
      text: `Estas seguro de guardar los datos de la visita?`,
      onAccept: async () => {
        body.id = visita?.id;
        body.diagnostico = diagnostico;
        body.lugar_atencion = lugarAtencion?.name;
        const newData = await dispatch(updateVisita(body));
        if (newData?.error) {
          toast.error(
            `No se pudo guardar los datos de la visita: ${newData.error}`,
            { duration: 5000 }
          );
        } else {
          toast.success("Datos de visita guardados con exito!", {
            duration: 5000,
          });
          window.location.reload();
          //navigate(0);
        }
      },
    });
  };
  const handleIMC = () => {
    const pesoF = getValues("peso");
    const alturaF = getValues("altura");
    if (pesoF > 0 && alturaF > 0) {
      const IMC = Number.parseFloat(
        pesoF / (((alturaF / 100) * alturaF) / 100)
      ).toFixed(2);
      if (IMC < 18.5) setValueIMC(`${IMC} - Bajo Peso`);
      else if (IMC < 25) setValueIMC(`${IMC} - Peso Saludable`);
      else if (IMC < 30) setValueIMC(`${IMC} - Sobrepeso`);
      else setValueIMC(`${IMC} - Obesidad`);
    } else {
      setValueIMC("Ingrese el peso y la altura.");
    }
  };

  const handlePdfReceta = async () => {
    if (receta.length === 0 && recetasFromBack?.length === 0) {
      toast.error("Agregue elementos a la receta!", { duration: 5000 });
      return;
    }
    if (recetasFromBack?.length === 0) {
      ConfirmationAlert({
        title: "Generar Receta",
        text: `Estas seguro de generar la receta? Ya no podra editar despues de generar la receta.`,
        onAccept: async () => {
          const datosReceta = {
            visita_id: visita?.id,
            recetas: receta,
          };
          const newData = await dispatch(createReceta(datosReceta));
          if (newData?.error) {
            toast.error(`No se pudo generar la receta: ${newData.error}`, {
              duration: 5000,
            });
            return;
          } else {
            dispatch(getRecetasById(visita?.id));
            toast.success("Receta generada con exito! Descargando . . .", {
              duration: 5000,
            });
            const blob = await pdf(
              <RecetaPdf
                data={{
                  nombre: `${cita?.patient?.persona?.nombre} ${cita?.patient?.persona?.apellidos}`,
                  edad: `${cita?.patient?.persona?.fecha_nacimiento ? getAge(cita?.patient?.persona?.fecha_nacimiento) + " años" : ""}`,
                  fecha: new Date().toLocaleDateString("en-GB"),
                  lugarAtencion:
                    visita?.lugar_atencion ?? `${getValues("lugar_atencion")}`,
                  seguro: `${cita?.patient?.empresa_seg ?? ""}`,
                  recetas: receta,
                }}
              />
            ).toBlob();
            saveAs(
              blob,
              `Receta-${visita.id}-${new Date().toLocaleDateString(
                "en-GB"
              )}.pdf`
            );
          }
        },
      });
    } else {
      const blob = await pdf(
        <RecetaPdf
          data={{
            nombre: `${cita?.patient?.persona?.nombre} ${cita?.patient?.persona?.apellidos}`,
            edad: `${cita?.patient?.persona?.fecha_nacimiento ? getAge(new Date(cita?.patient?.persona?.fecha_nacimiento)) + " años" : ""}`,
            fecha: new Date().toLocaleDateString("en-GB"),
            lugarAtencion:
              visita?.lugar_atencion ?? `${getValues("lugar_atencion")}`,
            seguro: `${cita?.patient?.empresa_seg ?? ""}`,
            recetas: recetasFromBack,
          }}
        />
      ).toBlob();
      saveAs(
        blob,
        `Receta-${visita.id}-${new Date().toLocaleDateString("en-GB")}.pdf`
      );
    }
  };

  const deleteReceta = () => {
    ConfirmationAlert({
      title: "Eliminar Receta",
      text: `Estas seguro de eliminar la receta? Ya no podra recuperar la informacion despues de eliminar la receta.`,
      onAccept: async () => {
        const newData = await dispatch(deleteRecetaByVisitaId(visita?.id));
        if (newData?.error) {
          toast.error(`No se pudo elimar la receta: ${newData.error}`, {
            duration: 5000,
          });
          return;
        } else {
          setReceta([]);
          dispatch(getRecetasById(visita?.id));
          toast.success("Receta eliminada con exito!", {
            duration: 5000,
          });
        }
      },
    });
  };

  const handleImpresionIC = async () => {
    const blob = await pdf(
      <HistoriaPdf
        data={{
          patient: cita?.patient,
          visita,
          receta: recetasFromBack,
        }}
      />
    ).toBlob();
    saveAs(
      blob,
      `HC-${visita.id}-${new Date().toLocaleDateString("en-GB")}.pdf`
    );
  };
  return (
    <>
      {
        // modal
        isOpen && (
          <MedicineDosageModal
            isOpen={isOpen}
            setReceta={setReceta}
            closeModal={() => {
              setIsOpen(false);
            }}
          />
        )
      }
      {
        // modal
        labOpen && (
          <LaboratoryServiceModal
            isOpen={labOpen}
            closeModal={() => {
              setLabOpen(!labOpen);
            }}
            visita={visita}
          />
        )
      }
      <Tabs className="border-border">
        <TabList>
          {cita?.tipo_evento !== "Analisis o Laboratorio" && (
            <>
              <Tab>Atención</Tab>
              <Tab>Recetas</Tab>
            </>
          )}
          <Tab>Analisis y Laboratorios</Tab>
        </TabList>
        {cita?.tipo_evento !== "Analisis o Laboratorio" && (
          <>
            <TabPanel>
              {/* Atencion */}
              <div className=" grid grid-cols-12 gap-6 items-start">
                <div
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  data-aos-delay="100"
                  data-aos-offset="200"
                  className="col-span-12 lg:col-span-12 bg-white rounded-xl p-6"
                >
                  <div className="flex w-full flex-col gap-5">
                    <Button label="Imprimir HC" onClick={handleImpresionIC} />
                    <div className="flex w-full flex-col gap-2">
                      <p className="text-black text-sm">Lugar de Atención</p>
                      <Select
                        selectedPerson={lugarAtencion}
                        setSelectedPerson={setLugarAtencion}
                        datas={lugaresAtencion}
                      >
                        <div className="w-full flex-btn text-textGray text-sm p-4 border border-border font-light rounded-lg focus:border focus:border-subMain">
                          {lugarAtencion?.name}{" "}
                          <BiChevronDown className="text-xl" />
                        </div>
                      </Select>
                    </div>
                    <Textarea
                      label="Motivo de la consulta"
                      color={true}
                      rows={3}
                      visible={true}
                      placeholder={"Dolor..."}
                      name="motivo"
                      register={register("motivo")}
                      setValue={setValue}
                      getValue={getValues}
                    />
                    <Textarea
                      label="Enfermedad Actual"
                      color={true}
                      rows={3}
                      register={register("desglose_motivo")}
                      name="desglose_motivo"
                      setValue={setValue}
                      getValue={getValues}
                      placeholder={"Dolor abdominal y ...."}
                    />
                    {/* Vital Signs */}
                    <div className="border p-5 rounded-lg flex flex-col gap-3">
                      <h2 className="mb-3">Examen Físico General</h2>
                      <ThreeColumRow>
                        <Input
                          label="Frecuencia Cardiaca"
                          color={true}
                          type="number"
                          register={register("f_cardiaca")}
                          placeholder="0-200"
                        />
                        <Input
                          label="Presion Arterial 1'"
                          color={true}
                          type="text"
                          register={register("p_arterial_1")}
                          placeholder="40- 230/0-160"
                        />
                        <Input
                          label="Presion Arterial 5'"
                          color={true}
                          type="text"
                          register={register("p_arterial_5")}
                          placeholder="40- 230/0-160"
                        />
                      </ThreeColumRow>
                      <TwoColumRow>
                        <Input
                          label="Frecuencia Respiratoria"
                          color={true}
                          type="number"
                          register={register("f_respiratoria")}
                          placeholder="0-340"
                        />
                        <Input
                          label="Temperatura"
                          color={true}
                          type="number"
                          register={register("temperatura")}
                          placeholder="36"
                        />
                      </TwoColumRow>
                      <ThreeColumRow>
                        <Input
                          label="Peso"
                          color={true}
                          type="number"
                          register={register("peso", { onChange: handleIMC })}
                          placeholder="36 kg"
                        />
                        <Input
                          label="Altura"
                          color={true}
                          type="number"
                          register={register("altura", { onChange: handleIMC })}
                          placeholder="175cm"
                        />
                        <div className="flex flex-col justify-center items-center ">
                          <p>IMC</p>
                          <p className="p-4 font-medium">{valueIMC}</p>
                        </div>
                        p
                      </ThreeColumRow>
                      <Textarea
                        label="Examen Regional"
                        color={true}
                        rows={3}
                        register={register("examen_fisico")}
                        name="examen_fisico"
                        setValue={setValue}
                        getValue={getValues}
                        placeholder={"Otros datos del examen físico, ...."}
                      />
                    </div>
                    <DinamicInputWithSearchBar
                      label="Diagnostico"
                      color={true}
                      rows={3}
                      visible={true}
                      datos={diagnostico}
                      dataSearch={Enfermedades}
                      setDatos={setDiagnostico}
                      placeholder={"CIE-10..."}
                    />
                    <Textarea
                      label="Comentarios"
                      color={true}
                      rows={3}
                      register={register("comentarios")}
                      name="comentarios"
                      setValue={setValue}
                      getValue={getValues}
                      placeholder={"Comentarios...."}
                    />
                    <Textarea
                      label="Conducta"
                      color={true}
                      rows={3}
                      register={register("conducta")}
                      name="conducta"
                      setValue={setValue}
                      getValue={getValues}
                      placeholder={"Conducta ...."}
                    />
                    <Button
                      label={"Guardar"}
                      Icon={HiOutlineCheckCircle}
                      onClick={handleSubmit(onSubmit)}
                    />
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              {/* receta */}
              <div className=" grid grid-cols-12 gap-6 items-start">
                <div
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  data-aos-delay="100"
                  data-aos-offset="200"
                  className="col-span-12 lg:col-span-12 bg-white rounded-xl p-6"
                >
                  <div>
                    <div className="flex w-full flex-col gap-4 mb-6">
                      <div className="flex justify-between items-center">
                        <p className="text-black text-lg">Prescripción</p>
                        <div className="flex gap-2">
                          <button
                            onClick={() => handlePdfReceta()}
                            className="flex justify-center items-center bg-subMain rounded-lg text-white text-center text-2xl p-2"
                          >
                            <span className="text-sm mr-1">
                              {recetasFromBack?.length > 0
                                ? "Imprimir "
                                : "Generar PDF "}
                            </span>{" "}
                            <FaFileDownload />
                          </button>
                          {recetasFromBack?.length > 0 && (
                            <button
                              onClick={deleteReceta}
                              className="bg-red-600 bg-opacity-5 text-red-600 rounded-lg border border-red-100 py-3 px-4 text-sm"
                            >
                              <RiDeleteBinLine />
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="w-full overflow-x-scroll">
                        <MedicineDosageTable
                          data={
                            recetasFromBack.length > 0
                              ? recetasFromBack
                              : receta
                          }
                          functions={{
                            delete: (id) => {
                              setReceta((state) =>
                                state.filter((_, index) => index !== id)
                              );
                              toast.success("Elemento eliminado!");
                            },
                          }}
                          button={true}
                        />
                      </div>
                      {recetasFromBack?.length === 0 && (
                        <button
                          onClick={() => {
                            setIsOpen(true);
                          }}
                          className=" text-subMain flex-rows gap-2 rounded-lg border border-subMain border-dashed py-4 w-full text-sm"
                        >
                          <BiPlus /> Agregar Medicamento
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </>
        )}
        <TabPanel>
          {/* Analisis y laboratorios */}
          <div className=" grid grid-cols-12 gap-6 items-start">
            <div
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-delay="100"
              data-aos-offset="200"
              className="col-span-12 lg:col-span-12 bg-white rounded-xl p-6"
            >
              <div>
                <div className="flex w-full flex-col gap-3">
                  <div className="flex justify-between items-center">
                    <p className="text-black text-lg">
                      Análisis y Laboratorios
                    </p>
                    {/* <button className="flex justify-center items-center bg-subMain rounded-xl text-white text-center text-2xl p-2">
                    <FaFileDownload />
                  </button> */}
                  </div>

                  <div className="w-full overflow-x-scroll">
                    <LaboratoryTable
                      data={estudios}
                      functions={{
                        delete: (body) => {
                          ConfirmationAlert({
                            title: "Eliminar Orden",
                            text: "Esta seguro de eliminar la orden?",
                            onAccept: async () => {
                              const res = await dispatch(deleteEstudio(body));
                              if (res?.error) {
                                toast.error(
                                  "No se pudo eliminar la orden, " + res.error
                                );
                              } else {
                                dispatch(getEstudiosByCitaId(cita?.id));
                                toast.success("Orden eliminada correctamente!");
                              }
                            },
                          });
                        },
                        printPdf: async (body) => {
                          const blob = await pdf(
                            <OrdenPdf
                              data={{
                                nombre: `${cita?.patient?.persona?.nombre} ${cita?.patient?.persona?.apellidos}`,
                                edad: `${cita?.patient?.persona?.fecha_nacimiento ? getAge(new Date(cita?.patient?.persona?.fecha_nacimiento)) + " años": ""}`,
                                fecha: new Date().toLocaleDateString("en-GB"),
                                lugarAtencion: visita?.lugar_atencion ??`${getValues("lugar_atencion")}`,
                                seguro: `${cita?.patient?.empresa_seg ?? ""}`,
                                orden: body,
                              }}
                            />
                          ).toBlob();
                          saveAs(
                            blob,
                            `Orden-${visita.id}-${new Date().toLocaleDateString("en-GB")}.pdf`
                          );
                        },
                      }}
                      button={true}
                    />
                  </div>
                  <button
                    onClick={() => {
                      setLabOpen(true);
                    }}
                    className=" text-subMain flex-rows gap-2 rounded-lg border border-subMain border-dashed py-4 w-full text-sm"
                  >
                    <BiPlus /> Agregar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </>
  );
}

export default NewMedicalRecode;
