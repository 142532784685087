import { useParams } from "react-router-dom";
import PersonalInfoView from "../../components/UsedComp/PersonalInfoView";
import ExamResults from "./ExamResults";
import HealthInfomationView from "./HealthInfomationView";
import { getAge } from "../../utils/age";
import Loading from "../Loading";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Modal from "../../components/Modals/Modal";
import PersonalInfo from "../../components/UsedComp/PersonalInfo";
import HealthInfomation from "./HealthInfomation";
import MedicalRecord from "./MedicalRecord";
import { LaboratoryTable } from "../../components/Tables";
import toast from "react-hot-toast";
import {
  deleteEstudio,
  getAllEstudiosByPatient,
  getEstudiosByPatientId,
} from "../../redux/features/estudiosSlice";
import ConfirmationAlert from "../../components/ConfirmationAlert";
import OrdenPdf from "../../pdf/OrdenPdf";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { usePatient } from "../../customHooks/queries/usePatient";

const PatientView360 = ({ setActiveTab }) => {
  const [openPatientInfo, setOpenPatientInfo] = useState(false);
  const [openPatientHealt, setOpenPatientHealt] = useState(false);
  const [openPatientCitas, setOpenPatientCitas] = useState(false);
  const [openPatientHistory, setOpenPatientHistory] = useState(false);

  const { id } = useParams();
  const dispatch = useDispatch();
  const { data: patient, isLoading } = usePatient(id);

  const estudios = useSelector(getAllEstudiosByPatient);

  useEffect(() => {
    dispatch(getEstudiosByPatientId(id));
  }, [id, dispatch]);
  if (isLoading) return <Loading />;
  return (
    <>
      {!patient.data ? (
        <Loading />
      ) : (
        <div className="w-full h-[calc(100vh-8rem)] overflow-clip">
          {openPatientInfo && (
            <Modal
              isOpen={openPatientInfo}
              closeModal={() => {
                setOpenPatientInfo(false);
              }}
              title="Informacion del Paciente"
              width={"max-w-3xl"}
            >
              <PersonalInfo titles={false} operation="edit" />
            </Modal>
          )}
          {openPatientHealt && (
            <Modal
              isOpen={openPatientHealt}
              closeModal={() => {
                setOpenPatientHealt(false);
              }}
              title="Antecedentes Clinicos"
              width={"max-w-3xl"}
            >
              <HealthInfomation />
            </Modal>
          )}
          {
            <Modal
              isOpen={openPatientCitas}
              closeModal={() => {
                setOpenPatientCitas(false);
              }}
              title="Análisis y Laboratorios"
              width={"max-w-3xl"}
            >
              <LaboratoryTable
                id={id}
                data={estudios}
                functions={{
                  delete: (body) => {
                    ConfirmationAlert({
                      title: "Eliminar Orden",
                      text: "Esta seguro de eliminar la orden?",
                      onAccept: async () => {
                        const res = await dispatch(deleteEstudio(body));
                        if (res?.error) {
                          toast.error(
                            "No se pudo eliminar la orden, " + res.error
                          );
                        } else {
                          dispatch(getEstudiosByPatientId(id));
                          toast.success("Orden eliminada correctamente!");
                        }
                      },
                    });
                  },
                  printPdf: async (body) => {
                    const blob = await pdf(
                      <OrdenPdf
                        data={{
                          nombre: `${body?.cita?.patient?.persona?.nombre} ${body?.cita?.patient?.persona?.apellidos}`,
                          edad: `${
                            body?.cita?.patient?.persona?.fecha_nacimiento
                              ? getAge(
                                  new Date(
                                    body?.cita?.patient?.persona?.fecha_nacimiento
                                  )
                                ) + " años"
                              : ""
                          }`,
                          fecha: new Date().toLocaleDateString("en-GB"),
                          lugarAtencion: body?.cita?.visita?.lugar_atencion,
                          seguro: `${body?.cita?.patient?.empresa_seg ?? ""}`,
                          orden: body,
                        }}
                      />
                    ).toBlob();
                    saveAs(
                      blob,
                      `Orden-${
                        body?.visita?.id
                      }-${new Date().toLocaleDateString("en-GB")}.pdf`
                    );
                  },
                }}
                button={true}
              />
            </Modal>
          }
          {
            <Modal
              isOpen={openPatientHistory}
              closeModal={() => {
                setOpenPatientHistory(false);
              }}
              title="Historial Medico"
              width={"max-w-3xl"}
            >
              <MedicalRecord />
            </Modal>
          }
          <div className="sm:grid sm:grid-cols-12 sm:grid-rows-3 gap-1 overflow-auto sm:overflow-hidden h-full w-full ">
            <div
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-delay="100"
              data-aos-offset="200"
              className="flex justify-center col-span-12 sm:col-span-3 row-span-3 sm:row-span-1 bg-white rounded-xl border-[1px] border-border p-6 overflow-hidden"
            >
              <div
                data-aos="fade-right"
                data-aos-duration="1000"
                data-aos-delay="100"
                data-aos-offset="200"
                className="col-span-12 flex-colo gap-6 lg:col-span-4 bg-white lg:sticky top-28"
              >
                <img
                  src={
                    patient
                      ? patient.data.persona?.perfil
                      : "https://png.pngtree.com/png-vector/20230131/ourmid/pngtree-flat-user-profile-icon-on-isolated-background-with-permission-sign-vector-png-image_49602760.jpg"
                  }
                  alt="imagen de perfil"
                  className="w-20 h-20 rounded-full object-cover border border-dashed border-subMain"
                />
                <div className="gap-2 flex-colo">
                  <h2 className="text-xs font-semibold">{`${patient.data?.persona?.nombre} ${patient.data?.persona?.apellidos}`}</h2>
                  <a
                    href={`mailto:${patient.data?.persona?.email}`}
                    className="text-xs text-textGray"
                  >
                    {patient.data?.persona?.email}
                  </a>
                  <a
                    href={`https://wa.me/+571${patient.data?.persona?.telf1}/`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-xs"
                  >
                    {patient.data?.persona?.telf1}
                  </a>
                  <p className="text-xs text-subMain bg-text font-medium py-1 px-4 rounded-full border-[0.5px] border-subMain">
                    {getAge(new Date(patient.data.persona?.fecha_nacimiento))}{" "}
                    años
                  </p>
                </div>
              </div>
            </div>
            <div className="sm:col-span-9 sm:row-span-1 sm:col-start-4 p-5 pb-0 flex flex-col justify-between border rounded-lg bg-white">
              <h2 className=" font-semibold text-base">Informacion Personal</h2>
              <div className="h-5/6 overflow-y-scroll">
                <PersonalInfoView />
              </div>
              <p
                onClick={() => setOpenPatientInfo(true)}
                className="text-right text-subMain font-medium hover:font-light text-sm cursor-pointer"
              >
                Ver mas . . .
              </p>
            </div>
            <div className="border sm:col-span-6 sm:row-span-1 sm:row-start-2 flex flex-col justify-between p-5 pb-0 rounded-lg overflow-hidden bg-white">
              <h2 className="pb-2 font-semibold text-base">
                Antecedentes Clinicos
              </h2>
              <div className="h-5/6 overflow-y-scroll">
                <HealthInfomationView />
              </div>
              <p
                onClick={() => setOpenPatientHealt(true)}
                className="text-right text-subMain font-medium hover:font-light text-sm cursor-pointer"
              >
                Ver mas . . .
              </p>
            </div>
            <div className="sm:col-span-6 sm:row-span-1 sm:col-start-7 p-5 pb-0 flex flex-col justify-between border rounded-lg bg-white">
              <h2 className="font-semibold text-base">
                Análisis y Laboratorios
              </h2>
              <div className="h-2/3 overflow-y-scroll">
                <LaboratoryTable data={estudios} button={true} view={true} />
              </div>
              <p
                onClick={() => setOpenPatientCitas(true)}
                className="text-right text-subMain font-medium hover:font-light text-sm cursor-pointer"
              >
                Ver mas . . .
              </p>
            </div>

            <div className="sm:col-span-8 sm:row-span-1 sm:row-start-3 flex flex-col justify-between p-5 pb-0 border rounded-lg bg-white">
              <h2 className="font-semibold text-base">Historial Medico</h2>
              <div className="pt-0 overflow-y-scroll ">
                <MedicalRecord />
              </div>
              <p
                onClick={() => setOpenPatientHistory(true)}
                className="text-right text-subMain font-medium hover:font-light text-sm cursor-pointer"
              >
                Ver mas . . .
              </p>
            </div>
            <div className="sm:col-span-4 sm:row-span-1 sm:col-start-9 sm:row-start-3 border rounded-lg p-5 bg-white">
              <h2 className="font-semibold text-base mb-2">
                Comparación de Resultados
              </h2>
              <div className="overflow-y-scroll h-5/6">
                <ExamResults />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default PatientView360;
