import { RadioGroup } from "@headlessui/react";
import { useEffect, useState } from "react";
import { BiChevronDown, BiPlus, BiSearch } from "react-icons/bi";
import Layout from "../../Layout";
import { medicineData, memberData } from "../../components/Datas";
import { Button, Select } from "../../components/Form";
import { LaboratoriesTable, LaboratoryTable } from "../../components/Tables";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteEstudio,
  getAllEstudios,
  getEstudios,
  getEstudiosByPatientId,
} from "../../redux/features/estudiosSlice";
import OrdenPdf from "../../pdf/OrdenPdf";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { getAge } from "../../utils/age";
import ConfirmationAlert from "../../components/ConfirmationAlert";

function Laboratories() {
  const dispatch = useDispatch();
  const estudios = useSelector(getAllEstudios);

  useEffect(() => {
    dispatch(getEstudios());
  }, []);

  return (
    <Layout>
      <h1 className="text-xl font-semibold m-5">Análisis y Laboratorios</h1>
      <div className="w-full justify-center items-center p-10 bg-white rounded-lg overflow-auto border h-full">
        <LaboratoryTable
          data={estudios}
          functions={{
            printPdf: async (body) => {
              const blob = await pdf(
                <OrdenPdf
                  data={{
                    nombre: `${body?.cita?.patient?.persona?.nombre} ${body?.cita?.patient?.persona?.apellidos}`,
                    edad: `${
                      body?.cita?.patient?.persona?.fecha_nacimiento
                        ? getAge(
                            new Date(
                              body?.cita?.patient?.persona?.fecha_nacimiento
                            )
                          ) + " años"
                        : "No disponible"
                    }`,
                    fecha: new Date().toLocaleDateString("en-GB"),
                    lugarAtencion:
                      body?.cita?.visita?.lugar_atencion ?? "No disponible",
                    seguro: `${
                      body?.cita?.patient?.empresa_seg ?? "No disponible"
                    }`,
                    orden: body,
                  }}
                />
              ).toBlob();
              saveAs(
                blob,
                `Orden-${body?.id}-${new Date().toLocaleDateString(
                  "en-GB"
                )}.pdf`
              );
            },
          }}
          button={true}
          patient={true}
        />
      </div>
    </Layout>
  );
}

export default Laboratories;
